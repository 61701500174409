import moment from 'moment';
import React, { useState, useEffect, useRef } from "react";
import './css/docwrite.css';
import axios from "axios";
import LeaveHistory from "../components/LeaveHistory";
import { BiSearchAlt } from "react-icons/bi";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, leaves }) {
  const hour = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

 
  return rows.map((rowsData, index) => {

  

    var {vtype,
      vrange,
      startHour,
      startMin,
      endHour,
      endMin,

      start_date,
      end_date,
      days,
      reason,
      contact}= rowsData;


      end_date= moment(end_date).format("yyyy-MM-DD");

      start_date= moment(start_date).format("yyyy-MM-DD");
      console.log("hihihihio", rowsData);

    return (
    <table style={{width:"100%"}}>

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  

  <tr>

  <td class="table_title2" nowrap="">
    휴가 종류
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    

    <select name="vtype" className="formInput" style={{marginLeft:"10px"}} value={vtype || ''}
      onChange={(event) => onValUpdate(index, event)}
      >

      
    {leaves.filter((ele) => ele.inuse === 1).map((ele,idx)=>(
      <option value={ele.leave_value}>{ele.leave_type}</option>
    ))}

    </select> 
    </div>
    </td>


    
<td class="table_title2 pc" nowrap="">
  범위선택
</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>

    {vtype ==="0.5" && (
        <select name="vrange" className="formInput" style={{marginLeft:"10px"}} value={vrange || ''}
      
        onChange={(event) => onValUpdate(index, event)}

>
  <option value="1">오전</option>
  <option value="2">오후</option>


  </select> 
    )}
{vtype ==="0.25" && (
  <div className="time">

    <div className="startTime">

  <select name="startHour" className="formInput" style={{marginLeft:"10px"}} value={startHour || ''}
   
   onChange={(event) => onValUpdate(index, event)}
   
   >
  {hour.map((element, index)=>(
      <option value={element}>{element}</option>
  ))}

  
  </select> 

  <span>:</span>
  <select name="startMin" className="formInput" style={{marginLeft:"10px"}} value={startMin || ''}
    onChange={(event) => onValUpdate(index, event)}
    >
  {Array.from(Array(60), (e, i) => {
    if (i >=0 && i <=9){
      return <option value={"0"+i}>0{i}</option>
    }else{
      return <option value={i}>{i}</option>
    }
  })}

  
  </select> 
  </div>

<span>~</span>

  <div className="endTime">

  <select name="endHour" className="formInput" style={{marginLeft:"10px"}} value={endHour || ''}>
  {hour.map((element, index)=>(
      <option value={element}>{element}</option>
  ))}

  
  </select> 

  <span>:</span>
  <select name="endMin" className="formInput" style={{marginLeft:"10px"}} value={endMin || ''}>
  {Array.from(Array(60), (e, i) => {
    if (i >=0 && i <=9){
      return <option value={"0"+i}>0{i}</option>
    }else{
      return <option value={i}>{i}</option>
    }
  })}

  
  </select> 
  </div>
  </div>
    )}
  </div>
  </td>


  <td class="table_title2 pc" nowrap="">
  휴가 기간 
</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
     <input type="date" name="start_date" className="formInput" placeholder="제목을 입력하세요."
       onChange={(event) => onValUpdate(index, event)}

     value={start_date}></input> 
<span>~</span>
     <input type="date" name="end_date" className="formInput" placeholder="제목을 입력하세요."
     
     onChange={(event) => onValUpdate(index, event)}
 
    value={end_date}></input> 



  </div>
  </td>

  <td class="table_title2 pc" nowrap="">
  차감기간
  </td>

  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    
  {!isNaN(days) && (
    days
  )}
  

  </div>
  </td>
  </tr>


<tr className="mobile">

<td class="table_title2" nowrap="">
  범위선택
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>

    {vtype ==="0.5" && (
        <select name="vrange" className="formInput" style={{marginLeft:"10px"}} value={vrange || ''}
      
        onChange={(event) => onValUpdate(index, event)}

>
  <option value="1">오전</option>
  <option value="2">오후</option>


  </select> 
    )}
{vtype ==="0.25" && (
  <div className="time">

    <div className="startTime">

  <select name="startHour" className="formInput" style={{marginLeft:"10px"}} value={startHour || ''}
   
   onChange={(event) => onValUpdate(index, event)}
   
   >
  {hour.map((element, index)=>(
      <option value={element}>{element}</option>
  ))}

  
  </select> 

  <span>:</span>
  <select name="startMin" className="formInput" style={{marginLeft:"10px"}} value={startMin || ''}
    onChange={(event) => onValUpdate(index, event)}
    >
  {Array.from(Array(60), (e, i) => {
    if (i >=0 && i <=9){
      return <option value={"0"+i}>0{i}</option>
    }else{
      return <option value={i}>{i}</option>
    }
  })}

  
  </select> 
  </div>

<span>~</span>

  <div className="endTime">

  <select name="endHour" className="formInput" style={{marginLeft:"10px"}} value={endHour || ''}>
  {hour.map((element, index)=>(
      <option value={element}>{element}</option>
  ))}

  
  </select> 

  <span>:</span>
  <select name="endMin" className="formInput" style={{marginLeft:"10px"}} value={endMin || ''}>
  {Array.from(Array(60), (e, i) => {
    if (i >=0 && i <=9){
      return <option value={"0"+i}>0{i}</option>
    }else{
      return <option value={i}>{i}</option>
    }
  })}

  
  </select> 
  </div>
  </div>
    )}
  </div>
  </td>

</tr>

<tr className="mobile">

<td class="table_title2" nowrap="">
  휴가 기간 
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
     <input type="date" name="start_date" className="formInput" placeholder="제목을 입력하세요."
       onChange={(event) => onValUpdate(index, event)}

     value={start_date}></input> 
<span>~</span>
     <input type="date" name="end_date" className="formInput" placeholder="제목을 입력하세요."
     
     onChange={(event) => onValUpdate(index, event)}
 
    value={end_date}></input> 



  </div>
  </td>
</tr>



<tr className="mobile">
<td class="table_title2" nowrap="">
  차감기간
  </td>

  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    

  {days}

  </div>
  </td>

</tr>

<tr>
  <td class="table_title2" nowrap="">
    사유
  </td>
  <td class="table_list2_left">
  <div className="input-group">
  <input type="text" name="reason" className="formInput" placeholder="휴가 사유를 입력해 주세요." value={reason}
  onChange={(event) => onValUpdate(index, event)}
  ></input>
 
  </div>
  </td>
    
  </tr>
  <tr>

<td class="table_title2" nowrap="">
  비상연락처
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <input type="text" name="contact" className="formInput" placeholder=""               
  onChange={(event) => onValUpdate(index, event)}
value={contact}></input>



  </div>
  </td>
</tr>

</table>
    );
  });
}
const Leave = ({doc_no, onChange}) => {  
  
  var todayDate = new Date().toISOString().slice(0, 10);
  console.log(todayDate);
  const inputFileRef = useRef();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [subject, setSubject] = useState("");
  const [expire, setExpire] = useState("1");
  const [vrange, setRange] = useState("1");
  const [vtype, setType] = useState("1");
  const [reason, setReason] = useState("");
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [startHour, setStartHour] = useState("");
  const [startMin, setStartMin] = useState("");
  const [endHour, setEndHour] = useState("");
  const [endMin, setEndMin] = useState("");
  const [info, setInfo]= useState([]);
  const [used, setUsed] = useState("");
  const [remain, setRemain] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData]= useState();
  const [holidays, setHolidays] = useState([]);
  const [rows, initRow] = useState([]);
  const [values, setValues] = useState([]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
};

function getBusinessDaysDiff(startDate, endDate) {
  var diffDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
  var weekends = 0;

  for (var i = 0; i <= diffDays; i++) {
    var currentDate = new Date(startDate.getTime() + i * (1000 * 3600 * 24));

    
    
        
       

    if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
      weekends++;
    }else{
      for (let i=0;i<holidays.length;i++){
        var from = new Date(holidays[i].start.date); // -1 because months are from 0 to 11
        var to = new Date(holidays[i].end.date); // -1 because months are from 0 to 11
        to.setDate(to.getDate()-1);
        if (currentDate >= from && currentDate <= to){
          weekends++;
          break;
  
        }
        
      }
    }
  }

  var businessDaysDiff = diffDays - weekends;
  return businessDaysDiff;
}


const [leaves, setLeaves] = useState([]);

  useEffect(()=>{

    axios
    .get("https://gw.thegmmedical.com:5003/api/getuser_leave")
    .then(({ data }) => {
      console.log("getuser_leave", data);
      setInfo(data[0]);

      let num = data[0].number;
      axios
        .get("https://gw.thegmmedical.com:5003/api/getuserleave", {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {

          var history=data;
          var sum = 0;
          for (let i=0;i<history.length;i++){
            if (history[i].vtype !== "1"){
              sum+=parseFloat(history[i].vtype);
              console.log(history[i].vtype)
            }else{
              sum += parseInt(history[i].days);
            }
          }
          var day = Math.trunc(sum);
          var time = sum % 1.0;
          setUsed(day+"일 "+(time/0.25)*2+"시간");
          //console.log("...." + data);
          var remainDay = Math.trunc(num - sum);
          var remainTime = (num - sum) % 1.0;
          setRemain(remainDay+"일 "+(remainTime/0.25)*2+"시간")
          
          /*setInfo(data[0][0]);
          var history = data[1];
         
          }*/
        });
        

    });
    
    if (doc_no!==undefined){
    
    fetch("https://gw.thegmmedical.com:5003/api/get_leaves", {
          method: "get", // 통신방법
          headers: {
            "content-vtype": "application/json",
          },
          credentials: "include",
        })
        .then((res) => res.json())
        .then((json) => {
          console.log("json", json);
          setLeaves(json);
   
          
          axios.get('https://gw.thegmmedical.com:5003/api/getleave/'+doc_no)
        .then(function (response){
        
          console.log("getleave",  response.data);
          if (response.data.length>0){
         
    
          initRow(response.data);
          }else{
            let initial = [{
              vtype:json[0].leave_value.toString(),
              vrange:"1",
              startHour:"",
              startMin:"",
              endHour:"",
              endMin:"",
              start_date:"",
              end_date:"",
              days:"",
              reason:"",
              contact:""    
            }];
  
            console.log('hihishfisdhfisdhflsdif', initial);
  
            initRow(initial);
          }
        
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });

      
        })
        .catch(() => {});
        
   
      }else{
        fetch("https://gw.thegmmedical.com:5003/api/get_leaves", {
          method: "get", // 통신방법
          headers: {
            "content-vtype": "application/json",
          },
          credentials: "include",
        })
        .then((res) => res.json())
        .then((json) => {
          console.log("json2222222222222", json);
          setLeaves(json);
          let initial = [{
            vtype:json[0].leave_value.toString(),
            vrange:"1",
            startHour:"",
            startMin:"",
            endHour:"",
            endMin:"",
            start_date:"",
            end_date:"",
            days:"",
            reason:"",
            contact:""    
          }];

          console.log('hihishfisdhfisdhflsdif', initial);

          initRow(initial);
       
        })
        .catch(() => {});
        
      }

    var user_calendar=[], group_calendar=[];
    const BASE_CALENDAR_URL = "https://www.googleapis.com/calendar/v3/calendars";
const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY =
  "holiday@group.v.calendar.google.com";
const API_KEY = "AIzaSyAblXK6hywzoPW3Vp_4ASRDogoyEufpITA";
const CALENDAR_REGION = "ko.south_korea";
const currentYear = new Date().getFullYear();
const timeMin = new Date(`${currentYear}-01-01`).toISOString();
const timeMax = new Date(`${currentYear}-12-31`).toISOString();

//https://calendar.google.com/calendar/embed?src=ko.south_korea.official%23holiday%40group.v.calendar.google.com&ctz=Asia%2FSeoul

fetch(
  `${BASE_CALENDAR_URL}/${CALENDAR_REGION}.official%23${BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY}/events?key=${API_KEY}&timeMin=${timeMin}&timeMax=${timeMax}`
)
  .then((response) => response.json())
  .then((response) => {
    const formattedResponse = response.items
      .map(({ summary, start, end }) => ({ summary, start, end }))
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
      console.log("holidays....dd", formattedResponse);
    setHolidays(formattedResponse);
  });


        
      }, []);
      const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };
 




  const addRowTable = () => {
    const data = {
      vtype:leaves[0].leave_value,
      vrange:"1",
      startHour:"",
      startMin:"",
      endHour:"",
      endMin:"",
      start_date:"",
      end_date:"",
      days:"",
      reason:"",
      contact:""    };
    initRow([...rows, data]);
  };

  const delRowTable = () => {
    let temp = rows;
    temp.pop();
    initRow([...temp]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, e) => {
    const { name, value } = e.target;
    
    const data = [...rows];
    //console.log(data, name, value, i);

    if (name==="vtype"){
      data[i].vtype = value;
      if (value === "0.5" || value === "0.25"){
        data[i].end_date = data[i].start_date;

        data[i].days = value;

      }
      if (value==="1" && data[i].start_date!=="" && data[i].end_date!==""){
        var start_date = new Date(data[i].start_date); // 시작 날짜
        var end_date = new Date(data[i].end_date); // 종료 날짜

        var businessDaysDiff = getBusinessDaysDiff(start_date, end_date);
        console.log("businessDaysDiff", businessDaysDiff);
        data[i].days = businessDaysDiff+1; // 결과 출력
      }
      if (value==="0"){
        data[i].days = 0;
      }
      
    
    }
    else if (name === "end_date"){



        

      data[i][name] = value;

      if (data[i].vtype === "0.5" || data[i].vtype === "0.25"){
        data[i].start_date = value;
        data[i].days = data[i].vtype;
        
      }else{
        let start_date = new Date(data[i].start_date); // 시작 날짜
        let end_date = new Date(value); // 종료 날짜

        let businessDaysDiff = getBusinessDaysDiff(start_date, end_date);
        console.log("businessDaysDiff", businessDaysDiff);
        data[i].days = businessDaysDiff+1; // 결과 출력

      

      }
   

    }

    else if (name === "start_date"){

      data[i].start_date = value;

      if (data[i].vtype === "0.5" || data[i].vtype === "0.25"){
        data[i].end_date = value;
        
        data[i].days = data[i].vtype;
        
      }else{
        let start_date = new Date(value); // 시작 날짜
        let end_date = new Date(data[i].end_date); // 종료 날짜
  
        let businessDaysDiff = getBusinessDaysDiff(start_date, end_date);
        console.log("businessDaysDiff", businessDaysDiff);
        data[i].days = businessDaysDiff+1; // 결과 출력
  
     
  
      }
      if (e.target.value > data[i].end_date){
        data[i].end_date = value;


        data[i].days = 1; // 결과 출력
      }
      
    }

    else if (name==="startMin"){
      data[i].startMin = value;
      data[i].endMin = value;
    }else if (name==="startHour"){
      data[i].startHour = value;
      //onChange(data);
      
      if (e.target.value === "23"){
        data[i].endHour = "01";
        
      }else{
        
        var s = parseInt(e.target.value, 10);
        s+=2;
        if (s >=0 && s <=9){
          s = s.toString();console.log(s);
          s = "0"+s;
        }else{
          s = s.toString();console.log(s);
        }
        
       
        data[i].endHour = s;
      }
    }else{
      data[i][name] = value;
    }

    
    

   
    initRow(data);

    values.arr = rows;
    onChange(values);

  };

  

  
  return (
    <div className="wrapper">

    
    <div className="" style={{justifyContent:"flex-start"}}>


   <div className="">
      {/*{show ? (
    <DraftsList onChange={(value) => {setSubject(value.subject);editorRef.current?.getInstance().setHTML(value.content);setDraftNo(value.draft_no)}} closePopup={() => toggleShow()}/>
      ) : null}*/}
 
  
            {showPopup ? (
  <LeaveHistory closePopup={() => togglePopup()} />
) : null}
            
      <table className="write_table leave_view">

      <tr className="">
<td>년도 : <span>{new Date().getFullYear()}</span></td>

<td><span>총 할당수 : </span><span> {info!==undefined && info.number}일</span></td>
<td><span>총 사용수 : </span><span> {used}</span><BiSearchAlt onClick={()=>togglePopup()}/></td>
<td><span>현재 잔여수 : </span><span> {remain}</span></td>


</tr>

 
  <TableRows
    rows={rows}
    tableRowRemove={tableRowRemove}
    onValUpdate={onValUpdate}
    leaves={leaves}
  />
  
 
      </table>

      <div className="btns" style={{display:"flex", justifyContent:"flex-end", margin:"20px auto", width:"100%"}}>
     
     <button className="btn" onClick={addRowTable}>
       추가
     </button>
     <button className="btn" onClick={delRowTable}>
       삭제
     </button>

   </div>
      
    

      </div>
   </div>
</div>
  );
};

export default Leave;