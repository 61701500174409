import React, { useState, useEffect, useRef } from "react";


import "./css/checkline.css";
import axios from "axios";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import initial from "../pages/data/cell";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs'




const BoardEditorSetting = ({ editor_type, bgNo, bgName, order_no, closePopup }) => {

  const [initialText, setInitialText] = useState([]);

  const [showPopup, setPopup] = useState(false);
 
  const [values, setValues] = useState(false);

  const [editor, setEditor] = useState("text");

  const [list, setList] = useState([]);

  const [imgLink, setImgLink] = useState("");
  const [fileArray, setArray] = useState([]);
  const [initialHtml, setInitialHtml] = useState("");
  const [div, setDiv]=useState(
  );

  const [excelData, setExcelData] = useState();
  const [__html, setHtml] = useState("");
  const [aa, setAA]= useState();
  const [jsonData, setJsonData] = useState("");
  const [cols, setCols] = useState("");
  const [rows, setRows] = useState("");




  var shData=[];
  const editorRef = useRef();
  function nextChar(c) {
    var u = c.toUpperCase();
    if (same(u,'Z')){
        var txt = '';
        var i = u.length;
        while (i--) {
            txt += 'A';
        }
        return (txt+'A');
    } else {
        var p = "";
        var q = "";
        if(u.length > 1){
            p = u.substring(0, u.length - 1);
            q = String.fromCharCode(p.slice(-1).charCodeAt(0));
        }
        var l = u.slice(-1).charCodeAt(0);
        var z = nextLetter(l);
        if(z==='A'){
            return p.slice(0,-1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
        } else {
            return p + z;
        }
    }
}


function nextLetter(l){
    if(l<90){
        return String.fromCharCode(l + 1);
    }
    else{
        return 'A';
    }
}


function same(str,char){
    var i = str.length;
    while (i--) {
        if (str[i]!==char){
            return false;
        }
    }
    return true;
}






  const togglePopup = () => {
    setPopup(!showPopup);
  };

  useEffect(() => {

    console.log("hifslfjslkdfsl", editor_type);

    axios.get('https://gw.thegmmedical.com:5003/api/get_data_editor/' + bgNo)
      .then(function (res){
        if (res.data.length>0){
          console.log("heyheyhey", res.data[0], "editor_type", editor_type);
          
          let content = res.data[0].content;
          let type = res.data[0].editor_type;
          setEditor(type);
          if (editor_type==="excel"){
        
          
            if (type==="excel"){
              const htmlData = JSON.parse(content);
              console.log("htmlData", htmlData);
              if (
                typeof htmlData === 'object' &&
                !Array.isArray(htmlData) &&
                htmlData !== null
            ) {

              setDiv(<div style={{width:"100%", height:"500px"}}>

              <Workbook onOp={console.log} data={[htmlData]} onContextMenu={e=>{e.preventDefault();}}  onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} />
              </div>
              );
            }else{


              setDiv(<div style={{width:"100%", height:"500px"}}>

              <Workbook onOp={console.log} data={[initial]} onContextMenu={e=>{e.preventDefault();}}  onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} />
              </div>
              );
            }
            }
          }else{
            if (type==="text"){
            editorRef.current?.getInstance().setHTML(content);
          
            setInitialText(content);
            }
          }
          
          
        }else{
          if (editor_type==="excel"){
            setDiv(<div style={{width:"100%", height:"500px"}}>

              <Workbook onOp={console.log} data={[initial]} onContextMenu={e=>{e.preventDefault();}}  onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} />
              </div>
              );
          }
        }
        
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });


 
    
    
   
    
  }, []);
 
  const itscell = {
    name: "Sheet1",
    config: {
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
      },
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 38,
    status: 1,
    ch_width: 1361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
   
    scrollLeft: 0,
    scrollTop: 0
  };
 
  async function handleFileAsync(e) {
    setDiv("");
   
    const wb = new ExcelJS.Workbook()
         
          const file = e.target.files[0];
    const exceldata = await file.arrayBuffer();
          wb.xlsx.load(exceldata)
          .then(wb => {
             //console.log(workbook, 'workbook instance');
 
              wb.eachSheet((sheet, id) => {
                 sheet.eachRow((row, rowIndex) => {
                 //console.log(row);
                 });
              });
              const ws = wb.worksheets[0] // 첫 번째 sheet 선택
     
    const options = { includeEmpty: true }
   
     ws.eachRow(options, (row, rowNum) => {
      shData[rowNum-1] = []
      row.eachCell(options, (cell, cellNum) => {
        shData[rowNum-1][cellNum-1] = { value:cell.value, style:cell.style }
     
     
      })
    })
    //console.log("sheeeeeeeeet", sheetData);
    //setShData(sheetData);
    /* data is an ArrayBuffer */
    const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});
 
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
      setHtml(htmlData);
      //console.log(htmlData);
 
    const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
      header:1,
      defval:"",
    })
    setJsonData(jsondata);
   
   


    var sheetnames = workbook.SheetNames;


    console.log("workbook sheets", workbook.Sheets);
   
   
 
   
   
   
   
   
   
   
    setCols(jsondata[0]);
    setRows(jsondata[1]);
    var cellData=[];
 
 
 
 
 
  var merges= workbook.Sheets[sheetnames[0]]["!merges"];


  console.log("jasondata...", workbook.Sheets[sheetnames[0]]);


  const merge_config={};


  if (merges!==undefined){
    for (let i=0;i<merges.length;i++){
      var title = merges[i]['s']['r']+"_"+merges[i]['s']['c'];
      var rowsize = merges[i]['e']['r']-merges[i]['s']['r'];
      var col = merges[i]['e']['c']-merges[i]['s']['c'];
      var merge={
          r: merges[i]['s']['r'],
          c: merges[i]['s']['c'],
          rs: rowsize+1,
          cs: col+1
      }
      merge_config[title]=merge;
   
    }


  }




 
 
  var rows= workbook.Sheets[sheetnames[0]]["!rows"];
  const rowht={};


  if (rows!==undefined){


  for (let i=0;i<rows.length;i++){
    if (rows[i]!==undefined){
      var row=rows[i]["hpx"];
    rowht[i]=row;
    }
 
    /**/
 
  }
  }
  var cols= workbook.Sheets[sheetnames[0]]["!cols"];
  const colht={};
  if (cols!==undefined){
  for (let i=0;i<jsondata[0].length;i++){
    if (cols[i]!==undefined){
      var coll=cols[i]["wpx"];
      colht[i]=coll;
    }
 
    /**/
 
  }
  }
  itscell.config.columnlen = colht;
 
  itscell.config.rowlen = rowht;
  itscell.config.merge = merge_config;
 
 
  var calccchain=[];
  var borderinfo=[];
  for (let i = 0;i<jsondata.length;i++){
    var alphabet = "A";    
    for (let j=0;j<jsondata[i].length;j++){
      var styleData; var ht=1; var vt=1; var fs = 11;
      var fa = "General";


      if (shData[i][j]!==undefined){
       
        if (i===4 && j===1){
          console.log(i, ":", j, "   ", shData[i][j]);


        }


        styleData = shData[i][j].style;
       
        if (styleData.font!==undefined){
         // console.log("styleData", styleData.font.color);


        }
       
        if (styleData.numFmt !==undefined){
          fa = styleData.numFmt;
        }
        if (styleData.alignment!==undefined){


          switch (styleData.alignment.horizontal) {
            case "center":
              ht = 0;
              break;
            case "left":
              ht = 1;
              break;
            case "right":
              ht = 2;
              break;
     
            default:
              ht = 1;
   
          }
          switch (styleData.alignment.vertical) {
            case "middle":
              vt = 0;
              break;
            case "up":
              vt = 1;
              break;
            case "down":
              vt = 2;
              break;
     
            default:
              vt = 1;
   
          }
        }
     
        if (styleData.style!==undefined){
          fs = styleData.font.size;
        }
       
        var border = styleData.border;
       
        var font = styleData.font;


       
        var bd = {
          rangeType: "cell",
          value: {
            row_index:i,
            col_index:j,
           
          }
        };


        if (border!==undefined){
          if (border.left!==undefined){
            var left = {
              //style: styleData.border.left.style,
              //color: "#000"
              style: 1,
              color: "rgb(0, 0, 0)"
            }
            bd.value.l = left;
          }
          if (border.right!==undefined){
            var right = {
              style: 1,
              color: "rgb(0, 0, 0)"
            }
            bd.value.r = right;
          }
          if (border.top!==undefined){
            var top = {
              style: 1,
              color: "rgb(0, 0, 0)"
            }
            bd.value.t = top;
          }
          if (border.bottom!==undefined){
            var bottom = {
              style: 1,
              color: "rgb(0, 0, 0)"
            }
            bd.value.b = bottom;
          }
        }
       
        if (border!==undefined && Object.keys(border).length !== 0){
          borderinfo.push(bd);
        }
       
       
       
       
      }
     
     
      var bgColor = "#ffffff";
     
      var func = null;
      var mcval = null;
      var num = i+1;
      var tmp = alphabet + num.toString();
      var w="";
      var v="";


      if (workbook.Sheets[sheetnames[0]][tmp]!==undefined){
        var cellst = workbook.Sheets[sheetnames[0]][tmp]["s"]["patternType"];
        w = workbook.Sheets[sheetnames[0]][tmp]["w"];
        v= workbook.Sheets[sheetnames[0]][tmp]["v"];
        if (cellst !== "none"){
       
          var fgcolor = workbook.Sheets[sheetnames[0]][tmp]["s"]["fgColor"];


          if (fgcolor.theme!==undefined){
           
            if (fgcolor.theme==0 && fgcolor.tint!==undefined){
              bgColor = "rgb(0,0,0,"+Math.abs((fgcolor.tint))+")";
              console.log("bgColor..........", bgColor, fgcolor.tint, parseInt(fgcolor.tint));
            }
          }
          if (fgcolor.rgb!==undefined){
            console.log(fgcolor.rgb, styleData, workbook.Sheets[sheetnames[0]][tmp]["s"]);


            bgColor = "#"+fgcolor.rgb;


          }
        }
        if (workbook.Sheets[sheetnames[0]][tmp]["f"]!==undefined){
          func=workbook.Sheets[sheetnames[0]][tmp]["f"];
          func = "="+func;
          var fff = {
            r: i,
            c: j,
            id: "0",
            func:[true, i, func],
            color:"w",
            parent:null,
            children:{},
            times:0
           
          };
          calccchain.push(fff);
        }
      }
      var tt = (i+"_"+j);
      if (merge_config[tt]!==undefined){
        mcval = merge_config[tt];
      }
     
      let bl=0;
      let fc = "rgb(51,51,51)";
      if (font !==undefined){


        if (font.size!==undefined){
          fs = font.size;
        }
        if (font.bold !==undefined){
          bl=1;
        }
        if (font.color!==undefined){


          if (font.color.theme=== 0){
            fc="#ffffff";
          }
          if (font.color.argb !==undefined){


            fc = "#"+ font.color.argb.substring(2,font.color.argb.length);
            //console.log("i:",i," j:", j, "   ", font.color.argb, fc);
          }
        }
       
      }
      //console.log("row: ", i, " col: ", j , jsondata[i][j]);
      var rowss = {
        r: i,
        c: j,
        v: {
          ct: { fa: fa, t: "n" },
          v : v,
          bg: bgColor,
          //bg : 'rgb(0,0,0,0.24)',
          m: w,
          bl:bl,
          it:0,
          ff:0,
          fs:fs,
          fc:fc,
          ht:ht,
          vt:vt,
        }
      };
      if (func !==null){
        rowss.v.f = "="+workbook.Sheets[sheetnames[0]][tmp]["f"];
      }
     
      if (mcval!==null){
        rowss.v.mc = mcval;
      }
      cellData.push(rowss);
      alphabet = nextChar(alphabet);

    }
   
  }


    itscell.config.borderInfo =borderinfo;
    itscell.calcChain =calccchain;
    itscell.celldata =cellData;
    setAA(itscell);
    console.log("result -> ", itscell);
    setDiv(<div style={{width:"100%", height:"500px"}}><Workbook onOp={console.log} data={[itscell]} onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} /></div>) ;
    //forceUpdate();
 
 
  });
   
   
   
   
  }
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 


  const handleInputChange = (event) => {
    //setReason(event.target.value);
    
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
    setValues[name] = value;

  };

  window.addEventListener('message', (event) => {
    if (event.data["toList"] !== undefined){
      console.log('event.data["toList"]', event.data["toList"]);
      setList(event.data["toList"]);
    }
  });

  function saveEditor(){


    let content;
    if (editor_type==="excel"){
     



 
      var test = excelData[0];
    console.log("test! : ", test.data.length);
    const asdf = test.data;
    var celldataa = [];
    for (let i = 0; i<asdf.length;i++){
      for (let j=0;j<asdf[i].length;j++){
        //console.log("vavava", asdf[i][j]);
        if (asdf[i][j]!==null){
          var trows = {
            r: i,
            c: j,
            v: {
              ct: asdf[i][j].ct,
              v : asdf[i][j].v,
              bg: asdf[i][j].bg,
              m: asdf[i][j].m,
              bl:asdf[i][j].bl,
              it:asdf[i][j].it,
              ff:asdf[i][j].ff,
              fs:asdf[i][j].fs,
              fc:asdf[i][j].fc,
              ht:asdf[i][j].ht,
              vt:asdf[i][j].t,
            }
          };
          if (asdf[i][j].mc!==null){
            trows.v.mc = asdf[i][j].mc;
          }
          if (asdf[i][j].f!==null){
            trows.v.f = asdf[i][j].f;
          }
          celldataa.push(trows);
        }
        
      }
    }

    itscell.calcChain = test.calcChain;
    itscell.config = test.config;
    itscell.celldata = celldataa;

    
console.log("test! : ", itscell);
   
    
    content = JSON.stringify(itscell);














    }else{
      content = editorRef.current?.getInstance().getHTML();
    }
    axios.post('https://gw.thegmmedical.com:5003/api/save_data_editor',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_no : bgNo,
        editor_type : editor_type,
        content : content
      }, 
    })
    .then(function (response){
  
     
      alert("저장되었습니다.")
      //window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }


 
  let files=[];
  return (
    <div className="popup">
      <div className="popup_inner" style={{width:"1300px"}}>

        
        <h3 style={{textAlign:"left", marginLeft:"20px"}}>문서 초기 내용</h3>
        <div className="checkline">

        <div style={{width:"100%", display:"flex", justifyContent:"start"}}>
        <input type="file" id="input_dom_element" onChange={handleFileAsync}
        style={{border:"1px solid #ddd", marginLeft:"20px"}}/>
        </div>




        <div className="" style={{ width: "100%", margin: "20px auto", height:"500px", border:"1px solid #ddd"}}>
    {editor_type === "text" && (
        <Editor
        ref={editorRef} // DOM 선택용 useRef
        initialValue={initialHtml}
        previewStyle="vertical" // 미리보기 스타일 지정
        height="500px" // 에디터 창 높이
        initialEditType="wysiwyg" //
        toolbarItems={[
          // 툴바 옵션 설정
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock']
        ]}
        plugins={[colorSyntax]} 
        useCommandShortcut={false} // 키보드 입력 컨트롤 방지
        hooks={{
          addImageBlobHook: async (blob, callback) => {
            console.log(blob.size);
            if (blob.size > 5000000) {
              alert(
                "업로드에 실패했습니다. 이미지당 5MB까지 업로드할 수 있습니다."
              );
              return;
            }
            const formData = new FormData();
                    formData.append("img", blob);
                    
                    // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
                    // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...
                    await axios.post(
                      "https://gw.thegmmedical.com:5003/api/img",
                      formData
                    ).then(( res ) => {
                      console.log(res);
                      console.log(
                        "성공 시, 백엔드가 보내주는 데이터",
                        res.data.url
                      );
  
                      const IMG_URL = res.data.url;
                      setImgLink(IMG_URL);
                      // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
                      callback(IMG_URL, "test");
                      //alert(IMG_URL);
                      files.push(IMG_URL);
                      setArray(files);
                      //setArray((fileArray) => [...fileArray, IMG_URL]);
                      //alert("fileArray: ", files);
                      console.log(files);
                      var arrayLength = files.length;
                      //alert(arrayLength);
                      //alert("end");
                    
                    });
            //callback('https://gw.thegmmedical.com:5003/img/카레유.png', '카레유');
          }
        }}
      ></Editor>

    )}
    {editor_type === "excel" && (

    div
    )}
    </div>
      

        </div>
        <div className="buttons">
        <button onClick={()=>saveEditor()}>저장</button>
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>취소</button>
        </div>
        
      </div>
      
    </div>
  );
};

export default BoardEditorSetting;