import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import Sign from "../../components/Sign";


import {
  format,
  endOfMonth,
  differenceInDays,
  differenceInMonths,
  addMonths,

  
} from "date-fns";
import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

const initialValues = {
  company: "",
  subject: "",
  start_date:"",
  end_date: "",

};

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {

    const {model, mfr_num, mth_amount, remain_amount, sum, memo}= rowsData;
    return (
      <tr key={index}>
        <td>{index+1}</td>
       
        <td>
          {model}
           
        </td>
        <td>
          {mfr_num}
           
        </td>
        <td>
         {mth_amount}
           
        </td>
        <td>
          {remain_amount}
           
        </td>

        <td>
          {sum}
            
        </td>
        <td>
          {memo}
           
        </td>
     
      </tr>
    );
  });
}

const EndoscopeSign = ({data, line}) => {  
  


  const [select, setSelect] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

  const [user, setUser] = useState([]);
  const [name, setname] = useState("");
  const [userNo, setUserNo] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [absent, setAbsent] = useState([]);
 
  const { doc_no } = useParams();
  const [showSign, setShowSign] = useState(false);
 
  const [rows, initRow] = useState([]);




  useEffect(() => {
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/endoscope/'+data)
    .then(function (response){
      console.log("order.........",response.data[0]);
      if (response.data[0]!==undefined){

      setValues(response.data[0]);
      let order_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/endoscope/'+order_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({product_name:'', quantity:'',unit_price:'', price:'', memo:''});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    

    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
      console.log("user is", response.data);
    setUser(response.data[0]);
    var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
    setname(name);
    setUserNo(response.data[0].user_no);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/checkabsent')
    .then(function (response){
      console.log("absent is", response.data);
      var user_nos = response.data.map(function(item) {
        return item['user_no'];
      })
    console.log("absent...", user_nos);
    setAbsent(user_nos);
    
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

  }, []);




 

  const [cnt, setCnt] = useState(0);


 


 
 

  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });

  const togglePopup = () => {

    setShowSign(!showSign);
  
    
    //
  };
  const openSign = () =>  {
    if (data.secured === 1){
      let pw = prompt("결재 암호를 입력하세요", "");
      if (pw === user.e_sign_pw){
        togglePopup();
      }else{
        alert("암호가 일치하지 않습니다.")
      }
      
    }else{
      togglePopup();
    }
  }
  return (
    <div className="wrapper">
    
    {showSign && absent.includes(user.user_no)? (
  <Sign docNo= {data} orderNo = {orderNo} subject="내시경 검진장비 유지보수 견적서" decider = {user} userNo = {userNo} closePopup={() => togglePopup()} />
  ) : null}
{showSign && !absent.includes(user.user_no)? (
  <Sign docNo= {data} orderNo = {orderNo} subject="내시경 검진장비 유지보수 견적서" decider = {undefined} userNo = {userNo} closePopup={() => togglePopup()} />
  ) : null}
      
      <div className="outer_wrapper forms" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

          <div className="order_write endoscope" style={{padding:"50px", boxSizing:"border-box",margin:"20px auto"}}>
          
      
          <table style={{width:"100%", border:"none"}} className="headers-1">
            <tr>
              <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>내시경 검진장비 유지보수 견적서</td>
            </tr>

            
          </table>

        <div style={{display:"flex", justifyContent:"space-between"}}>
          <table className="table1" style={{width:"42%"}}>
          <tbody>
          <tr>
          <td colspan="3">
          {values.company}

          </td><td>귀하</td>
          </tr>
          <tr>
          <td colspan="3">
          {values.subject} 
          </td>

          </tr>
          <tr>
          <td colspan="3" style={{display:"flex"}}>[ {values.start_date}  ~ {values.end_date} ]</td>

          </tr>
          <tr>
       
          </tr>
          </tbody>
          </table>


          <table border="1" className="supply" style={{width:""}}>
          <tbody>
          <tr>
          <td rowspan="4" style={{textAlign:"center"}}>공<br></br>
          <br></br><br></br>급</td>
          <td>상호</td>
          <td colspan="3">생생메디칼</td>
          </tr>
          <tr>
          <td>사업자</td>
          <td>555-06-00797</td>
          <td>대표자</td>
          <td>임명한</td>
          </tr>
          <tr>
          <td>소재지</td>
          <td colspan="3">서울 서초구 서초대로 46길 19-7, 202호</td>
          </tr>
          <tr>
          <td>업태</td>
          <td>서비스업</td>
          <td>종목</td>
          <td>컨설팅</td>
          </tr>
          </tbody>
          </table>
        </div>
               
        
       

      <p>아래와 같이 견적합니다.</p>
        <table className="form_list endoscope_list" style={{borderCollapse: 'collapse', width:"100%"}}>
                 
          <thead>
            <tr>
              <th>구분</th>
              <th>모델명</th>
              <th>제조번호</th>
              <th>당월금액</th>
              <th>잔여금액</th>
              <th>대당합계</th>
              <th>비고</th>

            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
       
            />
           <tr style={{background:"#b4c6e7"}}><th>합계</th><td colSpan={4}></td>
           <td>{values.v10}원</td><td colSpan={2}></td></tr>
          </tbody>
        </table>
        <p>※ 월 대당단가 : {values.monthlyPrice}(건협 본부 '22년도 내시경 검진장비 유지보수 계약 단가)</p>
        <table border="1" className="endoscope-2" style={{width:"100%"}}>
          <tbody>
          <tr>
          <th colspan="3">당월 계약일수</th>
          </tr>
          <tr>
          <td rowSpan="2" style={{textAlign:"center"}}>당월<br></br>{values.start_date !== "" &&(<span>[{format(new Date(values.start_date),"MM")}월]</span>)}</td>
          <td>계약시작일</td>
          <td>{values.start_date !== "" && (<span>{format(new Date(values.start_date),"dd일")}&nbsp;&nbsp;{format(new Date(values.start_date),"MM월 dd일")}</span>)}</td>
          </tr>
          <tr>
          <td>계약일수</td>
          <td>{values.start_date !== "" && (<span>{cnt}일 
          &nbsp;&nbsp;{format(new Date(values.start_date),"MM월 dd일")} ~ {format(endOfMonth(new Date(values.start_date)), "dd일")}</span>)}</td>
          
          </tr>
       
          </tbody>
        </table>

        <table border="1" className="endoscope-2" style={{width:"100%", marginTop:"20px"}}>
          <tbody>
          <tr>
          <th colspan="4">견적 안내</th>
          </tr>
          <tr>
          <th colspan="2" style={{background:"#f2f2f2"}}>구분</th>
          <th style={{background:"#f2f2f2"}}>1대</th>
          <th style={{background:"#f2f2f2"}}>{values.cnt}대</th>
          </tr>
          <tr>
          <td rowSpan="2" style={{textAlign:"center"}}>당월<br></br>{values.start_date !== "" &&(<span>[{format(new Date(values.start_date),"MM")}월]</span>)}</td>
          <td>일일금액</td>
          <td>{values.v1}</td>
          <td>{values.v2}</td>
          </tr>
          <tr>
          <td>합계금액</td>
          <td>{values.v3}</td>
          <td>{values.v4}</td>
          </tr>

          <tr>
          <td rowSpan="2" style={{textAlign:"center"}}>잔여<br></br>
          {(values.end_date !== "" && values.start_date !== "") &&(<span>[{format(addMonths(new Date(values.start_date),1),"MM")}월 ~ {format(new Date(values.end_date),"MM")}월]</span>)}</td>
          <td>단가 (월)</td>
          <td>{values.v5} (월 대당단가)</td>
          <td>
          {values.v6}

          </td>
          </tr>
          <tr>
          <td>합계금액</td>
          <td>
          {(values.end_date !== "" && values.start_date !== "") &&(<span>{values.v7}</span>)}

          </td>          
          <td>
          {(values.end_date !== "" && values.start_date !== "") &&(<span>{values.v8}</span>)}
          </td>
          </tr>

          <tr>
          <th colspan="2" style={{background:"#f2f2f2"}}>구분</th>
          <th style={{background:"#f2f2f2"}}>대당합계</th>
          <th style={{background:"#f2f2f2"}}>합계</th>
          </tr>
          <tr>
          <td colSpan={2}>전체[{(values.end_date !== "" && values.start_date !== "") &&(<span>
            {format(new Date(values.start_date),"MM")}월 ~ {format(new Date(values.end_date),"MM")}월</span>)}]합계 금액</td>
          <td>{values.v9}</td>

          <td>
          {values.v10}
          </td>
          </tr>
          </tbody>
        </table>
        
      </div>

      </div>

      </div>
      
 
  
      </div>
  
  );
};

export default EndoscopeSign;

