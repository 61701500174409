import { useParams, Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import DocList from "../components/side/DocList";
import AutoCompleteLine from "../components/AutoComplete";
import axios from "axios";
import "./css/board.css";
import { GiHamburgerMenu } from "react-icons/gi";
import Pagination from "./Pagination";
//import hi from '../excel/93.json';

const DocsAdmin = () => {
  const [data, setData] = useState([]);


  const [subject, setSubject] = useState("");
  const [user, setUser] = useState("");

  const [name, setname] = useState("");
  const [boardN, setBoardN] = useState("");
  const [boardNum, setBoardNum] = useState();
  const [filterData, setfilterData] = useState([]);
  const [sidebar, setSidebar] = useState(false);

  const [formNo, setFormNo] = useState();
  const [isEmpty, setIsEmpty] = useState(true);
  const [isPage, setIsPage] = useState(false);
  const [adminForms, setAdminForms] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const offset = (page - 1) * limit;
 
  const onChangeSubject = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
  };
 
  useEffect(() => {
    axios.get("https://gw.thegmmedical.com:5003/api/getadmins").then((response) => {
      
    });

    axios.get("https://gw.thegmmedical.com:5003/api/check_form_admin").then((response) => {
      if (response.data.length>0){
        setAdminForms(response.data);
      }else{
        axios.get("https://gw.thegmmedical.com:5003/api/getadmins").then((res) => {
          setAdminForms(res.data);
        });

      }
      
    });

  }, []);


  const onSearch = (e) => {
    e.preventDefault();
    console.log("formNo:", formNo);
    axios
    .get("https://gw.thegmmedical.com:5003/api/get_admin_docs/"+formNo)
    .then(function (response) {
      console.log("hey result is!!!!!!!!!!!!", response.data);
      setData(response.data);
      var filtered = response.data;
      
      if (subject !==""){
       
        filtered = filtered.filter((itemList) => {
          return itemList.subject.toUpperCase().includes(subject.toUpperCase());
        }); 
        
      }
     
      if (user !==""){
        let userNo = parseInt(user);console.log("userno", userNo);
        filtered = filtered.filter((itemList) => {
          return itemList.user_no == userNo;
        });
      }

      setfilterData(filtered);
      if (filtered.length === 0) {
        setIsEmpty(true);
        setIsPage(false);
      } else {
        setIsEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
      //setLine(response.data[1]);
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      //always executed
    });


  };

  
  const handleFormChange = (e) => {
    //const name = e.target.name
    //const value = e.target.value
    const { name, value } = e.target;

    

    setFormNo(value);
  };
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  window.addEventListener('message', (event) => {
    if (event.data["selectedMember"] !== undefined){
      setUser(event.data["selectedMember"].user_no);
      setname(event.data["selectedMember"].name);
      console.log(event.data["selectedMember"]);
    }
    
  });
  function renderStatus(param) {
    switch(param) {
      case 1:
        return '미완료';
      case 2:
        return '보류';  
      case 3:
        return '반려';  
      case 4:
        return '완료';  
      default:
        return '미완료';
    }
  }

  
  return (
    <div className="wrapper doc_admin">

<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DocList
            changeBoard={(value) => {
              setfilterData(value);
              if (value.length === 0) {
                setIsEmpty(true);
              } else {
                setIsEmpty(false);
                if (value.length <= 10) {
                  setIsPage(false);
                } else {
                  setIsPage(true);
                }
              }
            }}
            changeBoardN={(value) => {
              setBoardN(value);
            }}
            changeBoardNum={(value) => {
              setBoardNum(value);
            }}
          />
        </div>
      )}
    
      <div className="content-wrap">
        <div className="outer_wrapper" style={{ justifyContent: "flex-start" }}>
          <div className="side-menu">
          <DocList
            changeBoard={(value) => {
              setfilterData(value);
              if (value.length === 0) {
                setIsEmpty(true);
              } else {
                setIsEmpty(false);
                if (value.length <= 10) {
                  setIsPage(false);
                } else {
                  setIsPage(true);
                }
              }
            }}
            changeBoardN={(value) => {
              setBoardN(value);
            }}
            changeBoardNum={(value) => {
              setBoardNum(value);
            }}
          />
          </div>
          <div className="board_wrap">
            <div className="main_content" style={{padding:"0", width:"100%"}}>
              <div className="board_wrapper">
                  <div className="board_container">
                    <div id="content" className="content">

                      <h3>양식별 문서 조회</h3>
                      <div className="board_top">
                        <div
                          className="btns"
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            width: "100%",
                            margin: "0 0 20px 0",
                            marginBottom: "20px",
                            alignItems:"start"
                          }}
                        >
                          <div className="field">
                              <p>결재양식</p>
                           

                           
                                <select
                                  name="form_group"
                                  className="formInput"
                                  value={formNo || ""}
                                  onChange={handleFormChange}
                                >
                                  <option value="all">양식 전체</option>
                                  {adminForms.map((element, index) => (
                                    <option value={element.form_no}>
                                      {element.form_name}
                                    </option>
                                  ))}
                                </select>
                                </div>

                                <div className="field">
                              <p>제목</p>
                                <input
                                  type="text"
                                  id="search_content"
                                  name="search_content"
                                  value={subject || ""}
                                  placeholder="검색어를 입력하세요"
                                  onChange={onChangeSubject}
                                />

                               </div>
                               <div className="field">
                              <p>기안자</p>
                                <input
                                  type="text"
                                  id="search_content"
                                  name="search_content"
                                  value={name || ""}
                                  placeholder="검색어를 입력하세요"
                                  readonly
                                />
                                  <button onClick={() => popupwindow("/people", "People", 800, 800)} alt=''>찾기</button>
                              </div>
                              <button
                                  className="btn"
                                  type="submit"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                            
                           
                        </div>
                      </div>

                      <div className="">
                        <table class="table table-hover bd-b">
                          <thead class="thead-dark">
                            <tr class="tx-center">
                              <th class="wd-80 xl-hidden">번호</th>
                              <th class="">상태</th>
                              <th class="wd-150 xl-hidden">양식명</th>
                              <th class="wd-120 xl-hidden">제목</th>
                              <th class="wd-80 xl-hidden">작성일자</th>
                              <th class="wd-80 xl-hidden">결재자</th>
                              <th class="wd-80 xl-hidden">결재일자</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isEmpty && (
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan={7} style={{ padding: "50px 0" }}>
                                  게시물이 없습니다.
                                </td>
                              </tr>
                            )}
                            {filterData
                              .slice(offset, offset + limit)
                              .map(
                                ({
                                  doc_no,
                                  status,
                                  form_name,
                                  subject,
                                  submit_date,
                                  last_approver,
                                  last_date,
                                }) => (
                                  <tr>
                                    <td>{doc_no}</td>

                                    <td>{renderStatus(status)}</td>
                                    <td>{form_name}</td>
                                    <td>
                                      <Link
                                        to={"/docview"}
                                        state={{ doc_no: doc_no }}
                                      >
                                        {subject}
                                      </Link>
                                    </td>
                                    <td>{submit_date}</td>
                                    <td>{last_approver}</td>
                                    <td>{last_date}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                        <table>
                          <colgroup>
                            <col className="m_dp_n table_no" />
                            <col className="" />
                            <col className="ta_l" />
                            <col className="m_dp_n table_date" />
                          </colgroup>
                       
                          <tbody></tbody>
                        </table>
                        <div
                          style={{ width: "100%", textAlign: "right" }}
                        ></div>
                      </div>
                      {isPage && (
                        <div className="paging">
                          <Pagination
                            postsPerPage={limit}
                            totalPosts={filterData.length}
                            page={page}
                            paginate={setPage}
                          ></Pagination>
                          {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
                        </div>
                      )}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocsAdmin;