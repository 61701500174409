


import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
function App() {


  const [users, setUsers] = useState([]);


  useEffect(() => {



  },[]);
 


  const [events, setEvents] = useState([]);



    

 const option = {
    method: 'POST',
    url: 'https://fcm.googleapis.com/fcm/send',
    json: {
      'to':
       'eQKny52u4j-JBCrpO6kOet:APA91bHX-klZRQrIegqm271AmjNm3hQBCiMytc_VrmOmz8JcvFrIUn03q7aKe-ozzj4V1aIbda0DnMHAy291uOt6nLILfbw3_VKGGbdCvZVVprRaoKT2qMqBoIVlJN0qSml1sO6INEf4',
      'notification': {
        'title': 'hello',
        'body': 'world!',
        'click_action': 'www.naver.com', //이 부분에 원하는 url을 넣습니다.
      }
    },
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Authorization': 'AIzaSyDYeQmcN0qoGo6NvKkQ76EKKs7R-tkAQXY'
    }
  }
  
  const mesg = 
  {
    "data": {
        "body": "test",
        "title": "test",
        "sound": "default"
    },
    "priority": "high",
    "time_to_live": 30,
    "delay_while_idle": true,
    "content_available": true,
    "to": "fYB_1HLTHGf80niPw37RvP:APA91bG7KZi1-cvYTTwvEIK6O56Ddhv9oLaR1Zg7fi5imra2ug5z2cbNymrdtFFIxvipiR6_exL8h7eeBwr5UhOtre3MIv2zoxA0iXwKGJ6oqUZlc9hdul5jmUIu1R__2gzUuG-ugsjI",
    "notification": {
        "body": "test",
        "title": "test",
        "sound": "default"
    }
}


  /*request(option, (err, res, body) => {
    if(err) console.log(err); //에러가 발생할 경우 에러를 출력
    else console.log(body); //제대로 요청이 되었을 경우 response의 데이터를 출력
  })*/
 
 
  function hi(){
   axios({
     method: 'post',
     url: 'https://fcm.googleapis.com/fcm/send',
     headers: {
       "Content-Type": "application/json",
       'Access-Control-Allow-Origin' : '*',
       "Authorization": ['key', 'AIzaSyDYeQmcN0qoGo6NvKkQ76EKKs7R-tkAQXY'].join('=')
     },
     params: mesg
   })


  }
  
  const test=()=>{
    Notification.requestPermission().then((permission) => {
      if (permission !== "granted") {
        // 푸시 거부됐을 때 처리할 내용
        console.log("hihi");
      } else {
        // 푸시 승인됐을 때 처리할 내용
        console.log("granted");
      }
    });
   /* const config = {


      apiKey: "AIzaSyA4deuG-QRLdxS5iYNAn6bV-Tp4KjepYX0",
      authDomain: "gm-medical-58c86.firebaseapp.com",
      projectId: "gm-medical-58c86",
      storageBucket: "gm-medical-58c86.appspot.com",
      messagingSenderId: "880056248369",
      appId: "1:880056248369:web:aa6bdb95acc074cbd90d10",
      measurementId: "G-ZYGWLVRYFT"
    
    };
    
    
    let messaging = null; 
    firebase.initializeApp(config);
    
    
  
    let permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted. Requesting for token.");

      if (firebase.messaging.isSupported()){ 
        messaging = firebase.messaging(); 
      let token = await messaging.getToken({
        vapidKey: "BHBOyeWYKkwGfvXolZnj3C7VMMGABSRDZKuQnMovXtad4Un4MRF6jFMY0LWaW2wbwQQLtEh9zVVjt_bgokjYhwQ",
      });
      const data = {
        token: token
      }
      axios.post('http://gw.thegmmedical.com:5003/api/updateToken', data).then(function (response){
      console.log("updated");
      })
      .catch(function (error){
        console.log(error);
      })
        .then(function (){
      });

      }
      // do something with the FCM token
    } else {
      console.log("Notification permission denied");
      // Handle denied permission
    }
  
    */
    
  }
 
 
  

    return (
      <div className="App">
        <header className="App-header">
            <button onClick={()=>hi()}>ddddd</button>

            <button onClick={()=>test()}>Notification Test</button>


        </header>
      </div>
    );
  }
 
  export default App;

