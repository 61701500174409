import React, { useState, useEffect, useRef } from "react";


import "./css/checkline.css";
import axios from "axios";
import {FaAddressBook} from "react-icons/fa";






const AddConfirmLine = ({ target, bgNo, order_no, closePopup }) => {

  const [statusList, setStatusList] = useState([]);

  const [showPopup, setPopup] = useState(false);
 
  const [values, setValues] = useState(false);

  const [auto, setAuto] = useState(false);

  const [list, setList] = useState([]);

  const [imgLink, setImgLink] = useState("");
  const [fileArray, setArray] = useState([]);
  const [checkers, setCheckers] = useState([]);
  const [next, setNext] = useState("");


  const togglePopup = () => {
    setPopup(!showPopup);
  };


  useEffect(() => {

    axios.get('https://gw.thegmmedical.com:5003/api/getusers')
  .then(function (response){
  
    let users = response.data;

    

    axios.post('https://gw.thegmmedical.com:5003/api/get_checkline_setting',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_no : bgNo,
        status : target,
        order_no : order_no
      }, 
    })
    .then(function (response){
      console.log("response.data", response.data);
      let chks = JSON.parse(response.data[0].checkers);
      //setCheckers());
      
    
      let userlist = [];
      for (let i=0;i<chks.length;i++){
        console.log("chks[i]", chks[i]);
        if (!isNaN(chks[i])){
          var index = users.map(function(e) { return e.user_no; }).indexOf(parseInt(chks[i]));
          userlist.push(users[index]);
        }else{
          userlist.push(chks[i]);
        }
      }
      

      setList([...userlist]);
      if (response.data[0].auto===1){
        setAuto(true);
      }else if (response.data[0].auto===0){
        setAuto(false);
      }
   
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });



  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
    
    
    
    axios.post('https://gw.thegmmedical.com:5003/api/get_boardadmin',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_no : bgNo
      },
    })
  .then(function (response){
  
    if (response.data[0].status !==null){
      setStatusList(JSON.parse(response.data[0].status));
      var index = JSON.parse(response.data[0].status).indexOf(target);
      setNext(index + 1);
    }else{
      setStatusList([]);
    }

  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
    
  }, []);
 

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 


  const handleInputChange = (event) => {
    //setReason(event.target.value);
    
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
    setValues[name] = value;

  };

  window.addEventListener('message', (event) => {
    if (event.data["toList"] !== undefined){
      
      console.log('event.data["toList"]', event.data["toList"]);

 
      setList(event.data["toList"]);

    }
    
  });

  let files = [];

  function saveCheckLine(){
    var user_nos = list.map(function(item) {
      return item['user_no'];
    });
      var index = statusList.indexOf(target);
      console.log("index,,,", user_nos);
    axios.post('https://gw.thegmmedical.com:5003/api/save_checkline',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_no : bgNo,
        status : target,
        check_line : JSON.stringify(user_nos),
        auto: auto,
        order_no : index
      }, 
    })
    .then(function (response){
  
     
      alert("저장되었습니다.")
      //window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }


  /*function getCheckLineSetting(no){
    if (!isNaN(no)){
      var index = checklines.map(function(e) { return e.no; }).indexOf(parseInt(no));
      let checkers = JSON.parse(checklines[index].checkers);

      for (let i=0;i<checkers.length;i++){
        let checker = checkers[i];

        var index = checklines.map(function(e) { return e.no; }).indexOf(parseInt(no));

      }

      return departments[index2].d_head;
    }else{
      return userno;
    }
  }*/

  return (
    <div className="popup">
      <div className="popup_inner" style={{width:"700px", height:"300px"}}>

        
        <h3 style={{textAlign:"left", marginLeft:"20px"}}>문서 확인라인</h3>
        <div className="checkline">

        <table className="table" style={{marginBottom:"20px"}}>
          <tr>
            <th>문서상태명</th>
            <td>{target}</td>
          </tr>

          <tr>
            <th>문서상태 변경값</th>
            <td>
            {next}

            </td>
          </tr>

          <tr>
            <th>확인 방법</th>
            <td>
      
      <label>     
        <input type="radio" value="false" name="auto" checked={auto === false} onChange={()=>setAuto(!auto)}/><span>확인 클릭</span>
      </label>

      <label>
        <input type="radio" value="true" name="auto" checked={auto === true} onChange={()=>setAuto(!auto)}/><span>자동 확인</span>
      </label>

            </td>
          </tr>

          <tr>
            <th>확인자 추가</th>
            <td>

              {list.map((ele,idx)=> (
                <span>{ele.name}; </span>
              ))}

              <button onClick={() => popupwindow("/checkline", "checkline", 800, 800)}> <FaAddressBook alt=''/> 찾기</button>
            

            </td>
          </tr>


        </table>


      

        </div>
        <div className="buttons">
        <button onClick={()=>saveCheckLine()}>저장</button>
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>취소</button>
        </div>
        
      </div>
      
    </div>
  );
};

export default AddConfirmLine;