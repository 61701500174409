
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";
import React, { useState, useEffect, useRef } from "react";
import Popup from "../components/Popup";
import axios from "axios";
import "./css/adminUsers.css";
import Pagination from "./Pagination";
import addDays from "date-fns/addDays";

const AdminPassword = () => {
    const [sidebar, setSidebar] = useState(false);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [showCreate, setCreate] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [address, setAddress] = useState("");
    const [name, setName] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [listId, setListId]= useState();
    const [updateRec, setUpdateRec] = useState(0);
    const [updateName, setUpdateName] = useState("");
    const [updateAddr, setUpdateAddr] = useState("");
    const [index, setIndex] = useState("");

    const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const offset = (page - 1) * limit;

    const togglePopup = () => {
        setPopup(!showPopup);
        

    };
    const toggleCreate = () => {
      setCreate(!showCreate);
      

  };
    const updateUser = () => {
      console.log("checklist...", checkList);
        for (var i=0;i<data.length;i++){
          if (data[i].email === checkList[0]){
            setSelected(data[i]);
          }
        }
      setPopup(!showPopup);
      
    };
    const deleteLog = () => {
      
      var result = window.confirm("모든 로그인 오류 기록을 삭제하고 차단 해제 하겠습니까?");
      if(result){
        axios.get('https://gw.thegmmedical.com:5003/api/del_pwerror')
        .then(function (response){
          console.log(response);
          window.location.reload(true);
          
          
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const stopUser = () => {
      console.log(checkList);
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 삭제하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/stopUsers', data)
        .then(function (response){
          console.log(response);
     
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const activeUsers = () => {

      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked');
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log("안녕", array);

      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }

      var result = window.confirm("선택된 사용자를 차단 해제합니다");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/unblock_users',data)
        .then(function (response){
          console.log(response);
          alert("차단이 해제되었습니다.");
          window.location.reload(true);
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
  
    useEffect(() => {
       
      fetch("https://gw.thegmmedical.com:5003/api/get_mailing/", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("json", json);
        setData(json);
        setFilterData(json);
        
      })
      .catch(() => {});
        
     
        
    
      
      }, []);
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('check');
        const all 
             = document.getElementsByName('checkall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }
      function selectAllRec()  {
        const checkboxes 
             = document.getElementsByName('rec');
        const all 
             = document.getElementsByName('recall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...checkList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(checkList.filter((checkedId) => checkedId !== id));
        }
        
     }

     const deleteLists = () => {
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

      checkboxes = checkboxes.filter( v => v.name === 'check' )
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 삭제하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/del_mailing_lists', data)
        .then(function (response){
          console.log(response);
          alert("삭제되었습니다.");
          //setFilterData(recipients.filter((ele)=>{ele}))
     
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const deleteRecs = () => {
      var array = [];
      var checkbox = document.querySelectorAll('input[type=checkbox]:checked');
      console.log("checkboxes", checkbox);
      //let checkboxes = checkbox.filter( v => v.name === 'rec' );

      const checkboxes = Array.prototype.filter.call(
        checkbox,
        (container) => container.name === "rec"
      );

      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 삭제하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/del_mailing_recs', data)
        .then(function (response){
          console.log(response);
          alert("삭제되었습니다.");
          //setFilterData(recipients.filter((ele)=>{ele}))
     
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };

    const updateRecs = () => {
    

    };
     const addMailing  = () => {
      const data ={
        address : address,
        name : name,
        enabled : 1
      }
      axios.post('https://gw.thegmmedical.com:5003/api/save_mailing', data)
      .then(function (response){
        console.log(response);
        alert("추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
     };

    const deleteMailing = (id) =>{
      axios.get('https://gw.thegmmedical.com:5003/api/delete_mailing/'+id)
      .then(function (response){
        console.log(response);
        alert("삭제되었습니다.");
        window.location.reload(true);

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }

    const getRecipients = (id) =>{
      setListId(id);
      axios.get('https://gw.thegmmedical.com:5003/api/get_recipients/'+id)
      .then(function (response){
        console.log(response);
        setRecipients(response.data);

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }

    const deleteRecipient = (email, id) =>{
      const data = {
        email: email,
        id: id
      }
      axios.post('https://gw.thegmmedical.com:5003/api/del_recipients', data)
      .then(function (response){
        console.log(response);
        alert("삭제되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }

    function popupwindow(url, title, w, h) {
      var left = (window.screen.width/2)-(w/2);
      var top = (window.screen.height/2)-(h/2);
      return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
    } 

    
    window.addEventListener('message',  (event) => {
      if (event.data["selectedMembers"] !== undefined){
        
        var temp = event.data["selectedMembers"];
        var tempArray = recipients;
        for (let i=0;i<temp.length;i++){
          if (!tempArray.includes(temp[i])){
            
            tempArray = [...tempArray, temp[i]];
          }
        }
        /*const map = new Map(
          tempArray.map(obj => [obj.user_no, obj])
        );*/
  
        const deduplicatedArr = [...tempArray.values()];
        let emails = tempArray.map(function(value) {
          return value.email;
      });

     
        const data = {
          emails: emails,
          id: listId
        }
        console.log("emails....", emails);

        setRecipients(deduplicatedArr);

        /*axios.post('https://gw.thegmmedical.com:5003/api/add_recipients', data)
        .then(function (response){
          console.log(response);
          alert("추가되었습니다.");
          setRecipients(deduplicatedArr);
          return false;
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });*/
        

      }
   
    });

    const addRecipients = () =>{

      let emails = recipients.map(function(value) {
        return value.email;
    });

      const data = {
        emails: emails,
        id: listId
      }
      axios.post('https://gw.thegmmedical.com:5003/api/add_recipients', data)
        .then(function (response){
          console.log(response);
          alert("저장되었습니다.");
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
    }


    const handleKeyPressAddr = (e) =>{
      if (e.key === "Enter"){
        const data ={
          id: updateRec,
          addr : updateAddr
        }
        axios.post('https://gw.thegmmedical.com:5003/api/mailing_updateAddr', data)
        .then(function (response){
          console.log(response);
          alert("수정되었습니다.");
          let tmp = filterData;
          tmp[index].distributionlistaddress = updateAddr;
          setFilterData([...tmp]);
          setUpdateAddr(false);
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    }

    const handleKeyPressName = (e) =>{
      if (e.key === "Enter"){
        const data ={
          id: updateRec,
          name : updateName
        }
        axios.post('https://gw.thegmmedical.com:5003/api/mailing_updateName', data)
        .then(function (response){
          console.log(response);
          alert("수정되었습니다.");
          let tmp = filterData;
          tmp[index].distributionlistname = updateName;
          setFilterData([...tmp]);
          setUpdateName(false);
          

  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    }
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents pw" style={{maxWidth:"1000px"}}>
        <div className="header_txt">
        <h3>메일링 리스트</h3>
        </div>
        <div className="" style={{background:"white", padding:"20px"}}>

            <table className="form_write">
                <tr>
                <th className="table_title2">메일링 이름</th><td className="table_list2_left">
                <div className="input-group">
                  <input type="text" style={{border:"1px solid #ddd"}} onChange={(e)=>setName(e.target.value)}></input>
                </div></td>                   
                </tr>
                <tr>
                <th className="table_title2">메일링 주소</th>
                <td className="table_list2_left">
                  <div className="input-group">
                  <input type="text" style={{border:"1px solid #ddd", marginRight:"10px"}} onChange={(e)=>setAddress(e.target.value)}></input>
                <span>@thegmmedical.com</span>  

                <button onClick={()=>addMailing()} style={{marginLeft:"10px"}}>+</button>

                  </div>
                </td>                   
                </tr>
            </table>
            <div className="btns">
            </div>
            
        </div>

        <div className="main_contents_wrapper">
            <div className="btns" style={{display:"flex", justifyContent:"space-between"}}>
           

              <div className="right">
                
                <button onClick={() => deleteLists()}>삭제</button> 

            </div>
            </div>

 
<table class="responsive">
  <thead>
    <tr>
    <th className="check" style={{width:"20px"}}>
    <input type='checkbox'
       name='checkall' 
       value='selectall'
       onClick={selectAll}
      />

    </th>
      <th>메일링 이름</th>
      <th>메일링 주소</th>
      <th>기능</th>
     

    </tr>
  </thead>
  <tbody>
  
  { filterData.slice(offset, offset + limit).map((element, index) => 
    <tr key={index} onClick={()=>getRecipients(element.distributionlistid)} style={{cursor:"pointer"}}>
        <td>
        <input type='checkbox'
        name='check' 
        value={element.distributionlistid}
        onChange={(e) => {onChangeEach(e, element.distributionlistid); }} checked={checkList.includes(element.distributionlistid)}/> 
        </td>

    <td data-label="이름">
    
    {(updateRec == element.distributionlistid  && updateName!==false)?
      <input type="text" style={{border:"1px solid #ddd"}} value = {updateName} onChange={(e)=>setUpdateName(e.target.value)}
      onKeyDown={handleKeyPressName}></input>
    : <span>{element.distributionlistname}</span>}
    </td>
    <td data-label="주소">
    {(updateRec == element.distributionlistid && updateAddr!==false) ?
      <div>
        <input type="text" style={{border:"1px solid #ddd"}} value = {updateAddr} onChange={(e)=>setUpdateAddr(e.target.value)}
      onKeyDown={handleKeyPressAddr}
      ></input> 
        <span>@thegmmedical.com</span>  </div>
    : <span>{element.distributionlistaddress}</span>}
    
    </td>
    <td data-label="기능">
    <button onClick={() => {setUpdateRec(element.distributionlistid);setUpdateName(element.distributionlistname); setUpdateAddr(element.distributionlistaddress.slice(0, -17));
    setIndex(offset+index)}}>수정</button> 

      
      <button onClick={()=>deleteMailing(element.distributionlistid)}
      style={{marginLeft:"5px"}}>삭제</button></td>

    </tr>) }
   
    {filterData.length === 0 && (<tr><td colSpan={11} style={{padding:"50px 0", textAlign:"center"}}>리스트가 없습니다.</td></tr>)}
  </tbody>
</table>



        {data.length >10 && (
        <Pagination
                        postsPerPage={limit}
                        totalPosts={data.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>

        )}
     </div>



     {listId!==undefined && (

     
<div className="main_contents_wrapper">
            <div className="btns" style={{display:"flex", justifyContent:"space-between"}}>
           

              <div className="left">

                <button onClick={() => deleteRecs()}>삭제</button> 

            </div>

            <div className="right">
            <button onClick={() => popupwindow("/select", "결재라인", 800, 625)} alt=''>추가</button>

            </div>

            </div>

<table class="responsive">
  <thead>
    <tr>
    <th className="check" style={{width:"20px"}}>
    <input type='checkbox'
       name='recall' 
       value='selectall'
       onClick={selectAllRec}
      />

    </th>
      <th>이름</th>
      <th>직급</th>
      <th>부서</th>
      <th>메일주소</th>
      <th>기능</th>
     

    </tr>
  </thead>
  <tbody>
  
  { recipients.slice(offset, offset + limit).map((element, index) => 
    <tr key={index}>
        <td>
        <input type='checkbox'
        name='rec' 
        value={element.email}
        onChange={(e) => {onChangeEach(e, element.email); }} checked={checkList.includes(element.email)}/> 
        </td>

      
    <td data-label="이름">{element.name}</td>
    <td data-label="직급">{element.job_name}</td>
    <td data-label="부서">{element.d_name}</td>
    <td data-label="주소">{element.email}</td>

    <td data-label="기능"><button onClick={()=>deleteRecipient(element.email, element.id)}>삭제</button></td>
   
    

    </tr>) }
   
    {recipients.length === 0 && (<tr><td colSpan={11} style={{padding:"50px 0", textAlign:"center"}}>수신자가 없습니다.</td></tr>)}
  </tbody>
</table>

<div className="btns" style={{justifyContent:"center"}}>
  <button className="btn" onClick={()=>addRecipients()}>저장</button>

</div>

        </div>

)}
    </div>
    </div>
  );
};

export default AdminPassword;
