
import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {FaAddressBook} from "react-icons/fa";
import "../css/setting.css";
const DefaultSetting = ({navigation}) => {


  useEffect(() => {
    async function showList(){
      
    await fetch("http://gw.thegmmedical.com:5003/api/getspam", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      if (json.length>0){
        if (json[0].keyword !== null){
          let kw = JSON.parse(json[0].keyword);setKeywords(kw);
        }
        if (json[0].email_addr !== null){
          let adr = JSON.parse(json[0].email_addr);setAddrs(adr);
        }
        if (json[0].email_domain !== null){
          let dom = JSON.parse(json[0].email_domain);setDomains(dom);
        }
    
      }
      //console.log(boxes.boxes);
      //console.log(boxes.boxes);
    })
    .catch(() => {});
  }
  if (navigation === "spam"){

  showList();
  }
    /*fetch("http://gw.thegmmedical.com:5003/getbox", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      setBoxes(json);
    })
    .catch(() => {});*/

    

  
  }, [navigation]);
  
  const [keyword, setKeyword]=useState("");
  const [keywords, setKeywords]=useState([]);
  

  const generateId_kw = () => {
    const highestId = Math.max.apply(Math, keywords.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_kw() {
    if (keyword !== '') {
      const item = { id: generateId_kw(), text: keyword };
      const tempArray = [...keywords, item];
      setKeywords(tempArray);
    }
    setKeyword('');
  }

  function addkeywords() {
    createNewToDoItem_kw();
  }
  const deleteItem_kw = id => {
    setKeywords(keywords.filter(item => item.id !== id));
  };


  const display_keyword = keywords.map(item => (
    <div className="list"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_kw(item.id)}>X</span>

    </div>
  ));



  const [addr, setAddr]=useState("");
  const [addrs, setAddrs]=useState([]);
  

  const generateId_addr = () => {
    const highestId = Math.max.apply(Math, addrs.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_addr() {
    if (addr !== '') {
      const item = { id: generateId_addr(), text: addr };
      const tempArray = [...addrs, item];
      setAddrs(tempArray);
    }
    setAddr('');
  }

  function addaddrs() {
    createNewToDoItem_addr();
  }
  const deleteItem_addr = id => {
    setAddrs(addrs.filter(item => item.id !== id));
  };


  const display_addrs = addrs.map(item => (
    <div className="list"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_addr(item.id)}>X</span>

    </div>
  ));



  const [domain, setDomain]=useState("");
  const [domains, setDomains]=useState([]);
  

  const generateId_domain = () => {
    const highestId = Math.max.apply(Math, domains.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_domain() {
    if (domain !== '') {
      const item = { id: generateId_domain(), text: domain };
      const tempArray = [...domains, item];
      setDomains(tempArray);
    }
    setDomain('');
  }

  function addDomain() {
    createNewToDoItem_domain();
  }
  const deleteItem_domain = id => {
    setDomains(domains.filter(item => item.id !== id));
  };


  const display_domain = domains.map(item => (
    <div className="list"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_domain(item.id)}>X</span>

    </div>
  ));

  function submit_filter(){
    console.log(keywords);
    
    const data = {
      keywords: JSON.stringify(keywords),
      domains : JSON.stringify(domains),
      addrs : JSON.stringify(addrs),
      user_id : 1
    }

    axios.post('http://gw.thegmmedical.com:5003/api/addspam', data)
    .then(function (response){
      console.log(response);
      alert("저장되었습니다.");
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };

  return (
    <div className="wrapper">
        <div className="sort-setting">
        <table style={{width:"100%"}}>
            <tr>
                    <thead>스팸 키워드</thead>
                    <td style={{flexDirection:"column"}}>
                    <div className="input-group setting-input">
                    <input type="text" id="spam_keyword_input_1" className="spam-input" value={keyword}
                    onChange={e => {
                      setKeyword(e.currentTarget.value);
                    }}/>
                    <div className="add-btn" onClick={addkeywords}>+</div>
                    </div>
                    
                    <div class="spam_list_wrap"><ul class="spam_list">
                      {/*<li class="spam_item"><div class="button_checkbox_wrap">
                      <input type="checkbox" id="spam_item_ㅁㄴㄹ" class="button_checkbox blind" readonly=""/>
                      <label for="spam_item_ㅁㄴㄹ">ㅁㄴㄹ</label></div></li>*/}
                      {display_keyword}
                        </ul>
                    </div>
                    
                    </td>
                </tr>
                <tr>
                    <thead>메일 주소</thead>
                    <td style={{flexDirection:"column"}}>
                    <div className="input-group setting-input">
                    <input type="text" id="spam_keyword_input_1" className="spam-input" value={addr}
                    onChange={e => {
                      setAddr(e.currentTarget.value);
                    }}/>
                    <div className="add-btn" onClick={addaddrs}>+</div>
                    </div>
                    
                    <div class="spam_list_wrap"><ul class="spam_list">
                      {/*<li class="spam_item"><div class="button_checkbox_wrap">
                      <input type="checkbox" id="spam_item_ㅁㄴㄹ" class="button_checkbox blind" readonly=""/>
                      <label for="spam_item_ㅁㄴㄹ">ㅁㄴㄹ</label></div></li>*/}
                      {display_addrs}
                        </ul>
                    </div>
                    
                    </td>
                </tr>

                <tr>
                    <thead>

                    메일 도메인</thead>
                    <td style={{flexDirection:"column"}}>
                    <div className="input-group setting-input">
                    <input type="text" id="spam_keyword_input_1" className="spam-input" value={domain}
                    onChange={e => {
                      setDomain(e.currentTarget.value);
                    }}/>
                    <div className="add-btn" onClick={addDomain}>+</div>
                    </div>
                    
                    <div class="spam_list_wrap"><ul class="spam_list">
                      {/*<li class="spam_item"><div class="button_checkbox_wrap">
                      <input type="checkbox" id="spam_item_ㅁㄴㄹ" class="button_checkbox blind" readonly=""/>
                      <label for="spam_item_ㅁㄴㄹ">ㅁㄴㄹ</label></div></li>*/}
                      {display_domain}
                        </ul>
                    </div>
                    
                    </td>
                </tr>

              
              
               
            </table>


            <div className="btns" style={{display:"flex",justifyContent:"center", width:"auto", margin:"20px"}}>

            <button className="button" onClick={()=> submit_filter()} >저장</button>
            </div>
      
      </div>

    </div>

  );
};

export default DefaultSetting;
