import { NavLink, Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import WorkList from "../components/side/WorkList";
import axios from "axios";
import {GiHamburgerMenu} from "react-icons/gi";
import { HiPencilAlt } from "react-icons/hi";
import { BsPin } from "react-icons/bs";

import Pagination from "./Pagination";
//import hi from '../excel/93.json';

const initialValues = {
  pic1: 0,
  pic2: 0,
  pic3: 0,
  pic4: 0,
  pic5: 0,
  sent1: 0,
  sent2: 0,
  sent3: 0,
  sent4: 0,
  sent5: 0,
  cc1: 0,
  cc2: 0,
  cc3: 0,
  cc4: 0,
  cc5: 0,
};
const Board = () => {
  const [data, setData] = useState([]);
  const [status, setStatus]=useState("");
  const [isActive, setActive] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [pic1, setPic1] = useState([]);
  const [pic2, setPic2] = useState([]);
  const [pic3, setPic3] = useState([]);
  const [pic4, setPic4] = useState([]);
  const [pic5, setPic5] = useState([]);
  const [sidebar, setSidebar] = useState(false);

  const [cc1, setCc1] = useState([]);
  const [cc2, setCc2] = useState([]);
  const [cc3, setCc3] = useState([]);
  const [cc4, setCc4] = useState([]);
  const [cc5, setCc5] = useState([]);

  const [sent1, setSent1] = useState([]);
  const [sent2, setSent2] = useState([]);
  const [sent3, setSent3] = useState([]);
  const [sent4, setSent4] = useState([]);
  const [sent5, setSent5] = useState([]);

  const [parent, setParent] = useState("");
  const [name, setName] = useState("");
  const [num, setNum] = useState(0);
  const [lev, setLev] = useState(0);
  const [selected, setSelected] = useState();
  const [search, setSearch] = useState(null);
  const [boardN, setBoardN] = useState("");
  const [boardNum, setBoardNum] = useState();
  const [options, setOption] = useState("subject");
  const [filterData, setfilterData] = useState([]);
  const [category, setCategory] = useState("register_date");
  const [upDown, setUpDown] = useState(true);

  const [notice, setNotice] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [isPage, setIsPage] = useState(false);
  const [isloggedIn, setLoggedIn] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const offset = (page - 1) * limit;
  const { state } = useLocation();
  const toggleText = () => {
    setActive(!isActive);
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  function compareOnKey(key, updown) {
    return function(a, b) {
      const eleA = a[key]; // ignore upper and lowercase
      const eleB = b[key]; // ignore upper and lowercase
      if (updown === true){
        if (eleA > eleB) {
          return -1;
        }
        if (eleA < eleB) {
          return 1;
        }
      }else{
        if (eleA < eleB) {
          return -1;
        }
        if (eleA > eleB) {
          return 1;
        }
      }
    
      // names must be equal
      return 0;
    };
}

 
  useEffect(() => {
    if (state === null || state === undefined || state.boardName==="" || state.type==="") {
      axios.get("https://gw.thegmmedical.com:5003/api/getwork").then((response) => {
        //setNotice(response.data);
        let works = response.data;

        works.sort(compareOnKey("register_date", true));
        console.log(works);
        setData(works);
        setfilterData(works);
        if (response.data.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (response.data.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }
      });
    } else {
      if (state.board.length <= 10) {
        setIsPage(false);
      } else {
        setIsPage(true);
      }
      setfilterData(state.board);
      setData(state.board);
      //setNotice(state.notice);
    }
    axios.get("https://gw.thegmmedical.com:5003/api/getuser").then((response) => {
      var user = response.data[0];
      axios.get("https://gw.thegmmedical.com:5003/api/getwork_num").then((response) => {
        console.log("work num.....", response.data);
        var nums = response.data;
        let pic = nums.filter((ele) => ele.PIC == user.user_no);
        setPic1(pic.filter((ele) => ele.status === "계획"));
        setPic2(pic.filter((ele) => ele.status === "진행"));
        setPic3(pic.filter((ele) => ele.status === "지연"));
        setPic4(pic.filter((ele) => ele.status === "완료"));
        setPic5(pic.filter((ele) => ele.status === "취소"));

        var sent = nums.filter((ele) => ele.user_no == user.user_no);

        setSent1(sent.filter((ele) => ele.status === "계획"));
        setSent2(sent.filter((ele) => ele.status === "진행"));
        setSent3(sent.filter((ele) => ele.status === "지연"));
        setSent4(sent.filter((ele) => ele.status === "완료"));
        setSent5(sent.filter((ele) => ele.status === "취소"));

        var cc = nums.filter(
          (ele) =>
            ele.workers !== null &&
            ele.workers.includes("(" + user.user_no + ")")
        );

        let cc1 = cc.filter((ele) => ele.status === "계획");
        let cc2 = cc.filter((ele) => ele.status === "진행");
        let cc3 = cc.filter((ele) => ele.status === "지연");
        let cc4 = cc.filter((ele) => ele.status === "완료");
        let cc5 = cc.filter((ele) => ele.status === "취소");
        setValues({
          ...values,
          cc1: cc1,
          cc2: cc2,
          cc3: cc3,
          cc4: cc4,
          cc5: cc5,
        });
      });
    });
  }, []);

  const deleteDepartment = () => {
    const data = {
      name: name,
      parent: parent,
      num: num,
      level: lev,
    };
    axios
      .post("https://gw.thegmmedical.com:5003/api/delete_department", data)
      .then(function (response) {
        window.location.reload(true);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        //always executed
      });
  };

  const assign = () => {
    if (selected.name === undefined) {
    }
    var array = [];
    var checkboxes = document.querySelectorAll("input[type=checkbox]:checked");

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value !== "selectall") {
        array.push(checkboxes[i].value);
      }
    }
    if (array[0].value === "selectall") {
      array.shift();
    }
    const data = {
      nos: array,
      folderName: selected.name,
      folderNo: selected.num,
    };

    var result = window.confirm(
      "선택된 사용자를 [" + selected.name + "] 부서에 지정합니다."
    );
    if (result) {
      axios
        .post("https://gw.thegmmedical.com:5003/api/assign", data)
        .then(function (response) {
          window.location.reload(true);
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          //always executed
        });
    } else {
    }
  };
  const onNameClick = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();
    var notice_data = [];
    var board_data = [];
    const {
      // internal data
      path,
      name,
      num,
      level,
      type,
      isOpen,
      children,
      // custom data
    } = nodeData;
    setName(name);
    setNum(num);
    if (type !== "group") {
      var dropdown = document.getElementsByClassName("dropdown_div");
      var aTags = dropdown[0].getElementsByTagName("span");

      var searchText;
      var found;

      searchText = name;

      for (var i = 0; i < aTags.length; i++) {
        if (aTags[i].textContent === searchText) {
          found = aTags[i];
          break;
        }
      }
      const boxes = document.querySelectorAll(".editableNameContainer");

      boxes.forEach((box) => {
        box.style.backgroundColor = "transparent";
      });
      found.style.backgroundColor = "#BEFDFD";
      console.log(name);
    }
  };
  const addBookmark = () => {
    var result = window.confirm("즐겨찾기 게시판에 추가하시겠습니까?");
    if (result) {
      const data = {
        name: boardN,
        num: boardNum,
        table: "board",
      };
      console.log(data);
      axios
        .post("https://gw.thegmmedical.com:5003/api/add_bookmark", data)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          //always executed
        });
    }
  };

  const selectHandle = (e) => {
    console.log("changed", e.target.value);

    setOption(e.target.value);
  };
  const onSearch = (e) => {
    e.preventDefault();

    if (options === "subject") {
      const filtered = data.filter((itemList) => {
        return itemList.subject.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    } else if (options === "all") {
      const filtered = data.filter((itemList) => {
        var tmp = "";
        tmp += itemList.content;
        tmp += itemList.subject;
        return tmp.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    } else if (options === "content") {
      const filtered = data.filter((itemList) => {
        return itemList.content.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }
  };
  const addBookmarkPage = () => {
    var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
    if(result){

      const data ={
        name: '',
        num : '',
        link : window.location.pathname,
        bookmark : "업무관리"
      }
      
      console.log(window.location.pathname)
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
      .then(function (response){
        console.log(response);
        alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };
  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <WorkList/>
        </div>
      )}
      <div className="content-wrap">
        <div className="outer_wrapper" style={{ justifyContent: "flex-start" }}>
          <div className="side-menu">
          <WorkList
            changeBoard={(value) => {
              setfilterData(value);
              if (value.length === 0) {
                setEmpty(true);
              } else {
                setEmpty(false);
                if (value.length <= 10) {
                  setIsPage(false);
                } else {
                  setIsPage(true);
                }
              }
            }}
            changeNotice={(value) => {
              setNotice(value);
            }}
            changeBoardN={(value) => {
              setBoardN(value);
            }}
            changeBoardNum={(value) => {
              setBoardNum(value);
            }}
          />
          </div>
            <div className="work main_content">
              <div className="board_wrapper">
              <div className="btns" style={{width:"100%", justifyContent:"start", alignItems:"center"}}>
<div className="btn" style={{display:"flex", alignItem:"center", height:"15px",padding:"5px", marginRight:"7px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>
<h3>업무관리 메인</h3>
</div>
                <h3>업무 요약 </h3>
                <table class="GeneratedTable">
                  <thead>
                    <tr>
                      <th>분류</th>
                      <th>계획</th>
                      <th>진행</th>
                      <th>지연</th>
                      <th>완료</th>
                      <th>취소</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>작업책임자</td>
                      <td>{pic1.length}</td>
                      <td>{pic2.length}</td>
                      <td>{pic3.length}</td>
                      <td>{pic4.length}</td>
                      <td>{pic5.length}</td>
                    </tr>
                    <tr>
                      <td>작업자/참조자</td>
                      <td>{values.cc1.length}</td>
                      <td>{values.cc2.length}</td>
                      <td>{values.cc3.length}</td>
                      <td>{values.cc4.length}</td>
                      <td>{values.cc5.length}</td>
                    </tr>
                    <tr>
                      <td>보낸업무함</td>
                      <td>{sent1.length}</td>
                      <td>{sent2.length}</td>
                      <td>{sent3.length}</td>
                      <td>{sent4.length}</td>
                      <td>{sent5.length}</td>
                    </tr>
                  </tbody>
                </table>

                <form name="form1" id="form1" method="post">
                  <div className="board_container">
                    <div id="content" className="content">
                      <div className="board_top">
                     

                        <div className="status_filter" style={{display:"flex"}}>

<ul>
  <li>
    <input type="radio" value="plan" name="amount" checked={status === "plan"} onChange={(e)=>{setStatus(e.currentTarget.value); setfilterData(data.filter(ele=>ele.status==="계획"))}} />
    <label for="plan">계획</label>
  </li>
  <li>
  <input type="radio" value="progress" name="amount" checked={status === "progress"} onChange={(e)=>{setStatus(e.currentTarget.value); setfilterData(data.filter(ele=>ele.status==="진행"))}} />
    <label for="progress">진행</label>
  </li>
  <li>
  <input type="radio" value="postpone" name="amount" checked={status === "postpone"} onChange={(e)=>{setStatus(e.currentTarget.value); setfilterData(data.filter(ele=>ele.status==="지연"))}}  />
    <label for="postpone">지연</label>
  </li>
  <li>
  <input type="radio" value="complete" name="amount" checked={status === "complete"} onChange={(e)=>{setStatus(e.currentTarget.value); setfilterData(data.filter(ele=>ele.status==="완료"))}}  />
    <label for="complete">완료</label>
  </li>
  <li>
  <input type="radio" value="cancel" name="amount" checked={status === "cancel"} onChange={(e)=>{setStatus(e.currentTarget.value); setfilterData(data.filter(ele=>ele.status==="취소"))}} />
    <label for="cancel">취소</label>
  </li>
  <li>
  <input type="radio" value="all" name="amount" checked={status === "all"} onChange={(e)=>{setStatus(e.currentTarget.value); setfilterData(data)}} />
    <label for="all">모두 보기</label>

  </li>
  </ul>
                        </div>

                        <div className="bt_srch" style={{display:"flex"}}>
                          
                          <div className="bts_slct">
                            <select
                              id="u_shcate"
                              className="select"
                              value={options}
                              onChange={selectHandle}
                            >
                              <option value="all">제목+내용</option>
                              <option value="subject">제목</option>
                              <option value="content">내용</option>
                            </select>
                          </div>
                          <div className="bt_input">
                            <input
                              type="text"
                              id="search_content"
                              name="search_content"
                              value={search || ""}
                              placeholder="검색어를 입력하세요"
                              onChange={onChangeSearch}
                            />
                          </div>

                          <button
                            type="submit"
                            className="search_btn"
                            value="검색"
                            onClick={onSearch}
                          >
                            검색
                          </button>
                        </div>
                      </div>

                      <div className="work_list">
                        <table class="table table-hover bd-b">
                          <thead class="thead-dark">
                            <tr class="tx-center">
                              <th class="wd-80 xl-hidden">
                              <span onClick={()=>{if (category !=="status"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("status"); filterData.sort(compareOnKey("status", !upDown));}}>상태</span>

                              </th>
                              <th class="">
                              <span onClick={()=>{if (category !=="subject"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("subject"); filterData.sort(compareOnKey("subject", !upDown));}}>업무명</span>

                              </th>
                              <th class="wd-150 xl-hidden">
                              <span onClick={()=>{if (category !=="no"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("no"); filterData.sort(compareOnKey("no", !upDown));}}>번호</span>

                              </th>
                              <th class="wd-120 xl-hidden">

                              <span onClick={()=>{if (category !=="name"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("no"); filterData.sort(compareOnKey("name", !upDown));}}>업무담당자</span>

                              </th>
                              <th class="wd-80 xl-hidden">
                              <span onClick={()=>{if (category !=="start_date"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("start_date"); filterData.sort(compareOnKey("start_date", !upDown));}}>업무시작일</span>

                              </th>
                              <th class="wd-80 xl-hidden">
                              <span onClick={()=>{if (category !=="end_date"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("end_date"); filterData.sort(compareOnKey("end_date", !upDown));}}>업무종료일</span>

                              </th>
                              <th class="wd-80 xl-hidden">
                              <span onClick={()=>{if (category !=="importance"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("importance"); filterData.sort(compareOnKey("importance", !upDown));}}>중요도</span>
                              

                              </th>
                              <th class="wd-80 xl-hidden">
                              <span onClick={()=>{if (category !=="modify_date"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("modify_date"); filterData.sort(compareOnKey("modify_date", !upDown));}}>수정일</span>
                              </th>
                              <th class="wd-80 xl-hidden">
                              <span onClick={()=>{if (category !=="register_date"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("register_date"); filterData.sort(compareOnKey("register_date", !upDown));}}>등록일</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterData.length===0 && (
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan={9} style={{ padding: "50px 0" }}>
                                  업무가 없습니다.
                                </td>
                              </tr>
                            )}
                            {filterData
                              .slice(offset, offset + limit)
                              .map(
                                ({
                                  status,
                                  subject,
                                  no,
                                  name,
                                  start_date,
                                  end_date,
                                  importance,
                                  modify_date,
                                  register_date,
                                }) => (
                                  <tr key={no}>
                                    <td>
                                      {status === "계획" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "#c3bef0",
                                            width: "30px",
                                          }}
                                        >
                                          {status}
                                        </div>
                                      )}
                                      {status === "진행" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "#8dc6ff",
                                            width: "30px",
                                          }}
                                        >
                                          {status}
                                        </div>
                                      )}

                                      {status === "지연" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "#ffb5b5",
                                            width: "30px",
                                          }}
                                        >
                                          {status}
                                        </div>
                                      )}

                                      {status === "완료" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "#9bf4d5",
                                            width: "30px",
                                          }}
                                        >
                                          {status}
                                        </div>
                                      )}
                                      
                                      {status === "취소" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "#d3d6db",
                                            width: "30px",
                                          }}
                                        >
                                          {status}
                                        </div>
                                      )}
                                    </td>

                                    <td>
                                      <Link
                                        to="/workview"
                                        state={{ subject: subject, importance: importance, no: no }}
                                      >
                                        {subject}
                                      </Link>
                                    </td>
                                    <td>{no}</td>
                                    <td>{name}</td>
                                    <td>{start_date}</td>
                                    <td>{end_date}</td>
                                    <td>
                                      {importance === "true" && (
                                        <div
                                          class="status"
                                          style={{
                                            backgroundColor: "red",
                                            width: "30px",
                                            color: "white",
                                          }}
                                        >
                                          긴급
                                        </div>
                                      )}
                                    </td>
                                    <td>{modify_date}</td>
                                    <td>{register_date}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>

                        <div style={{ width: "100%", textAlign: "right" }}>
                          {isloggedIn && (
                            <Link
                              to="write"
                              style={{
                                padding: "10px 30px",
                                marginTop: "20px",
                                backgroundColor: "#555",
                                color: "white",
                                textAlign: "center",
                                position: "relative",
                                display: "inline-block",
                                width: "105px",
                                cursor: "pointer",
                              }}
                            >
                              <HiPencilAlt />
                              글쓰기
                            </Link>
                          )}
                        </div>
                      </div>
                      {isPage && (
                        <div className="paging">
                          <Pagination
                            postsPerPage={limit}
                            totalPosts={filterData.length}
                            page={page}
                            paginate={setPage}
                          ></Pagination>
                          {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Board;
