
import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import SideNav from "../components/AdminSideNav";
const Home = () => {
  function reply(){

    const msg = {
      subject : "subject",
      date : "date",
      html : "html"

    }
    console.log(msg);
  fetch("http://gw.thegmmedical.com:5003/send", {
    method: "POST", // 통신방법
    headers: {
      "content-type": "application/json",
    },
    credentials: "include",
    body:msg,
  })
    .then((res) => res.json())
    .then((json) => {
      //setData(json);
      console.log(json);
    })
    .catch(() => {});
 
  }
  return (
    <div className="wrapper">
    

    </div>

  );
};

export default Home;
