import React, { useRef, useEffect,useMemo, useState } from "react";
import Folder from "./Folder";
import FolderTree from 'react-folder-tree';
import axios from "axios";
import "./css/org.css";
import Employee from "../components/Employee";
import 'react-folder-tree/dist/style.css';
import user from "./img/user.png";
axios.defaults.withCredentials = true; 

const SelectMember = () => {
    const onTreeStateChange = (state, event) => console.log(state, event);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [users, setUsers] = useState([]);
    const [ccList, setCC] = useState([]);
    const [bccList, setBCC] = useState([]);
    const [check, setCheck] = useState([]);
    const [members, setMembers] = useState([]);
    const [CheckList, setCheckList] = useState([]);
    const [testData, setTestData] = useState([]);
    const [showUser, setShowuser] = useState(false);
    const [selectedMember, setSelectedMember]= useState();

    function selectAll()  {
      let checkboxes 
           = document.getElementsByName('member');
      let all 
           = document.getElementsByName('all');
      console.log(checkboxes);
      checkboxes.forEach((checkbox) => {
        checkbox.checked = all[0].checked;
      })
      if (all[0].checked === true){
        setCheckList(options);
      }else{
        setCheckList([]);
      }
    }

    function selectAllDel()  {
      let checkboxes 
           = document.getElementsByName('select');
      let all 
           = document.getElementsByName('delall');
      //console.log(checkboxes)
      checkboxes.forEach((checkbox) => {
        checkbox.checked = all[0].checked;
        //console.log(checkbox);
      })
      console.log(members);
      if (all[0].checked === true){
        setCheck(members);
      }else{
        setCheck([]);
      }
    }
    
    const onChangeEach = (e, id) => {
      // 체크할 시 CheckList에 id값 넣기
      if (e.target.checked) {
         setCheckList([...CheckList, id]);
      // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
      } else {
         setCheckList(CheckList.filter((checkedId) => checkedId !== id));
      }
   }
   const onChangeCheck = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
       setCheck([...check, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
      setCheck(check.filter((checkedId) => checkedId !== id));
    }
 }
    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
      
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].d_parent === parentId ) {
          var node = nodes[i];
            
          arrayToReturn.push({
            name: node.d_name,
            children: lookingForNodeWithParent( nodes, node.d_no )
          });
        }
      }
      return arrayToReturn;
    }
      
      
      useEffect(() => {
     
        fetch("http://gw.thegmmedical.com:5003/api/getdepartment_group", {
            method: "get", // 통신방법
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
          })
          .then((res) => res.json())
          .then((json) => {
            console.log(json);  
      console.log(lookingForNodeWithParent( json, null ));
            //setBoxes(json[0].boxes);
            var array = lookingForNodeWithParent( json, null );
            //let newData = array.map(el => el[0]);
                //console.log(newData);
            return setTestData(array[0]);
    
            //console.log(boxes.boxes);
            //console.log(boxes.boxes);
          })
          .catch(() => {});
      
      }, []);
    
   

      const [selectedItem, setSelectedItem] = useState(null);

   

      const onNameClick = ({ defaultOnClick, nodeData }) => {
        defaultOnClick();
        let all = document.getElementsByName('all');
        all.forEach((checkbox) => {
          checkbox.checked = false;
        })

        const {
          // internal data
          path, name, checked, isOpen, children
          // custom data
        } = nodeData;
      
        setCheckList([]);

        const item = name;
        const tempArray = [...options, item];
        setOptions(tempArray);

        axios.post('http://gw.thegmmedical.com:5003/api/getcontacts',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              folder_name: name
            },
          })
    .then(function (response){
      console.log("결과는요", response.data);
   
      setOptions(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

      };
      const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
        axios.post('http://gw.thegmmedical.com:5003/api/getcontactsbyinput',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              input: e.target.value
            },
          })
            .then(function (response){
            console.log(response.data);
            var tempArray = [];
            for (let i =0;i<response.data.length;i++){
        
                console.log(response.data[i].name);
                tempArray.push(response.data[i].name + response.data[i].email);
        
            }
            setOptions(tempArray);
            })
            .catch(function (error){
            console.log(error);
            })
            .then(function (){
            //always executed
            });
        }
      }
      const searchword = (e) => {
        const value = e.target.getAttribute("value");
        console.log(value);
        axios.post('http://gw.thegmmedical.com:5003/api/getcontactsbyword',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              input: value
            },
          })
            .then(function (response){
            console.log(response.data);
            var tempArray = [];
            for (let i =0;i<response.data.length;i++){
        
                console.log(response.data[i].name);
                tempArray.push(response.data[i].name + response.data[i].email);
        
            }
            setOptions(tempArray);
            })
            .catch(function (error){
            console.log(error);
            })
            .then(function (){
            //always executed
            });
        
      }
     
     
      
    

    function add(){
      console.log(selectedMember);
      window.opener.postMessage({ selectedMembers: members, users: users }, '*');
  
    
  
  window.close();
}



const addMembers = () => {
  console.log("CheckList: ", CheckList);
  var tempArray = members;
  for (let i=0;i<CheckList.length;i++){
    if (!tempArray.includes(CheckList[i])){
      tempArray = [...tempArray, CheckList[i]];
    }
  }
  
  console.log("CheckList: ", tempArray);
  setMembers(tempArray);
}

const delMembers = () =>{
  let all = document.getElementsByName('delall');
        all.forEach((checkbox) => {
          checkbox.checked = false;
        })
  var tempArray = members;
  console.log("check is...", check);
  for (let i=0;i<check.length;i++){
    tempArray= tempArray.filter(item => item.user_no !== check[i].user_no);
  }

  setMembers(tempArray);
  

}
    return (
        <div>

        <div className="select" style={{overflowY:"hidden", display:"flex", padding:"20px"}}> 
          <div className="directory-wrap" style={{justifyContent:"space-between"}}>
            <div className="directory-list">
                <FolderTree
      data={ testData }
      onChange={ onTreeStateChange }
      onNameClick={ onNameClick }
      showCheckbox={ false } 
      readOnly
      />

                </div>
                <div className="directory-select">
                <div style={{display:"flex", justifyContent:"space-between",
              alignItems:"center"}}><p>사원 목록</p><div className="btns">
                    <button onClick={()=>addMembers()}>추가</button>
                    </div></div>
                {/*<select
                multiple class="m_select">
                    { options.map((element, index) => <option className={element.email} key={index} onClick={() => {setSelectedMember(element);}}>
                      {element.name} {element.job_name} ({element.d_name})</option>) }
                </select>*/}
              <div style={{height:"190px", overflowY:"scroll", border:"1px solid #ddd"}}>
              <table className="member_table">
                <thead>
                  <tr><td><input type='checkbox' name='all' value='all' onClick={selectAll}/></td>
                  <td>부서</td>
                  <td>직급</td>
                  <td>이름</td>
                  </tr>
                </thead>
                <tbody>
                    { options.map((element, index) => 
                    
                    <tr>
                      <td>
                      <input type='checkbox' 
        name='member' 
        value={element.user_no}
        onChange={(e) => onChangeEach(e, element)} checked={CheckList.includes(element)}/> 
                      </td>
                      <td className={element.email} key={index} onClick={() => {setSelectedMember(element);}}>
                      {element.d_name}</td>
                      <td>{element.job_name}</td>
                      <td> {element.name}</td>
                      </tr>) }

                      </tbody>
                </table>
                </div>
                <div style={{display:"flex", justifyContent:"space-between",
              alignItems:"center"}}>
                <p>선택된 사원 목록</p>
                <div className="btns">
                  <button onClick={()=>delMembers()}>삭제</button>
                </div>
                </div>
                    <div style={{height:"190px", overflowY:"scroll", border:"1px solid #ddd"}}>
                <table className="member_table">
                <thead>
                  <tr><td><input type='checkbox' name='delall' value='selectall' onClick={selectAllDel}/></td>
                  <td>부서</td>
                  <td>직급</td>
                  <td>이름</td>
                  </tr>
                </thead>
                <tbody>
                    { members.map((element, index) => 
                    
                    <tr>
                      <td>
        <input type='checkbox' 
        name='select' 
        value={element.user_no}
        onChange={(e) => onChangeCheck(e, element)} checked={check.includes(element)}/> 
                      </td>
                      <td className={element.email} key={index} onClick={() => {setSelectedMember(element);}}>
                      {element.d_name}</td>
                      <td>{element.job_name}</td>
                      <td> {element.name}</td>
                      </tr>) }

                      </tbody>
                </table>
                </div>
                </div>
                
               
            </div>
   
   
        </div>
        <div className="button_wrap">
      <button className="submit" onClick={()=>add()}>추가</button>
      {/*</form>*/}
      </div>
    
    </div>
  )
}
export default SelectMember;
