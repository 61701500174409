
import { NavLink, Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import BoardList from "../components/side/BoardList";
import axios from "axios";
import "./css/board.css";
import { HiPencilAlt } from "react-icons/hi";
import {GiHamburgerMenu} from "react-icons/gi";
import Pagination from "./Pagination";
import { BsPin } from "react-icons/bs";

const Notice = () => {
    const [data, setData] = useState([]);

    const [sidebar, setSidebar] = useState(false);
    const [parent, setParent] = useState("");
    const [name, setName] =useState("");
    const [num, setNum] = useState(0);
    const [lev, setLev] = useState(0);
    const [selected, setSelected]= useState();
    const [search, setSearch] = useState(null);
    const [options, setOption] = useState("all");
    const [filterData, setfilterData] = useState([]);
    const [notice, setNotice] = useState([]);
    const [isEmpty, setEmpty] = useState(false);
    const [isPage, setIsPage] = useState(false);
    const [isloggedIn, setLoggedIn] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const offset = (page - 1) * limit;
    const { state } = useLocation();


    const addBookmark = () => {
      var result = window.confirm("즐겨찾기 게시판에 추가하시겠습니까?");
      if(result){
        const data ={
          name: "점검 결과표",
          table : "board",
          link : "/inspect"
        }
        console.log(data);
        axios.post('http://gw.thegmmedical.com:5003/api/add_bookmark', data)
      .then(function (response){
        console.log(response);
        window.location.reload(true);

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
    };
  
    const addBookmarkPage = () => {
      var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
      if(result){
        const data ={
          name: "점검 결과표",
          link : window.location.pathname,
          bookmark : "점검 결과표" + " 게시판"
        }
        console.log(window.location.pathname)
        console.log(data);
        axios.post('http://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
        .then(function (response){
          console.log(response);
          alert("메뉴 바로가기에 추가되었습니다.");
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };


    const onChangeSearch = (e) => {
      e.preventDefault();
      setSearch(e.target.value);
    };
  

    useEffect(() => {
      axios.get('http://gw.thegmmedical.com:5003/api/get_inspect')
      .then(function (response){
        console.log("order.........",response.data);
        setfilterData(response.data);
        setData(response.data);

        
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      
    }, []);
  

   



  const deleteDepartment = () => {
    const data ={
      name : name,
      parent : parent,
      num : num,
      level : lev
    }
    axios.post('http://gw.thegmmedical.com:5003/api/delete_department', data)
    .then(function (response){
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };


  const assign = () => {
    if (selected.name ===undefined){
    }
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    if (array[0].value==="selectall"){
      array.shift();
    }
    const data ={
      nos : array,
      folderName : selected.name,
      folderNo : selected.num
    }
    
    var result = window.confirm("선택된 사용자를 [" + selected.name+"] 부서에 지정합니다.");
    if(result){
      axios.post('http://gw.thegmmedical.com:5003/api/assign', data)
    .then(function (response){
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }
    
  };

  

  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };
  const onSearch = (e) => {
    e.preventDefault();
    if (options === "all") {
      const filtered = data.filter((itemList) => {
        var tmp = "";
        tmp+=itemList.name;
        tmp+=itemList.create_date;
        tmp+=itemList.hospital;
        tmp+=itemList.company;
        tmp+=itemList.department;
        tmp+=itemList.inspect_date;
        tmp+=itemList.checked;
        tmp+=itemList.inspector;
   
        return tmp.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    } else {
      
        
      const filtered = data.filter((itemList) => {
        
        return itemList[options].toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    }
  
  };

  const [category, setCategory] = useState("");
    const [upDown, setUpDown] = useState(true);


  const compare = ((a, b) => {
    const eleA = a[category]; // ignore upper and lowercase
    const eleB = b[category]; // ignore upper and lowercase
    if (upDown === true){
      if (eleA > eleB) {
        return -1;
      }
      if (eleA < eleB) {
        return 1;
      }
    }else{
      if (eleA < eleB) {
        return -1;
      }
      if (eleA > eleB) {
        return 1;
      }
    }
  
    // names must be equal
    return 0;
  });
  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
     <div className="content-wrap">
     
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>
        <div className="side-menu">
        <BoardList changeBoard={(value)=>{setfilterData(value);}} changeNotice={(value)=>{setNotice(value);}}/>
        </div>
        <div className="board_wrap">
    
           
        <div className="">
        
        <div className="board_wrapper">
          <form name="form1" id="form1" method="post">
                  <div className="board_container">
                      <div id="content" className="content cs">
                      
                        <div className="board_top" style={{alignItems:"flex-start"}}>
                        <Link to="/inspect_write"
                        style={{
                          padding: "10px 30px",
                          backgroundColor: "#fff",
                          color: "#333",
                          border:"1px solid #000",
                          textAlign: "center",
                          position:"relative",
                          display:"inline-block",
                          width:"105px",
                          cursor: "pointer",
                        }}
                      >
                        <HiPencilAlt/>글쓰기
                      </Link>
                      <div style={{margin:"10px 0 0px"}}>점검결과표</div>

                              {/*<label>
                            페이지 당 게시물 수:&nbsp;
                            <select
                            type="number"
                            value={limit}
                            onChange={({ target: { value } }) => setLimit(Number(value))}
                            >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </label>*/}
                              <div className="bt_srch">
                              
                                <div className="bts_slct">
                                  <select
                                    id="u_shcate"
                                    name="search_item"
                                    className="select"
                                    title="검색항목 선택"
                                    value={options || ''}
                                    onChange={selectHandle}
                                  >
                                    <option value="all">전체검색</option>
                                    <option value="name">작성자</option>
                                    <option value="create_date">작성일</option>
                                    <option value="hospital">병원명</option>
                                    <option value="company">점검업체</option>
                                    <option value="department">사용부서</option>
                                    <option value="inspect_date">점검일자</option>
                                    <option value="checked">확인자</option>
                                    <option value="inspector">점검자</option>


                                  </select>

                                </div>
                                <div className="bt_input">
                                  <input
                                    type="text"
                                    id="search_content"
                                    name="search_content"
                                    value={search || ''}
                                    placeholder="검색어를 입력하세요"
                                    onChange={onChangeSearch}
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="search_btn"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                              </div>
                          </div>

                          <div className="board_list">
                         
                          
                  <table class="delivery table table-hover bd-b">
									<thead class="thead-dark">
									<tr class="tx-center">
										<th class="wd-80 xl-hidden">
                    <span onClick={()=>{if (category !=="no"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("no"); filterData.sort(compare);}}>번호</span>

                    </th>
										<th class="">
                    <span onClick={()=>{if (category !=="create_date"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("create_date"); filterData.sort(compare);}}>제목</span>


                    </th>
										<th class="wd-150 xl-hidden">
                    <span onClick={()=>{if (category !=="name"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("name"); filterData.sort(compare);}}>작성자</span>

                    </th>
										<th class="wd-120 xl-hidden">
                    <span onClick={()=>{if (category !=="create_date"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("create_date"); filterData.sort(compare);}}>작성일</span>

                    </th>
									</tr>
									</thead>
									<tbody>
                 
                                 
                                    {isEmpty && (
                                  <tr style={{ textAlign: "center" }}>
                                    <td colSpan={40} style={{height:"200px"}}>게시물이 없습니다.</td>
                                  </tr>
                                )}
                                {filterData
                                  .slice(offset, offset + limit)
                                  .map(
                                    ((element,index) => (
                                      ( <tr key={element.no}>
                                      

                                        <td>
                                        {element.no}
                                        </td>
                                      
                                        <td style={{textAlign:"center"}}>
                                        <Link to={"/inspect_view"} state={{no: element.no, data : element}}>{element.create_date.substring(0,10)+"-점검결과표"}</Link>
                                        </td>
                                        <td style={{textAlign:"center"}}>
                                        {element.name+" "+element.job_name}
                                        </td>
                                        <td>
                                        {element.create_date}
                                        </td>
                                     
                                     
                                      </tr>)
                                     
                                    )
                                  ))}

									</tbody>
								</table>
                            <table>
                              <colgroup>
                                <col className="m_dp_n table_no" />
                                <col className="" />
                                <col className="ta_l" />
                                <col className="m_dp_n table_date" />
                              </colgroup>
                              {/*<thead>
                        <tr>
                            <th className="m_dp_n table_no" scope="col">NO.</th>
                            <th scope="col"></th>
                            <th className="ta_l" scope="col">제목</th>
                            <th className="m_dp_n table_date" scope="col">날짜</th>
                        </tr>
                    </thead>*/}
                              <tbody>
                               
                              </tbody>
                            </table>
                           
                  </div>

<div className="btns pc" style={{display:"flex",justifyContent:"start", width:"20%", margin:"20px 0 0 0", marginBottom:"20px"}}>
<div className="btn" style={{marginRight:"10px"}} onClick={()=>addBookmark()}>즐겨찾기 추가</div>
<div className="btn" style={{display:"flex", alignItem:"center", padding:"5px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>
</div> 

                  {(isPage && filterData.length>0) && (
                    <div className="paging">
                      <Pagination
                        postsPerPage={limit}
                        totalPosts={filterData.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>
                      {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
                    </div>
                  )}
                      </div>
                  </div>
          </form>
        </div>
      </div>
            
            
                
                
                
        </div>

                </div>
     </div>
    </div>

  );
};

export default Notice;
