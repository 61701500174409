import React, { useState } from 'react';
import "../css/treeview.css";
import {FaRegFolder , FaRegFolderOpen , FaClipboardList } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const TreeNode = ({ node, boardNo }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [name, setName] = useState("");
    const [num, setNum] = useState(0);
    const [selected, setSelected]= useState([]);
    const navigate = useNavigate();
    const [parent, setParent] = useState("");
    const [members, setMembers] = useState([]);

    
    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };
  

    const onNameClick = ( nodeData ) => {
      var notice_data=[];
      var board_data=[];
      console.log("nodedata....", nodeData);
      const {
        // internal data
        path, name, num, link, type, isOpen, children
        // custom data
      } = nodeData;
      if (link !==null && link !==undefined){
        navigate(link, {state: { boardName : name, bg_no: num}});
      }else if (type==="board"){
        navigate("/board", {state: { boardName : name, bg_no: num}});
      }else if (type==="notice"){
        navigate("/board", {state: { boardName : name, bg_no: num}});
      }else{
        return;
      }
    
      if (type!=="group"){
        setSelected(nodeData);
        var main = document.getElementsByClassName('tree-item');

        var aTags = main[0].getElementsByTagName("span");
        
  var searchText;
  var found;
  
  searchText=name;
  
  

  for (var i = 0; i < main.length; i++) {

    console.log("main.....", main[i], "hey", main[i].getElementsByTagName("span"));
    main[i].getElementsByTagName("span")[0].style.background="transparent";
    if (main[i].getElementsByTagName("span")[0].textContent === searchText) {
      found = main[i].getElementsByTagName("span")[0];
      break;
    }
  }


  const boxes = document.querySelectorAll('.tree-item');
  
 
  found.click();
        setParent(name);
        
        /*const tempArray = [...options, item];
        console.log(tempArray);
        setOptions(tempArray);*/
  
  
        const item = name;
        console.log("name........", name);
        boardNo({bg_no : num, bg_name : name});
        /*if (window.innerWidth < 620) {
          window.location.reload(true);
      } else {
          //setIsMobile(false)
      }
        return;*/
      }
      
  };

    return (
      <div className="tree-node">
        <div onClick={handleToggle} className={`node-toggle ${isExpanded ? 'expanded' : ''}`}>
          {(isExpanded && node.children !==undefined) &&  (<div className="tree-item" onClick={()=>onNameClick(node)}><FaRegFolderOpen /><span>{node.name}</span>
          
          {node.unread !== undefined && (<div className='unread'>{node.unread}</div>)} </div>)}
          {(!isExpanded && node.children !==undefined) &&  (<div className="tree-item" onClick={()=>onNameClick(node)}><FaRegFolder/>  <span>{node.name}</span>
          
          {node.unread !== undefined && (<div className='unread'>{node.unread}</div>)} </div>)}
          {(node.children ===undefined) &&  (<div className="tree-item" onClick={()=>onNameClick(node)}><FaClipboardList/> <span>{node.name}</span> 
          {node.unread !== undefined && (<div className='unread'>{node.unread}</div>)} </div>)}
        </div>
        {(isExpanded && node.children !==undefined) && (
          <ul className="child-nodes">
            {node.children.map(childNode => (
              <li key={childNode.id}>
                <TreeNode node={childNode} boardNo={(value)=>{boardNo(value);}}/>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };
  
  export default TreeNode;
