import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import Sign from "../../components/Sign";

//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

const initialValues = {
  
};

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {

    const {manufacturer, product_name, standard, quantity, destination}= rowsData;
    return (
      <tr key={index}>
        <td>{index+1}</td>
       
        <td>
          {manufacturer}
           
        </td>
        <td>
          {product_name}
           
        </td>
        <td>
          {standard}
            
        </td>
        <td>
          {quantity}
           
        </td>

        <td>
          {destination}
           
        </td>
       
      
      </tr>
    );
  });
}

const Endoscope = ({data, line}) => {  
  

  const [select, setSelect] = useState("");
 
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

  const [user, setUser] = useState([]);
  const [name, setname] = useState("");
  const [userNo, setUserNo] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [absent, setAbsent] = useState([]);
 
  const { doc_no } = useParams();
  const [showSign, setShowSign] = useState(false);


  useEffect(() => {
    axios.get('http://gw.thegmmedical.com:5003/api/get_forms_data/logi_order/'+data)
    .then(function (response){
      
      setValues(response.data[0]);
      let order_no = response.data[0].no;
      axios.get('http://gw.thegmmedical.com:5003/api/get_forms_list/logi_order/'+order_no)
      .then(function (response){
        console.log("orders...........", response.data);
        
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({manufacturer:"", product_name:"", standard:"", quantity:"", destination:""});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });



    axios.get('http://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
      console.log("user is", response.data);
    setUser(response.data[0]);
    var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
    setname(name);
    setUserNo(response.data[0].user_no);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('http://gw.thegmmedical.com:5003/api/checkabsent')
    .then(function (response){
      console.log("absent is", response.data);
      var user_nos = response.data.map(function(item) {
        return item['user_no'];
      })
    console.log("absent...", user_nos);
    setAbsent(user_nos);
    
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    
  }, []);




  const togglePopup = () => {

    setShowSign(!showSign);
  
    
    //
  };
  const openSign = () =>  {
    if (data.secured === 1){
      let pw = prompt("결재 암호를 입력하세요", "");
      if (pw === user.e_sign_pw){
        togglePopup();
      }else{
        alert("암호가 일치하지 않습니다.")
      }
      
    }else{
      togglePopup();
    }
  }



  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let initial = [{
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    }];
    initRow(initial);
    
  }, []);




  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });

  function dateFormat(value) {
    let date = new Date(value);
    let month = date.getMonth()+1;
    let day = date.getDate();
    let year = date.getFullYear();

    return year + "년 " + month + "월 " + day + "일";
  } 

  return (
    <div className="wrapper">
    
    {showSign && absent.includes(user.user_no)? (
  <Sign docNo= {data} orderNo = {orderNo} subject="발주서" decider = {user} userNo = {userNo} closePopup={() => togglePopup()} />
  ) : null}
{showSign && !absent.includes(user.user_no)? (
  <Sign docNo= {data} orderNo = {orderNo} subject="발주서" decider = {undefined} userNo = {userNo} closePopup={() => togglePopup()} />
  ) : null}
      
      <div className="outer_wrapper forms" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

          <div className="order_write endoscope" style={{padding:"0", margin:"20px auto"}}>
       
          <table style={{width:"100%", border:"none"}} className="headers-1">
            <tr>
              <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>발주서</td>
            </tr>

            
          </table>

        <div style={{display:"flex", justifyContent:"space-between"}}>
          <table className="table1" style={{width:"42%", borderCollapse:"collapse"}}>
          <tbody>

          <tr>
          <td colspan="3" style={{textAlign:"right", wordSpacing:"15px"}}> 
          {values.order_date} 
          </td>

          </tr>

          <tr style={{borderBottom:"2px solid #000"}}>
          <td colspan="3" style={{textAlign:"center", width:"90%"}}>
          {values.company}

          </td><td>귀하</td>
          </tr>
          <tr>
          <td colspan="3"> 
          <div style={{display:"flex", justifyContent:"space-between"}}>
              <div style={{display:"flex", alignItems:"center", marginTop:"10px"}}>
                <span>T: </span>
              {values.tel}
              </div>

              <div style={{display:"flex", alignItems:"center", marginTop:"10px"}}>
                <span>F: </span>
              {values.fax}
              </div>

          </div>
        
          </td>

          </tr>
          
          <tr>
       
          </tr>
          </tbody>
          </table>


          <table border="1" className="supply" style={{width:""}}>
          <tbody>
          <tr>
          <td rowspan="4" style={{textAlign:"center"}}>공<br></br>
          <br></br><br></br>급</td>
          <td>등록번호</td>
          <td colspan="3">108-81-84884</td>
          </tr>
          <tr>
          <td>상호</td>
          <td>주식회사 GM메디칼</td>
          <td>대표자</td>
          <td>임명한</td>
          </tr>
          <tr>
          <td>주소</td>
          <td colspan="3">서울 서초구 서초대로 46길 19-7, 201호</td>
          </tr>
          <tr>
          <td>전화</td>
          <td>02-2055-2255</td>
          <td>팩스</td>
          <td>02-2055-1177</td>
          </tr>

          <tr>
          <td>담당자</td>
          <td></td>
          <td>H.P</td>
          <td></td>
          </tr>
          </tbody>
          </table>
        </div>
               
        <div style={{marginTop:"20px"}}>
        <div style={{display:"flex", alignItems:"center", width:"100%", padding:"7px 0",
        borderBottom:"1px solid #ddd"}}>
            <span> 납품장소: </span>
          <span>{values.place} </span>
          </div>
          <div style={{display:"flex", alignItems:"center", width:"100%", padding:"7px 0",
          borderBottom:"1px solid #ddd"}}>
            <span> 특이사항: </span>
            <span>{values.note} </span>
          </div>
        </div>
     

      <p></p>
        <table className="form_list" style={{borderCollapse: 'collapse', width:"790px"}}>
                 
          <thead>
            <tr>
              <th>번호</th>
              <th>제조사</th>
              <th>품명</th>
              <th>규격</th>
              <th>수량</th>
              <th>납품 예상처</th>

            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            />
         
          </tbody>
        </table>
       
      </div>

      </div>

      </div>
      
 
  
      </div>
  
  );
};

export default Endoscope;
