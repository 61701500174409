import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";

import axios from "axios";

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  hospital_name: "",
  model: "",
  estimate_date:"",
  register_date: "",
  mfr_num: "",
  total:0,
  memo:"",
};


function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {part_name, item_code, quantity, unit, unit_price, price}= rowsData;


    return (
      <tr key={index}>
        <td>{index+1}</td>
       
        <td>
          <input
            type="text"
            value={part_name}
            onChange={(event) => onValUpdate(index, event)}
            name="part_name"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={item_code}
            onChange={(event) => onValUpdate(index, event)}
            name="item_code"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={quantity}
            onChange={(event) => onValUpdate(index, event)}
            name="quantity"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={unit}
            onChange={(event) => onValUpdate(index, event)}
            name="unit"
            className="form-control"
          />
        </td>

        <td>
          <input
            type="text"
            value={unit_price}
            onChange={(event) => onValUpdate(index, event)}
            name="unit_price"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={price}
            onChange={(event) => onValUpdate(index, event)}
            name="price"
            className="form-control"
          />
        </td>

        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

const Repair = ({doc_no, onChange, line}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [list, setList] = useState([]);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

  useEffect(()=>{
    //This function will run every time the value of values state changes.
    onChange(values); //Will give you the updated value of values state.
    //Do some other stuff
  }, [values])
  useEffect(() => {
    if (doc_no!==undefined){
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/repair/'+doc_no)
    .then(function (response){

      if (response.data.length>0){

      let inspect_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/repair/'+inspect_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
    
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);
 

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    
    values.arr = rows;
    onChange(values);

  };
 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let initial = [{
      part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    },
    {
      part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    },
    {
      part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    },
    {
      part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    },
    {
      part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    },
    {
      part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    },
    {
      part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    },
    {
      part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    },
    {
      part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    },
    {
      part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    }];
    initRow(initial);
    
  }, []);


  const addRowTable = () => {
    const data = {
        part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    if (name === "quantity" && value !=="" && data[i]['unit_price']!==""){
      let price = parseInt(value) * parseInt(data[i]['unit_price']);
     
      console.log("values.total .........", values.total);
     
     
     
      data[i]['price'] = price.toLocaleString();
       let sum = 0;
      for (let j=0;j<data.length;j++){
        if (!isNaN(parseInt(data[j]['unit_price'])) && !isNaN(parseInt(data[j]['quantity']))){
          sum += (parseInt(data[j]['unit_price'])* parseInt(data[j]['quantity']))
        }      
      }
      values.total = sum;
    }
    if (name === "unit_price" && value !=="" && data[i]['quantity']!==""){


      let price = parseInt(value) * parseInt(data[i]['quantity']);
      console.log("values.total .........", values.total);
   
      data[i]['price'] = price.toLocaleString();
      let sum = 0;
      for (let j=0;j<data.length;j++){
        if (!isNaN(parseInt(data[j]['unit_price'])) && !isNaN(parseInt(data[j]['quantity']))){
        sum += (parseInt(data[j]['unit_price'])* parseInt(data[j]['quantity']))
        }
      }
      values.total = sum;
     
    }



    initRow(data);

    values.arr = rows;
    onChange(values);

  };


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     
     

    

            <div className="order_write forms" style={{padding:"0", margin:"20px auto"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>수리견적서</td>
                
                  </tr>
              
            
                </table>
                <hr style={{borderWidth:"5px"}}/>
          <table border="1" className="supply" style={{width:"100%"}}>
          <tbody>
          <tr>
          <td rowspan="2" style={{textAlign:"center"}}>공<br></br>
          <br></br>급</td>
          <td>등록번호</td>
          <td>108-81-84884</td>
          <td>상호</td>
          <td>(주)GM메디칼</td>
          <td>대표자</td>
          <td>임명한</td>
          </tr>
          <tr>
          <td>주소</td>
          <td>서울 서초구 서초대로 46길 19-7, 201호</td>
          <td>업태</td>
          <td>도소매</td>
          <td>종목</td>
          <td>의료기기</td>
          </tr>
       
          </tbody>
          </table>
        
          <table className="" style={{width:"100%", margin:"20px"}}>
          <tbody>
          <tr>
          <td>병원명 : </td>
          <td colspan="3">                            
          <input type="text" name="hospital_name" value={values.hospital_name} onChange={handleInputChange}></input>
          </td>
          <td>견적일자 : </td>
          <td colspan="3">
          <input type="text" name="estimate_date" value={values.estimate_date} onChange={handleInputChange}></input>
          </td>
          </tr>
          <tr>
          <td>모델명 : </td><td colspan="3">
          <input type="text" name="model" value={values.model} onChange={handleInputChange}></input>
          </td><td>제조번호 : </td>
          <td colspan="3">
          <input type="text" name="mfr_num" value={values.mfr_num} onChange={handleInputChange}></input>
          </td>
          </tr>
          </tbody>
          </table>

          <table className="" style={{width:"100%", padding:"5px 0", borderTop:"1px solid #000", borderBottom:"1px solid #000"}}>
          <tbody>
          <tr style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
          <td>합계금액 : </td><td>
          <input type="text" name="total" value={values.total} onChange={handleInputChange}></input>
          </td><td><span>(VAT 포함)</span></td>
          </tr>
      
          </tbody>
          </table>

      
                <div className="btns" style={{display:"flex", justifyContent:"flex-end", margin:"20px 0"}}>
     
        <button className="btn" onClick={addRowTable}>
          추가
        </button>
      </div>
                <table className="form_list repair_list" style={{borderCollapse: 'collapse', width:"790px"}}>
                 
          <thead>
            <tr>
              <th></th>
              <th>부품명</th>
              <th>품목코드</th>
              <th>수량</th>
              <th>단위</th>
              <th>단가</th>
              <th>금액</th>
              <th>삭제</th>
            </tr>

            
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />
          <tr>
            <td style={{padding:"5px 0"}}>합계</td>
            <td colSpan={7} style={{textAlign:"right", padding:"0 35px"}}>{values.total}</td>
          </tr>
          </tbody>
        </table>
            
          </span>
          <table style={{width:"100%", borderCollapse:"collapse", border:"1px solid #000", marginTop:"20px"}}>
              <tr><th style={{textAlign:"center", padding:"5px", borderBottom:"1px solid #000"}}>특이사항</th></tr>
              <tr><td><textarea style={{padding:"0", border:"none", height:"50px"}} name="memo" value={values.memo} onChange={handleInputChange}></textarea></td></tr>
            </table>
          
          
          </div>
        
        
      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default Repair;

