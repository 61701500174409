import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./css/sign.css";

import axios from "axios";



const Sign = ({ custom_name, docNo, formNo, subject, line, orderNo, decider, userNo, closePopup = []}) => {

  const [status, setStatus] = useState(1);
  const [textValue, setTextValue] = useState("");
  const [signLink, setSignLink] = useState("");
  const [data, setData] = useState([]);

  const navigate= useNavigate();

  const handleSetValue = (e) => {
    setTextValue(e.target.value);
  };
  const handleChange = (e) => {
    // string passed in
    // a string returned by default
    console.log(e.currentTarget.value);
    // add + to the event to make the value a number
    setStatus(+e.currentTarget.value);
  };

  useEffect(() => {
    if (line==="전결"){
      setStatus(4);
    }
    else if (line==="후결"){
      setStatus(5);
    }

    console.log("sign check subject, ", subject);
    axios.get('http://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
      console.log("user is", response.data);
  
    setSignLink(response.data[0].sign_link);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    console.log(
      "formno", formNo
    );
    if (formNo == "3"){
      axios.get('http://gw.thegmmedical.com:5003/api/getcancel_leave/'+docNo)
      .then(function (response){
        console.log("getcancel_leave is", response.data);
    
      setData(response.data[0]);
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
    }
    
  }, []);
  function sign(){
    var user_no, job_name, name, d_name;
    if (decider === undefined){
      user_no = 0;
      job_name="";
      name="";
      d_name="";
    }else{
      user_no = decider.user_no;
      job_name=decider.job_name;
      name=decider.name;
      d_name=decider.d_name;
    }

    
    /*
    axios.get('http://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
      console.log("user is", response.data);
    setUser(response.data[0]);
    var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
    setname(name);
    setUserNo(response.data[0].user_no);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    
    */
    axios.post('http://gw.thegmmedical.com:5003/api/docsign',{

    
          data: {
            // 서버에서 req.body.{} 로 확인할 수 있다.
            doc_no : docNo,
            status : status,
            comment: textValue,
            order_no : orderNo,
            decider: user_no,
            decider_job : job_name,
            decider_name : name,
            decider_department : d_name,
            sign_link : signLink,
            userNo : userNo,
            custom_name : custom_name,
            cancel_doc_no : data.cancel_doc_no,
            formNo : formNo,
            subject: subject
          },
        })
    .then(function (response){
      //navigate("/docview", {state:{doc_no : docNo}})
      window.location.reload(true);
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  return (
    <div className="popup">
        <div className="popup_inner sign" style={{height:"300px"}}>
      <h3 style={{textAlign:"left", marginLeft:"20px"}}>결재하기</h3>
      <div className="radios">
      <ul>
        <li>
          <input
            type='radio'
            id='1'
            value='1'
            onChange={handleChange}
            checked={status === 1}
          />
          <label htmlFor='1'>승인</label>
        </li>
       
        <li>
          <input
            type='radio'
            id='2'
            value='2'
            onChange={handleChange}
            checked={status === 2}
          />
          <label htmlFor='2'>보류</label>
        </li>

        <li>
          <input
            type='radio'
            id='3'
            value='3'
            onChange={handleChange}
            checked={status === 3}
          />
          <label htmlFor='3'>반려</label>
        </li>

        <li>
          <input
            type='radio'
            id='4'
            value='4'
            onChange={handleChange}
            checked={status === 4}
          />
          <label htmlFor='4'>전결</label>
        </li>
        <li>
          <input
            type='radio'
            id='5'
            value='5'
            onChange={handleChange}
            checked={status === 5}
          />
          <label htmlFor='5'>후결</label>
        </li>
      </ul>
      </div>
      <p style={{color:"#8aa3ee"}}>결재첨언</p>
      <div className="">
      <textarea placeholder="의견을 입력하세요" value={textValue}
        onChange={(e) => handleSetValue(e)}></textarea>

      </div>

      <div className="btns" style={{marginTop:"8px", justifyContent:"center", width:"100%"}}>
        <button className="btn" onClick={()=>sign()} style={{marginRight:"5px",
      background:"#8aa3ee", color:"white"}}>결재</button>
        <button onClick={closePopup}>취소</button>
        </div>

      </div>
      
    </div>
  );
};

export default Sign;