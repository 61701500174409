import "./css/modal.css";
import axios from "axios";
import { useEffect, useState } from "react";

const Modal = (props) => {
    //const {modalData} =props;
    const {open, close, modalData, closeModalUntilExpires, remove } =props;
    const [boardAdmin, setBoardAdmin] = useState(false);
    const [user, setUser] = useState([]);

    useEffect(()=>{
        axios.get('https://gw.thegmmedical.com:5003/api/check_boardadmin')
        .then(function (response){
        if (response.data.length>0){
            setBoardAdmin(true);
        }
        /*var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
        setname(name);
        setUserNo(response.data[0].user_no);*/
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });


        axios.get('https://gw.thegmmedical.com:5003/api/getuser')
        .then(function (response){
        if (response.data.length>0){
            setUser(response.data[0]);
        }
        /*var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
        setname(name);
        setUserNo(response.data[0].user_no);*/
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });
    },[])
    function hideForWeek(){
        axios.get('http://gw.thegmmedical.com:5003/api/hide_for_week')
        .then(function (response){
          console.log(response);
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
    }

    function hidePopup(){
        const data ={
            viewers : modalData.viewers,
            b_no : modalData.b_no,
            no : modalData.no
        }
        axios.post('http://gw.thegmmedical.com:5003/api/hide_popup', data)
        .then(function (response){

          console.log(response);
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
    }
    function removePopup(){
        const data ={
            viewers : modalData.viewers,
            b_no : modalData.b_no,
            no : modalData.no
        }
        axios.post('http://gw.thegmmedical.com:5003/api/remove_popup', data)
        .then(function (response){

          console.log(response);
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
    }
    return (
        <div className={open ? 'openModal modal' : 'modal'}>
            { open ? (
                <section>
                    <header>
                        {modalData.subject}
                        <button className="close" onClick={close}>
                            &times;
                        </button>
                    </header>
                    <main>

                    <div dangerouslySetInnerHTML={{ __html: modalData.content }}></div>

                    </main>
                    <footer>
                        {(boardAdmin || user.user_no === modalData.user_no) && (
                            <button onClick={remove}>팝업 해제</button>
                        )}
                        <button onClick={closeModalUntilExpires} style={{marginLeft:"5px"}}>일주일간 보지않기</button>
                        <button onClick={()=>hidePopup()} style={{marginLeft:"5px"}}>계속 보지않기</button>
                    </footer>
                </section>
            ) : null}
        </div>
    );
};

export default Modal;