import axios from "axios";
import React, { useState, useEffect, useRef } from "react";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {name, start, end, reason, country, cost, user_no}= rowsData;

    return (
      <tr key={index}>
        <td>
        {name}
        </td>
        <td>
        {start}
        </td>
        <td>
        {end}
        </td>
     
    
        <td>
        {reason}
        </td>

        <td>
        {country}
        </td>
        <td>
        {cost}
        </td>
        <td style={{display:"none"}}>
          <input
            type="text"
            value={user_no}
            onChange={(event) => onValUpdate(index, event)}
            name="user_no"
            className="form-control"
            hidden
          />
        </td>
      
      </tr>
    );
  });
}
function Languages({ user_no }) {
  const [rows, initRow] = useState([]);

  useEffect(() => {
    console.log("userno", user_no);
    fetch("http://gw.thegmmedical.com:5003/api/get_userinfo/business_trip/"+user_no, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log(json);  
      initRow(json);

      //console.log(boxes.boxes);
      //console.log(boxes.boxes);
    })
    .catch(() => {});
    
  }, []);

  const addRowTable = () => {
    const data = {
      name : "", start: "", end:"", reason:"", country:"", cost:"", user_no: user_no
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  function addExperience(){
    for (var i=0;i<rows.length;i++){
      console.log(rows[i]);
    }

    console.log("last rows", rows);
    axios.post('http://gw.thegmmedical.com:5003/api/add_userinfo/business_trip/'+user_no, rows)
    .then(function (response){
      console.log(response);
   

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  return (
    <>

             
           
      <table style={{width:"100%"}} className="info_summary">
        <thead>
          <tr>
          <th>출장</th>
                <th>시작일</th>
                <th>종료일</th>

                <th>사유</th>
                <th>국가</th>
                <th>비용</th>

          </tr>
        </thead>
        <tbody>
          <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            userNo = {user_no}
          />
        </tbody>
      </table>
     
    </>
  );
}
export default Languages;