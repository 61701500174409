import moment from 'moment';
import React, { useState, useEffect, useRef } from "react";
import './css/docwrite.css';
import axios from "axios";
import LeaveHistory from "../components/LeaveHistory";
import { BiSearchAlt } from "react-icons/bi";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, leaves, user }) {
  const hour = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

 
  return rows.map((rowsData, index) => {

  

    var {type,
      workdate,
      startHour,
      startMin,
      endHour,
      endMin,
      content}= rowsData;



    return (

      <div className="ot_view">
  <table style={{width:"100%"}}>
  <tr>

  <td class="table_title2" nowrap="">
    특근근무
  </td>
    <td class="">
    <div className="input-group" style={{display:"flex", flexShrink:"0", alignItems:"center"}}>
    

    <select name="type" className="formInput" style={{marginLeft:"10px"}} value={type || ''}
      onChange={(event) => onValUpdate(index, event)}
      >
      <option value="휴일">휴일</option>
      <option value="야근">야근</option>
      <option value="출장">출장</option>
    </select> 
    </div>
    </td>


    
<td class="table_title2" nowrap="">
  근무일시
</td>
  <td class="" colSpan={3}>
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>

    
<input type="date" name="workdate" className="formInput" style={{marginLeft:"10px",
  border:"1px solid #000"
}} onChange={(event) => onValUpdate(index, event)}
value={workdate}></input>


<div className="time" style={{display:"flex", alignItems:"center"}}>

<div className="startTime">
<select name="startHour" className="formInput" style={{marginLeft:"10px"}} value={startHour || ''}
onChange={(event) => onValUpdate(index, event)}>
{hour.map((element, index)=>(
<option value={element}>{element}</option>
))}
</select> 

<span>:</span>

<select name="startMin" className="formInput" style={{marginLeft:"10px"}} value={startMin || ''}
onChange={(event) => onValUpdate(index, event)}>
{Array.from(Array(60), (e, i) => {
if (i >=0 && i <=9){
return <option value={"0"+i}>0{i}</option>
}else{
return <option value={i}>{i}</option>
}
})}


</select> 
</div>

<span style={{width:"15px", textAlign:"center"}}> ~</span>

<div className="endTime">

<select name="endHour" className="formInput" style={{marginLeft:"10px"}} value={endHour || ''}
onChange={(event) => onValUpdate(index, event)}>
{hour.map((element, index)=>(
<option value={element}>{element}</option>
))}


</select> 


<span>:</span>
<select name="endMin" className="formInput" style={{marginLeft:"10px"}} value={endMin || ''}
onChange={(event) => onValUpdate(index, event)}>
{Array.from(Array(60), (e, i) => {
if (i >=0 && i <=9){
return <option value={"0"+i}>0{i}</option>
}else{
return <option value={i}>{i}</option>
}
})}


</select> 
</div>
</div>



  </div>
  </td>



  </tr>
</table>

<table>
<tr className="">

<td class="table_title2" nowrap="">
  근무자
</td>
  <td class="">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  {user.name}
  </div>
  </td>



<td class="table_title2" nowrap="">
  부서
</td>
  <td class="">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  {user.d_name}  
  </div>
  </td>



<td class="table_title2" nowrap="">
  직위
  </td>

  <td class="">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    

  {user.job_name}  
  </div>
  </td>
  </tr>

</table>

<table>
<tr>
  <td class="table_title2" nowrap="">
    근무사유
  </td>
  <td class="" colSpan={5}>
  <div className="input-group">
  <input type="text" name="content" className="formInput" placeholder="근무 사유를 입력해 주세요." value={content}
  onChange={(event) => onValUpdate(index, event)} 
  ></input>
 
  </div>
  </td>
    
  </tr>
 
  </table>

</div>
    );
  });
}
const Leave = ({doc_no, onChange}) => {  
  
  var todayDate = new Date().toISOString().slice(0, 10);
  console.log(todayDate);
  const inputFileRef = useRef();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [subject, setSubject] = useState("");
  const [expire, setExpire] = useState("1");
  const [vrange, setRange] = useState("1");
  const [vtype, setType] = useState("1");
  const [reason, setReason] = useState("");
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [startHour, setStartHour] = useState("");
  const [startMin, setStartMin] = useState("");
  const [endHour, setEndHour] = useState("");
  const [endMin, setEndMin] = useState("");
  const [info, setInfo]= useState([]);
  const [used, setUsed] = useState("");
  const [remain, setRemain] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData]= useState();
  const [holidays, setHolidays] = useState([]);
  const [rows, initRow] = useState([]);
  const [values, setValues] = useState([]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
};

function getBusinessDaysDiff(startDate, endDate) {
  var diffDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
  var weekends = 0;

  for (var i = 0; i <= diffDays; i++) {
    var currentDate = new Date(startDate.getTime() + i * (1000 * 3600 * 24));

    
    
        
       

    if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
      weekends++;
      console.log("w주말");
    }else{
      for (let i=0;i<holidays.length;i++){
        var from = new Date(holidays[i].start.date); // -1 because months are from 0 to 11
        var to = new Date(holidays[i].end.date); // -1 because months are from 0 to 11
        to.setDate(to.getDate() - 1);

        if (currentDate >= from && currentDate <= to){
          weekends++;
          console.log("휴일", holidays[i]);
          break;
  
        }
        
      }
    }
  }

  var businessDaysDiff = diffDays - weekends;
  console.log("diffDays,,.,.", diffDays, "weekends", weekends);
  return businessDaysDiff;
}


const [leaves, setLeaves] = useState([]);
const [user, setUser]= useState([]);
  useEffect(()=>{

    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
      .then(function (response){
      
        setUser(response.data[0]);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });

    if (doc_no!==undefined){
    
      axios.get('https://gw.thegmmedical.com:5003/api/getot/'+doc_no)
      .then(function (response){
        console.log("ot", response);
        //setUser(response.data[0]);
        if (response.data.length>0){
         
          let otdata = response.data;
          let temp =[];
          for (let i=0;i<otdata.length;i++){
            otdata[i].startHour = otdata[i].start_time.substring(0,2);
            otdata[i].startMin =otdata[i].start_time.substring(3,5);



            otdata[i]["startTime"] =  otdata[i].start_time;
            otdata[i]["endTime"] =  otdata[i].end_time;

            otdata[i].endHour = otdata[i].end_time.substring(0,2);
            otdata[i].endMin =otdata[i].end_time.substring(3,5);
          }
          initRow(otdata);
        }

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });

   
      }else{
      
          let initial = [{
            type:"휴일",
            workdate:"",
            startHour:"",
            startMin:"00",
            endHour:"",
            endMin:"00",
            content:""  
          }];

          console.log('hihishfisdhfisdhflsdif', initial);

          initRow(initial);
       
      
        
      }




        
      }, []);
  




  const addRowTable = () => {
    const data = {
      type:"휴일",
      workdate:"",
      startHour:"",
      startMin:"00",
      endHour:"",
      endMin:"00",
      content:""   };
    initRow([...rows, data]);
  };
  const delRowTable = () => {
    let temp = rows;
    temp.pop();
    initRow([...temp]);
  };


  

  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, e) => {
    const { name, value } = e.target;
    
    const data = [...rows];
    //console.log(data, name, value, i);

    
 
    if (name === "startHour"){
      data[i]["startTime"] = value + ":" + data[i].startMin;
    }else if (name === "startMin"){
      data[i]["startTime"] = data[i].startHour + ":" + value;

    }else if (name === "endHour"){
      data[i]["endTime"] = value + ":" + data[i].endMin;
    }else if (name === "endMin"){
      data[i]["endTime"] = data[i].endHour + ":" + value;

    }
    data[i][name] = value;
    
    /*let d1 = ot.workdate + " " + ot.startHour+":"+ot.startMin;
    let d2 = ot.workdate + " " + ot.endHour+":"+ot.endMin;
    let date1 = new Date(d1);
    let date2 = new Date(d2);

    var hours = Math.abs(date1 - date2) / 36e5;
    formData.append(`hours`,hours);*/


   
    initRow(data);
    values.arr = rows;
    onChange(values);

  };

  

  
  return (
    <div className="wrapper">

    
    <div className="" style={{justifyContent:"flex-start"}}>


   <div className="">
      {/*{show ? (
    <DraftsList onChange={(value) => {setSubject(value.subject);editorRef.current?.getInstance().setHTML(value.content);setDraftNo(value.draft_no)}} closePopup={() => toggleShow()}/>
      ) : null}*/}
 
  
            {showPopup ? (
  <LeaveHistory closePopup={() => togglePopup()} />
) : null}
            


 
  <TableRows
    rows={rows}
    tableRowRemove={tableRowRemove}
    onValUpdate={onValUpdate}
    leaves={leaves}
    user = {user}
  />
  
 

  <div className="btns" style={{display:"flex", justifyContent:"flex-end", margin:"20px auto", width:"100%"}}>
     
     <button className="btn" onClick={addRowTable}>
       추가
     </button>

     <button className="btn" onClick={delRowTable}>
       삭제
     </button>
   </div>
      
      
    

      </div>
   </div>
</div>
  );
};

export default Leave;