import {useState, useEffect, useRef} from "react";
import axios from "axios";
import "./css/largefile.css";
import moment from "moment";
axios.defaults.withCredentials = true;


const chunkSize = 5 * 1024 * 1024;


const LargeFileUpload = () => {


  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [uploadBtn, setUploadBtn] = useState(false);
  const [downloadableDay, setDownloadableDay] = useState("");


  const inputFile = useRef(null);
  function handleDrop(e) {
    e.preventDefault();
    setFiles([...files, ...e.dataTransfer.files]);
  }


  function readAndUploadCurrentChunk() {
    const reader = new FileReader();
    const file = files[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = e => uploadChunk(e);
    reader.readAsDataURL(blob);
  }


  function uploadChunk(readerEvent) {
    var files_array = [];


    const file = files[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set('name', file.name);
    params.set('size', file.size);
    params.set('currentChunkIndex', currentChunkIndex);
    params.set('totalChunks', Math.ceil(file.size / chunkSize));
    const headers = {'Content-Type': 'application/octet-stream'};
    const url = 'https://gw.thegmmedical.com:5003/uploadtest?'+params.toString();
    let cnt=0;
    axios.post(url, data, {headers})
      .then(response => {
        const file = files[currentFileIndex];
        const filesize = files[currentFileIndex].size;
        const chunks = Math.ceil(filesize / chunkSize) - 1;
        const isLastChunk = currentChunkIndex === chunks;
        if (isLastChunk) {
          file.finalFilename = response.data.finalFilename;
          file.filepath = response.data.filepath;
          files_array.push({filepath :response.data.filepath, name: file.name, size: file.size})
         
          setLastUploadedFileIndex(currentFileIndex);
          setCurrentChunkIndex(null);
          const isLastFile = currentFileIndex === files.length - 1;
          console.log(currentFileIndex);
          if (isLastFile){
           
            console.log("???");
            if (cnt===0){
              window.opener.postMessage({ msg: files_array }, '*');
            window.close();
            }
           
            cnt++;
           
          }
         
        } else {
          setCurrentChunkIndex(currentChunkIndex + 1);
        }
      });
  }


  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      setUploadBtn(false);
     


      return;
    }
    const isLastFile = lastUploadedFileIndex === files.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);


  useEffect(() => {
    if (files.length > 0) {
      setDownloadableDay(moment(addDays(7)).format('YYYY-MM-DD'));
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [files.length]);


  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);


  useEffect(() => {
    if (uploadBtn===true){
       if (currentChunkIndex !== null) {
        readAndUploadCurrentChunk();
      }
    }
   
   
  }, [currentChunkIndex, uploadBtn]);


  const openFile = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };
  const [fileSize, setFileSize] = useState(0);
  const [fileList, setFileList] = useState([]);
  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
 
    var newFiles = [];
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
     
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
     
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
       
     
    }
    setFileSize(fileSize+fs);
    var newArray = files.concat(newFiles);
    setFiles(newArray);
    event.target.value = '';
  };
  const handleRegisterButton = () => {
    setUploadBtn(true);
    console.log(uploadBtn);
  };
  const removeAllFile = () => {
    var newArr = [];
    setFiles(newArr);
    //setFileSize(0);
  };
  const removeFile = (i) => {
    setFiles([...files.filter((_, index) => index !== i)]);
    //setFileSize(fileSize-fileList[i].size);
  };
  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'


    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];


    const i = Math.floor(Math.log(bytes) / Math.log(k))


    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
  function addDays(days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }
 
  return (
    <div>
      <div className="upload_wrapper">
      <div class="popup_buttons">
      <div class="button" onClick={openFile}><p>파일추가</p></div>
      <div class="button" onClick={handleRegisterButton}><p>파일전송</p></div>


    </div>
<input type="file" id="files" name="files" className="file" onChange={fileChangedHandler} multiple ref={inputFile} style={{display: 'none'}}/>
      <div
        onDragOver={e => {setDropzoneActive(true); e.preventDefault();}}
        onDragLeave={e => {setDropzoneActive(false); e.preventDefault();}}
        onDrop={e => handleDrop(e)}
        className={"file_list dropzone" + (dropzoneActive ? " active" : "")}>
      <div className="top_menu">
        <div class="t0">
          <p onClick={removeAllFile}>X</p>
        </div>
        <div class="t1">
          <p>파일명</p>
        </div>
        <div class="t2">
          <p>업로드상태</p>
        </div>
        <div class="t3">
          <p>용량</p>
        </div>
       
        <div class="t5">
          <p>다운로드 가능기간</p>
        </div>
      </div>
        {files.length===0 && <p>Drop your files here</p>}
     
      <div className="">
        {files.map((file,fileIndex) => {
          let progress = 0;
          if (file.finalFilename) {
            progress = 100;
          } else {
            const uploading = fileIndex === currentFileIndex;
            const chunks = Math.ceil(file.size / chunkSize);
            if (uploading) {
              progress = Math.round(currentChunkIndex / chunks * 100);
            } else {
              progress = 0;
            }
          }
          return (


          <div className="files">
            <div class="t0">
              <p onClick={() => {removeFile(fileIndex);}}>X</p>
            </div>
            <div class="t1">
              <p>{file.name}</p>
            </div>
            <div class="t2">
              <div className="progress_bar">
                <div className={"progress " + (progress === 100 ? 'done' : '')}
                   style={{width:progress+'%'}}>{progress}%</div>
              </div>


            </div>
            <div class="t3">
              <p>{formatBytes(file.size)}</p>
            </div>
           
            <div class="t5">
              <p>{downloadableDay}</p>
            </div>
          </div>
           
          );
        })}
      </div>
      </div>
      </div>
    </div>
  );
}


export default LargeFileUpload;


