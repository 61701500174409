import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";

import "@fortune-sheet/react/dist/index.css"
import {GiHamburgerMenu} from "react-icons/gi";
import SendSchedule from "../components/SendSchedule";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";


import axios from "axios";
import moment from "moment";
import Overlay from "../components/Overlay";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../components/ScheduleDraftsList";
import ScheduleList from "../components/side/ScheduleList";
import { Tooltip } from "react-tooltip";
import { IoIosArrowDown } from "react-icons/io";
import {
  format
  
} from "date-fns";
import "./css/schedulew.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

var today = new Date();

const initialValues = {
  subject: "",
  group: "",
  allday:0,
  repeat: 0,
  start_date: format(today, "yyyy-MM-dd"),
  start_hour:"00",
  start_min:"00",
  end_date:"",
  end_hour:"00",
  end_min:"00",
  applicant:"",
  rep_cycle:"DAILY",
  rep_cycleN:"1",
  rep_end:"keep",
  rep_week_day: "",
  rep_type:"date",
  times:"0",
  rep_end_date:"",
  participants:[]
};
export default function BoardWrite() {
  const location = useLocation();
  const editorRef = useRef();
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [data, setData]=useState([]);
  const [members, setMembers] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [link, setLink]=useState("");

  const [fileArray, setArray] = useState([]);
  const [participants, setParticipants] = useState([]);
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [html, setHtml] = useState("");
  const [options, setOption] = useState("");
  const [showPopup, setPopup] = useState(false);
  const [editor, setEditor] = useState("toast");
  const [allday, setAllday] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [dateN, setDateN] = useState("");
  const [nth, setNth] = useState("");
  const [nthDay, setNthDay] = useState("");
  const [nthDayKr, setNthDayKr] = useState("");
  const [calendars, setCalendars]=useState([]);
  const [teamCalendars, setTeamCalendars]=useState([]);
  const [userCalendars, setUserCalendars]=useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [importance, setImportance] = useState("");
  const [user, setUser]=useState([]);
  const [users, setUsers]=useState([]);

  const togglePopup = () => {
    setPopup(!showPopup);
};
  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };
  const selectBoard = (e) => {
    e.preventDefault();
  };
  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };
  const selectEditor = (e) => {
    e.preventDefault();
    setEditor(e.target.value);
  };
  const toggleShow = () => {
    setShow(!show);
};
  const onTypeChange = e => {
    setType(e.target.value)
  }
  const endDateChange = e => {
    setEndDate(e.target.value)
  }
  var CLIENT_ID = "103373930583-rnu1qv7i5vr7ng7b2ma5o5r2p9vaiqu4.apps.googleusercontent.com"
  var API_KEY = "AIzaSyCDeeCLkx3dIqf-6aQ_CRJ6vopUxJXwnzE"
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar"
  var calendarID=location.state.schedule.calendarID;
  var eventID =location.state.schedule.googleID;
  var gapi = window.gapi

  const deleteEvent = () => {


    //gapi.load("client:auth2", () => { gapi.client.init({ clientId: "*****.apps.googleusercontent.com", plugin_name: "chat", }); });
 
 
 
     gapi.load('client:auth2', () => {
       console.log('loaded client')
 
       gapi.client.init({
         apiKey: API_KEY,
         clientId: CLIENT_ID,
         discoveryDocs: DISCOVERY_DOCS,
         scope: SCOPES,
         plugin_name:'GM Medical'
 
       })
 
       gapi.client.load('calendar', 'v3', () => console.log('bam!'))
       gapi.auth2.getAuthInstance().signIn()
       .then(() => {
         
        var request = gapi.client.calendar.events.delete({
          'calendarId': calendarID,
          'eventId' : eventID,
        })


        request.execute(event => {
          console.log(event)
          //window.open(event.htmlLink)
        })
       
     
 
     
       
     
 
       })
     })
   }
 




  useEffect(() => {
    console.log("schedule is...", location.state.schedule);

    


    if (location.state.schedule.content_type === "excel"){
      //const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
      
      const htmlData = JSON.parse(location.state.schedule.content);
      htmlData.showGridLines=0;
      htmlData.ch_width = 600;
      console.log("htmldata", htmlData);
      setHtml(htmlData);
  
    }
    axios.get("https://gw.thegmmedical.com:5003/api/getuser").then((response) => {
      // setNotice(response.data);
      setUser(response.data[0]);
       
     });
    axios.get("https://gw.thegmmedical.com:5003/api/getcalendar").then((response) => {
      // setNotice(response.data);
      setCalendars(response.data);
       
     });

     if (location.state.schedule.work_no !== null){
      axios.get("https://gw.thegmmedical.com:5003/api/get_importance/"+location.state.schedule.work_no).then((response) => {
      // setNotice(response.data);
      setImportance(response.data[0].importance);
       
     });
     }
     axios.get("https://gw.thegmmedical.com:5003/api/get_teamcalendar").then((response) => {
      // setNotice(response.data);
      setTeamCalendars(response.data);
       
     });
     axios.get("https://gw.thegmmedical.com:5003/api/get_user_calendar").then((response) => {
      // setNotice(response.data);
      setUserCalendars(response.data);
       
     });
     console.log("스케쥴", location.state.schedule);
     
     if (location.state.schedule.participants !== ""){
      let participants = JSON.parse(location.state.schedule.participants);
      let user_nos = [];
      if (participants !== null && participants.length>0){
      for (let p = 0;p<participants.length;p++){
      let participant = participants[p];
      console.log("참석자ㅛㅇ", participant);
      let participant_no = participant.substring(1, participant.length-1);
      console.log(participant);
      user_nos.push(participant_no);
      }
    }
    const data ={
      user_nos : user_nos
    }
    var people =  [];


    console.log("user_nos", user_nos);


    axios.get('https://gw.thegmmedical.com:5003/api/getusers').then(function (response){
      let users = response.data;

      
    var p = user_nos.map(function(item) {
      let val;
      var result = users.filter(obj => {
        
        return obj.user_no == item;
      })

      
      if (result.length>0){
        val =result;
      }else{
        val = [{email:item}];
      }

      return val;
    });

    console.log("p", p);
    setParticipants(p);
    })
    .catch(function (error){
      console.log(error);
    })
      .then(function (){
    });


    /*axios.post("https://gw.thegmmedical.com:5003/api/get_participants", data).then((response) => {
      people = response.data;
      setParticipants(response.data);
       
     });*/
    }
    
    
     let schedule = location.state.schedule;
     
    if (schedule.start_hour === 0){
      schedule.start_hour = "00";
    }
    if (schedule.start_min === 0){
      schedule.start_min = "00";
    }
    if (schedule.end_hour === 0){
      schedule.end_hour = "00";
    }
    if (schedule.end_min === 0){
      schedule.end_min = "00";
    }
     let scheduleInfo = {
      subject: schedule.subject,
      group: schedule.calendar_no,
      allday:schedule.allday,
      repeat: schedule.is_repeat,
      start_date: schedule.start_date,
      start_hour:schedule.start_hour,
      start_min:schedule.start_min,
      end_date:schedule.end_date,
      end_hour:schedule.end_hour,
      end_min:schedule.end_min,
      applicant:"",
      rep_cycle:schedule.rep_cycle,
      rep_cycleN:schedule.rep_cycleN,
      rep_end:schedule.rep_end,
      rep_week_day: schedule.rep_week_day,
      rep_type:schedule.rep_type,
      times:schedule.times,
      rep_end_date:schedule.rep_end_date,
      content: schedule.content,
      participants:people
    };
    setValues(scheduleInfo);
    if (schedule.is_repeat===1){
      setRepeat(true);
     }
     if (schedule.allday===1){
      setAllday(true);
     }
     axios.get("https://gw.thegmmedical.com:5003/scheduleview/"+location.state.schedule.s_id)
     .then(function (response){
       console.log(response);
       setData(response.data);console.log("data.data...", response.data);
   
     })
     .catch(function (error){
       console.log(error);
     })
     .then(function (){
       //always executed
     });

     /*const output = document.createElement('p')
     var section1s = document.getElementsByClassName("write_table");
     console.log(renderToString(section1s));*/
     

}, []);

function getNth(dat) {
  var days = ['일', '월', '화', '수', '목', '금','토'],
      days_en  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
      d    = dat ? dat instanceof Date ? dat : new Date(dat) : new Date(),
      date = d.getDate(),
      day  = d.getDay(),
      n    = Math.ceil(date / 7);
  setNth(n);
  setNthDay(days_en[day]);
  setNthDayKr(days[day]);
  return n + ' ' + days[day];
}

const handleInputChange = (e) => {
  //const name = e.target.name 
  //const value = e.target.value 
  const { name, value } = e.target;

  setValues({
    ...values,
    [name]: value,
  });

  if (name === "rep_cycle" && value=== "monthly"){
    let d1 = values.start_date.split("-");
    setDateN(d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }else if (name === "rep_cycle" && value=== "yearly"){
    let d1 = values.start_date.split("-");
    setDateN(d1[1] +"-"+ d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }

  if (name === "start_date" && values.rep_cycle=== "monthly"){
    let d1 = value.split("-");
    setDateN(d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }else if (name === "start_date" && values.rep_cycle=== "yearly"){
    let d1 = value.split("-");
    setDateN(d1[1] +"-"+ d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }

  if (name === "end_date"){

    setValues({
      ...values,
      rep_end: "date",
    });
    values.rep_end = "date";
    setValues({
      ...values,
      [name]: value,
    });

  }
  if (name === "rep_end" && value === "keep"){
    setValues({
      ...values,
      end_date: "",
      times:"",
    });
    values.end_date = "";
    values.times = "";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "times" && value !== ""){
    setValues({
      ...values,
      end_date: "",
      rep_end: "times",
    });
    values.end_date = "";
    values.rep_end = "times";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "rep_cycle" && value === "weekly"){
    let date = values.start_date.split("-");
    let from = new Date(date[0], parseInt(date[1]) - 1, date[2]);
    let day = from.getDay();
    let days  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
    setValues({
      ...values,
      rep_week_day: days[day],
    });
    values.rep_week_day = days[day];
    setValues({
      ...values,
      [name]: value,
    });
  }
};
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const subjectChange = (event) => {
    setSubject(event.target.value);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };



function delSchedule() {
  var result = window.confirm("일정을 정말 삭제하시겠습니까?");
  if(result){
    const data={
      s_id : location.state.schedule.s_id
    }
    axios.post(
      "https://gw.thegmmedical.com:5003/api/delete_schedule",
      data
    ).then(( res ) => {

      if (location.state.schedule.googleID !==null && location.state.schedule.googleID !=="" && location.state.schedule.googleID !==undefined){
        deleteEvent();
      }
           
      navigate("/schedule");
    });
  }
}

function shareSchedule(){

}
 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {

    
    
    console.log(editorRef.current?.getInstance().getHTML());

    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`subject`, values.subject);
    formData.append(`group`, values.group);
    formData.append(`repeat`, repeat);
    formData.append(`allday`, allday);

    formData.append(`start_date`, values.start_date);
    formData.append(`start_hour`, values.start_hour);
    formData.append(`start_min`, values.start_min);

    if (repeat === true && values.end_date === ""){
      formData.append(`end_date`, "2099-12-31");
    }else{
      formData.append(`end_date`, values.end_date);
    }
    
    formData.append(`end_hour`, values.end_hour);
    formData.append(`end_min`, values.end_min);

    formData.append(`rep_cycle`, values.rep_cycle);
    formData.append(`rep_cycleN`, values.rep_cycleN);
    formData.append(`rep_end`, values.rep_end);
    formData.append(`rep_week_day`, values.rep_week_day);

    if (values.rep_type === "date"){
      formData.append(`rep_month_type`, "date");
      formData.append(`rep_type`, dateN);
    }else{
      formData.append(`rep_month_type`, "nthday");
      formData.append(`rep_type`, nth+"-"+nthDay);
    }
    formData.append(`times`, values.times);
    formData.append(`rep_end_date`, values.rep_end_date);
    let user_nos = [];
    for (let i =0;i<values.participants.length;i++){
      user_nos.push("("+values.participants[i].user_no+")");
    }
    formData.append(`participants`, JSON.stringify(user_nos));

  
    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/scheduleinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/schedule";
          //console.log("...." + data);
        });
    };

   fetch();
   


  
  };

  const handleDraftButton = () => {
 
    
    console.log(editorRef.current?.getInstance().getHTML());

    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`subject`, values.subject);
    formData.append(`group`, values.group);
    formData.append(`repeat`, repeat);
    formData.append(`allday`, allday);

    formData.append(`start_date`, values.start_date);
    formData.append(`start_hour`, values.start_hour);
    formData.append(`start_min`, values.start_min);

    if (repeat === true && values.end_date === ""){
      formData.append(`end_date`, "2099-12-31");
    }else{
      formData.append(`end_date`, values.end_date);
    }
    
    formData.append(`end_hour`, values.end_hour);
    formData.append(`end_min`, values.end_min);

    formData.append(`rep_cycle`, values.rep_cycle);
    formData.append(`rep_cycleN`, values.rep_cycleN);
    formData.append(`rep_end`, values.rep_end);
    formData.append(`rep_week_day`, values.rep_week_day);

    if (values.rep_type === "date"){
      formData.append(`rep_month_type`, "date");
      formData.append(`rep_type`, dateN);
    }else{
      formData.append(`rep_month_type`, "nthday");
      formData.append(`rep_type`, nth+"-"+nthDay);
    }
    formData.append(`times`, values.times);
    formData.append(`rep_end_date`, values.rep_end_date);
    let user_nos = [];
    for (let i =0;i<values.participants.length;i++){
      user_nos.push("("+values.participants[i].user_no+")");
    }
    formData.append(`participants`, JSON.stringify(user_nos));

  
    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/schedule_draftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/schedule";
          //console.log("...." + data);
        });
    };

   fetch();
   



  };

 

  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
  window.addEventListener('message', (event) => {
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      var tempArray = members;
      for (let i=0;i<temp.length;i++){
        if (!tempArray.includes(temp[i])){
          
          tempArray = [...tempArray, temp[i]];
        }
      }
      const map = new Map(
        tempArray.map(obj => [obj.user_no, obj])
      );

      const deduplicatedArr = [...map.values()];
        console.log(deduplicatedArr);
      setValues({
        ...values,
        participants: deduplicatedArr,
      })
      values.participants = deduplicatedArr;
      
      
    }
 
  });

  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const excelInitial = {
    name: "Sheet1",
    config: {
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
      },
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 38,
    status: 1,
    ch_width: 1361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    
    scrollLeft: 0,
    scrollTop: 0
  };
  

  
  
  function checkExtension(filename){
    var parts = filename.split('.');
    var extension = parts[parts.length-1];
    if (extension === "doc" || extension === "docx" || extension === "docm" || extension === "dot" || extension === "dotx"
    || extension === "odt" || extension === "xls" || extension === "xlsx" || extension === "xlsm"|| extension === "xlsb"
    || extension === "ppt"|| extension === "pptm"|| extension === "pptx"){
      return true;
    }else{
      return false;
    }
  }
  function absence(){
    let p = JSON.parse(location.state.schedule.participants);
    if (participants.length>0){
      var filtered = p.filter( el => el.user_no !== user.user_no);
      let user_nos = [];
      for (let i =0;i<filtered.length;i++){
        user_nos.push("("+filtered[i].user_no+")");
      }
      console.log("participants", p, "user.user_no", user.user_no, "filtered", filtered, "user_nos", user_nos);
      const data={
        participants : JSON.stringify(user_nos),
        s_id : location.state.schedule.s_id
      }
      axios
      .post("https://gw.thegmmedical.com:5003/api/participants_update", data )
      .then(({ data }) => {
        alert("적용되었습니다.");
        setParticipants(filtered);
      
        //window.location.href="https://gw.thegmmedical.com/schedule";
        //console.log("...." + data);
      });
    }
  
  }
  
  function filterArr(arr) {
    var x;
    for(x = 0; x < arr.length; x++)
    {
      arr[x]= arr[x].replace('(', '');
      arr[x]= arr[x].replace(')', '');

  

      
    }
    return arr
}

  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <ScheduleList/>
        </div>
      )}
     <div className="content-wrap">
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
        <div className="side-menu">
      <ScheduleList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>
      </div>
      <div className="main_content">
      {showPopup ? (
  <SendSchedule values = {values}
  onChange={(value) => setData(value.data)} closePopup={() => togglePopup()} />
) : null}
<Overlay isOpen={isOpen} onClose={toggleOverlay} link = {link}>
  <h1>Content in overlay</h1>
</Overlay>
      <h3>일정 상세</h3>
      
    

      <div className="btns" style={{display:"flex", justifyContent:"space-between"}}>
              <div className="left">

              
              <Link to ="/schedule_u" state={{schedule:location.state.schedule, files : data}}>
              <button className="btn" style={{marginRight:"10px"}}>수정</button>
              </Link>
              <button className="btn" style={{marginRight:"10px"}} onClick={()=>delSchedule()}>삭제</button>

              <button className="btn" style={{marginRight:"10px"}} onClick={()=>togglePopup()}>일정공유</button>

              

              {JSON.parse(location.state.schedule.participants).length>0 && (
                 <Link to="/write" state={{ org_to : filterArr(JSON.parse(location.state.schedule.participants)) }} >
              <button className="btn" style={{marginRight:"10px"}}>참석자알림</button></Link>
              )}
           {/** 
             {(participants.filter(e => e.user_no === user.user_no).length > 0) && (
              <button className="btn" style={{marginRight:"10px"}} onClick={()=>absence()}>불참</button>
             )}
            */}
              {location.state.schedule.work_no !==null && (
                <Link to ="/workview" state={{ subject: "", importance: importance, no: location.state.schedule.work_no }}>
                <button className="btn">업무로 이동</button></Link>
              )}
              </div>

          
            </div>
      <table className="write_table schedule view" style={{width:"100%"}}>

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  <tr>
  <td class="table_title2" nowrap="">
    제목
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <span>{values.subject}</span>
    </div>
    </td>
  </tr>

  <tr>

  <td class="table_title2" nowrap="">
    캘린더
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
     
     <select
                        id="u_shcate"
                        name="group"
                        className="select"
                        title="검색항목 선택"
                        value={values.group}
                        onChange={handleInputChange}
                        disabled
                      >
                        <option value="all">캘린더</option>
                        {calendars.map((element,index)=>(
                          <option value={element.no}>{element.name}</option>
                          ))}
                        {teamCalendars.map((element,index)=>(
                          <option value={element.no}>{element.name}</option>
                        ))}
                        {userCalendars.map((element,index)=>(
                          <option value={element.no}>{element.name}</option>
                        ))}
                      </select>
    </div>
    </td>
  </tr>
  

  <tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    일시
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <div className="date"> <label for="allday">종일</label>
    <input type="checkbox" name="allday" value={allday} onChange={()=>setAllday(!allday)} checked={allday} disabled></input>
    <label for="repeat">반복</label>
    <input type="checkbox" name="repeat" value={repeat} onChange={()=>setRepeat(!repeat)} checked={repeat} disabled></input></div>
    <div className="date">
      <span>시작 </span>
      <span>&nbsp; :&nbsp; {values.start_date}</span>
      {!allday && (<span>&nbsp; &nbsp;{values.start_hour} : {values.start_min}</span>)}
    
      
      </div>
    <div className="date"><span>종료 </span> 
    <span>&nbsp; :&nbsp; {values.end_date}</span>
    {!allday && ( <span>&nbsp; &nbsp;{values.end_hour} : {values.end_min}</span>)}

    </div>
    </div>
    </td>
  </tr>

{repeat && (
<table className="repeat" style={{width:"100%", margin:"20px 0"}}>
<tr>
<th class="table_title2" nowrap="">
  반복 주기
</th>
<td class="">

  {values.rep_cycle === "DAILY" && (<span>매일</span>)}
  {values.rep_cycle === "weekly_weekday" && (<span>매주 월-금</span>)}
  {values.rep_cycle === "WEEKLY" && (<span>매주</span>)}
  {values.rep_cycle === "MONTHLY" && (<span>매월</span>)}
  {values.rep_cycle === "YEARLY" && (<span>매년</span>)}


{values.rep_cycle === "WEEKLY" && (
<div style={{marginLeft:"10px", display:"flex"}}>
{values.rep_week_day === "mon" && (<span>월요일</span>)}
{values.rep_week_day === "tue" && (<span>화요일</span>)}
{values.rep_week_day === "wed" && (<span>수요일</span>)}
{values.rep_week_day === "thr" && (<span>목요일</span>)}
{values.rep_week_day === "fri" && (<span>금요일</span>)}
{values.rep_week_day === "sat" && (<span>토요일</span>)}
{values.rep_week_day === "sun" && (<span>일요일</span>)}

  </div>
)}

{values.rep_cycle === "monthly" && (

<select name="rep_type" className="formInput" value={values.rep_type} onChange={handleInputChange}>
<option value="date"> {dateN}일</option>
<option value="nthday">매월 {nth}번째 {nthDayKr}요일</option>
</select>


)}

{values.rep_cycle === "yearly" && (

<select name="rep_type" className="formInput" value={values.rep_type} onChange={handleInputChange}>
<option value="date"> {dateN.split("-")[0]}월 {dateN.split("-")[1]}일</option>
<option value="nthday"> {dateN.split("-")[0]}월 {nth}번째 {nthDayKr}요일</option>
</select>


)}


</td>


<th class="table_title2 pc" nowrap="">
  주기
</th>
<td class="pc">
<span>{values.rep_cycleN}
{values.rep_cycle === "DAILY" && (<span>일</span>)}
{values.rep_cycle === "weekly_weekday" && (<span>주</span>)}

{values.rep_cycle === "WEEKLY" && (<span>주</span>)}
{values.rep_cycle === "MONTHLY" && (<span>개월</span>)}
{values.rep_cycle === "YEARLY" && (<span>년</span>)}

</span>
</td>

</tr>


<tr className="table_row mobile">
<th class="table_title2" nowrap="">
  주기
</th>
<td class="">
<span>{values.rep_cycleN}
{values.rep_cycle === "DAILY" && (<span>일</span>)}
{values.rep_cycle === "weekly_weekday" && (<span>주</span>)}

{values.rep_cycle === "WEEKLY" && (<span>주</span>)}
{values.rep_cycle === "MONTHLY" && (<span>개월</span>)}
{values.rep_cycle === "YEARLY" && (<span>년</span>)}

</span>
</td>
</tr>
<tr>

<th class="table_title2" nowrap="">
  종료일
</th>
<td class="">
<div className="input-group radio" style={{display:"flex"}}>
     
        {values.rep_end === "date" && (
          <span style={{width:"auto", display:"flex", alignItems:"center"}}>
        {values.end_date} 에 종료
        </span>

        )}
        {values.rep_end === "times" && (
          <span style={{width:"auto", display:"flex", alignItems:"center"}}>
        {values.times} 번 이후
        </span>

        )}
        {values.rep_end === "keep" && (
          <span style={{width:"auto", display:"flex", alignItems:"center"}}>
          계속 반복
        </span>

        )}
        
  </div>
</td>
</tr>

</table>

)}


  <tr>
<td class="table_title2" nowrap="">
  참석자
</td>
<td class="table_list2_left">
        <div className="input-group">
        <div style={{display:"flex",     alignItems:"center"}}>
              {participants.length>0 && participants.map((element,index)=>(
                <li key={element[0].user_no}>
                {element[0].name} [{element[0].email}]; &nbsp;
              </li>
              ))}
              
        </div>

        

      </div>
      
		</td>
</tr>
</table>

     <div id="response">{links}</div>
 <div className="" style={{  margin: "20px auto", padding:"20px", height:"500px", border:"1px solid #ddd"}}>

 {(data !==undefined && data.length>0) &&   
<div className="attachment">
  <IoIosArrowDown onClick={() => setToggle(!toggle)} /><span>첨부파일 {data.length}개 </span>
  {toggle && (

  <div className="attachment_box">
    {data.map((element, idx) => {
    return <div><a href={"https://gw.thegmmedical.com/uploads/schedule/"+location.state.schedule.s_id+"/"+element.filename} download>{element.filename} &nbsp;&nbsp;&nbsp;
    <span className="attachment_size">{element.size} Bytes</span></a> &nbsp;&nbsp;&nbsp;
      {checkExtension(element.filename) && (
        <button onClick={()=>{toggleOverlay(); setLink("https://gw.thegmmedical.com/uploads/schedule/"+location.state.schedule.s_id +"/"+element.filename);}}>오피스뷰어로 보기</button>
      )}  
</div>
    })}
    </div>)}
  </div>

  }
  {location.state.schedule.content_type !== "excel" && (
      <div className="toastui-editor-contents" dangerouslySetInnerHTML={ {__html: location.state.schedule.content} }></div>
    )}
    {html!=="" && (
      <div style={{width:"100%", height:"800px"}} draggable={false}>
      <Workbook onOp={console.log} data={[html]} draggable={false} onChange={(data)=>{console.log("changed, ", data);}} onContextMenu={e=>{e.preventDefault();}} showToolbar={false} showFormulaBar={false} showSheetTabs={false}
      allowEdit={false} rowHeaderWidth={0} columnHeaderHeight={0}/>

      </div>
    )}
    </div>
  
   
      </div>
    </div>
    </div>
    </div>
  );
}
