import { menuItems } from '../menuItems';
import MenuItems from './MenuItems';
import "./css/navbar.css";
import userImg from "./img/user.png";
//import Modal from "react-overlays/Modal";
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { BiBell, BiGroup } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaCalendarDay } from "react-icons/fa";
import { BsPencilSquare } from "react-icons/bs";
import { MdWork } from "react-icons/md";
import { AiFillSetting } from "react-icons/ai";
import { ImHome } from "react-icons/im";
import { PiSquaresFour } from "react-icons/pi";
import { RiAdminFill } from "react-icons/ri";
import AutoComplete from "../components/AutoComplete";
import { HiMail, HiDocumentText, HiOutlineLogout, HiSearchCircle } from "react-icons/hi";
import { MdGroups } from "react-icons/md";


import CoworkerInfo from "../components/CoworkerInfo";


let currentLink = "";

const Navbar = ({showSidebar}) => {
  const [isActive, setIsActive] = useState(false);
  const [nots, setNots] = useState([]);
  const [empty, setEmpty] = useState(true);
  const [showNavbar, setShowNavbar] = useState(false)
  const [user, setUser] = useState([])
  const [admin, setAdmin] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [coworker, setCoworker] = useState();

  const [unseen, setUnseen] = useState(0);
  const [unseenBoard, setUnseenBoard] = useState(0);
  const [signDocs, setSignDocs] = useState(0);
  const [folder, setFolder] = useState("all");
  let body  = document.getElementsByTagName("BODY")[0];let width = body.offsetWidth;


  const handleShowNavbar = () => {
    
    if (document.getElementsByClassName("side-mobile-menu")[0] !==undefined){
    if (showNavbar === false){
      document.getElementsByClassName("side-mobile-menu")[0].style.display='none';
    }else{
      document.getElementsByClassName("side-mobile-menu")[0].style.display='block';
    }
    }
    setShowNavbar(!showNavbar);

    setShowPopup(false);
  }


  useEffect(()=>{


    const interval = setInterval(() => {  
      axios.get("https://gw.thegmmedical.com:5003/api/get_notifications").then((response) => {
        // setNotice(response.data);
       // console.log("알림", response.data);
        if (response.data.length === 0){
          setEmpty(true);
        }else{
          setEmpty(false);
        }
        setNots(response.data);
        
      });
  
  
  }, 1000);
  return () => {
    clearInterval(interval);
  };




 


  },[])


  useEffect(()=>{
    if(currentLink !== window.location.href) {currentLink=window.location.href};
     
    axios.get('https://gw.thegmmedical.com:5003/api/adminCheck')
    .then(function (response){
      if (response.data.length>0){
        setAdmin(true);
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });
    fetch("https://gw.thegmmedical.com:5003/api/getuser", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("user...", json[0])
        setUser(json[0]);
        
       
      })
      .catch(() => {});

    var currentURL = window.location.pathname;
    currentURL= currentURL.substring(1);
    let subBefore= currentURL.substring(0, currentURL.indexOf('/'));
    console.log("subBefore", currentURL);
    
    //if (subBefore !== "list" && currentURL !=="write"){
    if (subBefore !== "list"){

  /*
    axios.post("https://gw.thegmmedical.com:5003/api/getnotice").then((response) => {
      // setNotice(response.data);
      console.log("알림", response.data);
     
      
    });
  */
    }

   


    axios.get("https://gw.thegmmedical.com:5003/api/unseen_num", {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      console.log("unseen_num,...", data);
      setUnseen(data.unseen)
    });


    axios.get("https://gw.thegmmedical.com:5003/api/unseen_board", {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {

      console.log("unseenboard", data);
      setUnseenBoard(data[0].unread)
    });


    axios.get('https://gw.thegmmedical.com:5003/api/get_main_docs')
    .then(function (response){
      console.log("hey result is!!!!!!!!!!!!", response.data);
      //setAbsent(response.data);
      //setLine(response.data[1]);
      setSignDocs(response.data.length);
     
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });



    axios.get('https://gw.thegmmedical.com:5003/api/get_default_mail')
    .then(function (response){
      console.log("default setting,,,,,,", response.data[0]);
     
      if (response.data.length>0){


        setFolder(response.data[0].start_box);
       
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

  },[]);
  /*useEffect(() => {
    axios.get("https://gw.thegmmedical.com:5003/api/getleavehistory").then((response) => {
        console.log(response.data);
        setData(response.data);
      });
  }, []);*/
  function logout(){
    axios.get("https://gw.thegmmedical.com:5003/api/logout").then((response) => {
      console.log(response.data);
      alert(response.data);
      window.location.assign("https://gw.thegmmedical.com/login");
      //setData(response.data);
    });
  }
  function readNotice(id){
    const data ={
      id: id
    }
    axios.post("https://gw.thegmmedical.com:5003/api/readnotice", data).then((response) => {
      console.log(response.data);
      //setData(response.data);
    });
  }


  const togglePopup = () => {
    setShowPopup(!showPopup);
};

function popupwindow(url, title, w, h) {
  var left = (window.screen.width/2)-(w/2);
  var top = (window.screen.height/2)-(h/2);
  return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
}       

  return (
    <nav>
      {showNavbar && (
      <div class="main_menu">   

{showPopup ? (
  <CoworkerInfo data={coworker} closePopup={() => togglePopup()} />
) : null}

      <div className="work search_user mobile" style={{background:"#fff",
    alignItems:"center"}}>
      <h3 style={{width:"80px"}}><HiSearchCircle/>사원검색</h3>
      <table width="100%">
		<tr style={{display:"flex"}}>
    <AutoComplete onChange={(value) => {setCoworker(value);console.log("hihi", value)}} style={{width:"85%"}}/> 
    <button onClick={()=>{
      if (coworker!==undefined){togglePopup()}}}
      style={{width:"15%", minWidth:"50px"}}>보기</button>

    </tr>
		</table>
    </div>



      <div className="main_menu_wrap">
      <div class="col">        
      <a href="/">
      <div class="card">            
      <div class="card_icon">                
      <ImHome/> 
      </div>            
      <div class="card-body tx-center">                
      <span>Home</span>            
      </div>        
      </div></a>    
      </div>   
      
      <div class="col">        
      <a href={"/list/"+folder}>
      <div class="card">            
      <div class="card_icon">                
      <HiMail/>      
      <span className="unread">{unseen}</span>    
   
      </div>            
      <div class="card-body tx-center">                
      <span>전자메일</span>            
      </div>        
      </div></a>    
      </div>    
      <div class="col">        
      <a href="/doc_main">
      <div class="card">            
      <div class="card_icon">                
      <HiDocumentText/>    
      <span className="unread">{signDocs}</span>    
    
      </div>            
      <div class="card-body tx-center">                
      <span>전자결재</span>           
       </div>        
       </div></a> 
       </div>   


       <div class="col">        
      <a href="/schedule">
      <div class="card">            
      <div class="card_icon">                
      <FaCalendarDay/>        
      </div>            
      <div class="card-body tx-center">                
      <span>일정관리</span>           
       </div>        
       </div></a> 
       </div>   


       <div class="col">        
      <a href="/board">
      <div class="card">            
      <div class="card_icon">                
      <BsPencilSquare/>   
      <span className="unread">{unseenBoard}</span>    
      </div>            
      <div class="card-body tx-center">                
      <span>게시판</span>           
       </div>        
       </div></a> 
       </div>   

       <div class="col">        
      <a href="/work">
      <div class="card">            
      <div class="card_icon">                
      <MdWork/>        
      </div>            
      <div class="card-body tx-center">                
      <span>업무관리</span>           
       </div>        
       </div></a> 
       </div>   


       <div class="col">        
      <a href="/address">
      <div class="card">            
      <div class="card_icon">                
      <BiGroup/>        
      </div>            
      <div class="card-body tx-center">                
      <span>인맥관리</span>           
       </div>        
       </div></a> 
       </div>   


       <div class="col">        
      <a href="/cx">
      <div class="card">            
      <div class="card_icon">                
      <BiGroup/>        
      </div>            
      <div class="card-body tx-center">                
      <span>거래처관리</span>           
       </div>        
       </div></a> 
       </div>   

       <div class="col">        
      <a href="/usersetting">
      <div class="card">            
      <div class="card_icon">                
      <AiFillSetting/>        
      </div>            
      <div class="card-body tx-center">                
      <span>환경설정</span>           
       </div>        
       </div></a> 
       </div>  


       <div class="col"  onClick={()=>logout()}>        
      <div class="card">            
      <div class="card_icon">                
      <HiOutlineLogout/>        
      </div>            
      <div class="card-body tx-center">                
      <span>로그아웃</span>           
       </div>        
       </div>
       </div>  

       {admin &&
       <div class="col">        
      <a href="/overview/add_admin">
      <div class="card">            
      <div class="card_icon">                
      <RiAdminFill/>        
      </div>            
      <div class="card-body tx-center">                
      <span>관리자</span>           
       </div>        
       </div></a> 
       </div>  
      }
       
       

       </div> 
      </div>

    )}
      
      <div className={`nav-elements`}>
      <ul className="menus">
        {menuItems.map((menu, index) => {
          return <MenuItems items={menu} key={index} />;
        })}
      </ul>
      </div>
      <div className="setting">  
      <div className="notification">
      <BiBell onClick={()=>{
        if (document.getElementsByClassName("side-mobile-menu")[0] !==undefined && width < 800){
         if (isActive === false){
          document.getElementsByClassName("side-mobile-menu")[0].style.display='none';
        }else{
          document.getElementsByClassName("side-mobile-menu")[0].style.display='block';
        }
        }
        setIsActive(!isActive);
      }}/>
      {!empty && (<span class="badge">{nots.length}</span>)}
      {isActive && (
        <div>
          <div class="not_dropdown">

          <div class="dropdown_header">
        
        <p style={{margin:"1em 0"}}>
          notifications
        </p>
      </div>
            <div className="dropdown_list">
            <ul class="">
              {nots.map((element,index)=>(
                <li class="dropdown_item" onClick={()=>readNotice(element.not_id)}>
                  <a href={element.not_url}>{element.not_content}</a>
             
                </li>
              ))}

              {empty && (
                <li class="dropdown_item">
                알림이 없습니다.
           
                </li>
              )}

              
            </ul>
          
           
          </div>
          </div>
        </div>
      )}
      </div>

      



      <a href="/usersetting">

      {user.profile_link !=="" && user.profile_link !==undefined ? 
      <img src={user.profile_link} alt="profile" 
        style={{
          borderRadius: "50%",
          width: 35,
          height: 35,
          background: "white",
          display: "block",
          objectFit:"cover",
          marginRight:"10px"
        }}
        className="big cc visa icon"
      />
: <img src={userImg} alt="profile" 
style={{
  borderRadius: "50%",
  width: 35,
  height: 35,
  background: "white",
  display: "block",
  marginRight:"10px"
}}
className="big cc visa icon"
/>
}
</a>
<span className="toAdmin" onClick={() => popupwindow("/org", "조직도", 800, 650)} alt=''><MdGroups/>조직도</span>

{admin && <a href="/overview/add_admin" className="toAdmin" style={{height:"31px"}}>
  <span style={{width:"100%"}}>Admin</span></a>}
        

      <button className="logout" onClick={()=>logout()}>logout</button>
      <div className="four_square"></div>
      
      
      <div className="menu-icon" onClick={handleShowNavbar}>
        <PiSquaresFour style={{margin:"0"}}/>
      </div>

      </div>
      
    </nav>
  );
};

export default Navbar;