import React, { useState, useEffect, useRef } from "react";


import "./css/checkline.css";
import axios from "axios";



import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';

import colorSyntax from '@toast-ui/editor-plugin-color-syntax';


const ConfirmBoard = ({ subject, status, b_no, bg_no, order_no, checker, closePopup }) => {

  const [statusList, setStatusList] = useState([]);

  const [showPopup, setPopup] = useState(false);
 
  const [values, setValues] = useState(false);

  const [user, setUser] = useState([]);

  const [list, setList] = useState([]);

  const [imgLink, setImgLink] = useState("");
  const [fileArray, setArray] = useState([]);
  const [next, setNext] = useState("");
  const [nextStatus, setNextStatus]= useState("");
  const editorRef = useRef();

  const togglePopup = () => {
    setPopup(!showPopup);
  };


  useEffect(() => {

   
    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
    
      setUser(response.data[0]);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    axios.post('https://gw.thegmmedical.com:5003/api/get_boardadmin',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_no : bg_no
      },
    })
    .then(function (response){
    
      if (response.data[0].status !==null){
        setStatusList(JSON.parse(response.data[0].status));
        var index = JSON.parse(response.data[0].status).indexOf(status);
        setNextStatus(JSON.parse(response.data[0].status)[index+1]);
      }else{
        setStatusList([]);
      }

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  }, []);
 

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 


  const handleInputChange = (event) => {
    //setReason(event.target.value);
    
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
    setValues[name] = value;

  };

  window.addEventListener('message', (event) => {
    if (event.data["toList"] !== undefined){
      
      console.log('event.data["toList"]', event.data["toList"]);

 
      setList(event.data["toList"]);

    }
    
  });

  let files = [];

 

  function checkBoard(){
    const data = {
      b_no : b_no,
      bg_no : bg_no,
      status: status,
      next : nextStatus,
      order_no : order_no,
      comment : editorRef.current?.getInstance().getHTML()
    }
    axios.post('https://gw.thegmmedical.com:5003/api/check_board', data)
    .then(function (response){
      alert("확인되었습니다.");
      window.location.reload(true);
     
  
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    //always executed
    });



  }

  return (
    <div className="popup">
      <div className="popup_inner" style={{width:"700px"}}>

        
        <h3 style={{textAlign:"left", marginLeft:"20px"}}>문서 확인</h3>
        <div className="checkline">

        <table className="table" style={{margin:"20px auto", width:"95%"}}>
          <tr>
            <th>제목</th>
            <td>{subject}</td>
          </tr>

          <tr>
            <th>확인자</th>
            <td>
            {checker} - {user.name} {user.job_name} ({user.d_name})님이 문서를 확인합니다.

            </td>
          </tr>

          <tr>
            <th>문서상태변경값</th>
            <td>
            <select name="name" className="formInput" style={{marginLeft:"10px"}} value={nextStatus}
                                    onChange={(e)=>setNextStatus(e.currentTarget.value)}>
              {statusList.map((element,index)=><option value={element}>{element}</option>)}  
            </select> 
            </td>
          </tr>

       


        </table>

        <Editor
      ref={editorRef} // DOM 선택용 useRef
      initialValue=""
      previewStyle="vertical" // 미리보기 스타일 지정
      height="500px" // 에디터 창 높이
      initialEditType="wysiwyg" //
      toolbarItems={[
        // 툴바 옵션 설정
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        ['table', 'image', 'link'],
        ['code', 'codeblock']
      ]}
      plugins={[colorSyntax]} 
      useCommandShortcut={false} // 키보드 입력 컨트롤 방지
      hooks={{
        addImageBlobHook: async (blob, callback) => {
          console.log(blob.size);
          if (blob.size > 5000000) {
            alert(
              "업로드에 실패했습니다. 이미지당 5MB까지 업로드할 수 있습니다."
            );
            return;
          }
          const formData = new FormData();
                  formData.append("img", blob);
                  
                  // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
                  // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...
                  await axios.post(
                    "http://gw.thegmmedical.com:5003/api/img",
                    formData
                  ).then(( res ) => {
                    console.log(res);
                    console.log(
                      "성공 시, 백엔드가 보내주는 데이터",
                      res.data.url
                    );

                    const IMG_URL = res.data.url;
                    setImgLink(IMG_URL);
                    // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
                    callback(IMG_URL, "test");
                    //alert(IMG_URL);
                    files.push(IMG_URL);
                    setArray(files);
                    //setArray((fileArray) => [...fileArray, IMG_URL]);
                    //alert("fileArray: ", files);
                    console.log(files);
                    var arrayLength = files.length;
                    //alert(arrayLength);
                    //alert("end");
                  
                  });
          //callback('http://gw.thegmmedical.com:5003/img/카레유.png', '카레유');
        }
      }}
    ></Editor>
      

        </div>
        <div className="buttons">
        <button onClick={()=>checkBoard()}>확인</button>
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>취소</button>
        </div>
        
      </div>
      
    </div>
  );
};

export default ConfirmBoard;