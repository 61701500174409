import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import BoardList from "../components/side/BoardList";
import axios from "axios";
import {GiHamburgerMenu} from "react-icons/gi";


//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  order_client: "",
  order_date: "",
  issuer:"",
  issuer_contact: "",
  hospital_name: "",
  addr:"",
  pic:"",
  pic_contact:0,
  delivery:""
};


function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {model, defect_code, description, note}= rowsData;
    return (
      <tr key={index}>
       
        <td style={{width:"15%"}}>
          {model}
           
        </td>
        <td style={{width:"15%"}}>
          {defect_code}
            
        </td>
        <td style={{height:"70px"}}>
         {description}
           
        </td>
        <td>
         {note}
           
        </td>

 

      
      </tr>
    );
  });
}

const Order = ({onChange}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

  
  
  const { state } = useLocation();






  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    values.arr = rows;
    onChange(values);

  };
 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {
    let data = state.data;
    setValues(data);
    axios.get('http://gw.thegmmedical.com:5003/api/get_inspect_results/'+data.no)
    .then(function (response){
      console.log("orders...........", response.data);
      let temp = response.data;
      if (response.data.length<10){
        let len = response.data.length;
        while (10-len>0){
        temp.push({model:'', defect_code:'',description:'', note:''});
        len++;
        }
      }
      initRow(temp);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
    
  }, []);


  const addRowTable = () => {
    const data = {
        model:'', defect_code:'',description:'', note:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    
    const data = [...rows];
    console.log(data, name, value, i);
    data[i][name] = value;
    initRow(data);

    values.arr = rows;
    onChange(values);

  };


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });
  
  const deleteThis = () => {
  
 
    const msg ={
      nos : [values.no]
    }
    var result = window.confirm("삭제하시겠습니까?");
    if(result){
    axios.post('http://gw.thegmmedical.com:5003/api/as_delete/inspect', msg)
    .then(function (response){
      console.log(response);
      navigate("/inspect");
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
 
 
 
   };
  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
    
<div className="content-wrap">

      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%", }}>
      <div className="side-menu">
      <BoardList changeBoard={(value)=>{console.log(value);}} changeNotice={(value)=>{console.log(value);}}/>
      </div>
      <div style={{width:"90%", margin:"0 auto"}}>
     

    

            <div className="order_write" style={{padding:"0", margin:"20px 5%"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>점검결과표</td>
                  
                  </tr>
                  <tr>
                
                  </tr>
                  
                </table>
            <table style={{marginTop: '1px', borderCollapse: 'collapse'}} className="forms">{/* Header */} 
              <thead style={{height:"40px"}}>
                <tr>
             
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td style={{background: 'white', width:"100%", display:"flex", padding: '0px !important', border: 'currentColor', textAlign: 'left', color: 'black', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'top'}}>
                    <table className="header" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '1px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병원명
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.hospital}
                          </td>

                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            점검업체
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.company}
                          </td>
                        </tr>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            사용부서 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.department}
                          </td>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            점검일자 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.inspect_date}
                          </td>
                          
                        </tr>
                        <tr>
                        <th style={{background: 'rgb(226, 226, 226)',padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                             확인자
                        </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.checked}

                          </td>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            점검자 
                          </th>
                          <td colSpan="3" style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.inspector}
                          </td>

                        </tr>
                     
                  
                      </tbody>
                    </table>

                  </td>
                  
                </tr>

                <tr>
             
                <table className="form_list order_list" style={{borderCollapse: 'collapse', width:"790px"}}>
                 
          <thead>
          <tr>
          <th>점검대상</th>
          <th colSpan={4}>점검결과</th>
          </tr>
            <tr>
              <th>모델(S/N)</th>
              <th>불량코드</th>
              <th>조치 및 의견</th>
              <th>비고</th>
            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />
           
          </tbody>
        </table>
                </tr>
              </tbody>
            </table>
          </span></div>
        
        
      </div>
      <div className="btns" style={{display:"flex", justifyContent:"center", margin:"20px 0"}}>
      <Link to="/inspect_update" state={{no:state.no, data: state.data}}><button className="btn" style={{marginRight:""}}>수정</button></Link>
      <button className="btn" style={{marginRight:""}} onClick={()=>deleteThis()}>삭제</button>

      </div>
      
      </div>


      
 
      
      </div>
    </div>
    </div>
    </div>
  );
};

export default Order;

