import React, { useState, useEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Link } from "react-router-dom";

import "./css/popup.css";
import axios from "axios";
import moment from "moment";



const BoardViewHistory = ({ b_no, data, dataType, closePopup }) => {
  
  const [boardViews, setBoardViews] = useState([]);
  const [CheckList, setCheckList] = useState([]);
  useEffect(() => {
    axios.get('https://gw.thegmmedical.com:5003/api/get_view/'+b_no)
      .then(function (response){
        console.log(response);
        setBoardViews(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
  }, []);

  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
  if (e.target.checked) {
    setCheckList([...CheckList, id]);
    console.log("checkList...", [...CheckList, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
  } else {
    setCheckList(CheckList.filter((checkedId) => checkedId !== id));
  }
    
 }

 function selectAll()  {
  const checkboxes 
       = document.getElementsByName('user');
  const all 
       = document.getElementsByName('alluser');
  //console.log(all[0].checked)
  checkboxes.forEach((checkbox) => {
    checkbox.checked = all[0].checked;
  })

    let array = [];  
    console.log("all[0].checked", all[0].checked);
    if (all[0].checked === true){
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="alluser"){
        array.push(parseInt(checkboxes[i].value))
        }
      }
    }
      

      setCheckList(array);

}


  return (
    <div className="popup">
      <div className="inner" style={{width:"600px", height:"auto", maxHeight:"500px", position:"relative",
      left:"50%", transform:"translateX(-50%) translateY(-50%)", top:"50%", overflowY:"auto"}}>

<Link to="/write" state={{ org_to : CheckList }} ><div style={{border:"1px solid #000", width:"80px", textAlign:"center", padding:"5px", marginBottom:"15px"}}
      >이메일 전송</div></Link>
      <table width="100%" cellspacing="0" cellpadding="0" border="0" bordercolor="black">
    	<tbody>
           
            <tr height="23">
                <th>
                <input type='checkbox'
                name='alluser' 
                value='userall'
                onClick={selectAll}/>  
                </th>
                <th nowrap="" class="table_title">부서</th>
                <th nowrap="" class="table_title">직위</th>
                <th nowrap="" class="table_title">이름</th>

                {dataType === "view" &&
                  <th nowrap="" class="table_title_rno">열람일자</th>
                }
                {dataType !== "view" &&
                  <th nowrap="" class="table_title_rno">연락처</th>
                }
                {dataType !== "view" &&
                  <th nowrap="" class="table_title_rno">이메일</th>
                }
            </tr> 
            {data.map((element,index)=>(
                  <tr height="23">
                    <td>
                    <input type='checkbox'
        name='user' 
        value={element.user_no}
        onChange={(e) => onChangeEach(e, element.user_no)} checked={CheckList.includes(element.user_no)}/>    

                    </td>
                  <td nowrap="" class="table_title">{element.d_name}</td>
                  <td nowrap="" class="table_title">{element.job_name}</td>

                  <td nowrap="" class="table_title">{element.name}</td>

                  {dataType === "view" &&
                  <td nowrap="" class="table_title">{moment(element.view_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                }
                {dataType !== "view" &&
                  <td nowrap="" class="table_title">{element.tel}</td>
                }
                {dataType !== "view" &&
                  <td nowrap="" class="table_title">{element.email}</td>
                }

              </tr> 
            ))}
     





    </tbody></table>
    
        <button style={{marginTop:"20px"}} onClick={closePopup}>닫기</button>
      </div>
      
    </div>
  );
};

export default BoardViewHistory;