import React, { useState, useEffect} from "react";



import FolderTree from 'react-folder-tree';

import "./css/popup.css";

import axios from "axios";



const AddDepartment = ({ selected, onChange, closePopup }) => {
  
  const [testData, setTestData] = useState([]);
  const [parent, setParent] = useState("");
  const [name, setName] =useState("");
  const [parentNo, setParentNo] = useState(0);
  const [lev, setLev] = useState(0);
  const [order, setOrder] = useState(0);
  const [no, setNo] = useState(0);

  const onTreeStateChange = (state, event) => console.log(state, event);

  function lookingForNodeWithParent( nodes, parentId ) {

    var arrayToReturn = [];
  
    for( var i = 0, length = nodes.length; i < length; i++ ) {
      if( nodes[i].d_parent === parentId ) {
          var node = nodes[i];
        
        arrayToReturn.push({
          name: node.d_name,
          parentNo : node.d_parent,
          num : node.d_no,
          level : node.d_level,
          children: lookingForNodeWithParent( nodes, node.d_no )
        });
      }
    }
    
    return arrayToReturn;
  }
  function showDepartment(){
    fetch("http://gw.thegmmedical.com:5003/api/getdepartment_group", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("hahahaha",json);  
  console.log(lookingForNodeWithParent( json, null ));
        //setBoxes(json[0].boxes);
        var array = lookingForNodeWithParent( json, null );
        //let newData = array.map(el => el[0]);
            //console.log(newData);
            onChange(array[0]);
        return setTestData(array[0]);

        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});
    } 
  useEffect(() => {
    console.log(selected);
    if (selected !== undefined){
      setName(selected.name);
      setNo(selected.num);
      setOrder(selected.order);
      const data ={
      
        parentNo : selected.parentNo,
       
      }
      axios.post('http://gw.thegmmedical.com:5003/api/getParent', data)
      .then(function (response){
        setParent(response.data[0].d_name);
        setParentNo(response.data[0].d_no);
        var department = document.querySelector('.department');

        var aTags = department.getElementsByTagName("span");
        var searchText = response.data[0].d_name;
        var found;
        
        for (var i = 0; i < aTags.length; i++) {
          if (aTags[i].textContent === searchText) {
            found = aTags[i];
            break;
          }
        }
        const boxes = document.querySelectorAll('.editableNameContainer');
        
        boxes.forEach(box => {
          box.style.backgroundColor = 'transparent';
        });
        found.style.backgroundColor = '#BEFDFD';
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
    showDepartment();
  }, []);

    const onNameClick = ({ defaultOnClick, nodeData }) => {
      defaultOnClick();
      //else.style(checked);
      const {
        // internal data
        path, name, num, level, isOpen, children
        // custom data
      } = nodeData;
    

      var aTags = document.getElementsByTagName("span");
var searchText = name;
var found;

for (var i = 0; i < aTags.length; i++) {
  if (aTags[i].textContent === searchText) {
    found = aTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('.editableNameContainer');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';
console.log(found);
      setParent(name);
      setParentNo(num);
      setLev(level);
      /*const tempArray = [...options, item];
      console.log(tempArray);
      setOptions(tempArray);*/



  };

  const onChangeName = (e) => {
    setName(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
  }
  const onChangeOrder = (e) => {
    setOrder(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
  }
  const addDepartment = () =>  {
    const data ={
      name : name,
      parent : parent,
      parentNo : parentNo,
      level : lev+1,
      num : no,
      order: order
    }
    axios.post('http://gw.thegmmedical.com:5003/api/add_department', data)
    .then(function (response){
      console.log(response);
      showDepartment();
      setParent("");
      setName("");
      setParentNo(0);
      setLev(0);
      setNo(0);
      setOrder(0);
      closePopup();
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  return (
    <div className="popup add_department">
      <div className="popup_inner" style={{height:"570px"}}>
        <h3 style={{textAlign:"left", marginLeft:"20px"}}>부서 추가/변경</h3>
        <table>
          <tr>
            <th>
            부서명
            </th>
            <td><input id="name" type="text" value={name} onChange={onChangeName}/> </td>
          </tr>

          <tr>
            <th>
              정렬순서
            </th>
            <td><input type="text" value={order} onChange={onChangeOrder}></input></td>
          </tr>

          <tr>
            <th>
              상위부서
            </th>
            <td><input type="text" value={parent}></input></td>
          </tr>

          <tr>
            <th>
              
            </th>
            <td>
            <div className="department">
            <FolderTree
      data={ testData }
      onChange={ onTreeStateChange }
      onNameClick={ onNameClick }
      showCheckbox={ false } 
      readOnly
      />
            </div>
           
            </td>
          </tr>

        </table>
        <div className="buttons">
        <button onClick={()=>addDepartment()}>저장</button>
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>취소</button>
        </div>
        
      </div>
      
    </div>
  );
};

export default AddDepartment;