

import { NavLink, Link,useParams, useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import './css/form.css';


axios.defaults.withCredentials = true; 





const LineList = () => {
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState();
  const [selectedName, setSelectedName] = useState("");
  const [lineList, setLineList] = useState([]);
  const [type, setType] = useState([]);
  const [value, setValue] = useState("");
  const [rows, initRow] = useState([]);

  useEffect(()=>{
    axios.get('http://gw.thegmmedical.com:5003/api/getlines')
    .then(function (response){
    console.log("hey result is!!!!!!!!!!!!", response.data);
    setLines(response.data);

    //setLine(response.data[1]);
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    
}, []);  

function showLineList(e){
  setSelectedLine(e.currentTarget.id);
  setSelectedName(e.currentTarget.className);
 
var trTags = document.getElementsByTagName("tr");
var searchText = e.currentTarget.id;
var found;

for (var i = 0; i < trTags.length; i++) {
  if (trTags[i].id === searchText) {
    found = trTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('tr');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';

  axios.get('http://gw.thegmmedical.com:5003/api/getlinelist/'+e.currentTarget.id)
    .then(function (response){
      console.log(response);
      setLineList(response.data);
      //window.location.href="/doc_form";
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
}

const typeSelect = (e) => {
  e.preventDefault();
  var selectedId = Number(e.currentTarget.className);
  console.log(e.currentTarget.className);
  var tmp = lineList;
  tmp[selectedId].type = e.target.value;
  setLineList([...tmp]);
};









function register(){
  window.opener.postMessage({ line_no: selectedLine, line_name : selectedName, line_list : lineList }, '*');
        
          
        
  window.close();
}

function popupwindow(url, title, w, h) {
  var left = (window.screen.width/2)-(w/2);
  var top = (window.screen.height/2)-(h/2);
  return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
} 


  return (
    <div className="wrapper">

    
    <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>


   <div  className="line_main" style={{width:"90%", margin:"30px auto", padding:"0 5px"}}>

    <h4>결재 라인 선택</h4>
  


<div className="row" style={{flexDirection:"column"}}>
<table width="100%" border="0" cellspacing="0" cellpadding="0" className="line_table">
<thead>	
    <tr>

    <th>번호</th>
    <th>결재라인명</th>
    <th>적용된 양식수</th>
    <th>내용</th>

    </tr>

</thead>
<tbody>

{lines.map((element, index)=>(
  <tr id={element.line_no} className={element.line_name} onClick={(e)=>showLineList(e)} style={{cursor:"pointer"}}>
  <td>{element.line_no}</td>
  <td>{element.line_name}</td>
  <td>{element.form_no}</td>
  <td>{element.line_content}</td>
  </tr>
))}


</tbody>
</table>

<h4>{selectedName}</h4>


<table width="100%" border="0" cellspacing="0" cellpadding="0" className="line_table">
<thead>	
    <tr>

    <th>순서</th>
    <th>타입</th>
    <th>부서명</th>
    <th>결재자</th>
    </tr>

</thead>
<tbody>

{lineList.map((element, index)=>(
  <tr>
  <td>{element.order_no}</td>
  <td>
  <select name="form_group" className={index} value={element.type || type} onChange={typeSelect}>
    <option value="결재">결재</option>
    <option value="전결">전결</option>
    <option value="승인">승인</option>
  </select>
  </td>
  <td>{element.d_name}</td>
  <td>{element.name} ({element.job_name})</td>
 
  </tr>
))}
</tbody>
</table>
</div>
<div className="btns" style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>
    <button className="btns" onClick={()=>register()}>결재라인 선택</button>

    </div>


   
      </div>
   </div>
    </div>
  );
};

export default LineList;

