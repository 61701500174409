import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";


import axios from "axios";

import {GiHamburgerMenu} from "react-icons/gi";

import * as XLSX from "xlsx";

import DraftsList from "../components/CSDraftsList";
import BoardList from "../components/side/BoardList";

import "./css/write.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  part:'',
  gm_code:'',
  symptom:'',
  cause:'',
  measure:'',
  final_comment:''
};


export default function BoardWrite() {
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [list, setList] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [jsonData, setJsonData] = useState();
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);

  const [values, setValues] = useState(initialValues);

  const [codeOptions, setCodeOptions] = useState([]);


  useEffect(() => {
    axios.get("http://gw.thegmmedical.com:5003/api/get_as_status/defects").then((response) => {
  // setNotice(response.data);
   setfilterData(response.data);
   if (response.data.length === 0) {
     setEmpty(true);
   } else {
     setEmpty(false);
     
   }
  });
 
  /*axios.get("http://gw.thegmmedical.com:5003/api/get_defects")
  .then((response) => {
    let options = [];
     for (let i=0;i<response.data.length;i++){

      let obj = {value: response.data[i].model, label:response.data[i].model, name:"model", brand:response.data[i].brand, equipment:response.data[i].equipment};
      options.push(obj);
     }
     setCodeOptions(options);
  });*/


 
}, []);

  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };

  const toggleShow = () => {
    setShow(!show);
};

  const { state } = useLocation();
  useEffect(() => {
  
}, []);
 
  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

 

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };





 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {
  
 
   
    axios.post("https://gw.thegmmedical.com:5003/api/as_insert/defects", values)
    .then(({ data }) => {
       console.log("what is this");
       if (draftNo !== undefined){
         axios.get("https://gw.thegmmedical.com:5003/api/delete_draft/defects_draft/"+draftNo)
         .then(({ data }) => {
           console.log("what is this");
         });
       }
       navigate("/as_status");
    });



  };
  const handleDraftButton = () => {
 
    axios.post("http://gw.thegmmedical.com:5003/api/draft_insert/defects_draft", values)
   .then(({ data }) => {
      console.log("what is this");
      alert("임시 저장되었습니다.");
    });


  };
  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const downloadEmployeeData = () => {
    fetch('http://gw.thegmmedical.com:5003/api/download')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = '이호MH호텔_셀렉 A컷.zip';
                    a.click();
                });
                //window.location.href = response.url;
        });
  }
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
  window.addEventListener('message', (event) => {
    if (event.data["msg"] !== undefined){
      var files = event.data["msg"];
      for (let i= 0;i<files.length;i++){
        string+='<a href = "http://gw.thegmmedical.com:5003/get/'+ files[i].name+'">'+files[i].name+'</a>'

      }
      console.log(string);
      var content = editorRef.current?.getInstance().getHTML();
      string+=content;
      editorRef.current?.getInstance().setHTML(string);
    }
 
  });

  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const handleInputChange = (e) => {
    var name, value;
    if (e.target === undefined){
      name= e.name;
      value = e.value;
    }else{
      name= e.target.name;
      value = e.target.value;
    }
    
    console.log("name....", name, "  value....", value);
    setValues({
      ...values,
      [name]: value,
    });
  };

 


  async function handleFileAsync(e) {

    const file = e.target.files[0];
    const exceldata = await file.arrayBuffer();
          
    const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});
  
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
      //console.log(htmlData);
  
    const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
      header:1,
      defval:"",
      raw: false,
      blankrows: false
    })
    
    var sheetnames = workbook.SheetNames;
 
    jsondata.shift();
    setJsonData(jsondata);
  
    console.log("data",jsondata);

  
    /*for (let i = 0;i<jsondata.length;i++){
      for (let j=0;j<jsondata[i].length;j++){   
      }
    }*/
  }
  function importExcel(){

    const data ={
      tablename : "defects",
      header: "part, gm_code, symptom, cause, measure, final_comment",
      arr : jsonData
    }
    console.log(data);
    axios.post('http://gw.thegmmedical.com:5003/api/add_excel_data', data)
    .then(function (response){
      console.log(response);
      navigate("/defect");
  
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
     <div className="content-wrap">
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
      <div className="side-menu">
      <BoardList changeBoard={(value)=>{console.log(value);}} changeNotice={(value)=>{console.log(value);}}/>
      </div>
      <div className="main_content cs_board">
      {show ? (
    <DraftsList onChange={(value) => {setValues(value);setDraftNo(value.no)}} closePopup={() => toggleShow()}/>
    ) : null}
      

      <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              <div className="left">

              
              <button className="btn" onClick={handleRegisterButton} style={{marginRight:"10px"}}>등록</button>

              <button className="btn" onClick={handleDraftButton}>임시저장</button>

              <input type="file" id="input_dom_element" onChange={handleFileAsync}/>

              <button onClick={()=>importExcel()}>Import</button>


              </div>

              <div className="right">
                <button onClick={() => {setShow(!show);}}>임시저장글</button> 
              </div>
            </div>

            <div style={{width:"100%", margin:"0 auto"}}>
            <div className="as_status">

            <table className="write_table responsive" style={{ margin:"10px 0", width:"100%"}}>

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  <tr>
  <td class="table_title2" nowrap="">
    부위
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="text" name="part" className="formInput" placeholder="" value={values.part}
            onChange={handleInputChange}></input>
    </div>
    </td>

  <td class="table_title2" nowrap="">
    GM 코드
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="text" name="gm_code" className="formInput" placeholder="" value={values.gm_code}
            onChange={handleInputChange}></input>
{/* 
<CreatableSelect options={codeOptions} 
styles={colourStyles} Clearable={false} name="gm_code" onChange={handleInputChange}
placeholder={'상태를 선택하세요'}
/>

*/}

    </div>
    </td>

  </tr>

  <tr>

  <td class="table_title2" nowrap="">
    증상
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="symptom" className="formInput" value={values.symptom}
            onChange={handleInputChange}></input> 
    </div>
    </td>

  <td class="table_title2" nowrap="">
    원인
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="cause" className="formInput" value={values.cause}
            onChange={handleInputChange}></input> 
    </div>
    </td>

 
  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    필요조치
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="measure" className="formInput" value={values.measure}
            onChange={handleInputChange}></input> 
    </div>
    </td>

   

  
  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    최종소견
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <textarea type="text" name="final_comment" className="formInput" value={values.final_comment}
            onChange={handleInputChange}></textarea> 
    </div>
    </td>

   

  
  </tr>
 


 
{list.length>0&&(
  <tr>

  <td class="table_title2" nowrap="">
    문서 분류
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <select name="name" className="formInput" value={option || ''}
                                    onChange={selectHandle}>
    {list.map((element,index)=><option value={element.name}>{element.name}</option>)}  
    </select> 
    </div>
    </td>
  </tr>
)}
  

  
 
      </table>

    
      <div className="button_wrap">
      <button className="submit" onClick={handleRegisterButton}>등록</button>
      {/*</form>*/}
      </div>
      </div>



     

    <div className="as_list">
      <h4>최근 10개 장비 추가 내역</h4>


      <table class="delivery table-hover bd-b">
									<thead class="thead-dark">
									<tr class="tx-center">
                    <th>모델명</th>
                    <th>장비명</th>
                    <th>담당/제조사</th>
                    <th>장비명(FULL)</th>
                    <th>비고</th>
        
                
									</tr>
									</thead>
									<tbody>
                 
                                    {isEmpty && (
                                  <tr style={{ textAlign: "center" }}>
                                    <td colSpan={40}>게시물이 없습니다.</td>
                                  </tr>
                                )}
                                {filterData
                                  .slice(0, 9)
                                  .map((element, index) => (
                                      <tr key={element.no}>
                                    
                                        

                                        <td>
                                        {element.part}
                                        </td>
                                      
                                        <td>
                                        {element.gm_code}
                                        </td>
                                        <td>
                                        {element.symptom}
                                        </td>
                                        <td>
                                        {element.cause}
                                        </td>
                                        <td>
                                        {element.measure}
                                        </td>
                                        <td>
                                        {element.final_comment}
                                        </td>
                                      </tr>)
                                     
                                    
                                  )}

									</tbody>
								</table>
          </div>
      </div>


      
     <div id="response">{links}</div>
 
      
      </div>
    </div>
    </div>
    </div>
  );
}
