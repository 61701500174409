import { useParams , Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import WorkList from "../components/side/WorkList";
import axios from "axios";
import "./css/work.css";
import FolderTree, { testData } from 'react-folder-tree';
import { HiPencilAlt } from "react-icons/hi";
import { BsPin } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";

import Pagination from "./Pagination";
import WorkReportWrite from "../components/WorkReportWrite";
import {
  startOfWeek,
  format,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  parse,
  getMonth,
  getYear,
  addWeeks,
  addYears,
  subWeeks,
  subDays,
  getWeek,
  isWeekend,
  getDay,
  differenceInCalendarWeeks,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears
  
} from "date-fns";
//import hi from '../excel/93.json';


const Board = () => {
    const [data, setData] = useState([]);

    const [isActive, setActive] = useState(false);
    var { periodical } = useParams();
    const [parent, setParent] = useState("");
    const [name, setName] =useState("");
    const [num, setNum] = useState(0);
    const [lev, setLev] = useState(0);
    const [selected, setSelected]= useState();
    const [search, setSearch] = useState("");
    const [boardN, setBoardN] = useState("");
    const [boardNum, setBoardNum] = useState();
    const [options, setOption] = useState("subject");
    const [filterData, setfilterData] = useState([]);
    const [dates, setDates] = useState([]);
    const [workNo, setWorkNo] = useState("all");
    const [works, setWorks] = useState([]);

    const [dNo, setDNo] = useState("");
    const [userNo, setUserNo] = useState("");

    const [users, setUsers] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [popup, setPopup] = useState(false);
    const [notice, setNotice] = useState([]);
    const [isEmpty, setEmpty] = useState(false);
    const [isPage, setIsPage] = useState(false);
    const [isloggedIn, setLoggedIn] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [endDate, setEndDate] = useState("");
    const [myself, setMyself] = useState(0);
    const [sidebar, setSidebar] = useState(false);

    const offset = (page - 1) * limit;
    const { state } = useLocation();
    const toggleText = () => {
      setActive(!isActive);
    };
    const onChangeSearch = (e) => {
      e.preventDefault();
      setSearch(e.target.value);
    };
    const togglePopup = () => {
      setPopup(!popup);
  };
  
 
    useEffect(() => {

      axios.get("https://gw.thegmmedical.com:5003/api/get_all_works").then((response) => {
        //setNotice(response.data);
        setWorks(response.data);
        
      });

      
      var dNos;
      axios.get("https://gw.thegmmedical.com:5003/api/department_tree_report").then((response) => {
        // setNotice(response.data);
          console.log("dddd", response.data);
          let results = response.data;
          

          axios.get("https://gw.thegmmedical.com:5003/api/get_report_auth").then((response) => {
            // setNotice(response.data);
             console.log("view_auth...", response.data[0]);
             let user = response.data[0];
             if (response.data[0].view_auth === "department"){
              let filtered = results.filter((item=> item.d_no === user.d_no));
              setDepartments(filtered);
             }else if (response.data[0].view_auth === "sub"){
              const
              getChildren = d_no => (relations[d_no] || []).flatMap(o => [o, ...getChildren(o.d_no)]),
              relations = results.reduce((r, o) => {
                  (r[o.d_parent] ??= []).push(o);
                  return r;
              }, {});

              let children = getChildren(user.d_no);

              console.log("chilren ... ", children);
              let filtered = results.filter((item=> (item.d_no === user.d_no || children.some(e=> item.d_no == e.d_no))));
              setDepartments(filtered);

             }else if (response.data[0].view_auth === "myself"){
              //userNos.push(user.user_no); 
              let filtered = results.filter((item=> item.d_no === user.d_no));
              setDepartments(filtered);
              setMyself(response.data[0].user_no);
             }else if (response.data[0].view_auth === "all"){
              setDepartments(results);

             }
             //console.log("dNos....", dNos);
             //setDepartments(dNos);
          });
          
        });
     
      
    }, []);


   
    function getReport(d_no){
      axios.get("https://gw.thegmmedical.com:5003/api/getreport_dept/"+departments[d_no].d_no).then((response) => {
        //setNotice(response.data);
        console.log("dno...", departments[d_no].d_no);
        setData(response.data);
        setfilterData(response.data);
        
      });
      axios.get("https://gw.thegmmedical.com:5003/api/getuser_dept/"+departments[d_no].d_no).then((response) => {
        //setNotice(response.data);
        console.log("dno...", departments[d_no].d_no);
        let users = response.data;
        if (myself != 0){
          let filtered = users.filter((item=> item.user_no == myself));
          setUsers(filtered);
        }else{
          setUsers(response.data);
        }

        
        
      });
    }
function getReportByUser(user_no){
  axios.get("https://gw.thegmmedical.com:5003/api/getreport_user/"+user_no).then((response) => {
        //setNotice(response.data);
        setData(response.data);
        setfilterData(response.data);
        
      });
}

  const deleteDepartment = () => {
    const data ={
      name : name,
      parent : parent,
      num : num,
      level : lev
    }
    axios.post('https://gw.thegmmedical.com:5003/api/delete_department', data)
    .then(function (response){
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };


  const assign = () => {
    if (selected.name ===undefined){
    }
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    if (array[0].value==="selectall"){
      array.shift();
    }
    const data ={
      nos : array,
      folderName : selected.name,
      folderNo : selected.num
    }
    
    var result = window.confirm("선택된 사용자를 [" + selected.name+"] 부서에 지정합니다.");
    if(result){
      axios.post('https://gw.thegmmedical.com:5003/api/assign', data)
    .then(function (response){
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }
    
  };
  const onNameClick = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();
    var notice_data=[];
    var board_data=[];
    const {
      // internal data
      path, name, num, level, type, isOpen, children
      // custom data
    } = nodeData;
    setName(name);
      setNum(num);
    if (type!=="group"){
      var dropdown = document.getElementsByClassName('dropdown_div');
      var aTags = dropdown[0].getElementsByTagName("span");
      
var searchText;
var found;

        searchText=name;
      
for (var i = 0; i < aTags.length; i++) {
  if (aTags[i].textContent === searchText) {
    found = aTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('.editableNameContainer');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';
    console.log(name);
      
    

    }
    
};
  const addBookmark = () => {
    var result = window.confirm("즐겨찾기 게시판에 추가하시겠습니까?");
    if(result){
      const data ={
        name: boardN,
        num : boardNum,
        table : "board"
      }
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark', data)
    .then(function (response){
      console.log(response);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  };
  
  const selectHandle = (e) => {
    console.log("changed", e.target.value);

    setOption(e.target.value);
  };
  const onSearch = (e) => {
    e.preventDefault();
    var filtered=data;
    
    if ((workNo !=="all" && startDate !=="" && endDate!=="")) {
      const s = new Date(startDate);
      const e = new Date(endDate);

      
      filtered = data.filter((itemList) => {
        return new Date(itemList.wr_register_date) >= s && new Date(itemList.wr_register_date) <= e;
      });
      filtered = filtered.filter((itemList) => {
        return itemList.work_no == workNo;
      });

      filtered = filtered.filter((itemList) => {
        return itemList.wr_subject.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);

    }else if ((workNo !=="all" && endDate==="")){
      
      filtered = data.filter((itemList) => {
        return itemList.work_no == workNo;
      });

      filtered = filtered.filter((itemList) => {
        return itemList.wr_subject.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      
    }else if ((workNo ==="all" && startDate !=="" && endDate!=="")){
      const s = new Date(startDate);
      const e = new Date(endDate);

      
      filtered = data.filter((itemList) => {
        return new Date(itemList.wr_register_date) >= s && new Date(itemList.wr_register_date) <= e;
      });
      filtered = filtered.filter((itemList) => {
        return itemList.wr_subject.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
    }else{
      filtered = data.filter((itemList) => {
        return itemList.wr_subject.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
    }
    if (filtered.length === 0) {
      setEmpty(true);
      setIsPage(false);
    } else {
      setEmpty(false);
      if (filtered.length <= 10) {
        setIsPage(false);
      } else {
        setIsPage(true);
      }
    }
    
    /* 기간별 조회 */

  };
  function getWeekText(week_no){
    console.log("hi",startOfWeek(week_no));
  }

  const addBookmarkPage = () => {
    var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
    if(result){

      const data ={
        name: '',
        num : '',
        link : window.location.pathname,
        bookmark : "부서 업무일지"
      }
      
      console.log(window.location.pathname)
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
      .then(function (response){
        console.log(response);
        alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };


  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <WorkList changeBoard={(value)=>{setfilterData(value);
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);}} changeBoardNum={(value)=>{setBoardNum(value);}}/>
        </div>
      )}
     <div className="content-wrap">
     
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>
        <div className="side-menu">
        <WorkList changeBoard={(value)=>{setfilterData(value);
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);}} changeBoardNum={(value)=>{setBoardNum(value);}}/>
        </div>
        <div className="board_wrap">
    
       
        <div className="report">
        
        <div className="board_wrapper">
        <div className="btns" style={{display:"flex",justifyContent:"start", alignItems:"center"}}>
<div className="btn" style={{display:"flex", alignItem:"center", height:"15px",padding:"5px", marginRight:"7px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>
<h3>부서 업무일지</h3>
</div>
          <form name="form1" id="form1" method="post">
                  <div className="board_container">
                      <div id="content" className="content">
                          
                        <div className="board_top">
                        <div className="btns" style={{display:"flex",justifyContent:"start"}}>
                        <select name="d_no" className="formInput" value={dNo} style={{width:"140px", height: "30px",
    border: "1px solid #ddd",
    lineHeight: "30px"}}
    onChange={(e)=>{setDNo(e.currentTarget.value);getReport(e.currentTarget.value)}}>
      <option value="">부서를 선택하세요.</option>    
{departments.map((element, index)=>(
      <option value={index}>{element.d_name}</option>
    ))}
</select>

{users.length>0&&(
  <select name="d_no" className="formInput" value={userNo}
  onChange={(e)=>{setUserNo(e.currentTarget.value);getReportByUser(e.currentTarget.value)}}>    
{users.map((element, index)=>(
    <option value={element.user_no}>{element.name}</option>
  ))}
</select>
  )}

</div>
{popup ? (
  <WorkReportWrite type={periodical} closePopup={() => togglePopup()} />
) : null}

                          <div style={{margin:"0 0 20px"}}>{boardN}</div>
                            <div className="bt_srch">
                                
                              <div className="bts_slct">
                                  
                                  <span style={{marginRight:"5px"}}>기간 별 조회: </span>
                                  <input type="date" style={{minWidth:"110px"}} value={startDate} onChange={(e)=>setStartDate(e.target.value)}></input>
                                  <input type="date" style={{minWidth:"110px"}} value={endDate} onChange={(e)=>setEndDate(e.target.value)}></input>
                                </div>
                           <div style={{display:"flex"}}>
                                <div className="bt_input" style={{width:"auto", display:"flex"}}>

<select name="work_no" className="formInput" value={workNo}
    onChange={(e)=>{setWorkNo(e.currentTarget.value);}} style={{width:"30%"}}>    
    <option value="all">전체 선택</option>
{works.map((element, index)=>(
      <option value={element.no}>{element.subject}</option>
    ))}
</select>

                                  <input
                                   style={{width:"60%"}}
                                    type="text"
                                    id="search_content"
                                    name="search_content"
                                    value={search || ''}
                                    placeholder="검색어를 입력하세요"
                                    onChange={onChangeSearch}
                                  />
                                   <button
                                style={{margin:"0"}}
                                  type="submit"
                                  className="search_btn"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                                </div>

                               
                                </div>
                              </div>
                          </div>

                          <div className="work_list">

                          
 


 <div className="row">
 

 <table class="table table-hover bd-b">
 <thead class="thead-dark">
 <tr class="tx-center">
   <th class="">번호</th>
   <th class="wd-150 xl-hidden">부서</th>
   <th class="wd-150 xl-hidden">부서원</th>
   <th class="wd-80 xl-hidden">업무명</th>
   <th class="wd-120 xl-hidden">진척율</th>
   <th class="wd-80 xl-hidden">업무시간</th>
   <th class="wd-80 xl-hidden">내용</th>
   <th class="wd-80 xl-hidden">작성일</th>

 </tr>
 </thead>
 <tbody>

 {filterData.length===0 && (
                 <tr style={{ textAlign: "center", border:"1px solid #ddd", padding:"50px 0"}}>
                   <td colSpan={8} style={{padding:"50px 0"}}>작성된 업무 일지가 없습니다.</td>
                 </tr>
               )}
               {filterData
                 .map(
                   ({
                     wr_subject, wr_no, name,subject, wr_content, end_date, progress, workhour,  wr_register_date
                   }) => (
                     <tr key={wr_no}>
                     
                      <td>{wr_no}</td>
                     
                       <td>
                       {departments[dNo].d_name}
                       </td>
                       <td>
                        {name}
                       </td>
                       <td>
                        {subject}
                       </td>
                       <td>
                       {wr_subject}
                       </td>
                       <td>
                        {progress}
                       </td>
                       <td>
                        {workhour}
                       </td>
                       <td>
                        {wr_register_date}
                       </td>
                     </tr>)
                    
                   
                 )}

 </tbody>
</table>
</div>  

                 
                            <div style={{width:"100%",textAlign:"right"}}>
                    {isloggedIn && (
                      <Link to="write"
                        style={{
                          padding: "10px 30px",
                          marginTop: "20px",
                          backgroundColor: "#555",
                          color: "white",
                          textAlign: "center",
                          position:"relative",
                          display:"inline-block",
                          width:"105px",
                          cursor: "pointer",
                        }}
                      >
                        <HiPencilAlt/>글쓰기
                      </Link>
                    )}
                    </div>
                  </div>
                  {isPage && (
                    <div className="paging">
                      <Pagination
                        postsPerPage={limit}
                        totalPosts={filterData.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>
                      {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
                    </div>
                  )}
                      </div>
                  </div>
          </form>
        </div>
      </div>
            
            
                
                
                
        </div>

                </div>
     </div>
    </div>

  );
};

export default Board;