import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {current, job, appoint_date, appoint, memo, user_no}= rowsData;
    return (
      <tr key={index}>
       <td>
          <input
            type="text"
            value={current}
            onChange={(event) => onValUpdate(index, event)}
            name="current"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={job}
            onChange={(event) => onValUpdate(index, event)}
            name="job"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="date"
            value={appoint_date}
            onChange={(event) => onValUpdate(index, event)}
            name="appoint_date"
            className="form-control"
          />
        </td>
     
    
        <td>
          <input
            type="text"
            value={appoint}
            onChange={(event) => onValUpdate(index, event)}
            name="appoint"
            className="form-control"
            
          />
        </td>

        <td>
          <input
            type="text"
            value={memo}
            onChange={(event) => onValUpdate(index, event)}
            name="memo"
            className="form-control"
            
          />
        </td>
  
        <td style={{display:"none"}}>
          <input
            type="text"
            value={user_no}
            onChange={(event) => onValUpdate(index, event)}
            name="user_no"
            className="form-control"
            hidden
          />
        </td>
        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      
      </tr>
    );
  });
}
function Appointments({ user_no }) {
  const [rows, initRow] = useState([]);

  useEffect(() => {
    console.log("userno", user_no);
    fetch("http://gw.thegmmedical.com:5003/api/get_userinfo/appointments/"+user_no, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log(json);  
      initRow(json);

      //console.log(boxes.boxes);
      //console.log(boxes.boxes);
    })
    .catch(() => {});
    
  }, []);

  const addRowTable = () => {
    const data = {
      current:"", job:"", appoint_date:"", appoint:"", memo:"", user_no: user_no
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  function addExperience(){
    for (var i=0;i<rows.length;i++){
      console.log(rows[i]);
    }

    console.log("last rows", rows);
    axios.post('http://gw.thegmmedical.com:5003/api/add_userinfo/appointments/'+user_no, rows)
    .then(function (response){
      console.log(response);
      alert("저장되었습니다.");
   

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  return (
    <>

<div className="btns" style={{display:"flex", justifyContent:"flex-end", marginBottom:"20px"}}>

   
<button className="btn" onClick={addRowTable}>
  추가
</button>
</div>

      <table style={{width:"100%"}} className="info_summary">
        <thead>
          <tr>
                <th>소속</th>
                <th>직급</th>
                <th>발령일자</th>
                <th>발령사항</th>
                <th>비고</th>
                <th className="del">삭제</th>

          </tr>
        </thead>
        <tbody>
        <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            userNo = {user_no}
          />
        </tbody>
      </table>
      <div className="btns" style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>

<button className="btn" onClick={()=>addExperience()}>
          저장
        </button>

</div>
    </>
  );
}
export default Appointments;