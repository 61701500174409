
import { NavLink, Link } from "react-router-dom";
import FolderTree from 'react-folder-tree';
import React, { useState, useEffect, useRef } from "react";
import AddDepartment from "../components/AddDepartment";
import axios from "axios";
import "./css/adminDepartment.css";
import user from "../components/img/user.png";
import Popup from "../components/Popup";
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";
const AdminDepartments = () => {
    const [cnt, setCnt] = useState(0);
    const [data, setData] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [showUser, setShowuser] = useState(false);

    const [CheckList, setCheckList] = useState([]);
    const [testData, setTestData] = useState([]);
    const [unassigned, setUnassigned] = useState([]);
    const [members, setMembers] = useState([]);
    const [parent, setParent] = useState("");
    const [name, setName] =useState("");
    const [num, setNum] = useState(0);
    const [lev, setLev] = useState(0);
    const [selected, setSelected]= useState();
    const [selectedMember, setSelectedMember]= useState();
    const [sidebar, setSidebar]= useState(false);


    const onTreeStateChange = (state, event) => console.log(state, event);

    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].d_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.d_name,
            parentNo : node.d_parent,
            num : node.d_no,
            level : node.d_level,
            order : node.d_order,
            children: lookingForNodeWithParent( nodes, node.d_no )
          });
        }
      }
      
      return arrayToReturn;
    }
    
    function showDepartment(){
      
    }
    useEffect(() => {
      console.log("datga is,,,," ,members);
      fetch("https://gw.thegmmedical.com:5003/api/getdepartment_group", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);  
        setCnt(json.length-1);
  console.log(lookingForNodeWithParent( json, null ));
        //setBoxes(json[0].boxes);
        var array = lookingForNodeWithParent( json, null );
        //let newData = array.map(el => el[0]);
            //console.log(newData);
            setData(array[0]);
        return setTestData(array[0]);

        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});

      axios.get('https://gw.thegmmedical.com:5003/api/getunassigned',{
          
        })
  .then(function (response){
    console.log("????????", response);
    /* tempArray = [];
    for (let i =0;i<response.data.length;i++){
      
      console.log(response.data[i].name);
      tempArray.push(response.data[i].name + response.data[i].email);
      
    }*/
    setUnassigned(response.data);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
    
    }, []);
  
    const togglePopup = () => {
        setPopup(!showPopup);
    };
    const toggleUser = () => {
      setShowuser(!showUser);
  };
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('user');
        const all 
             = document.getElementsByName('alluser');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      function selectAssign()  {
        const checkboxes 
             = document.getElementsByName('unassigned');
        const all 
             = document.getElementsByName('all');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }
      
      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...CheckList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(CheckList.filter((checkedId) => checkedId !== id));
        }
     }

     const onNameClick = ({ defaultOnClick, nodeData }) => {
      defaultOnClick();
    
      const {
        // internal data
        path, name, num, level, isOpen, children
        // custom data
      } = nodeData;
    
      setSelected(nodeData);
      var aTags = document.getElementsByTagName("span");
var searchText = name;
var found;

for (var i = 0; i < aTags.length; i++) {
  if (aTags[i].textContent === searchText) {
    found = aTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('.editableNameContainer');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';
console.log(found);
      setParent(name);
      setNum(num);
      setLev(level);
      /*const tempArray = [...options, item];
      console.log(tempArray);
      setOptions(tempArray);*/


      const item = name;
      const tempArray = [...members, item];
      setMembers(tempArray);

      axios.post('https://gw.thegmmedical.com:5003/api/getcontacts',{
          data: {
            // 서버에서 req.body.{} 로 확인할 수 있다.
            folder_name: name
          },
        })
  .then(function (response){
    console.log("this is", response.data);
    /*var tempArray = [];
    for (let i =0;i<response.data.length;i++){
      
      console.log(response.data[i].name);
      tempArray.push(response.data[i].name + response.data[i].email);
      
    }*/
    setMembers(response.data);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });


  };



  const deleteDepartment = () => {
    var result = window.confirm("선택된 부서를 삭제하시겠습니까?");
    if(result){

    console.log(parent);
    const data ={
      name : name,
      parent : parent,
      num : num,
      level : lev
    }
    axios.post('https://gw.thegmmedical.com:5003/api/delete_department', data)
    .then(function (response){
      console.log(response);
      showDepartment();
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  };

  const unassign = () => {

    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value==="userall" || checkboxes[i].name==="user"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="userall"){
      array.shift();
    }
    const data ={
      nos : array,
      folderName : selected.name,
      folderNo : selected.num
    }
    
    var result = window.confirm("선택된 사용자를 [" + selected.name+"] 부서에서 삭제합니다.");
    if(result){
      axios.post('https://gw.thegmmedical.com:5003/api/unassign', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }


  }
  

  const assign = () => {
    
    if (selected ===undefined){
      alert("부서를 선택해주세요");
    }
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall" && checkboxes[i].name==="unassigned"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }
    const data ={
      nos : array,
      folderName : selected.name,
      folderNo : selected.num
    }
    
    var result = window.confirm("선택된 사용자를 [" + selected.name+"] 부서에 지정합니다.");
    if(result){
      axios.post('https://gw.thegmmedical.com:5003/api/assign', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }
    
  };

  function assignHead(){
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value==="userall" || checkboxes[i].name==="user"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="userall"){
      array.shift();
    }
    const data ={
      no : array[0],
      folderName : selected.name,
      folderNo : selected.num
    }
    
    var result = window.confirm("선택된 사용자를 [" + selected.name+"] 부서장으로 설정합니다.");
    if(result){
      axios.post('https://gw.thegmmedical.com:5003/api/assign_head', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }

  }
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents three_cols" style={{maxWidth:"1600px"}}>
        <div className="header_txt">
        <h3>부서 관리</h3>
        </div>
        <div className="contents_top">
            <table>
                <tr>
                    <td> 현재 부서 수 </td>
                    <td> {cnt}개 </td>
                    {/*<td> 정상({cnt}개), 중지된 부서 0개 </td>*/}
                </tr>
            </table>
        </div>
      <div className="admin_wrapper">
        <div className="main_contents_wrapper departments">
            <div className="btns">
                <button className="btn" onClick={() => {togglePopup();setSelected();}}>부서 생성</button> 
                <button className="btn" onClick={() => togglePopup()}>정보 변경</button> 
                <button className="btn" onClick={() => deleteDepartment()}>부서 삭제</button>
            </div>

{showPopup ? (
  <AddDepartment selected = {selected} onChange={(value) => setData(value.data)} closePopup={() => togglePopup()} />
) : null}

{showUser ? (
  <Popup member = {selectedMember} closePopup={() => {
    toggleUser();

    var aTags = document.getElementsByTagName("span");
var searchText = parent;
var found;

for (var i = 0; i < aTags.length; i++) {
  if (aTags[i].textContent === searchText) {
    found = aTags[i];
    break;
  }
}
found.click();

  }}/>
) : null}


    <div className="folder">
      <FolderTree
      data={ testData }
      onChange={ onTreeStateChange }
      onNameClick={ onNameClick }
      showCheckbox={ false } 
      readOnly
      />
</div>


        </div>

        <div className="main_contents_wrapper members">
        <div className="btns">
        <input type='checkbox'
       name='alluser' 
       value='userall'
       onClick={selectAll}/>  
                <button className="btn" onClick={() => unassign()}>사용자 삭제</button> 
                <button className="btn" onClick={() => assignHead()}>부서장 설정</button> 

                {selected && (<span style={{marginLeft:"20px", color:"#a6a6a6"}}>{selected.name}</span>)}
            </div>

           
   
              { members.length!==0 
              ? <ul>{members.map((element, index) => <li key={index}>
 


<input type='checkbox'
        name='user' 
        value={element.user_no}
        onChange={(e) => onChangeEach(e, element.user_no)} checked={CheckList.includes(element.user_no)}/>             

<div className="list_wrap" onClick={() => {setSelectedMember(element);toggleUser();}}>

{element.profile_link !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={element.profile_link} alt="profile_pic"/>
</div>
: <div className="img-wrap img-upload"><img for="photo-upload" src={user} alt="profile_pic"/>
</div>
}

<div className="info" style={{display:"flex", flexDirection:"column"}}>
   <span>{element.name}</span>
   <span>{element.email}</span>
</div>
                
</div> </li>)}</ul> :  <ul><li>멤버가 없습니다.</li></ul>}
            
                </div>

        <div id="cmbSelected" className="main_contents_wrapper members">
        <div className="btns">
        <input type='checkbox'
       name='all' 
       value='selectall'
       onClick={selectAssign}/>  
                <button className="btn" onClick={() => assign()}>부서 지정</button> 
                <span style={{marginLeft:"20px", color:"#a6a6a6"}}>부서 미지정 사용자</span>
                
            </div>
            <ul>

            
              { unassigned.map((element, index) => <li key={index} className="list_wrap">
 


<input type='checkbox'
        name='unassigned' 
        value={element.user_no}
        onChange={(e) => onChangeEach(e, element.user_no)} checked={CheckList.includes(element.user_no)}/>             

{element.profile_link !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={element.profile_link} alt="profile_pic"/>
</div>
: <div className="img-wrap img-upload"><img for="photo-upload" src={user} alt="profile_pic"/>
</div>
}

<div className="info" style={{display:"flex", flexDirection:"column"}}>
   <span>{element.name}</span>
   <span>{element.email}</span>
</div>
               </li>) }
            </ul>
                </div>
                </div>
     </div>
    </div>

  );
};

export default AdminDepartments;
