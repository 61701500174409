import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [

  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
 

  },
  {
    title: '기본관리',
    icon: <AiIcons.AiFillSetting />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
   
      {
        title: '관리자 추가',
        path: '/overview/add_admin',
        icon: <AiIcons.AiOutlineUserAdd />
      }
    ]
  },
  {
    title: '메일관리',
    icon: <AiIcons.AiFillMail />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: '메일 주석',
        path: '/mail/footer',
        icon: <RiIcons.RiStickyNoteLine />,
        cName: 'sub-nav'
      },
      {
        title: '메일용량관리',
        path: '/mail/capa',
        icon: <AiIcons.AiFillSave />,
        cName: 'sub-nav'
      },
      {
        title: '메일링리스트',
        path: '/mail/mailing',
        icon: <AiIcons.AiFillSave />,
        cName: 'sub-nav'
      }
    ]
  },
  {
    title: '보안관리',
    icon: <IoIcons.IoMdLock />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: '보안등급 관리',
        path: '/security/level',
        icon: <IoIcons.IoMdLock />,
        cName: 'sub-nav'
      },
      {
        title: '비밀번호 오류자',
        path: '/security/pw',
        icon: <RiIcons.RiErrorWarningLine />,
        cName: 'sub-nav'
      }
    ]
  },
  {
    title: '인사관리',
    icon: <IoIcons.IoMdPeople />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: '사용자관리',
        path: '/org/users',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: '부서관리',
        path: '/org/departments',
        icon: <AiIcons.AiOutlineUsergroupAdd />,
        cName: 'sub-nav'
      },
      {
        title: '직위관리',
        path: '/org/jobs',
        icon: <FaIcons.FaNetworkWired />,
        cName: 'sub-nav'
      },
      {
        title: '연차관리',
        path: '/org/leave',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: '인사카드 열람권한',
        path: '/org/hr',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: '업무일지 열람권한',
        path: '/org/report',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      }
    ]
    
  },
  {
    title: '메뉴관리',
    path: '/menu',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: '게시판관리',
        path: '/menu/board',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: '부서게시판관리',
        path: '/menu/teamboard',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: '자료실관리',
        path: '/menu/data',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      
    ]
  },
  {
    title: '휴가관리',
    path: '/holiday',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: '휴일등록',
        path: '/holiday',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: '연차 유형관리',
        path: '/leave',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      
    ]
  },


  
  /*{
    title: 'Messages',
    path: '/messages',
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Message 1',
        path: '/messages/message1',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Message 2',
        path: '/messages/message2',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Support',
    path: '/support',
    icon: <IoIcons.IoMdHelpCircle />
  }*/
];
