import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import { Sheet, Op, Selection, colors } from "@fortune-sheet/core";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import axios from "axios";
import moment from "moment";
import {GiHamburgerMenu} from "react-icons/gi";
// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../components/DraftsList";
import BoardList from "../components/side/DataList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import "./css/write.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

export default function TextEditor() {
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();


  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);
  const [subject, setSubject] = useState(location.state.data[0].subject);
  const [data, setData] = useState(location.state.data);
  const [links, setLinks]=useState([]);
  const [delFile, setDelFile]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [imgLink, setImgLink] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] =useState();
  const [excelData, setExcelData] = useState();
  const [type, setType] =useState("0");
  const [list, setList]=useState([]);
  const [bno, setBno] = useState(location.state.bno);
  const [endDate, setEndDate] = useState(location.state.data[0].end_date);
  const [option, setOption] = useState(location.state.data[0].category);
  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };

  const toggleShow = () => {
    setShow(!show);
};
  const onTypeChange = e => {
    setType(e.target.value)
  }
  const endDateChange = e => {
    setEndDate(e.target.value)
  }
  const { state } = useLocation();

  const excelInitial = {
    name: "Sheet1",
    config: {
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
      },
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 68,
    status: 1,
    ch_width: 2361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    
    scrollLeft: 0,
    scrollTop: 0
  };
  
  useEffect(() => {
    console.log("흐아아ㅏㅇ", location.state);
    fetch("http://gw.thegmmedical.com:5003/api/get_data_category/"+location.state.bg_no, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
        console.log(json);
        setOption(json[0].name);
        setList(json);
    })
    .catch(() => {});
    axios.get("http://gw.thegmmedical.com:5003/api/getexcel/"+location.state.bno)
    .then(resp => {
      console.log("ㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠ", resp)
    });
    /*fetch("http://gw.thegmmedical.com:5003/api/getexcel/"+location.state.bno, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
        alert("결과입니다...........", json);
       
    })
    .catch(() => {});*/

}, []);
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const subjectChange = (event) => {
    setSubject(event.target.value);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };
  const removeAttached = (i) => {
    setData([...data.filter((_, index) => index !== i)]);
    const tempArray = [...delFile, data[i].filename];
    setDelFile(tempArray);
    console.log("....",data[i]);
    setFileSize(fileSize-data[i].size);
    axios.get("https://gw.thegmmedical.com:5003/api/delete_data_attach/"+data[i].a_no)
    .then(resp => {
    });
      
  };





 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {

    if (location.state.data[0].content_type === "excel"){
      if (subject === "") {
        alert("제목을 입력해주세요.");
        return false;
      } 
      var test = excelData[0];
    console.log("test! : ", test.data.length);
    const asdf = test.data;
    var celldataa = [];
    for (let i = 0; i<asdf.length;i++){
      for (let j=0;j<asdf[i].length;j++){
        //console.log("vavava", asdf[i][j]);
        if (asdf[i][j]!==null){
          var trows = {
            r: i,
            c: j,
            v: {
              ct: asdf[i][j].ct,
              v : asdf[i][j].v,
              bg: asdf[i][j].bg,
              m: asdf[i][j].m,
              bl:asdf[i][j].bl,
              it:asdf[i][j].it,
              ff:asdf[i][j].ff,
              fs:asdf[i][j].fs,
              fc:asdf[i][j].fc,
              ht:asdf[i][j].ht,
              vt:asdf[i][j].t,
            }
          };
          if (asdf[i][j].mc!==null){
            trows.v.mc = asdf[i][j].mc;
          }
          if (asdf[i][j].f!==null){
            trows.v.f = asdf[i][j].f;
          }
          celldataa.push(trows);
        }
        
      }
    }

    excelInitial.calcChain = test.calcChain;
    excelInitial.config = test.config;
    excelInitial.celldata = celldataa;

    
console.log("test! : ", excelInitial);
   
    
    const fileData = JSON.stringify(excelInitial);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const d =  {
      json: fileData
    }
  

      var arrayLength = fileArray.length;
      var extractStr = editorRef.current?.getInstance().getMarkdown();
      for (var i = 0; i < arrayLength; i++) {
        let result = extractStr.match(fileArray[i]);
        console.log("result: " + result);
        if (result == null) {
          var filename = fileArray[i].substring(
            fileArray[i].lastIndexOf("/") + 1
          );
  
          axios
            .delete("http://gw.thegmmedical.com:5003/api/deletepic", {
              data: {
                // 서버에서 req.body.{} 로 확인할 수 있다.
                file_name: filename,
                board_name: boardName,
              },
            })
            .then(() => this.setState({ status: "Delete successful" }));
        }
        //Do something
      }
  
      const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      const formData = new FormData();
     
      for (let i = 0; i<fileList.length;i++){
        console.log(fileList[i].name);
        formData.append(`file[${i}]`, fileList[i]);
        formData.append(`filename[${i}]`, fileList[i].name);
      }
      for (let i = 0; i<largeFiles.length;i++){
        formData.append(`lfile[${i}]`, largeFiles[i]);
        formData.append(`lfilename[${i}]`, largeFiles[i].name);
      }
      formData.append(`content`, fileData);
      formData.append(`content_type`, "excel");
      formData.append(`date_time`, date_time);
      formData.append(`subject`, subject);
      formData.append(`file`, mailFile);
      formData.append(`is_notice`, type);
      formData.append(`name`, "변수연");
      formData.append(`bg_no`, data[0].bg_no);
      formData.append(`b_no`, data[0].b_no);

      formData.append(`end_date`, endDate);
      formData.append(`category`, option);
      const fetch = async () => {
        await axios
          .post("http://gw.thegmmedical.com:5003/api/dataupdate", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            console.log("what is this");
            window.location.href="http://gw.thegmmedical.com/data";
            //console.log("...." + data);
          });
      };
  
      const noticefetch = async () => {
        await axios
          .post("http://gw.thegmmedical.com:5003/api/noticeinsert", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            console.log("what is this");
            window.location.href="https://gw.thegmmedical.com/data";
            //console.log("...." + data);
          });
      };
      if (type === "2"){
        noticefetch();
      }else{
        fetch();
      }
     
  
      //saveExcel();
    }else{
    /*const formLargeData = new FormData();
   
    for (let i = 0; i<largeFiles.length;i++){
      formLargeData.append(`lfile[${i}]`, largeFiles[i]);
      formLargeData.append(`lfilename[${i}]`, largeFiles[i].name);
    }*/
    //alert(formLargeData);
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("http://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    formData.append(`is_notice`, type);
    formData.append(`content_type`, "html");
    formData.append(`b_no`, location.state.data[0].b_no);

    formData.append(`name`, "변수연");
    formData.append(`bg_no`, location.state.data[0].bg_no);
    formData.append(`end_date`, endDate);
    formData.append(`category`, option);
    const fetch = async () => {
      await axios
        .post("http://gw.thegmmedical.com:5003/api/dataupdate", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/data";
          //console.log("...." + data);
        });
    };

    const noticefetch = async () => {
      await axios
        .post("http://gw.thegmmedical.com:5003/api/noticeinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/data";
          //console.log("...." + data);
        });
    };
    if (type === "2"){
      noticefetch();
    }else{
      fetch();
    }
   


  }
  };

  const handleDraftButton = () => {
 
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("http://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    formData.append(`is_notice`, type);
    formData.append(`name`, "변수연");
    formData.append(`bg_no`, state.board_no);
    formData.append(`end_date`, endDate);
    formData.append(`category`, option);
    if (draftNo !==undefined){
      formData.append(`draft_no`, draftNo);
    }
    const fetch = () => {
      axios
        .post("http://gw.thegmmedical.com:5003/api/datadraftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response){
          setDraftNo(response.data);
          
        });
    };
    fetch();



  };

  
  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const downloadEmployeeData = () => {
    fetch('http://gw.thegmmedical.com:5003/api/download')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = '이호MH호텔_셀렉 A컷.zip';
                    a.click();
                });
                //window.location.href = response.url;
        });
  }
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
  window.addEventListener('message', (event) => {
    if (event.data["msg"] !== undefined){
      var files = event.data["msg"];
      for (let i= 0;i<files.length;i++){
        string+='<a href = "http://gw.thegmmedical.com:5003/get/'+ files[i].name+'">'+files[i].name+'</a>'

      }
      console.log(string);
      var content = editorRef.current?.getInstance().getHTML();
      string+=content;
      editorRef.current?.getInstance().setHTML(string);
    }
 
  });

  const [pw, setPw] = useState("");
  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();



 

  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
     <div className="content-wrap">
     
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
        <div className="side-menu">
      <BoardList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>
      </div>
      <div className="main_content">
      {show ? (
    <DraftsList onChange={(value) => {setSubject(value.subject);editorRef.current?.getInstance().setHTML(value.content);setDraftNo(value.draft_no)}} closePopup={() => toggleShow()}/>
    ) : null}
      <div className="board_name"><span>{location.state.bg_name}</span></div>
      <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"100%", margin:"0 auto"}}>
              <div className="left">

              
              <button className="btn" onClick={handleRegisterButton} style={{marginRight:"10px"}}>등록</button>

              <button className="btn" onClick={handleDraftButton}>임시저장</button>
              </div>

              <div className="right">
                <button onClick={() => {setShow(!show);}}>임시저장글</button> 

            </div>
            </div>
      <table className="write_table">

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  <tr>
  <td class="table_title2" nowrap="">
    제목
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="text" name="name" className="formInput" placeholder="제목을 입력하세요." style={{marginLeft:"10px"}} onChange={subjectChange} value={subject}></input>
    </div>
    </td>
  </tr>
  <tr>
  <Tooltip
        id="date_guide"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>설정하지 않을 시 무제한 보관됩니다.</span>
          </div>
        </Tooltip>
  <td class="table_title2" nowrap="">
    게시 유효일<BiInfoCircle data-tooltip-id="date_guide" style={{marginLeft:"5px"}}/>
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="date" name="name" className="formInput" placeholder="제목을 입력하세요." style={{marginLeft:"10px"}} onChange={endDateChange} value={endDate}></input> 
    </div>
    </td>
  </tr>
  <tr>
  <td class="table_title2" nowrap="">
    첨부파일
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler} multiple/>
    <button style={{cursor:'pointer'}} className="large_file_btn" onClick={()=>onBtnClick()}>
        파일 첨부
        </button>
        <button id='drftrgvlnbpewmcswmcs' style={{cursor:'pointer'}} className="large_file_btn" onClick={() => popupwindow("/large_file", "large file upload", 800, 400)} alt=''>
        대용량 파일 링크 첨부하기
        </button>
    </div>
    
    </td>
  </tr>
  <tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    
  </td>
    <td class="upload_file_list">

      {data[0]!==undefined && (

(data[0].filename !=="" && data[0].filename !==undefined) &&   
<div className="attachment">

  <div className="attachment_box">
    {data.map((element, idx) => {
    return <div className="file_item">
    <p>{element.filename} {formatBytes(element.size)}</p>
    <button type="button" className="remove" onClick={() => {removeAttached(idx);}}>x</button>
  </div>
    })}
    </div>
  </div>

  

      )}

  {fileList.map((file,index) => 
    <div className="file_item">
      <p>{file.name} {formatBytes(file.size)}</p>
      <button type="button" className="remove" onClick={() => {removeFile(index);}}>x</button>
    </div>
    
  )} 

{largeFiles.map((file,index) => 
    <div>
      <p>{file.name} {formatBytes(file.size)} <span>다운로드 가능 기간</span></p>
      
      <button type="button" onClick={() => {removeFile(index);}}>Remove</button>
    </div>
    
  )} 
    </td>
  </tr>
  <tr>
  <td class="table_title2" nowrap="">
    공지사항
  </td>
  <td class="table_list2_left">
    <div>

    
  <label htmlFor="type">
      <input
        type="radio"
        name="type"
        value="board"
        checked={type === "0"}
        onChange={onTypeChange}
      />
      설정안함
    </label>
    <label htmlFor="type">
      <input
        type="radio"
        name="type"
        value="group"
        checked={type === "1"}
        onChange={onTypeChange}
      />
      현재 게시판에 공지
    </label>
    <label htmlFor="type">
      <input
        type="radio"
        name="type"
        value="group"
        checked={type === "all"}
        onChange={onTypeChange}
      />
      전체 게시판에 공지
    </label>
    </div>
  </td>
    
  </tr>

  {list.length>0&&(
  <tr>

  <td class="table_title2" nowrap="">
    문서 분류
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <select name="name" className="formInput" style={{marginLeft:"10px"}} value={option || ''}
                                    onChange={selectHandle}>
    {list.map((element,index)=><option value={element.name}>{element.name}</option>)}  
    </select> 
    </div>
    </td>
  </tr>
)}
      </table>
     <div id="response">{links}</div>
     <div style={{height:"500px"}}>
     {location.state.data[0].content_type !== "excel" && (
      <Editor
      ref={editorRef} // DOM 선택용 useRef
      initialValue={location.state.data[0].content}
      previewStyle="vertical" // 미리보기 스타일 지정
      height="500px" // 에디터 창 높이
      initialEditType="wysiwyg" //
      toolbarItems={[
        // 툴바 옵션 설정
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        ['table', 'image', 'link'],
        ['code', 'codeblock']
      ]}
      plugins={[colorSyntax]} 
      useCommandShortcut={false} // 키보드 입력 컨트롤 방지
      hooks={{
        addImageBlobHook: async (blob, callback) => {
          console.log(blob.size);
          if (blob.size > 5000000) {
            alert(
              "업로드에 실패했습니다. 이미지당 5MB까지 업로드할 수 있습니다."
            );
            return;
          }
          const formData = new FormData();
                  formData.append("img", blob);
                  
                  // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
                  // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...
                  await axios.post(
                    "http://gw.thegmmedical.com:5003/api/img",
                    formData
                  ).then(( res ) => {
                    console.log(res);
                    console.log(
                      "성공 시, 백엔드가 보내주는 데이터",
                      res.data.url
                    );

                    const IMG_URL = res.data.url;
                    setImgLink(IMG_URL);
                    // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
                    callback(IMG_URL, "test");
                    //alert(IMG_URL);
                    files.push(IMG_URL);
                    setArray(files);
                    //setArray((fileArray) => [...fileArray, IMG_URL]);
                    //alert("fileArray: ", files);
                    console.log(files);
                    var arrayLength = files.length;
                    //alert(arrayLength);
                    //alert("end");
                  
                  });
          //callback('http://gw.thegmmedical.com:5003/img/카레유.png', '카레유');
        }
      }}
    ></Editor>
    )}
    {location.state.data[0].content_type === "excel" && (
      <Workbook onOp={console.log} data={[JSON.parse(location.state.data[0].content)]} onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}}  onContextMenu={e=>{e.preventDefault();}}></Workbook>
    
    )}
     </div>
      <div className="button_wrap">
      <button className="submit" onClick={handleRegisterButton}>등록</button>
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
    </div>
  );
}
