import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import axios from 'axios';
const Nav = styled.div`
  background: #15171c;
  height: 0px;
  width:0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position:fixed !important;
  @media only screen and (max-width: 768px) {
    display:none;
  }
  

`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #15171c;
  width: 250px !important;
  height: 100vh;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  transition: 350ms;
  position:relative !important;

  z-index: 10;
  @media only screen and (max-width: 768px) {
    display:none;
  }
`;
const Title = styled.div`
  color:#fff;
  font-size:25px;
  text-align:center;
  margin:30px;
`;
const SidebarWrap = styled.div`
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 768px) {
    display:none;
  }
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [adminMenus, setAdminMenus] = useState([]);

  const showSidebar = () => setSidebar(!sidebar);
  useEffect(() => {
   
      
    axios.get('https://gw.thegmmedical.com:5003/api/adminCheck')
    .then(function (response){
      console.log("sidebar...", response.data, "sidebardata....", SidebarData);
      setAdminMenus(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });
    
  }, []);
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
          <NavIcon to='#'>
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
        </Nav> 
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
              <Title>Administrator</Title>

              <a href="/" style={{display:"flex", justifyContent:"center", alignItems:"center", margin:"10px 0"}}><AiIcons.AiFillHome />
              <p style={{fontSize:"18px", color:"#fff", margin:"0px 5px"}}>홈으로 가기</p></a>
            {SidebarData.filter(item => adminMenus.some(obj => obj.name === item.title)).map((item, index) => {
              return <SubMenu item={item} key={index} toggleSidebar={showSidebar}/>;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;