import axios from "axios";
import React, { useState, useEffect, useRef } from "react";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {name, institution, content, cost, startDate, endDate, user_no}= rowsData;
    return (
      <tr key={index}>
        <td>
        {name}
        </td>
        <td>
        {institution}
        </td>
        <td>
        {content}
        </td>
     
    
        <td>
        {cost}
        </td>

        <td>
        {startDate}
        </td>

        <td>
        {endDate}
        </td>
        <td style={{display:"none"}}>
          <input
            type="text"
            value={user_no}
            onChange={(event) => onValUpdate(index, event)}
            name="user_no"
            className="form-control"
            hidden
          />
        </td>
     
      </tr>
    );
  });
}
function Languages({ user_no }) {
  const [rows, initRow] = useState([]);

  useEffect(() => {
    console.log("userno", user_no);
 
    axios.get("http://gw.thegmmedical.com:5003/api/get_userinfo/education/"+user_no, rows)
    .then(function (response){
      console.log(response);  
      initRow(response.data);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }, []);
  

  const addRowTable = () => {
    const data = {
        name: "", institution:"", content:"", cost:"", startDate:"", endDate:"", user_no: user_no
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  function addExperience(){
    for (var i=0;i<rows.length;i++){
      console.log(rows[i]);
    }

    console.log("last rows", rows);
    axios.post('http://gw.thegmmedical.com:5003/api/add_userinfo/education/'+user_no, rows)
    .then(function (response){
      console.log(response);
   

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  return (
    <>

             
      <table style={{width:"100%"}} className="info_summary">
        <thead>
          <tr>
          <th>교육명</th>
          <th>교육기관</th>
                <th>교육내용</th>
                <th>비용</th>
                <th>시작일</th>
                <th>종료일</th>
          </tr>
        </thead>
        <tbody>
          <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            userNo = {user_no}
          />
        </tbody>
      </table>
      <div className="btns" style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>



</div>
    </>
  );
}
export default Languages;