import {  Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import {FaCog, FaRegListAlt, FaClipboardList } from "react-icons/fa";
import { HiPencilAlt } from "react-icons/hi";
import {BsPencilSquare, BsPlusSquare} from "react-icons/bs";
import "../css/schedulelist.css";



const ScheduleList = ({onChange}) => {
    const [admin, setAdmin] = useState(false);
    const [calendars, setCalendars] = useState([]);
    const [teamCalendars, setTeamCalendars] = useState([]);
    const [userCalendars, setUserCalendars] = useState([]);
    const [groupCalendars, setGroupCalendars] = useState([]);
    const [user, setUser] = useState([]);

    const [members, setMembers] = useState([]);
    const [parent, setParent] = useState("");
    const [dHead, setDHead] = useState([]);
    const [name, setName] = useState("");
    const [num, setNum] = useState(0);
    const [selected, setSelected]= useState([]);
    const navigate = useNavigate();
    const [CheckList, setCheckList]=useState([]);

    const onTreeStateChange = (state, event) => console.log(state, event);

    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].bg_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.bg_name,
            parentNo : node.bg_parent,
            num : node.bg_no,
            level : node.bg_level,
            order : node.bg_order,
            dNo : node.d_no,
            type : node.bg_type,
            children: lookingForNodeWithParent( nodes, node.bg_no )
          });
        }
      }
      
      return arrayToReturn;
    }
    let location = useLocation();

  


    useEffect(() => {
      axios.get('https://gw.thegmmedical.com:5003/api/getuser',{})
      .then(function (response){
      //setBoxes(json[0].boxes);
        setUser(response.data[0]);
        //setBookmarks(response.data);
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
    axios.get('https://gw.thegmmedical.com:5003/api/get_calendar_setting')
    .then(function (response){
   
      let setting = JSON.parse(response.data[0].selections);


      setting.push("0");
      setting.push("00");
      setCheckList(setting);

    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

      axios.get('https://gw.thegmmedical.com:5003/api/check_dhead',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      console.log("부서장ㅇ?", response.data[0]);
      setDHead(response.data[0]);
      //setBookmarks(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/check_admin',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      console.log("부서장ㅇ?", response.data[0]);
      if (response.data[0].authority ==="admin"){
        setAdmin(true);
      }else{
        setAdmin(false);
      }
      
      //setBookmarks(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/getcalendar',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      setCalendars(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
        

    axios.get('https://gw.thegmmedical.com:5003/api/get_teamcalendar',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      setTeamCalendars(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    var user_calendar=[];
    axios.get('https://gw.thegmmedical.com:5003/api/get_user_calendar',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    setUserCalendars(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
      
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/get_invited_calendar',{})
    .then(function (response){
      
      setGroupCalendars(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    }, []);
 
    
    const delBookmark = (e) => {
      var result = window.confirm("즐겨찾기 게시판에서 삭제하시겠습니까?");
    if(result){
      
      const data ={
        num : e.target.className,
        table: "board"
      }
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/delete_bookmark', data)
    .then(function (response){
      console.log(response);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
    }

        function allBoard(){
          axios.get("https://gw.thegmmedical.com:5003/api/news").then((response) => {
              
            });
        }
        function noticeBoard(){
          axios.get("https://gw.thegmmedical.com:5003/api/notice").then((response) => {
              
            });
        }
        const onNameClick = ({ defaultOnClick, nodeData }) => {
          defaultOnClick();
          var notice_data=[];
          var board_data=[];
          const {
            // internal data
            path, name, num, level, type, isOpen, children
            // custom data
          } = nodeData;
          setName(name);
            setNum(num);
          if (type!=="group"){
            setSelected(nodeData);
            var main = document.getElementsByClassName('main_folder');

            var aTags = main[0].getElementsByTagName("span");
            
      var searchText;
      var found;
      
              searchText=name;
            
      for (var i = 0; i < aTags.length; i++) {
        if (aTags[i].textContent === searchText) {
          found = aTags[i];
          break;
        }
      }
      const boxes = document.querySelectorAll('.editableNameContainer');
      
      boxes.forEach(box => {
        box.style.backgroundColor = 'transparent';
      });
      found.style.backgroundColor = '#BEFDFD';
      found.click();
            setParent(name);
            
            /*const tempArray = [...options, item];
            console.log(tempArray);
            setOptions(tempArray);*/
      
      
            const item = name;
            const tempArray = [...members, item];
            setMembers(tempArray);
            axios.post('https://gw.thegmmedical.com:5003/api/getdoc',{
                data: {
                  // 서버에서 req.body.{} 로 확인할 수 있다.
                  folder_name: name
                },
              })
        .then(function (response){
          console.log("docs.........    ", response.data);
          board_data = response.data;
          //setBoard(response.data)
        
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
        
        
      
          }
          
      };

      const FileIcon = ({ onClick: defaultOnClick, nodeData }) => {
        const { path, name, checked, isOpen, url, ...restData } = nodeData;
        const handleClick = () => {
          defaultOnClick();
        };
      
        return <FaClipboardList onClick={ handleClick } />;
      };
      
     
     
    
     
      
      
      // add fake url to data for testing purpose
    
      const onChangeEach = (e, id) => {
          // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
          setCheckList([...CheckList, id]);
          onChange([...CheckList, id]);
          console.log("checkList...", [...CheckList, id]);
          // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
          setCheckList(CheckList.filter((checkedId) => checkedId !== id));
          onChange(CheckList.filter((checkedId) => checkedId !== id));
        }
          
       }
  return (
    <div className="wrapper">
     
     <div className="board_nav schedule_nav">
     <div style={{padding:"30px", background:"#f7f7f7"}}>
        <h2>일정관리</h2>
        <a href="/schedule_w"><div className="board_wrt"><HiPencilAlt style={{marginRight:"5px"}}/>일정등록</div></a>


    <div className="wrap">


<div className="list">
<span className="list_title" onClick={()=>allBoard()}><span><FaRegListAlt/>전사 캘린더</span>

{admin && (
  <Link to="/calendar_w"><BsPlusSquare/></Link>
)}
</span>
<div className="menus">


<div style={{display:"flex", marginBottom:"5px",
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}><div style={{border:"2px solid", borderColor:"aqua", width:"14px", height:"14px", position:"relative",
    marginRight:"5px", overflow:"hidden"}}>
        <input type="checkbox" style={{accentColor:"aqua"}}
        value="0"
        onChange={(e) => onChangeEach(e, "0")} checked={CheckList.includes("0")}
        ></input></div>
    <span className="0">연차</span></div>
   
   
   
    </div>
  {calendars.map((element, index)=>{
    var color = `${element.color}`;
    /*const style = {
      color: '#000000',
      "&::after": {
        backgroundColor: element.color
      }
    };*/

    
    return (
    
      <div style={{display:"flex", marginBottom:"5px", 
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}><div style={{border:"2px solid", borderColor:`${element.color}`, width:"14px", height:"14px", position:"relative",
    marginRight:"5px", overflow:"hidden"}}>
        <input type="checkbox" style={{accentColor:`${element.color}`}}
        value={element.no}
        onChange={(e) => onChangeEach(e, element.no)} checked={CheckList.includes(element.no)}
        ></input></div>
    <span className={element.no}>{element.name}</span></div>
    {element.user_no ===user.user_no && (
      <Link to="/calendar_u" state={{calendar : element}}><BsPencilSquare/></Link>
    )
    }
    
    
    </div>
    
   )
  })}

 

</div>    
</div>
<div className="list">
<span className="list_title" onClick={()=>allBoard()}><span><FaRegListAlt/>부서 캘린더</span>

{dHead !==undefined && (
  <Link to="/calendar_w" state={{group: "department"}}><BsPlusSquare/></Link>
)}

</span>
<div className="menus">
  {teamCalendars.map((element, index)=>{
    var color = `${element.color}`;
    /*const style = {
      color: '#000000',
      "&::after": {
        backgroundColor: element.color
      }
    };*/

    
    return (<div style={{display:"flex", marginBottom:"5px", 
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}><div style={{border:"2px solid", borderColor:`${element.color}`, width:"14px", height:"14px", position:"relative",
    marginRight:"5px", overflow:"hidden"}}>
        <input type="checkbox" style={{accentColor:`${element.color}`}}
        value={element.no}
        onChange={(e) => onChangeEach(e, element.no)} checked={CheckList.includes(element.no)}
        ></input></div>
    <span className={element.no}>{element.name}</span></div>
    {dHead !==undefined && (
      dHead.d_no === element.d_no && (
      <Link to="/calendar_u" state={{calendar : element}}><BsPencilSquare/></Link>
    )
    )}
    
    
    </div>
    
    )
  })}

 

</div> 
</div>
   
<div className="list">
<span className="list_title" onClick={()=>allBoard()}><span><FaRegListAlt/>내 캘린더</span> 
<Link to="/calendar_w" state={{group: "my"}}><BsPlusSquare/></Link></span>
<div className="menus">
{userCalendars.map((element, index)=>{
    var color = `${element.color}`;
    /*const style = {
      color: '#000000',
      "&::after": {
        backgroundColor: element.color
      }
    };*/

    
    return (<div style={{display:"flex", marginBottom:"5px", 
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}><div style={{border:"2px solid", borderColor:`${element.color}`, width:"14px", height:"14px", position:"relative",
    marginRight:"5px", overflow:"hidden"}}>
        <input type="checkbox" style={{accentColor:`${element.color}`}}
        value={element.no}
        onChange={(e) => onChangeEach(e, element.no)} checked={CheckList.includes(element.no)}
        ></input></div>
    <span className={element.no}>{element.name}</span></div> 
    
    
      <Link to="/calendar_u" state={{calendar : element}}><BsPencilSquare/></Link>
 
    </div>)
  })}

</div>    
</div>
  

<div className="list">
<span className="list_title" onClick={()=>allBoard()}><span><FaRegListAlt/>그룹 캘린더</span> 
<Link to="/calendar_w" state={{group: "group"}}><BsPlusSquare/></Link></span>
<div className="menus">
{groupCalendars.map((element, index)=>{
    var color = `${element.color}`;
    /*const style = {
      color: '#000000',
      "&::after": {
        backgroundColor: element.color
      }
    };*/

    
    return (<div style={{display:"flex", marginBottom:"5px", 
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}><div style={{border:"2px solid", borderColor:`${element.color}`, width:"14px", height:"14px", position:"relative",
    marginRight:"5px", overflow:"hidden"}}>
        <input type="checkbox" style={{accentColor:`${element.color}`}}
        value={element.no}
        onChange={(e) => onChangeEach(e, element.no)} checked={CheckList.includes(element.no)}
        ></input></div>
    <span className={element.no}>{element.name}</span></div> 
    
    <Link to="/calendar_u" state={{calendar : element}}><BsPencilSquare/></Link></div>)
  })}

</div>    
</div>


<div className="list">
<span className="list_title" onClick={()=>allBoard()}><span><FaRegListAlt/>시스템 캘린더</span>


{admin && (
  <Link to="/calendar_w"><BsPlusSquare/></Link>
)}
</span>
<div className="menus">


<div style={{display:"flex", marginBottom:"5px",
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}><div style={{border:"2px solid", borderColor:"red", width:"14px", height:"14px", position:"relative",
    marginRight:"5px", overflow:"hidden"}}>
        <input type="checkbox" style={{accentColor:"red"}}
        value="00"
        onChange={(e) => onChangeEach(e, "00")} checked={CheckList.includes("00")}
        ></input></div>
    <span className="0">공휴일</span></div>
   
   
   
    </div>


</div>    
</div>

    <div className="main_folder">




     

    <div className="list">
 <span className="list_title"><span><FaCog/>환경설정</span></span>
 <div className="menus">
  <span><Link to="/setting/calendar">기본설정</Link></span>

</div>    
   
      </div>


      </div>

</div>

        </div>
        </div>
    </div>

  );
};

export default ScheduleList;







