






import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {FaAddressBook} from "react-icons/fa";
import sign from "../img/user.png";
import "../css/setting.css";

const BasicInfo = ({info, no, onChange}) => {
  const [values, setValues]=useState(info);
 

  const inputFileRef = useRef();
  const [fileList, setFileList] = useState([]);
  const [files, setFiles] = useState([]);

  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);


  const [imgFileList, setImgFileList] = useState([]);
  const [pdfFileList, setPdfFileList] = useState([]);
  const [removeFileList, setRemoveFileList] = useState([]);


  function getExt(filename)
  {
      var ext = filename.split('.').pop();
      if(ext == filename) return "";
      return ext;
  }

  useEffect(()=>{
    if (no !==undefined){
      axios.get('http://gw.thegmmedical.com:5003/api/get_customer_basic/'+no)
      .then(function (response){
        console.log(response);
        setValues(response.data[0]);

        
      })
    

    axios.get('http://gw.thegmmedical.com:5003/api/get_customer_files/'+no)
    .then(function (response){
      console.log("get_customer_files....",response.data);
      //setFileList(response.data);
      setFiles(response.data);
      let imgs = [];
      let pdfs = [];
      var imagesExtension = ["png", "jpg", "jpeg"];


      for (let i=0;i<response.data.length;i++){
        console.log("getExt.....", getExt(response.data[i].filename));
        if (imagesExtension.indexOf(getExt(response.data[i].filename)) !== -1){
          imgs.push(response.data[i]);
        }else if (getExt(response.data[i].filename)==="pdf"){
          pdfs.push(response.data[i]);
        }
      }
      setImgFileList([...imgs]);
      setPdfFileList([...pdfs]);


    })

  }
  
  },[])
const handleInputChange = (e) => {
  //const name = e.target.name 
  //const value = e.target.value 
  const { name, value } = e.target;

  setValues({
    ...values,
    [name]: value,
  });
  values[name] = value;
  onChange(values);

 
};

const fileChangedHandler = (event) => {
  //let file_size = event.target.files[0].size;

  var newFiles = [];
  const LargeFs = [];
  var file__size=0;
  var fs = 0;
  for(let i = 0; i < event.target.files.length; i++){
    file__size+=event.target.files[i].size;
    
    //filesize+=event.target.files[i].size;
    console.log(fileSize);
    if ((fileSize+file__size)>20971520 || file__size > 20971520){
      LargeFs.push(event.target.files[i]);
      
     
    }else{
      
      fs+=event.target.files[i].size;
      newFiles.push(event.target.files[i]);
      
    }
  }
  setFileSize(fileSize+fs);
  var newArray = fileList.concat(newFiles);
  setLargeFiles(LargeFs);

  values.fileList = newArray;
  onChange(values);
  setFileList(newArray);
  console.log(largeFiles);

};


const removeFile = (i) => {
  setFileList([...fileList.filter((_, index) => index !== i)]);
  setFileSize(fileSize-fileList[i].size);
};

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0'

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const onBtnClick = () => {
  /*Collecting node-element and performing click*/
  inputFileRef.current.click();
};
const removeFiles = (i) => {
  setFiles([...files.filter((_, index) => index !== i)]);
  let tmp = removeFileList;
  let a_no = files[i].a_no;
  tmp.push(a_no);
  setRemoveFileList([...tmp]);
  values.removeFile = tmp;
  console.log("tmp", tmp);
  onChange(values);
};


  return (

<table className="form_write" style={{margin:"10px auto", width:"100%"}}>

{/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}



        <tr>
<td class="table_title2" nowrap="">
그룹 선택
</td>
<td class="table_list2_left">
<div className="input-group">
<select onChange={handleInputChange} name="type" value={values.type}>
<option value="매입처">매입처</option>
<option value="매출처">매출처</option>

</select>
</div>
</td>



</tr>

<tr>
<td class="table_title2" nowrap="">
상호(법인명)
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="name" className="formInput" value={values.name} onChange={handleInputChange}></input>
</div>
</td>



</tr>

<tr>


<td class="table_title2" nowrap="">
사업자등록번호
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="biz_no" className="formInput" value={values.biz_no} onChange={handleInputChange}></input>
</div>
</td>

<td class="table_title2" nowrap="">
성명(대표자)
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="rep" className="formInput" value={values.rep} onChange={handleInputChange}></input>
</div>
</td>
</tr>

<tr>
<td class="table_title2" nowrap="">
주민(법인)등록번호
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="register_no" className="formInput" value={values.register_no} onChange={handleInputChange}></input>
</div>
</td>




</tr>

<tr style={{height:"auto"}}>
<td class="table_title2" nowrap="">
사업장소재지
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="addr" className="formInput" value={values.addr} onChange={handleInputChange}></input>
</div>
</td>



</tr>

<tr>
<td class="table_title2" nowrap="">
본점소재지
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="hq" className="formInput" value={values.hq} onChange={handleInputChange}></input>
</div>

</td>
</tr>


<tr>
<td class="table_title2" nowrap="">
개업일
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="open_date" className="formInput" value={values.open_date} onChange={handleInputChange}></input>
</div>

</td>
</tr>


<tr>
<td class="table_title2" nowrap="">
사업자등록일
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="register_date" className="formInput" value={values.register_date} onChange={handleInputChange}></input>
</div>

</td>
</tr>




<tr>
<td class="table_title2" nowrap="">
업태
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="biz_type" className="formInput" value={values.biz_type} onChange={handleInputChange}></input>
</div>

</td>
</tr>
<tr>
<td class="table_title2" nowrap="">
종목
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="biz_item" className="formInput" value={values.biz_item} onChange={handleInputChange}></input>
</div>

</td>
</tr>


<tr>
<td class="table_title2" nowrap="">
메모
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="note" className="formInput" value={values.note} onChange={handleInputChange}></input>
</div>

</td>
</tr>


<tr>
<td class="table_title2" nowrap="">
첨부파일
</td>
<td class="table_list2_left file_btns">
<div className="input-group">
<input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler} multiple/>
<button style={{cursor:'pointer'}} className="large_file_btn" onClick={()=>onBtnClick()}>
파일 첨부
</button>

</div>

</td>

</tr>
{(fileList.length>0 || files.length>0) && (




<tr style={{height:"auto"}}>
<td class="table_title2" nowrap="">


</td>
<td class="upload_file_list">




{files.map((file,index) =>
<div className="file_item">
<p>{file.filename} {formatBytes(file.size)}</p>
<button type="button" className="remove" onClick={() => {removeFiles(index);}}>x</button>
</div>


)}
{fileList.map((file,index) =>
<div className="file_item">
<p>{file.name} {formatBytes(file.size)}</p>
<button type="button" className="remove" onClick={() => {removeFile(index);}}>x</button>
</div>


)}




</td>
</tr>


)}




</table>


)};

export default BasicInfo;