import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import "./css/expenditure.css";
import TextareaAutosize from 'react-textarea-autosize';

//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, blurHandler }) {



  return rows.map((rowsData, index) => {
    const {name, price, note }= rowsData;
    return (
      <tr key={index}>
      
          

          <td style={{padding: '5px', height: '18px'}}>{index+1}</td> 

          <td>
          <input
            type="text"
            value={name}
            onChange={(event) => onValUpdate(index, event)}
            name="name"
            className="form-control"
          />
       </td>
       <td style={{paddingRight:"5px"}}>
        <div style={{display:"flex"}}>
          <input
            type="text"
            value={price}
            onChange={(event) => onValUpdate(index, event)}
            name="price"
            className="form-control" style={{textAlign:"right"}}
            onBlur={(event) => blurHandler(index, event)}

          />
          {price !=="" &&  (
            <span>원</span>
          )}
          </div>
       </td>
       <td>

       
          <input
            type="text"
            value={note}
            onChange={(event) => onValUpdate(index, event)}
            name="note"
            className="form-control"
          />
       </td>
       <td>
         <button
            className="btn btn-dark"
            onClick={() => tableRowRemove(index)}
            style={{padding:"0 4px"}}
          >
            x
          </button>
       </td>
         
    
        
      </tr>
    );
  });
}

const Request = ({doc_no, onChange, line}) => {  
  
  const [rows, initRow] = useState([]);
  const [links, setLinks]=useState([]);
  const [select, setSelect] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState([]);

  
 
 

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    

    onChange(values);

  };
 


  useEffect(() => {

    let initial = [{
      name:"", price:"", note:""
    },
    {
      name:"", price:"", note:""    },
    {
      name:"", price:"", note:""    },
    {
      name:"", price:"", note:""    }
   ];
    initRow(initial);
    
    if (doc_no!==undefined){
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/expenditure/'+doc_no)
    .then(function (response){
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
    
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);


  const addRowTable = () => {
    const data = {
        name:"", price:"", note:""
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  const blurHandler = (i,e) => {
    var { name, value } = e.target;
    
    const data = [...rows];
    console.log(data, name, value, i);  
 

    
    if (name ==="price" && isNumeric(value)){ 
      data[i][name] = parseInt(value).toLocaleString();
    }

    
    initRow(data);

    values.arr = data;
 
   
    onChange(values);
  };
  const onValUpdate = (i, event) => {
    var { name, value } = event.target;
    
    const data = [...rows];
    console.log(data, name, value, i);  
 

    
    if (name ==="price"){
      var total = 0;
      data[i][name] = value;
        for (let j=0;j<data.length;j++){
          if (data[j].price!==""){
            let tmp_price = data[j].price;
           
            tmp_price=tmp_price.replace(',','');


            if (isNumeric(tmp_price)){
              total += parseInt(tmp_price);
            }


           
          }
           
        }
        values.total = total.toLocaleString();
    }else{
      data[i][name] = value;
    }
   

    
    initRow(data);

    values.arr = data;
 
   
    onChange(values);

  };


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });

  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     
     

    

            <div className="order_write forms" style={{padding:"0", margin:"20px auto"}}>
          

      <div className="">
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
        <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>지출 요구서</td>
                   
                  </tr>
                
                </table>

                <div style={{border:"1px solid #000",  padding:"30px", boxSizing:"border-box"}}>

                

                    <table className="expenditure" style={{fontFamily: 'malgun gothic,dotum,arial,tahoma', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{padding: '5px', height: '25px', textAlign: 'right', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            지출건명
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle',
                        border:"none"}}>
                          </td>
                        </tr>
                        <tr>
                          <th style={{padding: '5px',  height: '25px', textAlign: 'right', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                          <span>1.</span>
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <TextareaAutosize type="text" name="name" value={values.name} onChange={handleInputChange} style={{width:"100%"}}></TextareaAutosize>

                          </td>
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '25px', textAlign: 'right', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                          <span>2.</span>
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            

                            <TextareaAutosize type="text" name="content" value={values.content} onChange={handleInputChange} style={{width:"100%"}}></TextareaAutosize>
                          </td>

                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '25px', textAlign: 'right', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                          <span>3.</span>
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle', display:"flex", alignItems:"center",
                            border:"none"
                          }}>
                            
                          <span style={{lineHeight:"25px"}}>지출내역</span>

                          <button className="btn" onClick={addRowTable} style={{marginLeft:"5px", padding:"0 5px"}}>
                            +
                          </button>

                          </td>

                        </tr>
                   

                      </tbody>
                    </table>
                    <table style={{marginLeft:"60px"}} className="expend_list">


                        <tr>


                        <th style={{padding: '5px', height: '18px'}}>
                            No
                          </th>

                          <th style={{padding: '5px', height: '18px', width:"40%"}}>
                            품목
                          </th>

                          <th style={{padding: '5px', height: '18px'}}>
                            금액
                          </th>
                          <th style={{padding: '5px', height: '18px', width:"35%"}}>
                            비고
                          </th>

                          <th style={{padding: '5px', height: '18px'}}>
                           
                          </th>
                        </tr>
                        <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            blurHandler={blurHandler}
            />

<tr>
      
          

      <td style={{padding: '5px', height: '18px'}}></td> 

      <td>
  
   </td>
   <td style={{textAlign:"right", paddingRight:"5px"}}>
    <span>{values.total}원</span>
   </td>
   <td>

   </td>
   <td>
     
   </td>
     

    
  </tr>
                       
  </table> 



  <table className="expenditure" style={{fontFamily: 'malgun gothic,dotum,arial,tahoma', borderCollapse: 'collapse',
                  width:"100%", marginTop:"10px"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{padding: '5px', height: '25px', textAlign: 'right', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            붙임 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <TextareaAutosize type="text" name="memo" value={values.memo} onChange={handleInputChange} style={{width:"100%"}}></TextareaAutosize>
                          </td>
                        </tr>
                        </tbody>
                        </table>

  </div>         

                    <p style={{width:"100%", textAlign:"center", fontSize:"17px"}}>{new Date().getFullYear() + "년 " + ('0' + (new Date().getMonth() + 1)).slice(-2) +
"월 " + ('0' + new Date().getDate()).slice(-2) + "일" }</p>
              
                    <table style={{width:"100%", float:"right", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={9} className="title" style={{border:"none"}}></td>
                    <td rowSpan={2} className="approve">요구 부서</td>
                    <td className="approve">담당</td>
                    <td className="approve">차장</td>
                    <td className="approve">부서장</td>
                  </tr>
                  <tr>
                  <td>
                  </td>
                  <td>

                  </td>
                    <td>
           
                    </td>

               
                  </tr>
                </table>
              

      </div>

      </div>
      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
    </div>

  );
};

export default Request;

