import React, { useState, useEffect } from "react";


import "./css/popup.css";
import axios from "axios";




const NewMailBox = ({ member, text, closePopup }) => {

  const [userNo, setUserNo] = useState("0");
  const [name, setName] = useState("");



  useEffect(() => {
   
  }, []);

  const addUser = () =>  {
    if (name === ""){
      alert("새 메일함 이름을 입력해주세요.")
    }
    


    axios.get('http://gw.thegmmedical.com:5003/api/addBox/'+name)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
       
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  };
      
  

  return (
    <div className="popup">
      <div className="popup_inner" style={{height:"200px"}}>
        <h3 style={{textAlign:"left", marginLeft:"20px"}}>새 메일함 추가</h3>
        <div style={{padding:"20px 0"}}>
        <input type="text" placeholder="" value={name} onChange={(e)=>setName(e.target.value)}></input>
        </div>
        <div className="btns" style={{margin:"20px 0"}}>
        <button onClick={()=>addUser()}>저장</button>
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>취소</button>
        </div>
        
      </div>
      
    </div>
  );
};

export default NewMailBox;