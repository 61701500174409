import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    const {bank_name, account_holder, account_no}= rowsData;
    return (
      <tr key={index}>
       <td>
       {bank_name}
        </td>
        <td>
        {account_holder}
        </td>
        <td>
        {account_no}
        </td>
    

      </tr>
    );
  });
}
function AccountInfo({account}) {
  const [rows, initRow] = useState(account);



  const addRowTable = () => {
    const data = {
        bank_name:"", account_holder:"", account_no:""
    };
    initRow([...rows, data]);
  };
  

  return (
    <>

<div className="" style={{display:"flex", justifyContent:"flex-end", marginBottom:"20px"}}>

   

</div>

      <table style={{width:"100%"}} className="info_summary">
        <thead>
          <tr>
                <th>은행명</th>
                <th>예금주</th>
                <th>계좌번호</th>

          </tr>
        </thead>
        <tbody>
        <TableRows
            rows={rows}
      
          />
        </tbody>
      </table>
{/*<div className="btns" style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>

<button className="btn" onClick={()=>addExperience()}>
          저장
        </button>

  </div>*/}
    </>
  );
}
export default AccountInfo;