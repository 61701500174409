
import { NavLink, Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import BoardList from "../components/side/BoardList";
import axios from "axios";
import "./css/delivery.css";
import useSortableData from "./sorting";
import * as XLSX from "xlsx";
import { BsPin } from "react-icons/bs";
import Pagination from "./Pagination";
import {GiHamburgerMenu} from "react-icons/gi";

const BoardModel = () => {
    const [data, setData] = useState([]);
    const [html, setHtml] = useState("");
    const [sidebar, setSidebar] = useState(false);
    const [category, setCategory] = useState("");
    const [upDown, setUpDown] = useState(true);

    const [search, setSearch] = useState(null);
    const [options, setOption] = useState("all");
    const [filterData, setfilterData] = useState([]);
    const [notice, setNotice] = useState([]);
    const [isEmpty, setEmpty] = useState(false);
    const [isPage, setIsPage] = useState(false);
    const [isloggedIn, setLoggedIn] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const offset = (page - 1) * limit;
    const { state } = useLocation();
    const [CheckList, setCheckList] = useState([]);
    var { items, requestSort, sortConfig } = useSortableData(data);
    const getClassName = name => {
      if (sortConfig && sortConfig.key && sortConfig.direction) {
        if (sortConfig.key === name) {
          setfilterData(items);
          console.log("저기,,", sortConfig.key);
          return sortConfig.direction;
        }
        return undefined;
      }
    };
    const onChangeSearch = (e) => {
      e.preventDefault();
      setSearch(e.target.value);
    };
  
    function selectAll()  {
        const checkboxes 
             = document.getElementsByName('list');
        const all 
             = document.getElementsByName('listall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }
    useEffect(() => {
         axios.get("http://gw.thegmmedical.com:5003/api/get_as_status/models").then((response) => {
       // setNotice(response.data);
        //setfilterData(response.data);
        setData(response.data);
        setfilterData(response.data);
        if (response.data.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (response.data.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }
      });
      
    

      
    }, []);


    const addBookmark = () => {
      var result = window.confirm("즐겨찾기 게시판에 추가하시겠습니까?");
      if(result){
        const data ={
          name: "모델DB",
          table : "board",
          link : "/model"
        }
        console.log(data);
        axios.post('http://gw.thegmmedical.com:5003/api/add_bookmark', data)
      .then(function (response){
        console.log(response);
        window.location.reload(true);

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
    };
  
    const addBookmarkPage = () => {
      var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
      if(result){
        const data ={
          name: "모델DB",
          link : window.location.pathname,
          bookmark : "모델DB" + " 게시판"
        }
        console.log(window.location.pathname)
        console.log(data);
        axios.post('http://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
        .then(function (response){
          console.log(response);
          alert("메뉴 바로가기에 추가되었습니다.");
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };





  

  

  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };
  const onSearch = (e) => {
    e.preventDefault();
    if (options === "all") {
      const filtered = data.filter((itemList) => {
        var tmp = "";
        tmp+=itemList.model;
        tmp+=itemList.equipment;
        tmp+=itemList.brand;
        tmp+=itemList.full_name;
        tmp+=itemList.note;
        
        return tmp.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      items = filtered;
      if (filtered.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    } else {
      
        
      const filtered = data.filter((itemList) => {
        
        return itemList[options].toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    }
  
  };
  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
       setCheckList([...CheckList, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
       setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }
 }
 function editdelivery(){
  var array = []
  var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

  for (var i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].value!=="selectall"){
    array.push(checkboxes[i].value)
    }
  }
  console.log(array);
  if (array[0].value==="selectall"){
    array.shift();
  }
  const msg ={
    nos : array
  }
  axios.post('http://gw.thegmmedical.com:5003/api/editdelivery', msg)
  .then(function (response){
    console.log(response);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });

}
function deleteAll(){

  var result = window.confirm("정말 모두 삭제하시겠습니까?");
  if(result){
  axios.get('http://gw.thegmmedical.com:5003/api/as_del_all/models')
  .then(function (response){
    console.log(response);
    window.location.reload(true);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
  }
}
 function deleteAs(){
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }
    const msg ={
      nos : array
    }
    var result = window.confirm("선택 목록을 삭제하시겠습니까?");
    if(result){
    axios.post('http://gw.thegmmedical.com:5003/api/as_delete/models', msg)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
 
  }

  function getArrow(order) {
    if (order === 'ascending') return '▲';
    return '▼';
  }
  const onCheckEnter = (e) => {
    if(e.key === 'Enter') {
      onSearch();
    }
  }

  function displayJsonToHtmlTable(jsonData){
    var table=document.getElementById("display_excel_data");
    if(jsonData.length>0){
        var htmlData='<tr><th>Student Name</th><th>Address</th><th>Email ID</th><th>Age</th></tr>';
        for(var i=0;i<jsonData.length;i++){
            var row=jsonData[i];
            htmlData+='<tr>';
            for (var j=0;j<jsonData[i].length;j++){
              htmlData+='<td>'+jsonData[i][j]+'</td>';
            }
            htmlData+='</tr>';
            
        }
        table.innerHTML=htmlData;
    }else{
        table.innerHTML='There is no data in Excel';
    }
}
  async function excelFileToJSON(file){
    
        
const exceldata = await file.arrayBuffer();


const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});

const worksheet = workbook.Sheets[workbook.SheetNames[0]];
const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
//setHtml(htmlData);
//console.log(htmlData);
setHtml(htmlData);
const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
header:1,
defval:"",
})
for (let i = 0;i<jsondata.length;i++){
  var alphabet = "A";

  for (let j=0;j<jsondata[i].length;j++){
    console.log(jsondata[i][j]);
  }
}
}
  function upload() {
    var files = document.getElementById('file_upload').files;
    if(files.length===0){
      alert("파일을 선택해주세요.");

      return;
    }
    var filename = files[0].name;
    var extension = filename.substring(filename.lastIndexOf(".")).toUpperCase();
    if (extension === '.XLS' || extension === '.XLSX') {
        //Here calling another method to read excel file into json
        excelFileToJSON(files[0]);
    }else{
      alert("엑셀 파일을 선택해주세요.");

    }
  }
  async function handleFileAsync(e) {

    const file = e.target.files[0];
    const exceldata = await file.arrayBuffer();
          
    const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});
  
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
      setHtml(htmlData);
      //console.log(htmlData);
  
    const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
      header:1,
      defval:"",
      blankrows: false
    })
    
    var sheetnames = workbook.SheetNames;
 
    
 
  
  console.log("data",jsondata);

  const data ={
    header: "branch, ord, equipment, model, acquire_date, acquire_amount, acquire_type, use_status, serial_num, mfr_ctry, mfr_co",
    arr : jsondata
  }
  
  axios.post('http://gw.thegmmedical.com:5003/api/add_excel_data', data)
      .then(function (response){
        console.log(response);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
  
    /*for (let i = 0;i<jsondata.length;i++){
      for (let j=0;j<jsondata[i].length;j++){   
      }
    }*/
  }

  function exportExcel(){ 
    var keys= ['no', '모델명', '장비명', '담당/제조사', '장비명(FULL)', '비고'] 

    var arr = [];
    arr.push(keys);
    

    for (let i=filterData.length-1;i>=0;i--){
      var values = Object.values(filterData[i]);
      arr.push(values);
    }
    
    var wb = XLSX.utils.book_new();
    var excelHandler = {
      getExcelFileName : function(){
        return "test.xlsx";
      },
      getSheetName : function(){
        return "sheet1";
      },
      getExcelData : function(){
        return arr;
      },
      getWorksheet : function(){
        return XLSX.utils.aoa_to_sheet(this.getExcelData());
      }
    }
    var newWorksheet = excelHandler.getWorksheet();
    XLSX.utils.book_append_sheet(wb, newWorksheet, excelHandler.getSheetName());    

    XLSX.writeFile(wb, excelHandler.getExcelFileName());
  }
    const compare = ((a, b) => {
      const eleA = a[category]; // ignore upper and lowercase
      const eleB = b[category]; // ignore upper and lowercase
      if (upDown === true){
        if (eleA > eleB) {
          return -1;
        }
        if (eleA < eleB) {
          return 1;
        }
      }else{
        if (eleA < eleB) {
          return -1;
        }
        if (eleA > eleB) {
          return 1;
        }
      }
    
      // names must be equal
      return 0;
    });
    
  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
     <div className="content-wrap">
     
{/*<input type="file" id="file_upload" />
<button onClick={()=>upload()}>Upload</button>  
<div
      dangerouslySetInnerHTML={{__html: html}}
    />
<br/>
  <table id="display_excel_data"></table>*/}

      <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>
        <div className="side-menu">
        <BoardList changeBoard={(value)=>{setfilterData(value);
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}}/></div>
        <div className="board_wrap">
    
           
        <div className="">
        
        <div className="board_wrapper">
          <form name="form1" id="form1" method="post">
                  <div className="board_container">
                      <div id="content" className="content cs">
                      
                        <div className="board_top">
                        <div className="btns" style={{display:"flex",justifyContent:"start", width:"auto", margin:"0 0 20px 0", marginBottom:"20px"}}>

              
              <Link to="/model_w"><button className="btn" style={{marginRight:"10px"}}>등록</button></Link>
              {(CheckList.length>1 || CheckList.length===0) ? (<button className="btn" disabled style={{marginRight:"10px"}}>수정</button> 
                ): (<Link to="/model_u" state={{ no: CheckList[0] }} >
                <div className="btn" style={{marginRight:"10px"}}>수정</div> </Link>
                )}
              <div className="btn" style={{marginRight:"10px"}} onClick={()=>deleteAs()}>삭제</div>
              <div className="btn" style={{marginRight:"10px"}} onClick={()=>deleteAll()}>전체삭제</div>

              <div className="btn" style={{marginRight:"10px"}} onClick={()=>exportExcel()}>Export</div>


              
            </div>

            <div style={{margin:"0 0 20px"}}>모델DB</div>

                              {/*<label>
                            페이지 당 게시물 수:&nbsp;
                            <select
                            type="number"
                            value={limit}
                            onChange={({ target: { value } }) => setLimit(Number(value))}
                            >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </label>*/}
                              <div className="bt_srch"style={{display:"flex"}}>
                     
                                <div className="bts_slct">
                                  <select
                                    id="u_shcate"
                                    name="search_item"
                                    className="select"
                                    title="검색항목 선택"
                                    value={options || ''}
                                    onChange={selectHandle}
                                  >
                                    <option value="all">전체검색</option>
                                    <option value="model">모델명</option>
                                    <option value="equipment">장비명</option>
                                    <option value="brand">담당/제조사</option>
                                    <option value="full_name">장비명(FULL)</option>
                                    <option value="note">비고</option>
                                   
                                  </select>

                                </div>
                                <form onKeyPress={onCheckEnter} style={{display:"flex"}}>
                                <div className="bt_input">
                                  <input
                                    type="text"
                                    id="search_content"
                                    name="search_content"
                                    value={search || ''}
                                    placeholder="검색어를 입력하세요"
                                    onChange={onChangeSearch}
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="search_btn"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                                </form>
                              </div>
                          </div>
                          <div className="as_list">


      <table class="delivery table-hover bd-b">
			<thead class="thead-dark">
			<tr class="tx-center">
      <th><input type='checkbox' name='listall' value='selectall' onClick={selectAll}/></th>

      
			<th class="wd-150 xl-hidden">
        <span onClick={()=>{if (category !=="model"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("model"); filterData.sort(compare);}}>모델명</span>

      </th>
      <th class="wd-150 xl-hidden">
        <span onClick={()=>{if (category !=="equipment"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("equipment"); filterData.sort(compare);}}>장비명</span>

      </th>
      <th class="wd-150 xl-hidden">
        <span onClick={()=>{if (category !=="brand"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("brand"); filterData.sort(compare);}}>담당/제조사</span>
  
      </th>
      <th class="wd-150 xl-hidden">
      <span onClick={()=>{if (category !=="full_name"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("full_name"); filterData.sort(compare);}}>장비명(FULL)</span>
      </th>
      <th class="wd-150 xl-hidden">
        <span onClick={()=>{if (category !=="note"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("note"); filterData.sort(compare);}}>비고</span>

      </th>
      
			</tr>
									</thead>
									<tbody>
                 
                                    {isEmpty && (
                                  <tr style={{ textAlign: "center" }}>
                                    <td colSpan={40} style={{height:"200px"}}>게시물이 없습니다.</td>
                                  </tr>
                                )}
                                {filterData
                                  .slice(offset, offset + limit)
                                  .map((element, index) => (
                                      <tr key={element.no}>
                                    
                                    <td>
                                    <input type='checkbox' 
                                  name='list' 
                                  value={element.no}
                                  onChange={(e) => onChangeEach(e, element.no)} checked={CheckList.includes(element.no)}/> 
                                </td>

                                <td>
                                        {element.model}
                                        </td>
                                      
                                        <td>
                                        {element.equipment}
                                        </td>
                                        <td>
                                        {element.brand}
                                        </td>
                                        <td>
                                        {element.full_name}
                                        </td>
                                        <td>
                                        {element.note}
                                        </td>
                                       
                                      </tr>)
                                     
                                    
                                  )}

									</tbody>
								</table>
          </div>
<div className="btns pc" style={{marginTop:"20px", justifyContent:"start"}}>

<div className="btn" style={{marginRight:"10px"}} onClick={()=>addBookmark()}>즐겨찾기 추가</div>
<div className="btn" style={{display:"flex", alignItem:"center", padding:"5px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>
</div>  
          {(isPage && filterData.length>0) && (
                    <div className="paging">
                      <Pagination
                        postsPerPage={limit}
                        totalPosts={filterData.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>
                      {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
                    </div>
                  )}
                      </div>
                  </div>
          </form>
        </div>
      </div>
            
            
                
                
                
        </div>

                </div>
     </div>
    </div>

  );
};

export default BoardModel;
