import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import "../css/sidenav.css";
import { HiMail, HiPlus } from "react-icons/hi";
import { BiTagAlt } from "react-icons/bi";
import { RiMailSendFill, RiStarLine,RiDraftLine, RiSpamLine, RiSettings3Line } from "react-icons/ri";
import { FaTrashAlt } from "react-icons/fa";
import { AiOutlineSetting } from "react-icons/ai";

import NewMailBox from "../NewMailBox";

const SideNav = () => {
  const [unseen, setUnseen]=useState(0);
  const [boxes, setBoxes]=useState([]);
  const [link, setLink] = useState("");
  const [showPopup, setShowPopup]=useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(".................", window.location.pathname.substring(1));
    setLink(window.location.pathname.substring(1));
    axios.get('https://gw.thegmmedical.com:5003/api/getboxes')
    .then(function (response){
      //console.log(response);
      setBoxes(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

  }, []);
 
  function fetch() {
    axios.get("https://gw.thegmmedical.com:5003/api/unseen_num", {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      console.log("unseen_num,,,,", data);
      setUnseen(data.unseen)
    });
  };
  fetch();

  const togglePopup = () =>{
    setShowPopup(!showPopup)
  }
  return (
    <div className="wrapper">
{showPopup ? (
  <NewMailBox closePopup={() => togglePopup()} />
) : null}

<div class="board_nav email">
{/*<button onClick={()=>fetch()}>ㄱㄱ{boxes}</button>*/}
  <div style={{padding:"30px"}}>
  <div class="unseen_info">
      <span class="text">안읽은 메일</span><span class="count">{unseen}</span>
      
 
  </div>
  <div className="write_btn"><a href="/write">메일쓰기</a><a href="/write_to_me">내게쓰기</a></div>
  <div className="list">

    
  <a href="/list/INBOX" className={link === "list/INBOX" ? "box_item active" : "box_item"}><li><HiMail/><span>받은 편지함</span></li></a>

  
   
    
  <a href="/list/sent" className={link === "list/sent" ? "box_item active" : "box_item"}>
  <li style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
    <div><RiMailSendFill/>
    <span>보낸 편지함</span>
    </div>
   
    <a href="/mailread"><div style={{border:"1px solid #000", padding:"2px 5px"}}>수신확인</div></a>
 
  </li></a>





  <a href="/list/Stars" className={link === "list/Stars" ? "box_item active" : "box_item"}><li><RiStarLine/><span>중요 메일함</span></li></a>
  <a href="/list/draft" className={link === "list/draft" ? "box_item active" : "box_item"}><li><RiDraftLine/><span>임시 보관함</span></li></a>

<div className="separated"></div>
    <div style={{display:"flex", justifyContent:"space-between"}}><li style={{color:"gray"}}>내 메일함</li>
    <div style={{display:"flex", alignItems:"center", padding:"0 15px"}}>
      <AiOutlineSetting style={{cursor:"pointer"}} onClick={()=>navigate("/setting/mail/1")}/><HiPlus style={{cursor:"pointer"}} onClick={()=>togglePopup()}></HiPlus></div></div>
  {boxes.length>0 && (
    <ul style={{margin:"0"}}>

   {boxes.map((box, idx) => {

    if ((box.path).includes("INBOX.")){
     return <a href={"/list/"+box.name} className={link === "list/"+box.name ? "box_item active" : "box_item"}>
     <li>
    
       <BiTagAlt/><span>{box.name}</span>  
        
     </li>
     </a>
    }})}

    </ul>
  )}

<div className="separated"></div>
  <a href="/list/Junk" className={link === "list/Junk" ? "box_item active" : "box_item"}><li><RiSpamLine/><span>스팸 보관함</span></li></a>

  {boxes.map((box, idx) => {

  if (!(box.path).includes("INBOX.") && box.path !=="INBOX" && box.name !=="sent" && box.name !=="Junk" && box.name !=="delete" && box.name !=="draft" && box.name !=="Stars"){
  return <a href={"/list/"+box.name} className={link === "list/"+box.name ? "box_item active" : "box_item"}>
  <li>

    <span>{box.name}</span>  
      
  </li>
  </a>
  }})}
  <a href="/list/delete" className={link === "list/delete" ? "box_item active" : "box_item"}><li><FaTrashAlt/><span>휴지통</span></li></a>
  <a href="/setting/mail/0" className={link === "setting/mail/0" ? "box_item active" : "box_item"}><li><RiSettings3Line/><span>환경설정</span></li></a>
  </div>


  </div>
  </div>
    </div>

  );
};

export default SideNav;







