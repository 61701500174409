




import {  Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { HiPencilAlt, HiDocumentReport } from "react-icons/hi";
import {BsPencilSquare, BsPlusSquare} from "react-icons/bs";
import "../css/schedulelist.css";



const AddressList = ({onChange}) => {
    const [admin, setAdmin] = useState(false);
    const [received, setReceived] = useState([]);
    const [sent, setSent] = useState([]);
    const [myGroups, setMyGroups] = useState([]);
    const [addrs, setAddrs] = useState([]);

    const [members, setMembers] = useState([]);
    const [parent, setParent] = useState("");
    const [dHead, setDHead] = useState([]);
    const [name, setName] = useState("");
    const [num, setNum] = useState(0);
    const [department, setDepartment]= useState([]);
    const navigate = useNavigate();
    const {state}= useLocation();

    const onTreeStateChange = (state, event) => console.log(state, event);

    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].bg_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.bg_name,
            parentNo : node.bg_parent,
            num : node.bg_no,
            level : node.bg_level,
            order : node.bg_order,
            dNo : node.d_no,
            type : node.bg_type,
            children: lookingForNodeWithParent( nodes, node.bg_no )
          });
        }
      }
      
      return arrayToReturn;
    }
    let location = useLocation();

  


    useEffect(() => {
      axios.get('http://gw.thegmmedical.com:5003/api/check_dhead',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      console.log("부서장ㅇ?", response.data[0]);
      setDHead(response.data[0]);
      //setBookmarks(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('http://gw.thegmmedical.com:5003/api/check_admin',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      console.log("부서장ㅇ?", response.data[0]);
      if (response.data[0].authority ==="admin"){
        setAdmin(true);
      }else{
        setAdmin(false);
      }
      
      //setBookmarks(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('http://gw.thegmmedical.com:5003/api/getaddrs',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    setAddrs(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

   
    axios.get('http://gw.thegmmedical.com:5003/api/get_teamaddr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setDepartment(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('http://gw.thegmmedical.com:5003/api/get_user_addr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setMyGroups(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    }, []);
 

        function allBoard(){
          axios.get("http://gw.thegmmedical.com:5003/api/news").then((response) => {
              
            });
        }
    
     
     
     
     
    
     
      
      
      // add fake url to data for testing purpose
    
      const [CheckList, setCheckList]=useState([2,3,4,8]);
   
  return (
    <div className="wrapper">
     
     <div className="board_nav schedule_nav">
     <div style={{padding:"30px", background:"#f7f7f7"}}>
        <h2>주소록</h2>

        <div style={{display:"flex", justifyContent:"space-between"}}>
        <a href="/addr_w" style={{width:"48%"}}><div className="board_wrt"><HiPencilAlt style={{marginRight:"5px"}}/>상세 등록</div></a>
        <a href="/quick_addr" style={{width:"48%"}}><div className="board_wrt" style={{border:"1px solid #7282fe", background:"#fff", color:"#7282fe"}}><HiPencilAlt style={{marginRight:"5px"}}/>빠른 등록</div></a>
        </div>

    <div className="wrap">


   

  
{/*
<div className="list">
<span className="list_title" onClick={()=>allBoard()}><span><FaRegListAlt/>공용 주소록</span> 
<Link to="/addrgrp_add" state={{type:"all"}}><BsPlusSquare/></Link></span>

<div className="menus">
{addrs.map((element, index)=>{
    var color = `${element.color}`;
  
    return (<div style={{display:"flex", marginBottom:"5px", 
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}>
    <span className={element.no}><a href={"/addr_list/"+element.no}>{element.g_name}</a></span></div>
 
    <Link to="/addrgrp_u" state={{group : element}}><BsPencilSquare/></Link>
   
    
    </div>
    
    )
  })}
</div>    
</div>
*/}

<div className="list">
  <span className="list_title"><span><HiDocumentReport/>부서 주소록</span>
  <Link to="/addrgrp_add" state={{type:"department"}}><BsPlusSquare/></Link></span>

  <div className="menus">
{department.map((element, index)=>{
    var color = `${element.color}`;
    /*const style = {
      color: '#000000',
      "&::after": {
        backgroundColor: element.color
      }
    };*/

    
    return (<div style={{display:"flex", marginBottom:"5px", 
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}>
    <span className={element.no}><a href={"/addr_list/"+element.no}>{element.g_name}</a></span></div>
 
    <Link to="/addrgrp_u" state={{group : element}}><BsPencilSquare/></Link>
   
    
    </div>
    
    )
  })}
</div>    
</div>

<div className="list">
  <span className="list_title"><span><HiDocumentReport/>개인 주소록</span>
  <Link to="/addrgrp_add" state={{type:"my"}}><BsPlusSquare/></Link></span>

  <div className="menus">
{myGroups.map((element, index)=>{
   
    
    return (<div style={{display:"flex", marginBottom:"5px", 
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}>
    <span className={element.no}><a href={"/addr_list/"+element.no}>{element.g_name}</a></span></div>
 
    <Link to="/addrgrp_u" state={{group : element}}><BsPencilSquare/></Link>
   
    
    </div>
    
    )
  })}
</div>    
 
</div>

</div>

        </div>
        </div>
    </div>

  );
};

export default AddressList;








