import { Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import FolderTree, { testData } from 'react-folder-tree';
import {FaBuilding, FaSitemap, FaStar, FaRegListAlt,FaClipboardList } from "react-icons/fa";
import { MdAnnouncement } from "react-icons/md";
import {HiPencilAlt} from "react-icons/hi";
import "../css/boardlist.css";
import {IoIosStats} from "react-icons/io";
import TreeNode from "./TreeNode.js";

let currentData = [];
/*
const TreeNode = ({ node }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };


  return (
    <div className="tree-node">
      <div onClick={handleToggle} className={`node-toggle ${isExpanded ? 'expanded' : ''}`}>
        {(isExpanded && node.children !==undefined) &&  (<span><FaRegFolderOpen />  {node.name}</span>)}
        {(!isExpanded && node.children !==undefined) &&  (<span><FaRegFolder/>  {node.name}</span>)}
        {(node.children ===undefined) &&  (<span><FaClipboardList/>  {node.name}</span>)}


      </div>
      {(isExpanded && node.children !==undefined) && (
        <ul className="child-nodes">
          {node.children.map(childNode => (
            <li key={childNode.id}>
              <TreeNode node={childNode} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};*/

const BoardList = ({changeBoard, changeNotice, changeBoardN, changeBoardNum, boardN, boardName}) => {
    const [folders, setFolders] = useState([]);
    const [teamFolders, setTeamFolders] = useState([]);
    const [members, setMembers] = useState([]);
    const [parent, setParent] = useState("");
    const [bookmarks, setBookmarks] = useState([]);
    const [name, setName] = useState("");
    const [num, setNum] = useState(0);
    const [selected, setSelected]= useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [CSAdmin, setCSAdmin] = useState(false);

    const onTreeStateChange = (state, event) => console.log(state, event);

    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].bg_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.bg_name,
            parentNo : node.bg_parent,
            num : node.bg_no,
            level : node.bg_level,
            order : node.bg_order,
            dNo : node.d_no,
            link : node.link,
            type : node.bg_type,
            unread : node.unread,
            children: lookingForNodeWithParent( nodes, node.bg_no )
            
          });
        }
      }
      
      return arrayToReturn;
    }
    
      function clean(object) {
        Object
            .entries(object)
            .forEach(([k, v]) => {
              
                if (v && typeof v === 'object') {
                    clean(v);
                }
                if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
                    if (Array.isArray(object)) {
                        object.splice(k, 1);
                        
                    } else {
                        delete object[k];
                    }
                }
            });
        return object;
    }
function colorList(){
  
  if (location.state !==null && location.state!==undefined){
    
    setName(location.state.name);
    setNum(location.state.num);
  
    if (location.state.bg_no !== null && location.state.boardName !== undefined){
      var main = document.getElementsByClassName('tree-item');

        
  var searchText;
  var found;
  
  searchText=location.state.boardName;
  
  

  for (var i = 0; i < main.length; i++) {

    console.log("main.....", main[i], "hey", main[i].getElementsByTagName("span"));
    main[i].getElementsByTagName("span")[0].style.background="transparent";
    if (main[i].getElementsByTagName("span")[0].textContent === searchText) {
      found = main[i].getElementsByTagName("span")[0];
      break;
    }
  }
    }
  }

  if (found !==undefined){
     found.style.backgroundColor = '#d3daff';
    //found.click();

  }
 
}

const isSameUser = (a, b) => a.bg_no === b.bg_no;

// Get items that only occur in the left array,
// using the compareFunction to determine equality.
const onlyInLeft = (left, right, compareFunction) => 
  left.filter(leftValue =>
    !right.some(rightValue => 
      compareFunction(leftValue, rightValue)));


useEffect(() => {
 
  axios.get('https://gw.thegmmedical.com:5003/api/getboardlist',{})
  .then(function (response){
  //setBoxes(json[0].boxes);
    var array = lookingForNodeWithParent( response.data, null );
     

  
    setFolders(clean(array));

    console.log(array);
    
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
  //always executed
  });
axios.get('https://gw.thegmmedical.com:5003/api/get_teamboard',{
        
})
.then(function (response){
//setBoxes(json[0].boxes);
console.log("get_teamboard", response.data);
let arr = response.data;
const result = arr.map(node => ({

  name: node.bg_name,
            parentNo : node.bg_parent,
            num : node.bg_no,
            level : node.bg_level,
            order : node.bg_order,
            dNo : node.d_no,
            link : node.link,
            type : node.bg_type,
            unread : node.unread

}));

var array = lookingForNodeWithParent( response.data, null );

const onlyInA = onlyInLeft(array, result, isSameUser);
const onlyInB = onlyInLeft(result, array, isSameUser);

const resultArray = [...onlyInA, ...onlyInB];

console.log("resultArr...", resultArray, clean(array));
  setTeamFolders([...clean(array), ...resultArray]);
  

       
      //let newData = array.map(el => el[0]);
          //console.log(newData);
          
})
.catch(function (error){
console.log(error);
})
.then(function (){
//always executed
});
 
axios.get('https://gw.thegmmedical.com:5003/api/get_bookmark/board',{
        
})
.then(function (response){
//setBoxes(json[0].boxes);
  console.log("즐찾", response.data);
  setBookmarks(response.data);
})
.catch(function (error){
console.log(error);
})
.then(function (){
//always executed
});
      
axios.get('https://gw.thegmmedical.com:5003/api/admin_CS')
.then(function (response){
  if (response.data.length>0){
    setCSAdmin(true);
  }
})
.catch(function (error){
  console.log(error);
})
.then(function (){
});
//colorList();

console.log("BoardList.......... useEffect", location.state);

if (boardN!==undefined){
  setNum(boardN);
  setName(boardName);
}
    }, []);
 

    const delBookmark = (e) =>{
      var result = window.confirm("즐겨찾기 게시판에서 삭제하시겠습니까?");
    if(result){
      
      const data ={
        table : "board",
        num : e.target.className
      }
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/delete_bookmark', data)
    .then(function (response){
      console.log(response);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
    }

        function allBoard(){
          axios.get("https://gw.thegmmedical.com:5003/api/news").then((response) => {
              changeBoard(response.data);
              
            });


            
        }
        function noticeBoard(){
          navigate("/board", {state: { boardName : "공지사항", type: "board"}});
          axios.post('https://gw.thegmmedical.com:5003/api/getboard',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              folder_name: "공지사항"
            },
          })
    .then(function (response){
      changeBoard(response.data)
      //setBoard(response.data)
      axios.post("https://gw.thegmmedical.com:5003/api/notice_board",{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        folder_name: "공지사항"
      },
      }).then((response) => {
        changeNotice(response.data)
        //setNotice(response.data);
        //navigate("/board", {state: { boardName : name}});
        
      });
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
    
        }
        const onNameClick = ({ defaultOnClick, nodeData }) => {
          defaultOnClick();
          var notice_data=[];
          var board_data=[];
          console.log("nodedata....", nodeData);
          const {
            // internal data
            path, name, num, link, type, isOpen, children
            // custom data
          } = nodeData;
          if (link !==null && link !==undefined){
            navigate(link, {state: { boardName : name, bg_no: num}});
          }else if (type==="board"){
            navigate("/board", {state: { boardName : name, bg_no: num}});
          }else if (type==="notice"){
            navigate("/board", {state: { boardName : name, bg_no: num}});
          }else{
            return;
          }
          setName(name);
            setNum(num);
          if (type!=="group"){
            setSelected(nodeData);
            var main = document.getElementsByClassName('main_folder');

            var aTags = main[0].getElementsByTagName("span");
            
      var searchText;
      var found;
      
              searchText=name;
            
      for (var i = 0; i < aTags.length; i++) {
        if (aTags[i].textContent === searchText) {
          found = aTags[i];
          break;
        }
      }
    
      const boxes = document.querySelectorAll('.editableNameContainer');
      
      
      found.click();
            setParent(name);
            
            /*const tempArray = [...options, item];
            console.log(tempArray);
            setOptions(tempArray);*/
      
      
            const item = name;
            const tempArray = [...members, item];
            setMembers(tempArray);
            changeBoardN(name);
            changeBoardNum(num);
            
            axios.post('https://gw.thegmmedical.com:5003/api/getboard',{
                data: {
                  // 서버에서 req.body.{} 로 확인할 수 있다.
                  bg_no: num
                },
              })
        .then(function (response){
          changeBoard(response.data)
          board_data = response.data;

        console.log("umm,,,,", response.data);
          //setBoard(response.data)
          axios.post("https://gw.thegmmedical.com:5003/api/notice_board",{
          data: {
            // 서버에서 req.body.{} 로 확인할 수 있다.
            folder_name: name
          },
          }).then((response) => {
            notice_data = response.data;
            changeNotice(response.data)
            //setNotice(response.data);
            //navigate("/board", {state: { boardName : name}});
            
          });
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
        
        
      
          }
          
      };


    const changeBoardData = (element) =>{


      console.log("됏냐고", element);

        //changeBoardN(name);
      changeBoardNum(element.bg_no);
      changeBoardN(element.bg_name);  
      setName(element.bg_name);
      setNum(element.bg_no);
        axios.post('https://gw.thegmmedical.com:5003/api/getboard',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              bg_no: element.bg_no
            },
          })
    .then(function (response){
      changeBoard(response.data)
      console.log("됏냐고2", response.data);
      //setBoard(response.data)
      axios.post("https://gw.thegmmedical.com:5003/api/notice_board",{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_name: element.bg_name
      },
      }).then((response) => {
        
        changeNotice(response.data)
        //setNotice(response.data);
        //navigate("/board", {state: { boardName : name}});
        
      });
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    




    };
      const bookmarkClick = (element) =>{
        console.log("bookmark....", element);
        if (element.link !== null){
          navigate(element.link,  {state: { bg_name : element.name}});
        }else{
          navigate('/board',  {state: { bg_name : element.name}});
          var notice_data=[];
          var board_data=[];
          
        axios.post('https://gw.thegmmedical.com:5003/api/getboard',{
          data: {
            // 서버에서 req.body.{} 로 확인할 수 있다.
            folder_name: element.name
          },
        })
  .then(function (response){
    changeBoard(response.data)
    board_data = response.data;
    console.log("hihiihihi", response.data);
    //setBoard(response.data)
    axios.post("https://gw.thegmmedical.com:5003/api/notice_board",{
    data: {
      // 서버에서 req.body.{} 로 확인할 수 있다.
      folder_name: element.name
    },
    }).then((response) => {
      notice_data = response.data;
      changeNotice(response.data)
      //setNotice(response.data);
     // navigate("/board/"+e.target.className, {state: { board : board_data, notice:notice_data, name: name, num: num }});
      //navigate("/board", {state: { boardName : e.target.className}});

    });
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
  var main = document.getElementsByClassName('main_folder');
    
  var aTags = main[0].getElementsByTagName("span");
  
  var searchText;
  var found;
  
  searchText=element.name;
  for (var i = 0; i < aTags.length; i++) {console.log(aTags[i].textContent, "  search는", searchText);
    if (aTags[i].textContent === searchText) {
      
      found = aTags[i];
      console.log(aTags[i]);
      const boxes = document.querySelectorAll('.editableNameContainer');
  
     
      found.click();
      break;
    }
  }

        }
        
        
        /*var notice_data=[];
          var board_data=[];
          
        axios.post('https://gw.thegmmedical.com:5003/api/getboard',{
          data: {
            // 서버에서 req.body.{} 로 확인할 수 있다.
            folder_name: e.target.className
          },
        })
  .then(function (response){
    changeBoard(response.data)
    board_data = response.data;
    console.log("hihiihihi", e.target.className);
    console.log("hihiihihi", response.data);
    //setBoard(response.data)
    axios.post("https://gw.thegmmedical.com:5003/api/notice_board",{
    data: {
      // 서버에서 req.body.{} 로 확인할 수 있다.
      folder_name: e.target.className
    },
    }).then((response) => {
      notice_data = response.data;
      changeNotice(response.data)
      //setNotice(response.data);
     // navigate("/board/"+e.target.className, {state: { board : board_data, notice:notice_data, name: name, num: num }});
      //navigate("/board", {state: { boardName : e.target.className}});

    });
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });*/

  
      }
      const FileIcon = ({ onClick: defaultOnClick, nodeData }) => {
        const { path, name, checked, isOpen, url, ...restData } = nodeData;
        const handleClick = () => {
          defaultOnClick();
        };
      
        return <FaClipboardList onClick={ handleClick } />;
      };
      
     
     
    
     
      
      
      // add fake url to data for testing purpose
    
      const iconComponents = {
        FileIcon
      };
  return (
    <div className="wrapper" style={{height:"100%"}}>
     
     <div className="board_nav" style={{height:"100%"}}>
     <div style={{padding:"30px", background:"#f7f7f7"}}>

{/*}
<TreeView data={folders} changeBoard={(value)=>changeBoard(value)}
changeNotice={(value)=>changeNotice(value)} 
changeBoardN={(value)=>changeBoardN(value)} 
changeBoardNum={(value)=>changeBoardNum(value)} 
  />*/}
        <h2>게시판</h2>
        <Link to="/boardwrite" state={{boardName: name, bg_no: num}}><div className="board_wrt">
          <HiPencilAlt style={{marginRight:"5px"}}/>글쓰기</div></Link>


    <div className="folder-wrap">

    <div className="folder">
<span className="folder_title" onClick={()=>allBoard()}><FaRegListAlt/>전체게시글</span>

    
    </div>
   
    <div className="FolderTree">
<span className="folder_title"><FaStar/>즐겨찾기</span>

<div className="bookmarks" style={{display:"flex", flexDirection:"column"}}>
{bookmarks.map((element,index)=>

     <span className={element.name} onClick={()=>bookmarkClick(element)}><div className={element.no} onClick={(e)=>delBookmark(e)}><FaStar/></div>{element.name}</span>

)}
</div>    
</div>


  
    <div className="main_folder">

    <div className="folder">
<span className="folder_title"><FaBuilding/>전사 게시판</span>
    
    <div style={{marginTop:"10px"}}>

    
{folders.map(rootNode => (
        <TreeNode key={rootNode.id} node={rootNode} boardNo={(value)=>{changeBoardData(value);
        }}/>


        
))}
</div>
</div>


     

    <div className="folder">
 <span className="folder_title"><FaSitemap/>부서 게시판</span>
    
    { teamFolders.map((element, index) =>
    
    <FolderTree
      data={ element }
      onChange={ onTreeStateChange }
      onNameClick={ onNameClick }
      showCheckbox={ false } 
      iconComponents = {iconComponents}
      indentPixels={ 15 }
      readOnly
      />    
    ) }
      </div>
      {CSAdmin &&

      <Link to = "/stats"><span className="folder_title"><IoIosStats/>AS 현황 통계</span></Link>

      }


      </div>

</div>


        </div>
        </div>
    </div>

  );
};

export default BoardList;







