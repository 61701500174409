
import { NavLink, Link,useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailNav from "../components/side/MailNav";
import './css/view.css';
import { AiFillPrinter } from "react-icons/ai";
import LeaveView from "../components/LeaveView";
import OTView from "../components/OTView";
import Order from "./forms/OrderView";
import Confirm from "./forms/ConfirmView";
import Request from "./forms/RequestView";
import Repair from "./forms/RepairView";
import Rent from "./forms/RentView";
import Service from "./forms/ServiceView";
import Endoscope from "./forms/EndoscopeView";
import Inspect from "./forms/InspectView";
import Acquire from "./forms/AcquireView";
import HR from "./forms/HRView";
import Purchase from "./forms/PurchaseView";


import LogiOrder from "./logistics/OrderView";

import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { Sheet, Op, Selection, colors } from "@fortune-sheet/core";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import { GiHamburgerMenu } from "react-icons/gi";
import SendToBoard from "../components/SendToBoard";

import "@fortune-sheet/react/dist/index.css"
import initial from "./data/cell";
import ExcelJS from 'exceljs'
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useReactToPrint } from 'react-to-print';

axios.defaults.withCredentials = true; 
const initialValues = {
  subject: "",
  user_no: "",
  user_name: "",
  form_group :"",
  form_no:"",
  form_name:"",
  cc: "",
  desc:"",
  recipient:"",
  expire:"1",
  security:"1",
 
};


const BoardDocView = ({ doc_no }) => {

  const [values, setValues] = useState(initialValues);
  const [sidebar, setSidebar] = useState(false);
  
  const [selectForm, setSelectForm] = useState([]);
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [test, setTest]= useState();
  const [lineList, setLineList] = useState([]);
  const [lineName, setLineName] = useState("");
  const [lineNo, setLineNo] = useState();
  const [__html, setHtml] = useState("");
  const [name, setname]= useState("");
  const [, updateState] = React.useState();
  const [divv, setDivv] = useState();
  const [showPopup, setPopup] = useState(false);

  const togglePopup = () => {
    setPopup(!showPopup);
  };

  //const [shData, setShData]= useState();
  const [dddd, setDDDD] = useState();
  const [absent, setAbsent] = useState([]);
  var shData=[];
  function nextChar(c) {
    return String.fromCharCode(c.charCodeAt(0) + 1);
  }
  const itscell2 = {
    name: "Sheet1",
    config: {
      
     
      
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
     
      },
      
   
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 68,
    status: 1,
    ch_width: 2361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    /*calcChain: [
      {
        r: 0,
        c: 3,
        id: "0",
        func: [true, 3, "=Formula!A1+Formula!B1"],
        color: "w",
        parent: null,
        chidren: {},
        times: 0
      }
    ],*/
    scrollLeft: 0,
    scrollTop: 0
  };
  useEffect(()=>{

    
    
    axios.get('https://gw.thegmmedical.com:5003/api/docview/'+doc_no)
    .then(function (response){
    console.log("hey result is!!!!doc_no", doc_no, response.data[0]);

    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (res){
      let userNo = res.data[0].user_no;

      axios.get('https://gw.thegmmedical.com:5003/api/getdocline/'+doc_no)
        .then(function (l){
        
          var result=l.data;
        for (let i=0;i<l.data.length;i++){
          if (l.data[i].status === 4){
            result[l.data.length-1].sign_link = l.data[i].sign_link;
            console.log("heyhey");
            console.log("line list....", result);
          }
        }
        console.log("line...", result);
        let lineUsers = result.map(value => value.user_no);

        setLineList(result);

        axios.get('https://gw.thegmmedical.com:5003/api/get_security/'+response.data[0].security)
      .then(function (r){
        console.log("secu", r.data);
        if (r.data.length === 0){
          setData(response.data[0]);
          if (response.data[0].form_data !== null && response.data[0].form_data !== "undefined" && response.data[0].form_data !==""){
            var excel_data = JSON.parse(response.data[0].form_data);
            excel_data.showGridLines = 0;
            var rowN = excel_data.row;
            var height= parseInt(rowN) * 15;
            height = height + "px";
            height = height.toString();
            console.log(height, excel_data);
            console.log("excel_data...", excel_data);
            setDivv(<div style={{width:"100vh", height:"600px", overFlow:"visible"}}><Workbook data={[excel_data]} showToolbar={false} showFormulaBar={false} showSheetTabs={false} allowEdit={false} rowHeaderWidth={0} columnHeaderHeight={0}/></div>) ;
      
          }else{
            setDivv(<div></div>)
          }
          if (response.data[0].form_no === 1){
            console.log("1입니다");
            setDivv(<LeaveView docNo = {response.data[0].doc_no}/>)
          }else if (response.data[0].form_no === 2){
            setDivv(<OTView docNo = {response.data[0].doc_no}/>)
          }
        }else{
          let userNos = r.data.map(value => value.user_no);
          if (!userNos.includes(userNo) && response.data[0].user_no !== userNo
          && !lineUsers.includes(userNo)){
            alert("열람할 수 없습니다");
            window.history.go(-1);
          }else{
            setData(response.data[0]);
            if (response.data[0].form_data !== null && response.data[0].form_data !== "undefined" && response.data[0].form_data !==""){

              var excel_data = JSON.parse(response.data[0].form_data);
              excel_data.showGridLines = 0;
              height = height + "px";
              height = height.toString();
              console.log(height, excel_data);
              console.log("excel_data...", excel_data);
              setDivv(<div style={{width:"100vh", height:"600px", overFlow:"visible"}}><Workbook data={[excel_data]} showToolbar={false} showFormulaBar={false} showSheetTabs={false} allowEdit={false} rowHeaderWidth={0} columnHeaderHeight={0}/></div>) ;
        
            }else{
              setDivv(<div></div>)
            }
            if (response.data[0].form_no === 1){
              setDivv(<LeaveView docNo = {response.data[0].doc_no}/>)
            }else if (response.data[0].form_no === 2){
              setDivv(<OTView docNo = {response.data[0].doc_no}/>)
            }
          }
        }
    
    
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
        
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
      
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    

    
    
   
    
    

    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
      console.log("user is", response.data);
    setUser(response.data[0]);
    var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
    setname(name);
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/checkabsent')
    .then(function (response){
      console.log("absent is", response.data);
      var user_nos = response.data.map(function(item) {
        return item['user_no'];
      })
    console.log("absent...", user_nos);
    setAbsent(user_nos);

    
    
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

}, []);






  const itscell = {
    name: "Sheet1",
    config: {
      
     
      
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
     
      },
      
   
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 68,
    status: 1,
    ch_width: 2361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    /*calcChain: [
      {
        r: 0,
        c: 3,
        id: "0",
        func: [true, 3, "=Formula!A1+Formula!B1"],
        color: "w",
        parent: null,
        chidren: {},
        times: 0
      }
    ],*/
    scrollLeft: 0,
    scrollTop: 0
  };


  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  window.addEventListener('message', (event) => {
    if (event.data["line_no"] !== undefined){
      var line_no = event.data["line_no"];
      var line_name = event.data["line_name"];
      var line_list = event.data["line_list"];
      
      setLineNo(line_no);
      setLineName(line_name);
      setLineList(line_list);
    }

   
  });
////////////////////////////////////////////////
// EXCEL IMPORT
////////////////////////////////////////////////



const componentRef = useRef();
const formRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const formPrint = useReactToPrint({
    content: () => formRef.current,
  });


  
  return (
    <div className="wrapper">


      
<div className="content-wrap">
    
      
    <div className="outer_wrapper docview" style={{justifyContent:"flex-start", width:"100%"}}>
        
    

    <div className="docwrite">
  
    

    <div className="docs_view">
            
      <div className="doc_top">
        <h4 style={{padding:"0 10px"}}>문서 보기 </h4>
        <div className="doc_buttons">
          <div className="btn" onClick={handlePrint}><AiFillPrinter/>인쇄</div>
          <div className="btn" onClick={formPrint}><AiFillPrinter/>양식 인쇄</div>
        </div>
      </div>
          <div style={{background:"#fff", padding:"0 10px"}} ref={componentRef}>
          <div className="doc_header">
            <h3>{data.form_name}</h3>
            {(lineList.length >0 && data.type !=="custom") && (
              <div style={{display:"flex", justifyContent:"end"}} className="line">
                {lineList.map((element, index)=> (
                  (() => {
                    if (element.decider !==null && element.decider !==0 && element.status !==4) {
                      return (
                        <>
                        <table>
            <tr><td rowSpan={3} className="type">{element.type}</td><td className="job">{element.decider_job}</td></tr>
            <tr><td className="name"><div className="name">{element.decider_name}</div>
            
            <div style={{height:"40px", display:"flex", alignItems:"center"}}>대결</div>
            </td></tr>
            <tr></tr>
            </table>
            
            <table>
            <tr><td rowSpan={3} className="type">{element.type}</td><td className="job">{element.job_name}</td></tr>
            <tr><td className="name slash"><div className="name"></div>
            
            </td></tr>
            <tr></tr>
            </table>
            </>
                      )
                    } else if(element.status === 4 && element.last !== 1) {

                      return (<table>
            <tr><td rowSpan={3} className="type">{element.type}</td><td className="job">{element.job_name}</td></tr>
            <tr><td className="name"><div className="name">{element.name}</div>
            
            <div style={{height:"40px", display:"flex", alignItems:"center"}}>전결</div>
            </td></tr>
            <tr></tr>
            </table>)
                    }else if(element.status === 4 && element.last === 1) {

                      return (<table>
            <tr><td rowSpan={3} className="type">{element.type}</td><td className="job">{element.job_name}</td></tr>
            <tr><td className="name"><div className="name">{element.name}</div>
            
            <div style={{height:"40px", display:"flex", alignItems:"center"}}><img src={element.sign_img} alt="preview" style={{width:"40px"}}/></div>
            </td></tr>
            <tr></tr>
            </table>)
                    }else if(element.status === 5 && element.next === 1) {

                      return (<table>
            <tr><td rowSpan={3} className="type">{element.type}</td><td className="job">{element.job_name}</td></tr>
            <tr><td className="name"><div className="name">{element.name}</div>
            
            <div style={{height:"40px", display:"flex", alignItems:"center"}}>후결</div>
            </td></tr>
            <tr></tr>
            </table>)
                    }
                    else if(element.status === 0) {
                      return (
                      <table>
            <tr><td rowSpan={3} className="type">{element.type}</td><td className="job">{element.job_name}</td></tr>
            <tr><td className="name"><div className="name">{element.name}</div>
            
            <div style={{height:"40px", display:"flex", alignItems:"center"}}>대기중</div>
            </td></tr>
            <tr></tr>
            </table>)
                   }else {
                      return (
                        <table>
            <tr><td className="job">{element.job_name}</td></tr>
            <tr><td className="name"><div className="name">{element.name}</div><img src={element.sign_link} alt="preview" style={{width:"40px"}}/></td></tr>
            <tr><p style={{fontSize:"10px", padding:"2px 5px"}}>{element.approved_date}</p></tr>
            </table>
                      )
                    }
                  })()
                ))}
            </div>
          )}
          </div>
          <div style={{display:"flex", width:"100%", justifyContent:"space-between", flexDirection:"column"}} className="form_wrap">
          

         
          <table className="form_write" style={{margin:"20px 0", width:"100%"}}>

      {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
      <tr>
<td class="table_title2" nowrap="">
  제목
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <span type="text" name="subject" className="formInput">{data.subject}</span>
  </div>
  </td>

  <td class="table_title2 pc" nowrap="">
  작성자

</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{data.user_name}</span>
  </div>
  </td>

</tr>      
      
     
<tr className="table_row mobile">
<td class="table_title2" nowrap="">
  작성자

</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{data.user_name}</span>
  </div>
  </td>
</tr>







<tr>
<Tooltip
        id="cc"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>문서참조자는 결재문서와 결재상태를 확인할 수 있습니다.</span>
          </div>
        </Tooltip>

<td class="table_title2" nowrap="">
  문서참조자<BiInfoCircle data-tooltip-id="cc" style={{marginLeft:"5px"}}/>
</td>
<td class="table_list2_left" >
        <div className="input-group">
        <span type="text" name="subject" className="formInput"></span>
        {data.cc !==undefined&&(
          JSON.parse(data.cc).map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>

    </div>
      ))
        )}
      </div>
     
		</td>
</tr>

<tr>
<td class="table_title2" nowrap="">
  문서수신자
</td>
<td class="table_list2_left">
        <div className="input-group">
        <span type="text" name="subject" className="formInput"></span>
        {data.recipient !==undefined&&(
          JSON.parse(data.recipient).map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>

    </div>
      ))
        )}
      </div>
     
		</td>
</tr>

<tr>

<td class="table_title2" nowrap="">
  보관기간
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span type="text" name="subject" className="formInput">{data.expire}년</span>

  </div>
  </td>
  <td class="table_title2 pc" nowrap="">
  보안등급
</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span type="text" name="subject" className="formInput">{data.security}등급</span>

  </div>
  </td>

</tr>


<tr className="table_row mobile">
<td class="table_title2" nowrap="">
  보안등급
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span type="text" name="subject" className="formInput">{data.security}등급</span>

  </div>
  </td>
</tr>




    </table>


    {data.type ==="custom" &&(
<div className="custom_form custom_form_view" style={{height:"600px", overflow:"scroll", position:"relative"}} ref={formRef}>
  {/*<Workbook onOp={console.log} data={[initial]} onChange={(data)=>{console.log("changed, ", data);}} />*/}
 
  {(data.type ==="custom"  && data.custom_name==="order") && (
  <Order data={data.doc_no} line={lineList}/>)}

{(data.type ==="custom"  && data.custom_name==="confirm") && (
  <Confirm data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="request") && (
  <Request data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="repair") && (
  <Repair data={data.doc_no} line={lineList}/>)
}

{(data.type ==="custom"  && data.custom_name==="rent") && (
  <Rent data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="service") && (
  <Service data={data.doc_no} line={lineList}/>)
}

{(data.type ==="custom"  && data.custom_name==="endoscope") && (
  <Endoscope data={data.doc_no} line={lineList}/>)
}

{(data.type ==="custom"  && data.custom_name==="inspect") && (
  <Inspect data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="acquire") && (
  <Acquire data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="hr") && (
  <HR data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="purchase") && (
  <Purchase data={data.doc_no} line={lineList}/>)
}
{(data.type ==="custom"  && data.custom_name==="logi_order") && (
  <LogiOrder data={data.doc_no} line={lineList}/>)
}

</div>
    )}
     {data.type !=="custom" &&(<div className="custom_form custom_form_view" style={{height:"600px", overflow:"hidden", position:"relative"}} ref={formRef}>{divv}</div>)} 


    </div>

   

    </div>


    

  
    </div>
  </div>
  </div></div>

</div>
  );
};

export default BoardDocView;
