
import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from 'moment';


import "../css/setting.css";
const DefaultSetting = ({navigation}) => {
  const [userEmail, setUserEmail] = useState("");
  const [subject, setSubject]=useState("");
  const [boxes, setBoxes]=useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


const dataFetch = useRef(false);
const getInitialState = () => {
  const value = "Orange";
  return value;
};
const [start, setStart] = useState(getInitialState);

  const handleChange = (e) => {
    setSubject(e.target.value);
  };

  const [text, setText]=useState("");
  const handleText = (e) => {
    setText(e.target.value);
  };
  useEffect(() => {
    
    if (dataFetch.current)
    return
    dataFetch.current = true;
    console.log("..,.;??");
    axios.get('http://gw.thegmmedical.com:5003/api/getboxes')
    .then(function (response){
      console.log(response);
      setBoxes(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  
    fetch("https://gw.thegmmedical.com:5003/api/getuser", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log("user...", json[0])
      setUserEmail(json[0].email);
      
     
    })
    .catch(() => {});

    axios.get('http://gw.thegmmedical.com:5003/api/get_vacation')
    .then(function (response){
      console.log(response);
      if (response.data.length>0){
      let vacation = response.data[0];
      setSubject(vacation.vacation_subject);
      setText(vacation.vacation_message);
      setEndDate(moment(vacation.vacation_end).format('YYYY-MM-DD'));
      setStartDate(moment(vacation.vacation_start).format('YYYY-MM-DD'));
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    

  }, []);
 
 
  function submit_vacation(){
  
    const data = {
        account : userEmail,
      subject: subject,
      message : text,
      start : startDate,
      end : endDate
      

    
    }
    console.log("is it working..?", data);
    axios.post('http://gw.thegmmedical.com:5003/api/vacation',data)
    .then(function (response){
      console.log(response);
      alert("부재중 설정이 저장되었습니다.");
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };
  
  return (
    <div className="wrapper">
        <div className="sort-setting">
            <table style={{width:"100%"}}>
            <tr>
                    <thead>부재기간</thead>
                    <td>
                    <div className="input-group setting-input">

              <div class="date_picker">
               
                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />

              </div>
              </div>
              <div className="input-group setting-input">

              <div class="date_picker">
             
                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </div>
              </div>

                    </td>
                </tr>
                <tr>
                    <thead>부재중 응답 제목</thead>
                    <td>
                    <div className="input-group setting-input">
                        <input
          type="text"
          placeHolder=""
          name="todoItem2"
            value={subject} onChange={handleChange}
          className="form-control"
        />
      

      </div>                       
                    </td>
                </tr>

                <tr>
                    <thead>

                    부재중 응답 내용</thead>
                    <td>
                    <textarea cols="50" rows="10" value={text} onChange={handleText}></textarea>
      
                    </td>
                </tr>

        
               
            </table>

          <div className="btns" style={{display:"flex",justifyContent:"center", width:"auto", margin:"20px"}}>

            <button className="button" onClick={()=> submit_vacation()} >저장</button>

          </div>
            
            
      
      </div>

    </div>

  );
};

export default DefaultSetting;
