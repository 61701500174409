
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./css/address.css";

import { HiPencilAlt, HiMail, HiTrash, HiOutlineStar } from "react-icons/hi";

import Pagination from "./Pagination";
import {GiHamburgerMenu} from "react-icons/gi";
import * as XLSX from "xlsx";

import { SiMicrosoftexcel } from "react-icons/si";
import CustomerList from "../components/side/CustomerList";

const Customer = () => {
  const [data, setData] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [isActive, setActive] = useState(false);
  const [chosen, setChosen] = useState("전체");
  const [parent, setParent] = useState("");
  const [name, setName] =useState("");
  const [num, setNum] = useState(0);
  const [lev, setLev] = useState(0);
  const [selected, setSelected]= useState();
  const [search, setSearch] = useState(null);
  const [boardN, setBoardN] = useState("");
  const [boardNum, setBoardNum] = useState();
  const [options, setOption] = useState("name");
  const [filterData, setfilterData] = useState([]);
  const [addrs, setAddrs] = useState([]);
  const [department, setDepartment] = useState([]);
  const [myGroups, setMyGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState("");
  const [CheckList, setCheckList] = useState([]);
  const [notice, setNotice] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [isPage, setIsPage] = useState(false);
  const [isloggedIn, setLoggedIn] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const offset = (page - 1) * limit;
  const location = useLocation();

  const navigate = useNavigate();
  const toggleText = () => {
    setActive(!isActive);
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const {type} = useParams();
  
 
  useEffect(() => {

    console.log("state....", location);
    let type = 0;
    if (location.state !==null && location.state.type !== ''){
      type = location.state.type;
    }
    axios.get("http://gw.thegmmedical.com:5003/api/getcustomers/"+type).then((response) => {
      console.log("getcustomers...", response.data)
      setData(response.data);
      setfilterData(response.data);
      if (response.data.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
        if (response.data.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    });
      
  }, [location]);


  const addBookmark = () => {
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }
    const msg ={
      nos : array
    }
    var result = window.confirm("선택한 연락처를 즐겨찾기에 추가하시겠습니까?");
    if(result){
      
      axios.post('http://gw.thegmmedical.com:5003/api/add_addr_bookmark', msg)
    .then(function (response){
      console.log(response);
      navigate("/address");
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  };
  
  const selectHandle = (e) => {
    console.log("changed", e.target.value);

    setOption(e.target.value);
  };

  const selectGroupHandle = (e) => {
    console.log("changed", e.target.value);

    setGroupOptions(e.target.value);
  };

  const onSearch = (e) => {
    e.preventDefault();
    var filtered;
    if (groupOptions!==""){
      filtered = data.filter((itemList) => {
        console.log(itemList.group_no, ", ", parseInt(groupOptions));
        return itemList.group_no == parseInt(groupOptions);
      });
      if (search!==""){
        filtered = filtered.filter((itemList) => {
          return itemList[options].toUpperCase().includes(search.toUpperCase());
        });
      }
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }else if (groupOptions ===""){
      if (search!==""){
        filtered = data.filter((itemList) => {
          return itemList[options].toUpperCase().includes(search.toUpperCase());
        });
        setfilterData(filtered);
        if (filtered.length === 0) {
          setEmpty(true);
          setIsPage(false);
        } else {
          setEmpty(false);
          if (filtered.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }
      }
    }

  
    
   
    
  };
  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
       setCheckList([...CheckList, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
       setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }
 }

 function selectAll()  {
  const checkboxes 
       = document.getElementsByName('contact');
  const all 
       = document.getElementsByName('all');
  //console.log(all[0].checked)
  checkboxes.forEach((checkbox) => {
    checkbox.checked = all[0].checked;
  })
}



function exportExcel(){
  let newJson = data.map(ele => {
    return {
      '상호명': ele.name,
      '사업자 등록번호': ele.biz_no,
      '거래처 구분': ele.type,
      '대표자명' : ele.rep,
      '업태' : ele.biz_type,
      '종목' : ele.biz_item,
      '사업장 소재지' : ele.addr,
      '본점 소재지' : ele.hq,
      '등록번호' : ele.register_no,
      '등록일' : ele.register_date,
      '개업일' : ele.open_date
  
    }
  });

  const ws = XLSX.utils.json_to_sheet(newJson); 
   
  const wb= XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, "export.xlsx");
}


function delContacts(){
  console.log(CheckList);
  var array = []
  var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

  for (var i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].value!=="selectall"){
    array.push(checkboxes[i].value)
    }
  }
  console.log(array);
  if (array[0].value==="selectall"){
    array.shift();
  }
  const msg ={
    nos : array
  }
  var result = window.confirm("선택한 거래처를 삭제하시겠습니까?");
  if(result){

    axios.post('http://gw.thegmmedical.com:5003/api/delcustomers', msg)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

}
  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <CustomerList/>
        </div>
      )}
   
     <div className="content-wrap">
     
      
      <div className="outer_wrapper addrlist addr" style={{justifyContent:"flex-start"}}>

      <div className="side-menu">
        <CustomerList changeBoard={(value)=>{setfilterData(value);
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);}} changeBoardNum={(value)=>{setBoardNum(value);}}/>
         </div>
        
        <div className="board_wrap" style={{padding:"0 30px"}}>
    
       
        <div className="">
        
        <div className="board_wrapper">
          <form name="form1" id="form1" method="post">
                  <div className="board_container">
                      <div id="content" className="content">
                          
                        <div className="board_top">
                        <div className="btns">

            
<div className="btn" style={{marginRight:"10px", display:"flex", alignItems:"center"}} onClick={()=>addBookmark()}>
  
  <HiOutlineStar style={{color:"#bdd6ff"}}/><span>즐겨찾기 추가</span></div>
<div className="btn" style={{marginRight:"10px", display:"flex", alignItems:"center"}} onClick={()=>delContacts()}>

<HiTrash style={{color:"#bdd6ff"}}/><span>삭제</span>

</div>

<div className="btn" style={{marginRight:"10px", display:"flex", alignItems:"center"}} onClick={()=>exportExcel()}>
  <SiMicrosoftexcel style={{color:"#bdd6ff"}}/><span>엑셀로 내보내기</span>
  </div>






</div>

<div style={{margin:"0 0 20px"}}>{boardN}</div>
                              {/*<label>
                            페이지 당 게시물 수:&nbsp;
                            <select
                            type="number"
                            value={limit}
                            onChange={({ target: { value } }) => setLimit(Number(value))}
                            >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </label>*/}
                              <div className="bt_srch">
                              
                                <div className="bts_slct">
                                  <select
                                    id="u_shcate"
                                    className="select"
                                    value={options}
                                    onChange={selectHandle}
                                  >
                                    <option value="name">상호명</option>
                                    <option value="biz_no">사업자번호</option>
                                    <option value="rep">대표자명</option>
                                    <option value="biz_type">업태</option>
                                    <option value="biz_item">종목</option>
                                    <option value="addr">소재지</option>
                                    <option value="hq">본점소재지</option>

                                  </select>

                                </div>
                                <div className="bt_input" style={{marginLeft:"10px"}}>
                                  <input
                                    type="text"
                                    id="search_content"
                                    name="search_content"
                                    value={search || ''}
                                    placeholder="검색어를 입력하세요"
                                    onChange={onChangeSearch}
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="search_btn"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                              </div>
                          </div>
                         
                          <div className="cx_table">
                         
                          
                  <table class="contacts customer table bd-b">
									<thead class="thead-dark">
									<tr class="tx-center">
										<th class="wd-80 xl-hidden">
      <input type='checkbox'
       name='all' 
       value='selectall'
       onClick={selectAll}/></th>
										<th class="">상호명</th>
                    <th class="wd-120 xl-hidden">사업자번호</th>

										<th class="wd-150 xl-hidden" style={{width:"auto"}}>구분</th>
										<th class="wd-80 xl-hidden">대표자</th>
                    <th class="wd-80 xl-hidden">업태</th>
                    <th class="wd-80 xl-hidden">종목</th>

                    <th class="wd-80 xl-hidden">소재지</th>
                    <th class="wd-80 xl-hidden">본점</th>

									</tr>
									</thead>
									<tbody>
             
                                    {isEmpty && (
                                  <tr style={{ textAlign: "center" }}>
                                    <td colSpan={8} style={{padding:"50px 0"}}>연락처가 없습니다.</td>
                                  </tr>
                                )}
                                {filterData
                                  .slice(offset, offset + limit)
                                  .map(
                                    ({
                                      no,
                                      name,
                                      type,
                                      biz_no,
                                      rep,
                                      register_no,
                                      open_date,
                                      register_date,
                                      addr,
                                      hq,
                                      biz_type,
                                      biz_item
                                    }) => (
                                      ( <tr key={no}>
                                      <td>
                                      <input type='checkbox' 
                                      name='contact' 
                                      value={no}
                                      onChange={(e) => onChangeEach(e, no)} checked={CheckList.includes(no)}/> 

                                      </td>
                                      <td>
                                
                                      <Link to={"/cx_view/"+no}>{name}</Link>
                                        </td>
                                      
                                        <td>
                                        {biz_no}
                                        </td>
                                        <td>
                                        {type}
                                        </td>
                                        <td>
                                        {rep}
                                        </td>
                                        <td>
                                        {biz_type}
                                        </td>
                                        <td>
                                        {biz_item}
                                        </td>
                                        <td>
                                        <span style={{textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden", display:"block",
                                      maxWidth:"90%"}}>{addr}</span>
                                        </td>
                                        <td>
                                        <span style={{textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden", display:"block",
                                      maxWidth:"90%"}}>{hq}</span>
                                        </td>
                                      </tr>)
                                     
                                    )
                                  )}

									</tbody>
								</table>
                            <table>
                              <colgroup>
                                <col className="m_dp_n table_no" />
                                <col className="" />
                                <col className="ta_l" />
                                <col className="m_dp_n table_date" />
                              </colgroup>
                              {/*<thead>
                        <tr>
                            <th className="m_dp_n table_no" scope="col">NO.</th>
                            <th scope="col"></th>
                            <th className="ta_l" scope="col">제목</th>
                            <th className="m_dp_n table_date" scope="col">날짜</th>
                        </tr>
                    </thead>*/}
                              <tbody>
                               
                              </tbody>
                            </table>
                            <div style={{width:"100%",textAlign:"right"}}>
                    {isloggedIn && (
                      <Link to="write"
                        style={{
                          padding: "10px 30px",
                          marginTop: "20px",
                          backgroundColor: "#555",
                          color: "white",
                          textAlign: "center",
                          position:"relative",
                          display:"inline-block",
                          width:"105px",
                          cursor: "pointer",
                        }}
                      >
                        <HiPencilAlt/>글쓰기
                      </Link>
                    )}
                    </div>
                  </div>
                  {data.length>limit && isPage && (
                    <div className="paging">
                      <Pagination
                        postsPerPage={limit}
                        totalPosts={filterData.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>
                      {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
                    </div>
                  )}
                      </div>
                  </div>
          </form>
        </div>
      </div>
            
            
                
                
                
        </div>

                </div>
     </div>
    </div>

  );
};

export default Customer;
