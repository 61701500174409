import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/gi";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import initial from "./data/cell";
import { toPng } from 'html-to-image';
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../components/DraftsList";
import BoardList from "../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { BsPin } from "react-icons/bs";
import ExcelJS from 'exceljs'
import * as XLSX from "xlsx";
import OrderList from "../components/OrderList";
import BoardDocView from "./BoardDocView";
import html2canvas from 'html2canvas';
import LuckyExcel from "luckyexcel";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import "./css/write.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

let luckysheet;
export default function BoardWrite() {
  const { state } = useLocation();

  const editorRef = useRef();
  const elementRef = useRef(null);
  
  const navigate = useNavigate();
  const inputFileRef = useRef();
  //const [div, setDiv]=useState(<div style={{width:"100%", height:"500px"}}><Workbook onOp={console.log} data={[initial]} onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} /></div>) ;
  const [div, setDiv]=useState(<div style={{width:"100%", height:"500px"}}>
  
  <Workbook onOp={console.log} data={[initial]} onContextMenu={e=>{e.preventDefault();}}  onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} />
</div>
) ;

  const [makeBoards, setMakeBoards] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [list, setList] = useState([]);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [imgLink, setImgLink] = useState("");
  const [boardName, setBoardName] = useState(state.boardName);
  const [boardNo, setBoardNo] = useState(state.bg_no);
  const [excelData, setExcelData] = useState();
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [boards, setBoards] = useState([]);
  const [teamBoards, setTeamBoards] = useState([]);

  const [editor, setEditor] = useStateWithCallbackLazy("text");
  const [statusList, setStatusList] = useState([]);
  const [boardStatus, setBoardStatus] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [initialText, setInitialText] = useState("");
  const [jsonData, setJsonData] = useState([]);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);

  const [__html, setHtml] = useState("");

  const [orderDocNo, setOrderDocNo] = useState(49);
  const [cateList, setCateList] = useState([]);
  const canvasRef = useRef();
  const [cnt, setCnt]= useState(0);

  const [boardCate, setBoardCate] = useState("");
  const [excelType, setExcelType] = useState("");
  const [isActive, setActive] = useState(false);
  const handleDragStart = () => setActive(true);
  const handleDragEnd = () => setActive(false);
  
  const togglePopup = () => {
    setShowPopup(!showPopup);
};
function handleContextMenu(e) {
  e.preventDefault(); // prevents the default right-click menu from appearing
}


const luckyCss = {
  margin: '0px',
  padding: '0px',
  width: '100%',
  height: '500px',
  left: '0px',
}
function replaceAll(str, searchStr, replaceStr) {

  return str.split(searchStr).join(replaceStr);
}

const selectBoard = (e) => {
  var index = e.nativeEvent.target.selectedIndex;
  setBoardName(e.nativeEvent.target[index].text);
  setBoardNo(e.target.value);
  setDiv();
  if (luckysheet!==undefined){
    luckysheet.destroy();
  }
  axios.get('https://gw.thegmmedical.com:5003/api/get_board_editor/' + e.target.value)
  .then(function (res){
    if (res.data.length>0){
      
      let content = res.data[0].content;
      let type = res.data[0].editor_type;
      let excel_type = res.data[0].excel_type;
      setExcelType(excel_type);
setEditor(type, () => {
if (type==="excel"){

          
  let content = JSON.parse(res.data[0].content);

  const loadLuckysheet = async () => {
    try {
      // Load Luckysheet dynamically
      const luckysheetModule = await import('luckysheet');
      console.log("luckysheetModule", luckysheetModule);
      luckysheet = luckysheetModule.default;

      luckysheet.create({
        container: "luckysheet",
        title: 'Luckysheet sphinx Demo', // set the name of the table
        data: content,
        showsheetbar : false,
        showinfobar: false
    })
  
      // Set the loaded state
    } catch (error) {
      console.error('Error loading Luckysheet:', error);
    }
  };
  loadLuckysheet();


}else{
if (type==="text"){
editorRef.current?.getInstance().setHTML(content);

setInitialText(content);
}
}
});
    
      
      
      
    
    }else{
      setEditor("text");
      editorRef.current?.getInstance().setHTML("");

      setInitialText("");
    }
    
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });



 

  axios.post('https://gw.thegmmedical.com:5003/api/get_boardadmin',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_no : e.target.value
      },
    })
  .then(function (response){
  
    if (response.data[0].status !==null){
      setStatusList(JSON.parse(response.data[0].status));
    }else{
      setStatusList([]);
    }
    if (response.data[0].category !==null && response.data[0].category !==undefined){
      setCateList(JSON.parse(response.data[0].category));
    }else{
      setCateList([]);
    }
  
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
    


};
const selectHandle = (e) => {
  e.preventDefault();
  setOption(e.target.value);
};
const selectEditor = (e) => {
  e.preventDefault();
  console.log("hihi", e.target.value);

  if (e.target.value==="excel"){

          let sample = [{
            "name": "Sheet1",
            "config": {},
            "index": "1",
            "status": "1",
            "order": "0",
            "luckysheet_select_save": [{
                "row": [0, 0],
                "column": [0, 0],
                "row_focus": 0,
                "column_focus": 0,
                "left": 0,
                "width": 72,
                "top": 0,
                "height": 23,
                "left_move": 0,
                "width_move": 72,
                "top_move": 0,
                "height_move": 23
            }],
            "zoomRatio": 1,
            "showGridLines": "1",
            "defaultColWidth": 72,
            "defaultRowHeight": 23,
            "celldata": [],
            "calcChain": [],
            "jfgird_select_save": [],
            "data": [
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
            ],
            "visibledatarow": [24, 48, 72, 96, 120, 144, 168, 192, 216, 240, 264, 288, 312, 336, 360, 384, 408, 432, 456, 480, 504, 528, 552, 576, 600, 624, 648, 672, 696, 720, 744, 768, 792, 816, 840, 864, 888, 912, 936, 960, 984, 1008, 1032, 1056, 1080, 1104, 1128, 1152, 1176, 1200, 1224, 1248, 1272, 1296, 1320, 1344, 1368, 1392, 1416, 1440, 1464, 1488, 1512, 1536, 1560, 1584, 1608, 1632, 1656, 1680, 1704, 1728, 1752, 1776, 1800, 1824, 1848, 1872, 1896, 1920, 1944, 1968, 1992, 2016],
            "visibledatacolumn": [73, 146, 219, 292, 365, 438, 511, 584, 657, 730, 803, 876, 949, 1022, 1095, 1168, 1241, 1314, 1387, 1460, 1533, 1606, 1679, 1752, 1825, 1898, 1971, 2044, 2117, 2190, 2263, 2336, 2409, 2482, 2555, 2628, 2701, 2774, 2847, 2920, 2993, 3066, 3139, 3212, 3285, 3358, 3431, 3504, 3577, 3650, 3723, 3796, 3869, 3942, 4015, 4088, 4161, 4234, 4307, 4380],
            "ch_width": 4500,
            "rh_height": 2096,
            "luckysheet_selection_range": []
        }];


        setEditor("excel", () => {


          const loadLuckysheet = async () => {
            try {
              // Load Luckysheet dynamically
              const luckysheetModule = await import('luckysheet');
              console.log("luckysheetModule", luckysheetModule);
              luckysheet = luckysheetModule.default;
          
              luckysheet.create({
                container: "luckysheet",
                title: 'Luckysheet sphinx Demo', // set the name of the table
                data: sample,
                showsheetbar : false,
                showinfobar: false
            })
          
              // Set the loaded state
            } catch (error) {
              console.error('Error loading Luckysheet:', error);
            }
          };
          loadLuckysheet();
        

          });
       
        
      
      
   

  }else{
    if (luckysheet!==undefined){
      luckysheet.destroy();

    }
    setEditor(e.target.value);

  }
};
  const toggleShow = () => {
    setShow(!show);
};
  const onTypeChange = e => {
    setType(e.target.value)
  }
  const endDateChange = e => {
    setEndDate(e.target.value)
  }


  
  


  useEffect(() => {
    //var fx = formula.toJavaScript("DAY(TODAY())");
    console.log("!!!!!!!!!!!!!!!!!!!!!", state)

    if (state.bg_no !==0 && state.bg_no !== undefined){

   
      axios.get('https://gw.thegmmedical.com:5003/api/get_board_editor/'+state.bg_no)
      .then(function (response){
        if (response.data.length>0){

          axios.get('https://gw.thegmmedical.com:5003/api/get_board_editor/'+state.bg_no)
          .then(function (res){

            const loadLuckysheet = async () => {
              try {
                // Load Luckysheet dynamically
                const luckysheetModule = await import('luckysheet');
                console.log("luckysheetModule", luckysheetModule);
                luckysheet = luckysheetModule.default;
            
                if (res.data.length>0){
                  console.log("heyheyhey", res.data[0]);
                  
                  let content = res.data[0].content;
                  let type = res.data[0].editor_type;
                  setEditor(type, () => {
                    if (type==="excel"){
                    
                              
                      let content = JSON.parse(res.data[0].content);

                      luckysheet.create({
                          container: "luckysheet",
                          title: 'Luckysheet sphinx Demo', // set the name of the table
                          data: content,
                          showsheetbar : false,
                          showinfobar: false
                      })
                    
                    }else{
                    if (type==="text"){
                    editorRef.current?.getInstance().setHTML(content);
                    
                    setInitialText(content);
                    }
                    }
                    });
                  
              
                  
                }else{
                  editorRef.current?.getInstance().setHTML("");
                  
                    setInitialText("");
                  setEditor("text");
                }
            
                // Set the loaded state
              } catch (error) {
                console.error('Error loading Luckysheet:', error);
              }
            };
            loadLuckysheet();
          
            
            
          })
          .catch(function (error){
            console.log(error);
          })
          .then(function (){
            //always executed
          });


        }
        else{
          editorRef.current?.getInstance().setHTML("");
          
            setInitialText("");
          setEditor("text");
        }
        
        
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });


    

    }      
  


    fetch("https://gw.thegmmedical.com:5003/api/getboardlist_write", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
        console.log("hihih", state);
        setBoards(json);
        console.log("state....", state);

        axios.get('https://gw.thegmmedical.com:5003/api/get_teamboard')
        .then(function (response){
          console.log(
            "team boards........", response.data
          );
         setTeamBoards(response.data);
  
  
       
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
         
  
  
  
  
  
        if (state.bg_no ===0 && state.bg_no === undefined){
          
          setBoardNo(json[0].bg_no);
          setBoardName(json[0].bg_name);
        }else if(state.bg_no !==0 && state.bg_no !=="" && state.bg_no !== undefined){
          setBoardNo(state.bg_no);
        }
    })
    .catch(() => {});
    if (state !==null){
    axios.post('https://gw.thegmmedical.com:5003/api/get_boardadmin',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_no : state.bg_no
      },
    })
  .then(function (response){
  
    if (response.data.length>0){
      if (response.data[0].status !==null && response.data[0].status!=="[]"&& response.data[0].status!==undefined){
        setStatusList(JSON.parse(response.data[0].status));
      }else{
        setStatusList([]);
      }
      if (response.data[0].category !==null && response.data[0].category !==undefined){
        setCateList(JSON.parse(response.data[0].category));
      }else{
        setCateList([]);
      }
    }
   
  
  
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });




  }


}, []);



var shData=[];
function nextChar(c) {
  var u = c.toUpperCase();
  if (same(u,'Z')){
      var txt = '';
      var i = u.length;
      while (i--) {
          txt += 'A';
      }
      return (txt+'A');
  } else {
      var p = "";
      var q = "";
      if(u.length > 1){
          p = u.substring(0, u.length - 1);
          q = String.fromCharCode(p.slice(-1).charCodeAt(0));
      }
      var l = u.slice(-1).charCodeAt(0);
      var z = nextLetter(l);
      if(z==='A'){
          return p.slice(0,-1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
      } else {
          return p + z;
      }
  }
}


function nextLetter(l){
  if(l<90){
      return String.fromCharCode(l + 1);
  }
  else{
      return 'A';
  }
}


function same(str,char){
  var i = str.length;
  while (i--) {
      if (str[i]!==char){
          return false;
      }
  }
  return true;
}


const itscell = {
  name: "Sheet1",
  config: {
    
   
    
    columnlen: {
      "0": 131,
      "2": 131,
      "3": 131,
      "4": 131,
      "5": 131,
      "6": 131,
      "7": 131,
      "8": 131,
   
    },
    
 
  },
  id: "0",
  zoomRatio: 1,
  order: "0",
  row: 20,
  status: 1,
  celldata:[],
  luckysheet_select_save: [
    {
      left: 741,
      top: 796,
      height: 19,
      left_move: 741,
      width_move: 138,
      top_move: 796,
      height_move: 19,
      row: [33, 33],
      column: [6, 6],
      row_focus: 33,
      column_focus: 6
    }
  ],
  /*calcChain: [
    {
      r: 0,
      c: 3,
      id: "0",
      func: [true, 3, "=Formula!A1+Formula!B1"],
      color: "w",
      parent: null,
      chidren: {},
      times: 0
    }
  ],*/
  scrollLeft: 0,
  scrollTop: 0
};



async function handleFileAsync(e) {
  setDiv("");
  
  const wb = new ExcelJS.Workbook()
        
        const file = e.target.files[0];
  const exceldata = await file.arrayBuffer();
        wb.xlsx.load(exceldata)
        .then(wb => {
           //console.log(workbook, 'workbook instance');

            wb.eachSheet((sheet, id) => {
               sheet.eachRow((row, rowIndex) => {
               //console.log(row);
               });
            });
            const ws = wb.worksheets[0] // 첫 번째 sheet 선택
    
  const options = { includeEmpty: true }
 
   ws.eachRow(options, (row, rowNum) => {
    shData[rowNum-1] = []
    row.eachCell(options, (cell, cellNum) => {
      shData[rowNum-1][cellNum-1] = { value:cell.value, style:cell.style }
    
    
    })
  })
  //console.log("sheeeeeeeeet", sheetData);
  //setShData(sheetData);
  /* data is an ArrayBuffer */
  const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});

  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
    setHtml(htmlData);
    //console.log(htmlData);

  const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
    header:1,
    defval:"",
  })
  setJsonData(jsondata);
  
  

  var sheetnames = workbook.SheetNames;
  
  

  
  
  
  
  
  
  
  setCols(jsondata[0]);
  setRows(jsondata[1]);
  var cellData=[];





var merges= workbook.Sheets[sheetnames[0]]["!merges"];

console.log("jasondata...", workbook.Sheets[sheetnames[0]]);

const merge_config={};

if (merges!==undefined){
  for (let i=0;i<merges.length;i++){
    var title = merges[i]['s']['r']+"_"+merges[i]['s']['c'];
    var rowsize = merges[i]['e']['r']-merges[i]['s']['r'];
    var col = merges[i]['e']['c']-merges[i]['s']['c'];
    var merge={
        r: merges[i]['s']['r'],
        c: merges[i]['s']['c'],
        rs: rowsize+1,
        cs: col+1
    }
    merge_config[title]=merge;
  
  }

}




var rows= workbook.Sheets[sheetnames[0]]["!rows"];
const rowht={};

if (rows!==undefined){

for (let i=0;i<rows.length;i++){
  if (rows[i]!==undefined){
    var row=rows[i]["hpx"];
  rowht[i]=row;
  }

  /**/

}
}
var cols= workbook.Sheets[sheetnames[0]]["!cols"];
const colht={};
if (cols!==undefined){
for (let i=0;i<jsondata[0].length;i++){
  if (cols[i]!==undefined){
    var coll=cols[i]["wpx"];
    colht[i]=coll;
  }

  /**/

}
}
itscell.config.columnlen = colht;

itscell.config.rowlen = rowht;
itscell.config.merge = merge_config;


var calccchain=[];
var borderinfo=[];
for (let i = 0;i<jsondata.length;i++){
  var alphabet = "A";    
  for (let j=0;j<jsondata[i].length;j++){
    var styleData; var ht=1; var vt=1; var fs = 11;
    var fa = "General";


    if (shData[i][j]!==undefined){
     
      if (i===4 && j===1){
        console.log(i, ":", j, "   ", shData[i][j]);


      }


      styleData = shData[i][j].style;
     
      if (styleData.font!==undefined){
       // console.log("styleData", styleData.font.color);


      }
     
      if (styleData.numFmt !==undefined){
        fa = styleData.numFmt;
      }
      if (styleData.alignment!==undefined){


        switch (styleData.alignment.horizontal) {
          case "center":
            ht = 0;
            break;
          case "left":
            ht = 1;
            break;
          case "right":
            ht = 2;
            break;
   
          default:
            ht = 1;
 
        }
        switch (styleData.alignment.vertical) {
          case "middle":
            vt = 0;
            break;
          case "up":
            vt = 1;
            break;
          case "down":
            vt = 2;
            break;
   
          default:
            vt = 1;
 
        }
      }
   
      if (styleData.style!==undefined){
        fs = styleData.font.size;
      }
     
      var border = styleData.border;
     
      var font = styleData.font;


     
      var bd = {
        rangeType: "cell",
        value: {
          row_index:i,
          col_index:j,
         
        }
      };


      if (border!==undefined){
        if (border.left!==undefined){
          var left = {
            //style: styleData.border.left.style,
            //color: "#000"
            style: 1,
            color: "rgb(0, 0, 0)"
          }
          bd.value.l = left;
        }
        if (border.right!==undefined){
          var right = {
            style: 1,
            color: "rgb(0, 0, 0)"
          }
          bd.value.r = right;
        }
        if (border.top!==undefined){
          var top = {
            style: 1,
            color: "rgb(0, 0, 0)"
          }
          bd.value.t = top;
        }
        if (border.bottom!==undefined){
          var bottom = {
            style: 1,
            color: "rgb(0, 0, 0)"
          }
          bd.value.b = bottom;
        }
      }
     
      if (border!==undefined && Object.keys(border).length !== 0){
        borderinfo.push(bd);
      }
     
     
     
     
    }
   
   
    var bgColor = "#ffffff";
   
    var func = null;
    var mcval = null;
    var num = i+1;
    var tmp = alphabet + num.toString();
    var w="";
    var v="";


    if (workbook.Sheets[sheetnames[0]][tmp]!==undefined){
      var cellst = workbook.Sheets[sheetnames[0]][tmp]["s"]["patternType"];
      w = workbook.Sheets[sheetnames[0]][tmp]["w"];
      v= workbook.Sheets[sheetnames[0]][tmp]["v"];
      if (cellst !== "none"){
     
        var fgcolor = workbook.Sheets[sheetnames[0]][tmp]["s"]["fgColor"];


        if (fgcolor.theme!==undefined){
         
          if (fgcolor.theme==0 && fgcolor.tint!==undefined){
            bgColor = "rgb(0,0,0,"+Math.abs((fgcolor.tint))+")";
            console.log("bgColor..........", bgColor, fgcolor.tint, parseInt(fgcolor.tint));
          }
        }
        if (fgcolor.rgb!==undefined){
          console.log(fgcolor.rgb, styleData, workbook.Sheets[sheetnames[0]][tmp]["s"]);


          bgColor = "#"+fgcolor.rgb;


        }
      }
      if (workbook.Sheets[sheetnames[0]][tmp]["f"]!==undefined){
        func=workbook.Sheets[sheetnames[0]][tmp]["f"];
        func = "="+func;
        var fff = {
          r: i,
          c: j,
          id: "0",
          func:[true, i, func],
          color:"w",
          parent:null,
          children:{},
          times:0
         
        };
        calccchain.push(fff);
      }
    }
    var tt = (i+"_"+j);
    if (merge_config[tt]!==undefined){
      mcval = merge_config[tt];
    }
   
    let bl=0;
    let fc = "rgb(51,51,51)";
    if (font !==undefined){


      if (font.size!==undefined){
        fs = font.size;
      }
      if (font.bold !==undefined){
        bl=1;
      }
      if (font.color!==undefined){


        if (font.color.theme=== 0){
          fc="#ffffff";
        }
        if (font.color.argb !==undefined){


          fc = "#"+ font.color.argb.substring(2,font.color.argb.length);
          //console.log("i:",i," j:", j, "   ", font.color.argb, fc);
        }
      }
     
    }
    //console.log("row: ", i, " col: ", j , jsondata[i][j]);
    var rowss = {
      r: i,
      c: j,
      v: {
        ct: { fa: fa, t: "n" },
        v : v,
        bg: bgColor,
        //bg : 'rgb(0,0,0,0.24)',
        m: w,
        bl:bl,
        it:0,
        ff:0,
        fs:fs,
        fc:fc,
        ht:ht,
        vt:vt,
      }
    };
    if (func !==null){
      rowss.v.f = "="+workbook.Sheets[sheetnames[0]][tmp]["f"];
    }
   
    if (mcval!==null){
      rowss.v.mc = mcval;
    }
    cellData.push(rowss);
    alphabet = nextChar(alphabet);

  }
 
}


  itscell.config.borderInfo =borderinfo; 
  itscell.calcChain =calccchain; 
  itscell.celldata =cellData; 
 
  console.log("result -> ", itscell);
  setDiv(<div style={{width:"100%", height:"500px"}}><Workbook onOp={console.log} data={[itscell]} onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} /></div>) ;
  //forceUpdate();


});
  
  
  
 
}

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const subjectChange = (event) => {
    setSubject(event.target.value);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };





 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {
    console.log("boardNo..", boardNo);
    if (boardNo === undefined || boardNo === 0){
      alert("게시판을 선택해주세요");
      return false;
    }
    if (editor === "excel"){
      if (subject === "") {
        alert("제목을 입력해주세요.");
        return false;
      } 
      
   

      var arrayLength = fileArray.length;
      var extractStr = editorRef.current?.getInstance().getMarkdown();
      for (var i = 0; i < arrayLength; i++) {
        let result = extractStr.match(fileArray[i]);
        console.log("result: " + result);
        if (result == null) {
          var filename = fileArray[i].substring(
            fileArray[i].lastIndexOf("/") + 1
          );
  
          axios
            .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
              data: {
                // 서버에서 req.body.{} 로 확인할 수 있다.
                file_name: filename,
                board_name: boardName,
              },
            })
            .then(() => this.setState({ status: "Delete successful" }));
        }
        //Do something
      }
  
      const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      const formData = new FormData();
     
      for (let i = 0; i<fileList.length;i++){
        console.log(fileList[i].name);
        formData.append(`file[${i}]`, fileList[i]);
        formData.append(`filename[${i}]`, fileList[i].name);
      }
      for (let i = 0; i<largeFiles.length;i++){
        formData.append(`lfile[${i}]`, largeFiles[i]);
        formData.append(`lfilename[${i}]`, largeFiles[i].name);
      }
      let sheet = luckysheet.getAllSheets();
      console.log("sheet", sheet);
     

      formData.append(`content`, JSON.stringify(sheet));
      formData.append(`content_type`, "excel");
      formData.append(`date_time`, date_time);
      formData.append(`subject`, subject);
      formData.append(`file`, mailFile);
      formData.append(`is_notice`, type);
      formData.append(`name`, "변수연");
      formData.append(`bg_no`, boardNo);
      formData.append(`end_date`, endDate);
      formData.append(`category`, boardCate);
      formData.append(`status`, boardStatus);

      const fetch = async () => {
        await axios
          .post("https://gw.thegmmedical.com:5003/api/boardinsert", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            console.log("what is this");
            window.location.href="https://gw.thegmmedical.com/board";
            //console.log("...." + data);
          });
      };
  
      const noticefetch = async () => {
        await axios
          .post("https://gw.thegmmedical.com:5003/api/noticeinsert", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            console.log("what is this");
            window.location.href="https://gw.thegmmedical.com/board";
            //console.log("...." + data);
          });
      };
      if (makeBoards === true){




        axios
        .post("https://gw.thegmmedical.com:5003/api/makeboards", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/board";
          //console.log("...." + data);
        });
      }else{
        if (type === "2"){
          noticefetch();
        }else{
          fetch();
        }
      }




  
      //saveExcel();
    }else{
    /*const formLargeData = new FormData();
   
    for (let i = 0; i<largeFiles.length;i++){
      formLargeData.append(`lfile[${i}]`, largeFiles[i]);
      formLargeData.append(`lfilename[${i}]`, largeFiles[i].name);
    }*/
    //alert(formLargeData);
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    formData.append(`is_notice`, type);
    formData.append(`content_type`, "html");

    formData.append(`name`, "변수연");
    formData.append(`bg_no`, boardNo);
    formData.append(`end_date`, endDate);
    formData.append(`category`, option);
    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/boardinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/board";
          //console.log("...." + data);
        });
    };

    const noticefetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/noticeinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/board";
          //console.log("...." + data);
        });
    };
    if (makeBoards === true){
      axios
      .post("https://gw.thegmmedical.com:5003/api/makeboards", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        console.log("what is this");
        window.location.href="https://gw.thegmmedical.com/board";
        //console.log("...." + data);
      });
    }else{
      if (type === "2"){
        noticefetch();
      }else{
        fetch();
      }
    }




   


  }
  };

  const handleDraftButton = () => {
 
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    formData.append(`is_notice`, type);
    formData.append(`name`, "변수연");
    formData.append(`bg_no`, boardNo);
    formData.append(`end_date`, endDate);
    formData.append(`category`, option);
    if (draftNo !==undefined){
      formData.append(`draft_no`, draftNo);
    }
    const fetch = () => {
      axios
        .post("https://gw.thegmmedical.com:5003/api/draftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response){
          setDraftNo(response.data);
          alert("임시저장 되었습니다.");
         
        });
    };
    fetch();



  };
  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const downloadEmployeeData = () => {
    fetch('https://gw.thegmmedical.com:5003/api/download')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = '이호MH호텔_셀렉 A컷.zip';
                    a.click();
                });
                //window.location.href = response.url;
        });
  }
  const response = document.getElementById('response');
  var link_array=[];

  function addDays(days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }
  

  window.addEventListener('message', (event) => {
    if (event.data["msg"] !== undefined && cnt === 0){
        
      console.log(document.getElementsByClassName('large_file'));
      //document.getElementsByClassName('large_file')[0]="";
      //if ()
      var files = event.data["msg"];
      console.log("files....length     ", files.length);
      for (let i= 0;i<files.length;i++){
        var encoded = encodeURIComponent(files[i].filepath);
        var string = "<div className='large_file'>대용량첨부파일 : ";
        string+='<a href = "https://gw.thegmmedical.com:5003/download/'+encoded+'" download rel="" target="">'+files[i].name+'</a>'
        string+=" ~ "+moment(addDays(7)).format('YYYY-MM-DD')+"</div>";
        var content = editorRef.current?.getInstance().getHTML();
      console.log("content............",content);
      content.replaceAll("대용량첨부파일", "");
      
      string+=content;
      editorRef.current?.getInstance().setHTML(string);
      }
      
      
      
      let tmp = cnt;
      tmp++;
      setCnt(tmp);
      event.data["msg"]=[];

    }
 
  });

  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const excelInitial = {
    name: "Sheet1",
    config: {
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
      },
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 38,
    status: 1,
    ch_width: 1361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    
    scrollLeft: 0,
    scrollTop: 0
  };
  
  
    const addBookmarkPage = () => {
      var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
      if(result){
        const data ={
          name: boardNo,
          num : '',
          link : window.location.pathname,
          bookmark : boardName + " 게시글 작성"
        }
        console.log(window.location.pathname)
        console.log(data);
        axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
        .then(function (response){
          console.log(response);
          alert("메뉴 바로가기에 추가되었습니다.");
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };

    function writeOrder(value){
      console.log("write order..........", value);

      let order_list = value.order_list;
      let str="";
      for (let i=0;i<order_list.length;i++){
        str+="<tr> <td> "+order_list[i].product_name+"</td>"+
        "<td>"+order_list[i].standard+"</td>"+
        "<td>"+order_list[i].quantity+"</td>"+
        "<td>"+order_list[i].destination+"</td> </tr>";
      }

      var text = "발주 일자 : " + value.order.order_date + "<br/>발주 제목: " + value.order.subject + 
      "<br/>납품처: " +
      "<br/>장비명: " +
      "<br/><table>"+str+
      "</table>" +
      "<br/>납품일: " +  
      "<br/>배송지: " + value.order.place +
      "<br/>담당자: " + value.order.pic_name + " " +value.order.job_name + " (" +value.order.pic_tel + ")" +
      "<br/>비고: ";
      setOrderDocNo(value.order.doc_no);

      /*console.log("value.order.doc_no...", value.order.doc_no)
      let html = ReactDOMServer.renderToString(<BoardDocView doc_no = "49" ></BoardDocView>)
      var iframe = document.createElement('iframe');
      document.body.appendChild(iframe);
      var iframedoc = iframe.contentDocument||iframe.contentWindow.document;
      iframedoc.body.innerHTML=html;

      console.log(iframedoc.body);
      html2canvas(iframedoc.body).then((canvas) => {
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = canvas.toDataURL('image/png');
        link.download = 'result.png';
        link.click();
        document.body.removeChild(link);
      });*/

        toPng(elementRef.current, { cacheBust: false })
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = "my-image-name.png";
            link.href = dataUrl;
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
     
      setInitialText(text);
      editorRef.current?.getInstance().setHTML(text);
      togglePopup();



    }



    const [uploadedInfo, setUploadedInfo] = useState(null);
  
// ...생략

const setFileInfo = (file) => {
  const { name, type } = file;
  const isImage = type.includes('image');
  const size = (file.size / (1024 * 1024)).toFixed(2) + 'mb';

  if (!isImage) {
    setUploadedInfo({ name, size, type });
    return;
  }
  const reader = new FileReader();
  reader.onload = () => {
    setUploadedInfo({ name, size, type, imageUrl: String(reader.result) });
  };
  reader.readAsDataURL(file);
};

const handleDrop = (event) => {
  event.preventDefault();
  setActive(false);

  const files = event.dataTransfer.files;
  var oldFiles = fileList;
    console.log("event.....", files);
    console.log("bef oldfiels..." , oldFiles);


    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    const promises = [];
    var zips = [];

    for(let i = 0; i < files.length; i++){
      file__size+=files[i].size;
      
      if ((fileSize+file__size)>20971520  , file__size > 20971520){
        //LargeFs.push(event.target.files[i]);
        if (i ===files.length-1){
          alert("20,971,520 Bytes 이상의 파일은 첨부하실 수 없습니다. 본문에 대용량 첨부 기능을 사용하시기 바랍니다.");
        }
       
       
      }else{
        
        
        
         
            
            fs+=files[i].size;
            console.log("new file...", files[i]);
            oldFiles.push(files[i]);
            
         
        
        
      }
    }
  
    var removed  = oldFiles;

    
    
      // do something with data
      setFileSize(fileSize+fs);
      var newArray = fileList.concat(newFiles);
      setLargeFiles(LargeFs);
      console.log("after oldfiels..." , oldFiles);
      setFileList(oldFiles);
    
   
   // 코드 추가
};

const handleUpload = ({ target }) => {
  const file = target.files[0];
  console.log("file...", file);
  setFileInfo(file);  // 코드 추가
};

const handleDragOver = (event) => {
  event.preventDefault();  // 필수 1
};




  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
     <div className="content-wrap">
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
      <div className="side-menu">
      <BoardList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>
        </div>



      <div className="main_content boardwrite">


      {showPopup ? (
  <OrderList closePopup={() => togglePopup()} onChange={(value)=>writeOrder(value)} />
) : null}

      {show ? (
    <DraftsList onChange={(value) => {setSubject(value.subject);editorRef.current?.getInstance().setHTML(value.content);setDraftNo(value.draft_no)}} closePopup={() => toggleShow()}/>
    ) : null}
      
      <div className="btns">
      <div className="btn"  style={{width:"15px", padding:"4px 3px", display:"flex", alignItems:"center", marginRight:"7px"}} onClick={()=>addBookmarkPage()}><BsPin/></div>
      <h3>게시글 작성</h3>

      <select
        style={{border:"1px solid #ddd", marginLeft:"5px", padding:"2px 5px"}}
        className="select"
        value={boardNo}
        onChange={selectBoard}
      >
        <option value="">게시판 선택</option>
        {boards.map((element, index)=>
        <option value={element.bg_no}>{element.bg_name}</option>
        )}
        <option value="-">----------------</option>
        {teamBoards.map((element, index)=>
        <option value={element.bg_no}>{element.bg_name}</option>
        )}
      </select>

      </div>
       


      



    
      <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"100%", margin:"0 auto"}}>
              <div className="left">

              
              <button className="btn" onClick={handleRegisterButton} style={{marginRight:"5px"}}>등록</button>

              <button className="btn" onClick={handleDraftButton} style={{marginRight:"5px"}}>임시저장</button>

              <button className="btn" onClick={() => togglePopup()}>발주서 불러오기</button>
              </div>

              <div className="right">
                <button onClick={() => {setShow(!show);}}>임시저장글</button> 

            </div>
            </div>
      <table className="write_table" style={{width:"100%"}}>

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  <tr>
  <td class="table_title2" nowrap="">
    제목
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="text" name="name" className="formInput" placeholder="제목을 입력하세요." style={{marginLeft:"10px"}} onChange={subjectChange} value={subject}></input>
    </div>
    </td>
  </tr>

  {statusList.length>0 &&(
  <tr>
  <td class="table_title2" nowrap="">
    문서상태
  </td>
  <td class="table_list2_left">
    <div className="selectNotice">

    <select name="name" className="formInput" style={{marginLeft:"10px"}} value={boardStatus}
                                    onChange={(e)=>setBoardStatus(e.currentTarget.value)}>
    {statusList.map((element,index)=><option value={element}>{element}</option>)}  
    </select> 
    </div>
  </td>
    
  </tr>
)}
  

  {cateList.length>0 &&(
  <tr>
  <td class="table_title2" nowrap="">
    문서분류
  </td>
  <td class="table_list2_left">
    <div className="selectNotice">

    <select name="name" className="formInput" style={{marginLeft:"10px"}} value={boardCate}
                                    onChange={(e)=>setBoardCate(e.currentTarget.value)}>
    {cateList.map((element,index)=><option value={element}>{element}</option>)}  
    </select> 
    </div>
  </td>
    
  </tr>
)}
  

  <tr>
  <Tooltip
        id="date_guide"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>설정하지 않을 시 무제한 보관됩니다.</span>
          </div>
        </Tooltip>
  <td class="table_title2" nowrap="">
    게시 유효일<BiInfoCircle data-tooltip-id="date_guide" style={{marginLeft:"5px"}}/>
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="date" name="name" className="formInput" placeholder="제목을 입력하세요." style={{marginLeft:"10px"}} onChange={endDateChange} value={endDate}></input> 
    </div>
    </td>
  </tr>
  <tr>
  <td class="table_title2" nowrap="">
    첨부파일
  </td>
    <td class="table_list2_left">
    <div className="input-group"
    onDragEnter={handleDragStart}  // dragstart 핸들러 추가
    onDragLeave={handleDragEnd}  // dragend 핸들러 추가
    onDragOver={handleDragOver}
    onDrop={handleDrop}>
    <input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler} multiple/>
    <button style={{cursor:'pointer'}} className="large_file_btn" onClick={()=>onBtnClick()}>
      파일 첨부
    </button>
    <button id='drftrgvlnbpewmcswmcs' style={{cursor:'pointer'}} className="large_file_btn" onClick={() => popupwindow("/large_file", "large file upload", 800, 400)} alt=''>
      대용량 파일 링크 첨부하기
    </button>



    <label>
  <input type="checkbox" value="1" name="weekN" checked={makeBoards === true} onChange={()=>{setMakeBoards(!makeBoards)}} style={{marginBottom:"0", width:"unset"}}/>
  게시글로 업로드
</label>
<BiInfoCircle data-tooltip-id="make_board" style={{marginLeft:"5px"}}/>


<Tooltip
  id="make_board"
  place="bottom"
>
  <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
    <span>체크 시 첨부된 파일 별로 게시글이 자동 생성됩니다.
      <br></br>예를 들어 a.xlsx, b.xlsx 이라는 파일들을 첨부하면
      <br></br>a.xlsx을 제목으로 a.xlsx가 첨부된 게시글이 하나, b.xlsx을 제목으로 b.xlsx가 첨부된 게시글이 하나씩 생성됩니다.
    </span>
  </div>
</Tooltip>




    </div>
   
    </td>
  </tr>
  <tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    
  </td>
    <td class="upload_file_list">
  {fileList.map((file,index) => 
    <div className="file_item">
      <p>{file.name} {formatBytes(file.size)}</p>
      <button type="button" className="remove" onClick={() => {removeFile(index);}}>x</button>
    </div>
    
  )} 

{largeFiles.map((file,index) => 
    <div>
      <p>{file.name} {formatBytes(file.size)} <span>다운로드 가능 기간</span></p>
      
      <button type="button" onClick={() => {removeFile(index);}}>Remove</button>
    </div>
    
  )} 
    </td>
  </tr>


  <tr>
  <td class="table_title2" nowrap="">
    공지사항
  </td>
  <td class="table_list2_left">
    <div className="selectNotice">

    
  <label htmlFor="0">
      <input
        type="radio"
        name="0"
        value="0"
        checked={type === "0"}
        onChange={onTypeChange}
      />
      설정안함
    </label>
    <label htmlFor="1">
      <input
        type="radio"
        name="1"
        value="1"
        checked={type === "1"}
        onChange={onTypeChange}
      />
      현재 게시판에 공지
    </label>
    {/*<label htmlFor="2">
      <input
        type="radio"
        name="2"
        value="2"
        checked={type === "2"}
        onChange={onTypeChange}
      />
      전체 게시판에 공지
</label>*/}
    </div>
  </td>
    
  </tr>
{list.length>0&&(
  <tr>

  <td class="table_title2" nowrap=""  ref={elementRef} >
    문서 분류
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <select name="name" className="formInput" style={{marginLeft:"10px"}} value={option || ''}
                                    onChange={selectHandle}>
    {list.map((element,index)=><option value={element.name}>{element.name}</option>)}  
    </select> 
    </div>
    </td>
  </tr>
)}
  
 
      </table>
     <div id="response">{links}</div>
 <div className="" style={{ width: "100%", margin: "20px auto", height:"500px", border:"1px solid #ddd"}}>
    {editor === "text" && (
      <Editor
        ref={editorRef} // DOM 선택용 useRef
        initialValue={initialText}
        previewStyle="vertical" // 미리보기 스타일 지정
        height="500px" // 에디터 창 높이
        initialEditType="wysiwyg" //
        toolbarItems={[
          // 툴바 옵션 설정
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock']
        ]}
        plugins={[colorSyntax]} 
        useCommandShortcut={false} // 키보드 입력 컨트롤 방지
        hooks={{
          addImageBlobHook: async (blob, callback) => {
            console.log(blob.size);
            if (blob.size > 5000000) {
              alert(
                "업로드에 실패했습니다. 이미지당 5MB까지 업로드할 수 있습니다."
              );
              return;
            }
            const formData = new FormData();
                    formData.append("img", blob);
                    
                    // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
                    // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...
                    await axios.post(
                      "https://gw.thegmmedical.com:5003/api/img",
                      formData
                    ).then(( res ) => {
                      console.log(res);
                      console.log(
                        "성공 시, 백엔드가 보내주는 데이터",
                        res.data.url
                      );
  
                      const IMG_URL = res.data.url;
                      setImgLink(IMG_URL);
                      // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
                      callback(IMG_URL, "test");
                      //alert(IMG_URL);
                      files.push(IMG_URL);
                      setArray(files);
                      //setArray((fileArray) => [...fileArray, IMG_URL]);
                      //alert("fileArray: ", files);
                      console.log(files);
                      var arrayLength = files.length;
                      //alert(arrayLength);
                      //alert("end");
                    
                    });
            //callback('https://gw.thegmmedical.com:5003/img/카레유.png', '카레유');
          }
        }}
      ></Editor>
    )}
    {editor === "excel" && (

<div style={{width:"100%", height:"100%"}}>
<input type={"file"} onChange={(event) => {
const files = event.target.files
LuckyExcel.transformExcelToLucky(files[0], function(exportJson, luckysheetfile){
  let sh = exportJson.sheets[0].config.borderInfo;


  for (let i=1;i<sh.length;i++){
    let r = sh[i].value.row_index;
    let c = sh[i].value.col_index;


    let prevR = sh[i-1].value.row_index;
    let prevC = sh[i-1].value.col_index;


    if (r === prevR){
      if (sh[i].value.t!==undefined && sh[i-1].value.t === undefined){
        delete sh[i].value.t;
      }else if (sh[i].value.t!==undefined && sh[i-1].value.t === undefined){
        delete sh[i-1].value.t;


      }
    }
  }


  exportJson.sheets[0].config.borderInfo = sh;





  if(exportJson.sheets==null || exportJson.sheets.length===0){
      alert("Failed to read the content of the excel file, currently does not support xls files!");
      return;
  }
  luckysheet.destroy();

  luckysheet.create({
      container: 'luckysheet', //luckysheet is the container id
      showinfobar:false,
      data:exportJson.sheets,
      showsheetbar : false,
      title:exportJson.info.name,
      userInfo:exportJson.info.name.creator
  });
});
}}/>
<div
id="luckysheet"
style={luckyCss}
/>

</div>
)}
    </div>
      <div style={{width:"100%", margin:"10px auto"}}>
        <select value={editor} onChange={selectEditor}>
        <option value="text">Toast 에디터</option>
        <option value="excel">엑셀 에디터</option>
        </select>
      </div>
      
      <div className="button_wrap">
      <button className="submit" onClick={handleRegisterButton}>등록</button>
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>

    
    </div>
  );
}
