import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";

import axios from "axios";
import {
  format

  
} from "date-fns";
import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  hospital: "",
  product: "",
  model:"",
  mfr_num: "",
  note: "",
  content:"",
};




const Acquire = ({data, line}) => {  
  

  const [links, setLinks]=useState([]);
  const [select, setSelect] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

  
 
 

  useEffect(() => {

    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/acquire/'+data)
    .then(function (response){
      if (response.data[0]!==undefined){
      console.log("order.........",response.data[0]);
      setValues(response.data[0]);
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }, []);


  

 


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

            <div className="order_write forms inspect" style={{padding:"50px", boxSizing:"border-box",margin:"20px auto"}}>
            <div style={{display:"flex"}} className="line">

          
</div>
      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>인수확인서</td>
                
                  </tr>
              
                </table>
                <hr style={{borderWidth:"5px"}}/>
          <table border="1" className="supply" style={{width:"100%"}}>
          <tbody>
          <tr>
          <td>등록번호</td>
          <td colSpan={3}></td>
          </tr>
          <tr>
          <td>상호명</td>
          <td>(주)GM메디칼</td>
          <td>대표자</td>
          <td>임명한</td>
          </tr>
          <tr>
          <td>소재지</td>
          <td>서울 서초구 서초대로 46길 19-7, 201호</td>
          <td>전화번호</td>
          <td>02-2055-2255</td>
      
          </tr>
       
          </tbody>
          </table>
        
          <table className="first_table" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', width:"100%", marginTop:"50px", borderCollapse: 'collapse'}}>{/* User */} 
                   
                   <tbody>
                     <tr>
                       <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                        병 원 명
                       </th>
                       <td style={{ border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                         {values.hospital}
                       </td>

                    
                     </tr>
                     <tr>
                       <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                         품 목 명
                       </th>
                       <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                       {values.product}
                       </td>
                     
                       
                     </tr>
                   
                     <tr>
                       <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                         모 델 명
                       </th>
                       <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                       {values.model}
                       </td>

                     </tr>

                     <tr>
                       <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                         제조번호 
                       </th>
                       <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}
                       colSpan={3}>
                        {values.mfr_num}

                       </td>
                      
                     </tr>
                     <tr>
                       <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                         비고 
                       </th>
                       <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}
                       colSpan={3}>
                        {values.note}

                       </td>
                      
                     </tr>

                     <tr>
                       <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                         내용 
                       </th>
                       <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}
                       colSpan={3}>
{values.content} 
                       </td>
                      
                     </tr>
                   
                   </tbody>
                 </table>

           
            
          </span>
      
          
          </div>
          <div style={{textAlign:"center", marginTop:"50px"}}>
            <p style={{textAlign:"center", marginTop:"50px"}}>상기 장비를 인수하였음을 확인함.</p>
            <p style={{textAlign:"center", marginTop:"50px"}}>{format(new Date(),"yyyy.MM.dd")}</p>
            <h2 style={{textAlign:"center", marginTop:"50px"}}>(주)GM메디칼</h2>
          </div>

        
      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default Acquire;
