
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";
import React, { useState, useEffect, useRef } from "react";
import Popup from "../components/Popup";
import axios from "axios";
import "./css/adminUsers.css";
import Pagination from "./Pagination";
import addDays from "date-fns/addDays";

const AdminPassword = () => {
    const [sidebar, setSidebar] = useState(false);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [showCreate, setCreate] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filterData, setFilterData] = useState([]);

    
  
    const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const offset = (page - 1) * limit;

    const togglePopup = () => {
        setPopup(!showPopup);
        

    };
    const toggleCreate = () => {
      setCreate(!showCreate);
      

  };
    const updateUser = () => {
      console.log("checklist...", checkList);
        for (var i=0;i<data.length;i++){
          if (data[i].email === checkList[0]){
            setSelected(data[i]);
          }
        }
      setPopup(!showPopup);
      
    };
    const deleteLog = () => {
      
      var result = window.confirm("모든 로그인 오류 기록을 삭제하고 차단 해제 하겠습니까?");
      if(result){
        axios.get('https://gw.thegmmedical.com:5003/api/del_pwerror')
        .then(function (response){
          console.log(response);
          window.location.reload(true);
          
          
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const stopUser = () => {
      console.log(checkList);
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 삭제하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/stopUsers', data)
        .then(function (response){
          console.log(response);
          getUser();
     
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const activeUsers = () => {

      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked');
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log("안녕", array);

      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }

      var result = window.confirm("선택된 사용자를 차단 해제합니다");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/unblock_users',data)
        .then(function (response){
          console.log(response);
          //getUser();
          alert("차단이 해제되었습니다.");
          window.location.reload(true);
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    function getUser(){
      fetch("https://gw.thegmmedical.com:5003/api/get_pw_errors/", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("json", json);
        setData(json);
        setFilterData(json);
        
      })
      .catch(() => {});
    
    }
    useEffect(() => {
       
      getUser();
        
     
        
    
      
      }, []);
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('user');
        const all 
             = document.getElementsByName('userall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...checkList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(checkList.filter((checkedId) => checkedId !== id));
        }
        
     }

     const unBlock  = (no) => {
      var result = window.confirm("선택된 사용자를 차단 해제합니다");
      if(result){
        axios.get('https://gw.thegmmedical.com:5003/api/unblock/'+no)
        .then(function (response){
          console.log(response);
          //getUser();
          alert("차단이 해제되었습니다.");
          window.location.reload(true);
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
     }

     const filterDates  = (no) => {
      setFilterData(data.filter(item=> new Date(item.error_date) >= new Date(startDate) && new Date(item.error_date) <=addDays(new Date(endDate),1)));


     };
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents pw" style={{maxWidth:"1600px", background:"rgb(247,247,247)",overflow:"auto"}}>
        <div className="header_txt">
        <h3>비밀번호 오류자 관리</h3>
        </div>
        <div className="contents_top">

            <table>
                <tr>
                   
                    <td> 로그인 시도 차단된 계정 {data.filter(item => item.is_blocked===1).length}개 </td>
                </tr>
            </table>
        </div>

        <div className="main_contents_wrapper">
            <div className="btns" style={{display:"flex", justifyContent:"space-between"}}>
              <div className="left">
          
                <input type="date" value={startDate} onChange={(e)=>setStartDate(e.target.value)}/> <input type="date" value={endDate} onChange={(e)=>setEndDate(e.target.value)}/> 
                
                <button onClick={()=>filterDates()}>검색</button>
                <div className="break"></div>
                <button onClick={() => {setFilterData(data.filter(item=>item.is_blocked===1))}}>차단된 직원만 조회</button> 
              </div>

              <div className="right">
                <button onClick={() => activeUsers()}>차단 해제</button> 

                <button onClick={() => deleteLog()}>모든 사용자 로그 삭제 및 차단해제</button>
            </div>
            </div>

{showCreate ? (
  <Popup text="Title" closePopup={() => {toggleCreate();getUser();}} />
) : null}           

{showPopup ? (
  <Popup member={selected} text="Title" closePopup={() => {togglePopup();getUser();}} />
) : null}

        
<table class="responsive">
  <thead>
    <tr>
    <th className="check">
    <input type='checkbox'
       name='userall' 
       value='selectall'
       onClick={selectAll}/>

    </th>
      <th>재직</th>
      <th>이름</th>
      <th>부서</th>
      <th>직위</th>
      <th>아이디</th>
      <th>비밀번호</th>
      <th>접속일자</th>
      <th>접속장소</th>
      <th>실패횟수</th>
      <th>차단여부</th>

      <th>기능</th>

    </tr>
  </thead>
  <tbody>
  
  { filterData.slice(offset, offset + limit).map((element, index) => 
    <tr key={index}>
        <td>
        <input type='checkbox'
        name='user' 
        value={element.no}
        onChange={(e) => {onChangeEach(e, element.no); }} checked={checkList.includes(element.no)}/> 
        </td>

      
    <td data-label="재직">{element.accountstatus}</td>
    <td data-label="이름">{element.name}</td>
    <td data-label="부서">{element.d_name}</td>
    <td data-label="직위">{element.job_name}</td>
    <td data-label="아이디">{element.user_id}</td>
    <td data-label="비밀번호">{element.user_pw}</td>
    <td data-label="접속일자">{element.error_date}</td>
    <td data-label="접속장소">{element.ip_addr}</td>
    <td data-label="실패횟수">{element.cnt}</td>
    <td data-label="차단여부">{element.is_blocked === 1 && (<span>차단</span>)} {element.is_blocked === 0 && (<span>차단 안됨</span>)} </td>

    <td className="j_name">{element.is_blocked === 1 && (<button onClick={()=>unBlock(element.no)}>차단해제</button>)}</td>

    

    </tr>) }
   
    {filterData.length === 0 && (<tr><td colSpan={11} style={{padding:"50px 0", textAlign:"center"}}>실패 기록이 없습니다.</td></tr>)}
  </tbody>
</table>

        </div>
        {data.length >10 && (
        <Pagination
                        postsPerPage={limit}
                        totalPosts={data.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>

        )}
     </div>
    
    </div>

  );
};

export default AdminPassword;
