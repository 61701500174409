const data = {
  name: "Cell",
  
  id: "0",
  zoomRatio: 1,
  order: "0",
  column: 18,
  row: 36,
  status: 1,
  celldata: [],
  rh_height: 936,
  luckysheet_select_save: [
    {
      left: 741,
      width: 138,
      top: 796,
      height: 19,
      left_move: 741,
      width_move: 138,
      top_move: 796,
      height_move: 19,
      row: [33, 33],
      column: [6, 6],
      row_focus: 33,
      column_focus: 6
    }
  ],
  calcChain: [
  ],
  scrollLeft: 0,
  scrollTop: 0,
  
};

export default data;
