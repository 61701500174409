
import { useLocation } from "react-router-dom";
import FolderTree from 'react-folder-tree';
import React, { useState, useEffect, useRef } from "react";
import AddData from "../components/AddData";

import axios from "axios";
import "./css/adminBoard.css";
import user from "../components/img/user.png";
import Popup from "../components/Popup";
import AutoComplete from "../components/AutoComplete";
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";
import AddConfirmLine from "../components/AddConfirmLine";
import BoardEditorSetting from "../components/BoardEditorSettingData";
import Permissions from "../components/PermissionsData";


const AdminBoard = () => {
    const [department, setDepartment] = useState([]);
    const [data, setData] = useState([]);
    const [addBoard, setAddBoard] = useState(false);
    const [addGroup, setAddGroup] = useState(false);

    const [showUser, setShowuser] = useState(false);

    const [testData, setTestData] = useState([]);
    const [unassigned, setUnassigned] = useState([]);
    const [members, setMembers] = useState([]);
    const [parent, setParent] = useState("");
    const [name, setName] =useState("");
    const [desc, setDesc] =useState("");

    const [type, setType] =useState("board");
    const [use, setUse] =useState("use");
    const [num, setNum] = useState(0);
    const [lev, setLev] = useState(0);
    const [selected, setSelected]= useState({name:""});
    const [selectedMember, setSelectedMember]= useState();
    const [sidebar, setSidebar]=useState(false);


    const [editorType, setEditorType]= useState("text");

    const [addStatus, setAddStatus] =useState();
    const [statusList, setStatusList] =useState([]);
    const [status, setStatus] =useState("");
    const [showEditor, setEditor] = useState(false);

    const [showPopup, setPopup] = useState(false);
    const [addCategory, setAddCategory] = useState([]);

    const [cateList, setCateList] = useState([]);
    const [showPermission, setShowPermission] = useState(false);
    const [writers, setWriters] =useState();
    const { state } = useLocation();
    const togglePopup = () => {
      setPopup(!showPopup);
    };
    const toggleEditor = () => {
      setEditor(!showEditor);
    };

    const togglePermission = () => {
      setShowPermission(!showPermission);
    };

    const onTypeChange = e => {
      setType(e.target.value)
    };

    const onUseChange = e => {
      setUse(e.target.value)
    };

    const onTreeStateChange = (state, event) => console.log(state, event);

    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].bg_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.bg_name,
            parentNo : node.bg_parent,
            num : node.bg_no,
            level : node.bg_level,
            order : node.bg_order,
            dNo : node.d_no,
            type : node.bg_type,
            desc : node.bg_desc,
            children: lookingForNodeWithParent( nodes, node.bg_no )
          });
        }
      }
      
      return arrayToReturn;
    }
    
    function showDepartment(){
      
    }
    useEffect(() => {
      console.log("datga is,,,," ,members);

      if (state!==null){
        axios.post('https://gw.thegmmedical.com:5003/api/get_dataadmin',{
          data: {
            // 서버에서 req.body.{} 로 확인할 수 있다.
            bg_no : state.bg_no
          },
        })
      .then(function (response){
        setSelected({num : state.bg_no});
        setName(response.data[0].bg_name);
        setDesc(response.data[0].bg_desc);
        setType(response.data[0].bg_type);
        setUse(response.data[0].bg_use);
        if (response.data[0].editor_type !==null){
          setEditorType(response.data[0].editor_type);
        }
       
        if (response.data[0].status !==null){
          setStatusList(JSON.parse(response.data[0].status));
        }else{
          setStatusList([]);
        }
        if (response.data[0].category_list !==null){
          setCateList(JSON.parse(response.data[0].category_list));
        }else{
          setCateList([]);
        }
        var tempArray=[];
        for (let i = 0; i < response.data.length; i++) {
          var adminVal=response.data[i].admin_value;
 
          if (adminVal !== '') {
            const tempStr = adminVal.substring(adminVal.indexOf("<")+1, adminVal.indexOf(">"));
            const item = { id: i, text: adminVal, email : tempStr  };
           
              tempArray = [...tempArray, item];
             
           
     
           
          }
 
      }
      setKeywords(tempArray);
        console.log(keywords);
 
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
       
      }

      fetch("https://gw.thegmmedical.com:5003/api/admin_getdatalist", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("dddddd", json);  
        console.log(lookingForNodeWithParent( json, null ));
        //setBoxes(json[0].boxes);
        var array = lookingForNodeWithParent( json, null );
        //let newData = array.map(el => el[0]);
            //console.log(newData);
            setData(array);
        return setTestData(array[0]);

        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});

      axios.get('https://gw.thegmmedical.com:5003/api/getunassigned',{
          
        })
  .then(function (response){
    console.log("????????", response);
    /* tempArray = [];
    for (let i =0;i<response.data.length;i++){
      
      console.log(response.data[i].name);
      tempArray.push(response.data[i].name + response.data[i].email);
      
    }*/
    setUnassigned(response.data);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
    


  axios.get('https://gw.thegmmedical.com:5003/api/getdepartment',{
          data: {
            // 서버에서 req.body.{} 로 확인할 수 있다.
            folder_name: name
          },
        })
  .then(function (response){
    console.log("department......", response.data);
    /*var tempArray = [];
    for (let i =0;i<response.data.length;i++){
      
      console.log(response.data[i].name);
      tempArray.push(response.data[i].name + response.data[i].email);
      
    }*/
    setDepartment(response.data);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });


  /*axios.get('https://gw.thegmmedical.com:5003/api/get_checkline_setting/'+no)
  .then(function (response){

    setCheckLines(response.data);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });*/
  

  
    }, []);
  
    const toggleBoard = () => {
        setAddBoard(!addBoard);
    };
    const toggleGroup = () => {
      setAddGroup(!addGroup);
    };
    const toggleUser = () => {
      setShowuser(!showUser);
  };
      
  
     const onNameClick = ({ defaultOnClick, nodeData }) => {
      defaultOnClick();
    
      const {
        // internal data
        path, name, num, desc, level, type, children
        // custom data
      } = nodeData;
      console.log(
        "nodedaat....", nodeData
      );
setSelected(nodeData);
setEditorType("text");



var aTags = document.getElementsByTagName("span");
var searchText = name;
var found;

for (var i = 0; i < aTags.length; i++) {
  if (aTags[i].textContent === searchText) {
    found = aTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('.editableNameContainer');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';
     
    
      setKeywords([]);
        console.log(nodeData);
      

      setParent(name);
      setNum(num);
      setLev(level);

      axios.post('https://gw.thegmmedical.com:5003/api/get_dataadmin',{
        data: {
          // 서버에서 req.body.{} 로 확인할 수 있다.
          bg_no : num
        },
      })
    .then(function (response){
      setName(response.data[0].bg_name);
      setDesc(response.data[0].bg_desc);
      setType(response.data[0].bg_type);
      setUse(response.data[0].bg_use);
      if (response.data[0].editor_type !==null && response.data[0].editor_type!==undefined){
        setEditorType(response.data[0].editor_type);
      }
      
      if (response.data[0].status !==null&& response.data[0].status!==undefined){
        setStatusList(JSON.parse(response.data[0].status));
      }else{
        setStatusList([]);
      }
      if (response.data[0].category !==null && response.data[0].category!==undefined){
        setCateList(JSON.parse(response.data[0].category));
      }else{
        setCateList([]);
      }
      var tempArray=[];
      for (let i = 0; i < response.data.length; i++) {
        var adminVal=response.data[i].admin_value;

        if (adminVal !== '' && adminVal !== null) {
          const tempStr = adminVal.substring(adminVal.indexOf("<")+1, adminVal.indexOf(">"));
          const item = { id: i, text: adminVal, email : tempStr  };
         
            tempArray = [...tempArray, item];
            
          
    
          
        }

    }
    setKeywords(tempArray);
      console.log(keywords);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
      

    axios.post('https://gw.thegmmedical.com:5003/api/get_dataviewer',{
        data: {
          // 서버에서 req.body.{} 로 확인할 수 있다.
          bg_no : num
        },
    })
    .then(function (response){
      console.log(response.data);
      var tempArray=[];
      
      for (let i = 0; i < response.data.length; i++) {
        var val=response.data[i].d_name;
        var num=response.data[i].d_no;
        if (val !== '') {
          const item = { id: i, text: val, num : num };
          tempArray = [...tempArray, item];
        }
    }

    setViewers(tempArray);
    console.log("hey, what'sup?", viewers);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

      

  };



  const deleteBoard = () => {
    console.log(parent);
    const data ={
      name : name,
      parent : parent,
      num : num,
      level : lev
    }
    axios.post('https://gw.thegmmedical.com:5003/api/delete_data', data)
    .then(function (response){
      console.log(response);
      showDepartment();
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };


 
  

  const [keyword, setKeyword]=useState("");
  const [keywords, setKeywords]=useState([]);
  

  const generateId_kw = () => {
    const highestId = Math.max.apply(Math, keywords.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_kw() {
    if (keyword !== '') {
      const tempStr = keyword.substring(keyword.indexOf("<")+1, keyword.indexOf(">"));
      const item = { id: generateId_kw(), text: keyword, email : tempStr  };
      console.log("item...", item);
      var valueArr = keywords.map(function(item){ return item.text });
      if (valueArr.includes(keyword)){
        return false;
      }
      else{
        const tempArray = [...keywords, item];
        setKeywords(tempArray);
      }

      
    }
    setKeyword('');
  }

  function addkeywords() {
    console.log(keyword);
    createNewToDoItem_kw();
  }
  const deleteItem_kw = id => {
    setKeywords(keywords.filter(item => item.id !== id));
  };
  const deleteCate = id => {
    console.log(cateList);
    setCateList(cateList.filter(item => item !== id));
  };

  const display_keyword = keywords.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_kw(item.id)}>&times;</span>

    </div>
  ));


  const [viewer, setViewer]=useState("");
  const [viewerNo, setViewerNo]=useState("");
  const [viewers, setViewers]=useState([]);
  

  const generateId_vw = () => {
    const highestId = Math.max.apply(Math, viewers.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_vw() {
    if (viewer !== '') {
      const item = { id: generateId_vw(), text: viewer, num : viewerNo  };
      console.log("item...", item);
      var valueArr = viewers.map(function(item){ return item.text });
      if (valueArr.includes(viewer)){
        return false;
      }
      else{
        const tempArray = [...viewers, item];
        setViewers(tempArray);
      }

      
    }
    setViewer('');
  }

  function addViewers() {
    createNewToDoItem_vw();
  }
  const deleteItem_vw = id => {
    setViewers(viewers.filter(item => item.id !== id));
  };
  const deleteItem = id => {
    setStatusList(statusList.filter(item => item !== id));
  };


  const display_viewer = viewers.map(item => (
    <div className="viewer"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_vw(item.id)}>X</span>

    </div>
  ));

  function saveBoard() {
    
    var admins = keywords.map(function(item){ return [selected.num, item.text, item.email] });
    var viewers_list = viewers.map(function(item){ return [selected.num, item.text, item.num] });
    axios.post('https://gw.thegmmedical.com:5003/api/savedata',{
      data: {
        bg_no : selected.num,
        title : name,
        desc : desc,
        type : type,
        use: use,
        admins: admins,
        viewers : viewers_list,
        status : JSON.stringify(statusList),
        editor_type : editorType,
        category : JSON.stringify(cateList)
      },
    })
    .then(function (response){
       
        console.log(lookingForNodeWithParent( response.data, null ));
        //setBoxes(json[0].boxes);
        var array = lookingForNodeWithParent( response.data, null );
        //let newData = array.map(el => el[0]);
            //console.log(newData);
            setData(array);
        setTestData(array[0]);


      alert("저장되었습니다.");
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

  const onChangeName = (e) => {
    setName(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
  }
  const onChangeDesc = (e) => {
    setDesc(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
  }

  const addToList = () => {
    
    let temp = [...statusList,  addStatus];
    setStatusList(temp);

  }

  const addToCategories = () => {
    
    let temp = [...cateList,  addCategory];

    let hasDuplicate = temp.some((val, i) => temp.indexOf(val) !== i);

    if (hasDuplicate===true){
      alert("중복된 값은 입력할 수 없습니다.")
    }else{
      setCateList(temp);
    }
    

  }
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>


{showPermission ? (
  <Permissions bgNo={num} onChange={(value)=>{setWriters(value.writer); setViewers(value.viewer);}} closePermission={() => togglePermission()} />
) : null}  
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents">
        <div className="header_txt">
        <h3>자료실 관리</h3>
        </div>
    
      <div className="admin_wrapper">
        <div className="main_contents_wrapper departments">
            <div className="btns">
                <button onClick={() => {toggleBoard();setSelected({name:""});}}>게시판 생성</button> 
                <button onClick={() => {toggleGroup();setSelected({name:""});}}>그룹 생성</button> 
                <button onClick={() => {if (selected.type==="board"){toggleBoard();}else{toggleGroup();}}}>정보 변경</button> 
                <button onClick={() => deleteBoard()}>게시판 삭제</button>
            </div>

{addBoard ? (
  <AddData selected = {selected} type="board" closePopup={() => toggleBoard()} />
) : null}
{addGroup ? (
  <AddData selected = {selected} type="group" closePopup={() => toggleGroup()} />
) : null}
{showUser ? (
  <Popup member = {selectedMember} closePopup={() => {
    toggleUser();

    var aTags = document.getElementsByTagName("span");
var searchText = parent;
var found;

for (var i = 0; i < aTags.length; i++) {
  if (aTags[i].textContent === searchText) {
    found = aTags[i];
    break;
  }
}
found.click();

  }}/>
) : null}



{showPopup ? (
  <AddConfirmLine target={status} bgNo={num} order_no={statusList.indexOf(status)} closePopup={() => togglePopup()} />
) : null}

{showEditor ? (
  <BoardEditorSetting target={status} editor_type={editorType} bgName={name} bgNo={num} order_no={statusList.indexOf(status)} closePopup={() => toggleEditor()} />
) : null}
    <div className="folder">
      {data.map((element, index)=>
      <FolderTree
      data={ element }
      onChange={ onTreeStateChange }
      onNameClick={ onNameClick }
      showCheckbox={ false } 
      readOnly
      />
      )}
      
</div>


        </div>
        {selected.name!=="" && (
          <div className="board_setting">
        <div className="board_info">
          <label for="inputName">게시판 제목 : </label>
          <input type="text" id="inputName" value={name} onChange={onChangeName}/>
        </div>
        <div className="board_info">
          <label for="inputName">게시판 설명 :</label>
          <textarea type="text" id="inputName" value={desc} onChange={onChangeDesc}/>
        </div>
        <div className="board_info">
          <label for="inputName">분류 :</label>
          <label htmlFor="type">
      <input
        type="radio"
        name="type"
        value="board"
        checked={type === "board"}
        onChange={onTypeChange}
      />
      게시판
    </label>
    <label htmlFor="type">
      <input
        type="radio"
        name="type"
        value="group"
        checked={type === "group"}
        onChange={onTypeChange}
      />
      그룹
    </label>
        </div>
        <div className="board_info">
          <label for="inputName">운영자 :</label>
          <div style={{width:"80%"}}>
            <div style={{display:"flex"}}>
               <AutoComplete onChange={(value) => {setKeyword(value); console.log("hihi", value)}}/>
                <button onClick={addkeywords} style={{marginLeft:"10px"}}>+</button>
            </div>
         

          <div class="admin_list">
            {display_keyword}
          </div>

          </div>


          
                    

        </div>


        

 <div className="board_info">
          <label for="inputName">게시판 권한자 :</label>
          <button onClick={()=>togglePermission()}>권한자 설정</button>


       
        </div>

        <div className="board_info">
          
          <label for="inputName">문서분류 :</label>
          <div style={{width:"80%"}}>
          <div style={{display:"flex"}}><input type="text" value={addCategory} onChange={(e)=> setAddCategory(e.target.value)}></input>
          <button onClick={()=>addToCategories()} style={{marginLeft:"10px"}}>+</button>


          </div>
         <div className="admin_list" style={{display:"flex", flexWrap:"wrap"}}>
            {cateList.map((ele,idx)=>(
<div className="admin" style={{margin:"5px"}}>

  <span>{ele}</span>

<span className="del_btn" onClick={() => deleteCate(ele)}>X</span>

</div>
            ))}
          </div>

        </div>
      
        </div>


   
        <div className="board_info">
        <label for="inputName">에디터 :</label>
          <select value={editorType} onChange={(e)=>{
                                    setEditorType(e.currentTarget.value);
                                  }}> 
            <option value="text">텍스트 에디터</option>
            <option value="excel">엑셀 에디터</option>
          </select>
        </div>



        <div className="board_info">
        <label for="inputName">초기내용 :</label>
          <button onClick={()=>toggleEditor()}>에디터 설정</button>
                                </div>
        <div className="board_info">
          <label for="inputName">사용 여부 :</label>
          <label htmlFor="use">
      <input
        type="radio"
        name="use"
        id="use"
        value="use"
        checked={use === "use"}
        onChange={onUseChange}
      />
      사용
    </label>
    <label htmlFor="use">
      <input
        type="radio"
        name="use"
        id="stop"
        value="stop"
        checked={use === "stop"}
        onChange={onUseChange}
      />
      중지
    </label>
        </div>
        <div className="btns" style={{display:"flex",justifyContent:"center"}}>
        <button onClick={()=>saveBoard()}>저장</button>
        </div>
        </div>

        )}
        
        
        </div>
       
     </div> 
    </div>

  );
};

export default AdminBoard;
