
import { useParams, Link, useLocation } from "react-router-dom";

import "react-tooltip/dist/react-tooltip.css";

import React, { useState, useEffect, useRef } from "react";
import Popup from "../components/Popup";

import axios from "axios";
import "./css/adminUsers.css";

import user from "./img/user.png";
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";
import styled from 'styled-components';
import Languages from "./tables/Languages";
import Education from "./tables/Education";
import Experience from "./tables/Experience";
import Certificates from "./tables/Certificates";
import BusinessTrips from "./tables/BusinessTrips";
import Salaries from "./tables/Salaries";
import Vacation from "./tables/Vacation";
import Appointments from "./tables/Appointments";


const TabMenu = styled.ul`
  font-weight: bold;
  box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%),
  inset 0 1px 0 0 rgb(100 121 143 / 12%);
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin-bottom: 2rem;
  margin-top: 10px;
  @media only screen and (max-width: 1300px) {
    flex-direction:column;
  
  }

  a{
    @media only screen and (max-width: 1300px) {
      width:100%;
    
    }
  }
  .submenu {
  // 기본 Tabmenu 에 대한 CSS를 구현
    display: flex;
    /* justify-content: space-between;
    width: 380px;
    heigth: 30px; */
    padding: 10px;
    font-size: 12px;
    cursor:pointer;
    box-sizing:border-box;
    transition: 0.5s;

    @media only screen and (max-width: 1300px) {
      width:100%;
    
    }

  }
  .submenu:hover {
  }
  .submenu:first-child {
    margin:0 20px;
    @media only screen and (max-width: 1300px) {
      margin:0;
    
    }
  }
  .focused {
   //선택된 Tabmenu 에만 적용되는 CSS를 구현
    color: rgb(21,20,20);
    border-bottom:2px solid #000;
    @media only screen and (max-width: 1300px) {
      border:none;
      background:#ddd;
    
    }
  }

  & div.desc {
    text-align: center;
  }
`;

const Desc = styled.div`
  text-align: center;
`;
const AdminUsers = () => {
    const [cnt, setCnt] = useState(0);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [type, setType] = useState("양력");
    const [checkList, setCheckList] = useState([]);
    const location = useLocation();
   const [sidebar, setSidebar]=useState(false);
   const [selectedFile, setSelectedFile] = useState();
   const [userNo, setUserNo] = useState("0");
   const [name, setname] = useState("");
   const [howToEnter, setHowToEnter] = useState("");
   const [referee, setReferee] = useState("");
   const [addr, setAddr] = useState("");


   const [pw, setPw] = useState("");
   const [email, setEmail] = useState("");
   const [preview, setPreview] = useState("");
   const [profile, setProfile] = useState("");
   const [department, setDepartment] = useState([]);
   const [selectedD, setSelectedD] = useState(39);
   const [selectedJ, setSelectedJ] = useState(13);
   const [job, setJob] = useState([]);
   const [birthday, setBirthday]=useState("");
   const [startD, setStartD]=useState("");
   const [endD, setEndD]=useState("");
   const [leaveReason, setLeaveReason] = useState("");
   const [workName, setWorkName] = useState("");
   const [contract, setContract] = useState("");
   const [promoteDate, setPromoteDate] = useState("");

   const [showPopup, setPopup] = useState(false);
   const [tel, setTel]= useState("");
   const [extension, setExtension]= useState("");
   const [fax, setFax]= useState("");
   const [place, setPlace]= useState("");
   const [currentTab, clickTab] = useState(0);
  const { menu } = useParams();
  const stateData = location.state;
  var menuArr;
  if (stateData !==null){
    menuArr = [
      { name: '경력', content: <Experience user_no={stateData.member.user_no}/> },
      { name: '자격', content: <Certificates user_no={userNo}/> },
      { name: '어학', content: <Languages user_no={userNo}/>},
      { name: '교육', content: <Education user_no={userNo}/> },
      { name: '출장', content: <BusinessTrips user_no={userNo}/> },
      { name: '급여', content: <Salaries user_no={userNo}/> },
      { name: '휴가', content: <Vacation user_no={userNo}/> },
      { name: '인사발령', content: <Appointments user_no={userNo}/> },
    ];
    
  }else{
    menuArr = [
    { name: '경력', content: <Experience user_no={userNo}/> },
    { name: '자격', content: <Certificates user_no={userNo}/> },
    { name: '어학', content: <Languages user_no={userNo}/>},
    { name: '교육', content: <Education user_no={userNo}/> },
    { name: '출장', content: <BusinessTrips user_no={userNo}/> },
    { name: '급여', content: <Salaries user_no={userNo}/> },
    { name: '휴가', content: <Vacation user_no={userNo}/> },
    { name: '인사발령', content: <Appointments user_no={userNo}/> },
  ];
  }
 
  
    const updateUser = () => {

      console.log("checklist...", checkList);
        for (var i=0;i<data.length;i++){
          if (data[i].email === checkList[0]){
            setSelected(data[i]);
          }
        }
      setPopup(!showPopup);
      
    };
    const saveUser = () => {

      const formData = new FormData();
        

      formData.append(`user_no`, userNo);
      
      formData.append(`name`, name);
      formData.append(`birthdate`, birthday);
      formData.append(`start`, startD);
      formData.append(`resig`, endD);
      formData.append(`extension`, extension);

      formData.append(`tel`, tel);
      formData.append(`resig_reason`, leaveReason);
      formData.append(`work_name`, workName);
      formData.append(`contract`, contract);
      formData.append(`promote_date`, promoteDate);
      formData.append(`howToEnter`, howToEnter);
      formData.append(`referee`, referee);
      formData.append(`addr`, addr);
      formData.append(`profile_link`, profile);
      formData.append(`birthdate_type`, type);
      const fetch = async () => {
        await axios
          .post("https://gw.thegmmedical.com:5003/api/user_detail", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            console.log("what is this");
            alert("저장되었습니다");
            window.location.reload(true);
          });
      };
      fetch();
      
    };
    
    const deleteUser = () => {
      
      var array = [];
      array.push(email);
    
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 삭제하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/deleteUsers', data)
        .then(function (response){
          console.log(response);
          alert("삭제되었습니다.")
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const stopUser = () => {
      var array = [];
      array.push(email);
    
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 중지하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/stopUsers', data)
        .then(function (response){
          console.log(response);
          alert("중지되었습니다.")

          
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const activeUsers = () => {
      var array = [];
      array.push(email);
    
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 활성화하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/activateUsers', data)
        .then(function (response){
          console.log(response);
          alert("활성화되었습니다.")

  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
  
    useEffect(() => {
        
      const data = location.state;
      if (data !==null){

        let user_no = data.member.user_no;
        axios.get('https://gw.thegmmedical.com:5003/api/getuserinfo/'+user_no)
        .then(function (response){
        //setBoxes(json[0].boxes);
        console.log("user info", response.data);
        let info = response.data[0];
        if (info.user_no !==null){
          setUserNo(info.user_no);
        }
        if (info.email !==null){
          setEmail(info.email);
        }
        
        if (info.name !==null){
        setname(info.name);
        }
        if (info.birthdate !==null){
      setBirthday(info.birthdate);
        }
        if (info.profile_link !==null){
      setProfile(info.profile_link);
        }
        if (info.start !==null){
      setStartD(info.start);
        }
        if (info.resig !==null && info.resig !=="null"){
      setEndD(info.resig);
        }
        if (info.extension !==null && info.extension !=="null"){
      setExtension(info.extension);
        }
        if (info.tel !==null && info.tel !=="null"){
      setTel(info.tel);
        }
        if (info.resig_reason !==null && info.resig_reason !=="null"){
      setLeaveReason(info.resig_reason);
        }
        if (info.work_name !==null && info.work_name !=="null"){
      setWorkName(info.work_name);
        }
        if (info.contract !==null && info.contract !=="null"){
      setContract(info.contract);
        }
        if (info.promote_date !==null && info.promote_date !=="null"){
      setPromoteDate(info.promote_date);
        }
        if (info.howToEnter !==null && info.howToEnter !=="null"){
      setHowToEnter(info.howToEnter);
        }
        if (info.referee !==null && info.referee !=="null"){
      setReferee(info.referee);
        }
        if (info.addr !==null && info.addr !=="null"){
      setAddr(info.addr);
        }
        if (info.birthdate_type !==null){
          setType(info.birthdate_type);
        }

        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });
        
      }
      
     
    
    fetch("https://gw.thegmmedical.com:5003/api/getdepartment/", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setDepartment(json);
      })
      .catch(() => {});
      fetch("https://gw.thegmmedical.com:5003/api/getjob/", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("job is....", json);
        setJob(json);
      })
      .catch(() => {});
      if (!selectedFile) {
          setPreview(undefined)
          return
      }

      const objectUrl = URL.createObjectURL(selectedFile)
      setPreview(objectUrl)
      if (menu !==undefined){
        var index = menu * 1;
        clickTab(index); console.log(currentTab);
      }
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)

        
    
      
      }, [selectedFile]);
  
     

  const togglePopup = () => {
    setPopup(!showPopup);
  };

  const imageRef=useRef();

 
    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }


  const selectMenuHandler = (index) => {
    // parameter로 현재 선택한 인덱스 값을 전달해야 하며, 이벤트 객체(event)는 쓰지 않는다
    // 해당 함수가 실행되면 현재 선택된 Tab Menu 가 갱신.
    clickTab(index);
  };

  function getUser(){
    fetch("https://gw.thegmmedical.com:5003/api/getusers/", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      setCnt(json[0].cnt);
      setData(json);
      
    })
    .catch(() => {});
  
  }
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents" style={{maxWidth:"1600px"}}>
        <div className="header_txt">
        <h3>계정 상세</h3>
        </div>
  

        <div className="user_detail">
            <div className="btns" style={{display:"flex", justifyContent:"space-between"}}>
              <div className="left">

              
                <button className="btn" onClick={() => updateUser()}>정보 변경</button> 

                <button className="btn" onClick={() => deleteUser()}>계정 삭제</button>
              </div>

              <div className="right">
                <button className="btn" onClick={() => stopUser()}>사용자 중지</button> 
                <button className="btn" onClick={() => activeUsers()}>계정 활성화</button> 

            </div>
            </div>

            {showPopup ? (
  <Popup member={location.state.member} text="Title" closePopup={() => {togglePopup();getUser();}} />
) : null}
<div className="table_wrap">
<div className="addUser" style={{marginTop:"30px"}}>

<div style={{display:"flex"}}>
                
<div className="addInfo" style={{width:"100%"}}>
<table width="100%" border="0" cellspacing="0" cellpadding="0" className="info_summary profile responsive">
	
	<tbody>
  
  <tr>
    <td rowSpan={6}><div className="addpic" style={{margin:"20px"}}>

        
<label htmlFor="photo-upload" className="custom-file-upload fas">

{ selectedFile
? <div className="img-wrap img-upload" style={{justifyContent:"center", alignContent:"center"}}>
<img src={preview} alt="preview" ref={imageRef}/>
</div>
: profile !=="" ? 
<div className="img-wrap img-upload">
  <img for="photo-upload" src={profile} alt="profile_pic" ref={imageRef}/>
</div>
: <img for="photo-upload" src={user} alt="profile_pic"/>
}


</label>
</div></td>
<th class="table_title2_topline" width="120" nowrap="">성명</th>
<td class="table_list2_left_topline" data-label="성명" width="15%"><b>
  <input type="text" value={name}
  onChange={(e)=>setname(e.target.value)}></input>
  </b></td>
<th class="table_title2_topline" width="120" nowrap="">부서</th>
<td class="table_list2_left_topline" data-label="부서" width="15%">
 {location.state !==null && (location.state.member.d_name)}</td>
<th class="table_title2_topline" width="120" nowrap="">직급</th>
<td class="table_list2_left_topline" data-label="직급">{location.state !==null && (location.state.member.job_name)}</td>

</tr>
<tr>
<th class="" width="120" nowrap="">생년월일</th>
<td class="" data-label="생년월일">

<div>
  <label>    
        <input type="radio" value="양력" name="type" checked={type === "양력"} onChange={()=>setType("양력")}
        style={{width:"unset", height:"unset"}}/><span>양력</span>
      </label>


      <label>
        <input type="radio" value="음력" name="type" checked={type === "음력"} onChange={()=>setType("음력")}
        style={{width:"unset", height:"unset"}}/><span>음력</span>
      </label>
</div>
<input type="date" value={birthday}
  onChange={(e)=>setBirthday(e.target.value)} style={{marginTop:"5px"}}></input>
</td>
<th class="" width="120" nowrap="">입사일자</th>
<td class="" data-label="입사일자">
<input type="date" value={startD}
  onChange={(e)=>setStartD(e.target.value)}></input>
</td>
  <th class="" width="120" nowrap="">퇴사일자</th>
<td class="" data-label="퇴사일자">
<input type="date" value={endD}
  onChange={(e)=>setEndD(e.target.value)}></input>

</td>
</tr>
<tr>
<th class="" width="120" nowrap="">내선번호</th>
<td class="" data-label="내선번호">

<input type="text" value={extension}
  onChange={(e)=>setExtension(e.target.value)}></input>

</td>
<th class="" width="120" nowrap="">휴대폰번호</th>
<td class="" data-label="휴대폰번호">

<input type="text" value={tel}
  onChange={(e)=>setTel(e.target.value)}></input>  
  
</td>
<th class="" width="120" nowrap="">퇴사사유</th>
<td class="" data-label="퇴사사유">

<input type="text" value={leaveReason}
  onChange={(e)=>setLeaveReason(e.target.value)}></input>  

</td>
</tr>
<tr>
<th class="" width="120" nowrap="">담당업무</th>
<td class="" data-label="담당업무">
<input type="text" value={workName}
  onChange={(e)=>setWorkName(e.target.value)}></input>  
</td>
<th class="" width="120" nowrap="">근무형태</th>
<td class="" data-label="근무형태">
<input type="text" value={contract}
  onChange={(e)=>setContract(e.target.value)}></input>  
</td>
<th class="" width="120" nowrap="">현직급 승진일</th>
<td class="" data-label="현직급 승진일">

<input type="date" value={promoteDate}
  onChange={(e)=>setPromoteDate(e.target.value)}></input>  

</td>
</tr>
<tr>
<th class="" width="120" nowrap="">입사경로</th>
<td class="" colspan="3" data-label="입사경로">

<input type="text" value={howToEnter}
  onChange={(e)=>setHowToEnter(e.target.value)}></input>  
</td>
<th class="" width="120" nowrap="">추천인/관계</th>
<td class="" data-label="추천인/관계">
<input type="text" value={referee}
  onChange={(e)=>setReferee(e.target.value)}></input>  
</td>
</tr>
<tr>
<th class="" width="120" nowrap="">주소</th>
<td class="" colspan="5" data-label="주소">
<input type="text" value={addr}
  onChange={(e)=>setAddr(e.target.value)}></input>  
</td>
</tr>
	
</tbody></table>


        </div>
                </div>

<div style={{width:"100%", textAlign:"center", marginBottom:"30px"}}>
<button onClick={()=>saveUser()}>저장</button>


</div>
            
          <TabMenu style={{borderRight:"1px solid #ddd", borderLeft:"1px solid #ddd"}}>
          {menuArr.map((el,index) => (
            (location.state!==null) ? (
              <Link to ={el.href} state={{member : location.state.member}}><li className={index === currentTab ? "submenu focused" : "submenu" }
              onClick={() => selectMenuHandler(index)}>{el.name}</li></Link>
            ) :(
              <Link to ={el.href}><li className={index === currentTab ? "submenu focused" : "submenu" }
              onClick={() => selectMenuHandler(index)}>{el.name}</li></Link>
            )

          ))}
        </TabMenu>
  <Desc>
  <p>{menuArr[currentTab].content}</p>
</Desc> 
  
               
    </div>
  </div>
        <div style={{display:"flex"}}>
        <div className="addInfo" style={{width:"100%"}}>
      
        </div>


        

        </div>
        </div>
       
        
     </div>
    
    </div>

  );
};

export default AdminUsers;
