import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import "./css/popup.css";

import { IoIosCloseCircleOutline } from "react-icons/io";
import history from 'history/browser';
const DocExcel = ({ doc_no, closePopup }) => {
  const luckyCss = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    height: '100%',
    left: '0px',
    position:"absolute"
  }
  let luckysheet;





  useEffect(()=>{
    axios.get('https://gw.thegmmedical.com:5003/api/docview/'+doc_no)
    .then(function (response){
          

          if (response.data[0].form_data !== null && response.data[0].form_data !== "undefined" && response.data[0].form_data !==""
          && response.data[0].type ==="excel"){
            var excel_data = JSON.parse(response.data[0].form_data);
            excel_data.showGridLines = 0;
            var rowN = excel_data.row;
            var height= parseInt(rowN) * 15;
            height = height + "px";
            height = height.toString();
            console.log(height, excel_data);
            console.log("excel_data...", excel_data);
           
              const loadLuckysheet = async () => {
                try {
                  // Load Luckysheet dynamically
                  const luckysheetModule = await import('luckysheet');
                  excel_data[0].showGridLines="0";
   
   
                  luckysheet = luckysheetModule.default;
           
                  luckysheet.create({
                    container: "luckysheet",
                    title: 'Luckysheet sphinx Demo', // set the name of the table
                    data: excel_data,
                    showinfobar: false,
                    allowUpdate :false,
                    showstatisticBar:false,
                    sheetFormulaBar:false,
                    columnHeaderHeight:0,
                    rowHeaderWidth:0,
                    showsheetbar:false,
                    showtoolbar:false,
                })
                 
           
           
                  // Set the loaded state
                } catch (error) {
                  console.error('Error loading Luckysheet:', error);
                }
              };
              loadLuckysheet();
            
          }
     
         
 
   
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
 
      Element.prototype.remove = function() {
        this.parentElement.removeChild(this);
    }
    NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
        for(var i = this.length - 1; i >= 0; i--) {
            if(this[i] && this[i].parentElement) {
                this[i].parentElement.removeChild(this[i]);
            }
        }
    }


  },[])

  function onClose() {
    //luckysheet.destroy();
    //luckysheet=undefined;
    window.location.reload(true);
    //document.getElementById("luckysheet").remove();
/*
  

 
var touchPos;

// store the touching position at the start of each touch
document.body.ontouchstart = function(e){
    touchPos = e.changedTouches[0].clientY;
}

//luckysheetFreezen.freezenCancel();

// detect wether the "old" touchPos is 
// greater or smaller than the newTouchPos
document.body.ontouchmove = function(e){
    let newTouchPos = e.changedTouches[0].clientY;
    if(newTouchPos > touchPos) {
        console.log("finger moving down");
    }
    if(newTouchPos < touchPos) {
        console.log("finger moving up", newTouchPos, touchPos);
        setTimeout(function () {
          window.focus();
window.scrollTo(0,0);

      },2);
    }
}

*/
    //window.location.reload(true);
     //useClosePopup을 통해 자기 자신을 닫음
    
    //closePopup();
    //history.go(0);



  }



  return (
    <div className="popup" style={{zIndex:"9999"}}>
      <div className="popup_inner" style={{height:"100vh"}}>
      <div className="buttons" style={{ position:"absolute", width:"100%",
          padding:"10px auto",margin:"0", bottom:"40px", zIndex:"9999", justifyContent:"center"}}>
        {/*<button onClick={onClose} style={{padding:"10px 20px"}}>닫기</button>*/}
        <IoIosCloseCircleOutline onClick={onClose} style={{fontSize:"55px"}}/>
        </div> 

<div style={{height:"100vh"}}>
<div
     id="luckysheet"
     style={luckyCss}
   />  


     
       

</div>

       
        
      </div>
      
    </div>
  );
};

export default DocExcel;