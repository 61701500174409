




import { Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {FaRegListAlt } from "react-icons/fa";
import { HiPencilAlt, HiDocumentReport } from "react-icons/hi";
import * as XLSX from "xlsx";

import "../css/schedulelist.css";



const CustomerList = ({onChange}) => {
    const [admin, setAdmin] = useState(false);

    const [myGroups, setMyGroups] = useState([]);
    const [addrs, setAddrs] = useState([]);


    const [dHead, setDHead] = useState([]);
 
    const [department, setDepartment]= useState([]);
    const navigate = useNavigate();
    const {state}= useLocation();

    const importRef = useRef();

    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].bg_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.bg_name,
            parentNo : node.bg_parent,
            num : node.bg_no,
            level : node.bg_level,
            order : node.bg_order,
            dNo : node.d_no,
            type : node.bg_type,
            children: lookingForNodeWithParent( nodes, node.bg_no )
          });
        }
      }
      
      return arrayToReturn;
    }
    let location = useLocation();

  


    useEffect(() => {
      axios.get('http://gw.thegmmedical.com:5003/api/check_dhead',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      console.log("부서장ㅇ?", response.data[0]);
      setDHead(response.data[0]);
      //setBookmarks(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('http://gw.thegmmedical.com:5003/api/check_admin',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      console.log("부서장ㅇ?", response.data[0]);
      if (response.data[0].authority ==="admin"){
        setAdmin(true);
      }else{
        setAdmin(false);
      }
      
      //setBookmarks(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('http://gw.thegmmedical.com:5003/api/getaddrs',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    setAddrs(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

   
    axios.get('http://gw.thegmmedical.com:5003/api/get_teamaddr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setDepartment(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('http://gw.thegmmedical.com:5003/api/get_user_addr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setMyGroups(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    }, []);
 

        function allBoard(){
          axios.get("http://gw.thegmmedical.com:5003/api/news").then((response) => {
              
            });
        }
    
     
     
     
     
    
     
      
      
      // add fake url to data for testing purpose
    
      const [CheckList, setCheckList]=useState([2,3,4,8]);
   

      async function excelImport(e) {
        const file = e.target.files[0];
        const exceldata = await file.arrayBuffer();
              
        const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});
      
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
          //console.log(htmlData);
      
        const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
          header:1,
          defval:"",
          blankrows: false
        })
              const data ={
                tablename : "customers",
                header: "name, biz_no, type, rep, biz_type, biz_item, addr, hq, register_no, register_date, open_date",
                arr : jsondata
              }
              
              axios.post('http://gw.thegmmedical.com:5003/api/add_excel_data', data)
              .then(function (response){
                console.log(response);
                alert("추가되었습니다.");
                window.location.reload(true);
                
              })
              .catch(function (error){
                console.log(error);
              })
              .then(function (){
                //always executed
              });
        
    
              
              //initRow(rowObj);
      };


      

  return (
    <div className="wrapper">
     
     <div className="board_nav schedule_nav">
     <div style={{padding:"30px", background:"#f7f7f7"}}>
        <h2>거래처</h2>

        <div style={{display:"flex", justifyContent:"space-between"}}>
        <a href="/cx_w" style={{width:"100%"}}><div className="board_wrt"><HiPencilAlt style={{marginRight:"5px"}}/>거래처 등록</div></a>
        </div>
        <div style={{display:"flex", justifyContent:"space-between"}}>

          <div className="board_wrt" onClick={()=>{importRef.current.click()}}>
            엑셀로 불러오기
          </div>
          <input type="file" id="input_dom_element" ref={importRef} onChange={excelImport} style={{display:"none"}} />
        </div>

    <div className="wrap">


   

  

<div className="list">
<Link to="/cx" state={{type:"0"}}><span className="list_title" onClick={()=>allBoard()}><span><FaRegListAlt/>전체 거래처</span>
</span>
 </Link> 
</div>

<div className="list">
   <Link to="/cx" state={{type:"1"}}><span className="list_title"><span><HiDocumentReport/>매입처</span>
 </span></Link>


</div>

<div className="list">
  <Link to="/cx" state={{type:"2"}}><span className="list_title"><span><HiDocumentReport/>매출처</span>
 </span></Link>
{/*}
  <div className="menus">
{sales.map((element, index)=>{
   
    
    return (<div style={{display:"flex", marginBottom:"5px", 
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}>
    <span className={element.no}><a href={"/addr_list/"+element.no}>{element.g_name}</a></span></div>
 
    <Link to="/addrgrp_u" state={{group : element}}><BsPencilSquare/></Link>
   
    
    </div>
    
    )
  })}
</div>    
 */}
</div>

</div>

        </div>
        </div>
    </div>

  );
};

export default CustomerList;








