
import { NavLink, Link } from "react-router-dom";
import moment from 'moment';
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import OTHistory from "../components/OTHistory";

import {FaAddressBook} from "react-icons/fa";

axios.defaults.withCredentials = true; 
const initialValues = {
  leave_no:"",
  cancel_doc_no:"",
  reason:""
};


const CancelLeave = ({doc_no, onChange}) => {  
  
  const hour = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  var todayDate = new Date().toISOString().slice(0, 10);
  console.log(todayDate);
  const inputFileRef = useRef();
  const [leave, setLeave] = useState();
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [remainHour, setHour] = useState("12");
  const [subject, setSubject] = useState("");
  const [values, setValues] = useState(initialValues);
  const [range, setRange] = useState("1");
  const [type, setType] = useState("1");
  const [reason, setReason] = useState("");
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [startHour, setStartHour] = useState("");
  const [startMin, setStartMin] = useState("");
  const [endHour, setEndHour] = useState("");
  const [endMin, setEndMin] = useState("");
  const [rows, initRow] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const [user, setUser] = useState([]);
  const [location, setLocation] = useState("");
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
useEffect(()=>{
  
}, []);
   

  const handleInputChange = (event) => {
    //setReason(event.target.value);
    
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });


    values[name] = value;
    
    if (name === "selectdate"){
      const data = {
        date : value
      }
      axios.post("https://gw.thegmmedical.com:5003/api/getleave_bydate/", data)
        .then(({ data }) => {
          console.log("...................", data);

          setValues({
            ...values,
            leave_no: data[0].no,
            select_date : value,
            cancel_doc_no:data[0].doc_no,
            leave_start : data[0].start_date,
            leave_end : data[0].end_date,
            leave_days : data[0].days,
            leave_type : data[0].vtype,
            leave_range : data[0].vrange
          });
          values.leave_no = data[0].no;
          values.cancel_doc_no = data[0].doc_no;
          values.select_date = value;

          values.leave_start = data[0].start_date;
          values.leave_end = data[0].end_date;
          values.leave_days = data[0].days;
          values.leave_type = data[0].vtype;
          values.leave_range = data[0].vrange;

          setLeave(data[0]);
          onChange(values);
        
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });
    }
    
    

    

  };

 
  const rangeSelect = (e) => {
    e.preventDefault();
    setRange(e.target.value);
    var difference = new Date(endDate) - new Date(startDate);
    var days = difference/(24*3600*1000);
    days+=1;
    const data ={
      subject : subject,
      type : type,
      range : range,
      days: days,
      startDate: startDate,
      endDate : endDate,
      startTime: startHour+":"+startMin,
      endTime : endHour+":"+endMin,
      reason: reason
    }
    onChange(data);
  };

  

 
  

  const addRowTable = () => {
    const data = {
        product_name:'', quantity:'',unit_price:'', price:'', memo:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    //values.arr = rows;
    //onChange(values);


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    
    const data = [...rows];
    console.log(data, name, value, i);
    data[i][name] = value;
    initRow(data);

    //values.arr = rows;
    //onChange(values);

  };



  return (
    <div className="wrapper">

    
    <div className="" style={{justifyContent:"flex-start"}}>
  
   <div className="board_view_header" style={{width:"98%", margin:"10px auto"}}>
      {/*{show ? (
    <DraftsList onChange={(value) => {setSubject(value.subject);editorRef.current?.getInstance().setHTML(value.content);setDraftNo(value.draft_no)}} closePopup={() => toggleShow()}/>
      ) : null}*/}
 

        
 
           
            
 
 <table className="">

{/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
<thead>
<tr>
<th>수정영역</th>
<td class="">
<div className="input-group">

<select>
  <option>휴가취소신청서</option>

</select>
</div>
</td>
</tr>
<tr>

<th>적용 일자</th>
<td class="">
<div className="input-group">
<input type="date" name="selectdate" className="formInput" style={{marginLeft:"10px"}} onChange={handleInputChange} value={values.selectdate}></input>
</div>
</td>
</tr>
<tr>

<th>변경전 내용</th>

<td class="">
<div className="input-group" style={{display:"flex", flexShrink:"0"}}>

{leave!==undefined && (

<div className="" style={{marginLeft:"10px"}}>
<div className="row">
<span>휴가구분 :</span>
{leave.vtype == "1" && <span> 연차</span>}
{leave.vtype == "0.5" && <span> 반차</span>}
{leave.vtype == "0" && <span> 공가</span>}

</div>

<div className="row">
<span>시작일자 :</span>
<span> 
{moment(leave.start_date).format("yyyy-MM-DD")}


</span>

</div>


<div className="row">
<span>종료일자 :</span>
<span> 
{moment(leave.end_date).format("yyyy-MM-DD")}

</span>

</div>
<div className="row">
<span>휴가기간 :</span>
<span> {leave.days}</span>

</div>

<div className="row">
<span>전자결재 :</span>
<span>

{leave.status == "4" && <span> 승인</span>}
{leave.status == "1" && <span> 미승인</span>}

</span>

</div>

</div>

)}


</div>
</td>
</tr>
<tr>
<th>변경후 내용</th>

<td class="">
<div className="input-group" style={{display:"flex", flexShrink:"0"}}>

{leave!==undefined && (<span>위 선택된 휴가 신청서를 취소 요청합니다.</span>)}

</div>
</td>
</tr>
<tr>
<th>사유</th>
<td class="">
<div className="input-group" style={{display:"flex", flexShrink:"0"}}>

<input type="text" value={values.reason} onChange={(e)=>{
setValues({
  ...values,
  reason: e.target.value,
});


values['reason'] = e.target.value;
onChange(values);
}}></input>

</div>
</td>
</tr>

</thead>     



</table>
 
      

      </div>
   </div>
</div>
  );
};

export default CancelLeave;
