
import { NavLink, Link } from "react-router-dom";
import FolderTree from 'react-folder-tree';
import React, { useState, useEffect, useRef } from "react";
import AddBoard from "../components/AddBoard";
import axios from "axios";
import "./css/adminLevel.css";
import user from "../components/img/user.png";
import Popup from "../components/Popup";
import AutoComplete from "../components/AutoComplete";
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";

const AdminLevel = () => {
  const [members, setMembers] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectLevel, setSelectLevel] = useState();
  const [CheckList, setCheckList] = useState([]);
  const [CheckLevels, setCheckLevels] = useState([]);
  const [sidebar, setSidebar] = useState(false);

  const [selectAuth, setSelectAuth] = useState("");


  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  window.addEventListener('message', (event) => {
    if (event.data["selectedMembers"] !== undefined){
      
      var temp = event.data["selectedMembers"];
      var tempArray = members;
      for (let i=0;i<temp.length;i++){
        if (!tempArray.includes(temp[i])){
          
          tempArray = [...tempArray, temp[i]];
        }
      }
      const map = new Map(
        tempArray.map(obj => [obj.user_no, obj])
      );

      const deduplicatedArr = [...map.values()];
      setMembers(deduplicatedArr);
      
    }
 
  });
  function saveSetting(){
    const data={
      selectAuth : selectAuth,
      list : members
    }
    console.log(data);
    axios.post('https://gw.thegmmedical.com:5003/api/saveAuths', data)
        .then(function (response){
          console.log(response);
          alert("저장되었습니다.")
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
  }
  useEffect(() => {
  
    var str = window.location.href;
    var chars = str.split("/");
    


    axios.get('https://gw.thegmmedical.com:5003/api/adminCheck')
    .then(function (response){
      console.log("sidebar...", response.data);

      


    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });


  }, []);

  function showMembers(no){
    fetch("https://gw.thegmmedical.com:5003/api/get_auths/"+no, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log("members", json);
      setMembers(json);
    })
    .catch(() => {});

  }

  function selectAll()  {
    let checkboxes 
         = document.getElementsByName('member');
    let all 
         = document.getElementsByName('all');
    console.log(checkboxes);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = all[0].checked;
    })
  
  }
  function selectAllLevel()  {
    let checkboxes 
         = document.getElementsByName('level');
    let all 
         = document.getElementsByName('levelall');
    console.log(checkboxes);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = all[0].checked;
    })
  
  }
  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
       setCheckList([...CheckList, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
       setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }
 }

 const onChangeLevels = (e, id) => {
  // 체크할 시 CheckList에 id값 넣기
  if (e.target.checked) {
     setCheckLevels([...CheckLevels, id]);
  // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
  } else {
     setCheckLevels(CheckLevels.filter((checkedId) => checkedId !== id));
  }
}
 function delMembers(){
  let temp = members;
  let filtered = temp.filter( x => !CheckList.includes(x.user_no))
  setMembers(filtered);

      
 }
 function delLevels(){
  const data={
    checkList : CheckLevels
  }
  console.log(data);
  axios.post('https://gw.thegmmedical.com:5003/api/dellevels', data)
  .then(function (response){
    console.log(response);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
 }
 function addLevels(){
  var temp = levels;
  var last = levels[levels.length-1].no;
  var lastItem = {no:last+1, level: last+1, name: (last+1).toString(), in_use:null};
  
  temp = [...temp, lastItem];console.log(temp);
  const data = {
    add : lastItem
  }
  axios.post('https://gw.thegmmedical.com:5003/api/addlevel', data)
  .then(function (response){
    console.log(response);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
  setLevels(temp);
 }
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents">
        <div className="header_txt">
        <h3>관리자 추가</h3>
        </div>
    
      <div className="admin_wrapper admin_auth">
        <div className="main_contents_wrapper">
        <h4>관리기능 목록</h4>
        <div className="btns" style={{width:"100%",justifyContent:"space-between", display:"flex"}}>
          <span>관리자 메뉴 목록입니다.</span>
        </div>

          <table className="level_table" style={{width:"100%"}}>
                
            <tbody>
              <tr onClick={()=>{showMembers("기본관리"); setSelectAuth("기본관리")}}><td>기본 관리 [계정 설정, 관리자 추가]</td></tr>
                 
              <tr onClick={()=>{showMembers("보안관리");setSelectAuth("보안관리")}}><td>보안 관리 [보안등급 관리, 비밀번호]</td></tr>
                 
              <tr onClick={()=>{showMembers("인사관리");setSelectAuth("인사관리")}}><td>인사 관리 [사용자 관리, 부서 관리, 직위 관리]</td></tr>

                  
                   
              <tr onClick={()=>{showMembers("메뉴관리");setSelectAuth("메뉴관리")}}><td>메뉴 관리 [게시판 관리, 부서 게시판 관리]</td></tr>

              <tr onClick={()=>{showMembers("전자결재관리");setSelectAuth("전자결재관리")}}><td>전자결재관리 관리 [양식별 문서조회, 양식 수정]</td></tr>

              <tr onClick={()=>{showMembers("게시판관리");setSelectAuth("게시판관리")}}><td>게시판 관리 [팝업 공지사항 설정, 게시글 수정/삭제]</td></tr>
              <tr onClick={()=>{showMembers("휴가관리");setSelectAuth("휴가관리")}}><td>휴가 관리 [회사 휴일 등록, 연차 유형 관리]</td></tr>

            </tbody>
          </table>

        </div>

        <div className="main_contents_wrapper">
        <h4>사용자 목록</h4>
        <div className="btns" style={{width:"100%",justifyContent:"space-between", display:"flex"}}>
          <button onClick={() => popupwindow("/select", "결재라인", 800, 625)} alt=''>관리자 추가</button>
          <span>{selectAuth !="" && ([selectAuth])}</span>
        <button onClick={() => delMembers()} alt=''>삭제</button>
        </div>
        <div style={{height:"300px", marginTop:"20px",overflowY:"scroll", border:"1px solid #ddd"}}>
        <table className="member_table" style={{width:"100%", margin:"0"}}>
                <thead>
                <tr><td><input type='checkbox' name='all' value='all' onClick={selectAll}/></td>
                  <td>부서</td>
                  <td>직급</td>
                  <td>이름</td>
                  </tr>
                </thead>
                <tbody>
                    {members.map((element, index) => 
                    
                    <tr>
            <td>
                      <input type='checkbox' 
        name='member' 
        value={element.user_no}
        onChange={(e) => onChangeEach(e, element.user_no)} checked={CheckList.includes(element.user_no)}/> 
                      </td>
                      <td className={element.user_no} key={index}>
                      {element.d_name}</td>
                      <td>{element.job_name}</td>
                      <td> {element.name}</td>
                      </tr>) }

                      </tbody>
                </table>
                </div>
                        <div className="btns" style={{width:"100%",justifyContent:"center", marginTop:"30px",display:"flex"}}>
                          <button onClick={()=>saveSetting()}>저장</button>
                        </div>
        </div>

        
        </div>
       
     </div> 
    </div>

  );
};

export default AdminLevel;
