




import { Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import {FaCog, FaStar, FaRegListAlt, FaClipboardList } from "react-icons/fa";
import { HiPencilAlt } from "react-icons/hi";
import "../css/doclist.css";


const DocList = () => {

  const location = useLocation();

    const [docs0, setDocs0] = useState();
    const [docs1, setDocs1] = useState();
    const [docs2, setDocs2] = useState();
    const [docs3, setDocs3] = useState();
    const [docs4, setDocs4] = useState();

    const [rdocs0, setRdocs0] = useState(0);
    const [rdocs1, setRdocs1] = useState(0);
    const [rdocs2, setRdocs2] = useState(0);
    const [rdocs3, setRdocs3] = useState(0);
    const [rdocs5, setRdocs5] = useState(0);


    const [unRdocs0, setUnRdocs0] = useState(0);
    const [unRdocs1, setUnRdocs1] = useState(0);
    const [unRdocs2, setUnRdocs2] = useState(0);
    const [unRdocs3, setUnRdocs3] = useState(0);
    const [unRdocs5, setUnRdocs5] = useState(0);


    const [members, setMembers] = useState([]);
    const [parent, setParent] = useState("");
    const [bookmarks, setBookmarks] = useState([]);
    const [name, setName] = useState("");
    const [num, setNum] = useState(0);
    const [selected, setSelected]= useState([]);

    const [docAdmin, setDocAdmin]= useState(false);

    const navigate = useNavigate();

    const onTreeStateChange = (state, event) => console.log(state, event);

    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].bg_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.bg_name,
            parentNo : node.bg_parent,
            num : node.bg_no,
            level : node.bg_level,
            order : node.bg_order,
            dNo : node.d_no,
            type : node.bg_type,
            children: lookingForNodeWithParent( nodes, node.bg_no )
          });
        }
      }
      
      return arrayToReturn;
    }
    
      function clean(object) {
        Object
            .entries(object)
            .forEach(([k, v]) => {
              
                if (v && typeof v === 'object') {
                    clean(v);
                }
                if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
                    if (Array.isArray(object)) {
                        object.splice(k, 1);
                        
                    } else {
                        delete object[k];
                    }
                }
            });
        return object;
    }
    useEffect(() => {

      let dNo = 0;

      if (location.state!==null){
        if (location.state.doc_no!==undefined){
          dNo =location.state.doc_no;
        }
      }
      axios.get('https://gw.thegmmedical.com:5003/api/check_docadmin',{})
      .then(function (response){
        if (response.data.length>0){
          setDocAdmin(true);
        }
        
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
      axios.get('https://gw.thegmmedical.com:5003/api/update_docview/'+dNo)
    .then(function (response){
      console.log("doc_view", response);

      axios.get('https://gw.thegmmedical.com:5003/api/unrdocs0',{})
      .then(function (response){
        setUnRdocs0(response.data[0].difference);
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
  
      axios.get('https://gw.thegmmedical.com:5003/api/unrdocs1',{})
      .then(function (response){
        setUnRdocs1(response.data[0].difference);
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
      axios.get('https://gw.thegmmedical.com:5003/api/unrdocs2',{})
      .then(function (response){
        setUnRdocs2(response.data[0].difference);
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
      axios.get('https://gw.thegmmedical.com:5003/api/unrdocs3',{})
      .then(function (response){
        setUnRdocs3(response.data[0].difference);
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
      axios.get('https://gw.thegmmedical.com:5003/api/unrdocs5',{})
      .then(function (response){
        setUnRdocs5(response.data[0].difference);
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });

    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });


      axios.get('https://gw.thegmmedical.com:5003/api/get_bookmark/form',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      console.log("즐찾", response.data);
      setBookmarks(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });


    axios.get('https://gw.thegmmedical.com:5003/api/docs0',{})
    .then(function (response){
      setDocs0(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/docs1',{})
    .then(function (response){
      setDocs1(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/docs2',{})
    .then(function (response){
      setDocs2(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/docs3',{})
    .then(function (response){
      setDocs3(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/docs4',{})
    .then(function (response){
      setDocs4(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
        
    axios.get('https://gw.thegmmedical.com:5003/api/rdocs0',{})
    .then(function (response){
      setRdocs0(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/rdocs1',{})
    .then(function (response){
      setRdocs1(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/rdocs2',{})
    .then(function (response){
      setRdocs2(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/rdocs3',{})
    .then(function (response){
      setRdocs3(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/rdocs5',{})
    .then(function (response){
      setRdocs5(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

 
    }, []);
 

    const delBookmark = (e) =>{
      var result = window.confirm("즐겨찾기 게시판에서 삭제하시겠습니까?");
    if(result){
      
      const data ={
        num : e.target.className,
        table: "board"
      }
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/delete_bookmark', data)
    .then(function (response){
      console.log(response);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
    }

        function allBoard(){
          axios.get("https://gw.thegmmedical.com:5003/api/news").then((response) => {
              
            });
        }
        function noticeBoard(){
          axios.get("https://gw.thegmmedical.com:5003/api/notice").then((response) => {
              
            });
        }
        const onNameClick = ({ defaultOnClick, nodeData }) => {
          defaultOnClick();
          var notice_data=[];
          var board_data=[];
          const {
            // internal data
            path, name, num, level, type, isOpen, children
            // custom data
          } = nodeData;
          setName(name);
            setNum(num);
          if (type!=="group"){
            setSelected(nodeData);
            var main = document.getElementsByClassName('main_folder');

            var aTags = main[0].getElementsByTagName("span");
            
      var searchText;
      var found;
      
              searchText=name;
            
      for (var i = 0; i < aTags.length; i++) {
        if (aTags[i].textContent === searchText) {
          found = aTags[i];
          break;
        }
      }
      const boxes = document.querySelectorAll('.editableNameContainer');
      
      boxes.forEach(box => {
        box.style.backgroundColor = 'transparent';
      });
      found.style.backgroundColor = '#BEFDFD';
      found.click();
            setParent(name);
            
            /*const tempArray = [...options, item];
            console.log(tempArray);
            setOptions(tempArray);*/
      
      
            const item = name;
            const tempArray = [...members, item];
            setMembers(tempArray);
            axios.post('https://gw.thegmmedical.com:5003/api/getdoc',{
                data: {
                  // 서버에서 req.body.{} 로 확인할 수 있다.
                  folder_name: name
                },
              })
        .then(function (response){
          console.log("docs.........    ", response.data);
          board_data = response.data;
          //setBoard(response.data)
        
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
        
        
      
          }
          
      };

      const FileIcon = ({ onClick: defaultOnClick, nodeData }) => {
        const { path, name, checked, isOpen, url, ...restData } = nodeData;
        const handleClick = () => {
          defaultOnClick();
        };
      
        return <FaClipboardList onClick={ handleClick } />;
      };
      
     
     
    
     
      
      
      // add fake url to data for testing purpose
    
    function getDoc(){

    }
  return (
    <div className="wrapper">
     
     <div className="board_nav">
     <div classname="menu_wrapper" style={{padding:"30px"}}>
        <h2>전자결재</h2>
        <a href="/doc_w/0"><div className="board_wrt"><HiPencilAlt style={{marginRight:"5px"}}/>문서작성</div></a>


    <div className="wrap">

    <div className="FolderTree">
<span className="folder_title"><FaStar/>즐겨찾기</span>

<div className="bookmarks" style={{display:"flex", flexDirection:"column"}}>
{bookmarks.map((element,index)=>

  <a href={"/doc_w/"+element.no}><span className="" onClick={()=>noticeBoard()}><div className={element.no} onClick={(e)=>delBookmark(e)}><FaStar/></div>{element.name}</span></a>

)}
</div>    
</div>

<div className="list">
<span className="list_title" onClick={()=>allBoard()}><FaRegListAlt/>결재 상신함</span>
<div className="menus" onClick={()=>getDoc()}>
  <a href="/docs/1"><span className="1">미완료함 <span className='unread'>{docs1} </span></span></a>
  <a href="/docs/4"><span className="4">완료함<span className='unread'>{docs4} </span></span></a>
  <a href="/docs/2"><span className="2">보류함<span className='unread'>{docs2} </span></span></a>
  <a href="/docs/3"><span className="3">반려함<span className='unread'>{docs3} </span></span></a>
  <a href="/docs/0"><span className="0">임시저장<span className='unread'>{docs0} </span></span></a>

</div>    
</div>
<div className="list">
<span className="list_title" onClick={()=>allBoard()}><FaRegListAlt/>결재 수신함</span>
<div className="menus">
<a href="/rdocs/0"><span>대기중 요청<span className='unread'>{unRdocs0} / {rdocs0} </span></span></a>
<a href="/rdocs/1"><span>결재내역<span className='unread'>{unRdocs1} / {rdocs1} </span></span></a>
<a href="/rdocs/2"> <span>보류<span className='unread'>{unRdocs2} / {rdocs2} </span></span></a>
<a href="/rdocs/5">  <span>회람/참조 <span className='unread'>{unRdocs5} / {rdocs5} </span></span></a>
<a href="/rdocs/3"> <span>반려<span className='unread'>{unRdocs3} / {rdocs3} </span></span></a>
</div>    
</div>
  
{/*
<div className="list">
<span className="list_title" onClick={()=>allBoard()}><FaRegListAlt/>보관함</span>
<div className="menus">
  <span>개인 보관함</span>

</div>    
</div>
*/}
    <div className="main_folder">




     

    <div className="list">
 <span className="list_title"><FaCog/>환경설정</span>
 <div className="menus">
  <span><Link to="/doc_form">문서양식 관리</Link></span>
  <span><Link to="/line_list">결재라인 관리</Link></span>
  {docAdmin && (<span><Link to="/lastlines">고정 결재자</Link></span>)}

  <span><Link to="/setting/doc/0">개인설정</Link></span>
</div>    
   
      </div>

      <div className="list">
 <span className="list_title"><FaCog/>문서관리</span>
 <div className="menus">
  <span><Link to="/doc_admin">양식별 문서 조회</Link></span>

</div>    
   
      </div>

      </div>

</div>


        </div>
        </div>
    </div>

  );
};

export default DocList;








