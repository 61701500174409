import "./css/login.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./css/userSetting.css";
import user from "../components/img/user.png";
import Sidebar from "../components/SidebarSetting";
import axios from "axios";
axios.defaults.withCredentials = true; 

const UserSetting = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [info, setInfo]  =useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pemail, setPemail] = useState("");
  const [tel, setTel] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [profile, setProfile] = useState("");
  const imageRef=useRef();


    useEffect(() => {

      fetch("http://gw.thegmmedical.com:5003/api/userinfo/", {
          method: "get", // 통신방법
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        })
        .then((res) => res.json())
        .then((json) => {
          
          setInfo(json[0]);
          setName(json[0].name);
          setEmail(json[0].email);
          setPemail(json[0].personal_email);
          setTel(json[0].tel);
          setYear(json[0].birthyear);
          setMonth(json[0].birthmonth);
          setDay(json[0].birthday);
          setProfile(json[0].profile_link);
        })
        .catch(() => {});
      
      if (!selectedFile) {
          setPreview(undefined)
          return
      }

      const objectUrl = URL.createObjectURL(selectedFile)
      setPreview(objectUrl)

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);
    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0]);
    }
    function deletepic() {
      console.log(selectedFile);
      setSelectedFile();
      setPreview();
      setProfile("");
      imageRef.current.src = user;
    }

    const onChangeName = (e) => {
    	setName(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeEmail = (e) => {
    	setEmail(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangePemail = (e) => {
    	setPemail(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeTel = (e) => {
    	setTel(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeYear = (e) => {
    	setYear(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeMonth = (e) => {
    	setMonth(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeDay = (e) => {
    	setDay(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }

    const updateuser = () =>  {
      
        const formData = new FormData();
   
 

        formData.append(`name`, name);
        formData.append(`email`, email);
        formData.append(`personal_email`, pemail);
        formData.append(`tel`, tel);
        formData.append(`year`, year);
        formData.append(`month`, month);
        formData.append(`day`, day);
        formData.append(`profile_link`, profile);
        formData.append(`file`, selectedFile);
    
        const fetch = async () => {
          await axios
            .post("http://gw.thegmmedical.com:5003/api/updateuser", formData, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({ data }) => {
              console.log("what is this");
              
              //console.log("...." + data);
            });
        };
        fetch();
    }
    
  return (
    <div className="wrap">
        <Sidebar></Sidebar>
        <div className="user_info setting_wrap">
        <h2 id="title" class="screen_out">기본설정</h2>
        <table style={{border:"1px solid #ddd"}}>
            <tr>
                <td>사진</td>
                <td>

<div style={{width:"160px"}}>  
<label htmlFor="photo-upload" className="custom-file-upload">

{selectedFile 
? <div className="img-wrap img-upload"><img src={preview} alt="preview" ref={imageRef}/>
<button onClick={()=>deletepic()}>삭제</button>
</div>
: profile !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={profile} alt="profile_pic" ref={imageRef}/>
<button onClick={()=>deletepic()}>삭제</button>
</div>
: <img for="photo-upload" src={user} alt="profile_pic"/>
}

<input id="photo-upload" type="file" onChange={onSelectFile}/> 
</label>
</div>      
</td>
            </tr>
<tr>
<td>이름</td>
<td><input type="text" value={name} onChange={onChangeName}></input></td>
</tr>
<tr>
<td>이메일</td>
<td><input type="text" value={email} onChange={onChangeEmail}></input></td>
</tr>

<tr>
<td>외부메일</td>
<td><input type="text" value={pemail} onChange={onChangePemail}></input></td>
</tr>
<tr>
<td>전화번호</td>
<td><input type="text" value={tel} onChange={onChangeTel}></input></td>
</tr>

<tr>
<td>부서</td>
<td>IT개발본부 <span>(관리자에 의해 변경될 수 있습니다.)</span></td>
</tr>

<tr>
<td>직위</td>
<td>사원 <span>(관리자에 의해 변경될 수 있습니다.)</span></td>
</tr> 
<tr>
<td>생년월일</td>
<td className="bod">
<input type="number" placeholder="년(4자)" value={year} onChange={onChangeYear}/>                
                <select value={month} onChange={onChangeMonth}>
                    <option value="">월</option>
                    <option value="01">1월</option>
                    <option value="02">2월</option>
                    <option value="03">3월</option>
                    <option value="04">4월</option>
                    <option value="05">5월</option>
                    <option value="06">6월</option>
                    <option value="07">7월</option>
                    <option value="08">8월</option>
                    <option value="09">9월</option>
                    <option value="10">10월</option>
                    <option value="11">11월</option>
                    <option value="12">12월</option>
                </select>
                <input type="number" placeholder="일" value={day} onChange={onChangeDay}/>


</td>
</tr> 
        </table>
        <div style={{width:"40%", minWidth:"1000px", position:"relative"}}>
          <button className="submit" 
          style={{ width:"80px",position:"absolute", top:"30px",left:"0", right:"0", margin:"auto"}}
          onClick={()=>updateuser()}>저장</button>
       </div>
      </div>
    </div>
  );
};

export default UserSetting;
