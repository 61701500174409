import { NavLink, Link,useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import './css/view.css';
import LineListAdd from './LineListAdd';

import DocList from "../components/side/DocList";
import AutoComplete from "../components/AutoComplete";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { Sheet, Op, Selection, colors } from "@fortune-sheet/core";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import initial from "./data/cell";
import ExcelJS from 'exceljs'
import { VscTrash } from "react-icons/vsc";
import * as XLSX from "xlsx";
import { utils, writeFile } from "xlsx";
import { GiHamburgerMenu } from "react-icons/gi";
import { useStateWithCallbackLazy } from 'use-state-with-callback';


import LeaveView from "../components/LeaveView";
import OTView from "../components/OTView";
import CancelLeaveView from "../components/CancelLeaveView";


import Order from "./forms/Order";
import Confirm from "./forms/Confirm";
import Request from "./forms/Request";
import Repair from "./forms/Repair";
import Rent from "./forms/Rent";
import Service from "./forms/Service";
import Endoscope from "./forms/Endoscope";
import Inspect from "./forms/Inspect";
import Acquire from "./forms/Acquire";
import HR from "./forms/HR";
import Purchase from "./forms/Purchase";


import LogiOrder from "./logistics/Order";

import Expenditure from "./common/Expenditure";
import Receipt from "./common/Receipt";

import LuckyExcel from "luckyexcel";


let luckysheet;
axios.defaults.withCredentials = true; 
const initialValues = {
  subject: "",
  form_name: "",
  form_group:"",
  in_use: "",
  cc: "",
  desc:"",
  recipient:"",
  expire:"1",
  security:"1",
 
};


const Form = () => {

  const luckyCss = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    height: '100%',
    left: '0px',
}


  const location = useLocation();
  const [values, setValues] = useState(initialValues);
  const [forms, setForms] = useState([]);
  
  const [selectForm, setSelectForm] = useState([]);

  const [form, setForm] = useStateWithCallbackLazy([]);
  const [form1, setForm1]= useState(false);
  const [form2, setForm2]= useState(false);


  const [jsonData, setJsonData] = useState([]);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [test, setTest]= useState();
  const [sidebar, setSidebar] = useState(false);
  const [__html, setHtml] = useState("");
  const [aa, setAA]= useState();
  const [, updateState] = React.useState();
  const [divv, setDivv] = useState();
  const [lineList, setLineList] = useState([]);
  const [lineName, setLineName] = useState("");
  const [lineNo, setLineNo] = useState(0);
  //const [shData, setShData]= useState();
  const [dataaa, setDataaa] = useState();
  const [inUse, setInUse] = useState("");
  const [gender, setGender] = useState(1);
  var shData=[];
  const navigate = useNavigate();
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  window.addEventListener('message', (event) => {
    if (event.data["line_no"] !== undefined){
      var line_no = event.data["line_no"];
      var line_name = event.data["line_name"];
      var line_list = event.data["line_list"];
      setLineNo(line_no);
      setLineName(line_name);
      setLineList(line_list);
    }
   

   
  });

  function nextChar(c) {
    var u = c.toUpperCase();
    if (same(u,'Z')){
        var txt = '';
        var i = u.length;
        while (i--) {
            txt += 'A';
        }
        return (txt+'A');
    } else {
        var p = "";
        var q = "";
        if(u.length > 1){
            p = u.substring(0, u.length - 1);
            q = String.fromCharCode(p.slice(-1).charCodeAt(0));
        }
        var l = u.slice(-1).charCodeAt(0);
        var z = nextLetter(l);
        if(z==='A'){
            return p.slice(0,-1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
        } else {
            return p + z;
        }
    }
}


function nextLetter(l){
    if(l<90){
        return String.fromCharCode(l + 1);
    }
    else{
        return 'A';
    }
}


function same(str,char){
    var i = str.length;
    while (i--) {
        if (str[i]!==char){
            return false;
        }
    }
    return true;
}







  useEffect(()=>{
    console.log("???????????????????????", location.state.form_no); 
    if(location.state.form_no != "0"){
      
      axios.get('https://gw.thegmmedical.com:5003/api/getform/'+location.state.form_no)
    .then(function (response){
      if (response.data[0].form_no === 1){
        console.log("1입니다");
        setDivv(<LeaveView docNo = {response.data[0].doc_no}/>)
      }else if (response.data[0].form_no === 2){
        setDivv(<OTView docNo = {response.data[0].doc_no}/>)
      }else if (response.data[0].form_no === 3){
        setDivv(<CancelLeaveView docNo = {response.data[0].doc_no}/>)
      }

      setInUse(response.data[0].in_use);
      axios.get('https://gw.thegmmedical.com:5003/api/getallforms')
      .then(function (res){
      setForms(res.data);
      var value ;
      for (let i=0;i<res.data.length;i++){
        if (res.data[i].fgroup_no == response.data[0].form_group){
          value = res.data[i].fgroup_no;
          
        }
      }
      axios.get('https://gw.thegmmedical.com:5003/api/getformlist/'+value,).then(function (response){
        setSelectForm(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      //setLine(response.data[1]);
      
      
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
      
      values.form_no = response.data[0].form_no;
      values.form_group = response.data[0].form_group;
      
      console.log("setForm,,,, ", response.data[0]);
      setForm(response.data[0], () => {
if (response.data[0].type==="excel"){
      if (response.data[0].form_data !== null && response.data[0].form_data !== "undefined" && response.data[0].form_data !==""){
      /*var excel_data = JSON.parse(response.data[0].form_data);
      setDivv(<div style={{width:"100%"}} className="form_content"><Workbook data={[excel_data]} resized={()=>console.log()} onChange={(data)=>{console.log("changed, ", data); setDataaa(data)}} /></div>) ;
*/

let content = JSON.parse(response.data[0].form_data);
const loadLuckysheet = async () => {
  try {
    // Load Luckysheet dynamically
    const luckysheetModule = await import('luckysheet');
    console.log("luckysheetModule", );
    content[0].showGridLines="0";


    luckysheet = luckysheetModule.default;

    luckysheet.create({
      container: "luckysheet",
      title: 'Luckysheet sphinx Demo', // set the name of the table
      data: content,
      showinfobar: false,
      showtoolbar:false,
  })
   


    // Set the loaded state
  } catch (error) {
    console.error('Error loading Luckysheet:', error);
  }
};
loadLuckysheet();

      }else{
        let sample = [{
          "name": "Sheet1",
          "config": {},
          "index": "1",
          "status": "1",
          "order": "0",
          "luckysheet_select_save": [{
              "row": [0, 0],
              "column": [0, 0],
              "row_focus": 0,
              "column_focus": 0,
              "left": 0,
              "width": 72,
              "top": 0,
              "height": 23,
              "left_move": 0,
              "width_move": 72,
              "top_move": 0,
              "height_move": 23
          }],
          "zoomRatio": 1,
          "showGridLines": "1",
          "defaultColWidth": 72,
          "defaultRowHeight": 23,
          "celldata": [],
          "calcChain": [],
          "jfgird_select_save": [],
          "data": [
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
              [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
          ],
          "visibledatarow": [24, 48, 72, 96, 120, 144, 168, 192, 216, 240, 264, 288, 312, 336, 360, 384, 408, 432, 456, 480, 504, 528, 552, 576, 600, 624, 648, 672, 696, 720, 744, 768, 792, 816, 840, 864, 888, 912, 936, 960, 984, 1008, 1032, 1056, 1080, 1104, 1128, 1152, 1176, 1200, 1224, 1248, 1272, 1296, 1320, 1344, 1368, 1392, 1416, 1440, 1464, 1488, 1512, 1536, 1560, 1584, 1608, 1632, 1656, 1680, 1704, 1728, 1752, 1776, 1800, 1824, 1848, 1872, 1896, 1920, 1944, 1968, 1992, 2016],
          "visibledatacolumn": [73, 146, 219, 292, 365, 438, 511, 584, 657, 730, 803, 876, 949, 1022, 1095, 1168, 1241, 1314, 1387, 1460, 1533, 1606, 1679, 1752, 1825, 1898, 1971, 2044, 2117, 2190, 2263, 2336, 2409, 2482, 2555, 2628, 2701, 2774, 2847, 2920, 2993, 3066, 3139, 3212, 3285, 3358, 3431, 3504, 3577, 3650, 3723, 3796, 3869, 3942, 4015, 4088, 4161, 4234, 4307, 4380],
          "ch_width": 4500,
          "rh_height": 2096,
          "luckysheet_selection_range": []
      }];
       
      const loadLuckysheet = async () => {
        try {
          // Load Luckysheet dynamically
          const luckysheetModule = await import('luckysheet');
          console.log("luckysheetModule", luckysheetModule);
          luckysheet = luckysheetModule.default;
   
          luckysheet.create({
            container: "luckysheet",     
            title: 'Luckysheet sphinx Demo', // set the name of the table
            data: sample,
            showinfobar: false,
            showtoolbar:false
        })
         
   
   
          // Set the loaded state
        } catch (error) {
          console.error('Error loading Luckysheet:', error);
        }
      };
      loadLuckysheet();


      }
    }
    });
      let cc = JSON.parse(response.data[0].cc);
      let rec = JSON.parse(response.data[0].recipient);
      setCcs(cc);
      setRecs(rec);

      
      const tmp = {
        subject: response.data[0].subject,
        form_name: response.data[0].form_name,
        desc:response.data[0].form_desc,
        form_group:response.data[0].form_group,
        form_no:response.data[0].form_no,
        cc: cc,
        recipient:rec,
        admin:admin,
        expire:response.data[0].expire,
        security:response.data[0].security,
        group_name:response.data[0].fgroup_name,

      };
      console.log("tmptmptmptmp", tmp);
      setValues(tmp);
      axios.get('https://gw.thegmmedical.com:5003/api/getformline/'+response.data[0].line_no)
      .then(function (response){
        setLineList(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
      });
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    });

      if (location.state.form_no == "1"){
        setForm1(true);
      }else{
        setForm1(false);
      }
    }else{
        axios.get('https://gw.thegmmedical.com:5003/api/getallforms')
      .then(function (response){
      setForms(response.data);
      
      //setLine(response.data[1]);
      
      
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });

    
    }
    }, []);

    function delLine(){
      setLineList([]);
      setLineName("");
      setLineNo();
    }
  
      const itscell2 = {
        name: "Sheet1",
        config: {
          
         
          
          columnlen: {
            "0": 131,
            "2": 131,
            "3": 131,
            "4": 131,
            "5": 131,
            "6": 131,
            "7": 131,
            "8": 131,
         
          },
          
       
        },
        id: "0",
        zoomRatio: 1,
        order: "0",
        status: 1,
        celldata:[],
        luckysheet_select_save: [
          {
            left: 741,
            top: 796,
            height: 19,
            left_move: 741,
            width_move: 138,
            top_move: 796,
            height_move: 19,
            row: [33, 33],
            column: [6, 6],
            row_focus: 33,
            column_focus: 6
          }
        ],
        scrollLeft: 0,
        scrollTop: 0
      };
  const handleRegisterButton = () => {
   
    const formData = new FormData();
   
    
    formData.append(`desc`, values.desc);
    formData.append(`line_no`, lineNo);

    formData.append(`subject`, values.subject);
    formData.append(`form_name`, values.form_name);
    formData.append(`form_group`, values.form_group);
  
    formData.append(`in_use`, inUse);
    formData.append(`cc`, JSON.stringify(ccs));
    formData.append(`admin`, JSON.stringify(admins));
    formData.append(`recipient`, JSON.stringify(recs));
    formData.append(`expire`, values.expire);
    formData.append(`security`, values.security);
    
    if (luckysheet !==undefined){
      let sheet = luckysheet.getAllSheets();
      console.log("sheet", sheet);
      formData.append(`form_data`, JSON.stringify(sheet));
      
    }
   
    formData.append(`form_no`, location.state.form_no);

    //console.log(values, test);
    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/formedit", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this??");
          navigate('/form', { state: {form_no:location.state.form_no} });          //console.log("...." + data);
        });
    };

    fetch();
   



  };

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
   
    const { name, value } = e.target;
    console.log(name, value);
    setValues({
      ...values,
      [name]: value,
    });
  };

  

  const [admin, setAdmin]=useState("");
  const [admins, setAdmins]=useState([]);
  

  const generateId_ad = () => {
    const highestId = Math.max.apply(Math, admins.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_ad() {
    if (admin !== '') {
      const tempStr = admin.substring(admin.indexOf("<")+1, admin.indexOf(">"));
      const item = { id: generateId_ad(), text: admin, email : tempStr  };
      console.log("item...", item);
      var valueArr = admins.map(function(item){ return item.text });
      if (valueArr.includes(admin)){
        return false;
      }
      else{
        const tempArray = [...admins, item];
        setAdmins(tempArray);
      }

      
    }
    setAdmin('');
  }

  function addAdmins() {
    console.log(admin);
    createNewToDoItem_ad();
  }
  const deleteItem_ad = id => {
    setAdmins(admins.filter(item => item.id !== id));
  };


  const display_admin = admins.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_ad(item.id)}>&times;</span>

    </div>
  ));


  
  const [rec, setRec]=useState("");
  const [recs, setRecs]=useState([]);
  

  const generateId_rec = () => {
    const highestId = Math.max.apply(Math, recs.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_rec() {
    if (rec !== '') {
      const tempStr = rec.substring(rec.indexOf("<")+1, rec.indexOf(">"));
      const item = { id: generateId_rec(), text: rec, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = recs.map(function(item){ return item.text });
      if (valueArr.includes(rec)){
        return false;
      }
      else{
        const tempArray = [...recs, item];
        setRecs(tempArray);
      }

      
    }
    setRec('');
  }

  function addRecs() {
    console.log(rec);
    createNewToDoItem_rec();
  }
  const deleteItem_rec = id => {
    setRecs(recs.filter(item => item.id !== id));
  };


  const display_rec = recs.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>&times;</span>

    </div>
  ));

  const [cc, setCc]=useState("");
  const [ccs, setCcs]=useState([]);
  

  const generateId_cc = () => {
    const highestId = Math.max.apply(Math, ccs.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_cc() {
    if (cc !== '') {
      const tempStr = cc.substring(cc.indexOf("<")+1, cc.indexOf(">"));
      const item = { id: generateId_cc(), text: cc, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = ccs.map(function(item){ return item.text });
      if (valueArr.includes(cc)){
        return false;
      }
      else{
        const tempArray = [...ccs, item];
        setCcs(tempArray);
      }

      
    }
    setCc('');
  }

  function addCcs() {
    console.log(rec);
    createNewToDoItem_cc();
  }
  const deleteItem_cc= id => {
    setCcs(ccs.filter(item => item.id !== id));
  };


  const display_cc = ccs.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_cc(item.id)}>&times;</span>

    </div>
  ));

  const itscell = {
    name: "Sheet1",
    config: {
      
     
      
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
     
      },
      
   
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 68,
    status: 1,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    /*calcChain: [
      {
        r: 0,
        c: 3,
        id: "0",
        func: [true, 3, "=Formula!A1+Formula!B1"],
        color: "w",
        parent: null,
        chidren: {},
        times: 0
      }
    ],*/
    scrollLeft: 0,
    scrollTop: 0
  };
////////////////////////////////////////////////
// EXCEL IMPORT
////////////////////////////////////////////////
  async function handleFileAsync(e) {
    setDivv("");
    
    const wb = new ExcelJS.Workbook()
          
          const file = e.target.files[0];
    const exceldata = await file.arrayBuffer();
          wb.xlsx.load(exceldata)
          .then(wb => {
             //console.log(workbook, 'workbook instance');
  
              wb.eachSheet((sheet, id) => {
                 sheet.eachRow((row, rowIndex) => {
                 //console.log(row);
                 });
              });
              const ws = wb.worksheets[0] // 첫 번째 sheet 선택
      
    const options = { includeEmpty: true }
   
     ws.eachRow(options, (row, rowNum) => {
      shData[rowNum-1] = []
      row.eachCell(options, (cell, cellNum) => {
        shData[rowNum-1][cellNum-1] = { value:cell.value, style:cell.style }
      
      
      })
    })
    //console.log("sheeeeeeeeet", sheetData);
    //setShData(sheetData);
    /* data is an ArrayBuffer */
    const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});
  
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
      setHtml(htmlData);
      //console.log(htmlData);
  
    const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
      header:1,
      defval:"",
    })
    setJsonData(jsondata);
    
    var sheetnames = workbook.SheetNames;
    
  
    
    
    
    
    
    
    
    setCols(jsondata[0]);
    setRows(jsondata[1]);
    var cellData=[];
  
  
  
  console.log(workbook);
  
  
  var merges= workbook.Sheets[sheetnames[0]]["!merges"];
  const merge_config={};
  for (let i=0;i<merges.length;i++){
    var title = merges[i]['s']['r']+"_"+merges[i]['s']['c'];
    var rowsize = merges[i]['e']['r']-merges[i]['s']['r'];
    var col = merges[i]['e']['c']-merges[i]['s']['c'];
    var merge={
        r: merges[i]['s']['r'],
        c: merges[i]['s']['c'],
        rs: rowsize+1,
        cs: col+1
    }
    merge_config[title]=merge;
  
  }
  
  
  var rows= workbook.Sheets[sheetnames[0]]["!rows"];
  const rowht={};
  for (let i=0;i<rows.length;i++){
    if (rows[i]!==undefined){
      var row=rows[i]["hpx"];
    rowht[i]=row;
    }
  
    /**/
  
  }
  
  var cols= workbook.Sheets[sheetnames[0]]["!cols"];
  const colht={};
  for (let i=0;i<jsondata[0].length;i++){
    console.log(cols[i]);
    if (cols[i]!==undefined){
      var coll=cols[i]["wpx"];
      colht[i]=coll;
    }
  
    /**/
  
  }
  itscell.config.columnlen = colht;
  
  itscell.config.rowlen = rowht;
  itscell.config.merge = merge_config;
  
  
  var calccchain=[];
  var borderinfo=[];
  for (let i = 0;i<jsondata.length;i++){
    var alphabet = "A";    
    for (let j=0;j<jsondata[i].length;j++){
      var styleData; var ht=1; var vt=1; var fs = 11;
      var fa = "General";


      if (shData[i][j]!==undefined){
       
        if (i===4 && j===1){
          console.log(i, ":", j, "   ", shData[i][j]);


        }


        styleData = shData[i][j].style;
       
        if (styleData.font!==undefined){
         // console.log("styleData", styleData.font.color);


        }
       
        if (styleData.numFmt !==undefined){
          fa = styleData.numFmt;
        }
        if (styleData.alignment!==undefined){


          switch (styleData.alignment.horizontal) {
            case "center":
              ht = 0;
              break;
            case "left":
              ht = 1;
              break;
            case "right":
              ht = 2;
              break;
     
            default:
              ht = 1;
   
          }
          switch (styleData.alignment.vertical) {
            case "middle":
              vt = 0;
              break;
            case "up":
              vt = 1;
              break;
            case "down":
              vt = 2;
              break;
     
            default:
              vt = 1;
   
          }
        }
     
        if (styleData.style!==undefined){
          fs = styleData.font.size;
        }
       
        var border = styleData.border;
       
        var font = styleData.font;


       
        var bd = {
          rangeType: "cell",
          value: {
            row_index:i,
            col_index:j,
           
          }
        };


        if (border!==undefined){
          if (border.left!==undefined){
            var left = {
              //style: styleData.border.left.style,
              //color: "#000"
              style: 1,
              color: "rgb(0, 0, 0)"
            }
            bd.value.l = left;
          }
          if (border.right!==undefined){
            var right = {
              style: 1,
              color: "rgb(0, 0, 0)"
            }
            bd.value.r = right;
          }
          if (border.top!==undefined){
            var top = {
              style: 1,
              color: "rgb(0, 0, 0)"
            }
            bd.value.t = top;
          }
          if (border.bottom!==undefined){
            var bottom = {
              style: 1,
              color: "rgb(0, 0, 0)"
            }
            bd.value.b = bottom;
          }
        }
       
        if (border!==undefined && Object.keys(border).length !== 0){
          borderinfo.push(bd);
        }
       
       
       
       
      }
     
     
      var bgColor = "#ffffff";
     
      var func = null;
      var mcval = null;
      var num = i+1;
      var tmp = alphabet + num.toString();
      var w="";
      var v="";


      if (workbook.Sheets[sheetnames[0]][tmp]!==undefined){
        var cellst = workbook.Sheets[sheetnames[0]][tmp]["s"]["patternType"];
        w = workbook.Sheets[sheetnames[0]][tmp]["w"];
        v= workbook.Sheets[sheetnames[0]][tmp]["v"];
        if (cellst !== "none"){
       
          var fgcolor = workbook.Sheets[sheetnames[0]][tmp]["s"]["fgColor"];


          if (fgcolor.theme!==undefined){
           
            if (fgcolor.theme==0 && fgcolor.tint!==undefined){
              bgColor = "rgb(0,0,0,"+Math.abs((fgcolor.tint))+")";
              console.log("bgColor..........", bgColor, fgcolor.tint, parseInt(fgcolor.tint));
            }
          }
          if (fgcolor.rgb!==undefined){
            console.log(fgcolor.rgb, styleData, workbook.Sheets[sheetnames[0]][tmp]["s"]);


            bgColor = "#"+fgcolor.rgb;


          }
        }
        if (workbook.Sheets[sheetnames[0]][tmp]["f"]!==undefined){
          func=workbook.Sheets[sheetnames[0]][tmp]["f"];
          func = "="+func;
          var fff = {
            r: i,
            c: j,
            id: "0",
            func:[true, i, func],
            color:"w",
            parent:null,
            children:{},
            times:0
           
          };
          calccchain.push(fff);
        }
      }
      var tt = (i+"_"+j);
      if (merge_config[tt]!==undefined){
        mcval = merge_config[tt];
      }
     
      let bl=0;
      let fc = "rgb(51,51,51)";
      if (font !==undefined){


        if (font.size!==undefined){
          fs = font.size;
        }
        if (font.bold !==undefined){
          bl=1;
        }
        if (font.color!==undefined){


          if (font.color.theme=== 0){
            fc="#ffffff";
          }
          if (font.color.argb !==undefined){


            fc = "#"+ font.color.argb.substring(2,font.color.argb.length);
            //console.log("i:",i," j:", j, "   ", font.color.argb, fc);
          }
        }
       
      }
      //console.log("row: ", i, " col: ", j , jsondata[i][j]);
      var rowss = {
        r: i,
        c: j,
        v: {
          ct: { fa: fa, t: "n" },
          v : v,
          bg: bgColor,
          //bg : 'rgb(0,0,0,0.24)',
          m: w,
          bl:bl,
          it:0,
          ff:0,
          fs:fs,
          fc:fc,
          ht:ht,
          vt:vt,
        }
      };
      if (func !==null){
        rowss.v.f = "="+workbook.Sheets[sheetnames[0]][tmp]["f"];
      }
     
      if (mcval!==null){
        rowss.v.mc = mcval;
      }
      cellData.push(rowss);
      alphabet = nextChar(alphabet);

    }
   
  }


    itscell.config.borderInfo =borderinfo; 
    itscell.calcChain =calccchain; 
    itscell.celldata =cellData; 
    setTest(itscell);
    setAA(itscell);
    console.log("result -> ", itscell);
    setDivv(<div style={{width:"100%"}} className="form_content"><Workbook onOp={console.log} data={[itscell]} onChange={(data)=>{console.log("changed, ", data); setDataaa(data)}} /></div>) ;
    //forceUpdate();
  
  
  });
    
    
    
   
  }

  
////////////////////////////////////////////////
// EXCEL IMPORT
////////////////////////////////////////////////
  



const [editLine, setEditLine] = useState(false);


const toggleLine = () => {
setEditLine(!editLine);
};


return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DocList/>
        </div>
      )}
   
<div className="content-wrap">
    
      
{editLine ? (
  <LineListAdd data = {lineList} lineName={lineName} lineNo={lineNo} closePopup={() => toggleLine()} onChange={(value)=>{
    var line_no = value.line_no;
    var line_name = value.line_name;
    var line_list = value.line_list;
    
    setLineNo(line_no);
    setLineName(line_name);
    setLineList(line_list);
    setEditLine(false);
  }}/>
) : null}

    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
      <div className="side-menu">
        <DocList/>
      </div>
    

    <div className="main_content">
  
    

    <div className="btns" style={{display:"flex", alignItems:"start", 
    flexDirection:"column", width:"100%", margin:"0 auto"}}>
            
      <h4>결재 양식 수정 </h4>
          <div style={{background:"#fff"}}>
          {lineList.length >0  && (
            <div style={{display:"flex"}} className="line">
                {lineList.map((element, index)=> (
                  <table>
                    <tr className="name"><td rowSpan={3} className="type">{element.type}</td><td>{element.job_name}</td></tr>
                    <tr className="name"><td>{element.d_name}</td></tr>
                    <tr className=""><td>{element.name}</td></tr>
                    <tr></tr>
                  </table>
                ))}
            </div>
          )}
          </div>
        </div>
          <div style={{width:"100%"}}>
          <div className="button_wrap">
    <button onClick={handleRegisterButton}>등록</button>
    
    </div>
          <table className="form_write" style={{margin:"10px 0", width:"100%"}}>

      {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
      
      


<tr>


  <td class="table_title2" nowrap="">
  문서양식명
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <input type="text" name="form_name" className="formInput" value={form.form_name}
          onChange={handleInputChange}></input> 
  </div>
  </td>

</tr>

 

  <tr>
    <td class="table_title2" nowrap="">
      양식분류
    </td>
    <td class="table_list2_left">
      <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
        <select name="form_group" className="formInput" value={values.form_group || ''} onChange={handleInputChange}>    
          {forms.map((element, index)=>(
            <option value={element.fgroup_no}>{element.fgroup_name}</option>
          ))}
        </select>
      </div>
    </td>


    <Tooltip
      id="date_guide"
      place="bottom"
    >
      <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
        <span>양식의 상태를 설정합니다.</span>
      </div>
    </Tooltip>

    <td class="table_title2 pc" nowrap="">
      사용여부<BiInfoCircle data-tooltip-id="date_guide" style={{marginLeft:"5px"}}/>
    </td>

    <td class="table_list2_left pc">
    
      {inUse!=="" && (
 <div className="input-group radio" style={{display:"flex"}}>
      <input type="radio" id="use" value="use" name="in_use" defaultChecked = {inUse==="use"} onChange={(e)=>setInUse("use")}/><label htmlFor="use"><span>사용</span>
    </label>
   
      <input type="radio" id="no" value="no" name="in_use" defaultChecked = {inUse==="no"} onChange={(e)=>setInUse("no")}/> <label htmlFor="no"><span>사용안함</span>
    </label>
</div>
      )}
      
 


    </td>

  </tr>

  <tr className="mobile">

    <td class="table_title2" nowrap="">
      사용여부<BiInfoCircle data-tooltip-id="date_guide" style={{marginLeft:"5px"}}/>
    </td>
    <td class="table_list2_left">
    {inUse!=="" && (
 <div className="input-group radio" style={{display:"flex"}}>
      <input type="radio" id="use" value="use" name="in_use" defaultChecked = {inUse==="use"} onChange={(e)=>setInUse("use")}/><label htmlFor="use"><span>사용</span>
    </label>
   
      <input type="radio" id="no" value="no" name="in_use" defaultChecked = {inUse==="no"} onChange={(e)=>setInUse("no")}/> <label htmlFor="no"><span>사용안함</span>
    </label>
</div>
      )}
    </td>

  </tr>

  <tr>
    <td class="table_title2" nowrap="">
      문서설명
    </td>
    <td class="table_list2_left">
      <div className="input-group">
        <input type="text" name="desc" className="formInput" placeholder="설명을 입력하세요." value={values.desc} onChange={handleInputChange}></input>
      </div>
    </td>
  </tr>

  <tr>
    <td class="table_title2" nowrap="">
      초기 결재라인
    </td>
    <td class="table_list2_left">
      <div className="input-group">
        <span>{lineName} </span> &nbsp;&nbsp; 
        {lineName !=="" && (
          <button className="trash" onClick={()=>delLine()}>삭제</button>
        )}
        <button onClick={() => toggleLine()} alt=''>결재라인 선택</button>  
      </div>
    </td>
  </tr>

  <tr>
    <Tooltip id="cc" place="bottom">
      <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
        <span>문서참조자는 결재문서와 결재상태를 확인할 수 있습니다.</span>
      </div>
    </Tooltip>

    <td class="table_title2" nowrap="">
      문서참조자<BiInfoCircle data-tooltip-id="cc" style={{marginLeft:"5px"}}/>
    </td>
    <td class="table_list2_left" >
      <div className="input-group">
        <div style={{display:"flex"}}>
          <AutoComplete onChange={(value) => {setCc(value); console.log("hihi", value)}}/>
          <button onClick={addCcs} style={{marginLeft:"10px"}}>추가</button>
        </div>
      </div>
      {ccs.length >0 &&(
        <div class="admin_list" style={{flexGrow:"0"}}>
          <ul>
            {display_cc}
          </ul>
        </div>
      )}
    </td> 
  </tr>

  <tr>
    <td class="table_title2" nowrap="">
      문서수신자
    </td>
    <td class="table_list2_left">
      <div className="input-group">
        <div style={{display:"flex"}}>
          <AutoComplete onChange={(value) => {setRec(value); console.log("hihi", value)}}/>
          <button onClick={addRecs} style={{marginLeft:"10px"}}>추가</button>
        </div>
      </div>
      {recs.length >0 &&(

        <div class="admin_list">
          <ul>
            {display_rec}
          </ul>
        </div>
      )}
    </td>
  </tr>



  <tr>

    <td class="table_title2" nowrap="">
      운영자
    </td>
    <td class="table_list2_left">
      <div className="input-group">
        <div style={{display:"flex"}}>
          <AutoComplete onChange={(value) => {setAdmin(value); console.log("hihi", value)}}/>
          <button onClick={addAdmins} style={{marginLeft:"10px"}}>추가</button>
        </div>
      </div>
      {admins.length >0 &&(
        <div class="admin_list">
          <ul>
            {display_admin}
          </ul>
        </div> 
      )}
    </td>
  </tr>
</table>


<div className="form_content" style={{width:"100%",position:"relative"}}>

{form.form_no ==1 &&(
  <LeaveView doc_no={values.doc_no}/>
)}
{form.form_no ==2 &&(
  <OTView />
)}  


{form.type ==="custom" &&(
 <div className="custom_form custom_form_view" style={{display:"flex", flexDirection:"column"}}>
  
  {(form.type ==="custom"  && form.custom_name==="order") && (
   <Order data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)}
 
  {(form.type ==="custom"  && form.custom_name==="confirm") && (
    <Confirm data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  {(form.type ==="custom"  && form.custom_name==="request") && (
    <Request data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  {(form.type ==="custom"  && form.custom_name==="repair") && (
    <Repair data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  
  {(form.type ==="custom"  && form.custom_name==="rent") && (
    <Rent data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  {(form.type ==="custom"  && form.custom_name==="service") && (
    <Service data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>
  )}
  
  {(form.type ==="custom"  && form.custom_name==="endoscope") && (
    <Endoscope data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  
  {(form.type ==="custom"  && form.custom_name==="inspect") && (
    <Inspect data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  {(form.type ==="custom"  && form.custom_name==="acquire") && (
    <Acquire data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  {(form.type ==="custom"  && form.custom_name==="hr") && (
    <HR data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  {(form.type ==="custom"  && form.custom_name==="purchase") && (
    <Purchase data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  {(form.type ==="custom"  && form.custom_name==="logi_order") && (
    <LogiOrder data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  {(form.type ==="custom"  && form.custom_name==="expenditure") && (
    <Expenditure data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }
  {(form.type ==="custom"  && form.custom_name==="receipt") && (
    <Receipt data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
  }

  </div>
)}
  {form.type ==="html" && (
    <div className="custom_form custom_form_view" style={{ height:"600px", overflow:"hidden", position:"relative" }}>{divv}
      
     
    </div>
  )}  

{form.type ==="excel" && (
   <div style={{width:"100%", height:"600px"}}>
   <input type={"file"} onChange={(event) => {
     const files = event.target.files
     LuckyExcel.transformExcelToLucky(files[0], function(exportJson, luckysheetfile){
         console.log("export json,.,.", exportJson.sheets[0]);
         let sh = exportJson.sheets[0].config.borderInfo;
   
         for (let i=1;i<sh.length;i++){
           let r = sh[i].value.row_index;
           let c = sh[i].value.col_index;
   
           let prevR = sh[i-1].value.row_index;
           let prevC = sh[i-1].value.col_index;
   
           if (r === prevR){
             if (sh[i].value.t!==undefined && sh[i-1].value.t === undefined){
               delete sh[i].value.t;
             }else if (sh[i].value.t!==undefined && sh[i-1].value.t === undefined){
               delete sh[i-1].value.t;
   
             }
           }
         }
   
         exportJson.sheets[0].config.borderInfo = sh;
   
   
   
         if(exportJson.sheets==null || exportJson.sheets.length===0){
             alert("Failed to read the content of the excel file, currently does not support xls files!");
             return;
         }
         luckysheet.destroy();
   
         luckysheet.create({
             container: 'luckysheet', //luckysheet is the container id
             showinfobar:false,
             showsheetbar:false,
             data:exportJson.sheets,
             title:exportJson.info.name,
             userInfo:exportJson.info.name.creator
         });
     });
   }}/>
   <div
     id="luckysheet"
     style={luckyCss}
   />
   
   </div>
  )}  



</div>  

</div>

</div>

</div>
</div>
</div>


  );
};

export default Form;
