import "./css/login.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./css/userSetting.css";
import user from "../components/img/user.png";
import Sidebar from "../components/SidebarSetting";
import axios from "axios";
import sign from "./img/user.png";

axios.defaults.withCredentials = true; 

const UserSetting = () => {
  const [pw, setPw] = useState("");
  const [subject, setSubject]=useState("");
  const [boxes, setBoxes]=useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState("");
  const [profile, setProfile] = useState("");
  const imageRef=useRef();

  const dataFetch = useRef(false);
  const getInitialState = () => {
    const value = "Orange";
    return value;
  };
  const [start, setStart] = useState(getInitialState);
  useEffect(() => {
   
  if (!selectedFile) {
      setPreview(undefined)
      return
  }

  const objectUrl = URL.createObjectURL(selectedFile)
  setPreview(objectUrl)

  // free memory when ever this component is unmounted
  return () => URL.revokeObjectURL(objectUrl)

    

  
  }, [selectedFile]);
  const handleChange = (e) => {
    setStart(e.target.value);
  };

  const [num, setNum] = useState(getInitialState);

  const pwChange = e => {
    setPw(e.target.value)
  }
  const handleNum = (e) => {
    setNum(e.target.value);
  };
  useEffect(() => {
    
    fetch("https://gw.thegmmedical.com:5003/api/getuser/", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      if (json[0].sign_link !==null){
        setProfile(json[0].sign_link);
      }
      setPw(json[0].e_sign_pw);
      
    })
    .catch(() => {});
  
    

  
    

  }, []);
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 
 
  function submit_filter(){
  
    const data = {
      start: start,
      num : num
      

    
    }
    console.log("is it working..?");
    axios.post('https://gw.thegmmedical.com:5003/api/default_setting',data)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };
  
  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined)
        return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
}

const save_setting = () =>  {
 
    const formData = new FormData();
    

    formData.append(`password`, pw);
    formData.append('sign_link', profile);
    formData.append(`file`, selectedFile);

    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/docsetting", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          alert("저장되었습니다.");
          window.location.reload(true);
        });
    };
    fetch();
}
  return (
    <div className="">
        <Sidebar></Sidebar>
        <div className="user_info setting_wrap">
        <h2 id="title" class="screen_out">기본설정</h2>
        <div className="sort-setting doc-setting absent">
            <table style={{width:"100%"}}>
            <tr style={{height:"unset"}}>
                    <thead style={{width:"auto"}}>서명 이미지</thead>
                    <td>
                    <div className="input-group">
                    {selectedFile
? <div className="img-wrap img-upload" style={{justifyContent:"center", alignContent:"center"}}>
<img src={preview} alt="preview" ref={imageRef}/>
</div>
: profile !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={profile} alt="profile_pic" ref={imageRef}/>
</div>
: <img for="photo-upload" src={sign} alt="profile_pic"/>
}

<input id="photo-upload" type="file" onChange={onSelectFile} style={{height:"32px"}}/> 
                    </div>
                    </td>
                </tr>   
            </table>
            <div className="btns" style={{width:"100%", display:"flex", justifyContent:"center"}}>
            <button className="button" onClick={()=> save_setting()} >저장</button>
            </div>
            
            
      
      </div>

       
      </div>
    </div>
  );
};

export default UserSetting;