import React, { useRef, useEffect, useState } from "react";
import axios from "axios";



const OfficeViewer = () => {
  const [url, setUrl]=useState("http%3A%2F%2Flocalhost%3A3000%2Fuploads%2Fboard%2F1680222960121__SCN_0053.pdf");
    return (
        <div className="excel"
        style={{
          width: "100%",
          height: "100%"
        }}
      >
        {url}
<iframe src={"http://view.officeapps.live.com/op/view.aspx?src=http://www.cmu.edu/blackboard/files/evaluate/tests-example.xls"}
style={{width:"100%", height:"100%"}} id="ifmOfficePreview" name="ifmOfficePreview" title="viewer"></iframe>

{/*<iframe src={"http://docs.google.com/gview?url=http://www.cmu.edu/blackboard/files/evaluate/tests-example.xls&embedded=true"}
style={{width:"100%", height:"100%"}} id="ifmOfficePreview" name="ifmOfficePreview" title="viewer"></iframe>*/}


      </div>
    );
  };
 
  export default OfficeViewer;
  