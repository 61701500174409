import React, { useState, useEffect, useRef } from "react";
import {FaRegQuestionCircle} from "react-icons/fa";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

import "./css/popup.css";
import user from "./img/user.png";
import axios from "axios";

import {FaCog, FaRegListAlt, FaClipboardList } from "react-icons/fa";
import { HiPencilAlt } from "react-icons/hi";
const Popup = ({ member, text, closePopup }) => {
  const [idChange, setIdChange] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [userNo, setUserNo] = useState("0");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [originalId, setOriginalId] = useState("");
  const [pw, setPw] = useState("");
  const [pwCheck, setPwCheck] = useState("");
  const [preview, setPreview] = useState("");
  const [profile, setProfile] = useState("");
  const [department, setDepartment] = useState([]);
  const [selectedD, setSelectedD] = useState(1);
  const [selectedJ, setSelectedJ] = useState(1);
  const [job, setJob] = useState([]);
  const [startDate, setStartDate]=useState("");
  const [birthDate, setBirthDate]=useState("");

  const [startD, setStartD]=useState("");
  const [showPopup, setPopup] = useState(false);
  const [tel, setTel]= useState("");
  const [extension, setExtension]= useState("");
  const [fax, setFax]= useState("");
  const [place, setPlace]= useState("");

  const togglePopup = () => {
    setPopup(!showPopup);
  };

  const imageRef=useRef();

  useEffect(() => {
    if (member !==undefined){
      if (member.length!==0){
      console.log("member is....", member);
      setUserNo(member.user_no);
      setName(member.name);
      setId(member.user_id);
      setOriginalId(member.user_id);
      setProfile(member.profile_link);
      setExtension(member.extension);
      setPw(member.user_pw);
      setPwCheck(member.user_pw);
      setSelectedD(member.d_no);
      setBirthDate(member.birthdate);
      setSelectedJ(member.job_no);
      setStartDate(member.start);
      setStartD(member.start);
      setTel(member.tel);
      setFax(member.fax);
      setPlace(member.place);
    }
    }
    
    fetch("http://gw.thegmmedical.com:5003/api/getdepartment/", {
          method: "get", // 통신방법
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          setDepartment(json);
        })
        .catch(() => {});
        fetch("http://gw.thegmmedical.com:5003/api/getjob/", {
          method: "get", // 통신방법
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        })
        .then((res) => res.json())
        .then((json) => {
          console.log("job is....", json);
          setJob(json);
        })
        .catch(() => {});
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)


    }, [selectedFile]);
    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }
    const onChangeName = (e) => {
    	setName(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeId = (e) => {
    	setId(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangePw = (e) => {
    	setPw(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangePwCheck = (e) => {
    	setPwCheck(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeTel = (e) => {
    	setTel(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeFax = (e) => {
    	setFax(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangePlace = (e) => {
    	setPlace(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeExtension = (e) => {
    	setExtension(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const handleChange = (e) => {
      console.log(e.target.value);
      setSelectedD(e.target.value);
    };
    const handleJobChange = (e) => {
      console.log(e.target.value);
      setSelectedJ(e.target.value);
    };


    function chkPW(password){
      var num = password.search(/[0-9]/g);
      var eng = password.search(/[a-z]/ig);
      var spe = password.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
     
      if(password.length < 8 || password.length > 20){
     
       alert("8자리 ~ 20자리 이내로 입력해주세요.");
       return false;
      }else if(password.search(/\s/) != -1){
       alert("비밀번호는 공백 없이 입력해주세요.");
       return false;
      }else if(num < 0 || eng < 0 || spe < 0 ){
       alert("영문,숫자, 특수문자를 혼합하여 입력해주세요.");
       return false;
      }else {
       console.log("통과"); 
         return true;
      }
     
     }

    const addUser = () =>  {
      if (pw !== pwCheck){
        document.getElementById('check').innerHTML='비밀번호가 일치하지 않습니다.';
        document.getElementById('check').style.color='red';
        return false;
      }else{
        document.getElementById('check').innerHTML='';
      }

      if (chkPW(pw)===false){
        return false;
      }


        const formData = new FormData();
        

        formData.append(`user_no`, userNo);
        formData.append(`idChange`, idChange);
        formData.append(`name`, name);
        formData.append(`id`, id);
        formData.append(`originalId`, originalId);
        formData.append(`d_no`, selectedD);
        formData.append(`j_no`, selectedJ);
        formData.append(`pw`, pw);
        formData.append(`profile_link`, profile);
        formData.append(`tel`, tel);
        formData.append(`extension`, extension);
        formData.append(`birthdate`, birthDate);

        formData.append(`place`, place);
        formData.append(`fax`, fax);
        console.log(startDate);
        formData.append(`start_date`, startDate);
        formData.append(`file`, selectedFile);
    
        const fetch = async () => {
          await axios
            .post("http://gw.thegmmedical.com:5003/api/adduser", formData, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({ data }) => {
              console.log("what is this");
              closePopup();
            });
        };
        fetch();
    }
  function deletepic() {
    console.log(selectedFile);
    //setSelectedFile(undefined);
    imageRef.current.src = user;
    return;
  }
  const addDepartment = () =>{

  }

  return (
    <div className="popup">
      <div className="popup_inner">
        <h3 style={{textAlign:"left", marginLeft:"20px"}}>사용자 추가/변경</h3>
        <div className="addUser">
        <div className="addpic">

        
        <label htmlFor="photo-upload" className="custom-file-upload fas">

{selectedFile
? <div className="img-wrap img-upload">
    <img src={preview} alt="preview" ref={imageRef}/>
    <button onClick={()=>deletepic()}>삭제</button>
  </div>
: profile !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={profile} alt="profile_pic" ref={imageRef}/>
  <button onClick={()=>deletepic()}>삭제</button>
</div>
: <img for="photo-upload" src={user} alt="profile_pic"/>
}

<input id="photo-upload" type="file" onChange={onSelectFile}/> 

</label>
        </div>
        
        <div className="addInfo">
          <span>※업로드 된 사진의 크기는 [150px x 150px]로 적용됩니다</span>
        <div className="user_input">
        <label htmlFor="name" className="">
        이름(한글) : 
        
        </label><input id="name" type="text" value={name} onChange={onChangeName}/> 

        </div>

        <div className="user_input">
        <label htmlFor="name" className="">
        생년월일 : 
      
        </label><input type ="date" value={birthDate} selected={birthDate} onChange={(e) => {setBirthDate(e.target.value)}}/>

        </div>


        <div className="user_input">
        <label htmlFor="name" className="">
        입사일자 : 
      
        </label><input type ="date" value={startDate} selected={startDate} onChange={(e) => {setStartDate(e.target.value)}}/>

        </div>
        <div className="user_input">
        
        <label htmlFor="id" className="">
        아이디<FaRegQuestionCircle data-tooltip-id="id_guide"/> : 
        
        </label>
        {member===undefined || member.length===0 || idChange === true ? (<input id="id" type="text" value={id} onChange={onChangeId}/> ): (
        <div style={{width:"100%", display:"flex"}}>{id} <HiPencilAlt style={{fontSize:"14px"}} onClick={()=>setIdChange(!idChange)}/> </div> )}
        <Tooltip
        id="id_guide"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>② 영문자는 반드시 1개 이상 존재해야 합니다.</span>
          </div>
        </Tooltip>
        </div>
        <div className="user_input">

        <label htmlFor="pw" className="">
        비밀번호<FaRegQuestionCircle data-tooltip-id="pw_guide"/> : 
        <Tooltip
        id="pw_guide"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left", color:"white"}}>
            <span>① 최소 9자 이상되어야 합니다.</span>
            <span>② 영문자는 반드시 1개 이상 존재해야 합니다.</span>
            <span>③ 특수문자가 반드시 1개 이상 존재해야 합니다.</span>
            <span>④ 숫자가 반드시 1개 이상 존재해야 합니다.</span>
          </div>
        </Tooltip>
        </label>
        
        <input id="pw" type="password" value={pw} onChange={onChangePw}/> 
        
        </div>
        <div className="user_input">

        <label htmlFor="pwcheck" className="">
        비밀번호 확인 : 
        
        </label><input id="pwcheck" type="password" value={pwCheck} onChange={onChangePwCheck}/> 
        </div>

        <span id="check" style={{marginLeft:"35%", textAlign:"left"}}></span>
        <div className="user_input">

<label htmlFor="tel" className="">
전화번호 : 

</label><input id="tel" type="text" value={extension} onChange={onChangeExtension}/> 
</div>

        <div className="user_input">

        <label htmlFor="tel" className="">
        핸드폰번호 : 
        
        </label><input id="tel" type="text" value={tel} onChange={onChangeTel}/> 
        </div>

        <div className="user_input">

<label htmlFor="tel" className="">
팩스 : 

</label><input id="tel" type="text" value={fax} onChange={onChangeFax}/> 
</div>


        <div className="user_input">

        <label htmlFor="pw" className="">
        부서 :
        </label>
        <select value={selectedD} onChange={handleChange}>
          <option value="1">미지정</option>
        {department.map((box, idx) => {
          return <option className={box.d_no} value={box.d_no}>{box.d_name}</option>
        })}
                  
        </select>
        </div>
       
        <div className="user_input">
        <label htmlFor="pw" className="">
        직위 :
        </label>
        <select value={selectedJ} onChange={handleJobChange}>
        <option value="1">미지정</option>

        {job.map((box, idx) => {
          return <option className={box.job_no} value={box.job_no}>{box.job_name}</option>
        })}
                  
        </select>
        </div>

        <div className="user_input">

<label htmlFor="tel" className="">
근무처 : 

</label><input id="tel" type="text" value={place} onChange={onChangePlace}/> 
</div>

        </div>
        </div>
        <div className="buttons">
        <button onClick={()=>addUser()}>저장</button>
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>취소</button>
        </div>
        
      </div>
      
    </div>
  );
};

export default Popup;