import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

import {
  format,
  endOfMonth,
  differenceInDays,
  differenceInMonths,
  addMonths,
  isSameMonth

  
} from "date-fns";
import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

const initialValues = {
  company: "",
  subject: "",
  start_date:"",
  end_date: "",
  monthly_price : 275166
};

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {

    const {model, mfr_num, mth_amount, remain_amount, sum, memo}= rowsData;
    return (
      <tr key={index}>
        <td>{index+1}</td>
       
        <td>
          <input
            type="text"
            value={model}
            onChange={(event) => onValUpdate(index, event)}
            name="model"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={mfr_num}
            onChange={(event) => onValUpdate(index, event)}
            name="mfr_num"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={mth_amount}
            onChange={(event) => onValUpdate(index, event)}
            name="mth_amount"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={remain_amount}
            onChange={(event) => onValUpdate(index, event)}
            name="remain_amount"
            className="form-control"
          />
        </td>

        <td>
          <input
            type="text"
            value={sum}
            onChange={(event) => onValUpdate(index, event)}
            name="sum"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={memo}
            onChange={(event) => onValUpdate(index, event)}
            name="memo"
            className="form-control"
          />
        </td>
       
        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

const Endoscope = ({doc_no, onChange, line}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [list, setList] = useState([]);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [total, setTotal]=useState("");
  const [fileArray, setArray] = useState([]);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);
  const [dailyPrice, setDailyPrice] = useState(0);

  const [values, setValues] = useState(initialValues);

  useEffect(()=>{
    //This function will run every time the value of values state changes.
    onChange(values); //Will give you the updated value of values state.
    //Do some other stuff
  }, [values])

  useEffect(() => {
    console.log(doc_no);
    if (doc_no!==undefined){
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/endoscope/'+doc_no)
    .then(function (response){
      if (response.data[0]!==undefined){


      let order_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/endoscope/'+order_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({product_name:'', quantity:'',unit_price:'', price:'', memo:''});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);




 

  const [cnt, setCnt] = useState(0);

 
  const handleInputChange = (e) => {
    //const name = e.target.name
    //const value = e.target.value
    const { name, value } = e.target;


    setValues({
      ...values,
      [name]: value,
    });
    if (name === "start_date" && value !==""){
      setCnt(differenceInDays(endOfMonth(new Date(value)), new Date(value))+1);
      let lastDay = parseInt(format(endOfMonth(new Date(value)), "dd"));


      if (format(new Date(value), "dd")==="01"){
        setDailyPrice(values.monthly_price/lastDay);
      }else{
        setDailyPrice(Math.floor((values.monthly_price/lastDay)/ 10) * 10);
      }
     


     


    }
    values.arr = rows;




    values.v1  = dailyPrice.toLocaleString();
    values.v2 = (rows.filter((item)=>item.model !=="").length*dailyPrice).toLocaleString();
    values.v3 = (cnt*dailyPrice).toLocaleString();
    values.v4 = (rows.filter((item)=>item.model !=="").length*dailyPrice*cnt).toLocaleString();


    values.v5 = values.monthly_price.toLocaleString();
    values.v6 = (values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString();




    if (name === "start_date" && values.end_date !==""){
      values.v7 = (differenceInMonths(new Date(values.end_date), (new Date(value))) * values.monthly_price).toLocaleString();
      values.v8 = (differenceInMonths(new Date(values.end_date), (new Date(value))) * values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString();
      values.v9 = (cnt*dailyPrice+differenceInMonths(new Date(values.end_date), (new Date(value))) * values.monthly_price).toLocaleString() + (cnt*dailyPrice).toLocaleString() + "+" +
        (differenceInMonths(new Date(values.end_date), (new Date(value))) * values.monthly_price).toLocaleString();
      values.v10 = (differenceInMonths(new Date(values.end_date), (new Date(value))) * values.monthly_price * rows.filter((item)=>item.model !=="").length
      +values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString();
      values.cnt = rows.filter((item)=>item.model !=="").length;
      for (let i=0;i<rows.length;i++){
        if (rows[i].model !==""){
          let mth_amount;
          if (isSameMonth(new Date(values.start_date), new Date(values.end_date))){
            mth_amount =((differenceInDays((new Date(values.end_date)), new Date(value))+1)*dailyPrice);
            rows[i]["mth_amount"] = mth_amount.toLocaleString();


          }else{
            mth_amount = ((differenceInDays(endOfMonth(new Date(value)), new Date(value))+1)*dailyPrice)
            rows[i]["mth_amount"] = mth_amount.toLocaleString();


          }


          rows[i]["remain_amount"] = (differenceInMonths(new Date(values.end_date), (new Date(value))) * values.monthly_price).toLocaleString();
          rows[i]["sum"]=(mth_amount+(differenceInMonths(new Date(values.end_date), (new Date(value))) * values.monthly_price)).toLocaleString();
        }
      }
 
    }else  if (name === "end_date" && values.start_date !==""){
      values.v7 = (differenceInMonths(new Date(value), (new Date(values.start_date))) * values.monthly_price).toLocaleString();
      values.v8 = (differenceInMonths(new Date(value), (new Date(values.start_date))) * values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString();
      values.v9 = (cnt*dailyPrice+differenceInMonths(new Date(value), (new Date(values.start_date))) * values.monthly_price).toLocaleString() + (cnt*dailyPrice).toLocaleString() + "+" +
        (differenceInMonths(new Date(value), (new Date(values.start_date))) * values.monthly_price).toLocaleString();
      values.v10 = (differenceInMonths(new Date(value), (new Date(values.start_date))) * values.monthly_price * rows.filter((item)=>item.model !=="").length
      +values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString();
      values.cnt = rows.filter((item)=>item.model !=="").length;
      for (let i=0;i<rows.length;i++){
        if (rows[i].model !==""){
          let mth_amount;
          if (isSameMonth(new Date(values.start_date), new Date(values.end_date))){
            mth_amount = ((differenceInDays((new Date(value)), new Date(values.start_date))+1)*dailyPrice);
            rows[i]["mth_amount"] = mth_amount.toLocaleString();


          }else{
            mth_amount = ((differenceInDays(endOfMonth(new Date(values.start_date)), new Date(values.start_date))+1)*dailyPrice)
            rows[i]["mth_amount"] = mth_amount.toLocaleString();


          }
          rows[i]["remain_amount"] = (differenceInMonths(new Date(value), (new Date(values.start_date))) * values.monthly_price).toLocaleString();
          rows[i]["sum"]=(mth_amount +(differenceInMonths(new Date(value), (new Date(values.start_date))) * values.monthly_price)).toLocaleString();
        }
      }


    }
   
    values.arr = rows;


    onChange(values);




  };



 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let initial = [{
      model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    },
    {
      model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    },
    {
      model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    },
    {
      model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    },
    {
      model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    },
    {
      model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    },
    {
      model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    },
    {
      model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    },
    {
      model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    },
    {
      model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    }];
    initRow(initial);
    
  }, []);


  const addRowTable = () => {
    const data = {
        model:'', mfr_num:'',mth_amount:'', remain_amount:'', sum:'', memo:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
   
    /*if (name ==="mth_amount" || name ==="remain_amount"){
      let mth_amount = data[i]["mth_amount"];
      let remain_amount = data[i]["remain_amount"];
      if (data[i]["mth_amount"] === ""){
        mth_amount="0";
      }
      if (data[i]["remain_amount"] === ""){
        remain_amount="0";
      }
      data[i]["sum"] = parseInt(mth_amount)+parseInt(remain_amount);
     
      let result = data.filter(item=>item.sum !="").map(item => parseInt(item.sum));
      console.log("result...", result);
      var total = result.reduce(function(a, b) { return a + b; }, 0);
      console.log(total);
      setTotal(total);


    }*/


  if ( values.end_date !==""  && values.start_date !==""){


    values.v1  = dailyPrice.toLocaleString();
    values.v2 = (rows.filter((item)=>item.model !=="").length*dailyPrice).toLocaleString();
    values.v3 = (cnt*dailyPrice).toLocaleString();
    values.v4 = (rows.filter((item)=>item.model !=="").length*dailyPrice*cnt).toLocaleString();


    values.v5 = values.monthly_price.toLocaleString();
    values.v6 = (values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString();
    values.v7 = (differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price).toLocaleString();
    values.v8 = (differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString();
    values.v9 = (cnt*dailyPrice+differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price).toLocaleString() + (cnt*dailyPrice).toLocaleString() + "+" +
      (differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price).toLocaleString();
    values.v10 = (differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price * rows.filter((item)=>item.model !=="").length
    +values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString();
    values.cnt = rows.filter((item)=>item.model !=="").length;
    for (let i=0;i<rows.length;i++){
      if (rows[i].model !==""){
        let mth_amount;
        if (isSameMonth(new Date(values.start_date), new Date(values.end_date))){
          mth_amount = ((differenceInDays((new Date(values.end_date)), new Date(values.start_date))+1)*dailyPrice);
          rows[i]["mth_amount"] = mth_amount.toLocaleString();


        }else{
          mth_amount = ((differenceInDays(endOfMonth(new Date(values.start_date)), new Date(values.start_date))+1)*dailyPrice)
          rows[i]["mth_amount"] = mth_amount.toLocaleString();


        }
        rows[i]["remain_amount"] = (differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price).toLocaleString();
        rows[i]["sum"]=(mth_amount +(differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price)).toLocaleString();
      }
    }


 
  }
    initRow(data);
    values.cnt = data.filter((item)=>item.model !=="").length;
    values.arr = rows;
    onChange(values);


  };







  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper forms" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

          <div className="order_write endoscope" style={{padding:"0", margin:"20px auto"}}>
          
    
          <table style={{width:"100%", border:"none"}} className="headers-1">
            <tr>
              <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>내시경 검진장비 유지보수 견적서</td>
            </tr>

            
          </table>

        <div style={{display:"flex", justifyContent:"space-between"}}>
          <table className="table1" style={{width:"42%"}}>
          <tbody>
          <tr>
          <td colspan="3">
          <input type="text" name="company" value={values.company} onChange={handleInputChange}></input>

          </td><td>귀하</td>
          </tr>
          <tr>
          <td colspan="3">
          <input type="text" name="subject" value={values.subject} onChange={handleInputChange}></input>
          </td>

          </tr>
          <tr>
          <td colspan="3" style={{display:"flex"}}>[ <input type="date" name="start_date" value={values.start_date} onChange={handleInputChange}></input> ~ <input type="date" name="end_date" value={values.end_date} onChange={handleInputChange}></input> ]</td>

          </tr>
          <tr>
       
          </tr>
          </tbody>
          </table>


          <table border="1" className="supply" style={{width:""}}>
          <tbody>
          <tr>
          <td rowspan="4" style={{textAlign:"center"}}>공<br></br>
          <br></br><br></br>급</td>
          <td>상호</td>
          <td colspan="3">생생메디칼</td>
          </tr>
          <tr>
          <td>사업자</td>
          <td>555-06-00797</td>
          <td>대표자</td>
          <td>임명한</td>
          </tr>
          <tr>
          <td>소재지</td>
          <td colspan="3">서울 서초구 서초대로 46길 19-7, 202호</td>
          </tr>
          <tr>
          <td>업태</td>
          <td>서비스업</td>
          <td>종목</td>
          <td>컨설팅</td>
          </tr>
          </tbody>
          </table>
        </div>
               
        
        <div className="btns" style={{display:"flex", justifyContent:"flex-end", margin:"20px 0"}}>
     
        <button className="btn" onClick={addRowTable}>
          추가
        </button>
      </div>

      <p>아래와 같이 견적합니다.</p>
        <table className="form_list endoscope_list" style={{borderCollapse: 'collapse', width:"790px"}}>
                 
          <thead>
            <tr>
              <th>구분</th>
              <th>모델명</th>
              <th>제조번호</th>
              <th>당월금액</th>
              <th>잔여금액</th>
              <th>대당합계</th>
              <th>비고</th>

              <th>삭제</th>
            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />
           <tr style={{background:"#b4c6e7"}}><th>합계</th><td colSpan={4}></td>
           <td>{total}원</td><td colSpan={2}></td></tr>
          </tbody>
        </table>
        <p>※ 월 대당단가 : <input type="number" value={values.montly_price} onChange={(e)=> {values.montly_price = e.target.value}}></input>(건협 본부 '22년도 내시경 검진장비 유지보수 계약 단가)</p>
        <table border="1" className="endoscope-2" style={{width:"100%"}}>
          <tbody>
          <tr>
          <th colspan="3">당월 계약일수</th>
          </tr>
          <tr>
          <td rowSpan="2" style={{textAlign:"center"}}>당월<br></br>{values.start_date !== "" &&(<span>[{format(new Date(values.start_date),"MM")}월]</span>)}</td>
          <td>계약시작일</td>
          <td>{values.start_date !== "" && (<span>{format(new Date(values.start_date),"dd일")}&nbsp;&nbsp;{format(new Date(values.start_date),"MM월 dd일")}</span>)}</td>
          </tr>
          <tr>
          <td>계약일수</td>
          <td>{values.start_date !== "" && (<span>{cnt}일 
          &nbsp;&nbsp;{format(new Date(values.start_date),"MM월 dd일")} ~ {format(endOfMonth(new Date(values.start_date)), "dd일")}</span>)}</td>
          
          </tr>
       
          </tbody>
        </table>

        <table border="1" className="endoscope-2" style={{width:"100%", marginTop:"20px"}}>
          <tbody>
          <tr>
          <th colspan="4">견적 안내</th>
          </tr>
          <tr>
          <th colspan="2" style={{background:"#f2f2f2"}}>구분</th>
          <th style={{background:"#f2f2f2"}}>1대</th>
          <th style={{background:"#f2f2f2"}}>{rows.filter((item)=>item.model !=="").length}대</th>
          </tr>
          <tr>
          <td rowSpan="2" style={{textAlign:"center"}}>당월<br></br>{values.start_date !== "" &&(<span>[{format(new Date(values.start_date),"MM")}월]</span>)}</td>
          <td>일일금액</td>
          <td>{dailyPrice.toLocaleString()}</td>
          <td>{(rows.filter((item)=>item.model !=="").length*dailyPrice).toLocaleString()}</td>
          </tr>
          <tr>
          <td>합계금액</td>
          <td>{(cnt*dailyPrice).toLocaleString()}</td>
          <td>{(rows.filter((item)=>item.model !=="").length*dailyPrice*cnt).toLocaleString()}</td>
          </tr>

          <tr>
          <td rowSpan="2" style={{textAlign:"center"}}>잔여<br></br>
          {(values.end_date !== "" && values.start_date !== "") &&(<span>[{format(addMonths(new Date(values.start_date),1),"MM")}월 ~ {format(new Date(values.end_date),"MM")}월]</span>)}</td>
          <td>단가 (월)</td>
          <td>{values.monthly_price.toLocaleString()} (월 대당단가)</td>
          <td>
          {(values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString()}

          </td>
          </tr>
          <tr>
          <td>합계금액</td>
          <td>
          {(values.end_date !== "" && values.start_date !== "") &&(<span>{
          (differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price).toLocaleString()
          }</span>)}

          </td>          
          <td>
          {(values.end_date !== "" && values.start_date !== "") &&(<span>{
          (differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString()}</span>)}
          </td>
          </tr>

          <tr>
          <th colspan="2" style={{background:"#f2f2f2"}}>구분</th>
          <th style={{background:"#f2f2f2"}}>대당합계</th>
          <th style={{background:"#f2f2f2"}}>합계</th>
          </tr>
          <tr>
          <td colSpan={2}>전체[{(values.end_date !== "" && values.start_date !== "") &&(<span>
            {format(new Date(values.start_date),"MM")}월 ~ {format(new Date(values.end_date),"MM")}월</span>)}]합계 금액</td>
          <td>{(values.end_date !== "" && values.start_date !== "") &&(<span>{(cnt*dailyPrice+differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price).toLocaleString()} {(cnt*dailyPrice).toLocaleString()} + {
          (differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price).toLocaleString()
          }</span>)}</td>

          <td>
            {
              (differenceInMonths(new Date(values.end_date), (new Date(values.start_date))) * values.monthly_price * rows.filter((item)=>item.model !=="").length
              +values.monthly_price * rows.filter((item)=>item.model !=="").length).toLocaleString()
            }
          </td>
          </tr>
          </tbody>
        </table>
        
      </div>

      </div>

      </div>
      
 
  
      </div>
  
  );
};

export default Endoscope;

