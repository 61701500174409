import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  hospital_name: "",
  address: "",
  location:"",
  install_date: "",
  installer: "",
  memo:""
};


function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {product_name, model, manufacturer, create_date, quantity, mfr_num, memo}= rowsData;

    return (
      <tr key={index}>
        <td>{index+1}</td>
       
        <td>
          {product_name}
          
        </td>
        <td>
          {model}
           
        </td>
        <td>
          {manufacturer}
           
        </td>
        <td>
          {create_date}
            
        </td>

        <td>
          {quantity}
            
        </td>
        <td>
          {mfr_num}
           
        </td>
        <td>
          {memo}
            
        </td>
      
      </tr>
    );
  });
}

const ConfirmView = ({data, line}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [user, setUser] = useState([]);
  const [name, setname] = useState("");
  const [userNo, setUserNo] = useState("");
  const [links, setLinks]=useState([]);
  const [absent, setAbsent] = useState([]);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

 

  const { state } = useLocation();


  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  


 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/confirm/'+data)
    .then(function (response){
      console.log("order.........",response.data[0]);
      if (response.data[0]!==undefined){

      setValues(response.data[0]);
      let order_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/confirm/'+order_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

    
  }, []);


 

  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

            <div className="order_write" style={{padding:"50px", boxSizing:"border-box",margin:"20px auto"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>납품ㆍ설치 확인서</td>
                    
                  
                  </tr>
                
                  
                </table>
           
                    <table className="" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병 원 명
                          </th>
                          <td style={{ border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.hospital_name}
                          </td>

                       
                        </tr>
                        <tr>
                          <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            주&nbsp;&nbsp;&nbsp;&nbsp;소
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.address}
                          </td>
                        
                          
                        </tr>
                      
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            설치장소
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.location}
                          </td>

                        

                         
                        </tr>
                        <tr>
                          <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            설치일자 
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}
                          colSpan={3}>
                          {values.install_date}
                          </td>
                         
                        </tr>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            설 치 자 
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}
                          colSpan={3}>
                          {values.installer}
                          </td>
                         
                        </tr>
                      </tbody>
                    </table>

               
                
             
        <table className="form_list confirm_list" style={{borderCollapse: 'collapse', width:"100%"}}>
                 
          <thead>
            <tr>
              <th>No</th>
              <th>장비명</th>
              <th>모델명</th>
              <th>제조사</th>
              <th>제조일자</th>
              <th>수량</th>
              <th>제조번호</th>
              <th>비고</th>
            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            />
           
          </tbody>
        </table>
             
            <table style={{width:"100%", borderCollapse:"collapse", border:"1px solid #000", marginTop:"20px"}}>
              <tr><th style={{textAlign:"center", padding:"5px", borderBottom:"1px solid #000"}}>특이사항</th></tr>
              <tr><td>{values.memo}</td></tr>
            </table>
          
          </span></div>
        
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div style={{display:"flex", flexDirection:"column", width:"46%"}}>
          <h4>상기와 같이 인수하여 물품 이상이 없음을 확인합니다.</h4>

          <div><p>소&nbsp;&nbsp;&nbsp;&nbsp;속:</p></div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <p>인수자:</p>
            <p>(인)</p>
          </div>
          </div>

          <div style={{display:"flex", flexDirection:"column", width:"46%"}}>
          <h4>상기 물품을 정히 검수 확인합니다.</h4>

          <div><p>소&nbsp;&nbsp;&nbsp;&nbsp;속:</p></div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <p>검수자:</p>
            <p>(인)</p>
          </div>
          </div>


        </div>
        
      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default ConfirmView;

