import React, { useState } from "react";
import styled from "styled-components";
import arrow_end from "./img/icon/arrow_end.png";
import arrow_first from "./img/icon/arrow_first.png";

import arrow_next from "./img/icon/arrow_next.png";
import arrow_prev from "./img/icon/arrow_prev.png";

const PageUl = styled.ul`
  float: left;
  list-style: none;
  text-align: center;
  border-radius: 3px;
  color: black;
 display:flex;
 width:100%;
 justify-content:center;

`;

const PageLi = styled.li`
  display: flex;
  align-items:center;
  font-size: 15px;
  font-weight: 300;
  padding: 10px 8px;
  text-align:center;
  justify-content:center;
  border:none;
  width:20px;
  color:black;
  & img{
    width:15px;
  }
  & span{
    padding:0 7px;
  }
  &:hover {
    cursor: pointer;
    color: white;
    background-color: #eee;
  }
  &.active {
    cursor: pointer;
    color: white;
    background-color: #263a6c;
  }
  &:focus::after {
    color: white;
    background-color: #263a6c;
  }
`;

const Pagination = ({ postsPerPage, totalPosts, page, paginate }) => {
  const pageNumbers = [];
  if (page <= 3) {
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i);
      if (i === 5) {
        break;
      }
    }
  } else if (page > 3 && page + 2 <= Math.ceil(totalPosts / postsPerPage)) {
    if (Math.ceil(totalPosts / postsPerPage) > 5) {
      for (let i = page - 2; i <= page + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (Math.ceil(totalPosts / postsPerPage) < 5) {
      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }
    }
  } else if (page + 2 > Math.ceil(totalPosts / postsPerPage)) {
    const diff = page + 2 - Math.ceil(totalPosts / postsPerPage);
    for (let i = page - 2 - diff; i <= page + 2 - diff; i++) {
      pageNumbers.push(i);
    }
  }

  const numPages = Math.ceil(totalPosts / postsPerPage);

  console.log("pagination----", page, paginate )
  return (
    <div>
      <PageUl className="pagination">
        <PageLi onClick={() => paginate(1)} disabled={page === 1}>
          <div>
            <img src={arrow_first} alt="first" />
          </div>
        </PageLi>

        <PageLi
          onClick={() => {
            if (page > 1) paginate(page - 1);
          }}
          disabled={page === numPages}
        >
          <div>
            <img src={arrow_prev} alt="prev" />
          </div>
        </PageLi>

        {pageNumbers.filter((ele)=> ele!=0).map((number) => (

          <PageLi
            key={number}
            onClick={() => paginate(number)}
            className={`page-link ${page === number ? "active" : ""}`}
          >
            <span>{number}</span>
          </PageLi>
        ))}
        <PageLi
          onClick={() => {
            if (page < numPages) paginate(page + 1);
          }}
          disabled={page === numPages}
        >
          <div>
            <img src={arrow_next} alt="next" />
          </div>
        </PageLi>

        <PageLi onClick={() => paginate(numPages)} disabled={page === numPages}>
          <div>
            <img src={arrow_end} alt="end" />
          </div>
        </PageLi>
      </PageUl>
    </div>
  );
};

export default Pagination;
