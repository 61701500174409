import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {vtype, start_date, end_date, days, reason, memo, user_no}= rowsData;
    return (
      <tr key={index}>
        <td>
        <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    {vtype ==="0.5" && (
      <span>반차</span>
    )}
    {vtype ==="0.25" && (
      <span>반반차</span>
    )}
    {vtype ==="1" && (
      <span>연차</span>
    )}
    {vtype ==="0" && (
      <span>공가</span>
    )}
    </div>
        </td>
        <td>
        {moment(start_date).format('YYYY-MM-DD')}
        </td>
        <td>
        {moment(end_date).format('YYYY-MM-DD')}
        </td>
     
        <td>
{vtype ==="1" ? 
<span>{moment(end_date).diff(moment(start_date), 'days')}</span>:
vtype
}

        </td>
        <td>
        {reason}
        </td>

        <td>
        {memo}
        </td>

        <td style={{display:"none"}}>
          <input
            type="text"
            value={user_no}
            onChange={(event) => onValUpdate(index, event)}
            name="user_no"
            className="form-control"
            
          />
        </td>
      
      </tr>
    );
  });
}
function Vacation({ user_no }) {
  const [rows, initRow] = useState([]);

  useEffect(() => {
    console.log("userno", user_no);
    fetch("http://gw.thegmmedical.com:5003/api/get_userinfo/annual_leave/"+user_no, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log(json);  
      initRow(json);

      //console.log(boxes.boxes);
      //console.log(boxes.boxes);
    })
    .catch(() => {});
    
  }, []);

 
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  function addExperience(){
    for (var i=0;i<rows.length;i++){
      console.log(rows[i]);
    }

    console.log("last rows", rows);
    axios.post('http://gw.thegmmedical.com:5003/api/add_userinfo/vacation/'+user_no, rows)
    .then(function (response){
      console.log(response);
      alert("저장되었습니다.");


    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  return (
    <>

              <div className="btns" style={{display:"flex", justifyContent:"flex-end", marginBottom:"20px"}}>

   
              </div>
           
      <table style={{width:"100%"}} className="info_summary">
        <thead>
          <tr>
          <th>구분</th>
                <th>시작일</th>
                <th>종료일</th>
                <th>일수</th>
                <th>사유</th>
                <th>비고</th>
          </tr>
        </thead>
        <tbody>
          <TableRows
            rows={rows}
            onValUpdate={onValUpdate}
            userNo = {user_no}
          />
        </tbody>
      </table>
      <div className="btns" style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>


</div>
    </>
  );
}
export default Vacation;