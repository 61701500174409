import { NavLink, Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/SidebarSetting";
import axios from "axios";
import styled from 'styled-components';
import Languages from "./tables/HRLanguages";
import Education from "./tables/HREducation";
import Experience from "./tables/HRExperience";
import Certificates from "./tables/HRCertificates";
import BusinessTrips from "./tables/HRBusinessTrips";
import Salaries from "./tables/HRSalaries";
import Vacation from "./tables/HRVacation";
import userImg from "../components/img/user.png";
import Appointments from "./tables/HRAppointments";


axios.defaults.withCredentials = true; 

const TabMenu = styled.ul`
  font-weight: bold;
  box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%),
  inset 0 1px 0 0 rgb(100 121 143 / 12%);
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin-bottom: 2rem;
  margin-top: 10px;
  @media only screen and (max-width: 1300px) {
    flex-direction:column;
  
  }

  a{
    @media only screen and (max-width: 1300px) {
      width:100%;
    
    }
  }
  .submenu {
  // 기본 Tabmenu 에 대한 CSS를 구현
    display: flex;
    /* justify-content: space-between;
    width: 380px;
    heigth: 30px; */
    padding: 10px;
    font-size: 12px;
    cursor:pointer;
    box-sizing:border-box;
    transition: 0.5s;

    @media only screen and (max-width: 1300px) {
      width:100%;
    
    }

  }
  .submenu:hover {
  }
  .submenu:first-child {
    margin:0 20px;
    @media only screen and (max-width: 1300px) {
      margin:0;
    
    }
  }
  .focused {
   //선택된 Tabmenu 에만 적용되는 CSS를 구현
    color: rgb(21,20,20);
    border-bottom:2px solid #000;
    @media only screen and (max-width: 1300px) {
      border:none;
      background:#ddd;
    
    }
  }

  & div.desc {
    text-align: center;
  }
`;

const Desc = styled.div`
  text-align: center;
`;
const SettingHR = () => {
  const [pw, setPw] = useState("");
  const [changePw, setChangePw] = useState("");
  const [checkPw, setCheckPw] = useState("");
  const [currentTab, clickTab] = useState(0);
  const [userNo, setUserNo] = useState(0);
  const [user, setUser] = useState([]);

  var menuArr;
  useEffect(() => {

    fetch("https://gw.thegmmedical.com:5003/api/getuser", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("user...", json[0])
        setUserNo(json[0].user_no);
        setUser(json[0]);
        
       
      })
      .catch(() => {});
    
 
}, []);
menuArr = [
  { name: '경력', content: <Experience user_no={userNo}/> },
  { name: '자격', content: <Certificates user_no={userNo}/> },
  { name: '어학', content: <Languages user_no={userNo}/>},
  { name: '교육', content: <Education user_no={userNo}/> },
  { name: '휴가', content: <Vacation user_no={userNo}/> },
];
  function reply(){

    const msg = {
      subject : "subject",
      date : "date",
      html : "html"

    }
    console.log(msg);
  fetch("https://gw.thegmmedical.com:5003/send", {
    method: "POST", // 통신방법
    headers: {
      "content-type": "application/json",
    },
    credentials: "include",
    body:msg,
  })
    .then((res) => res.json())
    .then((json) => {
      //setData(json);
      console.log(json);
    })
    .catch(() => {});
 
  }
const onChangePw = (e) => {
    	setPw(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangePwChange = (e) => {
    	setChangePw(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangePwCheck = (e) => {
    	setCheckPw(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    var checkPassword = function(password){
      return !!password.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%* #+=\(\)\^?&])[A-Za-z\d$@$!%* #+=\(\)\^?&]{3,}$/);
  };
  const updatepw = () =>  {
    if (changePw !== checkPw){
      document.getElementById('check').innerHTML='비밀번호가 일치하지 않습니다.';
      document.getElementById('check').style.color='red';
      return false;
    }else{
      document.getElementById('check').innerHTML='';
      if (!checkPassword(changePw)){
        document.getElementById('check').innerHTML='비밀번호 규칙에 어긋납니다.';
        document.getElementById('check').style.color='red';
      }else{
        document.getElementById('check').innerHTML='';
      }
    }
    
    const msg = {
      pw : pw,
      changePw : changePw,

    }
    axios.post('https://gw.thegmmedical.com:5003/api/updatepw',msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  }
  const selectMenuHandler = (index) => {
    // parameter로 현재 선택한 인덱스 값을 전달해야 하며, 이벤트 객체(event)는 쓰지 않는다
    // 해당 함수가 실행되면 현재 선택된 Tab Menu 가 갱신.
    clickTab(index);
  };

  return (
    <div className="wrapper">
        <Sidebar/>
        <div className="setting_wrap">

        <h2 id="title" class="screen_out">인사 카드</h2>


   <div className="user_detail">
    
       <div className="addUser">

           <div style={{display:"flex"}}>
           
<div className="addInfo" style={{width:"100%"}}>

  
<table className="info_summary profile responsive">

<tbody>

<tr>
<td rowSpan={6}><div className="addpic" style={{margin:"20px"}}>

   
<label htmlFor="photo-upload" className="custom-file-upload fas">

{user.profile_link !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={user.profile_link} alt="profile_pic" />
</div>
: <img for="photo-upload" src={userImg} alt="profile_pic"/>
}


</label>
</div></td>
<th class="table_title2_topline" width="120" nowrap="">성명</th>
<td class="table_list2_left_topline" data-label="성명" width="15%"><b>{user.name}&nbsp;</b></td>
<th class="table_title2_topline" width="120" nowrap="">부서</th>
<td class="table_list2_left_topline" data-label="부서" width="15%">{user.d_name}</td>
<th class="table_title2_topline" width="120" nowrap="">직급</th>
<td class="table_list2_left_topline" data-label="직급">{user.job_name}</td>

</tr>
<tr>
<th class="" width="120" nowrap="">생년월일</th>
<td class="" data-label="생년월일">
{user.birthdate}&nbsp;</td>
<th class="" width="120" nowrap="">입사일자</th>
<td class="" data-label="입사일자">
   {user.start}&nbsp;</td>


<th class="" width="120" nowrap="">휴대폰번호</th>
<td class="" data-label="휴대폰번호">{user.tel}</td>

</tr>


<tr>
<th class="" width="120" nowrap="">주소</th>
<td class="" colspan="5" data-label="주소">&nbsp;</td>
</tr>

</tbody></table>
   </div>
           </div>


       
           <TabMenu style={{borderRight:"1px solid #ddd", borderLeft:"1px solid #ddd"}}>
     
     {menuArr.map((el,index) => (
         <Link to ={el.href}><li className={index === currentTab ? "submenu focused" : "submenu" }
         onClick={() => selectMenuHandler(index)}>{el.name}</li></Link>
       ))}
   </TabMenu>
<Desc>
<p>{menuArr[currentTab].content}</p>
</Desc> 

          
           </div>
          
   <div style={{display:"flex"}}>
   <div className="addInfo" style={{width:"100%"}}>
 
   </div>


   

   </div>
   </div>
  
   
</div>

</div>

  );
};

export default SettingHR;