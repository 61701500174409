
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";
import React, { useState, useEffect, useRef } from "react";
import Popup from "../components/Popup";
import axios from "axios";
import "./css/adminUsers.css";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";

const AdminLeaveType = () => {
    const [sidebar, setSidebar] = useState(false);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [showCreate, setCreate] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filterData, setFilterData] = useState([]);

    
  
    const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [year, setYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);

  const offset = (page - 1) * limit;

    const togglePopup = () => {
        setPopup(!showPopup);
        

    };
    const toggleCreate = () => {
      setCreate(!showCreate);
      

  };
 
    
 
    function getUser(value){
      fetch("http://gw.thegmmedical.com:5003/api/get_leaves", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("json", json);
        setData(json);
        setFilterData(json);
        
      })
      .catch(() => {});
    
    }
    useEffect(() => {
  
      let com_year= new Date().getFullYear();
      let year_arr = [];
      for (var y = (com_year - 5); y <= (com_year + 5); y++) {
        year_arr.push(y);
      }
     
      setYears(year_arr);
      getUser(year);
        
     
        
    
      
      }, []);
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('user');
        const all 
             = document.getElementsByName('userall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...checkList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(checkList.filter((checkedId) => checkedId !== id));
        }
        
     }

     const delHolidays = () => {

      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked');
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log("안녕", array);

      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }

      var result = window.confirm("선택된 연차 유형을 삭제합니다.");
      if(result){
        axios.post('http://gw.thegmmedical.com:5003/api/del_leave',data)
        .then(function (response){
          console.log(response);
          //getUser();
          alert("삭제되었습니다.");
          window.location.reload(true);
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents pw" style={{maxWidth:"1600px"}}>
        <div className="header_txt">
        <h3>연차 유형 관리</h3>
        </div>
      

        <div className="main_contents_wrapper" style={{width:"100%"}}>
            <div className="btns" style={{display:"flex", justifyContent:"space-between"}}>
           

              <div className="right">
          
                                  <div style={{display:"flex", width:"100%", justifyContent:"end"}}>

                                    <button onClick={()=>delHolidays()}>삭제</button>
                                    <Link to ="/leave_w"><button>추가</button></Link>
                                   
                                 
                                  </div>

              </div>

            </div>

{showCreate ? (
  <Popup text="Title" closePopup={() => {toggleCreate();getUser();}} />
) : null}           

{showPopup ? (
  <Popup member={selected} text="Title" closePopup={() => {togglePopup();getUser();}} />
) : null}

        
<table class="responsive">
  <thead>
    <tr>
    <th className="check" style={{textAlign:"left"}}>
    <input type='checkbox'
       name='userall' 
       value='selectall'
       onClick={selectAll}/>

    </th>



      <th>휴가종류</th>
      <th>차감 수</th>

      <th>연차 차감</th>
      <th>사용여부</th>
      <th>설정</th>

  

    </tr>
  </thead>
  <tbody>
  
  { filterData.slice(offset, offset + limit).map((element, index) => 
    <tr key={index}>
        <td style={{textAlign:"left"}}>
        <input type='checkbox'
        name='user' 
        value={element.no}
        onChange={(e) => {onChangeEach(e, element.no); }} checked={checkList.includes(element.no)}/> 
        </td>

      
    <td data-label="휴가종류">{element.leave_type}</td>
    <td data-label="차감 수">{element.leave_value}</td>

    <td data-label="연차차감">{element.minus === 1 ? <span>차감</span> : <span>차감 X</span> }</td>
    <td data-label="사용여부">{element.inuse === 1 ? <span>사용</span> : <span>미사용</span> }</td>
    <td data-label="수정"><Link to="/leave_w" state={{no: element.no}}><button>수정</button></Link></td>
    

    

    </tr>) }
   
    {filterData.length === 0 && (<tr><td colSpan={11} style={{padding:"50px 0", textAlign:"center"}}>등록된 연차 유형이 없습니다.</td></tr>)}
  </tbody>
</table>

        </div>
        {data.length >10 && (
        <Pagination
                        postsPerPage={limit}
                        totalPosts={data.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>

        )}
     </div>
    
    </div>

  );
};

export default AdminLeaveType;
