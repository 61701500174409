export const menuItems = [
    {
      title:'Home',
      url: '/',
    },
    {
      title: '전자메일',
      url: '/list/all',
    },
    {
      title: '전자결재',
      url: '/doc_main',
    },
    {
        title: '일정관리',
        url: '/schedule',
    },
    {
        title: '게시판',
        url: '/board',
    },
    {
      title: '자료실',
      url: '/data',
  },
    {
      title: '업무관리',
      url: '/work',
    },
    {
        title: '인맥관리',
        url: '/address',
    },
    {
      title: '거래처 관리',
      url: '/cx',
    }
  ];