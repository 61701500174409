
import { NavLink, Link } from "react-router-dom";
import FolderTree, { testData } from 'react-folder-tree';
import React, { useState, useEffect, useRef } from "react";
import BoardList from "../components/side/BoardList";
import axios from "axios";
import "./css/board.css";
import user from "../components/img/user.png";
import Popup from "../components/Popup";
import Pagination from "./Pagination";
import {FaBuilding, FaSitemap, FaStar, FaRegListAlt, FaClipboardList } from "react-icons/fa";
import { MdAnnouncement } from "react-icons/md";


import {
    FaCaretRight,
    FaCaretDown,
    FaRegWindowClose,
    FaRegTrashAlt,
    FaRegEdit,
    FaRegCheckSquare,
    FaBitcoin,
    FaEthereum,
  } from 'react-icons/fa';

const Board = () => {
    const [folders, setFolders] = useState([]);
    const [teamFolders, setTeamFolders] = useState([]);
    const [data, setData] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [showUser, setShowuser] = useState(false);
    
    const [CheckList, setCheckList] = useState([]);
    const [unassigned, setUnassigned] = useState([]);
    const [members, setMembers] = useState([]);
    const [parent, setParent] = useState("");
    const [name, setName] =useState("");
    const [num, setNum] = useState(0);
    const [lev, setLev] = useState(0);
    const [selected, setSelected]= useState();
    const [selectedMember, setSelectedMember]= useState();
    const [search, setSearch] = useState(null);
    const [options, setOption] = useState("subject");
    const [filterData, setfilterData] = useState([]);
    const [notice, setNotice] = useState([]);
    const [isEmpty, setEmpty] = useState(false);
    const [isPage, setIsPage] = useState(false);
    const [isloggedIn, setLoggedIn] = useState(false);
    const [ddd, setDdd]= useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const offset = (page - 1) * limit;
    const onChangeSearch = (e) => {
      e.preventDefault();
      setSearch(e.target.value);
    };
  

    const onTreeStateChange = (state, event) => console.log(state, event);

    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].bg_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.bg_name,
            parentNo : node.bg_parent,
            num : node.bg_no,
            level : node.bg_level,
            order : node.bg_order,
            dNo : node.d_no,
            type : node.bg_type,
            children: lookingForNodeWithParent( nodes, node.bg_no )
          });
        }
      }
      
      return arrayToReturn;
    }
    function lookingForNodeWithParent2( nodes, parentId ) {

        var arrayToReturn2 = [];
      
        for( var i = 0, length = nodes.length; i < length; i++ ) {
          if( nodes[i].bg_parent === parentId ) {
              var node = nodes[i];
            arrayToReturn2.push({
              name: node.bg_name,
              parentNo : node.bg_parent,
              num : node.bg_no,
              level : node.bg_level,
              order : node.bg_order,
              dNo : node.d_no,
              type : node.bg_type,
              children: lookingForNodeWithParent( nodes, node.bg_no )
            });
          }
        }
        
        return arrayToReturn2;
      }
      function clean(object) {
        Object
            .entries(object)
            .forEach(([k, v]) => {
              
                if (v && typeof v === 'object') {
                    clean(v);
                }
                if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
                    if (Array.isArray(object)) {
                        object.splice(k, 1);
                        
                    } else {
                        delete object[k];
                    }
                }
            });
        return object;
    }
    useEffect(() => {
      fetch("http://gw.thegmmedical.com:5003/api/getboardlist", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
  
        //setBoxes(json[0].boxes);
        var array = lookingForNodeWithParent( json, null );
        var ddd = lookingForNodeWithParent2( json, null );
       
         
        console.log("아니 저기", JSON.stringify(clean(ddd)));
        setDdd(clean(ddd[0]));
        //let newData = array.map(el => el[0]);
            //console.log(newData);
            setFolders(clean(array));
            

        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});
      fetch("http://gw.thegmmedical.com:5003/api/get_teamboard", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
  
        //setBoxes(json[0].boxes);
        var array = lookingForNodeWithParent( json, null );
        var ddd = lookingForNodeWithParent2( json, null );
       
         
        console.log("아니 저기", JSON.stringify(clean(ddd)));
        //let newData = array.map(el => el[0]);
            //console.log(newData);
            setTeamFolders(clean(array));
            

        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});
      axios.get("http://gw.thegmmedical.com:5003/api/notice").then((response) => {
        setfilterData(response.data);
        if (response.data.length === 0) {
          setEmpty(true);
          console.log(response.data);
        } else {
          console.log("haha");
          setEmpty(false);
          if (response.data.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }
      });

      
    }, []);
  function allBoard(){
    axios.get("http://gw.thegmmedical.com:5003/api/news").then((response) => {
        setfilterData(response.data);
        if (response.data.length === 0) {
          setEmpty(true);
          console.log(response.data);
        } else {
          console.log("haha");
          setEmpty(false);
          if (response.data.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }
      });
  }
    const togglePopup = () => {
        setPopup(!showPopup);
    };
    const toggleUser = () => {
      setShowuser(!showUser);
  };
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('animal');
        const all 
             = document.getElementsByName('animalall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...CheckList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(CheckList.filter((checkedId) => checkedId !== id));
        }
     }

     const onNameClick = ({ defaultOnClick, nodeData }) => {
      defaultOnClick();
    
      const {
        // internal data
        path, name, num, level, type, isOpen, children
        // custom data
      } = nodeData;
      console.log(nodeData);
      if (type!=="group"){
        setSelected(nodeData);
        var aTags = document.getElementsByTagName("span");
  var searchText = name;
  var found;
  
  for (var i = 0; i < aTags.length; i++) {
    if (aTags[i].textContent === searchText) {
      found = aTags[i];
      break;
    }
  }
  const boxes = document.querySelectorAll('.editableNameContainer');
  
  boxes.forEach(box => {
    box.style.backgroundColor = 'transparent';
  });
  found.style.backgroundColor = '#BEFDFD';
  console.log(found);
        setParent(name);
        setNum(num);
        setLev(level);
        /*const tempArray = [...options, item];
        console.log(tempArray);
        setOptions(tempArray);*/
  
  
        const item = name;
        const tempArray = [...members, item];
        setMembers(tempArray);
  
        axios.post('http://gw.thegmmedical.com:5003/api/getboard',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              folder_name: name
            },
          })
    .then(function (response){
      /*var tempArray = [];
      for (let i =0;i<response.data.length;i++){
        
        console.log(response.data[i].name);
        tempArray.push(response.data[i].name + response.data[i].email);
        
      }*/
      setfilterData(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    axios.post("http://gw.thegmmedical.com:5003/api/notice_board",{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        folder_name: name
      },
    }).then((response) => {
      console.log("kk", response.data);
      setNotice(response.data);
     
      
    });
  
      }
      
  };



  const deleteDepartment = () => {
    console.log(parent);
    const data ={
      name : name,
      parent : parent,
      num : num,
      level : lev
    }
    axios.post('http://gw.thegmmedical.com:5003/api/delete_department', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };


  const assign = () => {
    console.log("저기......"+selected.name);
    if (selected.name ===undefined){
    }
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }
    const data ={
      nos : array,
      folderName : selected.name,
      folderNo : selected.num
    }
    
    var result = window.confirm("선택된 사용자를 [" + selected.name+"] 부서에 지정합니다.");
    if(result){
      axios.post('http://gw.thegmmedical.com:5003/api/assign', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }
    
  };

  const deleteUser = () => {
    console.log(CheckList);
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }
    const data ={
      nos : array
    }
    var result = window.confirm("선택된 사용자를 삭제하시겠습니까?");
    if(result){
      axios.post('http://gw.thegmmedical.com:5003/api/deleteUsers', data)
      .then(function (response){
        console.log(response);
        
    var aTags = document.getElementsByTagName("span");
    var searchText = parent;
    var found;
    
    for (var i = 0; i < aTags.length; i++) {
      if (aTags[i].textContent === searchText) {
        found = aTags[i];
        break;
      }
    }
    found.click();

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };


  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };
  const onSearch = (e) => {
    e.preventDefault();

    /*
        axios.get('http://222.101.0.9:5000/boardlist').then((response)=> {
            setData(response.data);
            
        })
        alert(options);
        searchPosts({ page: page, option: options, content: search });
        setSearch('');*/

    if (options === "subject") {
      const filtered = data.filter((itemList) => {
        console.log(itemList);
        return itemList.subject.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    } else if (options === "content") {
      const filtered = data.filter((itemList) => {
        console.log(itemList);
        return itemList.content.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    }
  };
  const CaretRightIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, url, ...restData } = nodeData;
    const handleClick = () => {
      console.log('icon clicked:', { path, name, url, ...restData });
      defaultOnClick();
    };
  
    return <FaCaretRight onClick={ handleClick } />;
  };
  
  const CaretDownIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, url, ...restData } = nodeData;
    const handleClick = () => {
      console.log('icon clicked:', { path, name, url, ...restData });
      defaultOnClick();
    };
  
    return <FaCaretDown onClick={ handleClick } />;
  };
  
  const FileIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, url, ...restData } = nodeData;
    const handleClick = () => {
      console.log('icon clicked:', { path, name, url, ...restData });
      defaultOnClick();
    };
  
    return <FaClipboardList onClick={ handleClick } />;
  };
  
  const FolderIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, url, ...restData } = nodeData;
    const handleClick = () => {
      console.log('icon clicked:', { path, name, url, ...restData });
      defaultOnClick();
    };
  
    return <FaBitcoin onClick={ handleClick } />;
  };
  
  const FolderOpenIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, url, ...restData } = nodeData;
    const handleClick = () => {
      console.log('icon clicked:', { path, name, url, ...restData });
      defaultOnClick();
    };
  
    return <FaBitcoin onClick={ handleClick } />;
  };
  
  const EditIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, url, ...restData } = nodeData;
    const handleClick = () => {
      console.log('icon clicked:', { path, name, url, ...restData });
      defaultOnClick();
    };
  
    return <FaRegEdit onClick={ handleClick } />;
  };
  
  const DeleteIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, url, ...restData } = nodeData;
    const handleClick = () => {
      console.log('icon clicked:', { path, name, url, ...restData });
      defaultOnClick();
    };
  
    return <FaRegTrashAlt onClick={ handleClick } />;
  };
  
  const CancelIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, url, ...restData } = nodeData;
    const handleClick = () => {
      console.log('icon clicked:', { path, name, url, ...restData });
      defaultOnClick();
    };
  
    return <FaRegWindowClose onClick={ handleClick } />;
  };
  
  const OKIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, url, ...restData } = nodeData;
    const handleClick = () => {
      console.log('icon clicked:', { path, name, url, ...restData });
      defaultOnClick();
    };
  
    return <FaRegCheckSquare onClick={ handleClick } />;
  };
  
  // add fake url to data for testing purpose
  const addUrl = node => {
    const fakeUrl = `root/${node.name}`;
    if (node.children) {
      node.url = fakeUrl;
      node.children = node.children.map(c => addUrl(c));
    } else {
      node.url = fakeUrl;
    }
  
    return node;
  };
  const iconComponents = {
    FileIcon
  };
  return (
    <div className="wrapper">

     <div className="content-wrap">
     
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>
        <BoardList/>
        <div className="board_wrap">
    
           
        <div className="">
        
        <div className="board_wrapper">
          <form name="form1" id="form1" method="post">
                  <div className="board_container">
                      <div id="content" className="content">
                      
                        <div className="board_top">
                          <span className="headerText">{parent}</span>
                              {/*<label>
                            페이지 당 게시물 수:&nbsp;
                            <select
                            type="number"
                            value={limit}
                            onChange={({ target: { value } }) => setLimit(Number(value))}
                            >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </label>*/}
                              <div className="bt_srch">
                                <div className="bts_slct">
                                  <select
                                    id="u_shcate"
                                    name="search_item"
                                    className="select"
                                    title="검색항목 선택"
                                    value={options || ''}
                                    onChange={selectHandle}
                                  >
                                    <option value="subject">게시판 선택</option>
                                    <option value="content">내용</option>
                                  </select>
                                </div>
                                <div className="bts_slct">
                                  <select
                                    id="u_shcate"
                                    name="search_item"
                                    className="select"
                                    title="검색항목 선택"
                                    value={options || ''}
                                    onChange={selectHandle}
                                  >
                                    <option value="subject">제목+내용</option>
                                    <option value="content">내용</option>
                                  </select>

                                </div>
                                <div className="bt_input">
                                  <input
                                    type="text"
                                    id="search_content"
                                    name="search_content"
                                    value={search || ''}
                                    placeholder="검색어를 입력하세요"
                                    onChange={onChangeSearch}
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="search_btn"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                              </div>
                          </div>

                          <div className="board_list">
                         
                          
                  <table class="table table-hover bd-b">
									<thead class="thead-dark">
									<tr class="tx-center">
										<th class="wd-80 xl-hidden">번호</th>
										<th class="">제목</th>
										<th class="wd-150 xl-hidden">작성자</th>
										<th class="wd-120 xl-hidden">작성일</th>
										<th class="wd-80 xl-hidden">열람</th>
										<th class="wd-50 xl-hidden"><span class="ficon-14"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></span></th>
									</tr>
									</thead>
									<tbody>
               
                  {filterData
                                  .slice(offset, offset + limit)
                                  .map(
                                    ({
                                      b_no,bg_name,bg_no,
                                      subject,author,
                                      date_created,
                                      hit, is_notice
                                    }) => (
                                      is_notice !==1 &&( <tr key={b_no}>
                                      

                                        <td>
                                        {b_no}
                                        </td>
                                      
                                        <td>
                                        <Link to={"/boardview/"+b_no} state={{bg_name : bg_name, bg_no: bg_no}}>{subject}</Link>
                                        </td>
                                        <td>
                                        {author}
                                        </td>
                                        <td>
                                        {date_created}
                                        </td>
                                        <td>
                                        {hit}
                                        </td>
                                        <td>
                                        </td>
                                      </tr>)
                                     
                                    )
                                  )}

									</tbody>
								</table>
                            <table>
                              <colgroup>
                                <col className="m_dp_n table_no" />
                                <col className="" />
                                <col className="ta_l" />
                                <col className="m_dp_n table_date" />
                              </colgroup>
                              {/*<thead>
                        <tr>
                            <th className="m_dp_n table_no" scope="col">NO.</th>
                            <th scope="col"></th>
                            <th className="ta_l" scope="col">제목</th>
                            <th className="m_dp_n table_date" scope="col">날짜</th>
                        </tr>
                    </thead>*/}
                              <tbody>
                               
                              </tbody>
                            </table>
                            <div style={{width:"100%",textAlign:"right"}}>
                    {isloggedIn && (
                      <Link to="write"
                        style={{
                          padding: "10px 30px",
                          marginTop: "20px",
                          backgroundColor: "#555",
                          color: "white",
                          textAlign: "center",
                          position:"relative",
                          display:"inline-block",
                          width:"105px",
                          cursor: "pointer",
                        }}
                      >
                        글쓰기
                      </Link>
                    )}
                    </div>
                  </div>
                  {isPage && (
                    <div className="paging">
                      <Pagination
                        postsPerPage={limit}
                        totalPosts={filterData.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>
                      {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
                    </div>
                  )}
                      </div>
                  </div>
          </form>
        </div>
      </div>
            
            
                
                
                
        </div>

                </div>
     </div>
    </div>

  );
};

export default Board;
