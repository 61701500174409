
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import AddressList from "../components/side/AddressList";
import axios from "axios";
import "./css/board.css";
import {GiHamburgerMenu} from "react-icons/gi";
import { AiFillStar } from "react-icons/ai";
import Pagination from "./Pagination";
//import hi from '../excel/93.json';


const Board = () => {
  const [data, setData] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [isActive, setActive] = useState(false);
  const [chosen, setChosen] = useState("전체");
  const [parent, setParent] = useState("");
  const [name, setName] =useState("");
  const [num, setNum] = useState(0);
  const [lev, setLev] = useState(0);
  const [selected, setSelected]= useState();
  const [search, setSearch] = useState(null);
  const [boardN, setBoardN] = useState("");
  const [boardNum, setBoardNum] = useState();
  const [options, setOption] = useState("name");
  const [filterData, setfilterData] = useState([]);
  const [groupOptions, setGroupOptions] = useState("");
  const [CheckList, setCheckList] = useState([]);
  const [bookmarkList, setBookmarkList] = useState([]);


  const [notice, setNotice] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [isPage, setIsPage] = useState(false);
  const [isPageBookmark, setIsPageBookmark] = useState(false);

  const [isloggedIn, setLoggedIn] = useState(false);
  const [page, setPage] = useState(1);
  const [pageBookmark, setPageBookmark] = useState(1);

  const [limit, setLimit] = useState(5);
  const [addrs, setAddrs] = useState([]);
  const [department, setDepartment] = useState([]);
  const [myGroups, setMyGroups] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);


  const offset = (page - 1) * limit;

  const offsetBookmark = (pageBookmark - 1) * limit;

  const { state } = useLocation();
  const toggleText = () => {
    setActive(!isActive);
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  
  
    function getData(){
      /*axios.get("https://gw.thegmmedical.com:5003/api/get_user_addr").then((response) => {
        setMyGroups(response.data);
        var group_nos = response.data.map(function(item) {
          return item['no'];
        });

        const data = {
          nos : group_nos
        }

        axios.post('https://gw.thegmmedical.com:5003/api/addrmain', data)
    .then(function (response){
      setfilterData(response.data);
      setData(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

      });*/

      axios.get('https://gw.thegmmedical.com:5003/api/addrmain')
    .then(function (response){
      setfilterData(response.data);
      if (response.data.length>5){
        setIsPage(true);
      }
      setData(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
     
    }
    useEffect(() => {
    
      axios.get('https://gw.thegmmedical.com:5003/api/getaddrs',{})
      .then(function (response){
      //setBoxes(json[0].boxes);
      setAddrs(response.data);
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
      axios.get('https://gw.thegmmedical.com:5003/api/bookmark_addr',{})
      .then(function (response){
      //setBoxes(json[0].boxes);
      console.log(response.data);
      setBookmarks(response.data);
      if (response.data>5){
        setIsPageBookmark(true);
      }
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
     
      axios.get('https://gw.thegmmedical.com:5003/api/get_teamaddr',{})
      .then(function (response){
      //setBoxes(json[0].boxes);
      console.log("department", response.data);
      setDepartment(response.data);
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
      axios.get('https://gw.thegmmedical.com:5003/api/get_user_addr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setMyGroups(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    
      getData();

     
      
    }, []);


   




    const deleteDepartment = () => {
      const data ={
        name : name,
        parent : parent,
        num : num,
        level : lev
      }
      axios.post('https://gw.thegmmedical.com:5003/api/delete_department', data)
      .then(function (response){
        window.location.reload(true);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    };
  
  
    const assign = () => {
      if (selected.name ===undefined){
      }
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array,
        folderName : selected.name,
        folderNo : selected.num
      }
      
      var result = window.confirm("선택된 사용자를 [" + selected.name+"] 부서에 지정합니다.");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/assign', data)
      .then(function (response){
        window.location.reload(true);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }else{
      }
      
    };
    const onNameClick = ({ defaultOnClick, nodeData }) => {
      defaultOnClick();
      var notice_data=[];
      var board_data=[];
      const {
        // internal data
        path, name, num, level, type, isOpen, children
        // custom data
      } = nodeData;
      setName(name);
        setNum(num);
      if (type!=="group"){
        var dropdown = document.getElementsByClassName('dropdown_div');
        var aTags = dropdown[0].getElementsByTagName("span");
        
  var searchText;
  var found;
  
          searchText=name;
        
  for (var i = 0; i < aTags.length; i++) {
    if (aTags[i].textContent === searchText) {
      found = aTags[i];
      break;
    }
  }
  const boxes = document.querySelectorAll('.editableNameContainer');
  
  boxes.forEach(box => {
    box.style.backgroundColor = 'transparent';
  });
  found.style.backgroundColor = '#BEFDFD';
      console.log(name);
        
      
  
      }
      
  };
  const addBookmark = () => {
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }
    const msg ={
      nos : array
    }
    var result = window.confirm("선택한 연락처를 즐겨찾기에 추가하시겠습니까?");
    if(result){
      
      axios.post('https://gw.thegmmedical.com:5003/api/add_addr_bookmark', msg)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
      alert("이미 추가된 연락처입니다.");
    })
    .then(function (){
      //always executed
    });
    }
  };
  function delContacts(){
    console.log(CheckList);
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }
    const msg ={
      nos : array
    }
    var result = window.confirm("선택한 연락처를 삭제하시겠습니까?");
    if(result){
  
      axios.post('https://gw.thegmmedical.com:5003/api/delcontacts', msg)
      .then(function (response){
        console.log(response);
        window.location.reload(true);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  
  }
    
    const selectHandle = (e) => {
      console.log("changed", e.target.value);
  
      setOption(e.target.value);
    };
  
    const selectGroupHandle = (e) => {
      console.log("changed", e.target.value);
  
      setGroupOptions(e.target.value);
    };
  
    const onSearch = (e) => {
      e.preventDefault();
      var filtered;
      
      if (groupOptions!==""){
        filtered = data.filter((itemList) => {
          console.log(itemList.group_no, ", ", parseInt(groupOptions));
          return itemList.group_no == parseInt(groupOptions);
        });
        if (search!==""){
          filtered = filtered.filter((itemList) => {
            return itemList[options].toUpperCase().includes(search.toUpperCase());
          });
        }
        setfilterData(filtered);
        if (filtered.length === 0) {
          setEmpty(true);
          setIsPage(false);
        } else {
          setEmpty(false);
          if (filtered.length <= 5) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }
      }else if (groupOptions ===""){
        
        if (search!==""){
          filtered = data.filter((itemList) => {
            console.log(groupOptions, search, options);
            if (itemList[options]!==null){
              return itemList[options].toUpperCase().includes(search.toUpperCase());
            }
          });
          setfilterData(filtered);
          if (filtered.length === 0) {
            setEmpty(true);
            setIsPage(false);
          } else {
            setEmpty(false);
            if (filtered.length <= 5) {
              setIsPage(false);
            } else {
              setIsPage(true);
            }
          }
        }
      }
  
    
      
     
      
    };
  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
       setCheckList([...CheckList, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
       setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }
 }

 const onBookmarkChangeEach = (e, id) => {
  // 체크할 시 CheckList에 id값 넣기
  if (e.target.checked) {
     setBookmarkList([...bookmarkList, id]);
  // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
  } else {
    setBookmarkList(bookmarkList.filter((checkedId) => checkedId !== id));
  }
}

 function selectAll()  {
  const checkboxes 
       = document.getElementsByName('contact');
  const all 
       = document.getElementsByName('all');
  //console.log(all[0].checked)
  checkboxes.forEach((checkbox) => {
    checkbox.checked = all[0].checked;
  })
}


function selectBookmarkAll()  {
  const checkboxes 
       = document.getElementsByName('bookmark');
  const all 
       = document.getElementsByName('bookmarkall');
  //console.log(all[0].checked)
  checkboxes.forEach((checkbox) => {
    checkbox.checked = all[0].checked;
  })
}

const searchword = (e) => {
  setEmpty(false);
  setPage(1);
  const value = e.target.getAttribute("value");
  console.log(value);
  setChosen(value);
  axios.post('https://gw.thegmmedical.com:5003/api/getaddr_byword',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        input: value
      },
    })
      .then(function (response){
      console.log(response.data);
      /*var tempArray = [];
      for (let i =0;i<response.data.length;i++){
  
          console.log(response.data[i].name);
          tempArray.push(response.data[i].name + response.data[i].email);
  
      }*/
      //setData(response.data);
      setfilterData(response.data);

      if (response.data.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (response.data.length <= 5) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
  
}
function showAll(){
  setChosen("전체");
  setEmpty(false);
  getData();
}



function delContacts(){
  console.log(CheckList);
  var array = []
  var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

  for (var i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].value!=="selectall"){
    array.push(checkboxes[i].value)
    }
  }
  console.log(array);
  if (array[0].value==="selectall"){
    array.shift();
  }
  const msg ={
    nos : array
  }
  var result = window.confirm("선택한 연락처를 삭제하시겠습니까?");
  if(result){

    axios.post('https://gw.thegmmedical.com:5003/api/delcontacts', msg)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

}

function delBookmark(){
  var array = []
  const checkboxes 
       = document.getElementsByName('bookmark');
  
    for (var i = 0; i < checkboxes.length; i++) {
    console.log(checkboxes[i]);  
        if (checkboxes[i].checked === true){
          array.push(checkboxes[i].value)
        }
      
      
    }
    console.log(array);
    if (array[0].value==="bookmarkall"){
      array.shift();
    }
    const msg ={
      nos : array
    }
  console.log("hey", msg);
  var result = window.confirm("선택한 연락처를 즐겨찾기에서 삭제하시겠습니까?");
  if(result){

    axios.post('https://gw.thegmmedical.com:5003/api/del_addr_bookmark', msg)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

}

  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
  <AddressList changeBoard={(value)=>{setfilterData(value);
       if (value.length === 0) {
         setEmpty(true);
       } else {
         setEmpty(false);
         if (value.length <= 5) {
           setIsPage(false);
         } else {
           setIsPage(true);
         }
       }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);}} changeBoardNum={(value)=>{setBoardNum(value);}}/>        </div>
      )}
    <div className="content-wrap">
    
     
     <div className="outer_wrapper addr" style={{justifyContent:"flex-start"}}>

     <div className="side-menu">
       <AddressList changeBoard={(value)=>{setfilterData(value);
       if (value.length === 0) {
         setEmpty(true);
       } else {
         setEmpty(false);
         if (value.length <= 5) {
           setIsPage(false);
         } else {
           setIsPage(true);
         }
       }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);}} changeBoardNum={(value)=>{setBoardNum(value);}}/>
   
   </div>
       <div className="main_content">
       
       <div className="board_wrapper">
         <form name="form1" id="form1" method="post">
                 <div className="board_container">
                     <div id="content" className="content">
                         
                     <div className="">
                       <div className="btns" style={{display:"flex",justifyContent:"space-between", width:"100%", margin:"0 0 20px 0", marginBottom:"20px"}}>

           
<h3 style={{marginRight:"10px", display:"flex", alignItems:"center"}}>즐겨찾는 연락처 <AiFillStar style={{color:"blue", marginLeft:"5px"}}/> </h3>






           
<div className="btn" style={{display:"flex", alignItems:"center"}} onClick={()=>delBookmark()}>즐겨찾기 삭제  </div>




</div>

</div>
                        
                         <div className="addr_table">
                        
                         
                 <table class="contacts table bd-b">
                 <thead class="thead-dark">
                 <tr class="tx-center">
                   <th class="wd-80 xl-hidden">
     <input type='checkbox'
      name='bookmarkall' 
      value='bookmarkall'
      onClick={selectBookmarkAll}/></th>
                   <th class="">이름</th>
                   <th class="wd-150 xl-hidden">휴대폰</th>
                   <th class="wd-120 xl-hidden">이메일</th>
                   <th class="wd-80 xl-hidden">회사</th>
                   <th class="wd-80 xl-hidden">직급</th>
                   <th class="wd-80 xl-hidden">부서</th>

                   <th class="wd-80 xl-hidden">fax</th>
                   <th class="wd-80 xl-hidden">그룹</th>

                 </tr>
                 </thead>
                 <tbody>
                    {bookmarks.length===0 ?
                    <td colSpan={9} style={{padding:"50px 0"}}>즐겨찾는 연락처가 없습니다.</td>
                  :
                               bookmarks.slice(offsetBookmark, offsetBookmark + limit)
                                 .map(
                                   ({
                                     ab_no, no, name, phone, email, address, company, job_name, d_name, fax, note, group_no, g_name
                                   }) => (
                                     ( <tr key={no}>
                                     <td>
                                     <input type='checkbox' 
                                     name='bookmark' 
                                     value={no}
                                     onChange={(e) => onBookmarkChangeEach(e, no)} checked={bookmarkList.includes(no)}/> 

                                     </td>
                                     <td>
                               
                                     <Link to="/addrview" state={{no: no}}>{name}</Link>
                                       </td>
                                     
                                       <td>
                                       {phone}
                                       </td>
                                       <td>
                                       {email}
                                       </td>
                                       <td>
                                       {company}
                                       </td>
                                       <td>
                                       {job_name}
                                       </td>
                                       <td>
                                       {d_name}
                                       </td>
                                       <td>
                                       {fax}
                                       </td>
                                       <td>
                                       {g_name}
                                       </td>
                                     </tr>)
                                    
                                   )
                                 )
                    }
                 </tbody>
               </table>
                         
                           <div style={{width:"100%",textAlign:"right"}}>
                   {isloggedIn && (
                     <Link to="write"
                       style={{
                         padding: "10px 30px",
                         marginTop: "20px",
                         backgroundColor: "#555",
                         color: "white",
                         textAlign: "center",
                         position:"relative",
                         display:"inline-block",
                         width:"105px",
                         cursor: "pointer",
                       }}
                     >
                     </Link>
                   )}
                   </div>
                 </div>
                 {isPageBookmark && (
                   <div className="paging">
                     <Pagination
                       postsPerPage={limit}
                       totalPosts={bookmarks.length}
                       page={pageBookmark}
                       paginate={setPageBookmark}
                     ></Pagination>
                     {/*<Pagination
                   total={data.length}
                   limit={limit}
                   page={page}
                   setPage={setPage}
                   />*/}
                   </div>
                 )}


<div className="board_top">
                       <div className="btns" style={{display:"flex",justifyContent:"start", alignItems:"center"}}>

           
                       <h3 style={{marginRight:"10px"}}>전체 개인 주소록</h3> <p style={{marginRight:"10px"}}>(총 {data.length}건)</p>


            
<div className="btn" style={{marginRight:"10px"}} onClick={()=>addBookmark()}>즐겨찾기 추가</div>
<div className="btn" style={{marginRight:"10px"}} onClick={()=>delContacts()}>삭제</div>






</div>

                             {/*<label>
                           페이지 당 게시물 수:&nbsp;
                           <select
                           type="number"
                           value={limit}
                           onChange={({ target: { value } }) => setLimit(Number(value))}
                           >
                           <option value="10">10</option>
                           <option value="12">12</option>
                           <option value="20">20</option>
                           <option value="50">50</option>
                           <option value="100">100</option>
                           </select>
                       </label>*/}
                             <div className="bt_srch" style={{marginBottom:"0"}}>
                               <div className="bts_slct">
                                 <select
                                   id="u_shcate"
                                   name="search_item"
                                   className="select"
                                   value={groupOptions}
                                   onChange={selectGroupHandle}
                                   
                                 >
                                   <option value="">전체 주소록</option>
                                   {addrs.map((element,index)=>(
                                   <option value={element.no}>{element.g_name}</option>
                                 ))}
                             {department.map((element,index)=>(
                                   <option value={element.no}>{element.g_name}</option>
                                 ))}
                                 {myGroups.map((element,index)=>(
                                   <option value={element.no}>{element.g_name}</option>
                                 ))}
                                 </select>
                               </div>
                               <div className="bts_slct">
                                 <select
                                   id="u_shcate"
                                   className="select"
                                   value={options}
                                   onChange={selectHandle}
                                 >
                                   <option value="name">이름</option>
                                   <option value="phone">휴대폰</option>
                                   <option value="email">이메일</option>
                                   <option value="company">회사</option>
                                   <option value="job_name">직급</option>
                                   <option value="d_name">부서</option>

                                 </select>

                               </div>
                               <div className="bt_input">
                                 <input
                                   type="text"
                                   id="search_content"
                                   name="search_content"
                                   value={search || ''}
                                   placeholder="검색어를 입력하세요"
                                   onChange={onChangeSearch}
                                 />
                               </div>

                               <button
                                 type="submit"
                                 className="search_btn"
                                 value="검색"
                                 onClick={onSearch}
                               >
                                 검색
                               </button>
                             </div>
                         </div>

<div className="directory-select">
                   <div className="directory-search">
                   <div className="search-word">
                       <span onClick={()=>showAll()} className={chosen == "전체" ? "selected" : "" } value="전체">전체</span>

                       <span onClick={searchword} className={chosen == "가" ? "selected" : "" } value="가">ㄱ</span>
                       <span onClick={searchword} className={chosen == "나" ? "selected" : "" } value="나">ㄴ</span>
                       <span onClick={searchword} className={chosen == "다" ? "selected" : "" } value="다">ㄷ</span>
                       <span onClick={searchword} className={chosen == "라" ? "selected" : "" } value="라">ㄹ</span>
                       <span onClick={searchword} className={chosen == "마" ? "selected" : "" } value="마">ㅁ</span>
                       <span onClick={searchword} className={chosen == "바" ? "selected" : "" } value="바">ㅂ</span>
                       <span onClick={searchword} className={chosen == "사" ? "selected" : "" } value="사">ㅅ</span>
                       <span onClick={searchword} className={chosen == "아" ? "selected" : "" } value="아">ㅇ</span>
                       <span onClick={searchword} className={chosen == "자" ? "selected" : "" } value="자">ㅈ</span>
                       <span onClick={searchword} className={chosen == "차" ? "selected" : "" } value="차">ㅊ</span>
                       <span onClick={searchword} className={chosen == "카" ? "selected" : "" } value="카">ㅋ</span>
                       <span onClick={searchword} className={chosen == "타" ? "selected" : "" } value="타">ㅌ</span>
                       <span onClick={searchword} className={chosen == "파" ? "selected" : "" } value="파">ㅍ</span>
                       <span onClick={searchword} className={chosen == "하" ? "selected" : "" } value="하">ㅎ</span>
                       <span onClick={searchword} className={chosen == "a" ? "selected" : "" } value="a">a-z</span>
                       <span onClick={searchword} className={chosen == "0" ? "selected" : "" } value="0">0-9</span>


                   </div>
                   </div>
              
               </div>
<div className="addr_table">
<table class="contacts table bd-b">
                 <thead class="thead-dark">
                 <tr class="tx-center">
                   <th class="wd-80 xl-hidden">
     <input type='checkbox'
      name='all' 
      value='selectall'
      onClick={selectAll}/></th>
                   <th class="">이름</th>
                   <th class="wd-150 xl-hidden">휴대폰</th>
                   <th class="wd-120 xl-hidden">이메일</th>
                   <th class="wd-80 xl-hidden">회사</th>
                   <th class="wd-80 xl-hidden">직급</th>
                   <th class="wd-80 xl-hidden">부서</th>

                   <th class="wd-80 xl-hidden">fax</th>
                   <th class="wd-80 xl-hidden">그룹</th>

                 </tr>
                 </thead>
                 <tbody>
            
                 {filterData.length===0 && (<td colSpan={9} style={{padding:"50px 0"}}>데이터가 없습니다</td>)}

                               {filterData
                                 .slice(offset, offset + limit)
                                 .map(
                                   ({
                                     no, name, phone, email, address, company, job_name, d_name, fax, note, group_no, g_name
                                   }) => (
                                     ( <tr key={no}>
                                     <td>
                                     <input type='checkbox' 
                                     name='contact' 
                                     value={no}
                                     onChange={(e) => onChangeEach(e, no)} checked={CheckList.includes(no)}/> 

                                     </td>
                                     <td>
                               
                                     <Link to="/addrview" state={{no: no}}>{name}</Link>
                                       </td>
                                     
                                       <td>
                                       {phone}
                                       </td>
                                       <td>
                                       {email}
                                       </td>
                                       <td>
                                       {company}
                                       </td>
                                       <td>
                                       {job_name}
                                       </td>
                                       <td>
                                       {d_name}
                                       </td>
                                       <td>
                                       {fax}
                                       </td>
                                       <td>
                                       {g_name}
                                       </td>
                                     </tr>)
                                    
                                   )
                                 )}

                 </tbody>
               </table>
               </div>
                     </div>
                 </div>


                 {isPage && (
                   <div className="paging">
                     <Pagination
                       postsPerPage={limit}
                       totalPosts={filterData.length}
                       page={page}
                       paginate={setPage}
                     ></Pagination>
                     {/*<Pagination
                   total={data.length}
                   limit={limit}
                   page={page}
                   setPage={setPage}
                   />*/}
                   </div>
                 )}


         </form>
       </div>
     </div>
           
           
               
               
               

      </div>
    </div>
   </div>

  );
};

export default Board;
