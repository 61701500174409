
import { NavLink, Link } from "react-router-dom";
import moment from 'moment';
import React, { useState, useEffect, useRef } from "react";
import user from "../components/img/user.png";
import axios from "axios";
import {HiMail, HiPhone } from "react-icons/hi";
axios.defaults.withCredentials = true; 
const initialValues = {
  name:"",
  workdate:"",

  startTime:"",
  endTime:"",
  type:"",
  location:""
};
const CoworkerInfo = ({data, closePopup}) => {  
  

  const [showPopup, setShowPopup] = useState(false);
  const [selectedMember, setSelectedMember] = useState([]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
};
  useEffect(()=>{

    var userid = data.substring(data.indexOf("<")+1, data.lastIndexOf("@"));
    console.log(
      "userid", userid
    );
    const info={
      userid: userid
    }
    axios.post("https://gw.thegmmedical.com:5003/api/get_user_by_id", info).then((response) => {
      console.log("gruop schedule", response.data);
      setSelectedMember(response.data[0]);

    });
  }, []);
      

  return (
    <div className="wrapper" style={{position:"absolute", bottom:"0", height:"100%", margin:"auto", top:"0", right:"0",
    width:"100%", maxWidth:"450px", left:"0", zIndex:"9999"}}>

    



    {selectedMember !==undefined && (

<div className="">
      <div className="employee_inner">
      
        <div className="addUser">

        <div style={{display:"flex"}}>
        


        <Link to="/write" state={{ to_addr : selectedMember.email }} style={{width:"50%"}}>
          
          <button style={{border:"1px solid #ddd", background:"#fff", padding:"10px", width:"100%", 
        display:"flex", justifyContent:"center", alignItems:"center"}}><HiMail style={{color:"#bdd6ff"}}/>이메일</button>
        </Link>

        <a href="tel:010-2112-8935" style={{width:"50%"}}>
          <button style={{border:"1px solid #ddd", background:"#fff", padding:"10px", width:"100%", 
        display:"flex", justifyContent:"center", alignItems:"center"}}><HiPhone style={{color:"#bdd6ff"}}/>전화</button>
        </a>
       
        </div>  
        <div className="addpic" style={{marginTop:"20px"}}>

        
        <label htmlFor="photo-upload" className="custom-file-upload fas">

{selectedMember.profile_link !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={selectedMember.profile_link} alt="profile_pic"/>
</div>
: <img for="photo-upload" src={user} alt="profile_pic"/>
}


</label>
        </div>
        
        <div className="addInfo">
        <div className="user_input">
        <label htmlFor="name" className="">
        이름(한글) : 
        
        </label>
        <div>{selectedMember.name}</div>

        </div>
        <div className="user_input">
        <label htmlFor="name" className="">
        입사일자 : 
      
        </label>
        <div>{selectedMember.d_name}</div>

        </div>
     
       
       

        <span id="check" style={{marginLeft:"35%", textAlign:"left"}}></span>

        <div className="user_input">

<label htmlFor="tel" className="">
이메일 : 

</label>
<div>{selectedMember.email}</div>
</div>

<div className="user_input">

        <label htmlFor="tel" className="">
        전화번호 : 
        
        </label>
        <div>{selectedMember.extension}</div>
        </div>
        <div className="user_input">

        <label htmlFor="tel" className="">
        핸드폰번호 : 
        
        </label>
        <div>{selectedMember.tel}</div>
        </div>
        <div className="user_input">

<label htmlFor="tel" className="">
개인팩스번호 : 

</label>
<div>{selectedMember.fax}</div>
</div>

        <div className="user_input">

        <label htmlFor="pw" className="">
        부서 :
        </label>
        <div>{selectedMember.d_name}</div>
        </div>
       
        <div className="user_input">
        <label htmlFor="pw" className="">
        직위 :
        </label>
        <div>{selectedMember.job_name}</div>
        </div>


        <div className="user_input">

<label htmlFor="tel" className="">
근무처 : 

</label>
<div>{selectedMember.place}</div>
</div>



        </div>
        <div className="buttons" style={{justifyContent:"center"}}>
        <button style={{padding:"5px 20px", cursor:"pointer"}} onClick={()=>closePopup()}>닫기</button>
        </div>
        </div>
      
        
      </div>
      
    </div>

)}


 
    </div>
  );
};

export default CoworkerInfo;
