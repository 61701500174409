import React, { useRef, useEffect,useMemo, useState } from "react";
import Folder from "./Folder";
import FolderTree from 'react-folder-tree';
import axios from "axios";
import "./css/contacts.css";
import 'react-folder-tree/dist/style.css';
import user from "./img/user.png";
axios.defaults.withCredentials = true; 

const Org = () => {
    const onTreeStateChange = (state, event) => console.log(state, event);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [toList, setTo] = useState([]);
    const [ccList, setCC] = useState([]);
    const [bccList, setBCC] = useState([]);
    const [toDel, setToDel] = useState([]);
    const [showHRCard, setShowHRCard] = useState(false);
    const [userArr, setUserArr] = useState([]);
    const [testData, setTestData] = useState([]);
    const [showUser, setShowuser] = useState(false);
    const [selectedMember, setSelectedMember]= useState();
    const [authAll, setAuthAll]= useState(false);

    const togglePopup=()=>{
      setShowHRCard(!showHRCard);
    }

    function lookingForNodeWithParent( nodes, parentId ) {

        var arrayToReturn = [];
      
        for( var i = 0, length = nodes.length; i < length; i++ ) {
          if( nodes[i].d_parent === parentId ) {
              var node = nodes[i];
            
            arrayToReturn.push({
              name: node.d_name,
              children: lookingForNodeWithParent( nodes, node.d_no )
            });
          }
        }
        
        return arrayToReturn;
      }
      
      
      useEffect(() => {
     
        fetch("http://gw.thegmmedical.com:5003/api/getdepartment_group", {
            method: "get", // 통신방법
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
          })
          .then((res) => res.json())
          .then((json) => {
            console.log(json);  
      console.log(lookingForNodeWithParent( json, null ));
            //setBoxes(json[0].boxes);
            var array = lookingForNodeWithParent( json, null );
            //let newData = array.map(el => el[0]);
                //console.log(newData);
            return setTestData(array[0]);
    
            //console.log(boxes.boxes);
            //console.log(boxes.boxes);
          })
          .catch(() => {});
          let userNos = [];

         

          axios.get("http://gw.thegmmedical.com:5003/api/get_departments").then((response) => {
          // setNotice(response.data);
            console.log("user", response.data);
            let users = response.data;
           

            axios.get("http://gw.thegmmedical.com:5003/api/get_view_auth").then((response) => {
              // setNotice(response.data);
               console.log("view_auth...", response.data[0]);
               let user = response.data[0];
               if (response.data[0].view_auth === "department"){
                let filtered = users.filter((item=> item.d_no === user.d_no));
                filtered.map((item, idx) =>{
                  userNos.push(item['user_no']); 
                });
               }else if (response.data[0].view_auth === "sub"){

                  const
                  getChildren = d_no => (relations[d_no] || []).flatMap(o => [o, ...getChildren(o.d_no)]),
                  relations = users.reduce((r, o) => {
                      (r[o.d_parent] ??= []).push(o);
                      return r;
                  }, {});
    
                  let children = getChildren(user.d_no);
    
                  console.log("chilren ... ", children);
                  let filtered = users.filter((item=> (item.d_no === user.d_no || children.some(e=> item.user_no == e.user_no))));
    
                 
                  filtered.map((item, idx) =>{
                    userNos.push(item['user_no']); 
                  });
               }else if (response.data[0].view_auth === "myself"){
                userNos.push(user.user_no); 
               }else if (response.data[0].view_auth === "all"){
                setAuthAll(true);
               }
               setUserArr(userNos);
            });
            
          });
         
      }, []);
    
   

      const [selectedItem, setSelectedItem] = useState(null);

   

      const onNameClick = ({ defaultOnClick, nodeData }) => {
        defaultOnClick();
      
        const {
          // internal data
          path, name, checked, isOpen, children
          // custom data
        } = nodeData;
      
        

        const item = name;
        const tempArray = [...options, item];
        setOptions(tempArray);

        axios.post('http://gw.thegmmedical.com:5003/api/getcontacts',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              folder_name: name
            },
          })
    .then(function (response){
      console.log("결과는요", response.data);
   
      setOptions(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

      };
      const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
        axios.post('http://gw.thegmmedical.com:5003/api/getcontactsbyinput',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              input: e.target.value
            },
          })
            .then(function (response){
            console.log(response.data);
         
            setOptions(response.data);
            })
            .catch(function (error){
            console.log(error);
            })
            .then(function (){
            //always executed
            });
        }
      }
      const searchword = (e) => {
        const value = e.target.getAttribute("value");
        console.log(value);
        axios.post('http://gw.thegmmedical.com:5003/api/getcontactsbyword',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              input: value
            },
          })
            .then(function (response){
            console.log(response.data);
          
            setOptions(response.data);
            })
            .catch(function (error){
            console.log(error);
            })
            .then(function (){
            //always executed
            });
        
      }
      function getSelectValues(select) {
        var result = [];
        var options = select && select.options;
        var opt;
      
        for (var i=0, iLen=options.length; i<iLen; i++) {
          opt = options[i];
          
            var item = {name : opt.value || opt.text, id : opt.index};
          if (opt.selected) {
            console.log("selected");
            result.push(item); console.log("index..", result);
          }
        }
       
        return result;
      }
     
      
      const toggleUser = () => {
        
        if (showUser === true){
            var aTags = document.getElementsByTagName("option");
    console.log("...job", selectedMember.email);
var searchText = selectedMember.email;
var found;

for (var i = 0; i < aTags.length; i++) {
  console.log(aTags[i].className);
  if (aTags[i].className === searchText) {
    
    found = aTags[i];
    break;
  }
}
console.log(found);
found.click();
        }else{
        }
        setShowuser(!showUser); 
    };
    function popupwindow(url, title, w, h, no) {
      var user_no = encodeURIComponent(no);
      url = url + "?no=" + user_no;

      var left = (window.screen.width/2)-(w/2);
      var top = (window.screen.height/2)-(h/2);
      return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
    } 
    return (
        <div>

        <div style={{overflowY:"hidden", display:"flex", padding:"20px"}} className="org">
            
            <div className="directory-wrap">
            <div className="title">주소록</div>    
                <div className="directory-list">
                <FolderTree
      data={ testData }
      onChange={ onTreeStateChange }
      onNameClick={ onNameClick }
      showCheckbox={ false } 
      readOnly
      />

                </div>
                <div className="directory-select">
                    <div className="directory-search">
                    <div className="search-word">
                        <span onClick={searchword} value="가">ㄱ</span>
                        <span onClick={searchword} value="나">ㄴ</span>
                        <span onClick={searchword} value="다">ㄷ</span>
                        <span onClick={searchword} value="라">ㄹ</span>
                        <span onClick={searchword} value="마">ㅁ</span>
                        <span onClick={searchword} value="바">ㅂ</span>
                        <span onClick={searchword} value="사">ㅅ</span>
                        <span onClick={searchword} value="아">ㅇ</span>
                        <span onClick={searchword} value="자">ㅈ</span>
                        <span onClick={searchword} value="차">ㅊ</span>
                        <span onClick={searchword} value="카">ㅋ</span>
                        <span onClick={searchword} value="타">ㅌ</span>
                        <span onClick={searchword} value="파">ㅍ</span>
                        <span onClick={searchword} value="하">ㅎ</span>
                    </div>
                    <input type="text" onKeyDown={handleOnKeyPress} style={{marginLeft:"20px"}}></input>
                    </div>
                    <select
                multiple class="m_select pc">
                    { options.map((element, index) => <option className={element.email} key={index} onClick={() => {setSelectedMember(element);}}>
                      {element.name} {element.job_name} ({element.d_name})</option>) }
                </select>

                <div style={{border:"1px solid #ddd", padding:"0 1em"}}>
                  <ul>
                  { options.map((element, index) => <li className={(selectedMember !==undefined  && (selectedMember.user_no === element.user_no)) ? 'selected_member' : null} key={index} 
                  style={{cursor:"pointer"}}
                  onClick={() => {setSelectedMember(element);}}>
                      {element.name} {element.job_name} ({element.d_name})</li>) }

                  </ul>
                </div>
                </div>
                
               
            </div>
   
   
        </div>
{selectedMember !==undefined && (

<div className="employee">
      <div className="employee_inner">
      {(userArr.includes(selectedMember.user_no) || authAll === true) ?
      <div className="title" style={{display:"flex", justifyContent:"space-between", padding:"0 20px",
      boxSizing:"border-box"}}>{selectedMember.name} {selectedMember.job_name} 
      <button style={{marginLeft:"50px",display:"flex", border:"2px solid #fff", backgroundColor:"transparent", color:"white", 
      alignItems:"center", lineHeight:"20px", cursor:"pointer", padding:"1px 10px"}} onClick={() => popupwindow("/hr_card", "인사카드 열람", 1400, 700, selectedMember.user_no)}>인사카드 열람</button></div>
    :<div className="title">{selectedMember.name} {selectedMember.job_name} 
    </div>}
        <div className="addUser">
          
        <div className="addpic">

        
        <label htmlFor="photo-upload" className="custom-file-upload fas">

{selectedMember.profile_link !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={selectedMember.profile_link} alt="profile_pic"/>
</div>
: <img for="photo-upload" src={user} alt="profile_pic"/>
}


</label>
        </div>
        
        <div className="addInfo">
        <div className="user_input">
        <label htmlFor="name" className="">
        이름(한글) : 
        
        </label>
        <div>{selectedMember.name}</div>

        </div>
        <div className="user_input">
        <label htmlFor="name" className="">
        입사일자 : 
      
        </label>
        <div>{selectedMember.d_name}</div>

        </div>
     
       
       

        <span id="check" style={{marginLeft:"35%", textAlign:"left"}}></span>

        <div className="user_input">

<label htmlFor="tel" className="">
이메일 : 

</label>
<div>{selectedMember.email}</div>
</div>

<div className="user_input">

        <label htmlFor="tel" className="">
        전화번호 : 
        
        </label>
        <div>{selectedMember.extension}</div>
        </div>
        <div className="user_input">

        <label htmlFor="tel" className="">
        핸드폰번호 : 
        
        </label>
        <div>{selectedMember.tel}</div>
        </div>
        <div className="user_input">

<label htmlFor="tel" className="">
개인팩스번호 : 

</label>
<div>{selectedMember.fax}</div>
</div>

        <div className="user_input">

        <label htmlFor="pw" className="">
        부서 :
        </label>
        <div>{selectedMember.d_name}</div>
        </div>
       
        <div className="user_input">
        <label htmlFor="pw" className="">
        직위 :
        </label>
        <div>{selectedMember.job_name}</div>
        </div>


        <div className="user_input">

<label htmlFor="tel" className="">
근무처 : 

</label>
<div>{selectedMember.place}</div>
</div>



        </div>
        <div className="buttons" style={{justifyContent:"center"}}>
        <button style={{padding:"5px 20px", cursor:"pointer"}}onClick={()=>setSelectedMember()}>닫기</button>
        </div>
        </div>
      
        
      </div>
      
    </div>

)}
    
        </div>
    )
}
export default Org;
