
import { NavLink, Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import BoardList from "../components/side/BoardList";
import axios from "axios";
import "./css/allsearch.css";
import FolderTree, { testData } from 'react-folder-tree';
import { HiPencilAlt } from "react-icons/hi";
import { BsPin } from "react-icons/bs";
import {GiHamburgerMenu} from "react-icons/gi";
import Pagination from "./Pagination";
//import hi from '../excel/93.json';


const AllSearch = () => {
  const [boardData, setBoardData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [addrData, setAddrData] = useState([]);

  const [isActive, setActive] = useState(false);

  const [parent, setParent] = useState("");
  const [name, setName] =useState("");
  const [num, setNum] = useState(0);
  const [lev, setLev] = useState(0);
  const [selected, setSelected]= useState();
  const [boardN, setBoardN] = useState("");
  const [boardNum, setBoardNum] = useState();
  const [options, setOption] = useState("subject");
  const [filterData, setfilterData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [term, setTerm] = useState("");
  const [teamFolders, setTeamFolders] = useState([]);
  const [notice, setNotice] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [isPage, setIsPage] = useState(false);
  const [isloggedIn, setLoggedIn] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [more, setMore] = useState(false);
  const [category, setCategory] = useState("");


  const offset = (page - 1) * limit;
  const { state } = useLocation();


  
    
  useEffect(() => {
    console.log(state.term);

    setTerm(state.term);
    const msg = {
      term : state.term
    }
    axios.post("http://gw.thegmmedical.com:5003/api/board_search", msg).then((response) => {
      console.log("board...", response.data);
      setBoardData(response.data);
      
    });
    axios.post("http://gw.thegmmedical.com:5003/api/form_search", msg).then((response) => {
      console.log("board...", response.data);
      setFormData(response.data);
      
    });
    axios.post("http://gw.thegmmedical.com:5003/api/addr_search", msg).then((response) => {
      console.log("addr...", response.data);
      setAddrData(response.data);
      
    });
  }, []);

  function search(){
    const msg = {
      term : term
    }
    axios.post("http://gw.thegmmedical.com:5003/api/board_search", msg).then((response) => {
      console.log("board...", response.data);
      setBoardData(response.data);
      
    });
    axios.post("http://gw.thegmmedical.com:5003/api/form_search", msg).then((response) => {
      console.log("board...", response.data);
      setFormData(response.data);
      
    });
    axios.post("http://gw.thegmmedical.com:5003/api/addr_search", msg).then((response) => {
      console.log("addr...", response.data);
      setAddrData(response.data);
      
    });
  }

  function showMore(cate){
    setMore(true);
    setCategory(cate)
  }


  
  return (
    <div className="wrapper">

     <div className="content-wrap">
     
      
      <div className="outer_wrapper all_search" style={{justifyContent:"flex-start"}}>
 

        <div className="board_wrapper">
            <div id="content" className="content">
        
              <div className="box">
              <h3> 통합검색 </h3>
              <div>
              <table>
              <tr style={{display:"flex"}}>
                <input type="text" name="SearchTxt" class="tform_big" value={term} onChange={(e)=>setTerm(e.target.value)}/>
                  <button onClick={(e)=>search()}>검색</button>
                  </tr>
              </table>
              </div>
              </div>   
                      
    <div className="board_wrap" style={{padding:"0 30px", boxSizing:"border-box"}}> 
    <div className="board_list">



    {more === true && (
    category === "board" && (
  <div>
  <div className="title">
  <div> <h3>게시판</h3> <span>총 {boardData.length}건</span></div> <button onClick={()=>setMore(false)}>통합검색 이동</button>
  </div>
  <table>
    <tbody>
    {boardData.map((ele,idx)=>(
      <tr>
      <td>
        <Link to="/boardview">{ele.subject}</Link>
        <br></br>
        <div className="info"><span>작성자 : {ele.author} , 작성일 : {ele.date_created} , 게시판 그룹 : {ele.bg_name}</span></div>
      </td>
    </tr>
    ))}

    </tbody>
  </table>
  </div>
  )  
  )}

{more === true && (
    category === "form" && (
  <div>
  <div className="title">
  <div> <h3>문서양식</h3> <span>총 {boardData.length}건</span></div> <button onClick={()=>setMore(false)}>통합검색 이동</button>
  </div>
  <table>
    <tbody>
    {formData.map((ele,idx)=>(
      <tr>
      <td>
        <Link to="/boardview">{ele.form_name}</Link>
        <br></br>
        <div className="info"><span>양식 분류 : {ele.fgroup_name} , 작성자 : [{ele.d_name}] {ele.name} {ele.job_name}</span></div>
      </td>
    </tr>
    ))}

    </tbody>
  </table>
  </div>
  )  
  )}

  
{more === true && (
    category === "addr" && (
  <div>
  <div className="title">
  <div> <h3>인맥관리</h3> <span>총 {boardData.length}건</span></div><button onClick={()=>setMore(false)}>통합검색 이동</button>
  </div>
  <table>
    <tbody>
    {addrData.map((ele,idx)=>(
      <tr>
      <td>
      <Link to="/boardview">{ele.name}</Link>
        <br></br>

        <div className="info"><span>회사 : {ele.company}, 전화번호 : {ele.phone}, 이메일 : {ele.email} ,부서 : {ele.d_name} ,직급 : {ele.job_name} </span>
        <br></br>
        <span>작성자 : [{ele.dname}] {ele.name} {ele.jname} </span></div>      </td>
    </tr>
    ))}

    </tbody>
  </table>
  </div>
  )  
  )}

{more === false && boardData.length !==0 && (
  <div>
  <div className="title">
  <div> <h3>게시판</h3> <span>총 {boardData.length}건</span></div> <button onClick={(e)=>showMore("board")}>검색결과 더보기</button>
  </div>
  <table>
    <tbody>
    {boardData.filter((item, index) => index < 5).map((ele,idx)=>(
      <tr>
      <td>
        <Link to="/boardview">{ele.subject}</Link>
        <br></br>
        <div className="info"><span>작성자 : {ele.author} , 작성일 : {ele.date_created} , 게시판 그룹 : {ele.bg_name}</span></div>
      </td>
    </tr>
    ))}
    
    </tbody>
  </table>
  </div>
  )}

{more === false && formData.length !==0 && (
  <div>
  <div className="title">
  <div> <h3>문서양식</h3> <span>총 {formData.length}건</span></div> <button onClick={(e)=>showMore("form")}>검색결과 더보기</button>
  </div>
  <table>
    <tbody>
    {formData.filter((item, index) => index < 5).map((ele,idx)=>(
      <tr>
      <td>
        <Link to="/boardview">{ele.form_name}</Link>
        <br></br>
        <div className="info"><span>양식 분류 : {ele.fgroup_name} , 작성자 : [{ele.d_name}] {ele.name} {ele.job_name}</span></div>
      </td>
    </tr>
    ))}
    
    </tbody>
  </table>
  </div>
  )}
{more === false && addrData.length !==0 && (
  <div>
  <div className="title">
  <div> <h3>인맥관리</h3> <span>총 {addrData.length}건</span></div> <button onClick={(e)=>showMore("addr")}>검색결과 더보기</button>
  </div>
  <table>
    <tbody>
    {addrData.filter((item, index) => index < 5).map((ele,idx)=>(
      <tr>
      <td>
      <Link to="/boardview">{ele.name}</Link>
        <br></br>

        <div className="info"><span>회사 : {ele.company}, 전화번호 : {ele.phone}, 이메일 : {ele.email} ,부서 : {ele.d_name} ,직급 : {ele.job_name} </span>
        <br></br>
        <span>작성자 : [{ele.dname}] {ele.name} {ele.jname} </span></div>      </td>
    </tr>
    ))}
    
    </tbody>
  </table>
  </div>
  )}
 

                  </div>
                  {isPage && (
                    <div className="paging">
                      <Pagination
                        postsPerPage={limit}
                        totalPosts={filterData.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>
                    </div>
                  )}
            </div>
            </div>
        </div>
            
            
                
                
                

      </div>
     </div>
    </div>

  );
};

export default AllSearch;
