import axios from "axios";
import { useNavigate } from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/gi";
import React, { useState, useEffect, useRef } from "react";
import AddressList from "../components/side/AddressList";
import ExcelJS from 'exceljs'
import * as XLSX from "xlsx";
axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {이름, 핸드폰, 이메일, 회사, 부서, 직급, fax, 비고}= rowsData;
    console.log(rowsData, index);
    return (
      <tr key={index}>
        <td data-label="이름" >
          <input
            type="text"
            value={이름}
            onChange={(event) => onValUpdate(index, event)}
            name="이름"
            className="form-control"
          />
        </td>
        <td data-label="핸드폰">
          <input
            type="text"
            value={핸드폰}
            onChange={(event) => onValUpdate(index, event)}
            name="핸드폰"
            className="form-control"
          />
        </td>
        <td data-label="이메일">
          <input
            type="text"
            value={이메일}
            onChange={(event) => onValUpdate(index, event)}
            name="이메일"
            className="form-control"
          />
        </td>
     
    
        <td data-label="회사">
          <input
            type="text"
            value={회사}
            onChange={(event) => onValUpdate(index, event)}
            name="회사"
            className="form-control"
            
          />
        </td>

        <td data-label="부서">
          <input
            type="text"
            value={부서}
            onChange={(event) => onValUpdate(index, event)}
            name="부서"
            className="form-control"
            
          />
        </td>

        <td data-label="직급">
          <input
            type="text"
            value={직급}
            onChange={(event) => onValUpdate(index, event)}
            name="직급"
            className="form-control"
            
          />
        </td>

        <td data-label="fax">
          <input
            type="text"
            value={fax}
            onChange={(event) => onValUpdate(index, event)}
            name="fax"
            className="form-control"
            
          />
        </td>
        <td data-label="비고">
          <input
            type="text"
            value={비고}
            onChange={(event) => onValUpdate(index, event)}
            name="비고"
            className="form-control"
            
          />
        </td>
    
        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

function Languages({ user_no }) {
  var data = {
    이름:"", 핸드폰:"", 이메일:"", 회사:"", 부서:"", 직급:"", fax:"", 비고:""
  };
  
  var temp = [];
  temp.push(data);

  const [rows, initRow] = useState(temp);
  const [addrs, setAddrs] = useState([]);
  const [department, setDepartment] = useState([]);
  const [myGroups, setMyGroups] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [groupNo, setGroupNo] = useState("");
  const [sheetData, setSheetData] = useState([]);
  const importRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    
    axios.get('https://gw.thegmmedical.com:5003/api/getaddrs',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    setAddrs(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

   
    axios.get('https://gw.thegmmedical.com:5003/api/get_teamaddr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setDepartment(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/get_user_addr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setMyGroups(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
  }, []);
  

  const addRowTable = () => {
    const data = {
      이름:"", 핸드폰:"", 이메일:"", 회사:"", 부서:"", 직급:"", fax:"", 비고:""
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    console.log("data is...", data);
    console.log("data index and name", i, data[i][name]);
    data[i][name] = value;
    initRow(data);
  };

  function addExperience(){

    if (groupNo === "") {
      alert("주소록을 선택해주세요.");
      return false;
    } 
   
    for (var i=0;i<rows.length;i++){
      console.log(rows[i]);
      rows[i].group_no = groupNo;
    }

    console.log("last rows", rows);
    const msg = {
      data : rows
    }
    axios.post('https://gw.thegmmedical.com:5003/api/addr_add_rows', msg)
    .then(function (response){
      console.log(response);
      alert("추가되었습니다");
      navigate("/addr_list/"+groupNo);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  function renameKey ( obj, oldKey, newKey ) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
  function excelImport(event){
    var input = event.target;
    var reader = new FileReader();
    reader.onload = function(){
        var fileData = reader.result;
        var wb = XLSX.read(fileData, {type : 'binary'});
        wb.SheetNames.forEach(function(sheetName){
	        var rowObj =XLSX.utils.sheet_to_json(wb.Sheets[sheetName]); console.log("결과", rowObj);
          /*let key = Object.keys(rowObj[0]);
          console.log(key);
          rowObj.forEach(function(obj) {
            obj.name = obj[key[0]];
            delete obj[key[0]];
            obj.phone = obj[key[0]];
            delete obj[key[0]];
            //obj.email = obj[key[0]];
            //delete obj[key[0]];
          });
          var objectKey = Object.keys(rowObj);
          
        console.log("the first key is: "  + objectKey)*/

         

    

          
	        initRow(rowObj);
        })
    };
    reader.readAsBinaryString(input.files[0]);
}
  async function handleFileAsync(e) {
    
    const wb = new ExcelJS.Workbook()
    var shData = [];
          const file = e.target.files[0];
          
    const exceldata = await file.arrayBuffer();
          wb.xlsx.load(exceldata)
          .then(wb => {
             //console.log(workbook, 'workbook instance');
  
              wb.eachSheet((sheet, id) => {
                 sheet.eachRow((row, rowIndex) => {
                 //console.log(row);
                 });
              });
              const ws = wb.worksheets[0] // 첫 번째 sheet 선택
      console.log("sheet", ws);
    const options = { includeEmpty: true }
   
     ws.eachRow(options, (row, rowNum) => {
      shData[rowNum-1] = []
      row.eachCell(options, (cell, cellNum) => {
        shData[rowNum-1][cellNum-1] = { value:cell.value, style:cell.style }
      
      
      })
    })
    //console.log("sheeeeeeeeet", sheetData);
    //setShData(sheetData);
    /* data is an ArrayBuffer */
    const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});
  
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
      //console.log(htmlData);
  
    const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
      header:1,
      defval:"",
    })
    
    var sheetnames = workbook.SheetNames;
    for (var i = 0; i<sheetnames.length;i++){
      var sht = workbook.Sheets[sheetnames[i]]["A2"]["s"]["patternType"];
    }
    
  
    
    
    
    
    
    
  
  
  
  
  
  var merges= workbook.Sheets[sheetnames[0]]["!merges"];
  const merge_config={};
  for (let i=0;i<merges.length;i++){
    var title = merges[i]['s']['r']+"_"+merges[i]['s']['c'];
    var rowsize = merges[i]['e']['r']-merges[i]['s']['r'];
    var col = merges[i]['e']['c']-merges[i]['s']['c'];
    var merge={
        r: merges[i]['s']['r'],
        c: merges[i]['s']['c'],
        rs: rowsize+1,
        cs: col+1
    }
    merge_config[title]=merge;
  
  }
  
  
  var rows= workbook.Sheets[sheetnames[0]]["!rows"];
  const rowht={};
  for (let i=0;i<rows.length;i++){
    if (rows[i]!==undefined){
      var row=rows[i]["hpx"];
    rowht[i]=row;
    }
  
    /**/
  
  }
  
  var cols= workbook.Sheets[sheetnames[0]]["!cols"];
  const colht={};
  for (let i=0;i<jsondata[0].length;i++){
    if (cols[i]!==undefined){
      var coll=cols[i]["wpx"];
      colht[i]=coll;
    }
  
    /**/
  
  }
  
       
     
      
      
    
  
  
  });
    
    
    
   
  }

  function exportExcel(){
    const ws = XLSX.utils.json_to_sheet(rows); 
  const wb= XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, "export.xlsx");
  }

  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <AddressList/>
        </div>
      )}
   
<div className="content-wrap">
    
      
    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>

    <div className="side-menu">
       <AddressList/>
    </div>

    <div className="main_content addr">
  
    

    <div className="btns" style={{display:"flex", justifyContent:"space-between", alignItems:"start",
    flexDirection:"column", width:"100%", margin:"0 auto"}}>
            
      <h4 style={{textAlign:"left"}}>새 연락처 추가 </h4>
          <div style={{background:"#fff", width:"100%"}}>
       
          <div style={{display:"flex", width:"100%", alignItems:"center", flexWrap:"wrap"}}>

   
              <div className="btn" style={{color:"black"}} onClick={addRowTable}>
                추가
              </div>
      <div style={{marginLeft:"10px"}}>
              추가할 주소록 그룹 : <select name="group_no" className="formInput" value={groupNo ||''}
                                  onChange={(e)=>setGroupNo(e.currentTarget.value)}>    
      <option value="no">선택 안함</option>
    {addrs.map((element,index)=>(
      <option value={element.no}>{element.g_name}</option>
    ))}
{department.map((element,index)=>(
      <option value={element.no}>{element.g_name}</option>
    ))}
    {myGroups.map((element,index)=>(
      <option value={element.no}>{element.g_name}</option>
    ))}
  </select></div>

  <div class="break"></div>


  <button className="btn excel-btn" onClick={()=>{importRef.current.click()}}>
                엑셀로 불러오기
              </button>
              <input type="file" id="input_dom_element" ref={importRef} onChange={excelImport} style={{display:"none"}} />


              <button className="btn excel-btn" onClick={exportExcel}>
                엑셀로 내보내기
              </button>
              

              </div>

              
      <table style={{width:"100%", marginTop:"20px"}} className="info_summary addr_quick">
        <thead>
          <tr>
          <th>이름</th>
          <th>핸드폰</th>
                <th>이메일</th>
                <th>회사</th>
                <th>부서</th>
                <th>직급</th>
                <th>fax</th>
                <th>비고</th>

                <th className="del">삭제</th>
          </tr>
        </thead>
        <tbody>
          <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            userNo = {user_no}
          />
        </tbody>
      </table>
      <div className="btns" style={{display:"flex", justifyContent:"center", width:"100%", marginBottom:"20px"}}>

<button className="btn" style={{color:"black"}} onClick={()=>addExperience()}>
          저장
        </button>

</div>
</div>
</div>
</div>
</div>
</div>
</div>   
  );
}
export default Languages;