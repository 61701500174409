
import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {FaAddressBook} from "react-icons/fa";
import "../css/setting.css";
const DefaultSetting = () => {
  const [ruleName, setRuleName] = useState("");
  const [emailAddr, setEmailAddr]=useState("");
  const [forward, setForward]=useState(false);
  const [del, setDelete]=useState(true);

  const [boxes, setBoxes]=useState([]);
  const [serverAddr, setServerAddr] = useState("");
  const [serverPort, setServerPort] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [minutes, setMinutes] = useState("30");
  const [mailbox, setMailbox] = useState("");
  const [newMailbox, setNewMailbox] = useState("");
  const [boxType, setBoxType] = useState("exist");

  const [externals, setExternals] = useState([]);
  const [autoSort, setAutoSort] = useState(false);

  const dataFetch = useRef(false);
  const getInitialState = () => {
    const value = "INBOX";
    return value;
  };
  const [start, setStart] = useState(getInitialState);

  const handleChange = (e) => {
    setStart(e.target.value);
  };

  const [num, setNum] = useState(10);
  const renderSettingText = (boxname) => {
    switch (boxname) {
        
        case "INBOX":
            return "받은 메일함" ;
        case "sent":
            return "보낸 메일함";
        case "Junk":
            return "스팸 메일함";
        case "draft":
            return "임시 보관";
        case "delete":
            return "휴지통";
        case "Stars":
            return "중요 메일함";
        default:
            return boxname;
    }
  };
  const handleNum = (e) => {
    setNum(e.target.value);
  };
  useEffect(() => {
    
    if (dataFetch.current)
    return
    dataFetch.current = true;
    console.log("..,.;??");
    axios.get('https://gw.thegmmedical.com:5003/api/getboxes')
    .then(function (response){
      console.log("getboxes", response);
      setMailbox(response.data[0].path)
      setBoxes(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  
    
    axios.get('https://gw.thegmmedical.com:5003/api/get_default_mail')
    .then(function (response){
      console.log("default setting,,,,,,", response.data[0]);
      
      if (response.data.length>0){
        let setting = response.data[0];
        setStart(setting.start_box);
        setNum(response.data[0].perpage);
        setEmailAddr(response.data[0].forward_addr);

        if (response.data[0].forward_enabled == 1){
          setForward(true);
        }
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  
    axios.get('https://gw.thegmmedical.com:5003/api/get_external_mail')
    .then(function (response){
      console.log("getboxes", response);
      
      setExternals(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    

  }, []);
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 
 
  function saveExternalMail(){

    const data = {
      serverAddr: serverAddr,
      serverPort : serverPort,
      email : email,
      password : password,
      mailbox : mailbox,
      newMailbox : newMailbox,

      minutes : minutes,
      boxType : boxType
      

    
    }
    console.log("is it working..?");
    axios.post('https://gw.thegmmedical.com:5003/api/save_external_mail',data)
    .then(function (response){
      console.log(response);
      alert("추가되었습니다.");
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
      alert("가져올 외부게정의 POP3 설정이 잘못되었거나 아이디 및 비밀번호가 틀릴 경우 추가할 수 없습니다.");
    })
    .then(function (){
      //always executed
    });
  };
  
  const onBoxTypeChange = e => {
    setBoxType(e.target.value)
  }

  function delExternal(ele){

    const data = {
      DBID : ele.DBID,
      no : ele.no

    }
    axios.post('https://gw.thegmmedical.com:5003/api/delete_external_mail',data)
    .then(function (response){
      console.log(response);
      alert("삭제되었습니다.");
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
      alert("에러가 발생했습니다.");
    })
    .then(function (){
      //always executed
    });

  }
  return (
    <div className="wrapper">
        <div className="sort-setting external_mail">
            <table style={{width:"100%"}}>


            <tr>
                    <thead>서버 주소</thead>
                    <td style={{textAlign:"left"}}>
                    <div className="input-group">
                   <input type="text" name="serverAddr" value={serverAddr} onChange={(e)=>setServerAddr(e.target.value)}></input>
                       
                    </div>
                    </td>
                </tr>
            <tr>
                    <thead>연결포트</thead>
                    <td style={{textAlign:"left"}}>
                    <div className="input-group">
                   <input type="text" name="serverPort" value={serverPort} onChange={(e)=>setServerPort(e.target.value)}></input>
                       
                    </div>
                    </td>
                </tr>
               

                <tr>
                    <thead>이메일/비밀번호</thead>
                    <td style={{textAlign:"left"}}>
                    <div className="input-group">
                   <input type="text" placeholder="아이디" name="email" value={email} onChange={(e)=>setEmail(e.target.value)}></input>
                   <input type="password" placeholder="비밀번호" style={{marginLeft:"10px"}}
                    name="password" value={password} onChange={(e)=>setPassword(e.target.value)}></input>

                    </div>
                    </td>
                </tr>

                <tr>
                    <thead>저장할 메일함</thead>
                    <td style={{textAlign:"left"}}>
                    <div className="input-group">
                      <div className="button_radio" style={{marginBottom:"10px"}}>
                        <div class="button_radio_wrap">
                          <input type="radio" class="button_radio blind"     
                          name="type"
        value="new"
        checked={boxType === "new"}
        onChange={onBoxTypeChange}/>
                          <label for="type">새 메일함</label>
                        </div>
                        <div class="settings_input_wrap">
                          <input type="text" id="external_input_3" class="text_input"
                          style={{border:"1px solid #ddd", marginLeft:"10px"}}
                          value={newMailbox} onChange={(e)=>setNewMailbox(e.target.value)}/>
                        </div>
                        </div>
                    <div class="button_radio">
                      <div class="button_radio_wrap">
                        <input type="radio" class="button_radio blind" name="type"
        value="exist"
        checked={boxType === "exist"}
        onChange={onBoxTypeChange}/>
                        <label for="type">메일함 선택</label>
                      </div>
                      <div class="button_dropdown_wrap">
                        <select type="button" class="button_dropdown" disabled="" 
                        value={mailbox} onChange={(e)=>setMailbox(e.currentTarget.value)}
                        style={{border:"1px solid #ddd", marginLeft:"10px", padding:"5px", height:"30px"}}>
                          {boxes.map((ele,idx)=>(
                            <option value={ele.path}>{renderSettingText(ele.name)}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  </td>
                </tr>

            
{/*}
                <tr>
                    <thead>스팸 설정 적용</thead>
                    <td style={{textAlign:"left"}}>
                    <div className="input-group radio" style={{width:"25px", display:"flex"}}>
                    <input type="checkbox" value="1" name="weekN" checked={del === false} onChange={()=>{setDelete(!del)}}
                    style={{marginBottom:"0"}}/>
                    </div>
                    <span>사용</span>
                    </td>
                </tr>
                <tr>
                    <thead>자동 분류 적용</thead>
                    <td style={{textAlign:"left"}}>
                    <div className="input-group radio" style={{width:"25px", display:"flex"}}>
                    <input type="checkbox" value="1" name="weekN" checked={del === false} onChange={()=>{setDelete(!del)}}
                    style={{marginBottom:"0"}}/>
                    </div>
                    <span>사용</span>
                    </td>
                </tr>
                        */}
                <tr>
                    <thead>자동 다운로드 시간</thead>
                    <td style={{textAlign:"left"}}>
                    <div className="input-group">
                    <input type="text" id="external_input_4" class="text_input"
                          style={{border:"1px solid #ddd", marginLeft:"10px"}}
                          value={minutes} onChange={(e)=>setMinutes(e.target.value)}/>
                    <span style={{marginLeft:"5px"}}>분</span>
                    </div>
                    </td>
                </tr>
        
            </table>
            <div className="btns" style={{display:"flex",justifyContent:"center", width:"auto", margin:"20px"}}>

            <button className="button" onClick={()=> saveExternalMail()} >저장</button>
            </div>
            </div>
            <table className="rules" style={{marginTop:"20px"}}>
    <thead>
      <tr style={{display:"tableRow"}}>
      <th className="rulename" style={{width:"35%"}}>아이디</th>
      <th className="title">저장 메일함</th>
      <th className="content">관리</th>
     </tr>
    </thead>
    <tbody>
   
{ externals.map((element, index) => 
<tr>
  <td>{element.email}</td>
  <td>{element.mailbox}</td>
  <td>
  {/*<button onClick={()=>editInfo()}>수정</button>*/}

  <button onClick={()=>delExternal(element)}>삭제</button>
  </td>
</tr>
)}
</tbody>
</table>
            
      
      

    </div>

  );
};

export default DefaultSetting;
