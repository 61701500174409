import React, { useEffect, useState, useReducer  } from "react";
import { NavLink, Link,useParams } from "react-router-dom";
import moment from "moment";
import './css/list.css';
import './css/common.css';
import MailNav from "../components/side/MailNav";
import axios from "axios";
import Search from "./Search";
import Dropdown from "./Dropdown";
import { FaStar, FaRegStar, FaRegEnvelopeOpen, FaEnvelope } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
axios.defaults.withCredentials = true; 

const MailList = () => {
  const [data, setData]=useState("");
  const [boxes, setBoxes]=useState("");
  const [is_reversed, setReversed] = useState(false);
  const { name } = useParams();
  const [CheckList, setCheckList] = useState([]);
  const [unseen, setUnseen]=useState();
  const [isTrash, setIsTrash] = useState(false);
  const [dest, setDest] = useState("");
  const [isActive, setActive] = useState(false);
  const [starred, setStarred] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const toggleText = () => {
    setActive(!isActive);
  };
  const options =[
    { value : "INBOX", label : "받은메일함"},
    { value : "Hello", label : "Hello"},
    { value : "TEST", label : "TEST"},
    { value : "Junk E-Mail", label : "스팸보관홤"},
    { value : "Deleted Items", label : "휴지통"}
  ];
  //console.log(name);
  useEffect(() => {
    async function showList(){
      if (name === "Deleted Items"){
        setIsTrash(true);
      }
    await fetch("http://gw.thegmmedical.com:5003/api/unseen/", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      //setBoxes(json[0].boxes);
      /*setUnseen(json[0].unseen);
      json.shift();
      console.log(json[0].data);*/
      setData(json);
      
      //console.log(boxes.boxes);
      //console.log(boxes.boxes);
    })
    .catch(() => {});
  }
  showList();
    
    /*fetch("http://gw.thegmmedical.com:5003/getbox", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      setBoxes(json);
    })
    .catch(() => {});*/

    

  
  }, []);
  
  /*function emails(){
    for (let i = 0; i < data.length; i++) {
        var href = "/view/"+ data[i][3];
      return <a href={href}>
      <div className="emails">
        <div className="subjects">
          {data[i][0]}
        </div>

        <div className="dates">
          {data[i][1]}
        </div>
      </div>
      </a>
    }
  }*/

  function create(){
    axios.post('http://gw.thegmmedical.com:5003/createbox')
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

  function rename(){
    const msg = {
      name : "name"

    }
    console.log(msg);
  /*fetch("http://gw.thegmmedical.com:5003/renamebox", {
    method: "POST", // 통신방법
    headers: {
      "content-type": "application/json",
    },
    body:msg
  })
    .then((res) => res.json())
    .then((json) => {
      //setData(json);
      console.log(json);
    })
    .catch(() => {});*/

    axios.post('http://gw.thegmmedical.com:5003/renamebox',msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

  function delete_box(){
    const msg = {
      name : "name"

    }
    console.log(msg);


    axios.post('http://gw.thegmmedical.com:5003/deletebox',msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  function status(){
    const msg = {
      name : "name"

    }
    console.log(msg);


    axios.post('http://gw.thegmmedical.com:5003/statusbox',msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  function selectAll()  {
    const checkboxes 
         = document.getElementsByName('animal');
    const all 
         = document.getElementsByName('animalall');
    //console.log(all[0].checked)
    checkboxes.forEach((checkbox) => {
      checkbox.checked = all[0].checked;
    })
  }

  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
       setCheckList([...CheckList, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
       setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }
 }
  const starmail = (e) => {
    console.log(e.currentTarget.id);
    e.currentTarget.style.color = '#6e6e6e';
    axios.post('http://gw.thegmmedical.com:5003/api/starmail/'+e.currentTarget.id+'/'+name)
    .then(function (response){
      console.log(response);
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  }

  const unstarmail = (e) => {
    console.log(e.currentTarget.id);
    e.currentTarget.style.color = 'white';
    axios.post('http://gw.thegmmedical.com:5003/api/unstarmail/'+e.currentTarget.id+'/'+name)
    .then(function (response){
      console.log(response);
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  function mail_list(){
    let array = [];
    console.log("hihi",data);
      for (let i = data.length-1; i >= 0; i--) {
        var folder = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        var href = "/"+folder+"/view/"+ data[i].uid;
        console.log("hihi",data[i].uid);
        var formattedDate="";
        var d = new Date();
        var today_year = d.getFullYear();
        var year = moment(data[i][2]).format('YYYY');
        if (today_year === year){
          formattedDate = moment(data[i].received_date).format('MM-DD HH:mm:ss');
        }else{
          formattedDate = moment(data[i].received_date).format('YYYY-MM-DD HH:mm:ss');
        }
       var froms = data[i].from_addr.split('" "');
       var from;
       if (froms[1]===undefined){
        from = froms[0];
        from= from.substring(2, from.length - 2);
       }else{
        from = froms[1];
        from= from.substring(2, from.length - 2);
       }
       let seen, star;
       if (data[i].tags.includes("Seen")){
        seen = <FaRegEnvelopeOpen className='fa-envelope'></FaRegEnvelopeOpen>;
       }else{
        seen = <FaEnvelope className='fa-envelope'></FaEnvelope>;
       }
       if (data[i].is_star===1){
        star = <FaStar className='fa-star star' id={data[i].message_id} onClick={unstarmail}></FaStar>;
       }else{
        star = <FaStar className='fa-star nostar' id={data[i].message_id} onClick={starmail}></FaStar>;
       }
        array.push(<div className="email_item">
        <input type='checkbox'
        name='animal' 
        value={data[i].uid}
        onChange={(e) => onChangeEach(e, data[i].uid)} checked={CheckList.includes(data[i].uid)}/> 
        {star}
        <Link to={href} state={{ date: formattedDate }}>
          <div className="emails">

          <div className="froms">
            {from}
          </div>

          <div className="subjects">
            {seen}
            <span>{data[i].subject}</span>
          </div>
          
          <div className="dates">
            {formattedDate} 
          </div>
          </div>
        </Link></div>);
  
        
      }
      return array;
  }

  function sort_newest(){
    if (is_reversed === true){
      data.reverse();
      console.log(data);
      setData(data);
      setReversed(false);
    }
    
  }

  function sort_oldest(){
    data.reverse();
    console.log(data);
    setData(data);
    setReversed(true);
    
  }

  function deletemails(){
    console.log(CheckList);
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }
    const msg ={
      nos : array,
      folder : name
    }
    axios.post('http://gw.thegmmedical.com:5003/api/deletemails', msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
 
  }
  function expungemails(){
    console.log(CheckList);
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {

      if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value);
      }
      
    }
    
    console.log(array);
    const msg ={
      nos : array,
      folder:name
    }
    axios.post('http://gw.thegmmedical.com:5003/api/expungemails', msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
 
  }
  const handleSelect = (e) => {
    setDest(e.target.value);
  };
  function movemails(){

    var ele = document.getElementsByName('dest');
    var dest;
    for(let i = 0; i < ele.length; i++) {
      if(ele[i].checked){
        dest = ele[i].value;
        
      }
      
    }
  
    console.log(CheckList);
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }console.log(dest);
    const msg ={
      nos : array,
      folder : name,
      dest : dest
    }
    axios.post('http://gw.thegmmedical.com:5003/api/movemails', msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
 
  }

  if (data === undefined){
    return;
  }
  return (

  <div className="wrapper">
    
  <MailNav boxes={boxes}/>
  <div className="contents_wrap">
  
  {/*<button type="button" class="button_task" onClick={()=>create()}>메일함생성</button>
   <button type="button" class="button_task" onClick={()=>rename()}>메일함이름변경</button>
   <button type="button" class="button_task" onClick={()=>delete_box()}>메일함삭제</button>
  <button type="button" class="button_task" onClick={()=>status()}>메일함정보</button>*/}
   <div class="contents_header">
   <div class="mail_header"><h3>{name} &nbsp; </h3> <h3> {unseen} </h3></div>
   <Search onChange={(value) => setData(value.data)}/>
  </div>

<div className="mail_content">
<div class="mail_toolbar">
<div class="mail_toolbar_count">
<span class="current">
<strong class="count">0</strong>
<span class="text">개 선택</span>
</span>
<span class="total">
<span class="blind">전체</span>30
<span class="blind">개</span>
</span>
</div>
<div class="mail_toolbar_task">
<div class="button_group">
<div class="button_optional_select">
<div class="button_checkbox_wrap">
<label for="selection_all">
</label>
</div>

</div>
<div class="button_task_wrap button_select">
<input type='checkbox'
       name='animalall' 
       value='selectall'
       onClick={selectAll}/>
<br />
</div>


<button type="button" class="button_task svg_read" disabled=""><span class="text">읽음</span>
</button></div>
{ isTrash && 
<div class="button_task_wrap">
  <button type="button" class="button_task svg_delete" disabled="" onClick={()=>expungemails()}>
  <span class="text">영구삭제</span>
  </button>
</div>
}
{ !isTrash && 
<div class="button_task_wrap">
  <button type="button" class="button_task svg_delete" disabled="" onClick={()=>deletemails()}>
  <span class="text">삭제</span>
  </button>
</div>
}
<div class="button_task_wrap">
  <button type="button" class="button_task svg_spam" disabled="">
<span class="text">스팸차단</span></button>
</div>
<div class="button_task_wrap button_reply">
  <button type="button" class="button_task" disabled=""><span class="text">답장</span></button></div><div class="button_task_wrap button_reply_all">
    <button type="button" class="button_task" disabled=""><span class="text">전체답장</span></button></div><div class="button_task_wrap button_forward">
      <button type="button" class="button_task" disabled=""><span class="text">전달</span></button></div>
      <div class="button_task_wrap">
      <button type="button" class="button_task" onClick={toggleText} disabled=""><span class="text">이동</span></button>

</div>
<div class="button_task_wrap button_etc">
          <button type="button" className="button_task_dropdown sort_oldest" onClick={()=>sort_oldest()}>
            <span class="text">오래된 순 정렬</span>
          </button>
          <button type="button" className="button_task_dropdown sort_newest" onClick={()=>sort_newest()}>
            <span class="text">최신 순 정렬</span>
          </button>
        </div>
      {/*<select name="dstfolderA" className="move_to" placeholder="메일함 이동" onChange={handleSelect} value={dest}>
        <option value="" disabled selected>메일함 이동</option>
        <option value="INBOX">받은편지함</option>
        <option value="Hello">Hello</option>
        <option value="TEST">TEST</option>
        <option value="Deleted Items">휴지통</option>
        <option value="Junk E-Mail">스팸편지함</option>
</select>*/}


{isActive && (
        <div className="dropdown_div">

        <ul class="parent-list">
          <li class="parent-item">
            <div className="item-wrap">
              <input type="radio" id ="INBOX" name="dest" value="INBOX"/>
              <label for="INBOX">받은메일함</label>
            </div>
            <ul className="child-list">
              <li className="child-item">
                <div className="item-wrap">
                  <input type="radio" id = "INBOX/Hello" name="dest" value="INBOX/Hello"/>
                  <label for="INBOX/Hello">Hello</label>
                </div>
              </li>
              <li className="child-item">
                <div className="item-wrap">
                  <input type="radio" id = "INBOX/TEST" name="dest" value="INBOX/TEST"/>
                  <label for="INBOX/TEST">TEST</label>
                </div>
              </li>
            </ul>
          </li>
          <li class="parent-item">
          <div className="item-wrap">
            <input type="radio" id="Junk E-Mail" name="dest" value="Junk E-Mail"/>
            <label for="Junk E-Mail">스팸메일함</label>
          </div>
          </li>
          <li class="parent-item">
          <div className="item-wrap">
            <input type="radio" id="Deleted Items" name="dest" value="Deleted Items"/>
            <label for="Deleted Items">휴지통</label>
          </div>
          </li>
        </ul>
        
          <button onClick={()=>movemails()}>이동</button>
        </div>
      )}        
      </div>
       
        <div class="button_task_wrap button_filter">
            
        </div></div>


<div className="email_list">

    {mail_list()}
   
    </div>
</div>
    </div>
    </div>
  );
};

export default MailList;
