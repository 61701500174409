import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './components/side/Sidebar';//import Footer from './components/Footer';
import { useLayoutEffect } from 'react';

const Layout = () => {

  const location = useLocation();
  useLayoutEffect(() => {
    console.log("location",location);
    if (document.getElementsByTagName("main")[0] !==undefined){
      document.getElementsByTagName("main")[0].style.overflow="unset";
    }
   }, [location])

  return (
    <div className="admin" style={{display:"flex", flexDirection:"row", width:"100%", margin:"0", overflow:"auto",background:"rgb(21,23,28)"}}>
      <Sidebar/>
      <main style={{margin:"0 0 0 0", display:"block", overflow:"auto"}}>
        <Outlet />
      </main>
      {/*<Footer/>*/}
    </div>
  );
};

export default Layout;