import "./css/login.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./css/userSetting.css";
import user from "../components/img/user.png";
import Sidebar from "../components/SidebarSetting";
import axios from "axios";
import firebase from 'firebase';

axios.defaults.withCredentials = true; 




const UserSetting = () => {
  let body  = document.getElementsByTagName("BODY")[0];let width = body.offsetWidth;

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [info, setInfo]  =useState([]);


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pemail, setPemail] = useState("");
  const [tel, setTel] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [dayText, setDayText] = useState("");
  const [warning, setWarning] = useState(false);
  const [profile, setProfile] = useState("");
  const [dName, setDName] = useState("");
  const [jobName, setJobName] = useState("");
  const [type, setType] = useState("");

  const imageRef=useRef();

  useEffect(() => {

    fetch("https://gw.thegmmedical.com:5003/api/userinfo/", {
          method: "get", // 통신방법
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        })
        .then((res) => res.json())
        .then((json) => {
          
          setInfo(json[0]);
          setName(json[0].name);
          setEmail(json[0].email);
          if (json[0].personal_email !==null){
            setPemail(json[0].personal_email);
          }
          if (json[0].tel !==null){
            setTel(json[0].tel);
          }
          setYear(json[0].birthyear);
          setMonth(json[0].birthmonth);
          setDay(json[0].birthday);
          setType(json[0].birthdate_type);
          setDName(json[0].d_name);
          setJobName(json[0].job_name);
          setWarning(false);
          let dateTxt="";
          if (Number(json[0].birthday) <10){
            dateTxt = "0"+Number(json[0].birthday).toString();

          }else{
            dateTxt =Number(json[0].birthday);
          }
          setDayText(dateTxt);
          setProfile(json[0].profile_link);
        })
        .catch(() => {});
      
      if (!selectedFile) {
          setPreview(undefined)
          return
      }

      const objectUrl = URL.createObjectURL(selectedFile)
      setPreview(objectUrl)

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);
    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0]);
    }
    function deletepic() {
      console.log(selectedFile);
      setSelectedFile();
      setPreview();
      setProfile("");
      imageRef.current.src = user;
    }

    const onChangeName = (e) => {
    	setName(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeEmail = (e) => {
    	setEmail(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangePemail = (e) => {
    	setPemail(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeTel = (e) => {
    	setTel(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeYear = (e) => {
    	setYear(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeMonth = (e) => {
    	setMonth(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangeDay = (e) => {
    	setDay(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
      let val = Number(e.target.value);
      if (isNaN(val)){
        setWarning(true);

      }else{
        setWarning(false);
        if (val <10){
          val = "0"+val.toString();

        }
        setDayText(val);
      }
    }

    const updateuser = () =>  {
      
        const formData = new FormData();
   
 

        formData.append(`name`, name);
        formData.append(`email`, email);
        formData.append(`personal_email`, pemail);
        formData.append(`tel`, tel);
        formData.append(`year`, year);
        formData.append(`month`, month);
        formData.append(`day`, day);
        formData.append(`profile_link`, profile);
        formData.append(`file`, selectedFile);
        formData.append(`birthdate_type`, type);

        const fetch = async () => {
          await axios
            .post("https://gw.thegmmedical.com:5003/api/updateuser", formData, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({ data }) => {
              console.log("what is this");
              window.location.reload(true);
              //console.log("...." + data);
            });
        };
        fetch();
    }

    /*const config = {


      apiKey: "AIzaSyA4deuG-QRLdxS5iYNAn6bV-Tp4KjepYX0",
      authDomain: "gm-medical-58c86.firebaseapp.com",
      projectId: "gm-medical-58c86",
      storageBucket: "gm-medical-58c86.appspot.com",
      messagingSenderId: "880056248369",
      appId: "1:880056248369:web:aa6bdb95acc074cbd90d10",
      measurementId: "G-ZYGWLVRYFT"
    
    };
    
    
    let messaging = null; 
    firebase.initializeApp(config);*/
    
    
    
      /*messaging.onMessage(function(payload){
        const notificationTitle = payload.notification.title; 
        const notificationOptions = { 
          body: payload.notification.body, 
          icon: payload.notification.icon, 
        }; if (!("Notification" in window)) { 
          console.log("This browser does not support system notifications."); 
        } else if (Notification.permission === "granted") { 
          // If it's okay let's create a notification 
          var notification = new Notification(notificationTitle,notificationOptions); 
          notification.onclick = function(event) { 
            event.preventDefault(); 
            window.open(payload.notification.click_action , '_blank'); 
            notification.close(); 
          } 
        }
    
      })*/
    
    
    
      const requestPermission = async() => {
        let permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted. Requesting for token.");
  
          if (firebase.messaging.isSupported()){ 
            let messaging = firebase.messaging(); 
            let token = await messaging.getToken({
              vapidKey: "BHBOyeWYKkwGfvXolZnj3C7VMMGABSRDZKuQnMovXtad4Un4MRF6jFMY0LWaW2wbwQQLtEh9zVVjt_bgokjYhwQ",
            });
            const data = {
              token: token,
              page : "setting user"
            }
              axios.post('http://gw.thegmmedical.com:5003/api/updateToken', data).then(function (response){
              console.log("updated");
              alert("허용되었습니다.");
              })
              .catch(function (error){
                console.log(error);
              })
                .then(function (){
              });
          }
         
          // do something with the FCM token
        } else {
          console.log("Notification permission denied");
          // Handle denied permission
        }
      }
    
  
  
  return (
    <div className="">
        <Sidebar></Sidebar>
        <div className="user_info setting_wrap">
        <h2 id="title" class="screen_out">기본설정</h2>
        <table style={{border:"1px solid #ddd"}}>
            <tr>
                <td>사진</td>
                <td>

<div style={{width:"160px"}}>  
<label htmlFor="photo-upload" className="custom-file-upload">

{selectedFile 
? <div className="img-wrap img-upload"><img src={preview} alt="preview" ref={imageRef}/>
<button onClick={()=>deletepic()}>삭제</button>
</div>
: profile !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={profile} alt="profile_pic" ref={imageRef}/>
<button onClick={()=>deletepic()}>삭제</button>
</div>
: <img for="photo-upload" src={user} alt="profile_pic"/>
}

<input id="photo-upload" type="file" onChange={onSelectFile}/> 
</label>
</div>      
</td>
</tr>

{width <600 && (
  <tr style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<button onClick={()=>requestPermission()}>알림 허용</button>
</tr>
)}



<tr>
<td>이름</td>
<td><input type="text" value={name} onChange={onChangeName}></input></td>
</tr>
<tr>
<td>이메일</td>
<td><input type="text" value={email} onChange={onChangeEmail}></input></td>
</tr>

<tr>
<td>외부메일</td>
<td><input type="text" value={pemail} onChange={onChangePemail}></input></td>
</tr>
<tr>
<td>전화번호</td>
<td><input type="text" value={tel} onChange={onChangeTel}></input></td>
</tr>

<tr>
<td>부서</td>
<td>{dName} <span>(관리자에 의해 변경될 수 있습니다.)</span></td>
</tr>

<tr>
<td>직위</td>
<td>{jobName} <span>(관리자에 의해 변경될 수 있습니다.)</span></td>
</tr> 
<tr>
<td>생년월일</td>
<td style={{display:"flex", flexDirection:"column"}}>

<div>
  <label>     
        <input type="radio" value="양력" name="type" checked={type === "양력"} onChange={()=>setType("양력")}/><span>양력</span>
      </label>

      <label>
        <input type="radio" value="음력" name="type" checked={type === "음력"} onChange={()=>setType("음력")}/><span>음력</span>
      </label>
</div>


<div className="bod">
  <input type="text" placeholder="년(4자)" value={year} onChange={onChangeYear}/>        
<span>년</span>

                <select value={month} onChange={onChangeMonth}>
                    <option value="">선택</option>
                    <option value="1">01</option>
                    <option value="2">02</option>
                    <option value="3">03</option>
                    <option value="4">04</option>
                    <option value="5">05</option>
                    <option value="6">06</option>
                    <option value="7">07</option>
                    <option value="8">08</option>
                    <option value="9">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
                <span>월</span>
                <input type="text" placeholder="일" value={dayText} onChange={onChangeDay}/>
                <span>일</span>

                {warning && (<span style={{color:"red"}}>숫자를 입력해주십시오.</span>)}
</div>


</td>
</tr> 
        </table>
        <div style={{position:"relative", width:"100%"}}>
          <button className="submit" 
          style={{ width:"80px",position:"absolute", top:"30px",left:"0", right:"0", margin:"auto"}}
          onClick={()=>updateuser()}>저장</button>
       </div>
      </div>
    </div>
  );
};

export default UserSetting;