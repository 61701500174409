import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import {FaAddressBook} from "react-icons/fa";


import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  issue_date:new Date()
};



const HR = ({doc_no, onChange, line}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [list, setList] = useState([]);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);
  
  const [values, setValues] = useState(initialValues);
  const [idx, setIdx] = useState("0");
  const [department, setDepartment] = useState([]);
  const [job, setJob] = useState([]);
  
  
  const { state } = useLocation();

  useEffect(()=>{
    //This function will run every time the value of values state changes.
    onChange(values); //Will give you the updated value of values state.
    //Do some other stuff
  }, [values])

  useEffect(() => {

    if (doc_no!==undefined){

    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/hr/'+doc_no)
    .then(function (response){

      let order_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/hr/'+order_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({product_name:'', quantity:'',unit_price:'', price:'', memo:''});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }

    fetch("http://gw.thegmmedical.com:5003/api/getdepartment/", {
          method: "get", // 통신방법
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          setDepartment(json);
        })
        .catch(() => {});
        fetch("http://gw.thegmmedical.com:5003/api/getjob/", {
          method: "get", // 통신방법
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        })
        .then((res) => res.json())
        .then((json) => {
          console.log("job is....", json);
          setJob(json);
        })
        .catch(() => {});
  
  }, []);


  
  function popupwindow(url, title, w, h) {
      var left = (window.screen.width/2)-(w/2);
      var top = (window.screen.height/2)-(h/2);
      return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 
  window.addEventListener('message', (event) => {
     
      if (event.data["selectedMember"] !== undefined){
        console.log("selectedMEmber....", event.data["selectedMember"]);
        var user_no = event.data["selectedMember"].user_no;
        var name = event.data["selectedMember"].name;
        var d_name = event.data["selectedMember"].d_name;
        var job_name = event.data["selectedMember"].job_name;
  
      
          const data = [...rows];
          data[idx]["name"] = name;
          data[idx]["dept_before"] = d_name;
          data[idx]["pos_before"] = job_name;
          data[idx]["user_no"] = user_no;
          initRow(data);
  
          values.arr = rows;
          onChange(values);
  
      }
  
  });
  function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
      
    return rows.map((rowsData, index) => {
  
    
  
      const {name, branch, dept_before, dept_after, pos_before, pos_after, issued_date}= rowsData;
      return (
        <tr key={index}>
          <td>{index+1}</td>
         
          <td style={{width:"15%"}}>

            <div style={{display:"flex"}}>
            <input
              type="text"
              value={name}
              onChange={(event) => onValUpdate(index, event)}
              name="name"
              className="form-control"
            />
  
          <FaAddressBook onClick={() => {popupwindow("/people", "결재라인", 500, 750); setIdx(index)}} alt='' 
          style={{cursor:"pointer", color:"#666", marginRight:"5px"}}>찾기</FaAddressBook>
            </div>
          </td>
          <td style={{width:"15%"}}>
            <input
              type="text"
              value={branch}
              onChange={(event) => onValUpdate(index, event)}
              name="branch"
              className="form-control"
            />
          </td>
          <td style={{width:"15%"}}>
            <input
              type="text"
              value={dept_before}
              onChange={(event) => onValUpdate(index, event)}
              name="dept_before"
              className="form-control"
            />
          </td>
          <td style={{width:"15%"}}>
          
  
          <select value={dept_after}
              onChange={(event) => onValUpdate(index, event)}
              name="dept_after"
              className="form-control">
          {department.map((box, idx) => {
            return <option className={box.d_no} value={box.d_name}>{box.d_name}</option>
          })}
                    
          </select>
   
          </td>
  
          <td style={{width:"15%"}}>
            <input
              type="text"
              value={pos_before}
              onChange={(event) => onValUpdate(index, event)}
              name="pos_before"
              className="form-control"
            />
          </td>
          <td style={{width:"15%"}}>
       
  
          <select value={pos_after}
              onChange={(event) => onValUpdate(index, event)}
              name="pos_after"
              className="form-control">
          {job.map((box, idx) => {
            return <option className={box.job_no} value={box.job_name}>{box.job_name}</option>
          })}
                    
          </select>
  
          </td>
  
          <td style={{width:"15%"}}>
            <input
              type="date"
              value={issued_date}
              onChange={(event) => onValUpdate(index, event)}
              name="issued_date"
              className="form-control"
            />
          </td>
          <td>
            <button
              className="btn"
              onClick={() => tableRowRemove(index)}
            >
              x
            </button>
          </td>
        </tr>
      );
    });
  }
  
  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    values.arr = rows;
    onChange(values);

  };
 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let initial = [{
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    },
    {
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    },
    {
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    },
    {
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    },
    {
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    },
    {
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    },
    {
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    },
    {
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    },
    {
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    },
    {
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    }];
    initRow(initial);
    
    
  }, []);


  const addRowTable = () => {
    const data = {
      name:'', branch:'', dept_before:'', dept_after:'', pos_before:'', pos_after:'', issued_date:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    
    const data = [...rows];
    console.log(data, name, value, i);
    data[i][name] = value;

   
    initRow(data);

    values.arr = rows;
    onChange(values);

  };

 


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

            <div className="order_write" style={{padding:"0", margin:"20px auto"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>인사발령</td>
                 
                  </tr>
                



                  
                </table>
            <table style={{marginTop: '1px', borderCollapse: 'collapse'}} className="forms">{/* Header */} 
              <thead style={{height:"40px"}}>
                <tr>
             
                </tr>
              </thead>
              <tbody>
                
               
                <tr>
                <div className="btns" style={{display:"flex", justifyContent:"flex-end", margin:"20px 0"}}>
     
        <button className="btn" onClick={addRowTable}>
          추가
        </button>


      </div>
                <table className="form_list order_list" style={{borderCollapse: 'collapse', width:"790px"}}>
                 
          <thead>
            <tr>
              <th>No</th>
              <th>성명</th>
              <th>소속</th>
              <th>부서(전)</th>
              <th>부서(후)</th>
              <th>직급(전)</th>
              <th>직급(후)</th>
              <th>발령일자</th>

              <th>삭제</th>
            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />
           
          </tbody>
        </table>
                </tr>
              </tbody>
            </table>
          </span></div>
        
        
      </div>

<h3 style={{width:"100%", textAlign:"center"}}>{new Date().getFullYear() + "년 " + ('0' + (new Date().getMonth() + 1)).slice(-2) +
"월 " + ('0' + new Date().getDate()).slice(-2) + "일" }</h3>
<h3 style={{width:"100%", textAlign:"center"}}>(주)GM메디칼, 생생메디칼, MH홀딩스, 이호MH호텔</h3>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default HR;
