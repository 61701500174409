import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/gi";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import initial from "./data/cell";
import { toPng } from 'html-to-image';
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../components/DraftsList";
import BoardList from "../components/side/DataList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { BsPin } from "react-icons/bs";
import ReactDOMServer from "react-dom/server";

import OrderList from "../components/OrderList";
import BoardDocView from "./BoardDocView";
import html2canvas from 'html2canvas';

import "./css/write.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

export default function BoardWrite() {
  const { state } = useLocation();
  const location = useLocation();

  const editorRef = useRef();
  const elementRef = useRef(null);
  
  const navigate = useNavigate();
  const inputFileRef = useRef();
  //const [div, setDiv]=useState(<div style={{width:"100%", height:"500px"}}><Workbook onOp={console.log} data={[initial]} onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} /></div>) ;
  const [div, setDiv]=useState();
  

  
  const [sidebar, setSidebar] = useState(false);
  const [list, setList] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [imgLink, setImgLink] = useState("");
  const [boardName, setBoardName] = useState(state.boardName);
  const [boardNo, setBoardNo] = useState(state.bg_no);
  const [excelData, setExcelData] = useState();
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [boards, setBoards] = useState([]);
  const [editor, setEditor] = useState(location.state.data[0].content_type);
  const [statusList, setStatusList] = useState([]);
  const [boardStatus, setBoardStatus] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [initialText, setInitialText] = useState("");

  const [orderDocNo, setOrderDocNo] = useState(49);
  const [__html, setHtml] = useState(initial);
  
  const [subject, setSubject] = useState("RE: "+ location.state.data[0].subject);
  const togglePopup = () => {
    setShowPopup(!showPopup);
};
function handleContextMenu(e) {
  e.preventDefault(); // prevents the default right-click menu from appearing
}
function replaceAll(str, searchStr, replaceStr) {

  return str.split(searchStr).join(replaceStr);
}

  const selectBoard = (e) => {
    e.preventDefault();
    var index = e.nativeEvent.target.selectedIndex;
    setBoardName(e.nativeEvent.target[index].text);
    setBoardNo(e.target.value);
    setDiv();
    



      axios.get('https://gw.thegmmedical.com:5003/api/get_data_editor/'+e.target.value)
      .then(function (res){
        if (res.data.length>0){
          console.log("heyheyhey", res.data[0]);
          
          let content = res.data[0].content;
          let type = res.data[0].editor_type;
          
          
          if (type==="excel"){
          
          
            var htmlData = JSON.parse(content);

            

            const asdf = htmlData.celldata;
            
            var celldataa = [];
            for (let i = 0; i<asdf.length;i++){

                
                let v = asdf[i].v.v;
                let m = asdf[i].v.m;
           
                if (asdf[i].v.f !==undefined){

                  let val = asdf[i].v.f;
                  console.log("hihi", val);
                  val = replaceAll(val, ' ', '');
                 val = replaceAll(val, 'YEAR(TODAY())', new Date().getFullYear().toString());

                 val = replaceAll(val, 'MONTH(TODAY())', (new Date().getMonth()+1).toString());
                 val = replaceAll(val, 'DAY(TODAY())', new Date().getDate().toString());
                 val = replaceAll(val, '&', '');
                 val = replaceAll(val, '"', '');
                 console.log("hihi", val);
                  val = val.slice(1);
                  v = val;
                  m = val;

                }
                var trows = {
                  r: asdf[i].r,
                  c: asdf[i].c,
                  v: {
                    ct: asdf[i].v.ct,
                    v : v,
                    m: m,
                    bg: asdf[i].v.bg,
                    bl:asdf[i].v.bl,
                    it:asdf[i].v.it,
                    ff:asdf[i].v.ff,
                    fs:asdf[i].v.fs,
                    fc:asdf[i].v.fc,
                    ht:asdf[i].v.ht,
                    vt:asdf[i].v.t,
                    f:asdf[i].v.f,
                  }
                };
                if (asdf[i].mc!==null){
                  trows.v.mc = asdf[i].v.mc;
                }
                if (asdf[i].f!==null){
                  trows.v.f = asdf[i].v.f;
                }
                celldataa.push(trows);
                
                
              
            }

            htmlData.celldata = celldataa;
            console.log("htmldata...", htmlData);
            

          setDiv(<div style={{width:"100%", height:"500px"}}>
  
          <Workbook onOp={console.log} data={[htmlData]} onContextMenu={e=>{e.preventDefault();}}  onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} />
        </div>
        );
          console.log("어?ㅇㄹㄴㅇㄹㄴㅇㄹㄴㄹ", htmlData);

          }else{

            editorRef.current?.getInstance().setHTML(content);
          
            setInitialText(content);
          }
          
          setEditor(res.data[0].editor_type);
        }else{
          editorRef.current?.getInstance().setHTML("");
          
            setInitialText("");
          setEditor("text");
        }
        
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });


    axios.post('https://gw.thegmmedical.com:5003/api/get_dataadmin',{
        data: {
          // 서버에서 req.body.{} 로 확인할 수 있다.
          bg_no : e.target.value
        },
      })
    .then(function (response){
    
      if (response.data[0].status !==null){
        setStatusList(JSON.parse(response.data[0].status));
      }else{
        setStatusList([]);
      }
      
    
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
      


  };
  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };
  const selectEditor = (e) => {
    e.preventDefault();
    setEditor(e.target.value);
  };
  const toggleShow = () => {
    setShow(!show);
};
  const onTypeChange = e => {
    setType(e.target.value)
  }
  const endDateChange = e => {
    setEndDate(e.target.value)
  }


  
  const content = "<div><br/><br/><br/><br/><blockquote>------------original message------------"+
  "<p>작성자: " + location.state.data[0].author+"</p>"+
  "<p>작성일: " + location.state.data[0].date_created+"</p>"+
  "<p>제목: " + location.state.data[0].subject+"</p>"+
  "<p>" + location.state.data[0].content+"</p></blockquote></div>";

  useEffect(() => {
    if (location.state.data[0].content_type !=="excel"){
      editorRef.current?.getInstance().setHTML(content);
      setInitialText(content);
      setEditor("text");
    }else{
        
      var htmlData = JSON.parse(location.state.data[0].content);

            

      const asdf = htmlData.celldata;
      
      var celldataa = [];
      for (let i = 0; i<asdf.length;i++){

          
          let v = asdf[i].v.v;
          let m = asdf[i].v.m;
  
          var trows = {
            r: asdf[i].r,
            c: asdf[i].c,
            v: {
              ct: asdf[i].v.ct,
              v : v,
              m: m,
              bg: asdf[i].v.bg,
              bl:asdf[i].v.bl,
              it:asdf[i].v.it,
              ff:asdf[i].v.ff,
              fs:asdf[i].v.fs,
              fc:asdf[i].v.fc,
              ht:asdf[i].v.ht,
              vt:asdf[i].v.t,
              f:asdf[i].v.f,
            }
          };
          if (asdf[i].mc!==null){
            trows.v.mc = asdf[i].v.mc;
          }
          if (asdf[i].f!==null){
            trows.v.f = asdf[i].v.f;
          }
          celldataa.push(trows);
          
          
        
      }

      htmlData.celldata = celldataa;
      console.log("htmldata...", htmlData);
      
    setEditor("excel");
    setDiv(<div style={{width:"100%", height:"500px"}}>

    <Workbook onOp={console.log} data={[htmlData]} onContextMenu={e=>{e.preventDefault();}}  onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} />
  </div>
  );
    console.log("어?ㅇㄹㄴㅇㄹㄴㅇㄹㄴㄹ", htmlData);

    }
    
    fetch("https://gw.thegmmedical.com:5003/api/get_data_category/"+location.state.bg_no, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
        console.log(json);
        setOption(json[0].name);
        setList(json);
    })
    .catch(() => {});


    axios.get('https://gw.thegmmedical.com:5003/api/data_info/'+location.state.bg_no).then(function (response){
      setBoardName(response.data[0].bg_name);
    })
    .catch(function (error){
      console.log(error);
    })
      .then(function (){
    });


    setEndDate(location.state.data[0].end_date);
}, []);

  useEffect(() => {

    fetch("https://gw.thegmmedical.com:5003/api/get_datas", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
        console.log("hihih", state);
        setBoards(json);
        console.log("state....", state);
        if (state.board_no ===0 && state.board_no === undefined){
          
          setBoardNo(json[0].bg_no);
          setBoardName(json[0].bg_name);
        }else if(state.board_no !==0 && state.board_no !=="" && state.board_no !== undefined){
          setBoardNo(state.board_no);
        }
    })
    .catch(() => {});
    if (state !==null){
    axios.post('https://gw.thegmmedical.com:5003/api/get_dataadmin',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_no : state.board_no 
      },
    })
  .then(function (response){
  
    if (response.data[0].status !==null && response.data[0].status!=="[]"&& response.data[0].status!==undefined){
      setStatusList(JSON.parse(response.data[0].status));
    }else{
      setStatusList([]);
    }
    
  
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
  }
}, []);
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const subjectChange = (event) => {
    setSubject(event.target.value);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };





 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {
    console.log("boardNo..", boardNo);
    if (boardNo === undefined || boardNo === 0){
      alert("게시판을 선택해주세요");
      return false;
    }
    if (editor === "excel"){
      if (subject === "") {
        alert("제목을 입력해주세요.");
        return false;
      } 
      
      var test = excelData[0];
    console.log("test! : ", test.data.length);
    const asdf = test.data;
    var celldataa = [];
    for (let i = 0; i<asdf.length;i++){
      for (let j=0;j<asdf[i].length;j++){
        //console.log("vavava", asdf[i][j]);
        if (asdf[i][j]!==null){
          var trows = {
            r: i,
            c: j,
            v: {
              ct: asdf[i][j].ct,
              v : asdf[i][j].v,
              bg: asdf[i][j].bg,
              m: asdf[i][j].m,
              bl:asdf[i][j].bl,
              it:asdf[i][j].it,
              ff:asdf[i][j].ff,
              fs:asdf[i][j].fs,
              fc:asdf[i][j].fc,
              ht:asdf[i][j].ht,
              vt:asdf[i][j].t,
              f:asdf[i][j].f,
            }
          };
          if (asdf[i][j].mc!==null){
            trows.v.mc = asdf[i][j].mc;
          }
          if (asdf[i][j].f!==null){
            trows.v.f = asdf[i][j].f;
          }
          celldataa.push(trows);
        }
        
      }
    }

    excelInitial.calcChain = test.calcChain;
    excelInitial.config = test.config;
    excelInitial.celldata = celldataa;

    
console.log("test! : ", excelInitial);
   
    
    const fileData = JSON.stringify(excelInitial);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const d =  {
      json: fileData
    }
  

      var arrayLength = fileArray.length;
      var extractStr = editorRef.current?.getInstance().getMarkdown();
      for (var i = 0; i < arrayLength; i++) {
        let result = extractStr.match(fileArray[i]);
        console.log("result: " + result);
        if (result == null) {
          var filename = fileArray[i].substring(
            fileArray[i].lastIndexOf("/") + 1
          );
  
          axios
            .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
              data: {
                // 서버에서 req.body.{} 로 확인할 수 있다.
                file_name: filename,
                board_name: boardName,
              },
            })
            .then(() => this.setState({ status: "Delete successful" }));
        }
        //Do something
      }
  
      const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      const formData = new FormData();
     
      for (let i = 0; i<fileList.length;i++){
        console.log(fileList[i].name);
        formData.append(`file[${i}]`, fileList[i]);
        formData.append(`filename[${i}]`, fileList[i].name);
      }
      for (let i = 0; i<largeFiles.length;i++){
        formData.append(`lfile[${i}]`, largeFiles[i]);
        formData.append(`lfilename[${i}]`, largeFiles[i].name);
      }
      formData.append(`content`, fileData);
      formData.append(`content_type`, "excel");
      formData.append(`date_time`, date_time);
      formData.append(`subject`, subject);
      formData.append(`file`, mailFile);
      formData.append(`is_notice`, type);
      formData.append(`name`, "변수연");
      formData.append(`bg_no`, boardNo);
      formData.append(`end_date`, endDate);
      formData.append(`category`, option);
      formData.append(`status`, boardStatus);

      const fetch = async () => {
        await axios
          .post("https://gw.thegmmedical.com:5003/api/datainsert", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            console.log("what is this");
            window.location.href="https://gw.thegmmedical.com/data";
            //console.log("...." + data);
          });
      };
  
      const noticefetch = async () => {
        await axios
          .post("https://gw.thegmmedical.com:5003/api/noticeinsert", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            console.log("what is this");
            window.location.href="https://gw.thegmmedical.com/data";
            //console.log("...." + data);
          });
      };
      if (type === "2"){
        noticefetch();
      }else{
        fetch();
      }
     
  
      //saveExcel();
    }else{
    /*const formLargeData = new FormData();
   
    for (let i = 0; i<largeFiles.length;i++){
      formLargeData.append(`lfile[${i}]`, largeFiles[i]);
      formLargeData.append(`lfilename[${i}]`, largeFiles[i].name);
    }*/
    //alert(formLargeData);
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    formData.append(`is_notice`, type);
    formData.append(`content_type`, "html");

    formData.append(`name`, "변수연");
    formData.append(`bg_no`, boardNo);
    formData.append(`end_date`, endDate);
    formData.append(`category`, option);
    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/datainsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/data";
          //console.log("...." + data);
        });
    };

    const noticefetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/noticeinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/data";
          //console.log("...." + data);
        });
    };
    if (type === "2"){
      noticefetch();
    }else{
      fetch();
    }
   


  }
  };

  const handleDraftButton = () => {
 
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    formData.append(`is_notice`, type);
    formData.append(`name`, "변수연");
    formData.append(`bg_no`, state.board_no);
    formData.append(`end_date`, endDate);
    formData.append(`category`, option);
    if (draftNo !==undefined){
      formData.append(`draft_no`, draftNo);
    }
    const fetch = () => {
      axios
        .post("https://gw.thegmmedical.com:5003/api/datadraftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response){
          setDraftNo(response.data);
         
        });
    };
    fetch();



  };
  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const downloadEmployeeData = () => {
    fetch('https://gw.thegmmedical.com:5003/api/download')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = '이호MH호텔_셀렉 A컷.zip';
                    a.click();
                });
                //window.location.href = response.url;
        });
  }
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
  window.addEventListener('message', (event) => {
    if (event.data["msg"] !== undefined){
      var files = event.data["msg"];
      for (let i= 0;i<files.length;i++){
        string+='<a href = "https://gw.thegmmedical.com:5003/get/'+ files[i].name+'">'+files[i].name+'</a>'

      }
      console.log(string);
      var content = editorRef.current?.getInstance().getHTML();
      string+=content;
      editorRef.current?.getInstance().setHTML(string);
    }
 
  });

  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const excelInitial = {
    name: "Sheet1",
    config: {
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
      },
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 38,
    status: 1,
    ch_width: 1361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    
    scrollLeft: 0,
    scrollTop: 0
  };
  
  
    const addBookmarkPage = () => {
      var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
      if(result){
        const data ={
          name: boardNo,
          num : '',
          link : window.location.pathname,
          bookmark : boardName + " 게시글 작성"
        }
        console.log(window.location.pathname)
        console.log(data);
        axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
        .then(function (response){
          console.log(response);
          alert("메뉴 바로가기에 추가되었습니다.");
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };

    function writeOrder(value){
      console.log("write order..........", value);

      let order_list = value.order_list;
      let str="";
      for (let i=0;i<order_list.length;i++){
        str+="<tr> <td> "+order_list[i].product_name+"</td>"+
        "<td>"+order_list[i].standard+"</td>"+
        "<td>"+order_list[i].quantity+"</td>"+
        "<td>"+order_list[i].destination+"</td> </tr>";
      }

      var text = "발주 일자 : " + value.order.order_date + "<br/>발주 제목: " + value.order.subject + 
      "<br/>납품처: " +
      "<br/>장비명: " +
      "<br/><table>"+str+
      "</table>" +
      "<br/>납품일: " +  
      "<br/>배송지: " + value.order.place +
      "<br/>담당자: " + value.order.pic_name + " " +value.order.job_name + " (" +value.order.pic_tel + ")" +
      "<br/>비고: ";
      setOrderDocNo(value.order.doc_no);

      /*console.log("value.order.doc_no...", value.order.doc_no)
      let html = ReactDOMServer.renderToString(<BoardDocView doc_no = "49" ></BoardDocView>)
      var iframe = document.createElement('iframe');
      document.body.appendChild(iframe);
      var iframedoc = iframe.contentDocument||iframe.contentWindow.document;
      iframedoc.body.innerHTML=html;

      console.log(iframedoc.body);
      html2canvas(iframedoc.body).then((canvas) => {
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = canvas.toDataURL('image/png');
        link.download = 'result.png';
        link.click();
        document.body.removeChild(link);
      });*/

        toPng(elementRef.current, { cacheBust: false })
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = "my-image-name.png";
            link.href = dataUrl;
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
     
      setInitialText(text);
      editorRef.current?.getInstance().setHTML(text);
      togglePopup();



    }


  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
     <div className="content-wrap">
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
      <div className="side-menu">
      <BoardList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>
        </div>



      <div className="main_content">


      {showPopup ? (
  <OrderList closePopup={() => togglePopup()} onChange={(value)=>writeOrder(value)} />
) : null}

      {show ? (
    <DraftsList onChange={(value) => {setSubject(value.subject);editorRef.current?.getInstance().setHTML(value.content);setDraftNo(value.draft_no)}} closePopup={() => toggleShow()}/>
    ) : null}
      
      <div className="btns">
      <div className="btn"  style={{width:"15px", padding:"4px 3px", display:"flex", alignItems:"center", marginRight:"7px"}} onClick={()=>addBookmarkPage()}><BsPin/></div>
      <h3>게시글 작성</h3>

      <select
        style={{border:"1px solid #ddd", marginLeft:"5px", padding:"2px 5px"}}
        className="select"
        value={boardNo}
        onChange={selectBoard}
      >
        <option value="">게시판 선택</option>
        {boards.map((element, index)=>
        <option value={element.bg_no}>{element.bg_name}</option>
        )}
   
      </select>

      </div>
       


      



    
      <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"100%", margin:"0 auto"}}>
              <div className="left">

              
              <button className="btn" onClick={handleRegisterButton} style={{marginRight:"10px"}}>등록</button>

              <button className="btn" onClick={handleDraftButton}>임시저장</button>

              <button onClick={() => togglePopup()}>발주서 불러오기</button>
              </div>

              <div className="right">
                <button onClick={() => {setShow(!show);}}>임시저장글</button> 

            </div>
            </div>
      <table className="write_table" style={{width:"100%"}}>

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  <tr>
  <td class="table_title2" nowrap="">
    제목
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="text" name="name" className="formInput" placeholder="제목을 입력하세요." style={{marginLeft:"10px"}} onChange={subjectChange} value={subject}></input>
    </div>
    </td>
  </tr>
  <tr>
  <Tooltip
        id="date_guide"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>설정하지 않을 시 무제한 보관됩니다.</span>
          </div>
        </Tooltip>
  <td class="table_title2" nowrap="">
    게시 유효일<BiInfoCircle data-tooltip-id="date_guide" style={{marginLeft:"5px"}}/>
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="date" name="name" className="formInput" placeholder="제목을 입력하세요." style={{marginLeft:"10px"}} onChange={endDateChange} value={endDate}></input> 
    </div>
    </td>
  </tr>
  <tr>
  <td class="table_title2" nowrap="">
    첨부파일
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler} multiple/>
    <button style={{cursor:'pointer'}} className="large_file_btn" onClick={()=>onBtnClick()}>
      파일 첨부
    </button>
    <button id='drftrgvlnbpewmcswmcs' style={{cursor:'pointer'}} className="large_file_btn" onClick={() => popupwindow("/large_file", "large file upload", 800, 400)} alt=''>
        대용량 파일 링크 첨부하기
        </button>
    </div>
   
    </td>
  </tr>
  <tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    
  </td>
    <td class="upload_file_list">
  {fileList.map((file,index) => 
    <div className="file_item">
      <p>{file.name} {formatBytes(file.size)}</p>
      <button type="button" className="remove" onClick={() => {removeFile(index);}}>x</button>
    </div>
    
  )} 

{largeFiles.map((file,index) => 
    <div>
      <p>{file.name} {formatBytes(file.size)} <span>다운로드 가능 기간</span></p>
      
      <button type="button" onClick={() => {removeFile(index);}}>Remove</button>
    </div>
    
  )} 
    </td>
  </tr>
{statusList.length>0 &&(
  <tr>
  <td class="table_title2" nowrap="">
    문서상태
  </td>
  <td class="table_list2_left">
    <div className="selectNotice">

    <select name="name" className="formInput" style={{marginLeft:"10px"}} value={boardStatus}
                                    onChange={(e)=>setBoardStatus(e.currentTarget.value)}>
    {statusList.map((element,index)=><option value={element}>{element}</option>)}  
    </select> 
    </div>
  </td>
    
  </tr>
)}
  

  <tr>
  <td class="table_title2" nowrap="">
    공지사항
  </td>
  <td class="table_list2_left">
    <div className="selectNotice">

    
  <label htmlFor="0">
      <input
        type="radio"
        name="0"
        value="0"
        checked={type === "0"}
        onChange={onTypeChange}
      />
      설정안함
    </label>
    <label htmlFor="1">
      <input
        type="radio"
        name="1"
        value="1"
        checked={type === "1"}
        onChange={onTypeChange}
      />
      현재 게시판에 공지
    </label>
    {/*<label htmlFor="2">
      <input
        type="radio"
        name="2"
        value="2"
        checked={type === "2"}
        onChange={onTypeChange}
      />
      전체 게시판에 공지
</label>*/}
    </div>
  </td>
    
  </tr>
{list.length>0&&(
  <tr>

  <td class="table_title2" nowrap=""  ref={elementRef} >
    문서 분류
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <select name="name" className="formInput" style={{marginLeft:"10px"}} value={option || ''}
                                    onChange={selectHandle}>
    {list.map((element,index)=><option value={element.name}>{element.name}</option>)}  
    </select> 
    </div>
    </td>
  </tr>
)}
  
 
      </table>
     <div id="response">{links}</div>
 <div className="" style={{ width: "100%", margin: "20px auto", height:"500px", border:"1px solid #ddd"}}>
    {editor === "text" && (
      <Editor
        ref={editorRef} // DOM 선택용 useRef
        initialValue={initialText}
        previewStyle="vertical" // 미리보기 스타일 지정
        height="500px" // 에디터 창 높이
        initialEditType="wysiwyg" //
        toolbarItems={[
          // 툴바 옵션 설정
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock']
        ]}
        plugins={[colorSyntax]} 
        useCommandShortcut={false} // 키보드 입력 컨트롤 방지
        hooks={{
          addImageBlobHook: async (blob, callback) => {
            console.log(blob.size);
            if (blob.size > 5000000) {
              alert(
                "업로드에 실패했습니다. 이미지당 5MB까지 업로드할 수 있습니다."
              );
              return;
            }
            const formData = new FormData();
                    formData.append("img", blob);
                    
                    // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
                    // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...
                    await axios.post(
                      "https://gw.thegmmedical.com:5003/api/img",
                      formData
                    ).then(( res ) => {
                      console.log(res);
                      console.log(
                        "성공 시, 백엔드가 보내주는 데이터",
                        res.data.url
                      );
  
                      const IMG_URL = res.data.url;
                      setImgLink(IMG_URL);
                      // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
                      callback(IMG_URL, "test");
                      //alert(IMG_URL);
                      files.push(IMG_URL);
                      setArray(files);
                      //setArray((fileArray) => [...fileArray, IMG_URL]);
                      //alert("fileArray: ", files);
                      console.log(files);
                      var arrayLength = files.length;
                      //alert(arrayLength);
                      //alert("end");
                    
                    });
            //callback('https://gw.thegmmedical.com:5003/img/카레유.png', '카레유');
          }
        }}
      ></Editor>
    )}
    {editor === "excel" && (

    div
    )}
    </div>
      <div style={{width:"100%", margin:"10px auto"}}>
        <select value={editor} onChange={selectEditor}>
        <option value="toast">Toast 에디터</option>
        <option value="excel">엑셀 에디터</option>
        </select>
      </div>
      
      <div className="button_wrap">
      <button className="submit" onClick={handleRegisterButton}>등록</button>
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>

    
    </div>
  );
}
