import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {FaAddressBook} from "react-icons/fa";
import "../css/setting.css";
const AbsentSettings = ({navigation}) => {
  const [user, setUser] = useState("");
  const [reason, setReason]=useState("");
  const [absent, setAbsents]=useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const dataFetch = useRef(false);
  const getInitialState = () => {
    const value = "Orange";
    return value;
  };
  const [start, setStart] = useState(getInitialState);

  const handleChange = (e) => {
    setStart(e.target.value);
  };

  const [num, setNum] = useState(getInitialState);

  const handleNum = (e) => {
    setNum(e.target.value);
  };
  useEffect(() => {
    
   
    axios.get('https://gw.thegmmedical.com:5003/api/getabsents')
    .then(function (response){
      console.log(response);
      setAbsents(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  
    

  
    

  }, []);
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 
 
  function saveSettings(){
  
    const data = {
      start_date: startDate,
      end_date : endDate,
      reason: reason,
      user: user.user_no

    }
    console.log("is it working..?");
    axios.post('https://gw.thegmmedical.com:5003/api/absent_setting',data)
    .then(function (response){
      console.log(response);
      alert("대결자 설정이 저장되었습니다.")
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };
  const startDateChange = e => {
    
    const { name, value } = e.target;

    setStartDate(value);
  }
  const endDateChange = e => {
    
    const { name, value } = e.target;

    setEndDate(value);
  }

  window.addEventListener('message', (event) => {
    if (event.data["selectedMember"] !== undefined){
      setUser(event.data["selectedMember"]);
      console.log(event.data["selectedMember"]);
    }
    
  });

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setReason(value);
  };

  return (
    <div className="wrapper">
        <div className="sort-setting doc-setting absent">
            <table style={{width:"100%"}}>
            <tr>
                    <thead>부재기간</thead>
                    <td>
                    <div className="input-group" style={{display:"flex"}}>
     <input type="date" name="startDate" className="formInput" style={{width:"90px"}}  
     onChange={startDateChange} value={startDate}></input> 
<span>~</span>
     <input type="date" name="endDate" className="formInput" style={{marginLeft:"10px", width:"90px"}} 
     onChange={endDateChange} value={endDate}></input> 



  </div>
                       
                    </td>
                </tr>
                <tr>
                    <thead>부재사유</thead>
                    <td>
                    <div className="input-group" style={{textAlign:"left"}}>
                    <input type="text" onChange={handleInputChange} value={reason}></input>
                       
                    </div>
                    </td>
                </tr>

                <tr>
                    <thead>

                    대결자 선택</thead>
                    <td>
                    <div className="input-group" style={{textAlign:"left"}}>
                    <input type="text" value={user.name} readonly></input>
                    <FaAddressBook id='' style={{cursor:'pointer'}} onClick={() => popupwindow("/people", "people", 800, 800)} alt=''/>

                       
                    </div>
                    </td>
                </tr>

        
               
            </table>
            <div className="btns" style={{width:"100%", display:"flex", justifyContent:"center"}}>
            <button className="button" onClick={()=> saveSettings()} >저장</button>
            </div>
            </div>

            <div style={{ maxWidth:"1000px", marginTop:"20px", maxHeight:"400px", overflowY:"scroll"}}>
            <table style={{width:"100%", border:"1px solid #ddd"}}>
    	
           <thead>
            <tr height="23" style={{width:"100%"}}>
                <th nowrap="" class="table_title">순번</th>
                
                <th nowrap="" class="table_title">시작일</th>
                <th nowrap="" class="table_title">종료일</th>
                <th nowrap="" class="table_title">사유</th>
                <th nowrap="" class="table_title_rno">대결자</th>
            </tr> 
            </thead>
            <tbody>
            {absent.map((element,index)=>(
                  <tr height="23">
                  <td nowrap="" class="table_title">{element.no}</td>
                  
                  <td nowrap="" class="table_title">{element.start_date}</td>
                  <td nowrap="" class="table_title">{element.end_date}</td>
                  <td nowrap="" class="table_title">{element.reason}</td>
                  <td nowrap="" class="table_title_rno">{element.name} {element.job_name} [{element.d_name}]</td>
              </tr> 
            ))}
     





    </tbody></table>
    </div>

            
            
      

    </div>

  );
};

export default AbsentSettings;