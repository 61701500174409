
import { NavLink, Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import BoardList from "../components/side/BoardList";
import axios from "axios";
import "./css/leave.css";

import moment from "moment";
import LeaveHistory from "../components/LeaveHistory";
import { BiSearchAlt } from "react-icons/bi";
var currentData=[];
const LeaveList = ({date, closePopup}) => {
  var todayDate = new Date().toISOString().slice(0, 10);
  console.log(todayDate);
  const inputFileRef = useRef();
  const [year, setYear] = useState(new Date(date).getFullYear());
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([1,2,3,4,5,6,7,8,9,10,11,12]);


  const [month, setMonth] = useState(new Date(date).getMonth()+1);
  const [expire, setExpire] = useState("1");
  const [vrange, setRange] = useState("1");
  const [vtype, setType] = useState("1");
  const [reason, setReason] = useState("");
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState();
  const [startHour, setStartHour] = useState("");
  const [startMin, setStartMin] = useState("");
  const [endHour, setEndHour] = useState("");
  const [endMin, setEndMin] = useState("");
  const [info, setInfo]= useState([]);
  const [used, setUsed] = useState("");
  const [remain, setRemain] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData]= useState();
  const [holidays, setHolidays] = useState([]);
  const [rows, initRow] = useState([]);
  const [groupedLeaves, setGroupedLeaves] = useState([]);
  const [filterData, setfilterData] = useState([]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
};

function getBusinessDaysDiff(startDate, endDate) {
  var diffDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
  var weekends = 0;

  for (var i = 0; i <= diffDays; i++) {
    var currentDate = new Date(startDate.getTime() + i * (1000 * 3600 * 24));

    
    
        
       

    if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
      weekends++;
    }else{
      for (let i=0;i<holidays.length;i++){
        var from = new Date(holidays[i].start.date); // -1 because months are from 0 to 11
        var to = new Date(holidays[i].end.date); // -1 because months are from 0 to 11
        
        if (currentDate >= from && currentDate <= to){
          weekends++;
          break;
  
        }
        
      }
    }
  }

  var businessDaysDiff = diffDays - weekends;
  return businessDaysDiff;
}


  
    
    useEffect(() => {

      let com_year= new Date(date).getFullYear();
      let year_arr = [];
      for (var y = (com_year - 5); y <= (com_year + 2); y++) {
        year_arr.push(y);
      }
     
      setYears(year_arr);
      getLeaves(year, month);
      axios
      .get("https://gw.thegmmedical.com:5003/api/getuser_leave")
      .then(({ data }) => {
        console.log("getuser_leave", data);
        setInfo(data[0]);
  
        let num = data[0].number;
        axios
          .get("https://gw.thegmmedical.com:5003/api/getuserleave", {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
  
            var history=data;
            var sum = 0;
            for (let i=0;i<history.length;i++){
              if (history[i].vtype !== "1"){
                sum+=parseFloat(history[i].vtype);
                console.log(history[i].vtype)
              }else{
                sum += parseInt(history[i].days);
              }
            }
            var day = Math.trunc(sum);
            var time = sum % 1.0;
            setUsed(day+"일 "+(time/0.25)*2+"시간");
            //console.log("...." + data);
            var remainDay = Math.trunc(num - sum);
            var remainTime = (num - sum) % 1.0;
            setRemain(remainDay+"일 "+(remainTime/0.25)*2+"시간")
            
            /*setInfo(data[0][0]);
            var history = data[1];
           
            }*/
          });
          
  
      });
      
      
    }, []);

    const getLeaves = (year, month) =>{

      if (month < 10) { month = '0' + month; }

      const data = {
        year : year,
        month : month
      }

      console.log("저기", data);
      axios
      .post("https://gw.thegmmedical.com:5003/api/getleaves_bymonth",data)
      .then((response) => {
        console.log("responsed.,fs.", response);
        setfilterData(response.data);
        setData(response.data);
        if (response.data.length>0){
          let leaves = response.data;
          let grouped_leaves = Object.groupBy(leaves, ({ user_no }) => user_no);

          setGroupedLeaves(grouped_leaves);
          console.log("grouped_leaves,,,,,,,", grouped_leaves);
        }
        
      
      });
    }
   


  return (
    <div className="leave_list">

    
    <div className="popup">
      <div className="inner" style={{ height:"70%", position:"relative",overflowY:"scroll",
      left:"50%", transform:"translateX(-50%) translateY(-50%)"}}>
     {showPopup ? (
  <LeaveHistory closePopup={() => togglePopup()} />
) : null}

<div onClick={closePopup} style={{position:"absolute", right:"0", marginRight:"20px"}}> <button>닫기</button> </div>
      
      <div className="" style={{justifyContent:"flex-start"}}>
        {/*<div className="menu-icon" onClick={handleShowNavbar}>
          <GiHamburgerMenu/>
        </div>*/}
      
        <div className="">
        
        <div className="board_wrapper">
          <form name="form1" id="form1" method="post">
          <div className="board_container">
            <div id="content" className="content">
                          
              <div className="board_top">
                  
      <table className="write_table leave_view" style={{textAlign:"center", marginTop:"50px"}}>
<tr style={{marginBottom:"0", padding:"5px 0", background:"#f5fcff", borderTop:"1px solid #9dcffc", borderBottom:"1px solid #9dcffc"}}>
  <th style={{width:"25%"}}>
    년도
  </th>
  <th style={{width:"25%"}}>
    할당일
  </th>
  <th style={{width:"25%"}}>
    사용일
  </th>
  <th style={{width:"25%"}}>
    잔여일
  </th>
</tr>
<tr className="">
<td style={{width:"25%", border:"1px solid #F0F0F0",  height:"60px"}}><span>{new Date().getFullYear()}</span></td>

<td style={{width:"25%", border:"1px solid #F0F0F0",  height:"60px"}}><span> {info!==undefined && info.number}일</span></td>
<td style={{width:"25%", border:"1px solid #F0F0F0",  height:"60px"}}><span> {used}</span>
<span onClick={()=>togglePopup()} style={{marginLeft:"5px", fontWeight:"bold", color:"#337ab7", cursor:"pointer"}}>조회</span>
</td>
<td style={{width:"25%", border:"1px solid #F0F0F0",  height:"60px"}}><span> {remain}</span>
<Link to={"/doc_w/0"} state={{form : "휴가"}}><span style={{marginLeft:"5px", fontWeight:"bold", color:"#337ab7", cursor:"pointer"}}>휴가신청</span></Link>

</td>


</tr>
</table>
                           
                       
                          </div>

                          <select value={year}
                                    onChange={(e)=>{getLeaves(e.currentTarget.value, month);setYear(e.currentTarget.value);}}>
                                      {years.map((ele, idx)=>(
                                        <option value={ele}>{ele}년</option>
                                      ))}
                                      
                                    </select>

                                    <select value={month}
                                    onChange={(e)=>{getLeaves(year, e.currentTarget.value);setMonth(e.currentTarget.value)}}>
                                      <option>선택</option>
                                      {months.map((ele,idx)=>(
                                        <option value={ele}>{ele}월</option>
                                      ))}
                                      
                                    </select>

                                    <select value={user}
                                    onChange={(e)=>{
                                      if (e.currentTarget.value !=="all"){
                                      let temp = data; let filtered = temp.filter(item =>
                                      item.user_no == e.currentTarget.value
                                      )
                                      setfilterData([...filtered]);
                                      }else{
                                        setfilterData([...data]);
                                      }
                                      
                                    }}>
                                      <option value="all">휴가자 전체</option>
                                      {Object.keys(groupedLeaves).map((ele,idx)=>(
                                        <option value={groupedLeaves[ele][0].user_no}>{groupedLeaves[ele][0].name}{" "}{groupedLeaves[ele][0].job_name} ({groupedLeaves[ele][0].d_name}) - {groupedLeaves[ele].length+"건"}</option>
                                      ))}
                                      
                                    </select>     

                                     <select
                                        onChange={(e)=>{
                                          if (e.currentTarget.value !=="all"){
                                          let temp = data; let filtered = temp.filter(item =>
                                          item.vtype == e.currentTarget.value
                                          )
                                          setfilterData([...filtered]);
                                          }else{
                                            setfilterData([...data]);
                                          }
                                          
                                      }}>                                      
                                      <option value="all">구분 전체</option>
                                      <option value="1">연차</option>
                                      <option value="0.5">반차</option>
                                      <option value="0">공가</option>

                                    </select>                           
<div className="">
                         
                          
<table className="responsive">
<thead>
<tr class="tx-center">
  <th class="wd-80 xl-hidden">휴가자</th>
 
  <th class="">구분</th>
  <th class="wd-150 xl-hidden">휴가기간</th>
  <th class="wd-120 xl-hidden">휴가일수</th>

  <th class="wd-120 xl-hidden">연차차감</th>
  <th class="wd-120 xl-hidden">전자결재</th>
  <th class="wd-120 xl-hidden">사유</th>


</tr>
</thead>
<tbody>

                
               {filterData
                .map(
                  ({
                    no, name, start_date, end_date, job_name,days, contact, vtype, reason, vrange
                  }) => (
                    ( <tr key={no}>
       
                  
                     
                      <td data-label="휴가자">
                      {name} {job_name}
                      </td>

                      <td data-label="구분">
                      {vtype==="1" && (
                        <span>연차</span>
                      )}
                      {vtype==="0.5" && (

  vrange==="2"? <span>오후 반차</span>: <span>오전 반차</span>
                      )}
                      {vtype==="0.25" && (
                        <span>반반차</span>
                      )}
                       {vtype==="0" && (
                        <span>공가</span>
                      )}
                      </td>


                      <td data-label="휴가기간">
                      {moment(start_date).format("YYYY-MM-DD")} ~ {moment(end_date).format("YYYY-MM-DD")}
                      </td>

                      <td data-label="휴가일수">
                      {days * vtype} 일
                      </td>
                      
                      <td data-label="연차차감">
                      {days * vtype}
                      </td>
                      
                      <td data-label="전자결재">
                      {job_name}
                      </td>
                      <td data-label="사유">
                      {reason} / 비상 연락처 : {contact}
                      </td>
                    </tr>)
                   
                  )
                )}

</tbody>
</table>
          <table>

            <colgroup>
              <col className="m_dp_n table_no" />
              <col className="" />
              <col className="ta_l" />
              <col className="m_dp_n table_date" />
            </colgroup>
            {/*<thead>
      <tr>
          <th className="m_dp_n table_no" scope="col">NO.</th>
          <th scope="col"></th>
          <th className="ta_l" scope="col">제목</th>
          <th className="m_dp_n table_date" scope="col">날짜</th>
      </tr>
  </thead>*/}
            <tbody>
             
            </tbody>
          </table>

          {filterData.length===0 && (
                <div style={{ textAlign: "center", padding:"50px 0", width:"100%", borderBottom:"1px solid #ddd" }}>
                  <p>휴가자가 없습니다.</p>
                </div>
              )}

          
</div>
                      </div>
                  </div>
          </form>
        </div>
      </div>
            
            
                
                
                

        </div>
     </div>
    </div>
  </div>
  );
};

export default LeaveList;
