import React, { useState, useEffect, useRef } from "react";

import styled from 'styled-components'


import "./css/share_mail.css";

import axios from "axios";



const SendWorkReport = ({ values, member, text, closePopup }) => {

  const [peopleArray, setPeopleArray] = useState([]);
  const [inputValue, setInputValue] = useState('')
  const [isHaveInputValue, setIsHaveInputValue] = useState(false)
  const [dropDownList, setDropDownList] = useState(peopleArray)
  const [dropDownItemIndex, setDropDownItemIndex] = useState(-1)

  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const [pic, setPic]= useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [userNo, setUserNo] = useState("0");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [pwCheck, setPwCheck] = useState("");
  const [preview, setPreview] = useState("");
  const [profile, setProfile] = useState("");
  const [department, setDepartment] = useState([]);
  const [selectedD, setSelectedD] = useState(39);
  const [selectedJ, setSelectedJ] = useState(13);
  const [job, setJob] = useState([]);
  const [startDate, setStartDate]=useState("");
  const [startD, setStartD]=useState("");
  const [showPopup, setPopup] = useState(false);
  const [tel, setTel]= useState("");
  const [extension, setExtension]= useState("");
  const [fax, setFax]= useState("");
  const [place, setPlace]= useState("");
  const [recItem, setRecItem] = useState("");
  const [recList, setRecList] = useState([]);

  const togglePopup = () => {
    setPopup(!showPopup);
  };

  const imageRef=useRef();

   

  

   
    const generateId_rec = () => {
      console.log("rec................",recList);
       const highestId = Math.max.apply(Math, recList.map(function (element) {
              return element.id;
          }));
          let newId = 1; // default in case the array is empty
  
          if (highestId > 0) {
              // generate a new ID based off of the highest existing element ID 
              newId = (highestId + 1);
          }
          return newId;
    };
  
    function createNewToDoItem_rec() {
  
      if (recItem !== '') {
        const item = { id: generateId_rec(), text: recItem };
        const tempArray = [...recList, item];
        setRecList(tempArray);
      }
      setRecItem('');
    }
   
  
    const handleKeyPress_rec = e => {
      //console.log("keycode is " , e.key);
      if (e.key === 'Enter') {
        createNewToDoItem_rec();
      }else if (e.key === ' '){
        createNewToDoItem_rec();
      }else if (e.key === 'Tab'){
        createNewToDoItem_rec();
      }
      /*e.target.addEventListener("blur" , e =>{
        
        createNewToDoItem_rec2(e.target.value);
      });*/
      
      
      
    };

    const deleteItem_rec = id => {
      setRecList(recList.filter(item => item.id !== id));
    };
  
    function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }
    const display_rec = recList.map(item => (
    
      <div className="">
        {ValidateEmail(item.text) ? (
          <div className="user_list">
          <li key={item.id} className="valid">
            {item.text}
            </li>
            <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>X</span>
      </div>
        ) : (
          <div className="user_list unvalid">
          <li key={item.id}>
            {item.text}
            </li>
            <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>X</span>
      </div>
        )}
      </div>
      
    ));
const handleDropDownKey = event => {
    //input에 값이 있을때만 작동
    if (isHaveInputValue) {
      if (
        event.key === 'ArrowDown' &&
        dropDownList.length - 1 > dropDownItemIndex
      ) {
        setDropDownItemIndex(dropDownItemIndex + 1)
      }

      if (event.key === 'ArrowUp' && dropDownItemIndex >= 0)
        setDropDownItemIndex(dropDownItemIndex - 1)
      if (event.key === 'Enter' && dropDownItemIndex >= 0) {
        clickDropDownItem(dropDownList[dropDownItemIndex])
        setDropDownItemIndex(-1)
      }
    }
  }
  const clickDropDownItem = clickedItem => {
    setInputValue(clickedItem); setRecItem(clickedItem);
    const email = clickedItem.substring(clickedItem.indexOf("<")+1, clickedItem.indexOf(">"));
    const name = clickedItem.substring(clickedItem.indexOf(0), clickedItem.indexOf("<"));
    if (clickedItem !== '') {
      const item = { id: generateId_rec(), name: name ,text: email };
      const tempArray = [...recList, item];
      setRecList(tempArray);
    }
    setRecItem('');
    setIsHaveInputValue(false)
    console.log(clickedItem);
  }

  

  const showDropDownList = () => {
    if (recItem === '') {
      setIsHaveInputValue(false)
      setDropDownList([])
    } else {
      const choosenTextList = peopleArray.filter(textItem =>
        textItem.includes(recItem)
      )
      setDropDownList(choosenTextList)
    }
  }

  useEffect(showDropDownList, [recItem])
  useEffect(() => {
    console.log("hihihihiiii", values);
    setSubject(values.wr_subject);
    axios.get("http://gw.thegmmedical.com:5003/api/getuserinfo/"+values.wr_user_no).then((response) => {
     let user = response.data[0].name+" "+response.data[0].job_name+" ["+response.data[0].d_name+"]";
      setPic(user);
  });

    
    
}, []);


const handleRegisterButton = () => {

  if (subject === "") {
    alert("제목을 입력해주세요.");
    return false;
  } 
  let type="";
  if (values.wr_type==="daily"){
    type="일일";
  }else{
    type ="주간 : ["+values.week_text+"]";
  }

  var html = '<div style="width:80%;margin:20px auto;border-bottom:1px solid #ddd;">'+msg+'</div><table style="width: 80%;margin: 10px auto;border-collapse: collapse;table-layout: fixed; border:1px solid #ddd"><tr style="height: 50px;margin: 0;display: flex; width: 100%;"><td style="width:130px;text-align:center;display:flex;padding-bottom:5px;flex-shrink: 0;">업무명</td><td style="display:flex;width:100%;"><span>'+values.subject+'</span></td></tr><tr style="height: 50px;margin: 0;display: flex; width: 100%;"><td style="width:130px;text-align:center;display:flex;padding-bottom:5px;flex-shrink: 0;">일지제목</td><td style="display:flex;width:100%;"><span>'+values.wr_subject+'</span></td></tr><tr style="height: 50px;margin: 0;display: flex; width: 100%;"><td style="width:130px;text-align:center;display:flex;padding-bottom:5px;flex-shrink: 0;">작성자</td><td style="display:flex;width:100%;"><span>'+pic+'</span></td></tr><tr style="height: 50px;margin: 0;display: flex; width: 100%;"><td style="width:130px;text-align:center;display:flex;padding-bottom:5px;flex-shrink: 0;">분류</td><td style="display:flex;width:100%;"><span>'+type+'</span></td></tr><tr style="height: 50px;margin: 0;display: flex; width: 100%;"><td style="width:130px;text-align:center;display:flex;padding-bottom:5px;flex-shrink: 0;">진척율</td><td style="display:flex;width:100%;"><span>'+values.progress+'%</span></td></tr><tr style="height: 50px;margin: 0;display: flex; width: 100%;"><td style="width:130px;text-align:center;display:flex;padding-bottom:5px;flex-shrink: 0;">시간</td><td style="display:flex;width:100%;"><span>'+values.workhour+'시간</span></td></tr></table> <div style="display:flex;width:80%;margin:20px auto;border-top:1px solid #ddd;"><div style="padding:20px;width:50%"><span>실적</span><div>'+values.performance+'</div></div><div style="padding:20px;width:50%"><span>계획</span><div>'+values.plan+'</div></div></div>'; 

  var toList =  [];
  for (let i = 0; i<recList.length;i++){
    toList.push(recList[i].text);
  }
        
        
          const data = {
            subject: "[일지공유] "+values.wr_subject,
            html : html,
            receive : toList
           }
           console.log(data);
           axios.post('http://gw.thegmmedical.com:5003/api/mailshare', data)
           .then(function (response){
             console.log(response);
             window.location.reload(true);
        
           })
           .catch(function (error){
             console.log(error);
           })
           .then(function (){
             //always executed
           });    

};


  return (
    <div className="popup">
      <div className="inner" style={{height:"auto", maxHeight:"500px", position:"relative",
      left:"50%", transform:"translateX(-50%) translateY(-50%)", top:"50%"}}>
        <h3 style={{textAlign:"left"}}>업무 공유</h3>
        <div className="shareMail">
      
        <div className="input-group">

        {recList.length>0 && (<ul className="rec_list">
        {display_rec}
      
        </ul>)}

        <div style={{position:"relative"}} isHaveInputValue={isHaveInputValue}>
          
        <input
          type='text'
          value={recItem}
          onChange={e => {
            setRecItem(e.currentTarget.value);
          }}
          onKeyUp={handleDropDownKey}
          onKeyDown={handleKeyPress_rec}
          style={{borderBottom:"3px solid #ddd"}}
          placeholder="이메일을 입력하세요"
          //onBlur={handleBlur_rec} 
        />
        
        {recItem && (
        <DropDownBox>
          {dropDownList.length === 0 && (
            <DropDownItem>해당하는 단어가 없습니다</DropDownItem>
          )}
          {dropDownList.map((dropDownItem, dropDownIndex) => {
            return (
              <DropDownItem
                key={dropDownIndex}
                onClick={() => clickDropDownItem(dropDownItem)}
                onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
                className={
                  dropDownItemIndex === dropDownIndex ? 'selected' : ''
                }
              >
                {dropDownItem}
              </DropDownItem>
            )
          })}
        </DropDownBox>
      )}
      </div> 
      </div>

      <div className="input-group">

          <input type="text" value={subject} onChange={(e)=>setSubject(e.currentTarget.value)} placeholder="제목을 입력하세요"></input>
        </div>

        <div className="input-group">

          <textarea value={msg} onChange={(e)=>setMsg(e.currentTarget.value)} placeholder="내용을 입력하세요"></textarea>
        </div>
     
      
   
      <div className="buttons">
        <button onClick={()=>handleRegisterButton()}>전송</button>
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>취소</button>
        </div>


        </div>
        
        
      </div>
      
    </div>
  );
};
const DropDownBox = styled.ul`
  display: block;
  width:475px;
  top:21px;
  position:absolute;
  
  padding: 8px 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 2px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 9999;
`

const DropDownItem = styled.li`
  padding: 0 16px;

  &.selected {
    background-color: lightgray;
  }
`
export default SendWorkReport;