




import { NavLink, Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {FaCog, FaSitemap, FaStar, FaRegListAlt, FaClipboardList } from "react-icons/fa";
import { HiPencilAlt, HiDocumentReport } from "react-icons/hi";
import { AiFillPlusSquare } from "react-icons/ai";
import {BsPencilSquare, BsPlusSquare} from "react-icons/bs";
import "../css/schedulelist.css";



const ScheduleList = ({onChange}) => {
    const [admin, setAdmin] = useState(false);
    const [received, setReceived] = useState([]);
    const [sent, setSent] = useState([]);
    const [userCalendars, setUserCalendars] = useState([]);
    const [groupCalendars, setGroupCalendars] = useState([]);

    const [members, setMembers] = useState([]);
    const [parent, setParent] = useState("");
    const [dHead, setDHead] = useState([]);
    const [name, setName] = useState("");
    const [num, setNum] = useState(0);
    const [department, setDepartment]= useState([]);
    const navigate = useNavigate();
    const {state}= useLocation();

    const onTreeStateChange = (state, event) => console.log(state, event);

    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].bg_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.bg_name,
            parentNo : node.bg_parent,
            num : node.bg_no,
            level : node.bg_level,
            order : node.bg_order,
            dNo : node.d_no,
            type : node.bg_type,
            children: lookingForNodeWithParent( nodes, node.bg_no )
          });
        }
      }
      
      return arrayToReturn;
    }
    let location = useLocation();

  


    useEffect(() => {
      axios.get('http://gw.thegmmedical.com:5003/api/check_dhead',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      console.log("부서장ㅇ?", response.data[0]);
      setDHead(response.data[0]);
      //setBookmarks(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('http://gw.thegmmedical.com:5003/api/check_admin',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      console.log("부서장ㅇ?", response.data[0]);
      if (response.data[0].authority ==="admin"){
        setAdmin(true);
      }else{
        setAdmin(false);
      }
      
      //setBookmarks(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
   

   
    axios.get('http://gw.thegmmedical.com:5003/api/get_workgroup',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setDepartment(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    }, []);
 

        function allBoard(){
          axios.get("http://gw.thegmmedical.com:5003/api/news").then((response) => {
              
            });
        }
    
     
     
     
     
    
     
      
      
      // add fake url to data for testing purpose
    
      const [CheckList, setCheckList]=useState([2,3,4,8]);
   
  return (
    <div className="wrapper">
     
     <div className="board_nav schedule_nav">
     <div style={{padding:"30px", background:"#f7f7f7"}}>
        <h2>업무</h2>
        <a href="/work_w"><div className="board_wrt"><HiPencilAlt style={{marginRight:"5px"}}/>업무등록</div></a>


    <div className="wrap">


    <div className="list">
<span className="list_title" onClick={()=>allBoard()}><span><FaRegListAlt/>받은 업무함</span>



</span>
<div className="menus">

<span><a href="/worklist/pic">작업책임자</a></span>
<span><a href="/worklist/cc">작업자/참조자</a></span>


  {/*{received.map((element, index)=>{
    var color = `${element.color}`;
    

    
    return (<div style={{display:"flex", marginBottom:"5px", 
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}>
      <a href={"/worklist/"+element.no}><span className={element.no}>{element.name}</span></a></div>
    {dHead !==undefined && (
      dHead.d_no === element.d_no && (
      <a href={"/worklist/"+element.no}><BsPencilSquare/></a>
    )
    )}
    
    
    </div>
    
    )
  })}*/}

 

</div> 
</div>
   
<div className="list">
<span className="list_title"><span><a href="/worklist/sent"><FaRegListAlt/>보낸 업무함</a></span>


</span>
<div className="menus">
 

 

</div> 
</div>
   

  

<div className="list">
<span className="list_title" onClick={()=>allBoard()}><span><FaRegListAlt/>공용 업무함</span> 
<Link to="/add_workgrp"><BsPlusSquare/></Link></span>
<div className="menus">
{department.map((element, index)=>{
    var color = `${element.color}`;
    /*const style = {
      color: '#000000',
      "&::after": {
        backgroundColor: element.color
      }
    };*/

    
    return (<div style={{display:"flex", marginBottom:"5px", 
    alignItems:"center", justifyContent:"space-between"}}>
      <div style={{display:"flex"}}>
    <span className={element.no}><a href={"/worklist/"+element.no}>{element.name}</a></span></div>
 
      <Link to="/workgrp_u" state={{group : element}}><BsPencilSquare/></Link>
   
    
    </div>
    
    )
  })}
</div>    
</div>

<div className="list">
  <span className="list_title"><span><HiDocumentReport/>업무일지</span></span>

  <div className="menus">
    <span><a href="/report/daily">일일 업무일지</a></span>
    <span><a href="/report/weekly">주간 업무일지</a></span>
    <span><a href="/report/monthly">월간 업무일지</a></span>
    <span><a href="/d_report">부서 업무일지</a></span>

  </div>    
 
</div>


</div>


        </div>
    </div>
    </div>
  );
};

export default ScheduleList;








