import { NavLink, Link, useParams } from "react-router-dom";
import moment from 'moment';
import React, { useState, useEffect, useRef } from "react";
import './css/doc.css';
import user from "../components/img/user.png";
import Search from "./Search";
import axios from "axios";
import { BsPin } from "react-icons/bs";
import { HiDocumentMagnifyingGlass } from "react-icons/hi2";
import DocList from "../components/side/DocList";
import {GiHamburgerMenu} from "react-icons/gi";

import styled from 'styled-components';

import { BsPencilSquare, BsFillCheckCircleFill, BsFillPencilFill } from "react-icons/bs";
axios.defaults.withCredentials = true; 
const TabMenu = styled.ul`
  font-weight: bold;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin-top: 30px;
  margin-bottom:0;

  .submenu {
  // 기본 Tabmenu 에 대한 CSS를 구현
    display: flex;
    /* justify-content: space-between;
    width: 380px;
    heigth: 30px; */
    border:1px solid #ddd;
    border-bottom:none;
    padding: 15px 20px;
    cursor:pointer;
    transition: 0.5s;
  }
  .submenu:hover {
    background:#1c1d51;
    color:white;
  }
  .submenu:first-child {
  }
  .focused {
   //선택된 Tabmenu 에만 적용되는 CSS를 구현
    color: #fff;
    background:#6fa5d1;
    
  }

  & div.desc {
    text-align: center;
    
  }
`;

const Desc = styled.div`
  text-align: center;border:1px solid #ddd;
`;

const Doc = () => {  
  const [currentTab, clickTab] = useState(0);
  const { menu } = useParams();
  const [docs, setDocs] = useState([]);
  const [ccDocs, setCcDocs] = useState([]);
  const [repDocs, setRepDocs] = useState([]);

  const [isEmpty, setIsEmpty] = useState(true);
  const [isCcEmpty, setIsCcEmpty] = useState(true);
  const [isRepEmpty, setIsRepEmpty] = useState(true);

  const [sidebar, setSidebar] = useState(false);

  const [bookmarks, setBookmarks] = useState([]);
  const [incomplete, setInComplete] = useState([]);
  const [received, setReceived] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [check, setCheck] = useState([]);
  const [docs1, setDocs1] = useState("0");
  const [rdocs0, setRdocs0] = useState("0");
  const [docs3, setDocs3] = useState("0");
  const [rdocs5, setRdocs5] = useState("0");

  useEffect(() => {
    if (menu !==undefined){
      var index = menu * 1;
      clickTab(index); console.log(currentTab);
    }
    axios.get('https://gw.thegmmedical.com:5003/api/docs1',{})
    .then(function (response){
      setDocs1(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/rdocs0',{})
    .then(function (response){
      setRdocs0(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
axios.get('https://gw.thegmmedical.com:5003/api/docs3',{})
    .then(function (response){
      setDocs3(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/rdocs5',{})
    .then(function (response){
      setRdocs5(response.data[0].cnt);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/getdocs')
    .then(function (response){
      console.log("hey result is!!!!!!!!!!!!", response.data);
      //setAbsent(response.data);
      //setLine(response.data[1]);
      let docs = response.data;
      setDocs(docs);
      
      if (docs.length === 0){
        setIsEmpty(true);
      }else{
        setIsEmpty(false);
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/getdocs_cc')
    .then(function (response){
      console.log("hey cc result is!!!!!!!!!!!!", response.data);
      setCcDocs(response.data);
      if (response.data.length === 0){
        setIsCcEmpty(true);
      }else{
        setIsCcEmpty(false);
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/getdocs_rep')
    .then(function (response){
      console.log("hey cc result is!!!!!!!!!!!!", response.data);
      setRepDocs(response.data);
      if (response.data.length === 0){
        setIsRepEmpty(true);
      }else{
        setIsRepEmpty(false);
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    axios.get('https://gw.thegmmedical.com:5003/api/get_bookmark/form',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      console.log("즐찾", response.data);
      setBookmarks(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

 
  }, [menu]);

  const menuArr = [
    { name: '승인대기 결재문서', 
    content: <table className="table">
    <tbody>
      <tr>
      <td>번호</td>
      <td>문서양식</td>
      <td>제목</td>
      <td>기안자</td>
      <td>기안일</td>
      <td style={{textAlign:"center"}}>결재라인</td>
      </tr>
    {docs.length !==0 && (
      docs.map((element,index)=>(
        <tr>
          <td>{element.doc_no}</td>
          <td>{element.form_name}</td>
          <td>
            
            <Link to={"/docview"}  state={{doc_no: element.doc_no, href:"/rdocs/0"}}>{element.doc_subject}</Link>

          
          </td>
          <td>
          {element.user_name !==undefined && (
            element.user_name.substring(0,3)
          )}

          </td>
          <td>{element.submit_date}</td>
          <td className="doc_line">
          <div className="user">

          <span><BsFillCheckCircleFill/>
          {element.user_name !==undefined && (
            element.user_name.substring(0,3)
          )}
          </span> <p>---</p>
</div>
{element.approvers.split(',').map((ele, idx)=>(
    <div className="user">
    {ele.status !==1 ?
      <span className="wait"><BsFillPencilFill/>{ele}</span>
    : <span><BsFillCheckCircleFill/>{ele}</span>
    }

    {idx !== (element.approvers.split(',').length-1) && (
      <p>---</p>
    )}
    
  </div>
  ))}

  </td>
        </tr>
      ))
    )}
    {isEmpty && (
      <tr style={{ textAlign: "center" }}>
        <td colSpan={6} style={{padding:"50px 0"}}>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}><HiDocumentMagnifyingGlass style={{color:"#ddd", fontSize:"30px"}}/>문서가 없습니다.</div>
        </td>
      </tr>
    )}  
    </tbody>
  </table>},
    { name: '확인대기 결재문서', 
    content: <table className="table">
    <tbody>
      <tr>
      <td>번호</td>
      <td>문서양식</td>
      <td>제목</td>
      <td>기안자</td>
      <td>기안일</td>
      </tr>
      {ccDocs.length !==0 && (
      ccDocs.map((element,index)=>(
        <tr>
          <td>{element.doc_no}</td>
          <td>{element.form_name}</td>
          <td><Link to={"/docview"}  state={{doc_no: element.doc_no, href:"/rdocs/5"}}>{element.subject}</Link></td>
          <td>{element.user_name}</td>
          <td>{element.submit_date}</td>
        
        </tr>
      ))
    )}
    {isCcEmpty && (
      <tr style={{ textAlign: "center" }}>
        <td colSpan={6} style={{padding:"50px 0"}}>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}><HiDocumentMagnifyingGlass style={{color:"#ddd", fontSize:"30px"}}/>문서가 없습니다.</div>
        </td>
      </tr>
    )}  
    </tbody>
  </table>},
{ name: '수신 결재문서', 
content: <table className="table">
<tbody>
  <tr>
  <td>번호</td>
  <td>문서양식</td>
  <td>제목</td>
  <td>기안자</td>
  <td>기안일</td>
  </tr>
  {repDocs.length !==0 && (
  repDocs.map((element,index)=>(
    <tr>
      <td>{element.doc_no}</td>
      <td>{element.form_name}</td>
      <td><Link to={"/docview"}  state={{doc_no: element.doc_no, href:"/rdocs/5"}}>{element.subject}</Link></td>
      <td>{element.user_name}</td>
      <td>{element.submit_date}</td>
    
    </tr>
  ))
)}
{isRepEmpty && (
  <tr style={{ textAlign: "center" }}>
    <td colSpan={6} style={{padding:"50px 0"}}>
      <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}><HiDocumentMagnifyingGlass style={{color:"#ddd", fontSize:"30px"}}/>문서가 없습니다.</div>
    </td>
  </tr>
)}  
</tbody>
</table>}
  ];

  const selectMenuHandler = (index) => {
    // parameter로 현재 선택한 인덱스 값을 전달해야 하며, 이벤트 객체(event)는 쓰지 않는다
    // 해당 함수가 실행되면 현재 선택된 Tab Menu 가 갱신.
    clickTab(index);
  };


  const addBookmarkPage = () => {
    var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
    if(result){

      const data ={
        name: '',
        num : '',
        link : window.location.pathname,
        bookmark : "결재함"
      }
      
      console.log(window.location.pathname)
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
      .then(function (response){
        console.log(response);
        alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };

  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DocList/>
        </div>
      )}
    
    <div className="content-wrap">
    <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>

    <div className="side-menu">
       <DocList/>
    </div>
  

   <div  className="doc_main main_content">

<div className="btns">
<div className="btn" style={{display:"flex", alignItem:"center", height:"15px",padding:"5px", marginRight:"7px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>
<h3>전자결재 메인</h3>
</div>


<h4>내 결재 내역</h4>
<div className="row">
<div className="box"><Link to="/docs/1">미결 ({docs1}) </Link></div>
<div className="box"><Link to="/rdocs/0">수신 ({rdocs0}) </Link></div>
<div className="box"><Link to="/docs/3">반려 ({docs3})</Link></div>
<div className="box"><Link to="/rdocs/1">공람 ({rdocs5})</Link> </div>
</div>

<h4>결재양식 즐겨찾기</h4>
<div className="row">
{bookmarks.map((element, index)=>(
  <div className="box"><a href={"/doc_w/"+element.no}>{element.name}<BsPencilSquare/></a></div>

))}

</div>

<TabMenu>
          {/* <li className="submenu">{menuArr[0].name}</li>
          <li className="submenu">{menuArr[1].name}</li>
          <li className="submenu">{menuArr[2].name}</li> */}
          {menuArr.map((el,index) => (
              <Link to ={el.href}><li className={index === currentTab ? "submenu focused" : "submenu" }
              onClick={() => selectMenuHandler(index)}>{el.name}</li></Link>
            ))}
        </TabMenu>
        <Desc>
          <p style={{height:"500px", overflowY:"scroll"}}>{menuArr[currentTab].content}</p>
        </Desc>
 

   
      </div>
   </div>
    </div>
</div>
  );
};

export default Doc;