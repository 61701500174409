import { NavLink, Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import user from "../components/img/user.png";
import Search from "./Search";
import axios from "axios";
import { BsFillSquareFill } from "react-icons/bs";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import {BsPin} from "react-icons/bs";
import DeliveryScheduleWrite from "../components/DeliveryScheduleWrite";
import DeliveryScheduleUpdate from "../components/DeliveryScheduleUpdate";
import { TiDeleteOutline } from "react-icons/ti";
import { CiCirclePlus } from "react-icons/ci";

import BoardList from "../components/side/BoardList";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";
import addDays from "date-fns/addDays";
import {GiHamburgerMenu} from "react-icons/gi";
import {
  format,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  parse,
  getMonth,
  getYear,
  addWeeks,
  addYears,
  subWeeks,
  subDays,
  getWeek,
  isWeekend,
  getDay,
  differenceInCalendarWeeks,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears
  
} from "date-fns";
import "./css/schedule.css";

axios.defaults.withCredentials = true;


let currentData="";
const Schedule = () => {
  const [leave, setLeaves] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(moment(new Date()).format("MM"));
  
  const monthStart = startOfMonth(new Date());
  const monthEnd = endOfMonth(monthStart);

  const [startDate, setStartDate]=useState(startOfWeek(monthStart));
  const [endDate, setEndDate]=useState(endOfWeek(monthEnd));
  const [start, setStart]=useState(format(startOfWeek(monthStart), "yyyy-MM-dd"));
  const [end, setEnd]=useState(format(endOfWeek(monthEnd), "yyyy-MM-dd"));

  const [calendars, setCalendars]=useState([]);
  const [teamCalendars, setTeamCalendars]=useState([]);
  const [userCalendars, setUserCalendars]=useState([]);
  const [groupCalendars, setGroupCalendars]=useState([]);

  const [schedules, setSchedules] = useState([]);
  const location = useLocation();

  const [calendar, setCalendar] = useState("monthly");
  const [prevCalendar, setPrevCalendar] = useState("");
  const [showLeaveList, setShowLeaveList] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOption] = useState("all");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateText, setSelectedDateText] = useState(format(new Date(), "yyyy-MM-dd"));

  const [filterData, setFilterData]=useState([]);
  const [CheckList, setCheckList] = useState([]);
  const [showPopup, setPopup] = useState(false);
  const [showPopupUpdate, setPopupUpdate] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [leaveList, setLeaveList]=useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  
  const [startDay, setStartDay] = useState("sunday");
  const [showWeekNum, setShowWeekNum] = useState("0");
  const [currentWeekText, setCurrentWeekText] = useState("");

  const [holidays, setHolidays] = useState([]);

  const [delivery, setDelivery] = useState([]);

  const togglePopup = () => {
    setPopup(!showPopup);
  };

  const togglePopupUpdate = () => {
    setPopupUpdate(!showPopupUpdate);
  };
    const offset = (page - 1) * limit;


    const changeDayHandle = (btnType) => {
      if (btnType === "prev") {
        setSelectedDate(subDays(selectedDate, 1));
        setSelectedDateText(format(subDays(selectedDate, 1), "yyyy-MM-dd") );
        
      }
      if (btnType === "next") {
        setSelectedDate(addDays(selectedDate, 1));
        setSelectedDateText(format(addDays(selectedDate, 1), "yyyy-MM-dd") );
        /*let leave_temp=[];
        for (let j = 0; j < leave.length; j++) {
          if (CheckList.includes(2)) {
            let d1 = leave[j].start_date.split("-");
            let c = format(addDays(selectedDate, 1), "yyyy-MM-dd").split("-");
            let d2 = leave[j].end_date.split("-");
            
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
  
            if (check >= from && check <= to) {
              
              leave_temp.push(leave[j]);
              
            }
          }
        }
        setLeaveList(leave_temp);*/
      }
    };
  

    const changeWeekHandle = (btnType) => {
      //console.log("current week", currentWeek);
      if (btnType === "prev") {
        //console.log(subWeeks(currentMonth, 1));
        setCurrentMonth(subWeeks(currentMonth, 1));
        setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
        
        let dateFormat = "yyyy-MM-dd";
        let start = format(startOfWeek(subWeeks(currentMonth, 1)), dateFormat);
        let end = format(endOfWeek(subWeeks(currentMonth, 1)), dateFormat);

        setCurrentWeekText(start+" ~ "+end);
      }
      if (btnType === "next") {
        //console.log(addWeeks(currentMonth, 1));
        setCurrentMonth(addWeeks(currentMonth, 1));
        setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
        setCurrentWeekText();
        let dateFormat = "yyyy-MM-dd";
        let start = format(startOfWeek(addWeeks(currentMonth, 1)), dateFormat);
        let end = format(endOfWeek(addWeeks(currentMonth, 1)), dateFormat);

        setCurrentWeekText(start+" ~ "+end);
      }
    };

  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const onChangeStart= (e) => {
    e.preventDefault();
    setStart(e.target.value);
    
  };
  const onChangeEnd= (e) => {
    e.preventDefault();
    setEnd(e.target.value);
    
  };
  

  const onChangeDay= (e) => {
    e.preventDefault();
    setSelectedDateText(e.target.value);
    
  };
  const onSearch = (e) => {
    setIsSearch(true);
    e.preventDefault();

    if (options === "all") {
      const filtered = schedules.filter((itemList) => {
        return itemList.subject.toUpperCase().includes(search.toUpperCase());
      });

      setFilterData(filtered);
    }else{
      const noFiltered = schedules.filter((itemList) => {
        return itemList.calendar_no == options;
      });
      const filtered = noFiltered.filter((itemList) => {
        return itemList.subject.toUpperCase().includes(search.toUpperCase());
      });

      setFilterData(filtered);
    }
      
  };

  useEffect(() => {


    const data = {
      year : year,
      month : month
    }
    axios.post('https://gw.thegmmedical.com:5003/api/get_delivery_schedule', data)
    .then(function (response){
   
      console.log("get delivery schedule,,,,,,", response.data);
      setSchedules(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    var user_calendar=[], group_calendar=[];
    const BASE_CALENDAR_URL = "https://www.googleapis.com/calendar/v3/calendars";
const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY =
  "holiday@group.v.calendar.google.com";
const API_KEY = "AIzaSyAblXK6hywzoPW3Vp_4ASRDogoyEufpITA";
const CALENDAR_REGION = "ko.south_korea";
const currentYear = new Date().getFullYear();
const timeMin = new Date(`${currentYear}-01-01`).toISOString();
const timeMax = new Date(`${currentYear}-12-31`).toISOString();

//https://calendar.google.com/calendar/embed?src=ko.south_korea.official%23holiday%40group.v.calendar.google.com&ctz=Asia%2FSeoul

fetch(
  `${BASE_CALENDAR_URL}/${CALENDAR_REGION}.official%23${BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY}/events?key=${API_KEY}&timeMin=${timeMin}&timeMax=${timeMax}`
)
  .then((response) => response.json())
  .then((response) => {
    const formattedResponse = response.items
      .map(({ summary, start, end }) => ({ summary, start, end }))
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    setHolidays(formattedResponse);
  });

  

  
          

        

    
    

    let dateFormat = "yyyy-MM-d";
    let start = format(startOfWeek(currentDate), dateFormat);
    let end = format(endOfWeek(currentDate), dateFormat);
    setCurrentWeekText(start+" ~ "+end);


    


  }, [location]);

  const header = () => {
    const dateFormat = "yyyy년 M월";
    return (
      <div className="header row flex-middle">
        {showPopup ? (
  <DeliveryScheduleWrite date = {selectedDate} delivery = {delivery} closePopup={(value) => {
    console.log("안녕하세요", value);
    
    if (value.altKey !==false){
      let ns = new Object; ns.delivery_date = value.delivery_date; ns.product = JSON.stringify(value.arr);
      ns.subject= value.subject; ns.note=value.note; ns.place = value.place; ns.color = value.color;
      ns.s_id = value.sid;
      let oldArray  = [...schedules]
      oldArray.push(ns)
      setSchedules(oldArray);   
    }
   
    togglePopup();}} />
) : null}

{showPopupUpdate ? (
  <DeliveryScheduleUpdate date = {selectedDate} delivery = {delivery} closePopup={(value) => {
    console.log("update.", value);
    if (value.altKey !==false){
    let temp = schedules;
    var index1 = temp.findIndex(obj => obj.s_id === value.s_id);
    console.log("hi,", index1, temp[index1]);
    temp[index1].delivery_date = value.delivery_date; temp[index1].product = JSON.stringify(value.arr);
    temp[index1].subject= value.subject; temp[index1].note=value.note; temp[index1].place = value.place; 
    temp[index1].color = value.color;
  
    setSchedules([...temp]);
    }
    togglePopupUpdate();
  
  }} />
) : null}
        <div className="column col-start">
          <div className="icon" onClick={prevMonth}>
            <MdArrowBackIos />
          </div>
        </div>
        <div className="column col-center" style={{ textAlign: "center" }}>
          <span>{format(currentDate, dateFormat)}</span>
        </div>
        <div className="column col-end" style={{ textAlign: "right" }}>
          <div className="icon" onClick={nextMonth}>
            <MdArrowForwardIos />
          </div>
        </div>
      </div>
    );
  };
  const days = () => {
    const dateFormat = "ddd";
    var values;
    if (startDay === "sunday"){
      values = ["일", "월", "화", "수", "목", "금", "토"];
    }else{
      values = ["월", "화", "수", "목", "금", "토", "일"];
    }
    
    const days = [];
    let startDate = startOfWeek(currentDate);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="column col-center" key={i}>
          {values[i]}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  function compare( a, b ) {
    if ( a.start_hour < b.start_hour ){
      return -1;
    }
    if ( a.start_hour > b.start_hour ){
      return 1;
    }
    if (a.start_min < b.start_min) { 
      return -1;
  } else if (a.start_min > b.start_min) {
      return 1
  } else { // nothing to split them
      return 0;
  }
   
  }

  function delSchedule(no) {

    if (no !==null){
      var result = window.confirm("일정을 정말 삭제하시겠습니까?");
      if(result){
        const data={
          s_id : no
        }
    
        axios.post(
          "https://gw.thegmmedical.com:5003/api/delete_delivery_schedule",
          data
        ).then(( res ) => {
          console.log("res,",no, res.data);
          alert("삭제되었습니다.");

          if (res.data.msg === "success"){
            let temp = schedules;
            let index1 = temp.findIndex(obj => obj.s_id == no);

            console.log("index.......", index1, temp[index1]);
            temp.splice(index1,1);
            setSchedules([...temp]);
          }
          
          
          //window.location.reload(true);
        });
      }
    }
    
  }

  const cells = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";

    var isDelivery = false;
    var subject="";
    let color="";
    let monthstart = getMonth(currentDate) + 1;
    if (monthstart < 10) {
      monthstart = "0" + monthstart;
    }
    let currentyear = getYear(currentDate);
    const rows = [];
    let days = [];
    let day = startDate;
    if (startDay !== "sunday"){

      day = addDays(day, 1);
    }
    //console.log("checklist checkecheck", CheckList);
    let formattedDate = "";

    var type;
    while (day <= endDate) {
      var weekN;
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        var checkDate = format(day, "yyyy-MM-dd");
        var todo = [];
        var sid="";
        for (let i = 0; i < schedules.length; i++) {
          


            
            var d1 = schedules[i].delivery_date.split("-");
            var c = checkDate.split("-");

            var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            var to = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
            var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

        
            if (check>=from && check <=to){
              isDelivery = true;
              subject = schedules[i].subject;
              color = schedules[i].color;
              sid = schedules[i].s_id;
              let products=[];
              if (schedules[i].product !=='undefined'){
                products = JSON.parse(schedules[i].product);

              }
              todo.push(
                  
                <div className="delivery_schedule" style={{padding:"0 5px", boxSizing:"border-box"}}
                onClick={()=>{setDelivery(schedules[i]); togglePopupUpdate();}}>
              
                <span style={{textAlign:"right"}}>{schedules[i].place}</span>
                  
                  {products.map((ele,idx)=>(
                    <span style={{display:"flex", justifyContent:"space-between"}}>
                      <div>{ele.name}</div>   
                
                      <div>{ele.quantity}</div>
                      </span>
                  ))}
                
                <span style={{color:"#da0000"}}>{schedules[i].note}</span>  


                </div>
              );
            }
         
        }


        for (let i = 0; i < holidays.length; i++) {
          //if (CheckList.includes(holidays[i].calendar_no)) {
            let d1 = holidays[i].start.date.split("-");
            let c = checkDate.split("-");
            let d2 = holidays[i].end.date.split("-");

            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
            to = subDays(to, 1);
            if (check >= from && check <= to) {
              type = (
                <div
                  style={{ color: "red" }}
                >
                  <BsFillSquareFill />
                </div>
              );

          
                todo.push(
                  <div className="schedule_list">
                    <div style={{ display: "flex" }}>
                      {type}
                      {holidays[i].summary}
                    </div>
              
                  </div>
                );
              
            } else {
            }
          //}
        }
        

        var leaves = [];
        //if (CheckList.includes(2)) {
          for (let i = 0; i < leave.length; i++) {
            let d1 = moment(leave[i].start_date).format("YYYY-MM-DD").split("-");
            let c = checkDate.split("-");
            let d2 = moment(leave[i].end_date).format("YYYY-MM-DD").split("-");



            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
            var weekend = isWeekend(check);
            if (weekend === false) {
              if (check >= from && check <= to) {
                let vtype;
                if (leave[i].vtype === "1") {
                  vtype = "연차";
                }
                if (leave[i].vtype === "0.5") {

                  if (leave[i].vrange == "2"){
                    vtype = "오후 반차";

                  }else{
                    vtype = "오전 반차";
                  }
                  
                }
                if (leave[i].vtype === "0.25") {
                  vtype = "반반차";
                }
                if (leave[i].vtype === "0") {
                  vtype = "공가";
                }
              
              } else {
              }
            }
          }
        //}

        const cloneDay = day;
        //alert(day, selectedDate);

        days.push(
          <div
            className={`column cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : (isDelivery===true)
                ? "delivery_date"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
           
          >


            <span className=""
             style={ isDelivery===true ? {width:"100%", display:"flex", padding:"5px", boxSizing:"border-box", background:color} : 
             {width:"100%", display:"flex", padding:"5px", boxSizing:"border-box"}}

            >
              <div style={{display:"flex", alignItems:"center"}}> {formattedDate} 
            {!isDelivery &&( 
            
            <CiCirclePlus data-value={day}
            onClick={(ev)=>{
              const element = ev.target;
              setSelectedDate(element.getAttribute("data-value"));
              togglePopup();
            }}
            style={{cursor:"pointer", color:"rgb(100, 124, 246)", marginLeft:"2px"}}/>
            
          )}</div> 
            <div  className={`${
              isDelivery
                ? "pointer"
                : ""
               
            }`}style={{width:"100%", textAlign:"center", position:"relative",
              display:"flex", alignItems:"center", justifyContent:"space-between", color:"black"
            }}
            ><span style={{width:"100%"}}>{subject}</span>  {isDelivery &&( 
              <TiDeleteOutline style={{
                fontSize:"20px"}}
                data-value={sid}
                onClick={(ev)=>{
                  const element = ev.target;
                  console.log(element);
                  delSchedule(element.getAttribute("data-value"))}}>
 x
              </TiDeleteOutline>
           )}</div></span>
            <span className="bg">{formattedDate} </span>
            <div>{todo}</div>
          
          </div>
        );
        day = addDays(day, 1);
        isDelivery = false;
        subject = "";
       weekN = getWeek(day);
      }
      if (showWeekNum===1){
        rows.push(
        <div className="row" style={{padding:"5px 10px", fontSize:"12px", color:"#b6b6b6"}}>
          <span>{weekN}주</span>
        </div>
      );
      }
      
   
      rows.push(
        <div className="row" key={day}>
          {" "}
          {days}{" "}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };


  const searchPeriod = () => {
    let start_date = start.split("-");
    let end_date = end.split("-");
    let s =  new Date(start_date[0], parseInt(start_date[1]) - 1, start_date[2]);
    setStartDate(s);
    let e =  new Date(end_date[0], parseInt(end_date[1]) - 1, end_date[2]);
    setEndDate(e);
  }


  const pushSchedule = (day, days, schedule_info) => {
    console.log(day);
    let checkDate = format(day, "yyyy-MM-dd");
    
    
    

    days.push(
      <tr
        className={`column cell ${
          !isSameMonth(day, monthStart)
            ? "disabled"
            : isSameDay(day, selectedDate)
            ? "selected" 
            : ""
        }`}
       
      >
        <td className="number">{format(day, "yyyy-MM-dd")}</td>
        <td className="number">{schedule_info.start_hour !==null &&
          ( schedule_info.start_hour )} 
        {schedule_info.start_hour !==null &&(<span>:</span>)}
        {schedule_info.start_hour !==null && ( schedule_info.start_min )
        }
        {schedule_info.start_hour ===null && ( <span>00:00~24:00</span> )
        }
        </td>
        <td className="number">{schedule_info.name}</td>
        <td className="number" style={{display:"flex", alignItems:"center", paddingLeft:"10px"}}><div
                  className={schedule_info.type}
                  style={{ color: `${schedule_info.color}`, display:"flex", alignItems:"center" }}
                >
                  <BsFillSquareFill style={{marginTop:"3px"}}/>
                </div>
                  <span style={{marginLeft:"10px"}}><Link to="/schedule_v" state={{schedule: schedule_info}}>{schedule_info.subject}</Link></span></td>
      </tr>
    );
    return days;
  }
  const listSchedule = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    //const startDate = startOfWeek(monthStart);
    //const endDate = endOfWeek(monthEnd);
    const dateFormat = "yyyy-MM-dd";

    let monthstart = getMonth(currentDate) + 1;
    if (monthstart < 10) {
      monthstart = "0" + monthstart;
    }
    let currentyear = getYear(currentDate);
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    /*let start_date = start.split("-");
    let end_date = end.split("-");
    let s =  new Date(start_date[0], parseInt(start_date[1]) - 1, start_date[2]);
    let e =  new Date(end_date[0], parseInt(end_date[1]) - 1, end_date[2]);*/
    
    var type;
    while (day <= endDate) {
      
        formattedDate = format(day, dateFormat);
        var checkDate = formattedDate;
        var todo = [];
        let holiday =[];
        for (let i = 0; i < holidays.length; i++) {
          //if (CheckList.includes(holidays[i].calendar_no)) {
            let d1 = holidays[i].start.date.split("-");
            let c = checkDate.split("-");
            let d2 = holidays[i].end.date.split("-");
    
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
            to = subDays(to, 1);
            if (check >= from && check <= to) {
              
    
          
                holiday.push(
                    <div>
                      {holidays[i].summary}
                    </div>
              
                );
              
            } else {
            }
          //}
          }
          if (holiday.length>0){
             days.push(
              <tr
              className={`column cell ${
                !isSameMonth(day, monthStart)
                  ? "disabled"
                  : isSameDay(day, selectedDate)
                  ? "selected" 
                  : ""
              }`}
            
            >
              <td className="number">{format(day, "yyyy-MM-dd")}</td>
              <td className="number">
              </td>
              <td className="number">공휴일</td>
              <td className="number" style={{color:"red"}}>{holiday}</td>
            </tr>
            )
    
          }
         
    
        for (let i = 0; i < schedules.length; i++) {
            var d1 = schedules[i].start_date.split("-");
            var c = checkDate.split("-");
            var d2 = schedules[i].end_date.split("-");

            var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

            let schedule_info=[];
            console.log("dfdsfsdf[i]...........",check, from, to);
            if (check >= from && check <= to) {
            console.log("schedules[i]...........",schedules[i]);

              if (schedules[i].is_repeat === 1) {
                if (schedules[i].rep_cycle === "DAILY") {
                  let cycle_num = schedules[i].rep_cycleN;
                  let times = schedules[i].times;
                  let diff_week = differenceInCalendarDays(day, from);

                  //if (())
                  if (schedules[i].rep_end === "times"){
                    let total = times * cycle_num;
                    let maxdate = addDays(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if ((diff_week%cycle_num)===0){

                        days = pushSchedule(day, days, schedules[i]);
                        


                      }
                    }
                  }else{
                    if ((diff_week%cycle_num)===0){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                  }
                }else if (schedules[i].rep_cycle === "weekly_weekday"){
                  let cycle_num = schedules[i].rep_cycleN; // 주기
                  let diff_week = differenceInCalendarWeeks(day, from);

                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){

                  if (!isWeekend(day) && ((diff_week%cycle_num)===0)){
                    days = pushSchedule(day, days, schedules[i]);
                  }
                  }
                  }else{

                    if (!isWeekend(day) && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                  }
                }else if (schedules[i].rep_cycle === "WEEKLY"){

                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_weekday = schedules[i].rep_week_day;
                  let diff_week = differenceInCalendarWeeks(day, from);
                  let letterDays = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"];

                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){
                  
                    if (letterDays[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                    }
                  }else{
                    if (letterDays[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                      //console.log("매주 반복 나오셈", pushSchedule(day, days, schedules[i]));
                      days = pushSchedule(day, days, schedules[i]);

                    }
                  }

                }else if (schedules[i].rep_cycle === "MONTHLY"){
                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_type = schedules[i].rep_type;
                  let rep_month_type = schedules[i].rep_month_type;
                  let diff_week = differenceInCalendarMonths(day, from);
                  
                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addMonths(from, total);
                    if (check.getTime() < maxdate.getTime()){
                    
                      if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                        days = pushSchedule(day, days, schedules[i]);
                      }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                        days = pushSchedule(day, days, schedules[i]);
                      }
                    }
                  }else{
                    if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                  }
                }else if (schedules[i].rep_cycle === "YEARLY"){
                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_type = schedules[i].rep_type;
                  let rep_month_type = schedules[i].rep_month_type;
                  let diff_week = differenceInCalendarYears(day, from);
                  
                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addYears(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if (rep_month_type === "date" && c[2]== rep_type.split("-")[1] && c[1] ==  rep_type.split("-")[0] && ((diff_week%cycle_num)===0)){
                        days = pushSchedule(day, days, schedules[i]);
                      }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                        let nth = rep_type.split("-");
                        var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                        d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                        date = d.getDate(),
                        getday  = d.getDay(),
                        n    = Math.ceil(date / 7);
                        if (nth[0]==n && nth[1] == days_en[getday]){
                          days = pushSchedule(day, days, schedules[i]);
                        }
                      }
                    }
                  }else{

                    if (rep_month_type === "date" && parseInt(c[2])=== parseInt(rep_type.split("-")[1]) && parseInt(c[1]) ===  parseInt(rep_type.split("-")[0]) && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                      let nth = rep_type.split("-");
                      var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                      d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                      date = d.getDate(),
                      getday  = d.getDay(),
                      n    = Math.ceil(date / 7);
                      if (nth[0]==n && nth[1] == days_en[getday]){
                        days = pushSchedule(day, days, schedules[i]);
                      }
                    }
                  }
                }
              }else{
                
                days = pushSchedule(day, days, schedules[i]);
              }
              type = (
                <div
                  className={schedule_info.type}
                  style={{ color: `${schedule_info.color}` }}
                >
                  <BsFillSquareFill />
                </div>
              );

              const cloneDay = day;
              
             
             
              }
            
          
        }

        
        
        day = addDays(day, 1);
      
      
     
    }
    return days;


  }
  const daySchedule = () => {
    const monthStart = startOfMonth(currentDate);
    const values = ["일", "월", "화", "수", "목", "금", "토"];
    let day = [];
    let startDate = startOfWeek(currentDate);
    
    for (let i = 8; i < 24; i++) {
      let temp=[];
      
      for (let j = 0; j < schedules.length; j++) {
          //console.log("야");
          let d1 = schedules[j].start_date.split("-");
          let c = selectedDateText.split("-");
          let d2 = schedules[j].end_date.split("-");

          let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
          let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
          let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

          if (check >= from && check <= to) {
            let compare_hour =i;
            if (i < 10){
              compare_hour = "0"+i;
            }
            if (compare_hour === schedules[j].start_hour){
              
              if (schedules[j].is_repeat === 1) {
                if (schedules[j].rep_cycle === "DAILY") {
                  let cycle_num = schedules[j].rep_cycleN;
                  let times = schedules[j].times;
                  let diff_week = differenceInCalendarDays(check, from);
                  //if (())
                  if (schedules[j].rep_end === "times"){
                    let total = times * cycle_num;
                    let maxdate = addDays(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if ((diff_week%cycle_num)===0){
    
                        temp.push(schedules[j]);
                        
    
    
                      }
                    }
                  }else{
                    if ((diff_week%cycle_num)===0){
                      temp.push(schedules[j]);
                    }
                  }
                }else if (schedules[j].rep_cycle === "weekly_weekday"){
                  let cycle_num = schedules[j].rep_cycleN; // 주기
                  let diff_week = differenceInCalendarWeeks(check, from);
    
                  if (schedules[j].rep_end === "times"){
                    let times = schedules[j].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){
    
                  if (!isWeekend(check) && ((diff_week%cycle_num)===0)){
                    temp.push(schedules[j]);
                  }
                  }
                  }else{
    
                    if (!isWeekend(check) && ((diff_week%cycle_num)===0)){
                      temp.push(schedules[j]);
                    }
                  }
                }else if (schedules[j].rep_cycle === "WEEKLY"){
    
                  let cycle_num = schedules[j].rep_cycleN;
                  let rep_weekday = schedules[j].rep_week_day;
                  let diff_week = differenceInCalendarWeeks(check, from);
                  let letterDays = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"];
    
                  if (schedules[j].rep_end === "times"){
                    let times = schedules[j].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){
                  
                    if (letterDays[getDay(check)]===rep_weekday && ((diff_week%cycle_num)===0)){
                      temp.push(schedules[j]);
                    }
                    }
                  }else{
                    if (letterDays[getDay(check)]===rep_weekday && ((diff_week%cycle_num)===0)){
                      //console.log("매주 반복 나오셈", pushSchedule(day, days, schedules[i]));
                      temp.push(schedules[j]);
    
                    }
                  }
    
                }else if (schedules[j].rep_cycle === "MONTHLY"){
                  let cycle_num = schedules[j].rep_cycleN;
                  let rep_type = schedules[j].rep_type;
                  let rep_month_type = schedules[j].rep_month_type;
                  let diff_week = differenceInCalendarMonths(check, from);
                  
                  if (schedules[j].rep_end === "times"){
                    let times = schedules[j].times;
                    let total = times * cycle_num;
                    let maxdate = addMonths(from, total);
                    if (check.getTime() < maxdate.getTime()){
                    
                      if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                        temp.push(schedules[j]);
                      }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                        temp.push(schedules[j]);
                      }
                    }
                  }else{
                    if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                      temp.push(schedules[j]);
                    }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                      temp.push(schedules[j]);
                    }
                  }
                }else if (schedules[j].rep_cycle === "YEARLY"){
                  let cycle_num = schedules[j].rep_cycleN;
                  let rep_type = schedules[j].rep_type;
                  let rep_month_type = schedules[j].rep_month_type;
                  let diff_week = differenceInCalendarYears(check, from);
                  
                  if (schedules[j].rep_end === "times"){
                    let times = schedules[j].times;
                    let total = times * cycle_num;
                    let maxdate = addYears(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if (rep_month_type === "date" && parseInt(c[2])== rep_type.split("-")[1] && parseInt(c[1]) ==  rep_type.split("-")[0] && ((diff_week%cycle_num)===0)){
                        temp.push(schedules[j]);
                      }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                        let nth = rep_type.split("-");
                        var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                        d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                        date = d.getDate(),
                        getday  = d.getDay(),
                        n    = Math.ceil(date / 7);
                        if (nth[0]==n && nth[1] == days_en[getday]){
                          temp.push(schedules[j]);
                        }
                      }
                    }
                  }else{
    
                    if (rep_month_type === "date" && parseInt(c[2])===parseInt(rep_type.split("-")[1]) && parseInt(c[1]) === parseInt(rep_type.split("-")[0]) && ((diff_week%cycle_num)===0)){
                      temp.push(schedules[j]);
                    }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                      let nth = rep_type.split("-");
                      var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                      d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                      date = d.getDate(),
                      getday  = d.getDay(),
                      n    = Math.ceil(date / 7);
                      if (nth[0]==n && nth[1] == days_en[getday]){
                        temp.push(schedules[j]);
                      }
                    }
                  }
                }
              }else{
                temp.push(schedules[j]);
              }
            }else{

            }
          }
        
      }

      
      day.push(

        
        <tr className={`column cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}>
          <td class="tg-0lax">{i}:00</td>
          <td class="tg-0lax">
          
          {temp.map((element, index)=>
          
          
          <Link  to="/schedule_v" state={{schedule: element}}><li><div
                  className={element.vtype}
                  style={{ color: `${element.color}`, display:"flex", alignItems:"center" }}
                >
                  <BsFillSquareFill style={{marginRight:"5px"}}/>
                </div>{element.subject}
              
                
                </li></Link>)} 
            
          
               
                
          </td>
          
        </tr>
      )
    }
    return day;
  };


  const nextMonth = () => {
    let monthstart = getMonth(addMonths(currentDate, 1)) + 1;
    if (monthstart < 10) {
      monthstart = "0" + monthstart;
    }
    let currentyear = getYear(addMonths(currentDate, 1));
    let value = currentyear + "-" + monthstart;
    axios
      .get("https://gw.thegmmedical.com:5003/api/getleaves/" + value)
      .then((response) => {

        console.log("getleaves....", response.data);
        
        let leave_temp=[];
        for (let j = 0; j < response.data.length; j++) {
          console.log("dddd1111......", moment(response.data[j].start_date).format("YYYY-MM-DD"));
            let d1 = moment(response.data[j].start_date).format("YYYY-MM-DD").split("-");
            let c = format(addDays(selectedDate, 1), "yyyy-MM-dd").split("-");
            let d2 = moment(response.data[j].end_date).format("YYYY-MM-DD").split("-");
            
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
  
           
            if (check >= from && check <= to) {
              
              leave_temp.push(response.data[j]);
              
            
          }
        }
        setLeaveList(leave_temp);
        //setSchedule(response.data);
        setCurrentDate(addMonths(currentDate, 1));

      });
  };
  const prevMonth = () => {

    let monthstart = getMonth(subMonths(currentDate, 1)) + 1;
    if (monthstart < 10) {
      monthstart = "0" + monthstart;
    }
    let currentyear = getYear(subMonths(currentDate, 1));
    let value = currentyear + "-" + monthstart;
    axios
      .get("https://gw.thegmmedical.com:5003/api/getleaves/" + value)
      .then((response) => {

        console.log("getleaves....", response.data);
        
        let leave_temp=[];
        for (let j = 0; j < response.data.length; j++) {
          console.log("dddd1111......", moment(response.data[j].start_date).format("YYYY-MM-DD"));
            let d1 = moment(response.data[j].start_date).format("YYYY-MM-DD").split("-");
            let c = format(addDays(selectedDate, 1), "yyyy-MM-dd").split("-");
            let d2 = moment(response.data[j].end_date).format("YYYY-MM-DD").split("-");
            
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
  
           
            if (check >= from && check <= to) {
              
              leave_temp.push(response.data[j]);
              
            
          }
        }
        setLeaveList(leave_temp);
        setLeaves(response.data);
        //setSchedule(response.data);
        setCurrentDate(subMonths(currentDate, 1));

      });
  };
  const onDateClick = (day) => {
    console.log("day,", day);
    setSelectedDate(day);
  };

  const selectHandle = (e) => {
    console.log("changed", e.target.value);

    setOption(e.target.value);
  };

  const addBookmarkPage = () => {
    var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
    if(result){

      const data ={
        name: '',
        num : '',
        link : window.location.pathname,
        bookmark : "일정 관리"
      }
      
      console.log(window.location.pathname)
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
      .then(function (response){
        console.log(response);
        alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };

  return (
    <div className="wrapper">

<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
       </div>
      )}


      <div className="content-wrap">
        <div className="outer_wrapper" style={{ justifyContent: "flex-start" }}>
          <div className="side-menu">
          <BoardList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>
          </div>

<div className="schedule main_content">

<div className="btns" style={{display:"flex",flexDirection:"row",justifyContent:"start", alignItems:"center"}}>

            
<div className="btn" style={{display:"flex", alignItem:"center", height:"15px",padding:"5px", marginRight:"7px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>

<h3>납품 일정</h3>


</div>

            <div className="board_top">
           


              <div
                className="btns"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="left">
                <Link to="/dsr_write"><button style={{width:"100px"}}>일정 추가</button></Link>

                  {/*<button className="btn" onClick={()=>{setCalendar("daily");setPrevCalendar("daily");setIsSearch(false);}}>일간</button>
                  <button className="btn" onClick={()=>{setCalendar("weekly");setPrevCalendar("weekly");setIsSearch(false);}}>주간</button>
                  <button className="btn" onClick={()=>{setCalendar("monthly");setPrevCalendar("monthly");setIsSearch(false);}}>월간</button>
                  <button className="btn" onClick={()=>{setCalendar("list");setPrevCalendar("list");setIsSearch(false);}}>목록</button>*/}
                </div>

                {/*<div className="right" style={{display:"flex"}}>

                  <Link to="/dsr_write"><button style={{width:"100px"}}>일정 추가</button></Link>
                  <div className="bt_srch" style={{width:"100%", marginBottom:"0"}}>
                    {isSearch === true && (
                      <button onClick={()=>{setIsSearch(false)}}>이전으로 돌아가기</button>
                    )}
                    
                    
                  
                    <div className="bt_input">
                      <input
                        type="text"
                        id="search_content"
                        name="search_content"
                        value={search || ""}
                        placeholder="검색어를 입력하세요"
                        onChange={onChangeSearch}
                        style={{width:"180px"}}
                      />
                    </div>

                    <button
                      type="submit"
                      className="search_btn"
                      value="검색"
                      onClick={onSearch}
                    >
                      검색
                    </button>
                  </div>
                </div>*/}
              </div>
            </div>
            {isSearch ===false && calendar ==="monthly" && (
               <div className="calendar">
              <div>{header()}</div>
              <div>{days()}</div>
              <div>{cells()}</div>
            </div>
            )}
           
           










{isSearch ===true && (
            <table class="table table-hover bd-b">
              <thead class="thead-dark">
                <tr class="tx-center">
                  <th class="wd-80 xl-hidden">번호</th>
                  <th class="wd-80 xl-hidden">캘린더</th>
                  <th class="">제목</th>
                  <th class="wd-150 xl-hidden">작성자</th>
                  <th class="wd-120 xl-hidden">작성일</th>
                  <th class="wd-80 xl-hidden">시작일</th>
                  <th class="wd-80 xl-hidden">종료일</th>
                  
                </tr>
              </thead>
              <tbody>
                {isEmpty && (
                  <tr style={{ textAlign: "center" }}>
                    <td colSpan={5} style={{ padding: "50px 0" }}>
                      게시물이 없습니다.
                    </td>
                  </tr>
                )}
                {filterData.slice(offset, offset + limit).map(
                  ({
                    s_id, user_no, subject, content, create_date, start_date, end_date, name
                  }) =>
                      <tr key={s_id}>
                        <td>{s_id}</td>
                        <td>{name}</td>
                        <td>
                          
                            {subject}
                        </td>
                        <td>{user_no}</td>
                        <td>{create_date}</td>
                        <td>{start_date}</td>
                        <td>{end_date}</td>
                      </tr>
                    
                )}
              </tbody>
            </table>
            )}



          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
