
import { NavLink, Link } from "react-router-dom";
import FolderTree from 'react-folder-tree';
import React, { useState, useEffect, useRef } from "react";
import AddJob from "../components/AddJob";
import axios from "axios";
import "./css/adminDepartment.css";
import user from "../components/img/user.png";
import Popup from "../components/Popup";
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";

const AdminDepartments = () => {
    const [cnt, setCnt] = useState(0);
    const [data, setData] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [CheckList, setCheckList] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [options, setOptions] = useState([]);
    const [sidebar, setSidebar] = useState(false);
    const [name, setName] =useState("");
    const [members, setMembers] = useState([]);
    const [num, setNum] = useState(0);
    const [lev, setLev] = useState(0);
    const [selected, setSelected]= useState();

    const [jobName, setJobName]= useState();
    const [jobNo, setJobNo]= useState();
    const [showUser, setShowuser]= useState();
    const onTreeStateChange = (state, event) => console.log(state, event);
    const [selectedMember, setSelectedMember]= useState();
    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].d_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.d_name,
            parentNo : node.d_parent,
            num : node.d_no,
            level : node.d_level,
            children: lookingForNodeWithParent( nodes, node.d_no )
          });
        }
      }
      
      return arrayToReturn;
    }
    
    function showUnassigned(){
      axios.get('https://gw.thegmmedical.com:5003/api/getunassignedJob',{
          
        })
  .then(function (response){
    console.log("????????", response);
    /* tempArray = [];
    for (let i =0;i<response.data.length;i++){
      
      console.log(response.data[i].name);
      tempArray.push(response.data[i].name + response.data[i].email);
      
    }*/
    setOptions(response.data);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
    
    }


    useEffect(() => {
      console.log("datga is,,,," ,data);
      fetch("https://gw.thegmmedical.com:5003/api/getjob", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);  
  
        return setJobs(json);

        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});
      showUnassigned();
      
    }, []);
  
    const togglePopup = () => {
        setPopup(!showPopup);
        for (var i=0;i<jobs.length;i++){
          if (jobs[i].job_no === CheckList[0]){
            setSelected(jobs[i]);
            setJobNo(jobs[i].job_no);setJobName(jobs[i].job_name);
          }
        }
    };
    const toggleUser = () => {
      setShowuser(!showUser);
  };
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('animal');
        const all 
             = document.getElementsByName('animalall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...CheckList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(CheckList.filter((checkedId) => checkedId !== id));
        }
     }

 

  const deleteJob = () => {
    var result = window.confirm("선택된 직위를 삭제하시겠습니까?");
    if(result){
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="userall" && checkboxes[i].name==="job" ){
      array.push(checkboxes[i].value)
      }
    }
   
    
    const data ={
      
      num : array
    }
    axios.post('https://gw.thegmmedical.com:5003/api/delete_job', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  };

  const unassign = () => {
 
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="userall" && checkboxes[i].name==="user"){
        array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="userall"){
      array.shift();
    }
    const data ={
      nos : array,
      jobName : jobName,
      jobNo : jobNo
    }
    
    var result = window.confirm("선택된 사용자를 [" + jobName+"] 직위에서 해제합니다.");
    if(result){
      axios.post('https://gw.thegmmedical.com:5003/api/unassign_job', data)
    .then(function (response){
      console.log(response);
      showUnassigned();

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }
    
  };
  const assign = () => {
 
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall" && checkboxes[i].name==="unassigned"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }
    const data ={
      nos : array,
      jobName : jobName,
      jobNo : jobNo
    }
    
    var result = window.confirm("선택된 사용자를 [" + jobName+"] 직위에 지정합니다.");
    if(result){
      axios.post('https://gw.thegmmedical.com:5003/api/assignJob', data)
    .then(function (response){
      console.log(response);
      showUnassigned();

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }
    
  };
  const showMembers = (e, job) => {
    setJobName(job.job_name);
    setJobNo(job.job_no);
    console.log(e.target.id);
    axios.post('https://gw.thegmmedical.com:5003/api/membersbyJob',{
          data: {
            // 서버에서 req.body.{} 로 확인할 수 있다.
            job_no: job.job_no
          },
        })
  .then(function (response){
    console.log("this is", response.data);
    /*var tempArray = [];
    for (let i =0;i<response.data.length;i++){
      
      console.log(response.data[i].name);
      tempArray.push(response.data[i].name + response.data[i].email);
      
    }*/
    setMembers(response.data);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });

  }
  function selectAll()  {
    const checkboxes 
         = document.getElementsByName('user');
    const all 
         = document.getElementsByName('alluser');
    //console.log(all[0].checked)
    checkboxes.forEach((checkbox) => {
      checkbox.checked = all[0].checked;
    })
  }

  function selectAssign()  {
    const checkboxes 
         = document.getElementsByName('unassigned');
    const all 
         = document.getElementsByName('all');
    //console.log(all[0].checked)
    checkboxes.forEach((checkbox) => {
      checkbox.checked = all[0].checked;
    })
  }
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents three_cols" style={{maxWidth:"1600px"}}>
        <div className="header_txt">
        <h3>직위 관리</h3>
        </div>
        
        
        {/*<div className="contents_top">

            <table>
                <tr>
                    <td> 현재 사원 수 </td>
                    <td> {cnt}명 </td>
                    <td> 정상({cnt}명 / 메일 휴면 0명), 중지된 계정 0개 </td>
                </tr>
            </table>
  </div>*/}
      <div className="admin_wrapper">
        
        <div className="main_contents_wrapper departments">
            <div className="btns">
                <button className="btn" onClick={() => togglePopup()}>직위 생성</button> 

                {CheckList.length>1 ? (<button className="btn" disabled onClick={() => togglePopup()}>직위 변경</button> 
                ): (<button className="btn" onClick={() => togglePopup()}>직위 변경</button> 
                )}
                <button className="btn" onClick={() => deleteJob()}>직위 삭제</button>
            </div>

            {showUser ? (
  <Popup member = {selectedMember} closePopup={() => {
    toggleUser();

    var aTags = document.getElementsByTagName("li");
    console.log("...job", jobName);
var searchText = jobName;
var found;

for (var i = 0; i < aTags.length; i++) {
  console.log(aTags[i].id);
  if (aTags[i].id === searchText) {
    
    found = aTags[i];
    break;
  }
}
console.log(found);
found.click();

  }}/>
) : null}      
{showPopup ? (
  <AddJob job = {selected} onChange={(value) => setData(value.data)} closePopup={() => togglePopup()} />
) : null}


    <div className="">
    <ul>

            
{ jobs.map((element, index) => 
<li key={index} className={element.job_no} id = {element.job_name} onClick={(e)=>showMembers(e, element)}>



<input type='checkbox'
name='job' 
value={element.job_no}
onChange={(e) => {onChangeEach(e, element.job_no);}} checked={CheckList.includes(element.job_no)}/>             



<div className="list_wrap" style={{display:"flex", flexDirection:"column"}}>
<span>{element.job_name}</span>
</div>
 </li>) }
</ul>
</div>


        </div>



        <div className="main_contents_wrapper members">
        <div className="btns">
        <input type='checkbox'
       name='alluser' 
       value='userall'
       onClick={selectAll}/>  
                <button className="btn" onClick={() => unassign()}>사용자 삭제</button> 
                {selected && (<span style={{marginLeft:"20px", color:"#a6a6a6"}}>{selected.name}</span>)}
            </div>
   
   
              { members.length!==0 
              ? <ul>{members.map((element, index) => <li key={index}>
 


<input type='checkbox'
        name='user' 
        value={element.user_no}
        onChange={(e) => onChangeEach(e, element.user_no)} checked={CheckList.includes(element.user_no)}/>             
<div className="list_wrap" onClick={() => {setSelectedMember(element);toggleUser();}}>

{element.profile_link !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={element.profile_link} alt="profile_pic"/>
</div>
: <div className="img-wrap img-upload"><img for="photo-upload" src={user} alt="profile_pic"/>
</div>
}

<div className="info" style={{display:"flex", flexDirection:"column"}}>
   <span>{element.name}</span>
   <span>{element.email}</span>
</div>
              </div> </li>)}</ul> :  <ul><li>멤버가 없습니다.</li></ul>}
            
                </div>


        <div id="cmbSelected" className="main_contents_wrapper members">
        <div className="btns">
        <input type='checkbox'
       name='all' 
       value='selectall'
       onClick={selectAssign}/>  
                <button className="btn" onClick={() => assign()}>직위 지정</button> 
            </div>
            <ul>

            
              { options.map((element, index) => <li key={index} className="list_wrap">
 


<input type='checkbox'
        name='unassigned' 
        value={element.user_no}
        onChange={(e) => onChangeEach(e, element.user_no)} checked={CheckList.includes(element.user_no)}/>             

{element.profile_link !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={element.profile_link} alt="profile_pic"/>
</div>
: <div className="img-wrap img-upload"><img for="photo-upload" src={user} alt="profile_pic"/>
</div>
}

<div className="info" style={{display:"flex", flexDirection:"column"}}>
   <span>{element.name}</span>
   <span>{element.email}</span>
</div>
               </li>) }
            </ul>
                </div>
                </div>
     </div>
    </div>

  );
};

export default AdminDepartments;
