import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  hospital_name: "",
  address: "",
  location:"",
  install_date: "",
  installer: "",
  memo:""
};


function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {product_name, model, manufacturer, create_date, quantity, mfr_num, memo}= rowsData;

    return (
      <tr key={index}>
        <td>{index+1}</td>
       
        <td>
          <input
            type="text"
            value={product_name}
            onChange={(event) => onValUpdate(index, event)}
            name="product_name"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={model}
            onChange={(event) => onValUpdate(index, event)}
            name="model"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={manufacturer}
            onChange={(event) => onValUpdate(index, event)}
            name="manufacturer"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={create_date}
            onChange={(event) => onValUpdate(index, event)}
            name="create_date"
            className="form-control"
          />
        </td>

        <td>
          <input
            type="text"
            value={quantity}
            onChange={(event) => onValUpdate(index, event)}
            name="quantity"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={mfr_num}
            onChange={(event) => onValUpdate(index, event)}
            name="mfr_num"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={memo}
            onChange={(event) => onValUpdate(index, event)}
            name="memo"
            className="form-control"
          />
        </td>
        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

const Confirm = ({doc_no, onChange, line}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [list, setList] = useState([]);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);
  useEffect(()=>{
    //This function will run every time the value of values state changes.
    onChange(values); //Will give you the updated value of values state.
    //Do some other stuff
  }, [values])
  useEffect(() => {
    console.log(doc_no);
    if (doc_no!==undefined){
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/confirm/'+doc_no)
    .then(function (response){

      if (response.data[0]!==undefined){
        let order_no = response.data[0].no;
        axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/confirm/'+order_no)
        .then(function (response){
          console.log("orders...........", response.data);
          let temp = response.data;
          if (response.data.length<10){
            let len = response.data.length;
            while (10-len>0){
            temp.push({product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''});
            len++;
            }
          }
          initRow(temp);
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
        delete response.data[0].doc_no;
        delete response.data[0].no;
        onChange(response.data[0]);
        setValues(response.data[0]);
      }

      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);

  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };

  const toggleShow = () => {
    setShow(!show);
};
  const onTypeChange = e => {
    setType(e.target.value)
  }
  const endDateChange = e => {
    setEndDate(e.target.value)
  }
  const { state } = useLocation();
  useEffect(() => {
  
}, []);



function popupwindow(url, title, w, h) {
  var left = (window.screen.width/2)-(w/2);
  var top = (window.screen.height/2)-(h/2);
  return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
} 



 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {
 
    /*values.arr = rows;

    console.log("values........", values);
    axios.post('https://gw.thegmmedical.com:5003/api/orderinsert', values)
      .then(function (response){
        console.log(response);
        //alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
   */



  };

  const handleDraftButton = () => {
 
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    formData.append(`is_notice`, type);
    formData.append(`name`, "변수연");
    formData.append(`bg_no`, state.board_no);
    formData.append(`end_date`, endDate);
    formData.append(`category`, option);
    if (draftNo !==undefined){
      formData.append(`draft_no`, draftNo);
    }
    const fetch = () => {
      axios
        .post("https://gw.thegmmedical.com:5003/api/draftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response){
          setDraftNo(response.data);
          //window.location.href="https://gw.thegmmedical.com/board";
          //console.log("...." + data);
        });
    };
    fetch();



  };
  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const downloadEmployeeData = () => {
    fetch('https://gw.thegmmedical.com:5003/api/download')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = '이호MH호텔_셀렉 A컷.zip';
                    a.click();
                });
                //window.location.href = response.url;
        });
  }
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";


  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    values.arr = rows;
    onChange(values);

  };
 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let initial = [{
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    }];
    initRow(initial);
    
  }, []);


  const addRowTable = () => {
    const data = {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);

    values.arr = rows;
    onChange(values);

  };


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

            <div className="order_write" style={{padding:"0", margin:"20px auto"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>납품ㆍ설치 확인서</td>
                    <td className="approve">작업</td>
                    <td className="approve">검토</td>
                    <td className="approve">승인</td>
                  
                  </tr>
                  <tr>
                  <td>
                     {line[0]!==undefined && (<span>{line[0].name}<br/>{line[0].job_name}</span>)}
                    </td>
                    <td>
                    {line[1]!==undefined && (<span>{line[1].name}<br/>{line[1].job_name}</span>)}

                    </td>
                    <td>
                    {line[2]!==undefined && (<span>{line[2].name}<br/>{line[2].job_name}</span>)}
           
                    </td>
                  
                  </tr>
                  
                </table>
            <table style={{marginTop: '1px', borderCollapse: 'collapse'}} className="forms">{/* Header */} 
              <thead style={{height:"40px"}}>
                <tr>
             
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td style={{background: 'white', width:"100%", display:"flex", padding: '0px !important', border: 'currentColor', textAlign: 'left', color: 'black', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'top'}}>
                    <table className="" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '1px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병 원 명
                          </th>
                          <td style={{ border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="hospital_name" value={values.hospital_name} onChange={handleInputChange}></input>
                          </td>

                       
                        </tr>
                        <tr>
                          <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            주&nbsp;&nbsp;&nbsp;&nbsp;소
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="address" value={values.address} onChange={handleInputChange}></input>
                          </td>
                        
                          
                        </tr>
                      
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            설치장소
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="location" value={values.location} onChange={handleInputChange}></input>
                          </td>

                        

                         
                        </tr>
                        <tr>
                          <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            설치일자 
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}
                          colSpan={3}>
                          <input type="text" name="install_date" value={values.install_date} onChange={handleInputChange}></input>
                          </td>
                         
                        </tr>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            설 치 자 
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}
                          colSpan={3}>
                          <input type="text" name="installer" value={values.installer} onChange={handleInputChange}></input>
                          </td>
                         
                        </tr>
                      </tbody>
                    </table>

               
                  </td>
                  
                </tr>

                <tr>
                <div className="btns" style={{display:"flex", justifyContent:"flex-end", margin:"20px 0"}}>
     
        <button className="btn" onClick={addRowTable}>
          추가
        </button>
      </div>
        <table className="form_list confirm_list" style={{borderCollapse: 'collapse', width:"790px"}}>
                 
          <thead>
            <tr>
              <th>No</th>
              <th>장비명</th>
              <th>모델명</th>
              <th>제조사</th>
              <th>제조일자</th>
              <th>수량</th>
              <th>제조번호</th>
              <th>비고</th>
              <th>삭제</th>
            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />
           
          </tbody>
        </table>
                </tr>
              </tbody>


              
            </table>
            <table style={{width:"100%", borderCollapse:"collapse", border:"1px solid #000", marginTop:"20px"}}>
              <tr><th style={{textAlign:"center", padding:"5px", borderBottom:"1px solid #000"}}>특이사항</th></tr>
              <tr><td><textarea style={{padding:"0", border:"none", height:"50px"}} name="memo" value={values.memo} onChange={handleInputChange}></textarea></td></tr>
            </table>
          
          </span></div>
        
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div style={{display:"flex", flexDirection:"column", width:"46%"}}>
          <h4>상기와 같이 인수하여 물품 이상이 없음을 확인합니다.</h4>

          <div><p>소&nbsp;&nbsp;&nbsp;&nbsp;속:</p></div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <p>인수자:</p>
            <p>(인)</p>
          </div>
          </div>

          <div style={{display:"flex", flexDirection:"column", width:"46%"}}>
          <h4>상기 물품을 정히 검수 확인합니다.</h4>

          <div><p>소&nbsp;&nbsp;&nbsp;&nbsp;속:</p></div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <p>검수자:</p>
            <p>(인)</p>
          </div>
          </div>


        </div>
        
      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default Confirm;

