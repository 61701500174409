
import { NavLink, Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import BoardList from "../components/side/BoardList";
import axios from "axios";
import "./css/stats.css";

import { ResponsiveBar } from '@nivo/bar'
import {GiHamburgerMenu} from "react-icons/gi";
const initialValues={
  board:"AS현황",
  year:"2023"
}
const BoardStats = () => {
    const [fullData, setFullData] = useState([]);

    const [stats, setStats] = useState([]);
    const [statsModel, setStatsModels] = useState([]);

    const [search, setSearch] = useState(null);
    const [filterData, setfilterData] = useState([]);
    const [notice, setNotice] = useState([]);
    const [isEmpty, setEmpty] = useState(false);
    const [isPage, setIsPage] = useState(false);
    const [page, setPage] = useState(1);
    const [sum, setSum] = useState("");
    const [inCharge, setInCharge] = useState("");
    const [model, setModel] = useState("");
    const [purchase, setPurchase] = useState("");
    const [estimate, setEstimate] = useState("");
    const [sales, setSales] = useState("");

    const [values, setValues] = useState(initialValues);

    const [sidebar, setSidebar] = useState(false);

    //const [year, setYear] = useState(moment(new Date()).format("YYYY").slice(-2));
    //const [month, setMonth] = useState(moment(new Date()).format("MM"));
    const [month, setMonth] = useState("");
    const [year, setYear]= useState("");
 


    
    const onChangeSearch = (e) => {
      e.preventDefault();
      setSearch(e.target.value);
    };
  
    function selectAll()  {
        const checkboxes 
             = document.getElementsByName('list');
        const all 
             = document.getElementsByName('listall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }
    useEffect(() => {
      axios.get("https://gw.thegmmedical.com:5003/api/showstats").then((response) => {
        // setNotice(response.data);
        setStats(response.data);
        console.log(response.data);
        
      });
        
      axios.get("https://gw.thegmmedical.com:5003/api/showstats_model").then((response) => {
        setStatsModels(response.data);
        console.log(response.data);
      });
      axios.get("https://gw.thegmmedical.com:5003/api/show_sum").then((response) => {
        setSum(response.data[0].sum);
      });
      axios.get("https://gw.thegmmedical.com:5003/api/get_as_status/as_status").then((response) => {
        // setNotice(response.data);
        var regex = /[.,\s]/g;
        setFullData(response.data);
        console.log(" 2,740,650 ".replace(regex, ""));
        console.log("filtered..", response.data.filter(item=>item.estimate!==""));
        setfilterData(response.data);
        if (response.data.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);   
        }
      });
    }, []);


   

  function selectYear(e){
    let temp = fullData.filter(item=>item.register_date.substring(0,2)===e.currentTarget.value);
    setInCharge("");
    setfilterData(temp);
    var res = temp.reduce(function(obj, v) {
      // increment or set the property
      // `(obj[v.status] || 0)` returns the property value if defined
      // or 0 ( since `undefined` is a falsy value
      obj[v.incharge] = (obj[v.incharge] || 0) + 1;
      // return the updated object
      return obj;
      // set the initial value as an object
    }, {})
    var arr = [];
    setYear(e.currentTarget.value);
    Object.entries(res).map((ele)=>(
      arr.push({incharge: ele[0], cnt: ele[1]})
    ))
    setStats(arr);

  }

  function selectMonth(e){
    let temp;
    let m;
    if (e.currentTarget.value < 10){
      m = "0"+e.currentTarget.value;
    }else{
      m = e.currentTarget.value;
    }
    setInCharge("");
    console.log("23/02/21".substr(3,2));
    if (year !==""){
      temp = fullData.filter(item=>item.register_date.substring(0,5)===(year+"/"+m));

    }else{
      temp = fullData.filter(item=>item.register_date.substr(3,2)===m);

    }
    setfilterData(temp);
    setMonth(e.currentTarget.value);
    var res = temp.reduce(function(obj, v) {
      // increment or set the property
      // `(obj[v.status] || 0)` returns the property value if defined
      // or 0 ( since `undefined` is a falsy value
      obj[v.incharge] = (obj[v.incharge] || 0) + 1;
      // return the updated object
      return obj;
      // set the initial value as an object
    }, {})
    var arr = [];
    Object.entries(res).map((ele)=>(
      arr.push({incharge: ele[0], cnt: ele[1]})
    ))
    setStats(arr);
    console.log(res);

  }


 
 
  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
     <div className="content-wrap">
     
{/*<input type="file" id="file_upload" />
<button onClick={()=>upload()}>Upload</button>  
<div
      dangerouslySetInnerHTML={{__html: html}}
    />
<br/>
  <table id="display_excel_data"></table>*/}

      <div className="outer_wrapper stats" style={{justifyContent:"flex-start"}}>

      <div className="side-menu">

      <BoardList changeBoard={(value)=>{setfilterData(value);
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}}/>
</div>
<div className="main_content" style={{display:"flex", flexDirection:"column", minWidth:"1000px"}}>


<div className="btns" style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"50%", margin:"20px 0"}}>
              
{/*<div className="row">
<div className="box"><Link to="/docs/1">년도별 통계</Link></div>
<div className="box"><Link to="/rdocs/0">월별 통계</Link></div>

      </div>*/}
    <div className="left">
      <select id="u_shcate" name="search_item" className="select" value={year} onChange={(e)=>selectYear(e)}>
        <option value="">년도 선택</option>
        <option value="23">2023</option>
        <option value="22">2022</option>
      </select>

      <select id="u_shcate" name="search_item" className="select" value={month} onChange={(e)=>selectMonth(e)} style={{marginLeft:"20px"}}>
        <option value="">월 선택</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>

      </select>

    </div>

</div>

<div style={{display:"flex"}}>
<div className="sales" style={{border:"1px solid #4A6590", padding:"0 20px"}}>
<h3>총 매입</h3>

        
<p>{filterData.filter(item =>item.purchase!=="").reduce((partialSum, a) => 
                  partialSum + parseInt(a.purchase.replace(/[.,\s]/g, '')), 0).toLocaleString()} 원</p>
</div>

<div className="sales" style={{border:"1px solid #32376B", padding:"0 20px", marginLeft:"20px"}}>
<h3>총 견적</h3>
<p>{filterData.filter(item =>item.estimate!=="" && item.estimate!==" - ").reduce((partialSum, a) => 
                  partialSum + parseInt(a.estimate.replace(/[.,\s]/g, '')), 0).toLocaleString()} 원</p>
</div>

<div className="sales" style={{border:"1px solid #32376B", padding:"0 20px", marginLeft:"20px"}}>
<h3>총 매출</h3>
<p>{filterData.filter(item =>item.sales!=="" && item.sales!==" - ").reduce((partialSum, a) => 
                  partialSum + parseInt(a.sales.replace(/[.,\s]/g, '')), 0).toLocaleString()} 원</p>
</div>
</div>
        <div style={{display:"flex", marginTop:"20px"}}>
        <div className="left" style={{flexDirection:"column", height:"500px", width:"100%"}}>

        <h3>{values.board} 사원별 통계</h3>

      {filterData.length===0 ? 
      <div>데이터가 없습니다.</div>
      :
      <ResponsiveBar
                /**
                 * chart에 사용될 데이터
                 */
                data={stats}
                /**
                 * chart에 보여질 데이터 key (측정되는 값)
                 */
                keys={['cnt']}
                /**
                 * keys들을 그룹화하는 index key (분류하는 값)
                 */
                indexBy="incharge"
                /**
                 * chart margin
                 */
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                /**
                 * chart padding (bar간 간격)
                 */
                padding={0.3}
                /**
                 * chart 색상
                 */
                colors={['skyblue']} // 커스터하여 사용할 때
                // colors={{ scheme: 'nivo' }} // nivo에서 제공해주는 색상 조합 사용할 때
                /**
                 * color 적용 방식
                 */
                colorBy="id" // 색상을 keys 요소들에 각각 적용
                // colorBy="indexValue" // indexBy로 묵인 인덱스별로 각각 적용
                theme={{
                    /**
                     * label style (bar에 표현되는 글씨)
                     */
                    labels: {
                        text: {
                            fontSize: 14,
                            fill: '#000000',
                        },
                    },
                    /**
                     * legend style (default로 우측 하단에 있는 색상별 key 표시)
                     */
                    legends: {
                        text: {
                            fontSize: 12,
                            fill: '#000000',
                        },
                    },
                    axis: {
                        /**
                         * axis legend style (bottom, left에 있는 글씨)
                         */
                        legend: {
                            text: {
                                fontSize: 12,
                                fill: '#000000',
                            },
                        },
                        /**
                         * axis ticks style (bottom, left에 있는 값)
                         */
                        ticks: {
                            text: {
                                fontSize: 12,
                                fill: '#000000',
                            },
                        },
                    },
                }}
                /**
                 * axis bottom 설정
                 */
                axisBottom={{
                    tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                    tickPadding: 5, // tick padding
                    tickRotation: 0, // tick 기울기
                    legend: '[실적]', // bottom 글씨
                    legendPosition: 'middle', // 글씨 위치
                    legendOffset: 40, // 글씨와 chart간 간격
                }}
                /**
                 * axis left 설정
                 */
                axisLeft={{
                    tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                    tickPadding: 5, // tick padding
                    tickRotation: 0, // tick 기울기
                    legendPosition: 'middle', // 글씨 위치
                    legendOffset: -60, // 글씨와 chart간 간격
                }}
                /**
                 * label 안보이게 할 기준 width
                 */
                labelSkipWidth={36}
                /**
                 * label 안보이게 할 기준 height
                 */
                labelSkipHeight={12}
                /**
                 * bar 클릭 이벤트
                 */
                /**
                 * legend 설정 (default로 우측 하단에 있는 색상별 key 표시)
                 */
                legends={[
                    {
                        dataFrom: 'keys', // 보일 데이터 형태
                        anchor: 'bottom-right', // 위치
                        direction: 'column', // item 그려지는 방향
                        justify: false, // 글씨, 색상간 간격 justify 적용 여부
                        translateX: 120, // chart와 X 간격
                        translateY: 0, // chart와 Y 간격
                        itemsSpacing: 2, // item간 간격
                        itemWidth: 100, // item width
                        itemHeight: 20, // item height
                        itemDirection: 'left-to-right', // item 내부에 그려지는 방향
                        itemOpacity: 0.85, // item opacity
                        symbolSize: 20, // symbol (색상 표기) 크기
                        effects: [
                            {
                                // 추가 효과 설정 (hover하면 item opacity 1로 변경)
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    }
                ]}

                onClick={(data) => {
                  console.log(data);
                  setModel("");
                  setInCharge(data.data.incharge);
                  
                  
                 
              }}
            />  
          }
          </div>


          

        </div>

        <div style={{marginTop:"80px"}}>
          <div style={{display:"flex"}}>
            <p style={{padding:"5px 10px"}}>AS 내역 {inCharge !=="" && (<span>[{inCharge} 사원]</span>)} {model !=="" && (<span>[{model}]</span>)}</p>

            <p style={{textAlign:"center", border:"1px solid #4787EE", marginLeft:"10px", padding:"5px 10px"}}>
              매입 : ₩{filterData.filter(item => {if (inCharge!=="") {return (item.incharge===inCharge && item.purchase!=="" && item.purchase!==" - ")}
              else return (item.purchase!=="" && item.purchase!==" - ")}).reduce((partialSum, a) => 
                  partialSum + parseInt(a.purchase.replace(/[.,\s]/g, '')), 0).toLocaleString()}  |  
              견적 : ₩{filterData.filter(item => {if (inCharge!=="") {return (item.incharge===inCharge && item.estimate!=="" && item.estimate!==" - ")}
              else return (item.estimate!=="" && item.estimate!==" - ")}).reduce((partialSum, a) => 
                  partialSum + parseInt(a.estimate.replace(/[.,\s]/g, '')), 0).toLocaleString()}  |  
              매출 : ₩{filterData.filter(item => {if (inCharge!=="") {return (item.incharge===inCharge && item.sales!=="" && item.sales!==" - ")}
              else return (item.sales!=="" && item.sales!==" - ")}).reduce((partialSum, a) => 
                  partialSum + parseInt(a.sales.replace(/[.,\s]/g, '')), 0).toLocaleString()}
            </p>
          </div>
          <table class="delivery table-hover bd-b">
									<thead class="thead-dark">
									<tr class="tx-center">
                    <th>계약</th>
                    <th>담당</th>
                    <th>병원</th>
                    <th>장비</th>
                    <th>모델(제조사)</th>
                    <th>S/N(부품명)</th>
                    <th>접수날짜</th>
                    <th>견적날짜</th>
                    <th>발주(취소)</th>
                    <th>출고,종료</th>
                    <th>계산서발행</th>
                    <th>상태</th>
                    <th>유무상</th>
                    <th>랭크</th>
                    <th>매입</th>
                    <th>견적</th>
                    <th>매출</th>
                 
                  
             

									</tr>
									</thead>
									<tbody>
                 
                                    {isEmpty && (
                                  <tr style={{ textAlign: "center" }}>
                                    <td colSpan={11}>게시물이 없습니다.</td>
                                  </tr>
                                )}
                                
                                {filterData
                                  .filter(item => {if (inCharge!=="") {return item.incharge===inCharge} else return item;})
                                  .map((element, index) => (
                                      <tr key={element.no}>
                                    
                                        

                                    
                                      
                                        <td>
                                        {element.contract}
                                        </td>
                                        <td>
                                        {element.incharge}
                                        </td>
                                        <td>
                                        {element.hospital}
                                        </td>
                                        <td>
                                        {element.equipment}
                                        </td>
                                        <td>
                                        {element.model}
                                        </td>
                                        <td>
                                        {element.sn}
                                        </td>
                                        <td>
                                        {element.register_date}
                                        </td>
                                        <td>
                                        {element.estimate_date}
                                        </td>
                                        <td>
                                        {element.order_date}
                                        </td>

                                        <td>
                                        {element.release_date}
                                        </td>
                                        <td>
                                        {element.bill_date}
                                        </td>
                                        <td>
                                        {element.status}
                                        </td>
                                        <td>
                                        {element.free_paid}
                                        </td>

                                        <td>
                                        {element.rank}
                                        </td>
                                        <td>
                                        {element.purchase}
                                        </td>
                                        <td>
                                        {element.estimate}
                                        </td>
                                        <td>
                                        {element.sales}
                                        </td>
                                    
                                     
                                      </tr>)
                                     
                                    
                                  )}

									</tbody>
								</table>
        </div>
        </div>
      </div>
    </div>
  </div>

  );
};

export default BoardStats;
