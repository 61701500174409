import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터


import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 



const RentView = ({data, line}) => {  
  

  const [links, setLinks]=useState([]);
  const [select, setSelect] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState([]);

  
 
 


 


  
  useEffect(() => {
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/rent/'+data)
    .then(function (response){
      console.log("order.........",response.data[0]);
      if (response.data.length>0){
      setValues(response.data[0]);
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  }, []);




  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     
     

    

            <div className="order_write forms" style={{padding:"50px", boxSizing:"border-box",margin:"20px auto"}}>
          

        <div className="rent">
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>대여장비 확인서</td>
                 
                  </tr>
               
                </table>
            
                    <table className="" style={{fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                    <thead>
                      <tr><th style={{textAlign:"center", borderBottom:"1px solid #000",borderTop:"1px solid #000", padding:"5px 0", background:"#f2f2f2"}} colSpan={4}>대여 정보</th></tr>
                    </thead>
                      <tbody>
                      <tr style={{height:"10px"}}></tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            관리번호 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.mgmt_num}
                          </td>

                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            대여형태 : 
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.rent_type}
                          </td>
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            신청일자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.request_date} 
                          </td>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            대여목적 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.purpose} 
                          </td>
                          
                        </tr>
                        <tr style={{height:"10px"}}></tr>

                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병 원 명 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.hospital_name}
                          </td>


                         
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            모 델 명 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.model}
                          </td>


                         
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            제조번호 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.mfr_num}
                          </td>


                         
                        </tr>
                        <tr style={{height:"10px"}}></tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            대여기간 :
                          </th>
                          <td colSpan={3} style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <div style={{display:"flex"}}>
                          {values.rent_start} <span>~</span>
                          {values.rent_end}
                          </div>

                          </td>
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            비 고 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.memo}
                          </td>
                        </tr>

                        <tr style={{height:"10px"}}></tr>
                      </tbody>
                    </table>

                    <table className="" style={{fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                    <thead>
                      <tr><th style={{textAlign:"center", borderBottom:"1px solid #000",borderTop:"1px solid #000", padding:"5px 0", background:"#f2f2f2"}} colSpan={4}>인수</th></tr>
                    </thead>
                      <tbody>
                      <tr style={{height:"10px"}}></tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            인수일자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.acquire_date} 
                          </td>

                        </tr>
                        <tr>
                          <td colSpan={4}><hr></hr></td>
                        </tr>
                        <tr>
                          <td colSpan={2}></td><td colSpan={2}>상기 장비를 인수하였음을 확인합니다.</td>
                        </tr>

                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            대여자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.borrower}
                          </td>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병원명 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.hospital1}
                          </td>
                          
                        </tr>
                        <tr style={{height:"10px"}}></tr>

                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            담당자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.borrower_incharge}
                          </td>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            담당자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle', display:"flex"}}>
                          {values.hospital_incharge1}<span>(인)</span>
                          </td>
                          
                        </tr>
 
                        <tr style={{height:"10px"}}></tr>
                      </tbody>
                    </table>
              
                    <table className="" style={{fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                    <thead>
                      <tr><th style={{textAlign:"center", borderBottom:"1px solid #000",borderTop:"1px solid #000", padding:"5px 0", background:"#f2f2f2"}} colSpan={4}>반납</th></tr>
                    </thead>
                      <tbody>
                      <tr style={{height:"10px"}}></tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            반납일자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.return_date}
                          </td>

                        </tr>
                        <tr>
                          <td colSpan={4}><hr></hr></td>
                        </tr>

                        <tr>
                          <td colSpan={2}></td><td colSpan={2}>상기 장비를 반납하였음을 확인합니다.</td>
                        </tr>

                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            대여자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.borrower}
                          </td>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병원명 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.hospital1}
                          </td>
                          
                        </tr>
                        <tr style={{height:"10px"}}></tr>

                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            담당자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.borrower_incharge}
                          </td>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            담당자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle', display:"flex"}}>
                          {values.hospital_incharge1}<span>(인)</span>
                          </td>
                          
                        </tr>
 
                        <tr style={{height:"10px"}}></tr>
                      </tbody>
                    </table>
              

      </div>

      </div>
      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
    </div>

  );
};

export default RentView;

