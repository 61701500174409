import React, { useState, useEffect, useRef } from "react";
import {FaRegQuestionCircle, FaRegPlusSquare} from "react-icons/fa";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import FolderTree from 'react-folder-tree';

import "./css/report_write.css";
import user from "./img/user.png";
import axios from "axios";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

import {
  format, startOfWeek, endOfWeek, getWeek, subWeeks, addWeeks
  
} from "date-fns";


const WorkReportWrite = ({ type, update, closePopup }) => {
  var today = new Date();
  const [year, setYear] = useState(new Date().getFullYear());
  const [wrNo, setWrNo]=useState("");
  const [performance, setPerformance]=useState("");
  const [plan, setPlan]=useState("");
  const [month, setMonth]=useState("");

  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([1,2,3,4,5,6,7,8,9,10,11,12]);
  const [values, setValues]= useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date()); 
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [works, setWorks]= useState([]);
  const [currentWeekText, setCurrentWeekText] = useState("");
  useEffect(() => {
    console.log("update...", update);
    if (update !==undefined){
      setValues(update);
      setPerformance(update.performance);
      setPlan(update.plan);
      setCurrentWeekText(update.week_text);
      setCurrentWeek(update.week_no);
      setWrNo(update.wr_no);

    }else{
      values.wr_register_date = format(today, "yyyy-MM-dd");
      let dateFormat = "yyyy-MM-dd";
      let start = format(startOfWeek(today), dateFormat);
      console.log("start..........", start);
      let end = format(endOfWeek(today), dateFormat);
      setCurrentWeekText(start+" ~ "+end);
    }
    
    axios.get('https://gw.thegmmedical.com:5003/api/getworks')
    .then(function (response){
      console.log("user is", response.data);
      setWorks(response.data);
      values.work_no = response.data[0].no;
      if (update !==undefined){
        setValues(update);
        values.work_no = update.work_no;
        values.wr_register_date = update.wr_register_date;
      }
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    let dt = new Date();
    let com_year = dt.getFullYear();
      
      // 현재 년도 자동 선택
      //$("#yearSel").append("<option value='" + com_year + "' selected>" + com_year + "년</option>");
      // 올해 기준으로 -5년부터 +5년을 보여준다.
      let year_arr = [];
      for (var y = (com_year - 5); y <= (com_year + 5); y++) {
        year_arr.push(y);
      }
      let weeknum_arr = [];
      for (let i=0;i<53;i++){
        weeknum_arr.push(i+1);
      }
      setYears(year_arr);


        /*setCurrentMonth(subWeeks(currentMonth, 1));
        setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
        let dateFormat = "yyyy-MM-d";
        let start = format(startOfWeek(subWeeks(currentMonth, 1)), dateFormat);
        let end = format(endOfWeek(subWeeks(currentMonth, 1)), dateFormat);

        setCurrentWeekText(start+" ~ "+end);
        setCurrentMonth(addWeeks(currentMonth, 1));
        setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
        setCurrentWeekText();
        let dateFormat = "yyyy-MM-dd";
        let start = format(startOfWeek(addWeeks(currentMonth, 1)), dateFormat);
        let end = format(endOfWeek(addWeeks(currentMonth, 1)), dateFormat);

        setCurrentWeekText(start+" ~ "+end);*/
      
  }, []);

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;
  
    setValues({
      ...values,
      [name]: value,
    });
  };  

  const changeWeekHandle = (btnType) => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
      
      let dateFormat = "yyyy-MM-dd";
      let start = format(startOfWeek(subWeeks(currentMonth, 1)), dateFormat);
      let end = format(endOfWeek(subWeeks(currentMonth, 1)), dateFormat);

      setCurrentWeekText(start+" ~ "+end);
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
      setCurrentWeekText();
      let dateFormat = "yyyy-MM-dd";
      let start = format(startOfWeek(addWeeks(currentMonth, 1)), dateFormat);
      let end = format(endOfWeek(addWeeks(currentMonth, 1)), dateFormat);

      setCurrentWeekText(start+" ~ "+end);
    }
  };
  function addReport(){
    const data = {
      workNo : values.work_no,
      subject : values.wr_subject,
      progress : values.progress,
      workhour : values.workhour,
      performance: performance,
      plan:plan,
      registerDate : values.wr_register_date,
      type:type,
      weekNo: currentWeek,
      weekText: currentWeekText,
      wr_no : wrNo,
      year : year,
      month : month
  
     }
     
      console.log(data);
      if (update !==undefined){
        axios.post('https://gw.thegmmedical.com:5003/api/update_report', data)
        .then(function (response){
          console.log(response);
          window.location.reload(true);
    
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }else{
        axios.post('https://gw.thegmmedical.com:5003/api/add_report', data)
        .then(function (response){
          console.log(response);
          window.location.reload(true);
    
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
      
     
  }
  return (
    <div className="popup">
      <div className="inner report_w" style={{maxWidth:"1300px"}}>

<div className="row">
 <div className="box">
  {type==="daily"&&(<input type="date" name="wr_register_date" value={values.wr_register_date} onChange={handleInputChange}></input>)}
  {type==="weekly"&&(
  
  <div className="" style={{display:"flex"}}>
        <div className="">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            <MdArrowBackIos />
          </div>
        </div>
        <div className="" style={{ textAlign: "center" }}>
          <span>{currentWeekText}  - [{currentWeek}주]</span>
        </div>
        <div className="" style={{ textAlign: "right" }}>
          <div className="icon" onClick={() => changeWeekHandle("next")}>
            <MdArrowForwardIos />
          </div>
        </div>
      </div>
  
  )}

{type==="monthly"&&(

<div style={{display:"flex", width:"100%", justifyContent:"end"}}>
                                    <select value={year}
                                    onChange={(e)=>setYear(e.currentTarget.value)}>
                                      {years.map((ele, idx)=>(
                                        <option value={ele}>{ele}년</option>
                                      ))}
                                      
                                    
                                    </select>
                                    <select value={month}
                                    onChange={(e)=>setMonth(e.currentTarget.value)}>
                                      <option>선택</option>
                                      {months.map((ele,idx)=>(
                                        <option value={ele}>{ele}월</option>
                                      ))}
                                      
                                    </select>
                                 
                                  </div>


)}

  </div>
</div>
      <table width="100%" className="pc" cellspacing="0" cellpadding="0" border="0" bordercolor="black" style={{display:"table"}}>
    
          <thead>
            <tr height="23">
                <th nowrap="" class="table_title" style={{width:"25%"}}>업무명</th>
                <th nowrap="" class="table_title" style={{width:"25%"}}>제목</th>
                <th nowrap="" class="table_title" style={{width:"25%"}}>진행률</th>
                <th nowrap="" class="table_title" style={{width:"25%"}}>업무시간</th>
               
            </tr> 
          </thead>
            <tbody>
                  <tr height="23">
                  <td nowrap="" class="table_title">
                    <select name="work_no" value={values.work_no}
                        onChange={handleInputChange}>
                      {works.map((element, index)=>(
                        <option value={element.no}>{element.subject}</option>
                      ))}
                    </select>
                  </td>
                  <td nowrap="" class="table_title">
                    <input type="text" name="wr_subject" value={values.wr_subject} onChange={handleInputChange}></input>
                  </td>
                  <td nowrap="" class="table_title">
                    <input type="number" maxLength={2} name="progress" value={values.progress} onChange={handleInputChange}
                    onInput={(e) => {
                        if (e.target.value < 0) e.target.value = 0;
                          if (e.target.value > 100) e.target.value = 100;
                        
                    }}
                    ></input>%
                  </td>
                  <td nowrap="" class="table_title">
                    <input type="number" name="workhour" value={values.workhour} onChange={handleInputChange}></input>시간
                  </td>
           
              </tr> 
     





    </tbody></table>


    <table width="100%" className="mobile" cellspacing="0" cellpadding="0" border="0" bordercolor="black">
    
            <tr width="100%">
                <th style={{padding:"12px"}} class="table_title">업무명</th>
                <td nowrap="" class="table_title">
                    <select name="work_no" value={values.work_no}
                        onChange={handleInputChange}>
                      {works.map((element, index)=>(
                        <option value={element.no}>{element.subject}</option>
                      ))}
                    </select>
                  </td>
              </tr>
              <tr width="100%">
                <th style={{padding:"12px"}} class="table_title">제목</th>
                <td nowrap="" class="table_title">
                    <input type="text" name="wr_subject" value={values.wr_subject} onChange={handleInputChange}></input>
                  </td>
              </tr>
              <tr width="100%">
                <th style={{padding:"12px"}} class="table_title">진행률</th>
                <td nowrap="" class="table_title">
                    <input type="number" maxLength={2} name="progress" value={values.progress} onChange={handleInputChange}
                    onInput={(e) => {
                        if (e.target.value < 0) e.target.value = 0;
                          if (e.target.value > 100) e.target.value = 100;
                        
                    }}
                    ></input>%
                  </td>
              </tr>
                
              <tr width="100%">
                <th style={{padding:"12px"}} class="table_title">업무시간</th>
                <td nowrap="" class="table_title">
                    <input type="number" name="workhour" value={values.workhour} onChange={handleInputChange}></input>시간
                  </td>
              </tr>
                
               
          </table>

    <div style={{display:"flex", marginTop:"20px"}}>
      <div style={{width:"50%"}}>
      <p style={{width:"100%", background:"#f7f7f7",margin:"0", padding:"5px", textAlign:"center",boxSizing:"border-box"}}>실적</p>
      <textarea style={{height:"150px", padding:"15px", boxSizing:"border-box"}} value={performance} onChange={(e)=>setPerformance(e.target.value)}></textarea>
      </div>
    
      <div style={{width:"50%"}}>
      <p style={{width:"100%", background:"#f7f7f7",margin:"0", padding:"5px", textAlign:"center",boxSizing:"border-box"}}>계획</p>
      <textarea style={{height:"150px",  padding:"15px", boxSizing:"border-box"}} value={plan} onChange={(e)=>setPlan(e.target.value)}></textarea>
      </div>    </div>
    <div className="btns" style={{display:"flex",justifyContent:"center", width:"100%"}}>
    <div style={{marginTop:"20px"}} className="btn" onClick={()=>addReport()}>추가</div>
  <div style={{marginTop:"20px"}} className="btn" onClick={closePopup}>닫기</div>

</div>

      </div>
      
    </div>
  );
};

export default WorkReportWrite;