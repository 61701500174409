import React from "react";


import "./css/popup.css";



const ShowComment = ({ data, closePopup }) => {

     
  return (
    <div className="popup">
      <div className="popup_inner" style={{height:"300px"}}>
        <h3 style={{textAlign:"left", marginLeft:"20px"}}>문서 확인 첨언 조회</h3>
        <div className="addUser">
        {/*<table className="table" style={{margin:"20px auto", width:"95%"}}>
      
          <tr>
            <th></th>
            <td>
            {data.comment}
            </td>
          </tr>

       


        </table>*/}

          {data.comment}
        </div>
        <div className="buttons">
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>닫기</button>
        </div>
        
      </div>
      
    </div>
  );
};

export default ShowComment;