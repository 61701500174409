import { NavLink, Link,useParams, useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailNav from "../components/side/MailNav";
import './css/view.css';
import { GiHamburgerMenu } from "react-icons/gi";
import DocList from "../components/side/DocList";


import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { Sheet, Op, Selection, colors } from "@fortune-sheet/core";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import { useStateWithCallbackLazy } from 'use-state-with-callback';


import LeaveView from "../components/LeaveView";
import OTView from "../components/OTView";
import CancelLeaveView from "../components/CancelLeaveView";

import Order from "./forms/Order";
import Confirm from "./forms/Confirm";
import Request from "./forms/Request";
import Repair from "./forms/Repair";
import Rent from "./forms/Rent";
import Service from "./forms/Service";
import Endoscope from "./forms/Endoscope";
import Inspect from "./forms/Inspect";
import Acquire from "./forms/Acquire";
import HR from "./forms/HR";
import Purchase from "./forms/Purchase";


import LogiOrder from "./logistics/Order";

import Expenditure from "./common/Expenditure";
import Receipt from "./common/Receipt";

let luckysheet;
axios.defaults.withCredentials = true; 
const initialValues = {
  subject: "",
  form_name: "",
  form_group:"",
  in_use: "",
  form_no:"",
  expire:"",
  desc:"",
  security:"",
  admin:[],
  cc:[],
  recipient:[],
  group_name:"",
};


const Form = () => {

  const luckyCss = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    height: '100%',
    left: '0px',
}

  const location = useLocation();
  

  const [values, setValues] = useStateWithCallbackLazy(initialValues);
  const [forms, setForms] = useState([]);
  const [lineNo, setLineNo] = useState();
  const [lineList, setLineList] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [test, setTest]= useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [__html, setHtml] = useState("");
  const [aa, setAA]= useState();
  const [, updateState] = React.useState();
  const [divv, setDivv] = useState();

  //const [shData, setShData]= useState();
  const [dddd, setDDDD] = useState();
  const [dataaa, setDataaa] = useState();
  var shData=[];

  function nextChar(c) {
    var u = c.toUpperCase();
    if (same(u,'Z')){
        var txt = '';
        var i = u.length;
        while (i--) {
            txt += 'A';
        }
        return (txt+'A');
    } else {
        var p = "";
        var q = "";
        if(u.length > 1){
            p = u.substring(0, u.length - 1);
            q = String.fromCharCode(p.slice(-1).charCodeAt(0));
        }
        var l = u.slice(-1).charCodeAt(0);
        var z = nextLetter(l);
        if(z==='A'){
            return p.slice(0,-1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
        } else {
            return p + z;
        }
    }
}


function nextLetter(l){
    if(l<90){
        return String.fromCharCode(l + 1);
    }
    else{
        return 'A';
    }
}


function same(str,char){
    var i = str.length;
    while (i--) {
        if (str[i]!==char){
            return false;
        }
    }
    return true;
}






  useEffect(()=>{
console.log("???????????????????????", location.state); 
    fetch("https://gw.thegmmedical.com:5003/api/getform/"+location.state.form_no, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
        console.log("................",json);
        setLineNo(json[0].line_no);
        if (json[0].type==="excel"){
        if (json[0].form_data !== null && json[0].form_data !== "undefined" && json[0].form_data !==""){
          /*var excel_data = JSON.parse(json[0].form_data);
          excel_data.showGridLines = 0;
          var rowN = excel_data.row;
          var height= parseInt(rowN) * 15;
          height = height + "px";
          height = height.toString();
          console.log(height, excel_data);
          console.log("excel_data...", excel_data);
          setDivv(<div style={{width:"100vh", height:"600px", overFlow:"visible"}}><Workbook data={[excel_data]} showToolbar={false} showFormulaBar={false} showSheetTabs={false} allowEdit={false} rowHeaderWidth={0} columnHeaderHeight={0}/></div>) ;
    */

          let content = JSON.parse(json[0].form_data);
          let cc = JSON.parse(json[0].cc);
          let rec = JSON.parse(json[0].recipient);
          let admin = JSON.parse(json[0].admin);
          if (json[0].cc === null){
            cc = [];
          }
          if (json[0].recipient === null){
            rec = [];
          }
          if (json[0].admin === null){
            admin = [];
          }
        
          const tmp = {
            subject: json[0].subject,
            form_name: json[0].form_name,
            desc:json[0].form_desc,
            form_group:"",
            form_no:json[0].form_no,
            in_use: json[0].in_use,
            type: json[0].type,
            custom_name: json[0].custom_name,
            cc: cc,
            recipient:rec,
            admin:admin,
            expire:json[0].expire,
            security:json[0].security,
            group_name:json[0].fgroup_name,
  
          };
          setValues(tmp, () => {

          const loadLuckysheet = async () => {
            try {
              // Load Luckysheet dynamically
              const luckysheetModule = await import('luckysheet');
              console.log("luckysheetModule", );
              content[0].showGridLines="0";
      
              luckysheet = luckysheetModule.default;
       
              luckysheet.create({
                container: "luckysheet",
                title: 'Luckysheet sphinx Demo', // set the name of the table
                data: content,
                showinfobar: false,
                allowUpdate :false,
                showstatisticBar:false,
                sheetFormulaBar:false,
                columnHeaderHeight:0,
                rowHeaderWidth:0,
                showsheetbar:false,
                showtoolbar:false,
            })
             
       
       
              // Set the loaded state
            } catch (error) {
              console.error('Error loading Luckysheet:', error);
            }
          };
          loadLuckysheet();
        });


        }else{
          
          let sample = [{
            "name": "Sheet1",
            "config": {},
            "index": "1",
            "status": "1",
            "order": "0",
            "luckysheet_select_save": [{
                "row": [0, 0],
                "column": [0, 0],
                "row_focus": 0,
                "column_focus": 0,
                "left": 0,
                "width": 72,
                "top": 0,
                "height": 23,
                "left_move": 0,
                "width_move": 72,
                "top_move": 0,
                "height_move": 23
            }],
            "zoomRatio": 1,
            "showGridLines": "1",
            "defaultColWidth": 72,
            "defaultRowHeight": 23,
            "celldata": [],
            "calcChain": [],
            "jfgird_select_save": [],
            "data": [
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
            ],
            "visibledatarow": [24, 48, 72, 96, 120, 144, 168, 192, 216, 240, 264, 288, 312, 336, 360, 384, 408, 432, 456, 480, 504, 528, 552, 576, 600, 624, 648, 672, 696, 720, 744, 768, 792, 816, 840, 864, 888, 912, 936, 960, 984, 1008, 1032, 1056, 1080, 1104, 1128, 1152, 1176, 1200, 1224, 1248, 1272, 1296, 1320, 1344, 1368, 1392, 1416, 1440, 1464, 1488, 1512, 1536, 1560, 1584, 1608, 1632, 1656, 1680, 1704, 1728, 1752, 1776, 1800, 1824, 1848, 1872, 1896, 1920, 1944, 1968, 1992, 2016],
            "visibledatacolumn": [73, 146, 219, 292, 365, 438, 511, 584, 657, 730, 803, 876, 949, 1022, 1095, 1168, 1241, 1314, 1387, 1460, 1533, 1606, 1679, 1752, 1825, 1898, 1971, 2044, 2117, 2190, 2263, 2336, 2409, 2482, 2555, 2628, 2701, 2774, 2847, 2920, 2993, 3066, 3139, 3212, 3285, 3358, 3431, 3504, 3577, 3650, 3723, 3796, 3869, 3942, 4015, 4088, 4161, 4234, 4307, 4380],
            "ch_width": 4500,
            "rh_height": 2096,
            "luckysheet_selection_range": []
        }];
         
        const loadLuckysheet = async () => {
          try {
            // Load Luckysheet dynamically
            const luckysheetModule = await import('luckysheet');
            console.log("luckysheetModule", luckysheetModule);
            luckysheet = luckysheetModule.default;
     
            luckysheet.create({
              container: "luckysheet",     
              title: 'Luckysheet sphinx Demo', // set the name of the table
              data: sample,
              showinfobar: false,
              allowUpdate :false,
              showstatisticBar:false,
              sheetFormulaBar:false,
              columnHeaderHeight:0,
              rowHeaderWidth:0,
              showsheetbar:false,
              showtoolbar:false
          })
           
     
     
            // Set the loaded state
          } catch (error) {
            console.error('Error loading Luckysheet:', error);
          }
        };
        loadLuckysheet();
  
  
  
        }
        }else{
          let cc = JSON.parse(json[0].cc);
          let rec = JSON.parse(json[0].recipient);
          let admin = JSON.parse(json[0].admin);
          if (json[0].cc === null){
            cc = [];
          }
          if (json[0].recipient === null){
            rec = [];
          }
          if (json[0].admin === null){
            admin = [];
          }
        
          const tmp = {
            subject: json[0].subject,
            form_name: json[0].form_name,
            desc:json[0].form_desc,
            form_group:"",
            form_no:json[0].form_no,
            in_use: json[0].in_use,
            type: json[0].type,
            custom_name: json[0].custom_name,
            cc: cc,
            recipient:rec,
            admin:admin,
            expire:json[0].expire,
            security:json[0].security,
            group_name:json[0].fgroup_name,
  
          };
          setValues(tmp);
        }

        if (json[0].form_no === 1){
          console.log("1입니다");
          setDivv(<LeaveView docNo = {json[0].doc_no}/>)
        }else if (json[0].form_no === 2){
          setDivv(<OTView docNo = {json[0].doc_no}/>)
        }else if (json[0].form_no === 3){
          setDivv(<CancelLeaveView docNo = {json[0].doc_no}/>)
        }



     

        axios.get('https://gw.thegmmedical.com:5003/api/getformline/'+json[0].line_no)
        .then(function (response){
        console.log("line list....", response.data);
        setLineList(response.data);

        //setLine(response.data[1]);
        
        
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });
    })

    axios.get('https://gw.thegmmedical.com:5003/api/getforms')
    .then(function (response){
    console.log("hey result is!!!!!!!!!!!!", response.data);
    setForms(response.data);

    //setLine(response.data[1]);
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    
}, []);

const display_admin = values.admin.map(item => (
  <div className=""><li key={item.id}>
    {item.text};&nbsp;&nbsp;&nbsp;
  </li>

  </div>
));

const display_cc = values.cc.map(item => (
  <div className=""><li key={item.id}>
    {item.text};
  </li>

  </div>
));
const display_rec = values.recipient.map(item => (
  <div className=""><li key={item.id}>
    {item.text};
  </li>

  </div>
));

function deleteForm(){
  var result = window.confirm("문서 양식을 정말 삭제하시겠습니까?");
  if(result){
    const data ={
      form_no : location.state.form_no
    }
    axios.post('https://gw.thegmmedical.com:5003/api/deleteform', data)
    .then(function (response){
      console.log(response);
      window.location.href="/doc_form";
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

  }
  
}
  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DocList/>
        </div>
      )}
   
<div className="content-wrap">
    
      
    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
    <div className="side-menu">
      <DocList/>
    </div>
   
<div className="main_content">
  
    

          <div className="btns" style={{display:"flex",alignItems:"start", 
          flexDirection:"column", width:"100%"}}>
                  
            <h4 style={{ padding:"0", width:"100%"}}>{values.form_name} </h4>



          <div className="" style={{display:"flex", justifyContent:"start", width:"100%"}}>
          <Link to ="/form_edit" state={{form_no: location.state.form_no}}><button>수정</button></Link>

          {(values.form_no!==1 && values.form_no!==2) &&(
            <button onClick={()=>deleteForm()}>삭제</button>
          )}
          
      
          </div>
                          

                <div style={{ background:"#fff", padding:"0", width:"100%"}}>
                {lineList.length >0  && (
                  <div style={{display:"flex"}} className="line">
                      {lineList.map((element, index)=> (
                        <table>
                          <tr><td rowSpan={3} className="type">{element.type}</td><td className="job">{element.job_name}</td></tr>
                          <tr><td className="name">{element.name}</td></tr>
                         
                        </table>
                      ))}
                  </div>
                )}
                <div style={{width:"100%"}}>
                
                <table className="form_write" style={{margin:"10px 0", width:"100%"}}>
      
            {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
            
            
      
      
    
      <tr className="">
        
      <td class="table_title2" nowrap="">
        문서양식명
      </td>
        <td class="table_list2_left">
        <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
        {values.form_name}
        </div>
        </td>
      
      </tr>
      
      <tr>
      <td class="table_title2" nowrap="">
        양식분류
      </td>
        <td class="table_list2_left">
        <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
        {values.group_name}
       
        </div>
        </td>
      
      
        <Tooltip
              id="date_guide"
              place="bottom"
              >
                <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
                  <span>양식의 상태를 설정합니다.</span>
                </div>
              </Tooltip>
        <td class="table_title2 pc" nowrap="">
          사용여부<BiInfoCircle data-tooltip-id="date_guide" style={{marginLeft:"5px"}}/>
        </td>
        <td class="table_list2_left pc">
        <div className="input-group radio" style={{display:"flex"}}>
        {values.in_use==="use" && (<span>사용</span>)}
        {values.in_use==="no" && (<span>사용안함</span>)}
        </div>
        </td>
      
      </tr>
      
      <tr className="mobile">
      <td class="table_title2" nowrap="">
          사용여부<BiInfoCircle data-tooltip-id="date_guide" style={{marginLeft:"5px"}}/>
        </td>
        <td class="table_list2_left">
        <div className="input-group radio" style={{display:"flex"}}>
            
        <input type="radio" value="use" name="in_use" checked={values.in_use === "use"} disabled/><label><span>사용</span>
            </label>
            <label>
              <input type="radio" value="no" name="in_use" checked={values.in_use === "no"} disabled/><span>사용안함</span>
            </label>
        </div>
        </td>
      </tr>
      
      <tr>
      <td class="table_title2" nowrap="">
        문서설명
      </td>
        <td class="table_list2_left">
        <div className="input-group">
        {values.desc}
        </div>
        </td>
      
       
      
      </tr>
      
      
      <tr>
      <td class="table_title2" nowrap="">
        결재라인
      </td>
        <td class="table_list2_left">
        <div className="input-group">
        {lineList[0] !==undefined && (
           lineList[0].line_name
        )}
       
        </div>
        </td>
      
       
      
      </tr>
      
      <tr>
      <Tooltip
              id="cc"
              place="bottom"
              >
                <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
                  <span>문서참조자는 결재문서와 결재상태를 확인할 수 있습니다.</span>
                </div>
              </Tooltip>
      
      <td class="table_title2" nowrap="">
        문서참조자<BiInfoCircle data-tooltip-id="cc" style={{marginLeft:"5px"}}/>
      </td>
      <td class="table_list2_left">
              <div className="input-group">
              {display_cc}
            </div>
           
          </td>
      </tr>
      
      <tr>
      <td class="table_title2" nowrap="">
        문서수신자
      </td>
      <td class="table_list2_left">
              <div className="input-group">
           {display_rec}
            </div>
          
          </td>
      </tr>
      {/*
      <tr>
      
      <td class="table_title2" nowrap="">
        보관기간
      </td>
        <td class="table_list2_left">
        <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
        <select name="expire" className="formInput" value={values.expire || ''} disabled>
        <option value="1">1년</option>
        <option value="3">3년</option>
        <option value="5">5년</option>
        </select> 
        </div>
        </td>
        <td class="table_title2 pc" nowrap="">
        보안등급
      </td>
        <td class="table_list2_left pc">
        <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
        <select name="security" className="formInput" value={values.security || ''} disabled>
        <option value="1">1등급</option>
        <option value="2">2등급</option>
        <option value="3">3등급</option>
        <option value="4">4등급</option>
        <option value="5">5등급</option>
      
      </select> 
        </div>
        </td>
      
      </tr>
      
      <tr className="mobile">

      <td class="table_title2" nowrap="">
        보안등급
      </td>
        <td class="table_list2_left">
        <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
        <select name="security" className="formInput" value={values.security || ''} disabled>
        <option value="1">1등급</option>
        <option value="2">2등급</option>
        <option value="3">3등급</option>
        <option value="4">4등급</option>
        <option value="5">5등급</option>
      
      </select> 
        </div>
        </td>

      </tr>*/}
      
      <tr>
        
      <td class="table_title2" nowrap="">
        운영자
      </td>
      <td class="table_list2_left">
              <div className="input-group">
            {display_admin}
            </div>
           
              
          </td>
        
      </tr>
      

      <tr>
      <td class="table_title2" nowrap="">
        
      </td>
      <td class="table_list2_left">
              <div className="input-group">
            {display_admin}
            </div>
         
          </td>
      </tr>
      
      
    
    
     
      
          </table>


      <div className="form_content" style={{width:"100%",position:"relative"}}>
       

      {values.type ==="custom" &&(
<div className="custom_form custom_form_view">
 
  {(values.type ==="custom"  && values.custom_name==="order") && (
  <Order data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)}

{(values.type ==="custom"  && values.custom_name==="confirm") && (
  <Confirm data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}
{(values.type ==="custom"  && values.custom_name==="request") && (
  <Request data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}
{(values.type ==="custom"  && values.custom_name==="repair") && (
  <Repair data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}

{(values.type ==="custom"  && values.custom_name==="rent") && (
  <Rent data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}
{(values.type ==="custom"  && values.custom_name==="service") && (
  <Service data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}

{(values.type ==="custom"  && values.custom_name==="endoscope") && (
  <Endoscope data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}

{(values.type ==="custom"  && values.custom_name==="inspect") && (
  <Inspect data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}
{(values.type ==="custom"  && values.custom_name==="acquire") && (
  <Acquire data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}
{(values.type ==="custom"  && values.custom_name==="hr") && (
  <HR data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}
{(values.type ==="custom"  && values.custom_name==="purchase") && (
  <Purchase data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}
{(values.type ==="custom"  && values.custom_name==="logi_order") && (
  <LogiOrder data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}
{(values.type ==="custom"  && values.custom_name==="expenditure") && (
  <Expenditure data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}
{(values.type ==="custom"  && values.custom_name==="receipt") && (
  <Receipt data={values.doc_no} line={lineList} onChange={(values)=>console.log(values)}/>)
}



</div>
    )}
     {(values.type !=="custom" && values.type==="html") &&(<div className="custom_form custom_form_view" style={{height:"600px", overflow:"hidden", position:"relative"}}>{divv}</div>)} 

     {values.type ==="excel" &&(<div className="excel_view" style={{height:"1000px", overflow:"hidden", position:"relative"}}>


<div
id="luckysheet"
style={luckyCss}
/>


</div>)}




      </div>  
      
          </div>
      
         
      
          </div>
      
      
          
      
        
          </div>
        </div>;
      
  </div></div>

</div>
  );
};

export default Form;
