import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";

import axios from "axios";

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  hospital_name: "",
  model: "",
  estimate_date:"",
  register_date: "",
  mfr_num: "",
  total:"",
  memo:"",
};


function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {part_name, item_code, quantity, unit, unit_price, price}= rowsData;


    return (
      <tr key={index}>
        <td>{index+1}</td>
       
        <td>
          {part_name}
          
        </td>
        <td>
          {item_code}
          
        </td>
        <td>
          {quantity}
           
        </td>
        <td>
          {unit}
           
        </td>

        <td>
          {unit_price}
           
        </td>
        <td>
          {price}
            
        </td>

       
      </tr>
    );
  });
}

const RepairView = ({data, line}) => {  
  

  const [links, setLinks]=useState([]);
  const [select, setSelect] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

  
 
 

 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/repair/'+data)
    .then(function (response){
      console.log("order.........",response.data[0]);

      if (response.data.length>0){

      setValues(response.data[0]);
      let inspect_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/repair/'+inspect_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  }, []);


  const addRowTable = () => {
    const data = {
        part_name:'', item_code:'',quantity:'', unit:'', unit_price:'', price :''
    };
    initRow([...rows, data]);
  };
 

  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     
     

    

            <div className="order_write forms" style={{padding:"50px", boxSizing:"border-box",margin:"20px auto"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>수리견적서</td>
                
                  </tr>
          
                </table>
                <hr style={{borderWidth:"5px"}}/>
          <table border="1" className="supply" style={{width:"100%"}}>
          <tbody>
          <tr>
          <td rowspan="2" style={{textAlign:"center"}}>공<br></br>
          <br></br>급</td>
          <td>등록번호</td>
          <td>108-81-84884</td>
          <td>상호</td>
          <td>(주)GM메디칼</td>
          <td>대표자</td>
          <td>임명한</td>
          </tr>
          <tr>
          <td>주소</td>
          <td>서울 서초구 서초대로 46길 19-7, 201호</td>
          <td>업태</td>
          <td>도소매</td>
          <td>종목</td>
          <td>의료기기</td>
          </tr>
       
          </tbody>
          </table>
        
          <table className="" style={{width:"100%", margin:"20px"}}>
          <tbody>
          <tr>
          <td style={{width:"70px"}}>병원명 : </td>
          <td colspan="3">                            
          {values.hospital_name}
          </td>
          <td style={{width:"70px"}}>견적일자 : </td>
          <td colspan="3">
          {values.estimate_date}
          </td>
          </tr>
          <tr>
          <td>모델명 : </td><td colspan="3">
          {values.model}
          </td><td>제조번호 : </td>
          <td colspan="3">
          {values.mfr_num} 
          </td>
          </tr>
          </tbody>
          </table>

          <table className="" style={{width:"100%", padding:"5px 0", borderTop:"1px solid #000", borderBottom:"1px solid #000"}}>
          <tbody>
          <tr style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
          <td>합계금액 : </td><td>
          {values.total}
          </td><td><span>(VAT 포함)</span></td>
          </tr>
      
          </tbody>
          </table>

          <table className="form_list repair_list" style={{borderCollapse: 'collapse', width:"100%"}}>
                 
          <thead>
            <tr>
              <th></th>
              <th>부품명</th>
              <th>품목코드</th>
              <th>수량</th>
              <th>단위</th>
              <th>단가</th>
              <th>금액</th>
            </tr>

            
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            
            />
          <tr>
            <td style={{padding:"5px 0"}}>합계</td>
            <td colSpan={7} style={{textAlign:"right", padding:"0 35px"}}></td>
          </tr>
          </tbody>
        </table>
            
          </span>
          <table style={{width:"100%", borderCollapse:"collapse", border:"1px solid #000", marginTop:"20px"}}>
              <tr><th style={{textAlign:"center", padding:"5px", borderBottom:"1px solid #000"}}>특이사항</th></tr>
              <tr><td>{values.memo}</td></tr>
            </table>
          
          
          </div>
        
        
      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default RepairView;
