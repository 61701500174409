import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'

import axios from "axios";
import moment from "moment";
import {GiHamburgerMenu} from "react-icons/gi";

import * as XLSX from "xlsx";

import DraftsList from "../components/DraftsList";
import BoardList from "../components/side/BoardList";

import "./css/write.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  hospital_name:'',
  full_name:'',
  addr:'',
  tel:'',
  business_num:'',
  fax:'',
  note:''
 
};

var freePaidOptions = [{value:"무상", label:"무상", name:"free_paid"}, {value:"유상", label:"유상", name:"free_paid"}, {value:"폐기", label:"폐기", name:"free_paid"}, {value:"반송", label:"반송", name:"free_paid"}];
var rankOptions = [{value:"A", label:"A", name:"rank"}, {value:"B", label:"B", name:"rank"}, {value:"C", label:"C", name:"rank"}, {value:"D1", label:"D1", name:"rank"}, {value:"D", label:"D", name:"rank"}, {value:"E", label:"E", name:"rank"}];
var statusOptions = [
  {value:"점검중", label:"점검중", name:"status"}, 
  {value:"견적발행", label:"견적발행", name:"status"}, 
  {value:"견적제출", label:"견적제출", name:"status"}, 
  {value:"수리중", label:"수리중", name:"status"}, 
  {value:"수리취소", label:"수리취소", name:"status"}, 
  {value:"출고대기", label:"출고대기", name:"status"}, 
  {value:"계산서", label:"계산서", name:"status"}, 
  {value:"종료", label:"종료", name:"status"}, 
  {value:"센터대기", label:"센터대기", name:"status"}
]
export default function BoardWrite() {
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [list, setList] = useState([]);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [jsonData, setJsonData] = useState();
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);

  const [values, setValues] = useState(initialValues);
  const location = useLocation();

  useEffect(() => {
    axios.get("http://gw.thegmmedical.com:5003/api/get_as_status/models").then((response) => {
  // setNotice(response.data);
   setfilterData(response.data);
   if (response.data.length === 0) {
     setEmpty(true);
   } else {
     setEmpty(false);
     
   }
  });
 
  if (location.state  !==null){

    axios.get("http://gw.thegmmedical.com:5003/api/get_as/"+location.state.no+"/models")
    .then(function (response){
      console.log("result...............", response.data);
      setValues(response.data[0]);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  
  }
 


 
}, []);

  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };

  const toggleShow = () => {
    setShow(!show);
};

  const { state } = useLocation();
  useEffect(() => {
  
}, []);
 
  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

 

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };





 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {
  
 
   axios.post("http://gw.thegmmedical.com:5003/api/as_update/models", values)
   .then(({ data }) => {
      console.log("what is this");
      navigate("/model");

    });




  };

  const handleDraftButton = () => {
 
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("http://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    formData.append(`is_notice`, type);
    formData.append(`name`, "변수연");
    formData.append(`bg_no`, state.board_no);
    formData.append(`end_date`, endDate);
    formData.append(`category`, option);
    if (draftNo !==undefined){
      formData.append(`draft_no`, draftNo);
    }
    const fetch = () => {
      axios
        .post("http://gw.thegmmedical.com:5003/api/draftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response){
          setDraftNo(response.data);
          
        });
    };
    fetch();



  };
  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const downloadEmployeeData = () => {
    fetch('http://gw.thegmmedical.com:5003/api/download')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = '이호MH호텔_셀렉 A컷.zip';
                    a.click();
                });
                //window.location.href = response.url;
        });
  }
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
  window.addEventListener('message', (event) => {
    if (event.data["msg"] !== undefined){
      var files = event.data["msg"];
      for (let i= 0;i<files.length;i++){
        string+='<a href = "http://gw.thegmmedical.com:5003/get/'+ files[i].name+'">'+files[i].name+'</a>'

      }
      console.log(string);
      var content = editorRef.current?.getInstance().getHTML();
      string+=content;
      editorRef.current?.getInstance().setHTML(string);
    }
 
  });

  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const handleInputChange = (e) => {
    var name, value;
    if (e.target === undefined){
      name= e.name;
      value = e.value;
    }else{
      name= e.target.name;
      value = e.target.value;
    }
    
    console.log("name....", name, "  value....", value);
    setValues({
      ...values,
      [name]: value,
    });
  };

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white', minHeight: "unset", height:"18px", width:"100%", border:"none",
        display:"flex", alignItems:"center", boxShadow:"none", justifyContent:"space-between" }),
    
    placeholder : styles => ({
      ...styles,
      marginTop:"-15px"
    }),
    singleValue: styles => ({
      ...styles,
      marginTop:"-15px"
    }),
    Input: styles => ({
      ...styles,
      top:"-15px"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      backgroundColor:"transparent"
    }),
    IndicatorsContainer: styles => ({
      ...styles,
      marginTop:"-15px"
    }),
    menuList: styles => ({
      ...styles,
      
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#000',
        backgroundColor: isFocused ? '#ccf2ff' : 'default',
        
        cursor: isDisabled ? 'not-allowed' : 'default',
        /*'&:focus': {
          backgroundColor:"#ddd"
        },*/
      };
    }
  };


  async function handleFileAsync(e) {

    const file = e.target.files[0];
    const exceldata = await file.arrayBuffer();
          
    const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});
  
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
      //console.log(htmlData);
  
    const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
      header:1,
      defval:"",
      raw: false,
      blankrows: false
    })
    
    var sheetnames = workbook.SheetNames;
 
    jsondata.shift();
    setJsonData(jsondata);
  
    console.log("data",jsondata);

  
    /*for (let i = 0;i<jsondata.length;i++){
      for (let j=0;j<jsondata[i].length;j++){   
      }
    }*/
  }
  function importExcel(){

    const data ={
      tablename : "olympus",
      header: "reserve_num, apply_date, acquire_date, return_type, end_date, use_status, branch, num, model, sn, release_date, hospital_name, repair_model, repair_sn, repair_num, repair_in, note",
      arr : jsonData
    }
  
    axios.post('http://gw.thegmmedical.com:5003/api/add_excel_data', data)
      .then(function (response){
        console.log(response);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
  }

  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
     <div className="content-wrap">
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
        <div className="side-menu">
      <BoardList changeBoard={(value)=>{console.log(value);}} changeNotice={(value)=>{console.log(value);}}/>
</div>
      <div className="main_content cs_board">
      {show ? (
    <DraftsList onChange={(value) => {setSubject(value.subject);editorRef.current?.getInstance().setHTML(value.content);setDraftNo(value.draft_no)}} closePopup={() => toggleShow()}/>
    ) : null}
      

      <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              <div className="left">

              
              <button className="btn" onClick={handleRegisterButton} style={{marginRight:"10px"}}>등록</button>

              <button className="btn" onClick={handleDraftButton}>임시저장</button>

       

              </div>

              <div className="right">
                <button onClick={() => {setShow(!show);}}>임시저장글</button> 
              </div>
            </div>

            <div style={{width:"100%", margin:"0 auto"}}>
            <div className="as_status">

            <table className="write_table responsive" style={{ margin:"10px 0", width:"100%"}}>

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
        <tr>
  <td class="table_title2" nowrap="">
    모델명
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="text" name="model" className="formInput" placeholder="" value={values.model}
            onChange={handleInputChange}></input>
    </div>
    </td>

  <td class="table_title2" nowrap="">
    장비명
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="text" name="equipment" className="formInput" value={values.equipment}
            onChange={handleInputChange}></input>
    </div>
    </td>

  </tr>

  <tr>

  <td class="table_title2" nowrap="">
    담당/제조사
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="brand" className="formInput" value={values.brand}
            onChange={handleInputChange}></input> 
    </div>
    </td>

  <td class="table_title2" nowrap="">
    장비명(FULL)
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="full_name" className="formInput" value={values.full_name}
            onChange={handleInputChange}></input> 
    </div>
    </td>

 
  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    비고
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="note" className="formInput" value={values.note}
            onChange={handleInputChange}></input> 
    </div>
    </td>

   

  
  </tr>

 

 


 
{list.length>0&&(
  <tr>

  <td class="table_title2" nowrap="">
    문서 분류
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <select name="name" className="formInput" value={option || ''}
                                    onChange={selectHandle}>
    {list.map((element,index)=><option value={element.name}>{element.name}</option>)}  
    </select> 
    </div>
    </td>
  </tr>
)}
  

  
 
      </table>

    
      <div className="button_wrap">
      <button className="submit" onClick={handleRegisterButton}>등록</button>
      {/*</form>*/}
      </div>
      </div>



     

    <div className="as_list">
    <h4>최근 10개 장비 추가 내역</h4>


      <table class="delivery table-hover bd-b">
									<thead class="thead-dark">
									<tr class="tx-center">
                  <th>모델명</th>
                    <th>장비명</th>
                    <th>담당/제조사</th>
                    <th>장비명(FULL)</th>
                    <th>비고</th>

                
									</tr>
									</thead>
									<tbody>
                 
                                    {isEmpty && (
                                  <tr style={{ textAlign: "center" }}>
                                    <td colSpan={40}>게시물이 없습니다.</td>
                                  </tr>
                                )}
                                {filterData
                                  .slice(0, 9)
                                  .map((element, index) => (
                                      <tr key={element.no}>
                                    
                                        

                                    <td>
                                        {element.model}
                                        </td>
                                      
                                        <td>
                                        {element.equipment}
                                        </td>
                                        <td>
                                        {element.brand}
                                        </td>
                                        <td>
                                        {element.full_name}
                                        </td>
                                        <td>
                                        {element.note}
                                        </td>
                                    
                              
                                      </tr>)
                                     
                                    
                                  )}

									</tbody>
								</table>
          </div>
      </div>


      
     <div id="response">{links}</div>
 
      
      </div>
    </div>
    </div>
    </div>
  );
}
