import React, { useState, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';

import userImg from "./img/user.png";
import axios from "axios";
import styled from 'styled-components';
import Languages from "./tables/HRLanguages";
import Education from "./tables/HREducation";
import Experience from "./tables/HRExperience";
import Certificates from "./tables/HRCertificates";
import BusinessTrips from "./tables/HRBusinessTrips";
import Salaries from "./tables/HRSalaries";
import Vacation from "./tables/HRVacation";
import Appointments from "./tables/HRAppointments";
import {GiHamburgerMenu} from "react-icons/gi";

axios.defaults.withCredentials = true; 

const TabMenu = styled.ul`
  font-weight: bold;
  box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%),
  inset 0 1px 0 0 rgb(100 121 143 / 12%);
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin-bottom: 2rem;
  margin-top: 10px;

  .submenu {
  // 기본 Tabmenu 에 대한 CSS를 구현
    display: flex;
    /* justify-content: space-between;
    width: 380px;
    heigth: 30px; */
    padding: 10px 30px;
    font-size: 12px;

    cursor:pointer;
    transition: 0.5s;
  }
  .submenu:hover {
    border-bottom:2px solid #ddd;
  }

  .focused {
   //선택된 Tabmenu 에만 적용되는 CSS를 구현
    color: rgb(21,20,20);
    border-bottom:2px solid #000;
  }

  & div.desc {
    text-align: center;
  }
`;

const Desc = styled.div`
  text-align: center;
`;
const HRCard = () => {

  const [status, setStatus] = useState(1);
  const [textValue, setTextValue] = useState("");
  const [user, setUser] = useState([]);
  const [currentTab, clickTab] = useState(0);
  const [userNo, setUserNo] = useState(0);

  const selectMenuHandler = (index) => {
    // parameter로 현재 선택한 인덱스 값을 전달해야 하며, 이벤트 객체(event)는 쓰지 않는다
    // 해당 함수가 실행되면 현재 선택된 Tab Menu 가 갱신.
    clickTab(index);
  };

  
  var menuArr;
  const handleSetValue = (e) => {
    setTextValue(e.target.value);
  };
  const handleChange = (e) => {
    // string passed in
    // a string returned by default
    console.log(e.currentTarget.value);
    // add + to the event to make the value a number
    setStatus(+e.currentTarget.value);
  };
  useEffect(() => {
    let url_string = window.location.href;
    var url = new URL(url_string);
    var userNo = url.searchParams.get("no");
    fetch("http://gw.thegmmedical.com:5003/api/getuserinfo/"+userNo, {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("user...", json[0])
        setUser(json[0]);
        setUserNo(json[0].user_no);
       
      })
      .catch(() => {});
    
 
}, []);
menuArr = [
  { name: '경력', content: <Experience user_no={userNo}/> },
  { name: '자격', content: <Certificates user_no={userNo}/> },
  { name: '어학', content: <Languages user_no={userNo}/>},
  { name: '교육', content: <Education user_no={userNo}/> },
  { name: '출장', content: <BusinessTrips user_no={userNo}/> },
  { name: '급여', content: <Salaries user_no={userNo}/> },
  { name: '휴가', content: <Vacation user_no={userNo}/> },
  { name: '인사발령', content: <Appointments user_no={userNo}/> },
];
  return (
    <div className="">
        <div className="" style={{padding:"30px"}}>


<div className="user_detail">

<div className="header_txt">
  <h3 style={{margin:"0"}}>인사카드 열람</h3>
</div>
 
    <div className="addUser" style={{marginTop:"30px"}}>

        <div style={{display:"flex"}}>
        
<div className="addInfo" style={{width:"100%"}}>
<table  style={{width:"70%", minWidth:"1200px"}} className="info_summary">

<tbody>

<tr>
<td rowSpan={6} style={{width:"20%"}}><div className="addpic" style={{margin:"20px"}}>


<label htmlFor="photo-upload" className="custom-file-upload fas">

{user.profile_link !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={user.profile_link} alt="profile_pic" />
</div>
: <img for="photo-upload" src={userImg} alt="profile_pic"/>
}


</label>
</div></td>
<th class="table_title2_topline" width="120" nowrap="">성명</th>
<td class="table_list2_left_topline" nowrap="" width="15%"><b>{user.name}</b></td>
<th class="table_title2_topline" width="120" nowrap="">부서</th>
<td class="table_list2_left_topline" nowrap="" width="15%">{user.d_name}</td>
<th class="table_title2_topline" width="120" nowrap="">직급</th>
<td class="table_list2_left_topline" nowrap="">{user.job_name}</td>

</tr>
<tr>
<th class="" width="120" nowrap="">생년월일</th>
<td class="" nowrap="">
{user.birthdate}</td>
<th class="" width="120" nowrap="">입사일자</th>
<td class="" nowrap="">
{user.start}</td>
<th class="" width="120" nowrap="">퇴사일자</th>
<td class="">
{user.resig}
</td>
</tr>
<tr>
<th class="" width="120" nowrap="">내선번호</th>
<td class="" nowrap="">{user.extension}</td>
<th class="" width="120" nowrap="">휴대폰번호</th>
<td class="" nowrap="">{user.tel}</td>
<th class="" width="120" nowrap="">퇴사사유</th>
<td class="" nowrap="">{user.resig_reason}</td>
</tr>
<tr>
<th class="" width="120" nowrap="">담당업무</th>
<td class="" nowrap="">{user.work_name}</td>
<th class="" width="120" nowrap="">근무형태</th>
<td class="" nowrap="">{user.contract}</td>
<th class="" width="120" nowrap="">현직급 승진일</th>
<td class="" nowrap="">{user.promote_date}</td>
</tr>
<tr>
<th class="" width="120" nowrap="">입사경로</th>
<td class="" colspan="3" nowrap="">{user.howToEnter}</td>
<th class="" width="120" nowrap="">추천인/관계</th>
<td class="" nowrap="">{user.referee}</td>
</tr>
<tr>
<th class="" width="120" nowrap="">주소</th>
<td class="" colspan="5" nowrap="">{user.addr}</td>
</tr>

</tbody></table>
</div>
        </div>


    
        <TabMenu style={{borderRight:"1px solid #ddd", borderLeft:"1px solid #ddd", width:"70%", minWidth:"1200px"}}>
  {/* <li className="submenu">{menuArr[0].name}</li>
  <li className="submenu">{menuArr[1].name}</li>
  <li className="submenu">{menuArr[2].name}</li> */}
  {menuArr.map((el,index) => (
      <Link to ={el.href}><li className={index === currentTab ? "submenu focused" : "submenu" }
      onClick={() => selectMenuHandler(index)}>{el.name}</li></Link>
    ))}
</TabMenu>
<Desc>
<p style={{width:"70%", minWidth:"1200px"}}>{menuArr[currentTab].content}</p>
</Desc> 

       
        </div>
       
<div style={{display:"flex"}}>
<div className="addInfo" style={{width:"100%"}}>

</div>




</div>
</div>


</div>
      </div>
      
  );
};

export default HRCard;