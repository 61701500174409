import { NavLink, Link,useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailNav from "../components/side/MailNav";
import './css/view.css';
import { IoIosArrowDown } from "react-icons/io";
import AddressList from "../components/side/AddressList";
import AutoComplete from "../components/AutoCompleteLine";
import { Tooltip } from "react-tooltip";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "./img/user.png";

axios.defaults.withCredentials = true; 
const initialValues = {
  name:"",
  phone:"",
  email:"",
  address:"",
  company:"",
  job_name:"",
  d_name:"",
  fax:"",
  note:"",
  group_no:"",
  piclink:""
 
};


const AddressView = () => {
  const [values, setValues] = useState(initialValues);
  
  const [showDepartments, SetShowDepartments] = useState(false);
  const [addrs, setAddrs] = useState([]);
  const [department, setDepartment] = useState([]);
  const [sidebar, setSidebar] = useState(false);

  const [value, setValue] = useState("");
  const [viewers, setViewers] = useState([]);
  const [lineList, setLineList] = useState([]);
  const [users, setUsers]=useState([]);
  const [, updateState] = React.useState();
  const location = useLocation();
  //const [shData, setShData]= useState();
  const navigate = useNavigate();


  useEffect(()=>{
    
    axios.get('https://gw.thegmmedical.com:5003/api/check_login')
    .then(function (response){
    //console.log("hey result is!!!!!!!!!!!!", response.data);
    setUsers(response.data[0]);
    
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/view_addr/'+location.state.no)
    .then(function (response){
    //setBoxes(json[0].boxes);
    setValues(response.data[0]);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

   
    
}, []);

  const handleRegisterButton = () => {
    console.log(values);
    if (values.group_no === "") {
      alert("주소록을 선택해주세요.");
      return false;
    } 
    if (values.name === "") {
      alert("이름을 입력해주세요.");
      return false;
    } 
    if (values.phone === "") {
      alert("휴대폰 번호를 입력해주세요.");
      return false;
    } 
    const formData = new FormData();
   /*const data = {
      subject : values.subject,
      form_name : values.form_name,
      form_group : values.form_group,
      in_use : values.in_use,
      cc : ccs,
      recipient : recs,
      expire : values.expire,
      security : values.security,
      form_data : test

    }*/
   

    
   
    axios.post('https://gw.thegmmedical.com:5003/api/addr_add', values)
    .then(function (response){
      console.log(response);
      navigate('/addr_list/'+values.group_no);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
   



  };

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (name==="group" && value==="department"){
      SetShowDepartments(true);
    }else if (name==="group" && value!=="department"){
      SetShowDepartments(false);
      if (name==="group" && value === "group"){
        const item = { id: 1, name: users.name, user_no : users.user_no, email : users.email, check:false  };
        console.log("item...", item);
        var valueArr = viewers.map(function(item){ return "["+item.user_no+"]" });
        var valueArr2 = recs.map(function(item){ return "["+item.user_no+"]" });
        if (valueArr.includes("["+users.user_no+"]")){
          return false;
        }
        else{
          const tempArray = [...recs, item];
          setViewers(tempArray);
        }
        if (valueArr2.includes("["+users.user_no+"]")){
          return false;
        }
        else{
          const tempArray = [...recs, item];
          setRecs(tempArray);
        }
      }
    }
  };

  

  const [admin, setAdmin]=useState("");
  const [admins, setAdmins]=useState([]);
  

  const generateId_ad = () => {
    const highestId = Math.max.apply(Math, admins.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_ad() {
    if (admin !== '') {
      const tempStr = admin.substring(admin.indexOf("<")+1, admin.indexOf(">"));
      const item = { id: generateId_ad(), text: admin, email : tempStr  };
      console.log("item...", item);
      var valueArr = admins.map(function(item){ return item.text });
      if (valueArr.includes(admin)){
        return false;
      }
      else{
        const tempArray = [...admins, item];
        setAdmins(tempArray);
      }

      
    }
    setAdmin('');
  }

  function addAdmins() {
    console.log(admin);
    createNewToDoItem_ad();
  }
  const deleteItem_ad = id => {
    setAdmins(admins.filter(item => item.id !== id));
  };


  const display_admin = admins.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_ad(item.id)}>&times;</span>

    </div>
  ));


  
  const [rec, setRec]=useState("");
  const [recs, setRecs]=useState([]);
  

  const generateId_rec = () => {
    const highestId = Math.max.apply(Math, recs.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_rec() {
    if (rec !== '') {
      const tempStr = rec.substring(rec.indexOf("<")+1, rec.indexOf(">"));
      const item = { id: generateId_rec(), text: rec, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = recs.map(function(item){ return item.text });
      if (valueArr.includes(rec)){
        return false;
      }
      else{
        const tempArray = [...recs, item];
        console.log("dddddddddd...", tempArray);
        setRecs(tempArray);
      }

      
    }
    setRec('');
  }

  function addRecs() {
    var subStr = rec.substring(
      rec.indexOf("[") + 1, 
      rec.lastIndexOf("]")
  );
  const name = rec.substring(rec.indexOf(",")+1, rec.indexOf("<"));

  const tempStr = rec.substring(rec.indexOf("<")+1, rec.indexOf(">"));
      const item = { id: generateId_rec(), name: name, user_no: subStr, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = recs.map(function(item){ return "["+item.user_no+"]" });
      console.log("valueArr is...", valueArr, "["+subStr+"]" );
     

      if (valueArr.includes("["+subStr+"]")){
        console.log("이미 있으셈");
        return false;
      }
      else{
        const tempArray = [...recs, item];
        setRecs(tempArray);
      }

      console.log("viewers ,," , viewers);
      
  
        console.log("item...", item);
        var valueArr2 = viewers.map(function(item){ return "["+item.user_no+"]" });
        console.log("valueArr is...", valueArr, "["+subStr+"]" );
       
  
        if (valueArr2.includes("["+subStr+"]")){
          console.log("이미 있으셈");
          return false;
        }
        else{
          const tempArray = [...viewers, item];
          console.log("tempArray is...", viewers);
          setViewers(tempArray);
        }
  


  }
  const deleteItem_rec = id => {
    setRecs(recs.filter(item => item.id !== id));
  };


  const display_rec = recs.map(item => (
    <div className="admin"><li key={item.id}>
      <span>{item.name} [{item.email}]</span>
    </li>
    <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>&times;</span>

    </div>
  ));

  const [cc, setCc]=useState("");
  const [ccs, setCcs]=useState([]);
  

  const generateId_cc = () => {
    const highestId = Math.max.apply(Math, viewers.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_cc() {
    if (cc !== '') {
      const tempStr = cc.substring(cc.indexOf("<")+1, cc.indexOf(">"));
      const item = { id: generateId_cc(), text: cc, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = ccs.map(function(item){ return item.text });
      if (valueArr.includes(cc)){
        return false;
      }
      else{
        const tempArray = [...ccs, item];
        setCcs(tempArray);
      }

      
    }
    setCc('');
  }

  function addViewers() {
    console.log("viewers ,," , viewers);
    var subStr = value.substring(
      value.indexOf("[") + 1, 
      value.lastIndexOf("]")
  );
  const name = value.substring(value.indexOf(",")+1, value.indexOf("<"));

  const tempStr = value.substring(value.indexOf("<")+1, value.indexOf(">"));
      const item = { id: generateId_cc(), name: name, user_no: subStr, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = viewers.map(function(item){ return "["+item.user_no+"]" });
      console.log("valueArr is...", valueArr, "["+subStr+"]" );
     

      if (valueArr.includes("["+subStr+"]")){
        console.log("이미 있으셈");
        return false;
      }
      else{
        const tempArray = [...viewers, item];
        console.log("tempArray is...", viewers);
        setViewers(tempArray);
      }



    //createNewToDoItem_cc();
  }
  
  const deleteItem_cc= id => {
    setViewers(viewers.filter(item => item.id !== id));
  };


  const display_viewers = viewers.map(item => (
    <div className="admin"><li key={item.id}>
      <span>{item.name} [{item.email}]</span>
    </li>
    <span className="del_btn" onClick={() => deleteItem_cc(item.id)}>&times;</span>

    </div>
  ));

 


  function delContacts(){
    var array = []
    array.push(location.state.no);
    const msg ={
      nos : array
    }
    var result = window.confirm("연락처를 삭제하시겠습니까?");
    if(result){
  
      axios.post('https://gw.thegmmedical.com:5003/api/delcontacts', msg)
      .then(function (response){
        console.log(response);
        console.log(response);
        navigate('/addr_list/'+values.group_no);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  
  }

 

  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <AddressList/>
        </div>
      )}
   
<div className="content-wrap">
    
      
    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
    <div className="side-menu">
    <AddressList/>
    </div>
    <div className="main_content">
  
    

    <div className="btns" style={{display:"flex", alignItems:"start", 
    flexDirection:"column", width:"100%", margin:"0 auto"}}>
            
      <h4>연락처 상세보기 </h4>
          <div style={{background:"#fff", width:"100%"}}>
       
          <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
          

         
          <table className="form_write" style={{margin:"10px 0", width:"100%"}}>

      {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
      
      
      <tr>
<td class="table_title2" nowrap="">
  주소록 그룹<span style={{color:"red", marginLeft:"3px"}}>*</span>
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <span>{values.g_name}</span>
  </div>
  </td>


</tr>

{/*
<tr>
<td class="table_title2" nowrap="">
  명함<span style={{color:"red", marginLeft:"3px"}}>*</span>
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{width:"340px"}}>



{values.filename !=="" && values.filename !== null ? 
<div className="img-wrap img-upload">
  <img for="photo-upload" src={"https://gw.thegmmedical.com/uploads/addr/"+values.no+"/"+values.filename} alt="profile_pic"
  style={{width:"150px", height:"150px"}}/>
</div>
: <div className="img-wrap img-upload">
  <img for="photo-upload" src={user} alt="profile_pic" style={{width:"150px", height:"150px"}}/></div>
}




  </div>
  </td>


</tr>
*/}
<tr>
<td class="table_title2" nowrap="">
  이름<span style={{color:"red", marginLeft:"3px"}}>*</span>
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <span>{values.name}</span>

  </div>
  </td>


</tr>




<tr>
<td class="table_title2" nowrap="">
  휴대폰<span style={{color:"red", marginLeft:"3px"}}>*</span>
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <span>{values.phone}</span>

  </div>
  </td>

 

</tr>

<tr>
<td class="table_title2" nowrap="">
  회사
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <span>{values.company}</span>

  </div>
  </td>


</tr>


<tr>
<td class="table_title2" nowrap="">
  직위
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>

  <span>{values.job_name}</span>


  </div>
  </td>


 
  <td class="table_title2 pc" nowrap="">
    부서
  </td>
  <td class="table_list2_left pc">
  <div className="input-group">
  <span>{values.d_name}</span>

  
  </div>
  </td>

</tr>
<tr className="mobile">
<td class="table_title2" nowrap="">
    부서
  </td>
  <td class="table_list2_left">
  <div className="input-group">
  <span>{values.d_name}</span>

  
  </div>
  </td>
</tr>


<tr>

<td class="table_title2" nowrap="">
  이메일
</td>
<td class="table_list2_left" >
<div className="input-group">
<span>{values.email}</span>

</div>
</td>
</tr>
<tr>

<td class="table_title2" nowrap="">
  fax
</td>
<td class="table_list2_left">
<div className="input-group">
<span>{values.fax}</span>

</div>
</td>
</tr>

<tr>

<td class="table_title2" nowrap="">
  비고
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <span>{values.note}</span>
 
  </div>
  </td>
 

</tr>







    </table>


    </div>

   

    </div>


    

    <div className="button_wrap">
    <Link to ="/addr_u" state={{no: location.state.no}}><button>수정</button></Link>
    <button onClick={()=>delContacts()}>삭제</button>

    {/*</form>*/}
    </div>
    </div>
  </div>
  </div></div>

</div>
  );
};

export default AddressView;
