import React, { useState, useEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";


import "./css/popup.css";
import axios from "axios";
import moment from "moment";
import { VscTrash } from "react-icons/vsc";

function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    const {date, days, content, user_no}= rowsData;
    return (
      <tr key={index}>
       
        <td>
        <input
            type="date"
            value={date}
            onChange={(event) => onValUpdate(index, event)}
            name="date"
            className="form-control"
          />

        </td>
        <td>
          <input
            style={{width:"50px"}}
            type="text"
            value={days}
            onChange={(event) => onValUpdate(index, event)}
            name="days"
            className="form-control"
          />

        </td>
        <td>
          수동
        </td>
        <td>
          <input
          style={{width:"98%", border:"1px solid #ddd"}}
            type="text"
            value={content}
            onChange={(event) => onValUpdate(index, event)}
            name="content"
            className="form-control"
          />
        </td>
        <td>
          
        </td>
        <td>
          
          </td>
        <td style={{display:"none"}}>
          {user_no}
        </td>
        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

const LeaveUserHistory = ({ closePopup, user }) => {
  
  const [data, setData] = useState([]);
  const [rows, initRow] = useState([]);
  const [values, setValues] = useState([]);
  const [userinfo, setUserInfo] = useState([]);

  useEffect(() => {
    console.log("user...", user);
    axios.get("https://gw.thegmmedical.com:5003/api/getuser_leavehistory/"+user).then((response) => {
        console.log("hi", response.data);
        setData(response.data);
      });

      axios.get("https://gw.thegmmedical.com:5003/api/getuserinfo/"+user).then((response) => {
        console.log("hi", response.data);
        setUserInfo(response.data[0]);
      });
  }, []);

  const addRowTable = () => {
    const data = {
      date:'', days:'', content:'', user_no: userinfo.user_no
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    
    const data = [...rows];
    console.log(data, name, value, i);
    data[i][name] = value;
    initRow(data);

    values.arr = rows;

  };

  const saveLeaves  = () =>{
    console.log(values);


    if (values.arr===undefined){
      alert("변경 사항이 없습니다.");
      return;
    }
    const data = {
      leaves: JSON.stringify(values.arr)
     }

     console.log(data);
     axios.post('https://gw.thegmmedical.com:5003/api/add_manual_leave', data)
     .then(function (response){
       alert("적용되었습니다.");
       window.location.reload(true);
  
     })
     .catch(function (error){
       console.log(error);
     })
     .then(function (){
       //always executed
     });


  }

  const deleteLeave = (no) => {
    axios.get('https://gw.thegmmedical.com:5003/api/del_manual_leave/' + no)
     .then(function (response){
       alert("삭제되었습니다.");
       window.location.reload(true);
  
     })
     .catch(function (error){
       console.log(error);
     })
     .then(function (){
       //always executed
     });
  }

  
  const [editIndex, setEditIndex]=useState();

  const [edit, setEdit]=useState();
  const [createDate,setEditCreateDate] = useState(0);
  const [editDate, setEditDate]=useState();
  const [editDays, setEditDays]=useState();
  const [editContent, setEditContent]=useState();
  const [editApproval, setEditApproval]=useState();
  const saveEdits = () => {
    const msg ={
      no : edit,
      date : editDate,
      days : editDays,
      content : editContent
    }
    axios.post('https://gw.thegmmedical.com:5003/api/edit_manual_leave', msg)
     .then(function (response){
       alert("수정되었습니다.");
       //window.location.reload(true);
       const temp = data;
       temp[editIndex]['start_date'] = editDate;
       temp[editIndex]['end_date'] = editDate;

       temp[editIndex]['vtype'] = editDays;
       temp[editIndex]['reason'] = editContent;
    setData([...temp]);
    setEdit();
  
     })
     .catch(function (error){
       console.log(error);
     })
     .then(function (){
       //always executed
     });
  }

  

  return (
    <div className="popup">
      <div className="inner" style={{width:"80%", height:"auto", maxHeight:"500px", position:"relative",
      left:"50%", transform:"translateX(-50%) translateY(-50%)", top:"50%", overflowY:"scroll"}}>

      <div className="btns" style={{justifyContent:"space-between", alignItems:"center"}}>
        <h3>{userinfo.name} {userinfo.job_name} ({userinfo.d_name}) - 연차 사용 리스트</h3>
        <div className="btn" onClick={()=>{addRowTable();setEdit(true)}}>수동 추가</div>

      </div>
      <table width="100%" cellspacing="0" cellpadding="0" border="0" bordercolor="black" className="responsive leave_history"
      style={{tableLayout:"fixed"}}
      >
    	<tbody>
           
            <tr height="23">
            <th nowrap="" class="table_title" style={{width:"17%"}}>휴가일자</th>
                
                <th nowrap="" class="table_title" style={{width:"20px"}}>차감 일수</th>
                <th nowrap="" class="table_title" style={{width:"10px"}}>결재상태</th>
                <th nowrap="" class="table_title" style={{width:"100px"}}>내용</th>

                <th nowrap="" class="table_title" style={{width:"30px"}}>등록일</th>
                <th nowrap="" class="table_title_rno" style={{width:"10px"}}>수정</th>
                
                <th nowrap="" class="table_title_rno" style={{width:"10px"}}>삭제</th>

            </tr> 
            {data.map((element,index)=>(
                 (edit === element.no ? 
                  
                  
                    <tr key={index}>
       
                    <td data-label="휴가일자">
                    <input
                        type="date"
                        value={editDate}
                        onChange={(event) => setEditDate(event.target.value)}
                        name="date"
                        className="form-control"
                      />
            
                    </td>
                    <td data-label="차감 일수">
                      <input
                        style={{width:"50px"}}
                        type="text"
                        value={editDays}
                        onChange={(event) => setEditDays(event.target.value)}
                        name="days"
                        className="form-control"
                      />
            
                    </td>
                    <td data-label="결재상태">
                      {editApproval}
                    </td>
                    <td data-label="내용">
                      <input
                      style={{width:"98%", border:"1px solid #ddd"}}
                        type="text"
                        value={editContent}
                        onChange={(event) => setEditContent(event.target.value)}
                        name="content"
                        className="form-control"
                      />
                    </td>
                    <td data-label="등록일">
                      {createDate !== null && (
                      moment(createDate).format("yyyy-MM-DD")
                      )}
                    
                    </td>
                 
                    <td style={{display:"flex"}} data-label="수정">

                    <button style={{width:"60px", padding:"5px"}}
                        className="btn"
                        onClick={() => saveEdits()}
                      >
                        저장
                      </button>

                    </td>
                    <td data-label="삭제">
                      
                    </td>
                  </tr>
                 :  
                
                 <tr height="23">
                 <td nowrap="" class="table_title" data-label="휴가일자">{moment(element.start_date).format('YYYY-MM-DD')} ~ {moment(element.end_date).format('YYYY-MM-DD')}</td>
                 
                 <td nowrap="" class="table_title" data-label="차감 일수">{element.vtype * element.days}
                 {(element.vtype == "0.5" && element.vrange == "2") &&(<span> (오후)</span>)}
                 {(element.vtype == "0.5" && element.vrange == "1") &&(<span> (오전)</span>)}

                 </td>
                 <td nowrap="" class="table_title" data-label="결재상태">{element.approval}</td>
                 <td nowrap="" class="table_title_rno" data-label="내용">{element.reason}</td>
                 <td nowrap="" class="table_title_rno" data-label="등록일">
                  {element.create_date !==null && 
                  moment(element.create_date).format("yyyy-MM-DD")
                  }
                  </td>

                 <td nowrap="" class="table_title_rno" style={{display:"flex"}} data-label="수정">
                 <button style={{width:"60px", padding:"5px"}} onClick={()=>{setEdit(element.no);setEditCreateDate(element.create_date);
                setEditDate(moment(element.start_date).format('YYYY-MM-DD')); setEditDays(element.vtype); setEditContent(element.reason); setEditApproval(element.approval); setEditIndex(index)
                }}>수정</button></td>

                 <td data-label="삭제">
          <VscTrash style={{fontSize:"16px", color:"rgb(151 140 140)", cursor:"pointer", padding:"2px 0 0 0"}} className="trash" id={index} onClick={() => deleteLeave(element.no)}/>
       </td>
             </tr> 
                  )
            ))}
            {(data.length===0 && edit==false) && (
              <tr>
                <td colSpan={5} style={{padding:"20px 0"}}>휴가 기록이 없습니다.</td>
              </tr>
            )}
            
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />




    </tbody></table>
    <button style={{marginTop:"20px"}} className="" onClick={()=>saveLeaves()}>저장</button>

        <button style={{marginTop:"20px"}} className="" onClick={closePopup}>닫기</button>
      </div>
      
    </div>
  );
};

export default LeaveUserHistory;