import { NavLink, Link,useParams, useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailNav from "../components/side/MailNav";
import './css/form.css';
import { SiMicrosoftexcel } from "react-icons/si";
import { MdOutlineNoteAlt } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";

import {IoEyeOffOutline} from "react-icons/io5";
import DocList from "../components/side/DocList";

axios.defaults.withCredentials = true; 

const Form = () => {
  const [sidebar, setSidebar] = useState(false);
    const [forms, setForms]=useState([]);
    const [nullForms, setNullForms]=useState([]);
    const [CheckList, setCheckList]=useState([]);
    const [bookmarks, setBookmarks]=useState([]);
    const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...CheckList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(CheckList.filter((checkedId) => checkedId !== id));
        }
     }
     function selectAll()  {
        const checkboxes 
             = document.getElementsByName('animal');
        const all 
             = document.getElementsByName('animalall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }
      const delbookmark = (form_no) =>{
        var result = window.confirm("즐겨찾기 게시판에서 삭제하시겠습니까?");
      if(result){
        
        const data ={
          num : form_no,
          table : "form"

        }
        console.log(data);
        axios.post('https://gw.thegmmedical.com:5003/api/delete_bookmark', data)
      .then(function (response){
        console.log(response);
        window.location.reload(true);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
      }

      const addbookmark = (form_no, form_name) => {
        var result = window.confirm("즐겨찾기 게시판에 추가하시겠습니까?");
        if(result){
          const data ={
            name: form_name,
            num : form_no,
            table : "form"
          }
          console.log(data);
          axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark', data)
        .then(function (response){
          console.log(response);
          window.location.reload(true);
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
        }
      };
    useEffect(()=>{

      axios.get('https://gw.thegmmedical.com:5003/api/get_bookmark/form',{})
      .then(function (response){
      //setBoxes(json[0].boxes);
        var form_nos = response.data.map(function(item) {
          return item['no'];
        })
        console.log(form_nos);
        setBookmarks(form_nos);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
      //always executed
      });
        axios.get('https://gw.thegmmedical.com:5003/api/getforms')
        .then(function (response){
        console.log("hey result is!!!!!!!!!!!!", response.data);
        const groupBy = function (data, key) {
          return data.reduce(function (carry, el) {
            var group = el[key];
     
            if (carry[group] === undefined) {
              carry[group] = []
            }
     
            carry[group].push(el)
            return carry
          }, {})
        }
        const groupByData = groupBy(response.data, "fgroup_name");
        console.log("groupbydata", groupByData);
        var temp=[];
        Object.entries(groupByData).map((entrie, idx) => {
          return temp.push(entrie);
        });
        console.log("temp", temp);
        console.log(temp);



        setForms(temp);

      
  
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });

        axios.get('https://gw.thegmmedical.com:5003/api/get_nullforms')
        .then(function (response){
        console.log("hey result is!!!!!!!!!!!!", response.data);
        setNullForms(response.data);

        //setLine(response.data[1]);
        
        
        })
        .catch(function (error){
        console.log(error);
        })
        .then(function (){
        //always executed
        });


        

    }, []);
    function addGroup(){
        var x;
        var name=prompt("추가할 양식분류 이름을 입력해주세요","");
        if (name!=null){
            const data =  {
                group_name: name
            }
            axios.post(
                "https://gw.thegmmedical.com:5003/api/add_formgroup",data
              ).then(( res ) => {
                console.log(res);
                console.log("보냇긔??",res);
                window.location.reload(true);
              });
       }
    }
    function editGroup(){
        var x;
        var name=prompt("수정할 양식분류 이름을 입력해주세요","");
        if (name!=null){
            const data =  {
                group_name: name,
                original_no : CheckList[0]
            }
            axios.post(
                "https://gw.thegmmedical.com:5003/api/edit_formgroup",data
              ).then(( res ) => {
                console.log(res);
                console.log("보냇긔??",res);
                window.location.reload(true);
              });
       }
    }
    function delGroup(){
        console.log(CheckList);
        var array = []
        var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
    
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].value!=="selectall"){
          array.push(checkboxes[i].value)
          }
        }
        console.log(array);
        if (array[0].value==="selectall"){
          array.shift();
        }
        const msg ={
          nos : array,
        }
        var result = window.confirm("문서 양식을 정말 삭제하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/delete_formgroup', msg)
        .then(function (response){
          console.log(response);
          window.location.reload(true);
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
     
      }
        
      }
  return (
    <div className="wrapper">

<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DocList/>
        </div>
      )}
    <div className="content-wrap">
    <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>
    <div className="side-menu">
      <DocList/>
    </div>
   

   <div className="doc_main main_content">

    <h4>결재 양식 관리</h4>
   <div className="btns" style={{display:"flex", justifyContent:"space-between", margin:"0"}}>
              <div className="left">

              
              <button className="btn" onClick={()=>addGroup()}>분류추가</button>

              <button className="btn" onClick={()=>delGroup()}>분류삭제</button>
              {CheckList.length>1 ? (<button className="btn" disabled onClick={() => editGroup()}>분류수정</button> 
                ): (<button className="btn" onClick={() => editGroup()}>분류수정</button> 
                )}

              <Link to="/form_add"><button className="btn">양식추가</button></Link>

              </div>


            </div>


<div className="row">
<table width="100%" border="0" cellspacing="0" cellpadding="0" className="form_table">
<thead>	
    <tr>
    <th class="form_title"><input type='checkbox'
       name='animalall' 
       value='selectall'
       onClick={selectAll}/><span>양식분류명</span></th>	
    <th class="table_title_topline2px"><span>문서양식명</span></th>
    </tr>

</thead>
<tbody>

    {forms.map((element,index) =>(
        <tr><td className="title">
         <input type='checkbox' 
        name='animal' 
        value={element[0]
        }
        onChange={(e) => onChangeEach(e, element[0])} checked={CheckList.includes(element[0])}/> 

            [{element[0]}]  
        </td>
<td valign="top" nowrap="">
    <ul>{/** 
      {element.fgroup_name === "공통" && (
        element.list !==undefined &&(
          element.list.map((ele,idx)=>(
           ele.type === "html" ? (            
            <div style={{display:"flex", alignItems:"center"}}>
            { bookmarks.includes(ele.form_no) ? 
            (<span onClick={()=>delbookmark(ele.form_no)}><AiFillStar/></span> ): (<span onClick={()=>addbookmark(ele.form_no, ele.form_name)} className="notbookmark"><AiFillStar/></span>)}
            
            <Link to={"/form"} className="form" state={{form_no: ele.form_no}}> 
            <li><MdOutlineNoteAlt/>{ele.form_no}. {ele.form_name} <span> - {ele.form_desc}</span></li></Link>
            </div>
             ): (
              <div style={{display:"flex", alignItems:"center"}}>
                { bookmarks.includes(ele.form_no) ? 
            (<span onClick={()=>delbookmark(ele.form_no)}><AiFillStar/></span> ): (<span onClick={()=>addbookmark(ele.form_no, ele.form_name)} className="notbookmark"><AiFillStar/></span>)}
            <Link to={"/form"} className="form" state={{form_no: ele.form_no}}><li><SiMicrosoftexcel/>{ele.form_no}. {ele.form_name} <span> - {ele.form_desc}</span></li></Link>
               </div>
             )
         ))
     )
      )} */}

      
        {element[1] !==undefined &&(
            element[1].map((ele,idx)=>(
              ele.in_use!=="use" ? (         
                <div style={{display:"flex", alignItems:"center"}}>   
                { bookmarks.includes(ele.form_no) ? 
            (<span onClick={()=>delbookmark(ele.form_no)}><AiFillStar/></span> ): (<span onClick={()=>addbookmark(ele.form_no, ele.form_name)} className="notbookmark"><AiFillStar/></span>)}
            <Link to={"/form"} className="form" state={{form_no: ele.form_no}}><li><SiMicrosoftexcel/>{ele.form_no}. {ele.form_name} <span> - {ele.form_desc}</span><IoEyeOffOutline style={{color:"#616161"}}/></li></Link>
              </div>
                ): (
                  <div style={{display:"flex", alignItems:"center"}}>   
                { bookmarks.includes(ele.form_no) ? 
            (<span onClick={()=>delbookmark(ele.form_no)}><AiFillStar/></span> ): (<span onClick={()=>addbookmark(ele.form_no, ele.form_name)} className="notbookmark"><AiFillStar/></span>)}
            <Link to={"/form"} className="form" state={{form_no: ele.form_no}}><li><SiMicrosoftexcel/>{ele.form_no}. {ele.form_name} <span> - {ele.form_desc}</span></li></Link>
                  </div>
                )
            ))
        )}
        {(element[1] ===undefined || element[1].length === 0) &&(
            <li style={{color:"#ddd"}}>문서 양식이 없습니다.</li>
        )}
       
        
    </ul>

</td>

</tr>
    ))}
    {nullForms.length>0 && (
      <tr><td className="title">
         분류 없음
        </td>
<td align="center" valign="top" nowrap="">
    <ul>
    {nullForms.map((element,index) =>(
      <div style={{display:"flex", alignItems:"center"}}>   
      { bookmarks.includes(element.form_no) ? 
            (<span onClick={()=>delbookmark(element.form_no)}><AiFillStar/></span> ): (<span onClick={()=>addbookmark(element.form_no, element.form_name)} className="notbookmark"><AiFillStar/></span>)}
            <Link to={"/form"} className="form" state={{form_no: element.form_no}}><li>{element.form_no}. {element.form_name}</li></Link>
            </div>
            ))}
      
       
        
    </ul>

</td>

</tr>
    )}
        
    
    


</tbody></table>

</div>



   
      </div>
   </div>
    </div>
</div>
  );
};

export default Form;
