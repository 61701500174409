import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";

import axios from "axios";

import * as XLSX from "xlsx";
import {GiHamburgerMenu} from "react-icons/gi";
import DraftsList from "../components/CSDraftsList";
import BoardList from "../components/side/BoardList";
import CreatableSelect, { useCreatable } from 'react-select/creatable';

import "./css/write.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  user:'',
  userF:'',
  addr:'',
  contact:'',
  brand:'',
  equipment:'',
  model:'',
  sn:'',
  delivery_date:'',
  mfr_date:'',
  contract:'',
  contract_end:'',
  kahp:'',
  olym:'',
  note:'',
  category:'',
  update_date:''
};

var freePaidOptions = [{value:"무상", label:"무상", name:"free_paid"}, {value:"유상", label:"유상", name:"free_paid"}, {value:"폐기", label:"폐기", name:"free_paid"}, {value:"반송", label:"반송", name:"free_paid"}];
var rankOptions = [{value:"A", label:"A", name:"rank"}, {value:"B", label:"B", name:"rank"}, {value:"C", label:"C", name:"rank"}, {value:"D1", label:"D1", name:"rank"}, {value:"D", label:"D", name:"rank"}, {value:"E", label:"E", name:"rank"}];
var statusOptions = [
  {value:"점검중", label:"점검중", name:"status"}, 
  {value:"견적발행", label:"견적발행", name:"status"}, 
  {value:"견적제출", label:"견적제출", name:"status"}, 
  {value:"수리중", label:"수리중", name:"status"}, 
  {value:"수리취소", label:"수리취소", name:"status"}, 
  {value:"출고대기", label:"출고대기", name:"status"}, 
  {value:"계산서", label:"계산서", name:"status"}, 
  {value:"종료", label:"종료", name:"status"}, 
  {value:"센터대기", label:"센터대기", name:"status"}
]
export default function BoardWrite() {
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [list, setList] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [jsonData, setJsonData] = useState();
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);

  const [values, setValues] = useState(initialValues);
  const [modelOptions, setModelOptions] = useState([]);

  
  useEffect(() => {
    axios.get("https://gw.thegmmedical.com:5003/api/get_as_status/delivery").then((response) => {
  // setNotice(response.data);
   setfilterData(response.data);
   if (response.data.length === 0) {
     setEmpty(true);
   } else {
     setEmpty(false);
     
   }
  });
  var options=[];
  axios.get("https://gw.thegmmedical.com:5003/api/get_models")
  .then((response) => {
     for (let i=0;i<response.data.length;i++){

      let obj = {value: response.data[i].model, label:response.data[i].model, name:"model", brand:response.data[i].brand, equipment:response.data[i].equipment};
      options.push(obj);
     }
     setModelOptions(options);
  });


 
}, []);

  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };

  const toggleShow = () => {
    setShow(!show);
};

  const { state } = useLocation();
  useEffect(() => {
  
}, []);
 
  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

 

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };





 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {
  
 
   

   axios.post("https://gw.thegmmedical.com:5003/api/as_insert/delivery", values)
   .then(({ data }) => {
      console.log("what is this");
      if (draftNo !== undefined){
        axios.get("https://gw.thegmmedical.com:5003/api/delete_draft/delivery_draft/"+draftNo)
        .then(({ data }) => {
          console.log("what is this");
        });
      }
      navigate("/as_status");
   });


  };

  const handleDraftButton = () => {
 
    axios.post("http://gw.thegmmedical.com:5003/api/draft_insert/delivery_draft", values)
   .then(({ data }) => {
      console.log("what is this");
      alert("임시 저장되었습니다.");
    });


  };
  
  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const downloadEmployeeData = () => {
    fetch('https://gw.thegmmedical.com:5003/api/download')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = '이호MH호텔_셀렉 A컷.zip';
                    a.click();
                });
                //window.location.href = response.url;
        });
  }
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
  window.addEventListener('message', (event) => {
    if (event.data["msg"] !== undefined){
      var files = event.data["msg"];
      for (let i= 0;i<files.length;i++){
        string+='<a href = "https://gw.thegmmedical.com:5003/get/'+ files[i].name+'">'+files[i].name+'</a>'

      }
      console.log(string);
      var content = editorRef.current?.getInstance().getHTML();
      string+=content;
      editorRef.current?.getInstance().setHTML(string);
    }
 
  });

  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const handleInputChange = (e) => {
    var name, value;
    if (e.target === undefined){
      name= e.name;
      value = e.value;
    }else{
      name= e.target.name;
      value = e.target.value;
    }
    
    if (name === "model"){
      values.brand = e.brand;
      values.equipment = e.equipment;
    }
    setValues({
      ...values,
      [name]: value,
    });
  };

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white', minHeight: "unset", height:"18px", width:"100%", border:"none",
        display:"flex", alignItems:"center", boxShadow:"none", justifyContent:"space-between" }),
    
    placeholder : styles => ({
      ...styles,
      marginTop:"-15px"
    }),
    singleValue: styles => ({
      ...styles,
      marginTop:"-15px"
    }),
    Input: styles => ({
      ...styles,
      width:"200px",
      top:"-15px"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      backgroundColor:"transparent"
    }),
    IndicatorsContainer: styles => ({
      ...styles,
      marginTop:"-15px",
      width:"200px",
    }),
    menu: styles => ({
      ...styles,
      width:"200px"
      
    }),
    menuList: styles => ({
      ...styles,
      width:"200px"
      
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#000',
        width:"200px",
        backgroundColor: isFocused ? '#ccf2ff' : 'default',
        
        cursor: isDisabled ? 'not-allowed' : 'default',
        /*'&:focus': {
          backgroundColor:"#ddd"
        },*/
      };
    }
  };



  async function handleFileAsync(e) {

    const file = e.target.files[0];
    const exceldata = await file.arrayBuffer();
          
    const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});
  
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
      //console.log(htmlData);
  
    const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
      header:1,
      defval:"",
      raw: false,
      blankrows: false
    })
    
    var sheetnames = workbook.SheetNames;
 
    jsondata.shift();
    setJsonData(jsondata);
  
    console.log("data",jsondata);

  
    /*for (let i = 0;i<jsondata.length;i++){
      for (let j=0;j<jsondata[i].length;j++){   
      }
    }*/
  }
  function importExcel(){

    const data ={
      tablename : "delivery",
      header: "user, userF, addr, contact, brand, equipment, model, sn, delivery_date, mfr_date, contract, contract_end, kahp, olym, note, category, update_date",
      arr : jsonData
    }
  
    axios.post('https://gw.thegmmedical.com:5003/api/add_excel_data', data)
      .then(function (response){
        console.log(response);
        navigate("/delivery");
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
  }

  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
     <div className="content-wrap">
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
      <div className="side-menu">
      <BoardList changeBoard={(value)=>{console.log(value);}} changeNotice={(value)=>{console.log(value);}}/>
      </div>
      <div className="main_content cs_board">
      {show ? (
    <DraftsList onChange={(value) => {setValues(value);setDraftNo(value.no)}} closePopup={() => toggleShow()}/>
    ) : null}
      

      <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              <div className="left">

              
              <button className="btn" onClick={handleRegisterButton} style={{marginRight:"10px"}}>등록</button>

              <button className="btn" onClick={handleDraftButton}>임시저장</button>

              <input type="file" id="input_dom_element" onChange={handleFileAsync}/>

<button onClick={()=>importExcel()}>Import</button>




              </div>

              <div className="right">
                <button onClick={() => {setShow(!show);}}>임시저장글</button> 
              </div>
            </div>

            <div style={{width:"100%", margin:"0 auto"}}>
            <div className="as_status">

            <table className="write_table responsive" style={{ margin:"10px 0", width:"100%"}}>

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  <tr>
  <td class="table_title2" nowrap="">
    사용처
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="text" name="user" className="formInput" placeholder="" value={values.user}
            onChange={handleInputChange}></input>
    </div>
    </td>

    <td class="table_title2" nowrap="">
    사용처(F)
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="text" name="userF" className="formInput" value={values.userF}
            onChange={handleInputChange}></input>
    </div>
    </td>

  </tr>
  <tr>

  <td class="table_title2" nowrap="">
    주소
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex"}}>
    <input type="text" name="addr" className="formInput" value={values.addr}
            onChange={handleInputChange}></input> 
    </div>
    </td>

    <td class="table_title2" nowrap="">
    연락처
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="contact" className="formInput" value={values.contact}
            onChange={handleInputChange}></input> 
    </div>
    </td>

 
  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    제조
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="brand" className="formInput" value={values.brand}
            onChange={handleInputChange}></input> 
    </div>
    </td>

    <td class="table_title2" nowrap="">
    장비명
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="equipment" className="formInput" value={values.equipment}
            onChange={handleInputChange}></input> 
    </div>
    </td>

  
  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    모델명
  </td>
    <td class="table_list2_left">
    <div className="input-select" style={{display:"flex", flexShrink:"0"}}>
    <CreatableSelect options={modelOptions}
styles={colourStyles} Clearable={false} name="model" onChange={handleInputChange}
placeholder={'모델을 선택하세요'}
/>
    </div>
    </td>

    <td class="table_title2" nowrap="">
    제조번호
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="sn" className="formInput" value={values.sn}
            onChange={handleInputChange}></input> 
    </div>
    </td>


  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    납품일자
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="date" name="delivery_date" className="formInput" value={values.delivery_date}
            onChange={handleInputChange}></input> 
    </div>
    </td>

    <td class="table_title2" nowrap="">
    제조일자
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="date" name="mfr_date" className="formInput" value={values.mfr_date}
            onChange={handleInputChange}></input> 


    </div>
    </td>

  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    계약
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="contract" className="formInput" value={values.contract}
            onChange={handleInputChange}></input> 
    </div>
    </td>

    <td class="table_title2" nowrap="">
    계약종료
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="contract_end" className="formInput" value={values.contract_end}
            onChange={handleInputChange}></input> 
    </div>
    </td>

  </tr>
  <tr>
  <td class="table_title2" nowrap="">
    KAHP
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="kahp" className="formInput" value={values.kahp}
            onChange={handleInputChange}></input> 
    </div>
    </td>

    <td class="table_title2" nowrap="">
    OLYM
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="olym" className="formInput" value={values.olym}
            onChange={handleInputChange}></input> 
    </div>
    </td>

   

  </tr>


  <tr>
  <td class="table_title2" nowrap="">
    비고
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="note" className="formInput" value={values.note}
            onChange={handleInputChange}></input> 
    </div>
    </td>

    <td class="table_title2" nowrap="">
    구매/판매구분
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="category" className="formInput" value={values.category}
            onChange={handleInputChange}></input> 
    </div>
    </td>


  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    UPDATE
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <input type="text" name="update_date" className="formInput" value={values.update_date}
            onChange={handleInputChange}></input> 
    </div>
    </td>



  </tr>

 
{list.length>0&&(
  <tr>

  <td class="table_title2" nowrap="">
    문서 분류
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    <select name="name" className="formInput" value={option || ''}
                                    onChange={selectHandle}>
    {list.map((element,index)=><option value={element.name}>{element.name}</option>)}  
    </select> 
    </div>
    </td>
  </tr>
)}
  

  
 
      </table>


      <div className="button_wrap">
      <button className="submit" onClick={handleRegisterButton}>등록</button>
      {/*</form>*/}
      </div>
      </div>

    <div className="as_list">
      <h4>최근 10개 장비납품현황</h4>


      <table class="delivery table-hover bd-b">
									<thead class="thead-dark">
									<tr class="tx-center">
                    <th>사용처</th>
                    <th>사용처(F)</th>
                    <th>주소</th>
                    <th>연락처</th>
                    <th>제조</th>
                    <th>장비명</th>
                    <th>모델명</th>
                    <th>제조번호</th>
                    <th>납품일자</th>
                    <th>제조일자</th>
                    <th>계약</th>
                    <th>계약종료</th>
                    <th>KAHP</th>
                    <th>OLYM</th>
                    <th>비고</th>
                    <th>구매/판매구문</th>
                    <th>UPDATE</th>
                
									</tr>
									</thead>
									<tbody>
                 
                                    {isEmpty && (
                                  <tr style={{ textAlign: "center" }}>
                                    <td colSpan={40}>게시물이 없습니다.</td>
                                  </tr>
                                )}
                                {filterData
                                  .slice(0, 9)
                                  .map((element, index) => (
                                      <tr key={element.no}>
                                    
                                        

                                        <td>
                                        {element.user}
                                        </td>
                                      
                                        <td>
                                        {element.userF}
                                        </td>
                                        <td>
                                        {element.addr}
                                        </td>
                                        <td>
                                        {element.contact}
                                        </td>
                                        <td>
                                        {element.brand}
                                        </td>
                                        <td>
                                        {element.equipment}
                                        </td>
                                        <td>
                                        {element.model}
                                        </td>
                                        <td>
                                        {element.sn}
                                        </td>
                                        <td>
                                        {element.delivery_date}
                                        </td>
                                        <td>
                                        {element.mfr_date}
                                        </td>

                                        <td>
                                        {element.contract}
                                        </td>
                                        <td>
                                        {element.contract_end}
                                        </td>
                                        <td>
                                        {element.kahp}
                                        </td>
                                        <td>
                                        {element.olym}
                                        </td>

                                        <td>
                                        {element.note}
                                        </td>
                                        <td>
                                        {element.category}
                                        </td>
                                        <td>
                                        {element.update_date}
                                        </td>
                                       
                                      </tr>)
                                     
                                    
                                  )}

									</tbody>
								</table>
          </div>
      </div>


      
     <div id="response">{links}</div>
 
      
      </div>
    </div>
    </div>
    </div>
  );
}
