
import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import Popup from "../components/Popup";
import axios from "axios";
import "./css/adminUsers.css";
import Pagination from "./Pagination";
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";

const AdminPassword = () => {
    const [cnt, setCnt] = useState(0);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [showCreate, setCreate] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [sidebar, setSidebar] = useState(false);
    const [filterData, setFilterData] = useState([]);

    
  
    const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const offset = (page - 1) * limit;

    const togglePopup = () => {
        setPopup(!showPopup);
        

    };
    const toggleCreate = () => {
      setCreate(!showCreate);
      

  };
    const updateUser = () => {
      console.log("checklist...", checkList);
        for (var i=0;i<data.length;i++){
          if (data[i].email === checkList[0]){
            setSelected(data[i]);
          }
        }
      setPopup(!showPopup);
      
    };
    const deleteLog = () => {
      
      var result = window.confirm("모든 로그인 오류 기록을 삭제하고 차단 해제 하겠습니까?");
      if(result){
        axios.get('https://gw.thegmmedical.com:5003/api/del_pwerror')
        .then(function (response){
          console.log(response);
          window.location.reload(true);
          
          
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const stopUser = () => {
      console.log(checkList);
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 삭제하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/stopUsers', data)
        .then(function (response){
          console.log(response);
          getUser();
     
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const activeUsers = () => {

      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked');
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log("안녕", array);

      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }

      var result = window.confirm("선택된 사용자를 차단 해제합니다");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/unblock_users',data)
        .then(function (response){
          console.log(response);
          //getUser();
          alert("차단이 해제되었습니다.");
          window.location.reload(true);
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    function getUser(){
      fetch("https://gw.thegmmedical.com:5003/api/get_mail_size/", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("json", json);
        setData(json);
        setFilterData(json);
        
      })
      .catch(() => {});
    
    }
    useEffect(() => {
       
      getUser();

      
        console.log(new Date().getTime());
    
      
      }, []);
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('user');
        const all 
             = document.getElementsByName('userall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...checkList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(checkList.filter((checkedId) => checkedId !== id));
        }
        
     }

     const saveSetting  = (no) => {
      var result = window.confirm("해당 사용자의 용량 제한 설정을 저장합니다.");
      if(result){

        let temp = filterData;
        const msg = {
          user_no : temp[no].userNo,
          size_limit :temp[no].size_limit,
          limit_per_day : temp[no].limit_per_day
        }
        console.log("msg...", msg);
        axios.post('https://gw.thegmmedical.com:5003/api/save_mail_size/', msg)
        .then(function (response){
          console.log(response);
          //getUser();
          alert("저장되었습니다");
          window.location.reload(true);
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
     }

     function formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0'
  
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k))
  
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents" style={{maxWidth:"1600px", background:"rgb(247,247,247)",overflow:"auto"}}>
        <div className="header_txt">
        <h3>이메일 용량 관리</h3>
        </div>
       

        <div className="main_contents_wrapper" style={{width:"100%"}}>
         

{showCreate ? (
  <Popup text="Title" closePopup={() => {toggleCreate();getUser();}} />
) : null}           

{showPopup ? (
  <Popup member={selected} text="Title" closePopup={() => {togglePopup();getUser();}} />
) : null}

<div className="table_wrap">
<table class="responsive">
  <thead>
    <tr>
 
      <th>이름</th>
      <th>부서</th>
      <th>직위</th>
      <th>이메일</th>
      <th>현재 사용량</th>
      <th>크기제한</th>
      <th>일일 발송 메일 수</th>

      <th>기능</th>

    </tr>
  </thead>
  <tbody>
  
  { filterData.slice(offset, offset + limit).map((element, index) => 
    <tr key={index}>
       

      
    <td data-label="이름">{element.name}</td>
    <td data-label="부서">{element.d_name}</td>
    <td data-label="직위">{element.job_name}</td>
    <td data-label="이메일">{element.email}</td>
    <td data-label="현재 사용량">{element.sum !== null ? <span>{formatBytes(element.sum)}</span> : <span>0 bytes</span>  }</td>
    <td data-label="크기제한">
    <input type="text" value={(element.size_limit) / 1024 / 1024 / 1024} className="size" onChange={(e)=>{
      let temp = data;
      temp[offset+index].size_limit = e.target.value * 1024 * 1024 * 1024;
      console.log(temp[offset+index].name);
      setFilterData([...temp]);
    }} style={{width:"100px", marginRight:"10px"}}></input>
    GB
    </td>
    <td data-label="일일 발송 메일 수">

    <input type="text" value={element.limit_per_day} className="size" onChange={(e)=>{
      let temp = data;
      temp[offset+index].limit_per_day = e.target.value;
      console.log(temp[offset+index].name);
      setFilterData([...temp]);
    }} style={{width:"100px", marginRight:"10px"}}></input>
    건
    </td>


    <td data-label="기능">
      <button onClick={()=>saveSetting(offset+index)}
      style={{background:"#fff", border:"1px solid #ddd"}}>저장</button></td>

    

    </tr>) }
   
    {filterData.length === 0 && (<tr><td colSpan={11} style={{padding:"50px 0"}}>실패 기록이 없습니다.</td></tr>)}
  </tbody>
</table>
</div>
        </div>
        {data.length >10 && (
        <Pagination
                        postsPerPage={limit}
                        totalPosts={data.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>

        )}
     </div>
    
    </div>

  );
};

export default AdminPassword;
