import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import BoardList from "../components/side/BoardList";
import axios from "axios";
import {GiHamburgerMenu} from "react-icons/gi";


//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  order_client: "",
  order_date: "",
  issuer:"",
  issuer_contact: "",
  hospital_name: "",
  addr:"",
  pic:"",
  pic_contact:0,
  delivery:""
};


function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {model, defect_code, description, note}= rowsData;
    return (
      <tr key={index}>
       
        <td style={{width:"15%"}}>
          <input
            type="text"
            value={model}
            onChange={(event) => onValUpdate(index, event)}
            name="model"
            className="form-control"
            style={{height:"70px", width:"98.5%"}}

          />
        </td>
        <td style={{width:"15%"}}>
          <input
            type="text"
            value={defect_code}
            onChange={(event) => onValUpdate(index, event)}
            name="defect_code"
            className="form-control"
          />
        </td>
        <td style={{height:"70px"}}>
          <textarea
            type="text"
            value={description}
            onChange={(event) => onValUpdate(index, event)}
            name="description"
            className="form-control"
            style={{height:"70px", width:"98.5%"}}
          />
        </td>
        <td>
          <input
            type="text"
            value={note}
            onChange={(event) => onValUpdate(index, event)}
            name="note"
            className="form-control"
            
          />
        </td>

 

        <td style={{width:"5%"}}>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

const Order = ({onChange}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

  
  
  const { state } = useLocation();






  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    values.arr = rows;
    onChange(values);

  };
 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let data = state.data;
    
    axios.get('http://gw.thegmmedical.com:5003/api/get_inspect_results/'+data.no)
    .then(function (response){
      console.log("orders...........", response.data);
      let temp = response.data;
      if (response.data.length<10){
        let len = response.data.length;
        while (10-len>0){
        temp.push({model:'', defect_code:'',description:'', note:''});
        len++;
        }
      }
      data.arr = temp;
      setValues(data);
      initRow(temp);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
    
    
  }, []);


  const addRowTable = () => {
    const data = {
        model:'', defect_code:'',description:'', note:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    
    const data = [...rows];
    console.log(data, name, value, i);
    data[i][name] = value;
    initRow(data);

    values.arr = rows;
    onChange(values);

  };


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });

  const handleRegisterButton = () => {
  
    for (var i = 0; i < values.arr.length; i++){
      delete values.arr[i].no;
    }
    axios.post("http://gw.thegmmedical.com:5003/api/inspect_update", values)
    .then(({ data }) => {
       console.log("what is this");
       navigate("/inspect_view", {state: { no : values.no, data: values}});
      });
 
 
 
   };
  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
    
<div className="content-wrap">

      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%", }}>
      <div className="side-menu">
      <BoardList changeBoard={(value)=>{console.log(value);}} changeNotice={(value)=>{console.log(value);}}/>
      </div>
      <div style={{width:"90%", margin:"0 auto"}}>
     

    

            <div className="order_write" style={{padding:"0", margin:"20px 5%"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>점검결과표</td>
                  
                  </tr>
                  <tr>
                
                  </tr>
                  
                </table>
            <table style={{marginTop: '1px', borderCollapse: 'collapse'}} className="forms">{/* Header */} 
              <thead style={{height:"40px"}}>
                <tr>
             
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td style={{background: 'white', width:"100%", display:"flex", padding: '0px !important', border: 'currentColor', textAlign: 'left', color: 'black', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'top'}}>
                    <table className="header" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '1px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병원명
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="hospital" value={values.hospital} onChange={handleInputChange}></input>
                          </td>

                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            점검업체
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="company" value={values.company} onChange={handleInputChange}></input>
                          </td>
                        </tr>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            사용부서 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="department" value={values.department} onChange={handleInputChange}></input>
                          </td>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            점검일자 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="inspect_date" value={values.inspect_date} onChange={handleInputChange}></input>
                          </td>
                          
                        </tr>
                        <tr>
                        <th style={{background: 'rgb(226, 226, 226)',padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                             확인자
                        </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="checked" value={values.checked} onChange={handleInputChange}></input>

                          </td>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            점검자 
                          </th>
                          <td colSpan="3" style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="inspector" value={values.inspector} onChange={handleInputChange}></input>
                          </td>

                        </tr>
                     
                  
                      </tbody>
                    </table>

                  </td>
                  
                </tr>

                <tr>
                <div className="btns" style={{display:"flex", justifyContent:"flex-end", margin:"20px 0"}}>
     
        <button className="btn" onClick={addRowTable}>
          추가
        </button>

      

      </div>
                <table className="form_list order_list" style={{borderCollapse: 'collapse', width:"790px"}}>
                 
          <thead>
          <tr>
          <th>점검대상</th>
          <th colSpan={4}>점검결과</th>
          </tr>
            <tr>
              <th>모델(S/N)</th>
              <th>불량코드</th>
              <th>조치 및 의견</th>
              <th>비고</th>
              <th>삭제</th>
            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />
           
          </tbody>
        </table>
                </tr>
              </tbody>
            </table>
          </span></div>
        
        
      </div>
      <div className="btns" style={{display:"flex", justifyContent:"center", margin:"20px 0"}}>
      <button className="btn" onClick={handleRegisterButton} style={{marginRight:"10px"}}>등록</button>
      </div>
      </div>


      
 
     
      </div>
    </div>
    </div>
    </div>
  );
};

export default Order;

