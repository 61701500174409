import React, { useState, useEffect, useRef } from "react";
import "react-tooltip/dist/react-tooltip.css";
import "./css/add_addr.css";
import axios from "axios";



const AddAddr = ({ addr, closePopup }) => {

  const [groupName, setGroupName] = useState("");


  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");

  const [addrs, setAddrs]= useState([]);
  const [departments, setDepartment]= useState([]);
  const [myGroups, setMyGroups]= useState([]);

  const [links, setLinks]= useState([]);
 

  useEffect(() => {

    


    let temp =[];
    
 
    let obj = {"email": addr};
    temp.push(obj);
    
     
    const data =
    {
      email : addr
    }
   
    axios.post('https://gw.thegmmedical.com:5003/api/get_addr_mail',data)
    .then(function (response){
    //setBoxes(json[0].boxes);
    if (response.data.length>0){
      setLinks(response.data);
    }else{
      setLinks([...temp]);
    }
      
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });


    //myObj.forEach(item => temp.push(item));

    
    axios.get('https://gw.thegmmedical.com:5003/api/getaddrs',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    setAddrs(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

   
    axios.get('https://gw.thegmmedical.com:5003/api/get_teamaddr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setDepartment(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/get_user_addr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("user", response.data);
    setMyGroups(response.data);
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });


    
    
}, []);


const handleRegisterButton = () => {
  console.log("업데이트됨", links);
  const msg ={
    arr  : links
  }
  axios.post('https://gw.thegmmedical.com:5003/api/check_addr_mail',msg)
  .then(function (response){
    //setBoxes(json[0].boxes);
    
    if (response.data[0].cnt==0){
      axios.post('https://gw.thegmmedical.com:5003/api/add_addr_mail',msg)
      .then(function (response){
        alert("등록되었습니다.");
        closePopup();
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
      });
    }else{
      alert("이미 등록된 주소록입니다.");
      closePopup();
    }
    
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
  });
 
}

function createGroup(){
  const data = {
    name : groupName,
    use : 1,
    viewer : "[]",
    writer : "[]",
    type : "my",

   }
  
    console.log(data);
    axios.post('https://gw.thegmmedical.com:5003/api/add_addrgrp', data)
    .then(function (response){
      
      axios.get('https://gw.thegmmedical.com:5003/api/get_user_addr',{})
      .then(function (res){
      //setBoxes(json[0].boxes);
      console.log("user", res.data);
      setMyGroups(res.data);
      
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
      console.log("hi", response.data);
      let temp = links;
      temp[0].group_no = response.data.insertId;
      setLinks([...temp]);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
}
  return (
    <div className="popup">
      <div className="inner" style={{maxWidth:"700px", height:"auto", maxHeight:"500px", position:"relative",
      left:"50%", transform:"translateX(-50%) translateY(-50%)", top:"50%", zIndex:"999999"}}>
        <h3 style={{textAlign:"left"}}>주소록 추가</h3>
        <p style={{color:"gray"}}>아래 메일 주소를 주소록에 추가합니다.</p>
        <div className="shareMail">
      
        <table class="addr_table">
          <thead>
            <tr>
              <th class="name">이름</th>
              <th class="addr">메일주소</th>
              <th class="grp">그룹</th>
            </tr>
          </thead>
          <tbody>
            {links.map((ele,idx)=>(
              <tr>
              <td class="name">
                <div class="input_wrap">
                <input type="text" value={ele.name} placeholder="이름없음" onChange={(e)=>{
                  
                  let temp = links;
                  temp[idx].name = e.target.value;
                  setLinks([...temp]);
                }}/>
                </div>
              </td>
              <td class="contact_address">{ele.email}</td>
              <td class="contact_group" style={{textAlign:"center"}}>

              {(addrs.length===0 && departments.length===0 && myGroups.length===0)
          
          ?<div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <input type="text" value={groupName} onChange={(e)=>setGroupName(e.target.value)}/>
            <div className="btn" onClick={()=>createGroup()}
            style={{border:"1px solid #ddd", cursor:"pointer", marginLeft:"10px", padding:"2px 5px"}}>새 그룹 만들기</div>
            </div>
            
        :
              <select name="group_no" className="formInput" value={ele.group_no}
                                  onChange={(e)=>{
                                    let temp = links;
                                    temp[idx].group_no = e.currentTarget.value;
                                    setLinks([...temp]);
                                  }}> 

          <option value="">주소록 선택</option>
          {addrs.map((element,index)=>(
            <option value={element.no}>{element.g_name}</option>
          ))}
      {departments.map((element,index)=>(
            <option value={element.no}>{element.g_name}</option>
          ))}
          {myGroups.map((element,index)=>(
            <option value={element.no}>{element.g_name}</option>
          ))}
          
                
              </select>
        } 
              </td>
              </tr>
            ))}
            
          </tbody>
        </table>

      

       
     
      
   
      <div className="buttons">
        <button onClick={()=>handleRegisterButton()}>추가</button>
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>취소</button>
        </div>


        </div>
        
        
      </div>
      
    </div>
  );
};


export default AddAddr;