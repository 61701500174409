import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";

import { Workbook } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import initial from "./data/cell";

import axios from "axios";
import moment from "moment";
import {GiHamburgerMenu} from "react-icons/gi";


import CustomerList from "../components/side/CustomerList";
import styled from 'styled-components';

import {
  format
  
} from "date-fns";

import BasicInfo from "./customers/BasicInfo";
import PICInfo from "./customers/PICInfo";
import AccountInfo from "./customers/AccountInfo";

//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

var today = new Date();

const basicInitials = {
  name: "",
  biz_no: "",
  rep: "",
  register_no:"",
  open_date:"",
  register_date:"",
  addr:"",
  hq:"",
  biz_type : "",
  biz_item:"",
  account_no:"",
  type:"매입처"
};

    
    

   
const TabMenu = styled.ul`
  font-weight: bold;
  box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%),
  inset 0 1px 0 0 rgb(100 121 143 / 12%);
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin-bottom: 2rem;
  margin-top: 10px;
  @media only screen and (max-width: 1300px) {
    flex-direction:column;
  
  }

  a{
    @media only screen and (max-width: 1300px) {
      width:100%;
    
    }
  }
  .submenu {
  // 기본 Tabmenu 에 대한 CSS를 구현
    display: flex;
    /* justify-content: space-between;
    width: 380px;
    heigth: 30px; */
    padding: 10px;
    font-size: 15px;
    cursor:pointer;
    box-sizing:border-box;
    transition: 0.5s;

    @media only screen and (max-width: 1300px) {
      width:100%;
    
    }

  }
  .submenu:hover {
  }
  .submenu:first-child {
    margin:0 20px;
    @media only screen and (max-width: 1300px) {
      margin:0;
    
    }
  }
  .focused {
   //선택된 Tabmenu 에만 적용되는 CSS를 구현
    color: rgb(21,20,20);
    border-bottom:2px solid #000;
    @media only screen and (max-width: 1300px) {
      border:none;
      background:#ddd;
    
    }
  }

  & div.desc {
    text-align: center;
  }
`;

const Desc = styled.div`
  text-align: center;
`;




export default function WorkWrite() {

  const [info, setInfo] = useState(basicInitials);
  const [PIC, setPIC] = useState([]);
  const [account, setAccount] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [sidebar, setSidebar] = useState(false);

  const [currentTab, clickTab] = useState(0);
  const { menu } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  var menuArr; 
  if (location.state!==null){

  menuArr = [
    { name: '기본정보', content: <BasicInfo info={info} no={location.state.no} onChange={(values)=>{setInfo(values);console.log("info",values)}}/>},
    { name: '담당자정보', content: <PICInfo pic={PIC} onChange={(values)=>{setPIC(values);console.log("pic",values)}}/> },
    { name: '계좌정보', content: <AccountInfo account={account} onChange={(values)=>setAccount(values)}/> },
  ];

  }else{
    menuArr = [
      { name: '기본정보', content: <BasicInfo info={info} onChange={(values)=>{setInfo(values);console.log("info",values)}}/>},
      { name: '담당자정보', content: <PICInfo pic={PIC} onChange={(values)=>{setPIC(values);console.log("pic",values)}}/> },
      { name: '계좌정보', content: <AccountInfo account={account} onChange={(values)=>setAccount(values)}/> },
    ];
  }
  
  useEffect(() => {
    if (menu !==undefined){
      var index = menu * 1;
      clickTab(index); console.log(currentTab);
    }
  }, [menu]);


  useEffect(() => {

    if (location.state!==null){
    axios.get('http://gw.thegmmedical.com:5003/api/get_customer_basic/'+location.state.no)
    .then(function (response){
      console.log(response);
      setInfo(response.data[0]);

      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    axios.get('http://gw.thegmmedical.com:5003/api/get_customer_pic/'+location.state.no)
    .then(function (response){
      console.log(response);
      setPIC(response.data);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
    axios.get('http://gw.thegmmedical.com:5003/api/get_customer_account/'+location.state.no)
    .then(function (response){
      console.log(response);
      setAccount(response.data);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
    }
   

  }, []);


const handleInputChange = (e) => {
  //const name = e.target.name 
  //const value = e.target.value 
  const { name, value } = e.target;

  setInfo({
    ...info,
    [name]: value,
  });

 
};
 
  const handleRegisterButton = () => {
    if (info.type === undefined){
      alert("거래처 분류를 선택해주세요.");
      return;
    }
    console.log("info arr...", info);
    //alert("info.no...", location.state.no);
    
    const formData = new FormData();
   
    if (info.fileList!==undefined){
       for (let i = 0; i<info.fileList.length;i++){
        formData.append(`file[${i}]`, info.fileList[i]);
    
      }
    }
   
    
    formData.append(`no`, info.no);
    formData.append(`name`, info.name);
    formData.append(`biz_no`, info.biz_no);
    formData.append(`rep`, info.rep);
    formData.append(`register_no`, info.register_no);
    formData.append(`open_date`, info.open_date);
    formData.append(`register_date`, info.register_date);
    
    
    formData.append(`addr`, info.addr);
    formData.append(`hq`, info.hq);
    formData.append(`pic`, info.pic);
    formData.append(`pic_tel`, info.pic_tel);
    formData.append(`pic_email`, info.pic_email);

    formData.append(`biz_type`, info.biz_type);

    formData.append(`biz_item`, info.biz_item);
    formData.append(`account_no`, info.account_no);
    formData.append(`type`, info.type);

   
    /*담당자, 계좌 배열*/
    formData.append(`pic`, JSON.stringify(PIC));
    formData.append(`account`, JSON.stringify(account));
    console.log("PIC.....", PIC);
  
    const fetch = async () => {
      await axios
        .post("http://gw.thegmmedical.com:5003/api/customer_insert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log(data);

          navigate('/cx_view/'+data.no);
          //window.location.href="https://gw.thegmmedical.com/cx";
          //console.log("...." + data);
        });
    };

   fetch();
   


  
  };

  const selectMenuHandler = (index) => {
    // parameter로 현재 선택한 인덱스 값을 전달해야 하며, 이벤트 객체(event)는 쓰지 않는다
    // 해당 함수가 실행되면 현재 선택된 Tab Menu 가 갱신.
    clickTab(index);
  };


 
  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <CustomerList/>
        </div>
      )}
     <div className="content-wrap">
    
      
      <div className="outer_wrapper work" style={{justifyContent:"flex-start", width:"100%"}}>
        <div className="side-menu">
      <CustomerList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>
      </div>
      <div className="main_content">

      <h3 class="mail_title" style={{width:"100%", margin:"20px auto", padding:"0"}}>거래처 등록</h3>
   
    

      <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"100%", margin:"0 auto"}}>
              <div className="left">

              
              <button className="btn" onClick={handleRegisterButton} style={{marginRight:"10px"}}>등록</button>

             
              {/*<button className="btn" onClick={handleDraftButton}>임시저장</button>*/}
              </div>

             {/* <div className="right">
                <button onClick={() => {setShow(!show);}}>임시저장글</button> 

            </div>*/}
            </div>




            <TabMenu>
          {/* <li className="submenu">{menuArr[0].name}</li>
          <li className="submenu">{menuArr[1].name}</li>
          <li className="submenu">{menuArr[2].name}</li> */}
          {menuArr.map((el,index) => (
              <Link to ={el.href}><li className={index === currentTab ? "submenu focused" : "submenu" }
              onClick={() => selectMenuHandler(index)}>{el.name}</li></Link>
            ))}
        </TabMenu>
        <Desc>
          <p>{menuArr[currentTab].content}</p>
        </Desc>
     
    
    <div className="button_wrap">
      <button className="submit" onClick={handleRegisterButton}>등록</button>
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
    </div>
  );
}
