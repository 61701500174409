
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";
import React, { useState, useEffect, useRef } from "react";
import Popup from "../components/Popup";
import axios from "axios";
import "./css/adminHR.css";
import Pagination from "./Pagination";
import addDays from "date-fns/addDays";
import {differenceInCalendarYears, differenceInCalendarMonths} from "date-fns";
const AdminReport = () => {
    const [cnt, setCnt] = useState(0);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [showCreate, setCreate] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [sidebar, setSidebar] = useState(false);

    const [idxs, setIdxs] = useState([]);

  
    const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const offset = (page - 1) * limit;

    const togglePopup = () => {
        setPopup(!showPopup);
        

    };
    const toggleCreate = () => {
      setCreate(!showCreate);
      

  };
    const updateUser = () => {
      console.log("checklist...", checkList);
        for (var i=0;i<data.length;i++){
          if (data[i].email === checkList[0]){
            setSelected(data[i]);
          }
        }
      setPopup(!showPopup);
      
    };
    const deleteLog = () => {
      
      var result = window.confirm("모든 로그인 오류 기록을 삭제하고 차단 해제 하겠습니까?");
      if(result){
        axios.get('https://gw.thegmmedical.com:5003/api/del_pwerror')
        .then(function (response){
          console.log(response);
          window.location.reload(true);
          
          
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const stopUser = () => {
      console.log(checkList);
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 삭제하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/stopUsers', data)
        .then(function (response){
          console.log(response);
          getUser();
     
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const allInsert = () => {
      let temp = data;
      for (let i=0;i<temp.length;i++){
        let num;
        if (temp[i].workYear <1){
          num = differenceInCalendarMonths(new Date(new Date().getFullYear(), 11, 31), new Date(temp[i].start));
        }else{
          num = 15 + parseInt((temp[i].workYear-1)/2);

        }
        temp[i].curNum = num;
      }
      console.log("temp....", temp);

      setFilterData([...temp]);
    };


    const autoInsert = () => {
      console.log(checkList);
      let temp = data;
      for (let i=0;i<temp.length;i++){
        
        if (temp[i].number === 0){
          let num;
           if (temp[i].workYear <1){
          num = differenceInCalendarMonths(new Date(new Date().getFullYear(), 11, 31), new Date(temp[i].start));
          }else{
            num = 15 + parseInt((temp[i].workYear-1)/2);

          } 
          temp[i].curNum = num;
        }
       
       
      }
      console.log("temp....", temp);

      setFilterData([...temp]);
    };


    function getUser(){
      fetch("https://gw.thegmmedical.com:5003/api/department_tree/", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("json", json);
        let temp = json;
       
        setData(json);
      
        
        setFilterData(json);
        
      })
      .catch(() => {});
    
    }
    useEffect(() => {
       
      axios.get('https://gw.thegmmedical.com:5003/api/department_tree_report')
        .then(function (response){
        console.log("json", response.data);
        let departments = response.data;

        //setData(json);
        var valueArr = departments.map(function(item){ return item.path });
        let check;
        let indexes = [];
        /*var isDuplicate = valueArr.some(function(item, idx){ 
            //return valueArr.indexOf(item) != idx 
          
            if (valueArr.indexOf(item) != idx ){
              if (check != valueArr.indexOf(item)){
              check = valueArr.indexOf(item);console.log(check, "...", valueArr.indexOf(item), "...", idx);
              indexes.push(check);

              }
              

            }
        });*/

        for (let i=0;i<departments.length;i++){
          if (departments[i].name != null && departments[i].name !== undefined){
            if (check !== departments[i].path){
            check = departments[i].path;
            indexes.push(i);
            }
          }
        }

        setIdxs(indexes);
        console.log("indexes...", indexes);
       for (let i=indexes.length-1;i>=0;i--){
        departments.splice(indexes[i], 0, {path:departments[indexes[i]].path, d_no :departments[indexes[i]].d_no });
       }
        console.log("response.data=>", departments);
        setFilterData(response.data);
        console.log(indexes.includes(12));

    
        
      })
      .catch(() => {});
        
      axios.get('https://gw.thegmmedical.com:5003/api/getusers')
      .then(function (response){
        console.log(response.data);
        let user = response.data;


        /*setFilterData(departments);*/
        //setUsers(response.data);
        //getUser();

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      
      
      }, []);
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('user');
        const all 
             = document.getElementsByName('userall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...checkList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(checkList.filter((checkedId) => checkedId !== id));
        }
        
     }

     const batchChange  = (d_no, view_auth) => {
      console.log(d_no, view_auth);
      let temp = filterData;
      for (let i=0;i<temp.length;i++){
        if (temp[i].d_no === d_no){
          temp[i].view_auth = view_auth;
        }
      }
      setFilterData([...temp]);
     }

     const filterDates  = (no) => {
      setFilterData(data.filter(item=> new Date(item.error_date) >= new Date(startDate) && new Date(item.error_date) <=addDays(new Date(endDate),1)));


     };

     function getNum(arr, year){
      if (arr !==null && arr !==undefined){
        let temp = arr.split(",");
        console.log(temp);
        let value = temp.filter(item => item.includes(year));
        if (value[0] !==undefined){
          let final =value[0].split("-");
          console.log(final);
          return final[1];
        
        }
        
      }
     }
     function getUsed(arr, year){
      if (arr !==null && arr !==undefined){
        let temp = arr.split(",");
        console.log(temp);
        let value = temp.filter(item => item.includes(year));
        if (value[0] !==undefined){
          let final =value[0].split("-");
          console.log(final);
          return final[2];
        
        }
        
      }
     }

function saveAll(){
  let arr = [];
  let year = new Date().getFullYear();
  let temp = filterData;
  for (let i=0;i<temp.length;i++){
    if (temp[i].view_auth!==undefined && temp[i].view_auth!==null &&
      temp[i].user_no!==undefined && temp[i].user_no!==null){
    arr.push([temp[i].view_auth, temp[i].user_no]);
    }
  }
  console.log("결과는", arr);

  const msg = {
    arr : arr
  }


  axios.post('https://gw.thegmmedical.com:5003/api/save_report_auth',msg)
  .then(function (response){
    console.log(response);
    alert("저장되었습니다.");
    window.location.reload(true);

  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });

}
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents">
        <div className="header_txt">
        <h3>업무일지 열람권한</h3>
        </div>
    

        <div className="main_contents_wrapper"  style={{width:"100%", height:"600px", overflowY:"scroll"}}>
            

{showCreate ? (
  <Popup text="Title" closePopup={() => {toggleCreate();getUser();}} />
) : null}           

{showPopup ? (
  <Popup member={selected} text="Title" closePopup={() => {togglePopup();getUser();}} />
) : null}

<div className="table_wrap">

<table class="admin_hr responsive">
  <thead>
    <tr>
  
      <th>부서</th>
      <th>열람권한</th>
   

    </tr>
  </thead>
  <tbody>
 

  { filterData.map((element, index) => 
 (
    
    <tr key={index} 
    style={{ backgroundColor: element.name !==null && element.name !==undefined? 'white': '#E0F4FF'
    ,color: element.name !==null && element.name !==undefined? 'black': '#6199B7'}}>
   {/*{idxs.includes(index) && (
    <tr>하</tr>
   )}*/}
   
    <td className="id" style={{textAlign:"left"}}>
    {element.name !==null && element.name !==undefined ? 
    <span> ㅡ {element.name} {element.job_name} ({element.user_id})</span> :
    <span> {element.path.slice(0, -3)}</span> }</td>
   
    <td className="d_name">
      <div>
        <input type="radio" value="all" name={element.user_id}
        checked={element.view_auth === "all"} onChange={(e)=>{
      let temp = filterData;
      temp[index].view_auth = e.target.value;
      setFilterData([...temp]);}}/> 전체 부서
        <input type="radio" value="department" name={element.user_id}
        checked={element.view_auth === "department"} onChange={(e)=>{
          let temp = filterData;
          temp[index].view_auth = e.target.value;
          setFilterData([...temp]);}}/> 자기 부서만
        <input type="radio" value="sub" name={element.user_id}
        checked={element.view_auth === "sub"} onChange={(e)=>{
          let temp = filterData;
          temp[index].view_auth = e.target.value;
          setFilterData([...temp]);}}/> 자기부서 + 하위부서
        
        <input type="radio" value="myself" name={element.user_id}
        checked={element.view_auth === "myself"} onChange={(e)=>{
          let temp = filterData;
          temp[index].view_auth = e.target.value;
          setFilterData([...temp]);}}/> 본인만


      </div>

    
    
    
    </td>
    

    {element.name !==null && element.name !==undefined ?
    <td>

    </td>
    :
    <td>
<button onClick={()=>batchChange(element.d_no, element.view_auth)}
style={{background:"#fff", border:"1px solid #ddd"}}>하위 일괄 변경</button>
    </td>
    }

    </tr>


  
    )) }
   {/* 

   { filterData.slice(offset, offset + limit).map((element, index) => 
(
<tr key={index}>
   
      
   <td className="id" style={{textAlign:"left"}}>{index}{idxs[1]}{idxs.includes(index)} {element.path.slice(0, -3)}
   {element.name !==null && (<span style={{color:"#91A2AB"}}> ㅡ {element.name} {element.job_name} ({element.user_id})</span>)}</td>
  
   <td className="d_name">{element.job_no}</td>
   <td className="j_name">{element.start}</td>
   <td className="j_name">{element.workYear}년</td>
   <td className="j_name">{element.workMonth}개월</td>
   <td className="j_name"><span>할당: {element.prevNum}</span> | 
   <span>잔여: {element.prevUsed} </span></td>
   <td className="j_name"><span>할당: 
   <input type="number" value={element.curNum} onChange={(e)=>{
     let temp = filterData;
     temp[index].curNum = e.target.value;
     setFilterData([...temp]);
   }}
   style={{width:"35px"}}></input> </span>
   |<span>잔여: {element.curUsed}</span></td>
   <td className="j_name"><span>할당: {element.nextNum}</span> | <span>잔여: {element.nextUsed} </span></td>


   

   </tr>

    )) }
*/}
    {filterData.length === 0 && (<tr><td colSpan={11} style={{padding:"50px 0"}}>실패 기록이 없습니다.</td></tr>)}
  </tbody>
</table>
</div>
  </div>

  <div className="btns" style={{display:"flex",justifyContent:"center", marginBottom:"15px"}}>
        <button className="save" onClick={()=>saveAll()}>저장</button>
        </div>
        {data.length >20 && (
        <Pagination
          postsPerPage={limit}
          totalPosts={filterData.length}
          page={page}
          paginate={setPage}
        ></Pagination>

        )}
     </div>
    
    </div>

  );
};

export default AdminReport;
