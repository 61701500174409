import React, { useState, useEffect } from "react";
import "./css/mailsetting.css";
import MailNav from "../components/side/MailNav";
import SortSettings from "./settings/SortSetting";
import DefaultSetting from "./settings/DefaultSetting";
import SpamSetting from "./settings/SpamSetting";
import BoxSetting from "./settings/BoxSetting";
import AutoReplySetting from "./settings/AutoReplySetting";
import ExternalMail from "./settings/ExternalMail";


import {GiHamburgerMenu} from "react-icons/gi";
import { Link, useParams } from "react-router-dom";

import styled from 'styled-components';



const TabMenu = styled.ul`
  font-weight: bold;
  box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%),
  inset 0 1px 0 0 rgb(100 121 143 / 12%);
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin-bottom: 2rem;
  margin-top: 10px;
  @media only screen and (max-width: 1300px) {
    flex-direction:column;
  
  }

  a{
    @media only screen and (max-width: 1300px) {
      width:100%;
    
    }
  }
  .submenu {
  // 기본 Tabmenu 에 대한 CSS를 구현
    display: flex;
    /* justify-content: space-between;
    width: 380px;
    heigth: 30px; */
    padding: 10px;
    font-size: 15px;
    cursor:pointer;
    box-sizing:border-box;
    transition: 0.5s;

    @media only screen and (max-width: 1300px) {
      width:100%;
    
    }

  }
  .submenu:hover {
  }
  .submenu:first-child {
    margin:0 20px;
    @media only screen and (max-width: 1300px) {
      margin:0;
    
    }
  }
  .focused {
   //선택된 Tabmenu 에만 적용되는 CSS를 구현
    color: rgb(21,20,20);
    border-bottom:2px solid #000;
    @media only screen and (max-width: 1300px) {
      border:none;
      background:#ddd;
    
    }
  }

  & div.desc {
    text-align: center;
  }
`;

const Desc = styled.div`
  text-align: center;
`;


const MailSettings = () => {
  const [sidebar, setSidebar] = useState(false);
  const [currentTab, clickTab] = useState(0);
  const { menu } = useParams();
  const menuArr = [
    { name: '기본설정', href:"/setting/mail/0", content: <DefaultSetting navigation={"basic"}/>},
    { name: '메일함관리', href:"/setting/mail/1", content: <BoxSetting/> },
    { name: '메일 자동분류', href:"/setting/mail/2", content: <SortSettings/> },
    { name: '스팸 관리', href:"/setting/mail/3", content: <SpamSetting navigation={"spam"}/> },
    { name: '부재중 자동 응답', href:"/setting/mail/4", content: <AutoReplySetting/> },
    { name: '외부메일 불러오기(작업중)', href:"/setting/mail/5", content: <ExternalMail/> }
  ];
  useEffect(() => {
    if (menu !==undefined){
      var index = menu * 1;
      clickTab(index); console.log(currentTab);
    }
  }, [menu]);

  
  const selectMenuHandler = (index) => {
    // parameter로 현재 선택한 인덱스 값을 전달해야 하며, 이벤트 객체(event)는 쓰지 않는다
    // 해당 함수가 실행되면 현재 선택된 Tab Menu 가 갱신.
    clickTab(index);
  };

  return (
    <div className="">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <MailNav/>
        </div>
      )}
<div className="content-wrap">
  <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%", height:"100vh"}}>
    <div className="side-menu">
      <MailNav/>
      </div>
      <div className="main_content">
      <h2 style={{marginLeft:"0px"}}>환경설정</h2>
      <TabMenu>
          {/* <li className="submenu">{menuArr[0].name}</li>
          <li className="submenu">{menuArr[1].name}</li>
          <li className="submenu">{menuArr[2].name}</li> */}
          {menuArr.map((el,index) => (
              <Link to ={el.href}><li className={index === currentTab ? "submenu focused" : "submenu" }
              onClick={() => selectMenuHandler(index)}>{el.name}</li></Link>
            ))}
        </TabMenu>
        <Desc>
          <p>{menuArr[currentTab].content}</p>
        </Desc>
        
        </div>
        </div>
        </div>
    </div>
  );
}
export default MailSettings;
