import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import { Sheet, Op, Selection, colors } from "@fortune-sheet/core";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import Toggle from "../components/Toggle";
import initial from "./data/cell";
import SendSchedule from "../components/SendSchedule";

import {GiHamburgerMenu} from "react-icons/gi";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../components/ScheduleDraftsList";
import WorkList from "../components/side/WorkList";
import { Tooltip } from "react-tooltip";
import { IoIosArrowDown } from "react-icons/io";
import {
  format
  
} from "date-fns";
import "./css/schedulew.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

var today = new Date();

const initialValues = {
  subject: "",
  group: "1",
  start_date: format(today, "yyyy-MM-dd"),
  start_hour:"00",
  start_min:"00",
  end_date:format(today, "yyyy-MM-dd"),
  end_hour:"00",
  end_min:"00",
  person_in_charge : "",
  workers:[],
  status:"계획"
};
export default function BoardWrite() {
  const { state } = useLocation();
  const hour = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  const location = useLocation();
  const editorRef = useRef();
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [data, setData]=useState([]);

  const [delFile, setDelFile]=useState([]);

  const [members, setMembers] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [div, setDiv]=useState(<div style={{width:"100%", height:"500px"}}><Workbook onOp={console.log} data={[initial]} onChange={(data)=>{console.log("changed, ", data);}} /></div>) ;


  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [workGroups, setWorkGroups] = useState([]);
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState((location.state.importance === 'true'));
  const [options, setOption] = useState("");
  const [showPopup, setPopup] = useState(false);
  const [editor, setEditor] = useState("toast");

  const [workers, setWorkers]=useState([]);
  const [user, setUser]=useState([]);
  const [attach, setAttach]=useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(true);
  const logState = state => {
    console.log("Toggled:", state)
    setOpen(state);
  }

/*  const display = values.workers.map(item => (
    <div className=""><li key={item.user_no}>
      {item.name} [{item.email}]; &nbsp;
    </li>
  
    </div>
  ));*/

  const togglePopup = () => {
    setPopup(!showPopup);
};
  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };
  const selectBoard = (e) => {
    e.preventDefault();
  };
  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };
  const selectEditor = (e) => {
    e.preventDefault();
    setEditor(e.target.value);
  };
  const toggleShow = () => {
    setShow(!show);
};
  const onTypeChange = e => {
    setType(e.target.value)
  }
  const endDateChange = e => {
    setEndDate(e.target.value)
  }
  useEffect(() => {
    console.log(location.state);
    let no = location.state.no;
    axios.get("http://gw.thegmmedical.com:5003/api/get_workattach/"+no).then((response) => {
      setAttach(response.data);
      
    });
    axios.get("http://gw.thegmmedical.com:5003/api/get_write_workgroup").then((response) => {
      // setNotice(response.data);
      setWorkGroups(response.data);
       
     });
     axios.get("http://gw.thegmmedical.com:5003/api/workview/"+no)
     .then(function (response){
       
       setData(response.data);console.log("data.data...", response.data);
       editorRef.current?.getInstance().setHTML(response.data[0].content);
       setValues(response.data[0]);
       if (response.data[0].importance === "true"){
        setOpen(true);console.log("저 tttt", response.data[0].importance);
       }else{
        setOpen(false);
       }
       console.log(response.data[0].workers);
       axios.get("http://gw.thegmmedical.com:5003/api/getuserinfo/"+response.data[0].PIC).then((response) => {
        setUser(response.data[0]);
        
      });
       

       if (response.data[0].workers.length>0){
        console.log("hihihihi");
        let workers = JSON.parse(response.data[0].workers);
        let user_nos = [];
        if (workers !== null && workers.length>0){
        for (let w = 0;w<workers.length;w++){
        let worker = workers[w];
        console.log("참석자ㅛㅇ", worker);
        let worker_no = worker.substring(1, worker.length-1);
        console.log(worker);
        user_nos.push(worker_no);
        }
       }
       const data ={
        user_nos : user_nos
       }
        var people =  [];
        axios.post("http://gw.thegmmedical.com:5003/api/get_participants", data).then((response) => {
          people = response.data;
          setWorkers(response.data);
          
        });
       }
   
     })
     .catch(function (error){
       console.log(error);
     })
     .then(function (){
       //always executed
     });

     /*const output = document.createElement('p')
     var section1s = document.getElementsByClassName("write_table");
     console.log(renderToString(section1s));*/
     

}, []);



const handleInputChange = (e) => {
  //const name = e.target.name 
  //const value = e.target.value 
  const { name, value } = e.target;

  setValues({
    ...values,
    [name]: value,
  });

 

 

  if (name === "end_date"){

    setValues({
      ...values,
      rep_end: "date",
    });
    values.rep_end = "date";
    setValues({
      ...values,
      [name]: value,
    });

  }
  if (name === "rep_end" && value === "keep"){
    setValues({
      ...values,
      end_date: "",
      times:"",
    });
    values.end_date = "";
    values.times = "";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "times" && value !== ""){
    setValues({
      ...values,
      end_date: "",
      rep_end: "times",
    });
    values.end_date = "";
    values.rep_end = "times";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "rep_cycle" && value === "weekly"){
    let date = values.start_date.split("-");
    let from = new Date(date[0], parseInt(date[1]) - 1, date[2]);
    let day = from.getDay();
    let days  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
    setValues({
      ...values,
      rep_week_day: days[day],
    });
    values.rep_week_day = days[day];
    setValues({
      ...values,
      [name]: value,
    });
  }
};
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const subjectChange = (event) => {
    setSubject(event.target.value);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };



function delWork() {
  var result = window.confirm("일정을 정말 삭제하시겠습니까?");
  if(result){
    const data={
      w_no : location.state.no
    }
    axios.post(
      "http://gw.thegmmedical.com:5003/api/delete_work",
      data
    ).then(( res ) => {
      navigate("/work");
    });
  }
}

function shareSchedule(){

}
 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {

    
    
    console.log(editorRef.current?.getInstance().getHTML());

    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("http://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`subject`, values.subject);
    formData.append(`work_group`, values.work_group);
    formData.append(`start_date`, values.start_date);
    formData.append(`start_hour`, values.start_hour);
    formData.append(`start_min`, values.start_min);


    formData.append(`end_date`, values.end_date);
    
    
    formData.append(`end_hour`, values.end_hour);
    formData.append(`end_min`, values.end_min);
    formData.append(`person_in_charge`, values.PIC);
    formData.append(`status`, values.status);
    formData.append(`open`, open);
    formData.append(`work_no`, location.state.no);
    formData.append(`importance`, open);

    let user_nos = [];
    for (let i =0;i<workers.length;i++){
      user_nos.push("("+workers[i].user_no+")");
    }

    formData.append(`workers`, JSON.stringify(user_nos));
    formData.append(`del_file`, JSON.stringify(delFile));

  
    const fetch = async () => {
      await axios
        .post("http://gw.thegmmedical.com:5003/api/workupdate", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/work";
          //console.log("...." + data);
        });
    };

   fetch();
   


  
  };

  const handleDraftButton = () => {
 
    
    console.log(editorRef.current?.getInstance().getHTML());

    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("http://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`subject`, values.subject);
    formData.append(`group`, values.group);

    formData.append(`start_date`, values.start_date);
    formData.append(`start_hour`, values.start_hour);
    formData.append(`start_min`, values.start_min);

    formData.append(`end_date`, values.end_date);
    
    
    formData.append(`end_hour`, values.end_hour);
    formData.append(`end_min`, values.end_min);

    formData.append(`rep_cycle`, values.rep_cycle);
    formData.append(`rep_cycleN`, values.rep_cycleN);
    formData.append(`rep_end`, values.rep_end);
    formData.append(`rep_week_day`, values.rep_week_day);

    formData.append(`times`, values.times);
    formData.append(`rep_end_date`, values.rep_end_date);
    let user_nos = [];
    for (let i =0;i<values.participants.length;i++){
      user_nos.push("("+values.participants[i].user_no+")");
    }
    formData.append(`participants`, JSON.stringify(user_nos));

  
    const fetch = async () => {
      await axios
        .post("http://gw.thegmmedical.com:5003/api/schedule_draftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/schedule";
          //console.log("...." + data);
        });
    };

   fetch();
   



  };

 

  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
  window.addEventListener('message', (event) => {
    if (event.data["selectedMembers"] !== undefined){
      
      var temp = event.data["selectedMembers"];
      var tempArray = members;
      for (let i=0;i<temp.length;i++){
        if (!tempArray.includes(temp[i])){
          
          tempArray = [...tempArray, temp[i]];
        }
      }
      const map = new Map(
        tempArray.map(obj => [obj.user_no, obj])
      );

      const deduplicatedArr = [...map.values()];
        console.log("응:<ㅡ",deduplicatedArr);
      setWorkers(deduplicatedArr);      
      
    }
    if (event.data["selectedMember"] !== undefined){
      var user_no = event.data["selectedMember"].user_no;
      var name = event.data["selectedMember"].name;
      var email = event.data["selectedMember"].email;
      setValues({
        ...values,
        person_in_charge: name + " ["+email+"]",
        user_no : user_no
      })
      values.PIC = user_no;
      values.person_in_charge = name + " ["+email+"]; ";
    }
  });

  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const excelInitial = {
    name: "Sheet1",
    config: {
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
      },
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 38,
    status: 1,
    ch_width: 1361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    
    scrollLeft: 0,
    scrollTop: 0
  };
  

  
  const removeAttached = (i) => {
    setAttach([...attach.filter((_, index) => index !== i)]);
    const tempArray = [...delFile, attach[i].a_no];
    setDelFile(tempArray);
    console.log("....",attach[i]);
    setFileSize(fileSize-attach[i].size);
    /*axios.get("https://gw.thegmmedical.com:5003/api/delete_attach_work/"+attach[i].a_no)
    .then(resp => {
    });*/
      
  };
  
  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <WorkList/>
        </div>
      )}
     <div className="content-wrap">
    
      
     <div className="outer_wrapper work" style={{justifyContent:"flex-start", width:"100%"}}>
      <div className="side-menu">
      <WorkList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>
      </div>
      <div className="main_content">
      {showPopup ? (
  <SendSchedule values = {values}
  onChange={(value) => setData(value.data)} closePopup={() => togglePopup()} />
) : null}

      <h3 class="">업무 수정</h3>
      
    

      <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"100%", margin:"0 auto"}}>
      <div className="left">

              
<button className="btn" onClick={handleRegisterButton} style={{marginRight:"10px"}}>등록</button>

<button className="btn" onClick={handleDraftButton}>임시저장</button>
</div>

<div className="right">
  <button onClick={() => {setShow(!show);}}>임시저장글</button> 

</div>
          
            </div>
            <table className="form_write" style={{margin:"10px auto", width:"100%"}}>

{/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}




<tr>
<td class="table_title2" nowrap="">
업무명
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="subject" className="formInput" placeholder="제목을 입력하세요." value={values.subject} onChange={handleInputChange}></input>
</div>
</td>



</tr>

<tr>


<td class="table_title2" nowrap="">
상태
</td>
<td class="table_list2_left">
<div className="input-group status">
<select name="status" className="formInput" value={values.status} onChange={handleInputChange}>
<option value="진행">진행</option>
<option value="완료">완료</option>
<option value="지연">지연</option>
<option value="계획">계획</option>
<option value="취소">취소</option>
</select>

</div>
</td>

<td class="table_title2" nowrap="">
    중요도
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <Toggle
            label="중요도"
            toggled={open}
            onClick={logState}
        />
    </div>
    </td>
</tr>

<tr>
  <td class="table_title2" nowrap="">
    업무그룹
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <select name="work_group" className="formInput" value={values.work_group} onChange={handleInputChange}>
      <option value="0">선택 안함</option>
  {workGroups.map((element, index)=>(
      <option value={element.no}>{element.name}</option>
  ))}

  
  </select> 
    </div>
    </td>

   

  </tr>

<tr style={{height:"auto"}}>
<td class="table_title2" nowrap="">
업무 일시
</td>
<td class="table_list2_left">
<div className="input-group">

<div className="date">
<span>시작 </span>
<input type="date" name="start_date" className="formInput"  style={{marginLeft:"10px"}} value={values.start_date} onChange={handleInputChange}></input>

<div style={{display:"flex"}}>
<select name="start_hour" className="formInput" style={{marginLeft:"10px"}} value={values.start_hour}
                          onChange={handleInputChange}>
                         
{hour.map((element, index)=>(
<option value={element}>{element}</option>
))}
</select> 
<span>:</span>
<select name="start_min" className="formInput" style={{marginLeft:"10px"}} value={values.start_min}
                          onChange={handleInputChange}>
{Array.from(Array(60), (e, i) => {
if (i >=0 && i <=9){
return <option value={"0"+i}>0{i}</option>
}else{
return <option value={i}>{i}</option>
}
})}


</select> 

</div>

</div>
<div className="date"><span>종료</span> 
<input type="date" name="end_date" className="formInput"  style={{marginLeft:"10px"}} value={values.end_date} onChange={handleInputChange}></input>

<div style={{display:"flex"}}>
<select name="end_hour" className="formInput" style={{marginLeft:"10px"}} value={values.end_hour} onChange={handleInputChange}>
{hour.map((element, index)=>(
<option value={element}>{element}</option>
))}


</select> 

<span>:</span>
<select name="end_min" className="formInput" style={{marginLeft:"10px"}} value={values.end_min} onChange={handleInputChange}>
{Array.from(Array(60), (e, i) => {
if (i >=0 && i <=9){
return <option value={"0"+i}>0{i}</option>
}else{
return <option value={i}>{i}</option>
}
})}


</select> 

</div>
</div>
</div>
</td>



</tr>


<tr>
<td class="table_title2" nowrap="">
작업책임자
</td>
<td class="table_list2_left">
<div className="input-group">
<div style={{display:"flex",     alignItems:"center"}}>
<span style={{marginRight:"10px",width:"70%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{user.d_name} {user.name} {user.job_name}[{user.email}]</span>
      
  <button onClick={() => popupwindow("/people", "결재라인", 500, 750)} alt='' 
  style={{background:"white", border:"1px solid #666", padding:"5px 10px", display:"flex",cursor:"pointer"}}>찾기</button>

</div>



</div>

</td>
</tr>


<tr>
<td class="table_title2" nowrap="">
작업자/참조자
</td>
<td class="table_list2_left">
<div className="input-group">
<div style={{display:"flex",     alignItems:"center"}}>
{workers.length>0 && workers.map((element,index)=>(
                <li key={element.user_no}>
                {element.name} [{element.email}]; &nbsp;
              </li>
              ))}
 
      
  <button onClick={() => popupwindow("/select", "결재라인", 800, 700)} alt='' style={{background:"white", border:"1px solid #666", padding:"5px 10px", display:"flex",cursor:"pointer"}}>찾기</button>

</div>



</div>

</td>
</tr>



<tr>
<td class="table_title2" nowrap="">
첨부파일
</td>
<td class="table_list2_left file_btns">
<div className="input-group">
<input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler} multiple/>
<button style={{cursor:'pointer'}} className="large_file_btn" onClick={()=>onBtnClick()}>
파일 첨부
</button>

</div>

</td>

</tr>

<tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    
  </td>
    <td class="upload_file_list">

    {(attach !==undefined && attach.length>0) &&   
<div className="attachment">

  <div className="attachment_box" style={{width:"100%", padding:"10px 0"}}>
    {attach.map((element, idx) => {
    return <div className="file_item">
    <p>{element.filename} {formatBytes(element.size)}</p>
    <button style={{width:"5px"}} type="button" className="remove" onClick={() => {removeAttached(idx);}}>x</button>
  </div>
    })}
    </div>
  </div>

  }

  {fileList.map((file,index) => 
    <div className="file_item">
      <p>{file.name} {formatBytes(file.size)}</p>
      <button type="button" className="remove" onClick={() => {removeFile(index);}}>x</button>
    </div>
    
  )} 

{largeFiles.map((file,index) => 
    <div>
      <p>{file.name} {formatBytes(file.size)} <span>다운로드 가능 기간</span></p>
      
      <button type="button" onClick={() => {removeFile(index);}}>Remove</button>
    </div>
    
  )} 
    </td>
  </tr>


</table>

     <div id="response">{links}</div>

 <div className="" style={{ width: "100%", margin: "20px auto", height:"500px", border:"1px solid #ddd"}}>
    {editor === "toast" && (
      <Editor
        ref={editorRef} // DOM 선택용 useRef
        initialValue={values.content}
        previewStyle="vertical" // 미리보기 스타일 지정
        height="500px" // 에디터 창 높이
        initialEditType="wysiwyg" //
        toolbarItems={[
          // 툴바 옵션 설정
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock']
        ]}
        plugins={[colorSyntax]} 
        useCommandShortcut={false} // 키보드 입력 컨트롤 방지
        hooks={{
          addImageBlobHook: async (blob, callback) => {
            console.log(blob.size);
            if (blob.size > 5000000) {
              alert(
                "업로드에 실패했습니다. 이미지당 5MB까지 업로드할 수 있습니다."
              );
              return;
            }
            const formData = new FormData();
                    formData.append("img", blob);
                    
                    // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
                    // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...
                    await axios.post(
                      "http://gw.thegmmedical.com:5003/api/img",
                      formData
                    ).then(( res ) => {
                      console.log(res);
                      console.log(
                        "성공 시, 백엔드가 보내주는 데이터",
                        res.data.url
                      );
  
                      const IMG_URL = res.data.url;
                     
                      // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
                      callback(IMG_URL, "test");
                      //alert(IMG_URL);
                      files.push(IMG_URL);
                      setArray(files);
                      //setArray((fileArray) => [...fileArray, IMG_URL]);
                      //alert("fileArray: ", files);
                      console.log(files);
                      var arrayLength = files.length;
                      //alert(arrayLength);
                      //alert("end");
                    
                    });
            //callback('http://gw.thegmmedical.com:5003/img/카레유.png', '카레유');
          }
        }}
      ></Editor>
    )}
 
    </div>
 
      
      <div className="button_wrap">
      <button className="submit" onClick={handleRegisterButton}>등록</button>
      {/*</form>*/}
      </div>
    </div>
   
      
   
      </div>
    </div>
    </div>
  );
}
