
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import AddressList from "../components/side/AddressList";
import axios from "axios";
import "./css/address.css";

import { HiPencilAlt, HiMail, HiTrash, HiOutlineStar } from "react-icons/hi";

import Pagination from "./Pagination";
import {GiHamburgerMenu} from "react-icons/gi";
import * as XLSX from "xlsx";

import { SiMicrosoftexcel } from "react-icons/si";

const Board = () => {
  const [data, setData] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [isActive, setActive] = useState(false);
  const [chosen, setChosen] = useState("전체");
  const [parent, setParent] = useState("");
  const [name, setName] =useState("");
  const [num, setNum] = useState(0);
  const [lev, setLev] = useState(0);
  const [selected, setSelected]= useState();
  const [search, setSearch] = useState(null);
  const [boardN, setBoardN] = useState("");
  const [boardNum, setBoardNum] = useState();
  const [options, setOption] = useState("name");
  const [filterData, setfilterData] = useState([]);
  const [addrs, setAddrs] = useState([]);
  const [department, setDepartment] = useState([]);
  const [myGroups, setMyGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState("");
  const [CheckList, setCheckList] = useState([]);
  const [notice, setNotice] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [isPage, setIsPage] = useState(false);
  const [isloggedIn, setLoggedIn] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const offset = (page - 1) * limit;
  const { state } = useLocation();

  const navigate = useNavigate();
  const toggleText = () => {
    setActive(!isActive);
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const {no} = useParams();
  
  function getData(){
    axios.get("http://gw.thegmmedical.com:5003/api/getaddr/"+no).then((response) => {
      setNotice(response.data);
      setData(response.data);
      setfilterData(response.data);
      if (response.data.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
        if (response.data.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    });
  }
  useEffect(() => {

    getData();
    
    axios.get('http://gw.thegmmedical.com:5003/api/getaddrs',{})
    .then(function (response){
      //setBoxes(json[0].boxes);
      setAddrs(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  
     
    axios.get('http://gw.thegmmedical.com:5003/api/get_teamaddr',{})
    .then(function (response){
      //setBoxes(json[0].boxes);
      console.log("department", response.data);
      setDepartment(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  
    axios.get('http://gw.thegmmedical.com:5003/api/get_user_addr',{})
    .then(function (response){
      //setBoxes(json[0].boxes);
      console.log("department", response.data);
      setMyGroups(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
      
  }, []);


   



  const deleteDepartment = () => {
    const data ={
      name : name,
      parent : parent,
      num : num,
      level : lev
    }
    axios.post('http://gw.thegmmedical.com:5003/api/delete_department', data)
    .then(function (response){
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };


  const assign = () => {
    if (selected.name ===undefined){
    }
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    if (array[0].value==="selectall"){
      array.shift();
    }
    const data ={
      nos : array,
      folderName : selected.name,
      folderNo : selected.num
    }
    
    var result = window.confirm("선택된 사용자를 [" + selected.name+"] 부서에 지정합니다.");
    if(result){
      axios.post('http://gw.thegmmedical.com:5003/api/assign', data)
    .then(function (response){
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }
    
  };
  const onNameClick = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();
    var notice_data=[];
    var board_data=[];
    const {
      // internal data
      path, name, num, level, type, isOpen, children
      // custom data
    } = nodeData;
    setName(name);
      setNum(num);
    if (type!=="group"){
      var dropdown = document.getElementsByClassName('dropdown_div');
      var aTags = dropdown[0].getElementsByTagName("span");
      
var searchText;
var found;

        searchText=name;
      
for (var i = 0; i < aTags.length; i++) {
  if (aTags[i].textContent === searchText) {
    found = aTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('.editableNameContainer');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';
    console.log(name);
      
    

    }
    
};
  const addBookmark = () => {
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }
    const msg ={
      nos : array
    }
    var result = window.confirm("선택한 연락처를 즐겨찾기에 추가하시겠습니까?");
    if(result){
      
      axios.post('http://gw.thegmmedical.com:5003/api/add_addr_bookmark', msg)
    .then(function (response){
      console.log(response);
      navigate("/address");
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  };
  
  const selectHandle = (e) => {
    console.log("changed", e.target.value);

    setOption(e.target.value);
  };

  const selectGroupHandle = (e) => {
    console.log("changed", e.target.value);

    setGroupOptions(e.target.value);
  };

  const onSearch = (e) => {
    e.preventDefault();
    var filtered;
    if (groupOptions!==""){
      filtered = data.filter((itemList) => {
        console.log(itemList.group_no, ", ", parseInt(groupOptions));
        return itemList.group_no == parseInt(groupOptions);
      });
      if (search!==""){
        filtered = filtered.filter((itemList) => {
          return itemList[options].toUpperCase().includes(search.toUpperCase());
        });
      }
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }else if (groupOptions ===""){
      if (search!==""){
        filtered = data.filter((itemList) => {
          return itemList[options].toUpperCase().includes(search.toUpperCase());
        });
        setfilterData(filtered);
        if (filtered.length === 0) {
          setEmpty(true);
          setIsPage(false);
        } else {
          setEmpty(false);
          if (filtered.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }
      }
    }

  
    
   
    
  };
  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
       setCheckList([...CheckList, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
       setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }
 }

 function selectAll()  {
  const checkboxes 
       = document.getElementsByName('contact');
  const all 
       = document.getElementsByName('all');
  //console.log(all[0].checked)
  checkboxes.forEach((checkbox) => {
    checkbox.checked = all[0].checked;
  })
}

const searchword = (e) => {
  setEmpty(false);
  setPage(1);
  const value = e.target.getAttribute("value");
  console.log(value);
  setChosen(value);
  axios.post('http://gw.thegmmedical.com:5003/api/getaddr_list_byword',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        input: value,
        group_no: no
      },
    })
      .then(function (response){
      console.log(response.data);
      /*var tempArray = [];
      for (let i =0;i<response.data.length;i++){
  
          console.log(response.data[i].name);
          tempArray.push(response.data[i].name + response.data[i].email);
  
      }*/
      //setData(response.data);
      setfilterData(response.data);

      if (response.data.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (response.data.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });
  
}

function showAll(){
  setChosen("전체");
  getData();
}

function exportExcel(){
  console.log(data[0]);
  let newJson = data.map(ele => {
    return {
      '이름': ele.name,
      '핸드폰': ele.phone,
      '이메일': ele.email,
      '회사' : ele.company,
      '직급' : ele.job_name,
      '부서' : ele.d_name,
      'fax' : ele.fax,
      '비고' : ele.note,
      '주소록 그룹' : ele.g_name
    }
  });

  const ws = XLSX.utils.json_to_sheet(newJson); 
   
  const wb= XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, "export.xlsx");
}


function delContacts(){
  console.log(CheckList);
  var array = []
  var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

  for (var i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].value!=="selectall"){
    array.push(checkboxes[i].value)
    }
  }
  console.log(array);
  if (array[0].value==="selectall"){
    array.shift();
  }
  const msg ={
    nos : array
  }
  var result = window.confirm("선택한 연락처를 삭제하시겠습니까?");
  if(result){

    axios.post('http://gw.thegmmedical.com:5003/api/delcontacts', msg)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

}
  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <AddressList/>
        </div>
      )}
   
     <div className="content-wrap">
     
      
      <div className="outer_wrapper addrlist addr" style={{justifyContent:"flex-start"}}>

      <div className="side-menu">
        <AddressList changeBoard={(value)=>{setfilterData(value);
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);}} changeBoardNum={(value)=>{setBoardNum(value);}}/>
         </div>
        
        <div className="board_wrap">
    
       
        <div className="">
        
        <div className="board_wrapper">
          <form name="form1" id="form1" method="post">
                  <div className="board_container">
                      <div id="content" className="content">
                          
                        <div className="board_top">
                        <div className="btns">

            
<div className="btn" style={{marginRight:"10px", display:"flex", alignItems:"center"}} onClick={()=>addBookmark()}>
  
  <HiOutlineStar style={{color:"#bdd6ff"}}/><span>즐겨찾기 추가</span></div>
<div className="btn" style={{marginRight:"10px", display:"flex", alignItems:"center"}} onClick={()=>delContacts()}>

<HiTrash style={{color:"#bdd6ff"}}/><span>삭제</span>

</div>

<div className="btn" style={{marginRight:"10px", display:"flex", alignItems:"center"}} onClick={()=>exportExcel()}>
  <SiMicrosoftexcel style={{color:"#bdd6ff"}}/><span>엑셀로 내보내기</span>
  </div>
<div className="btn" style={{marginRight:"10px", display:"flex", alignItems:"center"}}>
<Link to="/write" state={{ to : CheckList }} >
          <HiMail style={{color:"#bdd6ff"}}/><span>메일 전송</span></Link>

</div>





</div>

<div style={{margin:"0 0 20px"}}>{boardN}</div>
                              {/*<label>
                            페이지 당 게시물 수:&nbsp;
                            <select
                            type="number"
                            value={limit}
                            onChange={({ target: { value } }) => setLimit(Number(value))}
                            >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </label>*/}
                              <div className="bt_srch">
                                <div className="bts_slct">
                                  <select
                                    id="u_shcate"
                                    name="search_item"
                                    className="select"
                                    value={groupOptions}
                                    onChange={selectGroupHandle}
                                    
                                  >
                                    <option value="">전체 주소록</option>

                                    {addrs.map((element,index)=>(
                                    <option value={element.no}>{element.g_name}</option>
                                  ))}
                              {department.map((element,index)=>(
                                    <option value={element.no}>{element.g_name}</option>
                                  ))}
                                  {myGroups.map((element,index)=>(
                                    <option value={element.no}>{element.g_name}</option>
                                  ))}
                                  </select>
                                </div>
                                <div className="bts_slct">
                                  <select
                                    id="u_shcate"
                                    className="select"
                                    value={options}
                                    onChange={selectHandle}
                                  >
                                    <option value="name">이름</option>
                                    <option value="phone">휴대폰</option>
                                    <option value="email">이메일</option>
                                    <option value="company">회사</option>
                                    <option value="job_name">직급</option>
                                    <option value="d_name">부서</option>

                                  </select>

                                </div>
                                <div className="bt_input">
                                  <input
                                    type="text"
                                    id="search_content"
                                    name="search_content"
                                    value={search || ''}
                                    placeholder="검색어를 입력하세요"
                                    onChange={onChangeSearch}
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="search_btn"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                              </div>
                          </div>
                          <div className="directory-select">
                    <div className="directory-search">
                    <div className="search-word">
                    <span onClick={()=>showAll()} className={chosen == "전체" ? "selected" : "" } value="전체">전체</span>

<span onClick={searchword} className={chosen == "가" ? "selected" : "" } value="가">ㄱ</span>
<span onClick={searchword} className={chosen == "나" ? "selected" : "" } value="나">ㄴ</span>
<span onClick={searchword} className={chosen == "다" ? "selected" : "" } value="다">ㄷ</span>
<span onClick={searchword} className={chosen == "라" ? "selected" : "" } value="라">ㄹ</span>
<span onClick={searchword} className={chosen == "마" ? "selected" : "" } value="마">ㅁ</span>
<span onClick={searchword} className={chosen == "바" ? "selected" : "" } value="바">ㅂ</span>
<span onClick={searchword} className={chosen == "사" ? "selected" : "" } value="사">ㅅ</span>
<span onClick={searchword} className={chosen == "아" ? "selected" : "" } value="아">ㅇ</span>
<span onClick={searchword} className={chosen == "자" ? "selected" : "" } value="자">ㅈ</span>
<span onClick={searchword} className={chosen == "차" ? "selected" : "" } value="차">ㅊ</span>
<span onClick={searchword} className={chosen == "카" ? "selected" : "" } value="카">ㅋ</span>
<span onClick={searchword} className={chosen == "타" ? "selected" : "" } value="타">ㅌ</span>
<span onClick={searchword} className={chosen == "파" ? "selected" : "" } value="파">ㅍ</span>
<span onClick={searchword} className={chosen == "하" ? "selected" : "" } value="하">ㅎ</span>
<span onClick={searchword} className={chosen == "a" ? "selected" : "" } value="a">a-z</span>
<span onClick={searchword} className={chosen == "0" ? "selected" : "" } value="0">0-9</span>


                    </div>
                    </div>
               
                </div>
                          <div className="addr_table">
                         
                          
                  <table class="contacts table bd-b">
									<thead class="thead-dark">
									<tr class="tx-center">
										<th class="wd-80 xl-hidden">
      <input type='checkbox'
       name='all' 
       value='selectall'
       onClick={selectAll}/></th>
										<th class="">이름</th>
										<th class="wd-150 xl-hidden">휴대폰</th>
										<th class="wd-120 xl-hidden">이메일</th>
										<th class="wd-80 xl-hidden">회사</th>
                    <th class="wd-80 xl-hidden">직급</th>
                    <th class="wd-80 xl-hidden">부서</th>

                    <th class="wd-80 xl-hidden">fax</th>
                    <th class="wd-80 xl-hidden">그룹</th>

									</tr>
									</thead>
									<tbody>
             
                                    {isEmpty && (
                                  <tr style={{ textAlign: "center" }}>
                                    <td colSpan={8} style={{padding:"50px 0"}}>연락처가 없습니다.</td>
                                  </tr>
                                )}
                                {filterData
                                  .slice(offset, offset + limit)
                                  .map(
                                    ({
                                      no, name, phone, email, address, company, job_name, d_name, fax, note, group_no, g_name
                                    }) => (
                                      ( <tr key={no}>
                                      <td>
                                      <input type='checkbox' 
                                      name='contact' 
                                      value={no}
                                      onChange={(e) => onChangeEach(e, no)} checked={CheckList.includes(no)}/> 

                                      </td>
                                      <td>
                                
                                      <Link to="/addrview" state={{no: no}}>{name}</Link>
                                        </td>
                                      
                                        <td>
                                        {phone}
                                        </td>
                                        <td>
                                        {email}
                                        </td>
                                        <td>
                                        {company}
                                        </td>
                                        <td>
                                        {job_name}
                                        </td>
                                        <td>
                                        {d_name}
                                        </td>
                                        <td>
                                        {fax}
                                        </td>
                                        <td>
                                        {g_name}
                                        </td>
                                      </tr>)
                                     
                                    )
                                  )}

									</tbody>
								</table>
                            <table>
                              <colgroup>
                                <col className="m_dp_n table_no" />
                                <col className="" />
                                <col className="ta_l" />
                                <col className="m_dp_n table_date" />
                              </colgroup>
                              {/*<thead>
                        <tr>
                            <th className="m_dp_n table_no" scope="col">NO.</th>
                            <th scope="col"></th>
                            <th className="ta_l" scope="col">제목</th>
                            <th className="m_dp_n table_date" scope="col">날짜</th>
                        </tr>
                    </thead>*/}
                              <tbody>
                               
                              </tbody>
                            </table>
                            <div style={{width:"100%",textAlign:"right"}}>
                    {isloggedIn && (
                      <Link to="write"
                        style={{
                          padding: "10px 30px",
                          marginTop: "20px",
                          backgroundColor: "#555",
                          color: "white",
                          textAlign: "center",
                          position:"relative",
                          display:"inline-block",
                          width:"105px",
                          cursor: "pointer",
                        }}
                      >
                        <HiPencilAlt/>글쓰기
                      </Link>
                    )}
                    </div>
                  </div>
                  {isPage && (
                    <div className="paging">
                      <Pagination
                        postsPerPage={limit}
                        totalPosts={filterData.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>
                      {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
                    </div>
                  )}
                      </div>
                  </div>
          </form>
        </div>
      </div>
            
            
                
                
                
        </div>

                </div>
     </div>
    </div>

  );
};

export default Board;
