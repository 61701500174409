import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";

import "@fortune-sheet/react/dist/index.css"
import {GiHamburgerMenu} from "react-icons/gi";
import SendSchedule from "../components/SendSchedule";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";


import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../components/ScheduleDraftsList";
import BoardList from "../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { IoIosArrowDown } from "react-icons/io";
import {
  format
  
} from "date-fns";
import "./css/schedulew.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

var today = new Date();

const initialValues = {
  subject: "",
  group: "",
  allday:0,
  repeat: 0,
  start_date: format(today, "yyyy-MM-dd"),
  start_hour:"00",
  start_min:"00",
  end_date:"",
  end_hour:"00",
  end_min:"00",
  applicant:"",
  rep_cycle:"DAILY",
  rep_cycleN:"1",
  rep_end:"keep",
  rep_week_day: "",
  rep_type:"date",
  times:"0",
  rep_end_date:"",
  participants:[]
};
export default function BoardWrite() {
  const location = useLocation();
  const editorRef = useRef();
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [data, setData]=useState([]);
  const [members, setMembers] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [participants, setParticipants] = useState([]);
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [html, setHtml] = useState("");
  const [options, setOption] = useState("");
  const [showPopup, setPopup] = useState(false);
  const [editor, setEditor] = useState("toast");
  const [allday, setAllday] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [dateN, setDateN] = useState("");
  const [nth, setNth] = useState("");
  const [nthDay, setNthDay] = useState("");
  const [nthDayKr, setNthDayKr] = useState("");
  const [calendars, setCalendars]=useState([]);
  const [teamCalendars, setTeamCalendars]=useState([]);
  const [userCalendars, setUserCalendars]=useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [importance, setImportance] = useState("");
  const [user, setUser]=useState([]);
  const [users, setUsers]=useState([]);

  const togglePopup = () => {
    setPopup(!showPopup);
};
  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };

  var CLIENT_ID = "103373930583-rnu1qv7i5vr7ng7b2ma5o5r2p9vaiqu4.apps.googleusercontent.com"
  var API_KEY = "AIzaSyCDeeCLkx3dIqf-6aQ_CRJ6vopUxJXwnzE"
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar"
  var calendarID=location.state.schedule.calendarID;
  var eventID =location.state.schedule.googleID;
  var gapi = window.gapi

  const addEvent = () => {

    //gapi.load("client:auth2", () => { gapi.client.init({ clientId: "*****.apps.googleusercontent.com", plugin_name: "chat", }); });
 
 
 
     gapi.load('client:auth2', () => {
       console.log('loaded client')
 
       gapi.client.init({
         apiKey: API_KEY,
         clientId: CLIENT_ID,
         discoveryDocs: DISCOVERY_DOCS,
         scope: SCOPES,
         plugin_name:'GM Medical'
 
       })
 
       gapi.client.load('calendar', 'v3', () => console.log('bam!'))
       gapi.auth2.getAuthInstance().signIn()
       .then(() => {
         
        var request = gapi.client.calendar.events.delete({
          'calendarId': calendarID,
          'eventId' : eventID,
        })

        request.execute(event => {
          console.log(event)
          window.open(event.htmlLink)
        })
        
         request.execute(event => {
           console.log("hihi", event.id);
           //window.open(event.htmlLink)
         })
         
 
      
       
     
 
       })
     })
   }
 

   

  useEffect(() => {
    console.log("schedule is...", location.state.schedule);

    


    if (location.state.schedule.content_type === "excel"){
      //const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
      
      const htmlData = JSON.parse(location.state.schedule.content);
      htmlData.showGridLines=0;
      htmlData.ch_width = 600;
      console.log("htmldata", htmlData);
      setHtml(htmlData);
  
    }
    axios.get("https://gw.thegmmedical.com:5003/api/getuser").then((response) => {
      // setNotice(response.data);
      setUser(response.data[0]);
       
     });
    axios.get("https://gw.thegmmedical.com:5003/api/getcalendar").then((response) => {
      // setNotice(response.data);
      setCalendars(response.data);
       
     });

     if (location.state.schedule.work_no !== null){
      axios.get("https://gw.thegmmedical.com:5003/api/get_importance/"+location.state.schedule.work_no).then((response) => {
      // setNotice(response.data);
      setImportance(response.data[0].importance);
       
     });
     }
     axios.get("https://gw.thegmmedical.com:5003/api/get_teamcalendar").then((response) => {
      // setNotice(response.data);
      setTeamCalendars(response.data);
       
     });
     axios.get("https://gw.thegmmedical.com:5003/api/get_user_calendar").then((response) => {
      // setNotice(response.data);
      setUserCalendars(response.data);
       
     });
     console.log("스케쥴", location.state.schedule);
     
   
    
    
     let schedule = location.state.schedule;
     
  
  
    
     axios.get("https://gw.thegmmedical.com:5003/delivery_scheduleview/"+location.state.schedule.s_id)
     .then(function (response){
       console.log("hihihi", response);
       let scheduleInfo = {
        subject: response.data[0].subject,
        name: response.data[0].name,
        delivery_date:response.data[0].delivery_date,
        quantity: response.data[0].quantity,
        place: response.data[0].place,
        note:response.data[0].note
      };
      setValues(scheduleInfo);
      editorRef.current?.getInstance().setHTML(response.data[0].content);
      setContent(response.data[0].content);
      setData(response.data);console.log("data.data...", response.data);
   
     })
     .catch(function (error){
       console.log(error);
     })
     .then(function (){
       //always executed
     });

     /*const output = document.createElement('p')
     var section1s = document.getElementsByClassName("write_table");
     console.log(renderToString(section1s));*/
     

}, []);

function getNth(dat) {
  var days = ['일', '월', '화', '수', '목', '금','토'],
      days_en  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
      d    = dat ? dat instanceof Date ? dat : new Date(dat) : new Date(),
      date = d.getDate(),
      day  = d.getDay(),
      n    = Math.ceil(date / 7);
  setNth(n);
  setNthDay(days_en[day]);
  setNthDayKr(days[day]);
  return n + ' ' + days[day];
}

const handleInputChange = (e) => {
  //const name = e.target.name 
  //const value = e.target.value 
  const { name, value } = e.target;

  setValues({
    ...values,
    [name]: value,
  });

  if (name === "rep_cycle" && value=== "monthly"){
    let d1 = values.start_date.split("-");
    setDateN(d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }else if (name === "rep_cycle" && value=== "yearly"){
    let d1 = values.start_date.split("-");
    setDateN(d1[1] +"-"+ d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }

  if (name === "start_date" && values.rep_cycle=== "monthly"){
    let d1 = value.split("-");
    setDateN(d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }else if (name === "start_date" && values.rep_cycle=== "yearly"){
    let d1 = value.split("-");
    setDateN(d1[1] +"-"+ d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }

  if (name === "end_date"){

    setValues({
      ...values,
      rep_end: "date",
    });
    values.rep_end = "date";
    setValues({
      ...values,
      [name]: value,
    });

  }
  if (name === "rep_end" && value === "keep"){
    setValues({
      ...values,
      end_date: "",
      times:"",
    });
    values.end_date = "";
    values.times = "";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "times" && value !== ""){
    setValues({
      ...values,
      end_date: "",
      rep_end: "times",
    });
    values.end_date = "";
    values.rep_end = "times";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "rep_cycle" && value === "weekly"){
    let date = values.start_date.split("-");
    let from = new Date(date[0], parseInt(date[1]) - 1, date[2]);
    let day = from.getDay();
    let days  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
    setValues({
      ...values,
      rep_week_day: days[day],
    });
    values.rep_week_day = days[day];
    setValues({
      ...values,
      [name]: value,
    });
  }
};
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const subjectChange = (event) => {
    setSubject(event.target.value);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };



function delSchedule() {
  var result = window.confirm("일정을 정말 삭제하시겠습니까?");
  if(result){
    const data={
      s_id : location.state.schedule.s_id
    }


    axios.post(
      "https://gw.thegmmedical.com:5003/api/delete_delivery_schedule",
      data
    ).then(( res ) => {

      if (location.state.schedule.googleID !==null && location.state.schedule.googleID !=="" && location.state.schedule.googleID !==undefined){
        addEvent();
      }
      
      navigate("/dsr");
    });
  }
}





 



  
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
 
  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const excelInitial = {
    name: "Sheet1",
    config: {
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
      },
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 38,
    status: 1,
    ch_width: 1361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    
    scrollLeft: 0,
    scrollTop: 0
  };
  

  
  
  function checkExtension(filename){
    var parts = filename.split('.');
    var extension = parts[parts.length-1];
    if (extension === "doc" || extension === "docx" || extension === "docm" || extension === "dot" || extension === "dotx"
    || extension === "odt" || extension === "xls" || extension === "xlsx" || extension === "xlsm"|| extension === "xlsb"
    || extension === "ppt"|| extension === "pptm"|| extension === "pptx"){
      return true;
    }else{
      return false;
    }
  }
  function absence(){
    
    if (participants.length>0){
      var filtered = participants.filter( el => el.user_no !== user.user_no);
      let user_nos = [];
      for (let i =0;i<filtered.length;i++){
        user_nos.push("("+filtered[i].user_no+")");
      }
      console.log("participants", participants, "user.user_no", user.user_no, "filtered", filtered, "user_nos", user_nos);
      const data={
        participants : JSON.stringify(user_nos),
        s_id : location.state.schedule.s_id
      }
      axios
      .post("https://gw.thegmmedical.com:5003/api/participants_update", data )
      .then(({ data }) => {
        alert("적용되었습니다.");
        setParticipants(filtered);
      
        //window.location.href="http://localhost:3000/schedule";
        //console.log("...." + data);
      });
    }
  
  }
  
  function filterArr(arr) {
    var x;
    for(x = 0; x < arr.length; x++)
    {
      arr[x]= arr[x].replace('(', '');
      arr[x]= arr[x].replace(')', '');

  

      
    }
    return arr
}

  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>           </div>
      )}
     <div className="content-wrap">
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
        <div className="side-menu">
        <BoardList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>   
      </div>
      <div className="main_content">
      {showPopup ? (
  <SendSchedule values = {values}
  onChange={(value) => setData(value.data)} closePopup={() => togglePopup()} />
) : null}

      <h3>일정 상세</h3>
      
    

      <div className="btns" style={{display:"flex", justifyContent:"space-between"}}>
              <div className="left">

              
              <Link to ="/dsr_update" state={{schedule:location.state.schedule, files : data}}>
              <button className="btn" style={{marginRight:"10px"}}>수정</button>
              </Link>
              <button className="btn" style={{marginRight:"10px"}} onClick={()=>delSchedule()}>삭제</button>

              {/*<button className="btn" style={{marginRight:"10px"}} onClick={()=>togglePopup()}>일정공유</button>*/}

              

           
             


              {location.state.schedule.work_no !==null && (
                <Link to ="/workview" state={{ subject: "", importance: importance, no: location.state.schedule.work_no }}>
                <button className="btn">업무로 이동</button></Link>
              )}
              </div>

          
            </div>
      <table className="write_table schedule view" style={{width:"100%"}}>

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  <tr>
  <td class="table_title2" nowrap="">
    제목
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <span>{values.subject}</span>
    </div>
    </td>
  </tr>



  <tr>
  <td class="table_title2" nowrap="">
    물품명
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <span>{values.name}</span>
    </div>
    </td>
  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    납품일
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <span>{values.delivery_date}</span>
    </div>
    </td>
  </tr>


  <tr>
<td class="table_title2" nowrap="">
  수량
</td>
<td class="table_list2_left">
  <div className="input-group">
  <span>{values.quantity}</span>
  </div>
      
		</td>
</tr>

<tr>
<td class="table_title2" nowrap="">
  납품장소
</td>
<td class="table_list2_left">
  <div className="input-group">
  <span>{values.place}</span>
  </div>
      
		</td>
</tr>

  
<tr>
<td class="table_title2" nowrap="">
  비고
</td>
<td class="table_list2_left">
  <div className="input-group">
  <span>{values.note}</span>
  </div>
      
		</td>
</tr>




</table>

     <div id="response">{links}</div>
 <div className="" style={{  margin: "20px auto", padding:"20px", height:"500px", border:"1px solid #ddd"}}>


      <div className="toastui-editor-contents" dangerouslySetInnerHTML={ {__html: content} }></div>
    {html!=="" && (
      <div style={{width:"100%", height:"800px"}} draggable={false}>
      <Workbook onOp={console.log} data={[html]} draggable={false} onChange={(data)=>{console.log("changed, ", data);}} onContextMenu={e=>{e.preventDefault();}} showToolbar={false} showFormulaBar={false} showSheetTabs={false}
      allowEdit={false} rowHeaderWidth={0} columnHeaderHeight={0}/>

      </div>
    )}
    </div>
  
   
      </div>
    </div>
    </div>
    </div>
  );
}
