import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { SidebarData } from './SidebarDataSetting';
import SubMenu from './SubMenuSetting';
import { IconContext } from 'react-icons/lib';

const Nav = styled.div`
  background: #15171c;
  height: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 1300px) {
    flex-direction:row;
  }
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
 
`;

const SidebarNav = styled.nav`
  background: #fff;
  border-right:1px solid #ddd;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  color:#000;
  top: 60px;
  left: 0;
  transition: 350ms;
  z-index: 10;

  @media only screen and (max-width: 1300px) {
    width:100%;
    height:auto;
    position: relative;
    flex-direction:row;
  }

`;
const Title = styled.div`
  color:#000;
  font-size:25px;
  text-align:left;
  margin:30px 0 30px 30px;
  @media only screen and (max-width: 1300px) {
    display:none;
  }
`;
const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
          <NavIcon to='#'>
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
        </Nav> 
        <SidebarNav sidebar={sidebar}>
          
          <SidebarWrap><Title>환경설정</Title>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;