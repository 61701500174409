import React, { useRef, useEffect,useMemo, useState } from "react";
import {GiHamburgerMenu} from "react-icons/gi";
import FolderTree from 'react-folder-tree';
import axios from "axios";
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from 'react-icons/fa';
import "./css/contacts.css";
import { Link, useParams } from "react-router-dom";

import styled from 'styled-components';
import 'react-folder-tree/dist/style.css';
axios.defaults.withCredentials = true; 

const Contacts = () => {
    const onTreeStateChange = (state, event) => console.log(state, event);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [toList, setTo] = useState([]);
  
    const [toDel, setToDel] = useState([]);

    const [folders, setFolders] = useState([]);

    const [addrs, setAddrs] = useState([]);
    const [department, setDepartment] = useState([]);
    const [myGroups, setMyGroups] = useState([]);
    const [currentTab, clickTab] = useState(0);
    const [confirmer, setConfirmer] = useState(0);

    function lookingForNodeWithParent( nodes, parentId ) {

        var arrayToReturn = [];
      
        for( var i = 0, length = nodes.length; i < length; i++ ) {
          if( nodes[i].d_parent === parentId ) {
              var node = nodes[i];
            
            arrayToReturn.push({
              name: node.d_name,
              no: node.d_no,
              children: lookingForNodeWithParent( nodes, node.d_no )
            });
          }
        }
        
        return arrayToReturn;
      }
      
      
      useEffect(() => {
     
        fetch("https://gw.thegmmedical.com:5003/api/getdepartment_group", {
            method: "get", // 통신방법
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
          })
          .then((res) => res.json())
          .then((json) => {
            console.log(json);  
      console.log(lookingForNodeWithParent( json, null ));
            //setBoxes(json[0].boxes);
            var array = lookingForNodeWithParent( json, null );
            //let newData = array.map(el => el[0]);
                //console.log(newData);
              

            console.log("lookingForNodeWithParent.......", array);
            return setFolders(array[0]);
    
            //console.log(boxes.boxes);
            //console.log(boxes.boxes);
          })
          .catch(() => {});
      




          axios.get('https://gw.thegmmedical.com:5003/api/getaddrs',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    setAddrs(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

   
    axios.get('https://gw.thegmmedical.com:5003/api/get_teamaddr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setDepartment(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/get_user_addr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setMyGroups(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });



      }, []);
    
   

      const [selectedItem, setSelectedItem] = useState(null);

   

  const onNameClick = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();
      
    const {
      path, name, checked, isOpen, children
    } = nodeData;
      
        

    const item = name;
    const tempArray = [...options, item];
      
    axios.post('https://gw.thegmmedical.com:5003/api/getcontacts',{
      data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
        folder_name: name
      },
    })
    .then(function (response){
    
      setOptions(response.data);
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

  };

  const onNameClickAll = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();
      
    const {
      path, name, no, isOpen, children
    } = nodeData;
      
        
    console.log("onNameClickAll, ", nodeData, no);
    const item = name;
    const tempArray = [...options, item];

    
    axios.post('https://gw.thegmmedical.com:5003/api/getcontacts_all',{
      data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
        folder_no: no
      },
    })
    .then(function (response){
    
      setOptions(response.data);
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

  };
      const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
        axios.post('https://gw.thegmmedical.com:5003/api/getcontactsbyinput',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              input: e.target.value
            },
          })
            .then(function (response){
          
            setOptions(response.data);
            })
            .catch(function (error){
            console.log(error);
            })
            .then(function (){
            //always executed
            });
        }
      }
      const searchword = (e) => {
        const value = e.target.getAttribute("value");
        console.log(value);
        axios.post('https://gw.thegmmedical.com:5003/api/getcontactsbyword',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              input: value
            },
          })
          .then(function (response){
            
            setOptions(response.data);
          
          })
          .catch(function (error){
            console.log(error);
          })
          .then(function (){
       
          });
        
      }
      function getSelectValues(select) {
        var result = [];
        var option = select && select.options;
        var opt;
      

        for (var i=0, iLen=option.length; i<iLen; i++) {
          opt = option[i];
            
          if (opt.selected) {
            let tmp_idx = opt.value;
            result.push(options[tmp_idx]); 
          }
        }
        
        return result;
      }

function getUnique(arr, comp) {

  // store the comparison  values in array
const unique =  arr.map(e => e[comp])

// store the indexes of the unique objects
.map((e, i, final) => final.indexOf(e) === i && i)

// eliminate the false indexes & return unique objects
.filter((e) => arr[e]).map(e => arr[e]);

return unique;
}


      function handleChange(e){
        
        setSelected(getSelectValues(e.target));
      };
      function handleTo(e){
        setToDel(getSelectValues(e.target));
        
      };
    
      const addList = () => {
        let temp = [];
        const toListEmail =  toList.map(e => e.email);
        if (selected.length>0){
          selected.forEach((element) => {
          if (!toListEmail.includes(element.email)) {
            temp.push(element);
          }
        });
        }else{
          temp.push(selected);
        }
        
          setTo([...toList, ...temp]);
          console.log(toList);
        };
      
        const delList = () => {
          for (let i=0;i<toDel.length;i++){
              setTo(toList.filter(item => item.email !== toDel[i].email));
          }
        };
     

     
      function register(){

            window.opener.postMessage({ toList: toList}, '*');
        
          
        
        window.close();
      }


      const TabMenu = styled.ul`
  font-weight: bold;
  box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%),
  inset 0 1px 0 0 rgb(100 121 143 / 12%);
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin:0;
 
 
  }
  .submenu {
  // 기본 Tabmenu 에 대한 CSS를 구현
    display: flex;
    /* justify-content: space-between;
    width: 380px;
    heigth: 30px; */
    padding: 10px;
    font-size: 12px;
    cursor:pointer;
    box-sizing:border-box;
    transition: 0.5s;

  

  }
  .submenu:hover {
  }
  .submenu:first-child {
    margin:0 20px;
    @media only screen and (max-width: 1300px) {
      margin:0;
    
    }
  }
  .focused {
   //선택된 Tabmenu 에만 적용되는 CSS를 구현
    color: rgb(21,20,20);
    border-bottom:2px solid #000;
    @media only screen and (max-width: 1300px) {
      border:none;
      background:#ddd;
    
    }
  }

  & div.desc {
    text-align: center;
  }
`;

const Desc = styled.div`
`;


const selectMenuHandler = (index) => {
  // parameter로 현재 선택한 인덱스 값을 전달해야 하며, 이벤트 객체(event)는 쓰지 않는다
  // 해당 함수가 실행되면 현재 선택된 Tab Menu 가 갱신.
  clickTab(index);
};

function selectAddrGroup(no){
  axios.get("https://gw.thegmmedical.com:5003/api/getaddr/"+no).then((response) => {

    
      setOptions(response.data);
  });
}

var menuArr = [

  { name: '기타', content: 
    <div className="directory-list">
     <table>
   
      <tr onClick={()=>{setSelected({name:"작성자", user_no:"작성자"});console.log("selected", selected)}} style={{ background: selected.name==="작성자" ? "#8aa3ee" : "white"}}>
        <th>1</th>
        <td>작성자</td>
        <td>작성자</td>
      </tr>

      <tr onClick={()=>{setSelected({name:"부서장", user_no:"부서장"});console.log("selected", selected)}} style={{ background: selected.name==="부서장" ? "#8aa3ee" : "white"}}>
        <th>2</th>
        <td>부서장</td>
        <td>작성자 부서의 부서장</td>

      </tr>

      <tr onClick={()=>{setSelected({name:"파트장", user_no:"파트장"});console.log("selected", selected)}} style={{ background: selected.name==="파트장" ? "#8aa3ee" : "white"}}>
        <th>3</th>
        <td>파트장</td>
        <td>작성자 부서의 파트장</td>

      </tr>

      <tr onClick={()=>{setSelected({name:"부서원", user_no:"부서원"});console.log("selected", selected)}} style={{ background: selected.name==="부서원" ? "#8aa3ee" : "white"}}>
        <th>4</th>
        <td>부서원</td>
        <td>작성자 부서의 부서원 모두</td>
      </tr>
      <tr onClick={()=>{setSelected({name:"전사원", user_no:"전사원"});console.log("selected", selected)}} style={{ background: selected.name==="전사원" ? "#8aa3ee" : "white"}}>
        <th>5</th>
        <td>전사원</td>
        <td>전체 사원</td>

      </tr>



     </table>
    </div> 
  },
  { name: '조직도', content: 
  <div className="directory-list">
  <FolderTree
    data={ folders }
    onChange={ onTreeStateChange }
    onNameClick={ onNameClickAll }
    showCheckbox={ false } 
    readOnly
  />
</div>},
  { name: '개별부서', content: 
  <div className="directory-list">
  <FolderTree
    data={ folders }
    onChange={ onTreeStateChange }
    onNameClick={ onNameClick }
    showCheckbox={ false } 
    readOnly
  />
</div>}
];
  



const [users, setUsers] = useState([]);

const [check, setCheck] = useState([]);
const [members, setMembers] = useState([]);
const [CheckList, setCheckList] = useState([]);
const [testData, setTestData] = useState([]);
const [showUser, setShowuser] = useState(false);
const [selectedMember, setSelectedMember]= useState();

function selectAll()  {
  let checkboxes 
       = document.getElementsByName('member');
  let all 
       = document.getElementsByName('all');
  
  checkboxes.forEach((checkbox) => {
    checkbox.checked = all[0].checked;
  })
  if (all[0].checked === true){
    setCheckList(options);
  }else{
    setCheckList([]);
  }
}

function selectAllDel()  {
  let checkboxes 
       = document.getElementsByName('select');
  let all 
       = document.getElementsByName('delall');
  //console.log(checkboxes)
  checkboxes.forEach((checkbox) => {
    checkbox.checked = all[0].checked;
    //console.log(checkbox);
  })
  console.log(members);
  if (all[0].checked === true){
    setCheck(members);
  }else{
    setCheck([]);
  }
}

const onChangeEach = (e, id) => {
  // 체크할 시 CheckList에 id값 넣기
  if (e.target.checked) {
     setCheckList([...CheckList, id]);
  // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
  } else {
     setCheckList(CheckList.filter((checkedId) => checkedId !== id));
  }
}
const onChangeCheck = (e, id) => {
// 체크할 시 CheckList에 id값 넣기
if (e.target.checked) {
   setCheck([...check, id]);
// 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
} else {
  setCheck(check.filter((checkedId) => checkedId !== id));
}
}
function lookingForNodeWithParent( nodes, parentId ) {

  var arrayToReturn = [];
  
  for( var i = 0, length = nodes.length; i < length; i++ ) {
    if( nodes[i].d_parent === parentId ) {
      var node = nodes[i];
        
      arrayToReturn.push({
        name: node.d_name,
        no : node.d_no,
        children: lookingForNodeWithParent( nodes, node.d_no )
      });
    }
  }
  return arrayToReturn;
}
  
  
  useEffect(() => {
 
    fetch("https://gw.thegmmedical.com:5003/api/getdepartment_group", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);  
  console.log(lookingForNodeWithParent( json, null ));
        //setBoxes(json[0].boxes);
        var array = lookingForNodeWithParent( json, null );
        //let newData = array.map(el => el[0]);
            //console.log(newData);
        return setTestData(array[0]);

        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});
  
  }, []);


 
 
  


function add(){
  console.log(selectedMember);
  window.opener.postMessage({ selectedMembers: members, users: users }, '*');



window.close();
}



const addMembers = () => {
console.log("CheckList: ", CheckList);
var tempArray = members;
for (let i=0;i<CheckList.length;i++){
if (!tempArray.includes(CheckList[i])){
  tempArray = [...tempArray, CheckList[i]];
}
}

console.log("CheckList: ", tempArray);
setMembers(tempArray);
}

const delMembers = () =>{
let all = document.getElementsByName('delall');
    all.forEach((checkbox) => {
      checkbox.checked = false;
    })
var tempArray = members;
console.log("check is...", check);
for (let i=0;i<check.length;i++){
tempArray= tempArray.filter(item => item.user_no !== check[i].user_no);
}

setMembers(tempArray);


}


    return (
        <div>
        <div style={{overflowY:"hidden", display:"flex", padding:"20px"}}>
            
            
    


          <div className="directory-wrap">


            <TabMenu>
      
      {menuArr.map((el,index) => (
          <Link to ={el.href}><li className={index === currentTab ? "submenu focused" : "submenu" }
          onClick={() => selectMenuHandler(index)}>{el.name}</li></Link>
        ))}
    </TabMenu>
    <Desc>
      <p>{menuArr[currentTab].content}</p>
    </Desc>


                
                <div className="directory-select" style={{justifyContent:"space-between"}}>
                    <div className="directory-search">
                    <div className="search-word">
                        <span onClick={searchword} value="가">ㄱ</span>
                        <span onClick={searchword} value="나">ㄴ</span>
                        <span onClick={searchword} value="다">ㄷ</span>
                        <span onClick={searchword} value="라">ㄹ</span>
                        <span onClick={searchword} value="마">ㅁ</span>
                        <span onClick={searchword} value="바">ㅂ</span>
                        <span onClick={searchword} value="사">ㅅ</span>
                        <span onClick={searchword} value="아">ㅇ</span>
                        <span onClick={searchword} value="자">ㅈ</span>
                        <span onClick={searchword} value="차">ㅊ</span>
                        <span onClick={searchword} value="카">ㅋ</span>
                        <span onClick={searchword} value="타">ㅌ</span>
                        <span onClick={searchword} value="파">ㅍ</span>
                        <span onClick={searchword} value="하">ㅎ</span>
                    </div>
                    <input type="text" onKeyDown={handleOnKeyPress}></input>
                    </div>

                    <select style={{width:"95%"}}
                multiple class="m_select" onChange={handleChange}>
                    { options.map((element, index) => <option key={index} value={index}>[{element.name}] {element.email} </option>) }
                </select>
               
                </div>
               
            </div>
            
            <div className="directory-btn">

<div className="directory-btns">
  <FaArrowAltCircleRight onClick={addList}/>
  <FaArrowAltCircleLeft onClick={delList}/>
        {/*<img src="http://images5.zioyou.com/skin_basic/color_blue/arrow01.gif" style={{cursor:"pointer"}} alt="" onClick={addTo}/>
        <img src="http://images5.zioyou.com/skin_basic/color_blue/arrow02.gif" style={{cursor:"pointer"}} alt="" onClick={delTo}/>*/}
    </div>
   
</div>

<div className="directory-wrap" style={{marginTop:"14px"}}>
   
<div className="directory-select" style={{height:"95%"}}>
    <div className="sub-title">확인라인 권한부여자</div>
    <select name="cmbSelected" style={{height:"100%"}} 
    multiple class="m_select" onChange={handleTo}>
        { toList.map((element, index) => <option key={index} value={element}>[{element.name}] {element.email}</option>) }
    </select>
    </div>





</div>
                

        </div>
        <div style={{width:"100%", textAlign:"center"}}>
          <button style={{padding:"5px 20px", border:"none", backgroundColor:"#eee"}} onClick={register}>등록</button>
        </div>
      </div>
    )
}
export default Contacts;
