import { useParams , Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import WorkList from "../components/side/WorkList";
import axios from "axios";
import "./css/work.css";
import FolderTree, { testData } from 'react-folder-tree';
import { HiPencilAlt } from "react-icons/hi";
import Pagination from "./Pagination";
import WorkReportWrite from "../components/WorkReportWrite";
import { BsPin } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";


import {
  startOfWeek,
  format,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  parse,
  getMonth,
  getYear,
  addWeeks,
  addYears,
  subWeeks,
  subDays,
  getWeek,
  isWeekend,
  getDay,
  differenceInCalendarWeeks,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears
  
} from "date-fns";
//import hi from '../excel/93.json';


const Board = () => {
    const [data, setData] = useState([]);
    const [sidebar, setSidebar] = useState(false);

    const [isActive, setActive] = useState(false);
    var { periodical } = useParams();
    const [parent, setParent] = useState("");
    const [name, setName] =useState("");
    const [num, setNum] = useState(0);
    const [lev, setLev] = useState(0);
    const [selected, setSelected]= useState();
    const [search, setSearch] = useState("");
    const [boardN, setBoardN] = useState("");
    const [boardNum, setBoardNum] = useState();
    const [options, setOption] = useState("subject");
    const [filterData, setfilterData] = useState([]);
    const [dates, setDates] = useState([]);
    const [filterDate, setFilterDate] = useState([]);
    const [title, setTitle]=useState("");
    const [year, setYear] = useState(new Date().getFullYear());
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([1,2,3,4,5,6,7,8,9,10,11,12]);
    const [startWeek, setStartWeek] = useState("");
    const [endWeek, setEndWeek] = useState("");
    const [weekNums, setWeekNums] = useState([]);
    const [month, setMonth] = useState("");
    

    const [popup, setPopup] = useState(false);
    const [notice, setNotice] = useState([]);
    const [isEmpty, setEmpty] = useState(false);
    const [isPage, setIsPage] = useState(false);
    const [isloggedIn, setLoggedIn] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [endDate, setEndDate] = useState("");


    const offset = (page - 1) * limit;
    const { state } = useLocation();
    const toggleText = () => {
      setActive(!isActive);
    };
    const onChangeSearch = (e) => {
      e.preventDefault();
      setSearch(e.target.value);
    };
    const togglePopup = () => {
      setPopup(!popup);
  };
  
    
    useEffect(() => {

      
    if (periodical === "daily"){
      setTitle("일일 업무일지");
    }else if (periodical === "weekly"){
      setTitle("주간 업무일지");
    }else if (periodical === "monthly"){
      setTitle("월간 업무일지");
    }

      let dt = new Date();
      let com_year = dt.getFullYear();
      //setEndWeek(getWeek(dt));
      setStartWeek(getWeek(dt));
      setYear(com_year);
      // 현재 년도 자동 선택
      //$("#yearSel").append("<option value='" + com_year + "' selected>" + com_year + "년</option>");
      // 올해 기준으로 -5년부터 +5년을 보여준다.
      let year_arr = [];
      for (var y = (com_year - 5); y <= (com_year + 5); y++) {
        year_arr.push(y);
      }
      let weeknum_arr = [];
      for (let i=0;i<53;i++){
        weeknum_arr.push(i+1);
      }
      setWeekNums(weeknum_arr);
      setYears(year_arr);
         axios.get("https://gw.thegmmedical.com:5003/api/getreport/"+periodical).then((response) => {
        //setNotice(response.data);
        console.log(response.data);
        setData(response.data);
        setfilterData(response.data);
        if (response.data.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (response.data.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }
      });

      axios.get("https://gw.thegmmedical.com:5003/api/getreport_dates/"+periodical).then((response) => {
        //setNotice(response.data);
        console.log("dates..................",response.data);
        setDates(response.data);
        setFilterDate(response.data);
        if (response.data.length===0){
          setEmpty(true);
        }
       
      });
    
     
      
    }, []);


   



  const deleteDepartment = () => {
    const data ={
      name : name,
      parent : parent,
      num : num,
      level : lev
    }
    axios.post('https://gw.thegmmedical.com:5003/api/delete_department', data)
    .then(function (response){
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };


  const assign = () => {
    if (selected.name ===undefined){
    }
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    if (array[0].value==="selectall"){
      array.shift();
    }
    const data ={
      nos : array,
      folderName : selected.name,
      folderNo : selected.num
    }
    
    var result = window.confirm("선택된 사용자를 [" + selected.name+"] 부서에 지정합니다.");
    if(result){
      axios.post('https://gw.thegmmedical.com:5003/api/assign', data)
    .then(function (response){
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }
    
  };
  const onNameClick = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();
    var notice_data=[];
    var board_data=[];
    const {
      // internal data
      path, name, num, level, type, isOpen, children
      // custom data
    } = nodeData;
    setName(name);
      setNum(num);
    if (type!=="group"){
      var dropdown = document.getElementsByClassName('dropdown_div');
      var aTags = dropdown[0].getElementsByTagName("span");
      
var searchText;
var found;

        searchText=name;
      
for (var i = 0; i < aTags.length; i++) {
  if (aTags[i].textContent === searchText) {
    found = aTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('.editableNameContainer');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';
    console.log(name);
      
    

    }
    
};
  const addBookmark = () => {
    var result = window.confirm("즐겨찾기 게시판에 추가하시겠습니까?");
    if(result){
      const data ={
        name: boardN,
        num : boardNum,
        table : "board"
      }
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark', data)
    .then(function (response){
      console.log(response);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  };
  
  const selectHandle = (e) => {
    console.log("changed", e.target.value);

    setOption(e.target.value);
  };
  const onSearch = (e) => {
    e.preventDefault();
    var filtered_date = dates;
    var filtered=data;
    if (startDate !=="" && endDate !==""){
      
      
      const s = new Date(startDate);
      const e = new Date(endDate);

      

      filtered_date = dates.filter((itemList) => {
        return new Date(itemList.wr_register_date) >= s && new Date(itemList.wr_register_date) <= e;
      });
      console.log("filtered date....",filtered_date);
      setFilterDate(filtered_date);

    }
    if (startWeek !=="" && endWeek !=="" && year !==""){
      
    

      filtered_date = dates.filter((itemList) => {
        let s = parseInt(startWeek);
        let e = parseInt(endWeek);
        let y= year.toString();
      
        return itemList.week_no >= s && itemList.week_no <= e && itemList.year === y;
      });
      console.log("filtered date....",filtered_date);
      setFilterDate(filtered_date);

    }
    if (options === "subject" && search!=="") {
      filtered = filtered.filter((itemList) => {
        return itemList.wr_subject.toUpperCase().includes(search.toUpperCase());
      });
    
      setfilterData(filtered);
      

      
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    } else if (options === "all" && search!==""){
      
        
      filtered = filtered.filter((itemList) => {
        var tmp = "";
        tmp+=itemList.wr_content;
        tmp+=itemList.wr_subject;
        return tmp.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }else if (options === "content" && search!=="") {
      filtered = filtered.filter((itemList) => {
        return itemList.wr_content.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }else{
      setfilterData(filtered);
    }

    
    /* 기간별 조회 */

  };
  function getWeekText(week_no){
    console.log("hi",startOfWeek(week_no));
  }

  const addBookmarkPage = () => {
    var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
    if(result){

      const data ={
        name: '',
        num : '',
        link : window.location.pathname,
        bookmark : title
      }
      
      console.log(window.location.pathname)
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
      .then(function (response){
        console.log(response);
        alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };

  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
         <WorkList changeBoard={(value)=>{setfilterData(value);
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);}} changeBoardNum={(value)=>{setBoardNum(value);}}/>
        </div>
      )}
     <div className="content-wrap">
     
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>

        <div className="side-menu">

        
        <WorkList changeBoard={(value)=>{setfilterData(value);
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);}} changeBoardNum={(value)=>{setBoardNum(value);}}/>
        
        </div>
        
        <div className="board_wrap">
    
       
        <div className="report">
        
        <div className="board_wrapper">
        

        <div className="btns" style={{display:"flex",justifyContent:"start", alignItems:"center",margin:"0 0 20px 0", marginBottom:"20px"}}>
<div className="btn" style={{display:"flex", alignItem:"center", height:"15px",padding:"5px", marginRight:"7px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>
<h3>{title}</h3>
</div>


          <form name="form1" id="form1" method="post">
                  <div className="board_container">
                      <div id="content" className="content">
                          
                        <div className="board_top" style={{alignItems:"start"}}>
                        <div className="btns" style={{display:"flex",alignItems:"start"}}>
  <div className="btn" style={{marginRight:"10px"}} onClick={()=>togglePopup()}>일지 작성</div>
</div>



{popup ? (
  <WorkReportWrite type={periodical} closePopup={() => togglePopup()} />
) : null}

<div style={{margin:"0 0 20px"}}>{boardN}</div>
                              {/*<label>
                            페이지 당 게시물 수:&nbsp;
                            <select
                            type="number"
                            value={limit}
                            onChange={({ target: { value } }) => setLimit(Number(value))}
                            >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </label>*/}
                              <div className="bt_srch">
                                
                                <div className="bts_slct" style={{width:"auto"}}>
                                  
                                  {periodical==="daily"&&(
                                  <div style={{display:"flex"}}>
                                    <input type="date" style={{width:"100px"}} value={startDate} onChange={(e)=>setStartDate(e.target.value)}></input>
                                  <input type="date" style={{width:"100px"}} value={endDate} onChange={(e)=>setEndDate(e.target.value)}></input></div>)}

                                  {periodical==="weekly"&&(
                                  <div style={{display:"flex", width:"100%", justifyContent:"end"}}>
                                    <select value={year}
                                    onChange={(e)=>setYear(e.currentTarget.value)}>
                                      {years.map((ele, idx)=>(
                                        <option value={ele}>{ele}년</option>
                                      ))}
                                      
                                    
                                    </select>
                                    <select value={startWeek}
                                    onChange={(e)=>setStartWeek(e.currentTarget.value)}>
                                      <option>선택</option>
                                      {weekNums.map((ele,idx)=>(
                                        <option value={ele}>{ele}주</option>
                                      ))}
                                      
                                    </select>~
                                    <select value={endWeek}
                                    onChange={(e)=>setEndWeek(e.currentTarget.value)}>
                                        <option>선택</option>
                                      {weekNums.map((ele,idx)=>(
                                        <option value={ele}>{ele}주</option>
                                      ))}
                                    </select>
                                  </div>)}


                                  {periodical==="monthly"&&(
                                  <div style={{display:"flex", width:"100%", justifyContent:"end"}}>
                                    <select value={year}
                                    onChange={(e)=>setYear(e.currentTarget.value)}>
                                      {years.map((ele, idx)=>(
                                        <option value={ele}>{ele}년</option>
                                      ))}
                                      
                                    
                                    </select>
                                    <select value={month}
                                    onChange={(e)=>setMonth(e.currentTarget.value)}>
                                      <option>선택</option>
                                      {months.map((ele,idx)=>(
                                        <option value={ele}>{ele}월</option>
                                      ))}
                                      
                                    </select>
                                 
                                  </div>)}

                                </div>
                                <div style={{display:"flex"}}>
                                <div className="bts_slct">
                                  <select
                                    id="u_shcate"
                                    className="select"
                                    value={options}
                                    onChange={selectHandle}
                                  >
                                    <option value="all">업무명+내용</option>
                                    <option value="subject">업무명</option>
                                    <option value="content">내용</option>
                                  </select>

                                </div>
                                <div className="bt_input">
                                  <input
                                    type="text"
                                    id="search_content"
                                    name="search_content"
                                    value={search || ''}
                                    placeholder="검색어를 입력하세요"
                                    onChange={onChangeSearch}
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="search_btn"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                                </div>
                              </div>
                          </div>

                          <div className="work_list">

                          {isEmpty && (
                 <div style={{ textAlign: "center", border:"1px solid #ddd", padding:"50px 0"}}>
                   <span style={{padding:"50px 0"}}>작성된 업무 일지가 없습니다.</span>
                 </div>
               )}

                  {filterDate.map((element,index)=>(
 <div className="row">
 {periodical==="daily" && (<div className="box">{element.wr_register_date}</div>)}
 {periodical==="weekly" && (<div className="box">({element.week_text})-{element.week_no}주</div>)}
 {periodical==="monthly" && (<div className="box">({element.year})-{element.month}월</div>)}

 <table class="table table-hover bd-b">
 <thead class="thead-dark">
 <tr class="tx-center">
   <th class="">번호</th>
   <th class="wd-150 xl-hidden">업무명</th>
   <th class="wd-150 xl-hidden">제목</th>
   <th class="wd-120 xl-hidden">진척율</th>
   <th class="wd-80 xl-hidden">업무시간</th>
 </tr>
 </thead>
 <tbody>

            
               
               {periodical==="daily" && filterData
                 .filter((ele)=>ele.wr_register_date === element.wr_register_date)
                 .map(
                   ({
                     wr_subject, wr_no, subject, progress, workhour
                   }) => (
                     <tr key={wr_no}>
                     
                      <td>{wr_no}</td>
                     
                       <td>
                       <Link to={"/workrpt"} state={{subject : subject, periodical: periodical, no: wr_no}}>{subject}</Link>
                       </td>
                       <td>
                        {wr_subject}
                       </td>
                       <td>
                       {progress}%
                       </td>
                       <td>
                        {workhour}시간
                       </td>
                    
                     </tr>)
                    
                   
                 )}
        {periodical==="weekly" && filterData
                 .filter((ele)=>ele.week_no === element.week_no)
                 .map(
                   ({
                    wr_subject, wr_no, subject, progress, workhour
                  }) => (
                     <tr key={wr_no}>
                     
                      <td>{wr_no}</td>
                     
                       <td>
                       <Link to={"/workrpt"} state={{subject : subject, periodical: periodical, no: wr_no}}>{subject}</Link>
                       </td>
                       <td>
                        {wr_subject}
                       </td>
                       <td>
                       {progress}%
                       </td>
                       <td>
                        {workhour}시간
                       </td>
                    
                     </tr>)
                    
                   
                 )}


{periodical==="monthly" && filterData
                 .filter((ele)=>ele.month === element.month)
                 .map(
                   ({
                    wr_subject, wr_no, subject, progress, workhour
                  }) => (
                     <tr key={wr_no}>
                     
                      <td>{wr_no}</td>
                     
                       <td>
                       <Link to={"/workrpt"} state={{subject : subject, periodical: periodical, no: wr_no}}>{subject}</Link>
                       </td>
                       <td>
                        {wr_subject}
                       </td>
                       <td>
                       {progress}%
                       </td>
                       <td>
                        {workhour}시간
                       </td>
                    
                     </tr>)
                    
                   
                 )}

 </tbody>
</table>
</div>  
                  ))}    
                 
                            <div style={{width:"100%",textAlign:"right"}}>
                    {isloggedIn && (
                      <Link to="write"
                        style={{
                          padding: "10px 30px",
                          marginTop: "20px",
                          backgroundColor: "#555",
                          color: "white",
                          textAlign: "center",
                          position:"relative",
                          display:"inline-block",
                          width:"105px",
                          cursor: "pointer",
                        }}
                      >
                        <HiPencilAlt/>글쓰기
                      </Link>
                    )}
                    </div>
                  </div>
                  {isPage && (
                    <div className="paging">
                      <Pagination
                        postsPerPage={limit}
                        totalPosts={filterData.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>
                      {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
                    </div>
                  )}
                      </div>
                  </div>
          </form>
        </div>
      </div>
            
            
                
                
                
        </div>

                </div>
     </div>
    </div>

  );
};

export default Board;