import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import { Sheet, Op, Selection, colors } from "@fortune-sheet/core";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import Overlay from "../components/Overlay";

import SendWork from "../components/SendWork";

import {GiHamburgerMenu} from "react-icons/gi";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../components/ScheduleDraftsList";
import WorkList from "../components/side/WorkList";
import { Tooltip } from "react-tooltip";
import { IoIosArrowDown } from "react-icons/io";
import {
  format
  
} from "date-fns";
import "./css/schedulew.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

var today = new Date();

const initialValues = {
  subject: "",
  group: "",
  allday:0,
  repeat: 0,
  start_date: format(today, "yyyy-MM-dd"),
  start_hour:"00",
  start_min:"00",
  end_date:"",
  end_hour:"00",
  end_min:"00",
  applicant:"",
  rep_cycle:"everyday",
  rep_cycleN:"1",
  rep_end:"keep",
  rep_week_day: "",
  rep_type:"date",
  times:"0",
  rep_end_date:"",
  participants:[]
};
export default function BoardWrite() {
  const [sidebar, setSidebar] = useState(false);
  const hour = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  const location = useLocation();
  const editorRef = useRef();
  const excelRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [data, setData]=useState([]);
  const [members, setMembers] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [link, setLink]=useState("");

  const [fileArray, setArray] = useState([]);
  const [participants, setParticipants] = useState([]);
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [options, setOption] = useState("");
  const [showPopup, setPopup] = useState(false);
  const [editor, setEditor] = useState("toast");

  const [workers, setWorkers]=useState([]);
  const [user, setUser]=useState([]);
  const [attach, setAttach]=useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(true);
  
  const togglePopup = () => {
    setPopup(!showPopup);
};
  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };
  const selectBoard = (e) => {
    e.preventDefault();
  };
  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };
  const selectEditor = (e) => {
    e.preventDefault();
    setEditor(e.target.value);
  };
  const toggleShow = () => {
    setShow(!show);
};
  const onTypeChange = e => {
    setType(e.target.value)
  }
  const endDateChange = e => {
    setEndDate(e.target.value)
  }
  useEffect(() => {
    console.log(location.state);
    let no = location.state.no;
    axios.get("https://gw.thegmmedical.com:5003/api/get_workattach/"+no).then((response) => {
      setAttach(response.data);
      
    });
     axios.get("https://gw.thegmmedical.com:5003/api/workview/"+no)
     .then(function (response){
       console.log(response);
       setData(response.data);console.log("data.data...", response.data);
       setValues(response.data[0]);
       console.log(response.data[0].workers);
       axios.get("https://gw.thegmmedical.com:5003/api/getuserinfo/"+response.data[0].PIC).then((response) => {
        setUser(response.data[0]);
        
       });
       

       if (response.data[0].workers.length>0){
        console.log("hihihihi");
        let workers = JSON.parse(response.data[0].workers);
        let user_nos = [];
        if (workers !== null && workers.length>0){
        for (let w = 0;w<workers.length;w++){
        let worker = workers[w];
        console.log("참석자ㅛㅇ", worker);
        let worker_no = worker.substring(1, worker.length-1);
        console.log(worker);
        user_nos.push(worker_no);
        }
       }
       const data ={
        user_nos : user_nos
       }
        var people =  [];
        axios.post("https://gw.thegmmedical.com:5003/api/get_participants", data).then((response) => {
          people = response.data;
          setWorkers(response.data);
          
        });
       }
   
     })
     .catch(function (error){
       console.log(error);
     })
     .then(function (){
       //always executed
     });

     /*const output = document.createElement('p')
     var section1s = document.getElementsByClassName("write_table");
     console.log(renderToString(section1s));*/
     

}, []);



const handleInputChange = (e) => {
  //const name = e.target.name 
  //const value = e.target.value 
  const { name, value } = e.target;

  setValues({
    ...values,
    [name]: value,
  });

 

 

  if (name === "end_date"){

    setValues({
      ...values,
      rep_end: "date",
    });
    values.rep_end = "date";
    setValues({
      ...values,
      [name]: value,
    });

  }
  if (name === "rep_end" && value === "keep"){
    setValues({
      ...values,
      end_date: "",
      times:"",
    });
    values.end_date = "";
    values.times = "";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "times" && value !== ""){
    setValues({
      ...values,
      end_date: "",
      rep_end: "times",
    });
    values.end_date = "";
    values.rep_end = "times";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "rep_cycle" && value === "weekly"){
    let date = values.start_date.split("-");
    let from = new Date(date[0], parseInt(date[1]) - 1, date[2]);
    let day = from.getDay();
    let days  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
    setValues({
      ...values,
      rep_week_day: days[day],
    });
    values.rep_week_day = days[day];
    setValues({
      ...values,
      [name]: value,
    });
  }
};
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const subjectChange = (event) => {
    setSubject(event.target.value);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };



function delWork() {
  var result = window.confirm("업무를 정말 삭제하시겠습니까?");
  if(result){
    const data={
      w_no : location.state.no
    }
    axios.post(
      "https://gw.thegmmedical.com:5003/api/delete_work",
      data
    ).then(( res ) => {
      navigate("/work");
    });
  }
}

function shareSchedule(){

}
 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {

    
    
    console.log(editorRef.current?.getInstance().getHTML());

    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`subject`, values.subject);
    formData.append(`group`, values.group);

    formData.append(`start_date`, values.start_date);
    formData.append(`start_hour`, values.start_hour);
    formData.append(`start_min`, values.start_min);


    formData.append(`end_date`, values.end_date);
    
    
    formData.append(`end_hour`, values.end_hour);
    formData.append(`end_min`, values.end_min);

    formData.append(`rep_cycle`, values.rep_cycle);
    formData.append(`rep_cycleN`, values.rep_cycleN);
    formData.append(`rep_end`, values.rep_end);
    formData.append(`rep_week_day`, values.rep_week_day);

 
    formData.append(`times`, values.times);
    formData.append(`rep_end_date`, values.rep_end_date);
    let user_nos = [];
    for (let i =0;i<values.participants.length;i++){
      user_nos.push("("+values.participants[i].user_no+")");
    }
    formData.append(`participants`, JSON.stringify(user_nos));

  
    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/scheduleinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/schedule";
          //console.log("...." + data);
        });
    };

   fetch();
   


  
  };

  const handleDraftButton = () => {
 
    
    console.log(editorRef.current?.getInstance().getHTML());

    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`subject`, values.subject);
    formData.append(`group`, values.group);

    formData.append(`start_date`, values.start_date);
    formData.append(`start_hour`, values.start_hour);
    formData.append(`start_min`, values.start_min);

    formData.append(`end_date`, values.end_date);
    
    
    formData.append(`end_hour`, values.end_hour);
    formData.append(`end_min`, values.end_min);

    formData.append(`rep_cycle`, values.rep_cycle);
    formData.append(`rep_cycleN`, values.rep_cycleN);
    formData.append(`rep_end`, values.rep_end);
    formData.append(`rep_week_day`, values.rep_week_day);

    formData.append(`times`, values.times);
    formData.append(`rep_end_date`, values.rep_end_date);
    let user_nos = [];
    for (let i =0;i<values.participants.length;i++){
      user_nos.push("("+values.participants[i].user_no+")");
    }
    formData.append(`participants`, JSON.stringify(user_nos));

  
    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/schedule_draftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/schedule";
          //console.log("...." + data);
        });
    };

   fetch();
   



  };

 

  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
  window.addEventListener('message', (event) => {
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      var tempArray = members;
      for (let i=0;i<temp.length;i++){
        if (!tempArray.includes(temp[i])){
          
          tempArray = [...tempArray, temp[i]];
        }
      }
      const map = new Map(
        tempArray.map(obj => [obj.user_no, obj])
      );

      const deduplicatedArr = [...map.values()];
        console.log(deduplicatedArr);
      setValues({
        ...values,
        participants: deduplicatedArr,
      })
      values.participants = deduplicatedArr;
      
      
    }
 
  });

  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const excelInitial = {
    name: "Sheet1",
    config: {
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
      },
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 38,
    status: 1,
    ch_width: 1361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    
    scrollLeft: 0,
    scrollTop: 0
  };
  

  
  
  function addSchedule(){
    var result = window.confirm("업무를 캘린더에 등록하시겠습니까? [내일정]에 등록됩니다.");
    if(result){
      axios.post("https://gw.thegmmedical.com:5003/api/addschedule", values)
      .then(({ data }) => {
        console.log("what is this");
        window.location.href="https://gw.thegmmedical.com/schedule";
      });
    }

  }
  function checkExtension(filename){
    var parts = filename.split('.');
    var extension = parts[parts.length-1];
    if (extension === "doc" || extension === "docx" || extension === "docm" || extension === "dot" || extension === "dotx"
    || extension === "odt" || extension === "xls" || extension === "xlsx" || extension === "xlsm"|| extension === "xlsb"
    || extension === "ppt"|| extension === "pptm"|| extension === "pptx"){
      return true;
    }else{
      return false;
    }
  }
  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <WorkList/>
        </div>
      )}
     <div className="content-wrap">
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
        <div className="side-menu">
      <WorkList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>
      </div>
      <div className="main_content">
      {showPopup ? (
  <SendWork values = {values}
  onChange={(value) => setData(value.data)} closePopup={() => togglePopup()} />
) : null}

<Overlay isOpen={isOpen} onClose={toggleOverlay} link = {link}>
  <h1>Content in overlay</h1>
</Overlay>

    <div className="btns">
      <h3>업무 상세</h3>
    </div>
      
      
    

      <div className="btns" style={{display:"flex", justifyContent:"space-between"}}>
              <div className="left">

              
              <Link to ="/work_u" state={{no:location.state.no, importance : location.state.importance, files : attach}}>
              <button className="btn" style={{marginRight:"10px"}}>수정</button>
              </Link>
              <button className="btn" style={{marginRight:"10px"}} onClick={()=>delWork()}>삭제</button>

              <button className="btn" style={{marginRight:"10px"}} onClick={()=>togglePopup()}>업무공유</button>

              <button className="btn" onClick={()=>addSchedule()}>캘린더 등록</button>
              </div>

          
            </div>
      <table className="write_table schedule view" style={{width:"100%"}}>

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  <tr>
  <td class="table_title2" nowrap="">
    제목
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", alignItems:"center"}}>
    <span>{values.subject}</span> {values.importance === "true" && (<div
                                          class="status"
                                          style={{
                                            backgroundColor: "red",
                                            width: "30px",
                                            color: "white",
                                            padding:"2px 5px",
                                            marginLeft:"10px"
                                          }}
                                        >
                                          긴급
                                        </div>)} 
    </div>
    </td>
  </tr>

  <tr>
  <td class="table_title2" nowrap="">
    상태
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <span>{values.status}</span>
    </div>
    </td>
  </tr>


  <tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    일시
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <div className="date">
      <span>시작 </span>
      <span>&nbsp; :&nbsp; {values.start_date}</span>
      <span>&nbsp; &nbsp;{values.start_hour} : {values.start_min}</span>
      
      </div>
    <div className="date"><span>종료 </span> 
    <span>&nbsp; :&nbsp; {values.end_date}</span>
    <span>&nbsp; &nbsp;{values.end_hour} : {values.end_min}</span>
    </div>
    </div>
    </td>
  </tr>


  <tr>
  <td class="table_title2" nowrap="">
    작업책임자
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <span style={{marginRight:"10px",width:"70%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{user.d_name} {user.name} {user.job_name}[{user.email}]</span>
    </div>
    </td>
  </tr>

  <tr>
<td class="table_title2" nowrap="">
  작업자/참조자
</td>

<td class="table_list2_left">
        <div className="input-group">
        <div className="list" style={{display:"flex"}}>
              {workers.length>0 && workers.map((element,index)=>(
                <li key={element.user_no}>
                {element.name} [{element.email}]; &nbsp;
              </li>
              ))}
              
        </div>

        

      </div>
      
		</td>
</tr>
</table>

     <div id="response">{links}</div>
 <div className="" style={{margin: "20px auto", height:"500px", border:"1px solid #ddd", padding:"10px"}}>

 {(attach !==undefined && attach.length>0) &&   
<div className="attachment">
  <IoIosArrowDown onClick={() => setToggle(!toggle)} style={{cursor:"pointer"}}/><span>첨부파일 {data.length}개 </span>
  {toggle && (

  <div className="attachment_box">
    {attach.map((element, idx) => {
    return <div><a href={"https://gw.thegmmedical.com/uploads/work/"+location.state.no+"/"+element.filename} download>{element.filename} &nbsp;&nbsp;&nbsp;
    <span className="attachment_size">{element.size} Bytes</span></a> &nbsp;&nbsp;&nbsp;
      {checkExtension(element.filename) && (
        <button onClick={()=>{toggleOverlay(); setLink("https://gw.thegmmedical.com/uploads/work/"+location.state.no +"/"+element.filename);}}>오피스뷰어로 보기</button>
      )}  



</div>
    })}
    </div>)}
  </div>

  }


 <div className="toastui-editor-contents" style={{padding:"20px"}} dangerouslySetInnerHTML={ {__html: values.content} }></div>

    </div>
   
      
   
      </div>
    </div>
    </div>
    </div>
  );
}
