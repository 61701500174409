

import { NavLink, Link,useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect} from "react";
import './css/view.css';
import WorkList from "../components/side/WorkList";
import AutoComplete from "../components/AutoCompleteLine";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import {GiHamburgerMenu} from "react-icons/gi";
axios.defaults.withCredentials = true; 
const initialValues = {
  name: "",
  color: "",
  group:"all",
  d_no: "0",
  in_use: "use",
  desc:"",
  expire:"",
 
};

let currentData = [];

const WorkGroupAdd = () => {
  const [values, setValues] = useState(initialValues);
  const location = useLocation();
  const [color, setColor] = useState("#000000");
  const [colorPicker, setColorPicker] = useState(false);
  const [showDepartments, SetShowDepartments] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [value, setValue] = useState("");
  const [viewers, setViewers] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [users, setUsers]=useState([]);
  const [, updateState] = React.useState();

  //const [shData, setShData]= useState();
  const navigate = useNavigate();

  useEffect(()=>{

    console.log("bbbb", currentData, location.state.group)
    if(currentData !== location.state.group) setValues(initialValues);
     
    currentData = location.state.group;
    setValues(location.state.group);
    console.log(location.state.group);
    
    let viewers = JSON.parse(currentData.viewer);
    let viewer_nos = [];
   for (let i = 0;i<viewers.length;i++){
     let viewer = viewers[i].substring(1, viewers[i].length-1);
     viewer_nos.push(viewer);
   }
   const data ={
    user_nos : viewer_nos
   }
   console.log("viewer_nos", data);

   axios.post("http://gw.thegmmedical.com:5003/api/get_participants", data).then((response) => {
    var id_no = 1;
    var tempArray=[];
    console.log("응응", response.data);
    for (let i=0;i<response.data.length;i++){
      const item = { id: id_no, name: response.data[i].name, user_no: response.data[i].user_no, email : response.data[i].email, check : false  };
      tempArray = [...tempArray, item];
      console.log(item);
      
      id_no++;
    }
    console.log(tempArray);
    setViewers(tempArray);
     //setViewers(response.data);

      
    });

    let writers = JSON.parse(currentData.writer);
    console.log("writers", writers);
    let writer_nos = [];
    for (let i = 0;i<writers.length;i++){
      let writer = writers[i].substring(1, writers[i].length-1);
      writer_nos.push(writer);
    }
    const writer_data ={
      user_nos : writer_nos
    }
   console.log('writer_data', writer_nos);

   axios.post("http://gw.thegmmedical.com:5003/api/get_participants", writer_data).then((response) => {
    var id_no = 1;
    var tempArray=[];
    console.log("응응", response.data);
    for (let i=0;i<response.data.length;i++){
      const item = { id: id_no, name: response.data[i].name, user_no: response.data[i].user_no, email : response.data[i].email, check : false  };
      tempArray = [...tempArray, item];
      console.log(item);
      
      id_no++;


    }
    console.log(tempArray);
    setRecs(tempArray);
     //setViewers(response.data);

      
    });


    axios.get('http://gw.thegmmedical.com:5003/api/getdepartment')
    .then(function (response){
    console.log("hey result is!!!!!!!!!!!!", response.data);
    setDepartments(response.data);
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('http://gw.thegmmedical.com:5003/api/check_login')
    .then(function (response){
    //console.log("hey result is!!!!!!!!!!!!", response.data);
    setUsers(response.data[0]);
    
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
}, [location]);

  const handleRegisterButton = () => {
    currentData=[];
    console.log(values);
    if (values.name === "") {
      alert("내용을 입력해주세요.");
      return false;
    } 
   
    const formData = new FormData();
   /*const data = {
      subject : values.subject,
      form_name : values.form_name,
      form_group : values.form_group,
      in_use : values.in_use,
      cc : ccs,
      recipient : recs,
      expire : values.expire,
      security : values.security,
      form_data : test

    }*/
   

    formData.append(`subject`, values.name);
    formData.append(`color`, color);
    formData.append(`group`, values.group);

    formData.append(`d_no`, values.d_no);
    formData.append(`in_use`, values.in_use);
    formData.append(`viewer`, JSON.stringify(ccs));
    formData.append(`writer`, JSON.stringify(admins));
    formData.append(`expire`, values.expire);
    let viewer_list =[];
    for (let i =0;i<viewers.length;i++){
      viewer_list.push("("+viewers[i].user_no+")");
    }
    let writers =[];
    for (let i =0;i<recs.length;i++){
      writers.push("("+recs[i].user_no+")");
    }
    console.log("writers는.......!!!!!!!!!", writers);

    const data = {
    name : values.name,
    order : values.order_no,
    in_use : values.in_use,
    viewer : JSON.stringify(viewer_list),
    writer : JSON.stringify(writers),
    no : location.state.group.no

   }
   
    console.log(data);
    axios.post('http://gw.thegmmedical.com:5003/api/update_workgroup', data)
    .then(function (response){
      console.log(response);
      navigate('/workgrp_u', { state: {group: data }});
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
   



  };

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (name==="group" && value==="department"){
      SetShowDepartments(true);
    }else if (name==="group" && value!=="department"){
      SetShowDepartments(false);
      if (name==="group" && value === "group"){
        const item = { id: 1, name: users.name, user_no : users.user_no, email : users.email, check:false  };
        console.log("item...", item);
        var valueArr = viewers.map(function(item){ return "["+item.user_no+"]" });
        var valueArr2 = recs.map(function(item){ return "["+item.user_no+"]" });
        if (valueArr.includes("["+users.user_no+"]")){
          return false;
        }
        else{
          const tempArray = [...recs, item];
          setViewers(tempArray);
        }
        if (valueArr2.includes("["+users.user_no+"]")){
          return false;
        }
        else{
          const tempArray = [...recs, item];
          setRecs(tempArray);
        }
      }
    }
  };

  

  const [admin, setAdmin]=useState("");
  const [admins, setAdmins]=useState([]);
  

  const generateId_ad = () => {
    const highestId = Math.max.apply(Math, admins.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_ad() {
    if (admin !== '') {
      const tempStr = admin.substring(admin.indexOf("<")+1, admin.indexOf(">"));
      const item = { id: generateId_ad(), text: admin, email : tempStr  };
      console.log("item...", item);
      var valueArr = admins.map(function(item){ return item.text });
      if (valueArr.includes(admin)){
        return false;
      }
      else{
        const tempArray = [...admins, item];
        setAdmins(tempArray);
      }

      
    }
    setAdmin('');
  }

  function addAdmins() {
    console.log(admin);
    createNewToDoItem_ad();
  }
  const deleteItem_ad = id => {
    setAdmins(admins.filter(item => item.id !== id));
  };


  const display_admin = admins.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_ad(item.id)}>&times;</span>

    </div>
  ));


  
  const [rec, setRec]=useState("");
  const [recs, setRecs]=useState([]);
  

  const generateId_rec = () => {
    const highestId = Math.max.apply(Math, recs.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_rec() {
    if (rec !== '') {
      const tempStr = rec.substring(rec.indexOf("<")+1, rec.indexOf(">"));
      const item = { id: generateId_rec(), text: rec, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = recs.map(function(item){ return item.text });
      if (valueArr.includes(rec)){
        return false;
      }
      else{
        const tempArray = [...recs, item];
        console.log("dddddddddd...", tempArray);
        setRecs(tempArray);
      }

      
    }
    setRec('');
  }

  function addRecs() {
    var subStr = rec.substring(
      rec.indexOf("[") + 1, 
      rec.lastIndexOf("]")
  );
  const name = rec.substring(rec.indexOf(",")+1, rec.indexOf("<"));

  const tempStr = rec.substring(rec.indexOf("<")+1, rec.indexOf(">"));
      const item = { id: generateId_rec(), name: name, user_no: subStr, email : tempStr, check : false  };
      console.log("recssss...", recs);
      var valueArr = recs.map(function(item){ return "["+item.user_no+"]" });
      console.log("valueArr is...", valueArr, "["+subStr+"]" );
     

      if (valueArr.includes("["+subStr+"]")){
        console.log("이미 있으셈");
        return false;
      }
      else{
        const tempArray = [...recs, item];
        setRecs(tempArray);
      }

      console.log("viewers ,," , viewers);
      
  
        console.log("item...", item);
        var valueArr2 = viewers.map(function(item){ return "["+item.user_no+"]" });
        console.log("valueArr is...", valueArr, "["+subStr+"]" );
       
  
        if (valueArr2.includes("["+subStr+"]")){
          console.log("이미 있으셈");
          return false;
        }
        else{
          const tempArray = [...viewers, item];
          console.log("tempArray is...", viewers);
          setViewers(tempArray);
        }
  


  }
  const deleteItem_rec = id => {
    setRecs(recs.filter(item => item.id !== id));
  };


  const display_rec = recs.map(item => (
    <div className="admin"><li key={item.id}>
      <span>{item.name} [{item.email}]</span>
    </li>
    <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>&times;</span>

    </div>
  ));

  const [cc, setCc]=useState("");
  const [ccs, setCcs]=useState([]);
  

  const generateId_cc = () => {
    const highestId = Math.max.apply(Math, viewers.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_cc() {
    if (cc !== '') {
      const tempStr = cc.substring(cc.indexOf("<")+1, cc.indexOf(">"));
      const item = { id: generateId_cc(), text: cc, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = ccs.map(function(item){ return item.text });
      if (valueArr.includes(cc)){
        return false;
      }
      else{
        const tempArray = [...ccs, item];
        setCcs(tempArray);
      }

      
    }
    setCc('');
  }

  function addViewers() {
    console.log("viewers ,," , viewers);
    var subStr = value.substring(
      value.indexOf("[") + 1, 
      value.lastIndexOf("]")
  );
  const name = value.substring(value.indexOf(",")+1, value.indexOf("<"));

  const tempStr = value.substring(value.indexOf("<")+1, value.indexOf(">"));
      const item = { id: generateId_cc(), name: name, user_no: subStr, email : tempStr, check : false  };
      console.log("viewers...", viewers);
      var valueArr = viewers.map(function(item){ return "["+item.user_no+"]" });
      console.log("valueArr is...", valueArr, "["+subStr+"]" );
     

      if (valueArr.includes("["+subStr+"]")){
        console.log("이미 있으셈");
        return false;
      }
      else{
        const tempArray = [...viewers, item];
        console.log("tempArray is...", viewers);
        setViewers(tempArray);
      }



    //createNewToDoItem_cc();
  }
  
  const deleteItem_cc= id => {
    setViewers(viewers.filter(item => item.id !== id));
  };


  const display_viewers = viewers.map(item => (
    <div className="admin"><li key={item.id}>
      <span>{item.name} [{item.email}]</span>
    </li>
    <span className="del_btn" onClick={() => deleteItem_cc(item.id)}>&times;</span>

    </div>
  ));

 
function deleteGroup(){

  var result = window.confirm("업무함을 삭제하시겠습니까?");
  if(result){
  
    axios.get("http://gw.thegmmedical.com:5003/api/del_workgroup/"+location.state.group.no).then((response) => {
      navigate("/work");
    });

  }
  
}



 

  return (
    <div className="wrapper">

<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <WorkList/>
        </div>
      )}
<div className="content-wrap">
    
      
    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
        <div className="side-menu">
    <WorkList/>
</div>
    <div className="main_content">
  
    

    <div className="btns" style={{display:"flex", justifyContent:"space-between", 
    flexDirection:"column", width:"100%", margin:"0 auto"}}>
            
      <h4 style={{padding:"0 10px"}}>새 공유 업무함 추가 </h4>
          <div style={{background:"#fff", padding:"0 10px"}}>
         
          <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
          

         
  <table className="form_write" style={{margin:"10px 0", width:"100%"}}>
<tr>
<td class="table_title2" nowrap="">
  업무함 이름
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <input type="text" name="name" className="formInput" placeholder="이름을 입력하세요." value={values.name}
          onChange={handleInputChange}></input>
  </div>
  </td>
</tr>


<tr>

  <Tooltip
        id="date_guide"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>양식의 상태를 설정합니다.</span>
          </div>
        </Tooltip>
  <td class="table_title2" nowrap="">
    사용여부<BiInfoCircle data-tooltip-id="date_guide" style={{marginLeft:"5px"}}/>
  </td>
  <td class="table_list2_left">
  <div className="input-group radio" style={{display:"flex"}}>
      
        <input type="radio" value="use" name="in_use" checked={values.in_use === "use"} onChange={handleInputChange}/><label><span>사용</span>
      </label>
      <label>
        <input type="radio" value="no" name="in_use" checked={values.in_use === "no"} onChange={handleInputChange}/><span>사용안함</span>
      </label>
  </div>
  </td>

</tr>


<tr>
<td class="table_title2" nowrap="">
  정렬순서
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <input type="text" name="order_no" className="formInput" placeholder="" value={values.order_no}
          onChange={handleInputChange}></input>
  </div>
  </td>

 

</tr>




<tr>

<td class="table_title2" nowrap="">
  열람권한자
</td>
<td class="table_list2_left" >
        <div className="input-group">
        <div style={{display:"flex"}}>
               <AutoComplete onChange={(value) => {setValue(value); console.log("hihi", value)}}/>
                <button onClick={()=>addViewers()} style={{marginLeft:"10px"}}>추가</button>
            </div>
      </div>
      {viewers.length >0 &&(

      <div class="admin_list" style={{flexGrow:"0"}}>
            <ul>
                      {display_viewers}
                        </ul>
                    </div>
      )}
		</td>
</tr>
<tr>

<td class="table_title2" nowrap="">
  쓰기권한자
</td>
<td class="table_list2_left">
        <div className="input-group">
        <div style={{display:"flex"}}>
               <AutoComplete onChange={(value) => {setRec(value); console.log("hihi", value)}}/>
                <button onClick={()=>addRecs()} style={{marginLeft:"10px"}}>추가</button>
            </div>
      </div>
      {recs.length >0 &&(

      <div class="admin_list">
            <ul>
                      {display_rec}
                        </ul>
                    </div>
      )}
		</td>
</tr>






    </table>


    </div>

   

    </div>


    

    <div className="button_wrap">
    <button onClick={handleRegisterButton}>등록</button>
    <button onClick={()=>deleteGroup()}>삭제</button>

    {/*</form>*/}
    </div>
    </div>
  </div>
  </div></div>

</div>
  );
};

export default WorkGroupAdd;

