
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";
import React, { useState, useEffect, useRef } from "react";
import Popup from "../components/Popup";
import axios from "axios";
import "./css/adminUsers.css";
import Pagination from "./Pagination";
import addDays from "date-fns/addDays";

const AdminHolidayWrite = () => {
    const [sidebar, setSidebar] = useState(false);
    const [data, setData] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [showCreate, setCreate] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filterData, setFilterData] = useState([]);

    
    const [values, setValues] = useState([]);

    const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [year, setYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);

  const offset = (page - 1) * limit;

    const togglePopup = () => {
        setPopup(!showPopup);
        

    };
    const toggleCreate = () => {
      setCreate(!showCreate);
      

  };
 
    
 
    function getUser(){
      fetch("https://gw.thegmmedical.com:5003/api/get_company_holidays/"+year, {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("json", json);
        setData(json);
        setFilterData(json);
        
      })
      .catch(() => {});
    
    }
    useEffect(() => {
  
      let com_year= new Date().getFullYear();
      let year_arr = [];
      for (var y = (com_year - 5); y <= (com_year + 5); y++) {
        year_arr.push(y);
      }
     
      setYears(year_arr);
      getUser();
        
     
        
    
      
      }, []);
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('user');
        const all 
             = document.getElementsByName('userall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...checkList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(checkList.filter((checkedId) => checkedId !== id));
        }
        
     }

     const saveHoliday  = () => {
        axios.post('https://gw.thegmmedical.com:5003/api/write_holiday', values)
        .then(function (response){
          console.log(response);
          //getUser();
          alert("등록되었습니다.");
          window.location.href="https://gw.thegmmedical.com/holiday";
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      
     }

     const handleInputChange = (event) => {
      //setReason(event.target.value);
      
      const { name, value } = event.target;
  
      setValues({
        ...values,
        [name]: value,
      });
  
    };

  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents pw" style={{maxWidth:"1600px"}}>
        <div className="header_txt">
        <h3>휴무관리</h3>
        </div>
      

        <div className="main_contents_wrapper" style={{width:"100%"}}>
           
            <table className="write_table" style={{width:"100%", margin:"0"}}>

{/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}




<tr>
<td class="table_title2" nowrap="">
휴일일자
</td>
<td class="table_list2_left">
<div className="input-group" style={{display:"flex"}}>
<input type="date" name="datetext" className="formInput" style={{marginLeft:"10px"}} onChange={handleInputChange} value={values.datetext}></input>
</div>
</td>
</tr>

<tr>

<td class="table_title2" nowrap="">
휴일명
</td>
<td class="table_list2_left">
<div className="input-group" style={{display:"flex", flexShrink:"0"}}>
<input type="text" name="holiday_name" className="formInput" style={{marginLeft:"10px"}} onChange={handleInputChange} value={values.holiday_name}></input>
</div>
</td>
</tr>

<tr>
<td class="table_title2" nowrap="">
휴일설명
</td>
<td class="table_list2_left">
<div className="input-group" style={{width:"100%"}}>

<textarea type="text" name="description" className="formInput" onChange={handleInputChange} value={values.description}></textarea>

</div>

</td>
</tr>



<tr>
<td class="table_title2" nowrap="">
유무급구분
</td>
<td class="table_list2_left">
<div className="selectNotice">


<label htmlFor="1">
<input
type="radio"
name="paid"
value="1"
checked={values.paid === "1"}
onChange={handleInputChange}
/>
유급
</label>
<label htmlFor="0">
<input
type="radio"
name="paid"
value="0"
checked={values.paid === "0"}
onChange={handleInputChange}
/>
무급
</label>

</div>
</td>

</tr>


<tr>
<td class="table_title2" nowrap="">
연차사용여부
</td>
<td class="table_list2_left">
<div className="selectNotice">


<label htmlFor="1">
<input
type="radio"
name="annual_use"
value="1"
checked={values.annual_use === "1"}
onChange={handleInputChange}
/>
사용
</label>
<label htmlFor="0">
<input
type="radio"
name="annual_use"
value="0"
checked={values.annual_use === "0"}
onChange={handleInputChange}
/>
미사용
</label>

</div>
</td>

</tr>



</table>
<div style={{display:"flex", width:"100%", justifyContent:"center", marginTop:"20px"}}>
  <button onClick={()=>saveHoliday()}>저장</button>
</div>


        </div>
        {data.length >10 && (
        <Pagination
                        postsPerPage={limit}
                        totalPosts={data.length}
                        page={page}
                        paginate={setPage}
                      ></Pagination>

        )}
     </div>
    
    </div>

  );
};

export default AdminHolidayWrite;
