
import "./css/drafts.css";
import userImg from "./img/user.png";
//import Modal from "react-overlays/Modal";
import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

const DraftsList = ({onChange, closePopup }) => {
    const [list, setList]= useState([]);
    useEffect(() => {
        fetch("http://gw.thegmmedical.com:5003/api/get_scheduledrafts", {
          method: "get", // 통신방법
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        })
        .then((res) => res.json())
        .then((json) => {
            console.log(json);
            setList(json);
        })
        .catch(() => {});
    }, []);
    
 function applyDraft(element){
    var confirm = window.confirm("선택한 문서를 불러오시겠습니까?");
    if (confirm){
        onChange(element);
        closePopup();
    }
 }
  return (
    <div className="popup">
        <div className="popup_inner drafts" style={{height:"600px"}}>
        <h3 style={{textAlign:"left", marginLeft:"20px"}}>임시저장된 글 ({list.length}개)</h3>
        <ul className="draft_list">
            {list.map((element,index) => 
                <li onClick={()=>{applyDraft(element)}}><span>{element.subject}</span><span>{element.create_date}</span></li>
            )}
      </ul>

      <button onClick={closePopup}>닫기</button>
        </div>
    </div>
  );
};

export default DraftsList;