import { Fragment } from "react";
import "./css/overlay.css";

export function Overlay({ isOpen, onClose, link }) {
  return (
    <Fragment>
      {isOpen && (
        <div className="overlay">
          <div className="overlay__background" onClick={onClose} />
          <div className="overlay__container">
            <div className="overlay__controls">
              <button
                className="overlay__close"
                type="button"
                onClick={onClose}
              />
            </div>
            <iframe src={"http://view.officeapps.live.com/op/view.aspx?src="+link}
style={{width:"100%", height:"100%"}} id="ifmOfficePreview" name="ifmOfficePreview" title="viewer"></iframe>

          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Overlay;