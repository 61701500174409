
import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {FaAddressBook} from "react-icons/fa";
import Modal from "react-overlays/Modal";


import "../css/setting.css";
const BoxSetting = () => {
  const [ruleName, setRuleName] = useState("");
  const [subject, setSubject]=useState("");
  const [boxes, setBoxes]=useState([]);
  const [folderName, setFolderName]=useState([]);
  const [path, setPath]=useState("");
  const [name, setName]=useState("");
  const [original, setOriginal]=useState("");
  const [showModal, setShowModal] = useState(false);

  // Backdrop JSX code
  const renderBackdrop = (props) => <div className="backdrop" {...props} />;

  var handleClose = () => setShowModal(false);

  var handleSuccess = () => {
    console.log("success");
  };
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 
  
  

  useEffect(() => {
    axios.get('http://gw.thegmmedical.com:5003/api/getboxes')
    .then(function (response){
      //console.log(response);
      setBoxes(response.data);
      console.log(boxes);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  
  
  }, []);

  

  const emptyMails = event => {
    var name = renderSettingText(event.currentTarget.className);
    var result = window.confirm(name+" 메일함을 비우시겠습니까?")
    if (result){
    console.log('className 👉️', event.currentTarget.className);

    axios.get('http://gw.thegmmedical.com:5003/api/empty/'+event.currentTarget.className)
    .then(function (response){
      console.log(response);
      alert("메일함이 비워졌습니다.");
      window.location.reload(true);


    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }
  const seenMails = event => {
    var name = renderSettingText(event.currentTarget.className);
    var result = window.confirm(name+" 메일함을 모두 읽음 표시하시겠습니까?");
    if (result){

    console.log('className 👉️', event.currentTarget.className);

    axios.get('http://gw.thegmmedical.com:5003/api/seen/'+event.currentTarget.className)
    .then(function (response){
      console.log(response);
      alert("읽음 처리되었습니다.");
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }

  const deleteBox = event => {
    var result = window.confirm("삭제하시겠습니까?");
    if (result){
      axios.get('http://gw.thegmmedical.com:5003/api/deleteBox/'+event.currentTarget.className)
      .then(function (response){
        console.log(response);
        window.location.reload(true);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
    
  }
  
  const onChangeName = (e) => {
    setFolderName(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
  }
  const addBox = event => {

    axios.get('http://gw.thegmmedical.com:5003/api/addBox/'+folderName)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  function handleShow(val, val2) {
    setPath(val);
    setName(val2);
    setOriginal(val2);
    setShowModal(true);
}
const onChangePath = (e) =>{
  setName(e.target.value);
}

const renameBox = () => {

  var str = path.replace(original, name);

  const msg = {
    original : path,
    rename : str,
    name : name

  }
 

  axios
    .post("http://gw.thegmmedical.com:5003/api/renameBox", msg, {
      withCredentials: true,
      credentials: "include", // Now this is was the missing piece in the client side
    })
    .then((res) => {
      window.location.reload(true);
    })
    .catch((error) => {
    });
}

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
const renderSettingText = (boxname) => {
  switch (boxname) {
      
      case "INBOX":
          return "받은 메일함" ;
      case "sent":
          return "보낸 메일함";
      case "Junk":
          return "스팸 메일함";
      case "draft":
          return "임시 보관";
      case "delete":
          return "휴지통";
      case "Stars":
          return "중요 메일함";
      default:
          return boxname;
  }
};
  return (
    <div className="wrapper">
        <div className="sort-setting mailbox">
      
        <table style={{width:"100%"}}>
	<tbody><tr><td></td></tr>
	<tr align="center">
		<td class="table_title">메일함</td>
		<td class="table_title">안읽음/전체</td>
		<td class="table_title">용량</td>
		<td class="table_title">관리</td>
	</tr>
  {boxes.map((box, idx) => {
     return <tr align="center">
     <td class="table_list">&nbsp;
     <span>{renderSettingText(box.name)}</span>
    </td>
     <td class="table_list"><span class="orange">{box.unseen}</span>&nbsp;/&nbsp;{box.total}</td>
     <td align="right" class="table_list">{formatBytes(box.size)}&nbsp;</td>
     <td class="table_list">
     <div onClick={seenMails} className={box.name}>모두 읽음</div> &nbsp;&nbsp;&nbsp; <div onClick={emptyMails} className={box.name}>비우기</div>
     &nbsp;&nbsp;&nbsp; 
     {(box.name!=="INBOX" && box.name !=="sent"
     && box.name !== "Junk" && box.name!=="delete" && box.name!=="Stars")&&(
      <div onClick={() => handleShow(box.path, box.name)} className={box.path}>수정</div>
     )}
      {(box.name!=="INBOX" && box.name !=="sent"
     && box.name !== "Junk" && box.name!=="delete" && box.name!=="Stars")&&(
      <div onClick={deleteBox} className={box.path}>삭제</div>
     )}
      
      
     </td>
   </tr>
  })}
  <div className="btns" style={{margin:"20px 0"}}>
 <input type="text" value={folderName} onChange={onChangeName}
 placeholder="메일함 이름을 입력하세요"></input><button className="button" onClick={addBox} style={{marginLeft:"20px"}}>새 메일함 만들기</button>
 </div>
</tbody></table>

            
            
      
      </div>
      <Modal
        className="mail_modal"
        show={showModal}
        onHide={handleClose}
        renderBackdrop={renderBackdrop}
      >
        <div>
          <div className="modal-header">
            <div className="modal-title">메일함 이름 변경</div>
            <div>
              <span className="close-button" onClick={handleClose}>
                x
              </span>
            </div>
          </div>
          <div className="modal-desc">
            <input type="text" value={name} onChange={onChangePath}/>
          </div>
          <div className="modal-footer">
            <button className="secondary-button" onClick={handleClose}>
              취소
            </button>
            <button className="primary-button" onClick={renameBox}>
              저장
            </button>
          </div>
        </div>
      </Modal>
    </div>

  );
};

export default BoxSetting;
