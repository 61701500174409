import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import axios from "axios";
import "./css/search.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { CgSearch } from "react-icons/cg"

axios.defaults.withCredentials = true; 

const Search = ({ onChange }) => {
  const [isActive, setActive] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [box, setBox] = useState("all");
  const [textOptions, setOptions] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue]= useState("");
  const [boxes, setBoxes]= useState([
    { value : "all", label : "전체메일"},
    { value : "INBOX", label : "받은메일함"},
    { value : "sent", label : "보낸메일함"},
    { value : "draft", label : "임시보관함"},
    { value : "Junk", label : "스팸보관홤"},
    { value : "delete", label : "휴지통"}
  ]);
  const { name } = useParams();
  const toggleText = () => {
    setActive(!isActive);
  };
  const options =[
    { value : "all", label : "전체"},
    { value : "sub_text", label : "제목+본문"},
    { value : "subject", label : "제목"},
    { value : "text", label : "본문"},
  ];

  /*const boxes =[
    { value : "all", label : "전체메일"},
    { value : "INBOX", label : "받은메일함"},
    { value : "sent", label : "보낸메일함"},
    { value : "draft", label : "임시보관함"},
    { value : "Junk", label : "스팸보관홤"},
    { value : "delete", label : "휴지통"}
  ];*/


  useEffect(() => {
    
    axios.get('https://gw.thegmmedical.com:5003/api/getmailboxes')
    .then(function (response){
      console.log("search...", response);
      if (response.data.length>0){
        let tmp =boxes;
        for (let i=0;i<response.data.length;i++){
          
          tmp.push({value : response.data[i].path, label:response.data[i].name });
        }

        setBoxes([...tmp]);
        
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  },[]);
  const changeFrom = (e) => {
    setFrom(e.target.value);
    
  };
  const changeTo = (e) => {
    setTo(e.target.value);
  
};
const changeSubject = (e) => {
    setSubject(e.target.value);
  
};

  function searchMail(){
    var list = new Object;
    if (from !==""){
        console.log(from);
        list.FROM= from;
    }
  
    if (to !==""){
      list.To= to;
    }

    if (textOptions === "subject"){
        if (subject !==""){
            
            list.SUBJECT= subject;
        }
    }
    else if (textOptions === "sub_text"){
        if (subject !==""){
           
            list.SUB_TEXT= subject;
      
        }
    }
    else if (textOptions === "text"){
        if (subject !==""){
           
            list.BODY= subject;
        }
    }
    else if (textOptions === "attachment"){

    }else{
        if (subject !==""){
            
            list.TEXT= subject;
        }
    }
    if (startDate !==""){
        
        list.SINCE= startDate;
    }
  
    if (endDate !==""){
        
        list.BEFORE= endDate;
    }
    if (box !==""){
      list.BOX= box;
  }
    console.log("list!!!!!!!!!!!!!!!!!!!",list);
    const msg ={
        list : JSON.stringify(list)
    }
    axios.post('https://gw.thegmmedical.com:5003/api/searchmail', msg)
    .then(function (response){
      console.log("search...", response);

      
      onChange(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

  const searchEnter = (e) => {
    if (e.key === "Enter"){
      var list = {};
      console.log("검색....", e.target.value);
      list.TEXT = e.target.value;
      list.BOX = box;
      const msg ={
        list : JSON.stringify(list)

      }
      axios.post('https://gw.thegmmedical.com:5003/api/searchmail', msg)
      .then(function (response){
        console.log("response", response);
        onChange(response);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  }

  const clickSearch = (e) => {

      var list = {};
      list.TEXT = searchValue;
      list.BOX = box;
      const msg ={
        list : JSON.stringify(list)

      }
      axios.post('https://gw.thegmmedical.com:5003/api/searchmail', msg)
      .then(function (response){
        console.log("response", response);
        onChange(response);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    
  }
  return (
    <div>
      <div class="search_area">
        <div class="search_bar_wrap" id="search-bar">
          <div class="search_bar" style={{display:"flex", alignItems:"center"}} role="search">
           
            <input
              type="text"
              id="search"
              class="search_input"
              placeholder="메일 검색"
              autoComplete="off"
              maxLength="100"
              defaultValue=""
              onChange={(e)=>setSearchValue(e.target.value)}
              onKeyDown={searchEnter}
            />
            <button type="button" class="button_svg_search" onClick={()=>clickSearch()}
            style={{display:"flex", cursor:"pointer", marginRight:"5px", border:"none", 
            background:"transparent", fontSize:"22px", color:"#6A6A6A"}}>
              <CgSearch/>
            </button>
          </div>
          <button type="button" onClick={toggleText} class="button_detail">
            상세
          </button>
        </div>
      </div>

      {isActive && (
        <div>
          <div class="search_dropdown">
            <div className="search_dropdown_list">
            <ul class="">
              <li class="search_dropdown_item">
                <strong class="category">
                  <label for="search_detail_1">보낸사람</label>
                </strong>
                <div className="input_wrap">
                <input
                  type="search"
                  id="search_detail_1"
                  class="search_input"
                  autocomplete="off"
                  maxlength="100"
                  onChange={changeFrom}
                  value={from}
                />
                </div>
             
              </li>
              <li class="search_dropdown_item">
                <strong class="category">
                  <label for="search_detail_2">받는사람</label>
                </strong>
                <div className="input_wrap">
                <input
                  type="search"
                  id="search_detail_2"
                  class="search_input"
                  autocomplete="off"
                  maxlength="100"
                  onChange={changeTo}
                  value={to}
                />
                </div>
              </li>
              <li class="search_dropdown_item">
                <strong class="category">
                  <label for="search_detail_3">내용</label>
                </strong>
                <div className="input_wrap">
                <Dropdown placeHolder="전체" options={options} onChange={(value) => setOptions(value.value)}/>
                <input
                  type="search"
                  id="search_detail_3"
                  class="search_input"
                  placeholder="검색어 입력"
                  maxlength="100"
                  autocomplete="off"
                  onChange={changeSubject}
                  value={subject}
                />
                </div>
             
                
             
              </li>
            </ul>
            <div class="search_dropdown_item">
              <strong class="category">메일함</strong>
              <div className="input_wrap">
              <Dropdown placeHolder="전체" options={boxes} onChange={(value) => setBox(value.value)}/>
              </div>
            </div>
            <div class="search_dropdown_item">
              <strong class="category">기간</strong>
              <div className="input_wrap">
              <div class="date_picker">
               
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />

              </div>
              <div class="date_picker">
             
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
              </div>
              </div>
            </div>
            <div class="search_dropdown_footer">
        
              <button type="button" onClick ={()=>searchMail()} class="button_search disabled">
                검색
              </button>
            </div>
          </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;