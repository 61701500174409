import React, { useState } from "react";
import "./css/folder.css";

const Folder = ({ name, file, onChange }) => {

  const [expand, setExpand] = useState(true);
  if (typeof file !== "object") return <span>{file}</span>;

  const onItemClick = (option) =>{
    console.log(option);
    setExpand(!expand);
    onChange(option);
  }
  return (
    
    <div className="folder">
      <span onClick={() => onItemClick(name)} >{name}</span>
      <br/>
      <div className="" style={{ display: expand ? "block" : "none", paddingLeft: 15 }}>
        {
          Object.entries(file).map(([key, value]) => (
            <Folder key={key} name={key} file={value}/>
          ))
        }
      </div>
    </div>
  );
}
export default Folder;
