import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const AutoComplete = ({onChange}) => {
  const [peopleArray, setPeopleArray] = useState([]);
  const [inputValue, setInputValue] = useState('')
  const [isHaveInputValue, setIsHaveInputValue] = useState(false)
  const [dropDownList, setDropDownList] = useState(peopleArray)
  const [dropDownItemIndex, setDropDownItemIndex] = useState(-1)

  const showDropDownList = () => {
    if (inputValue === '') {
      setIsHaveInputValue(false)
      setDropDownList([])
    } else {
      const choosenTextList = peopleArray.filter(textItem =>
        textItem.includes(inputValue)
      )
      setDropDownList(choosenTextList)
    }
  }

  const changeInputValue = event => {
    setInputValue(event.target.value)
    setIsHaveInputValue(true)
    
  }

  const clickDropDownItem = clickedItem => {
    setInputValue(clickedItem)
    setIsHaveInputValue(false)
    console.log(clickedItem);
    onChange(clickedItem);
  }

  const handleDropDownKey = event => {
    //input에 값이 있을때만 작동
    if (isHaveInputValue) {
      if (
        event.key === 'ArrowDown' &&
        dropDownList.length - 1 > dropDownItemIndex
      ) {
        setDropDownItemIndex(dropDownItemIndex + 1)
      }

      if (event.key === 'ArrowUp' && dropDownItemIndex >= 0)
        setDropDownItemIndex(dropDownItemIndex - 1)
      if (event.key === 'Enter' && dropDownItemIndex >= 0) {
        clickDropDownItem(dropDownList[dropDownItemIndex])
        setDropDownItemIndex(-1)
      }
    }
  }

  useEffect(showDropDownList, [inputValue])
  useEffect(() => {
    console.log("is it working or not");
    async function fetchboxes(){
      await fetch("https://gw.thegmmedical.com:5003/api/getpeople", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
            var tempArray = [];
            for (let i =0;i<json.length;i++){
        
              tempArray.push(json[i].name +"<"+ json[i].email+">");
        
            }
            
            setPeopleArray(tempArray);
        //json.shift();
        
        //console.log(boxes.boxes);
        //console.log(boxes.boxes);
      })
      .catch(() => {});
    };
    fetchboxes();


    
    
}, []);

  return (
    <WholeBox>
      {/*<Title text='AutoComplete' />*/}
      <InputBox isHaveInputValue={isHaveInputValue}>
        <Input
          type='text'
          value={inputValue}
          onChange={changeInputValue}
          onKeyUp={handleDropDownKey}
        />
        <DeleteButton onClick={() => setInputValue('')}>&times;</DeleteButton>
      </InputBox>
      {isHaveInputValue && (
        <DropDownBox>
          {dropDownList.length === 0 && (
            <DropDownItem>해당하는 단어가 없습니다</DropDownItem>
          )}
          {dropDownList.map((dropDownItem, dropDownIndex) => {
            return (
              <DropDownItem
                key={dropDownIndex}
                onClick={() => clickDropDownItem(dropDownItem)}
                onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
                className={
                  dropDownItemIndex === dropDownIndex ? 'selected' : ''
                }
              >
                {dropDownItem}
              </DropDownItem>
            )
          })}
        </DropDownBox>
      )}
    </WholeBox>
  )
}

const activeBorderRadius = '16px 16px 0 0'
const inactiveBorderRadius = '16px 16px 16px 16px'

const WholeBox = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  position:relative;
  .search_user & {
    width:85%;
  }
`

const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  position:relative;
  
  z-index: 3;
  border:1px solid #ddd;
  &:focus-within {
    box-shadow: 0 2px 2px rgb(0, 0, 0, 0.2);
  }
  
`

const Input = styled.input`

  flex: 1 0 0;
  margin: 0;
  width:calc(100% - 100px) !important;
  background-color: transparent;
  border: none !important;
  outline: none;
  
  font-size: 12px;
  .search_user & {
    height:24px;
  }
  @media only screen and (max-width: 1300px) {
    width: 150px !important;
  }

  @media only screen and (max-width: 600px) {
    width: auto;
  }

`

const DeleteButton = styled.div`
  cursor: pointer;
  display:flex;
  padding-right:10px;
  align-items:center;
`

const DropDownBox = styled.ul`
  display: block;
  width:475px;
  top:11px;
  position:absolute;
  
  padding: 8px 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 2px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 9999;
`

const DropDownItem = styled.li`
  padding: 0 16px;

  &.selected {
    background-color: lightgray;
  }
`

export default AutoComplete;