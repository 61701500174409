import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import { Sheet, Op, Selection, colors } from "@fortune-sheet/core";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import initial from "./data/cell";
import ddd from "./data/user-info2.json";
import {GiHamburgerMenu} from "react-icons/gi";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../components/ScheduleDraftsList";
import ScheduleList from "../components/side/ScheduleList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import {
  format
  
} from "date-fns";
import "./css/schedulew.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

var today = new Date();

const initialValues = {
  subject: "",
  group: "",
  allday:0,
  repeat: 0,
  start_date: format(today, "yyyy-MM-dd"),
  start_hour:"00",
  start_min:"00",
  end_date:"",
  end_hour:"00",
  end_min:"00",
  applicant:"",
  rep_cycle:"everyday",
  rep_cycleN:"1",
  rep_end:"keep",
  rep_week_day: "",
  rep_type:"date",
  times:"0",
  rep_end_date:"",
  participants:[]
};
export default function BoardWrite() {
  const [sidebar, setSidebar] = useState(false);
  const hour = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  const location = useLocation();
  const editorRef = useRef();
  const excelRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [div, setDiv]=useState(<div style={{width:"100%", height:"500px"}}><Workbook onOp={console.log} data={[initial]} onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} /></div>) ;
  const [members, setMembers] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [imgLink, setImgLink] = useState("");
  const [excelData, setExcelData] = useState();
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [options, setOption] = useState("");
  const [data, setData] = useState(location.state.files);
  const [editor, setEditor] = useState("toast");
  const [allday, setAllday] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [dateN, setDateN] = useState("");
  const [nth, setNth] = useState("");
  const [nthDay, setNthDay] = useState("");
  const [nthDayKr, setNthDayKr] = useState("");
  const [calendars, setCalendars]=useState([]);
  const [teamCalendars, setTeamCalendars]=useState([]);
  const [userCalendars, setUserCalendars]=useState([]);
  const [delFile, setDelFile]=useState([]);
  const [participants, setParticipants] = useState([]);
  const selectBoard = (e) => {
    e.preventDefault();
  };
  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };
  const selectEditor = (e) => {
    e.preventDefault();
    setEditor(e.target.value);
  };
  const toggleShow = () => {
    setShow(!show);
};
  const onTypeChange = e => {
    setType(e.target.value)
  }
  const endDateChange = e => {
    setEndDate(e.target.value)
  }
  var CLIENT_ID = "103373930583-rnu1qv7i5vr7ng7b2ma5o5r2p9vaiqu4.apps.googleusercontent.com"
  var API_KEY = "AIzaSyCDeeCLkx3dIqf-6aQ_CRJ6vopUxJXwnzE"
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar"
  var calendarID=location.state.schedule.calendarID;
  var eventID =location.state.schedule.googleID;
  var gapi = window.gapi

  const addEvent = () => {
    gapi.load('client:auth2', () => {
      console.log('loaded client')

      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
        plugin_name:'App Name that you used in google developer console API'

      })

      gapi.client.load('calendar', 'v3', () => console.log('bam!'))

      gapi.auth2.getAuthInstance().signIn()
      .then(() => {
        var start = values.start_date+"T"+values.start_hour+":"+values.start_min+":00+09:00";

        var end = values.end_date+"T"+values.end_hour+":"+values.end_min+":00+09:00";
        let emails = [];
        for (let i =0;i<values.participants.length;i++){
          emails.push({'email':values.participants[i].email, 'displayName':values.participants[i].name});
        }

        let interval = "INTERVAL="+values.rep_cycleN;
        let rep_end;
        let weekday="";
        let by_week_day="";
        let by_month_day="";
        let by_year="";
        let recurrence ="RRULE:";
        let days  = {'sun':'SU', 'mon':'MO', 'tue':'TU', 'wed':'WE', 'thr':'TH', 'fri':'FR', 'sat':'SA'};

        if (values.rep_cycle==="weekly_weekday"){
          weekday="BYDAY=MO,TU,WE,TH,FR";
          recurrence+="FREQ=DAILY;"+weekday;
        }

      
        if (values.rep_cycle==="DAILY"){
          recurrence+="FREQ=DAILY";
        }

        if (values.rep_cycle==="WEEKLY"){
          by_week_day = "BYDAY="+days[values.rep_week_day]+"";
          recurrence+="FREQ=WEEKLY;"+by_week_day;
        }
        
        else if (values.rep_cycle==="MONTHLY" && values.rep_type==="date" ){
          by_month_day = "BYMONTHDAY="+dateN;
          recurrence+="FREQ=MONTHLY;"+by_month_day;
        }else if (values.rep_cycle==="MONTHLY" && values.rep_type==="nthday"){
          by_month_day = "BYDAY="+nth+days[nthDay];
          recurrence+="FREQ=MONTHLY;"+by_month_day;
        }else if (values.rep_cycle==="YEARLY" && values.rep_type==="date"){
          by_year = "BYMONTH="+dateN.split("-")[0]+";BYMONTHDAY="+dateN.split("-")[1];
          recurrence+="FREQ=YEARLY;"+by_year;
        }else if (values.rep_cycle==="YEARLY" && values.rep_type==="nthday"){
          by_year = "BYMONTH="+dateN.split("-")[0]+";BYDAY="+nth+days[nthDay];
          recurrence+="FREQ=YEARLY;"+by_year;
        }
        if (values.rep_end == "keep"){
          let end_date = values.end_date.replace(/-/g, "");

          rep_end = "UNTIL="+end_date;
        }else{
          rep_end = "COUNT="+values.times;
        }
        recurrence+=";"+interval+";"+rep_end;
        console.log("recurrence  : ", recurrence);

        /*rep_cycle weekly
ScheduleWrite.js:510 rep_cycleN 1
ScheduleWrite.js:511 rep_end date
ScheduleWrite.js:512 rep_week_day sat
ScheduleWrite.js:515 rep_month_type date
ScheduleWrite.js:516 rep_type 
ScheduleWrite.js:521 times 0
ScheduleWrite.js:522 rep_end_date */
        let ex= "RRULE:FREQ=DAILY;UNTIL=20240515;INTERVAL=2";
        if (repeat === true){
          end = start;
        }
        var event = {
          'summary': values.subject,
          'description': 'Really great refreshments',
          'start': {
            'dateTime': start,
            'timeZone': 'Asia/Seoul'
          },
          'end': {
            'dateTime': end,
            'timeZone': 'Asia/Seoul'
          },
          'recurrence': [
            recurrence
          ],
          'attendees': emails,
          'reminders': {
            'useDefault': false,
            'overrides': [
              {'method': 'email', 'minutes': 24 * 60},
              {'method': 'popup', 'minutes': 10}
            ]
          }
        }

        var request = gapi.client.calendar.events.update({
          'calendarId': calendarID,
          'eventId' : eventID,
          'resource': event,
        })

        request.execute(event => {
          console.log(event)
          window.open(event.htmlLink)
        })
        

     
        // get events
        gapi.client.calendar.events.list({
          'calendarId': 'primary',
          'timeMin': (new Date()).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 10,
          'orderBy': 'startTime'
        }).then(response => {
          const events = response.result.items
          console.log('EVENTS: ', events);
          window.location.href="https://gw.thegmmedical.com/schedule";
        })
        
    

      })
    })
  }


  useEffect(() => {
    console.log("location.state.files", location.state.files);
    axios.get("https://gw.thegmmedical.com:5003/api/getcalendar").then((response) => {
      // setNotice(response.data);
      setCalendars(response.data);
       
     });
     axios.get("https://gw.thegmmedical.com:5003/api/get_teamcalendar").then((response) => {
      // setNotice(response.data);
      setTeamCalendars(response.data);
       
     });
     axios.get("https://gw.thegmmedical.com:5003/api/get_user_calendar").then((response) => {
      // setNotice(response.data);
      setUserCalendars(response.data);
       
     });
     let schedule = location.state.schedule;
     console.log("스케쥴", schedule);
     let participants = JSON.parse(location.state.schedule.participants);
     let user_nos = [];
    for (let p = 0;p<participants.length;p++){
      let participant = participants[p].substring(1, participants[p].length-1);
      console.log(participant);
      user_nos.push(participant);
    }
    const data ={
      user_nos : user_nos
    }
    axios.post("https://gw.thegmmedical.com:5003/api/get_participants", data).then((response) => {
      
      let participantss=[];
      if (response.data.length>0){
        participantss = response.data;
      }
    


      if (schedule.start_hour === 0){
        schedule.start_hour = "00";
      }
      if (schedule.start_min === 0){
        schedule.start_min = "00";
      }
      if (schedule.end_hour === 0){
        schedule.end_hour = "00";
      }
      if (schedule.end_min === 0){
        schedule.end_min = "00";
      }
      
       let scheduleInfo = {
        subject: schedule.subject,
        group: schedule.calendar_no,
        allday:schedule.allday,
        repeat: schedule.is_repeat,
        start_date: schedule.start_date,
        start_hour:schedule.start_hour,
        start_min:schedule.start_min,
        end_date:schedule.end_date,
        end_hour:schedule.end_hour,
        end_min:schedule.end_min,
        applicant:"",
        rep_cycle:schedule.rep_cycle,
        rep_cycleN:schedule.rep_cycleN,
        rep_end:schedule.rep_end,
        rep_week_day: schedule.rep_week_day,
        rep_type:schedule.rep_type,
        times:schedule.times,
        rep_end_date:schedule.rep_end_date,
        participants : participantss
      };
      if (schedule.rep_month_type === "date"){
        setDateN(schedule.rep_type);
      }else{
        
      }
      
      setValues(scheduleInfo);
      if (schedule.is_repeat===1){
        setRepeat(true);
       }
       if (schedule.allday===1){
        setAllday(true);
       }


       
     });
    
     
     

}, []);

function getNth(dat) {
  var days = ['일', '월', '화', '수', '목', '금','토'],
      days_en  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
      d    = dat ? dat instanceof Date ? dat : new Date(dat) : new Date(),
      date = d.getDate(),
      day  = d.getDay(),
      n    = Math.ceil(date / 7);
  setNth(n);
  setNthDay(days_en[day]);
  setNthDayKr(days[day]);
  return n + ' ' + days[day];
}

const handleInputChange = (e) => {
  //const name = e.target.name 
  //const value = e.target.value 
  const { name, value } = e.target;

  setValues({
    ...values,
    [name]: value,
  });

  if (name === "rep_cycle" && value=== "monthly"){
    let d1 = values.start_date.split("-");
    setDateN(d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }else if (name === "rep_cycle" && value=== "yearly"){
    let d1 = values.start_date.split("-");
    setDateN(d1[1] +"-"+ d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }

  if (name === "start_date" && values.rep_cycle=== "monthly"){
    let d1 = value.split("-");
    setDateN(d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }else if (name === "start_date" && values.rep_cycle=== "yearly"){
    let d1 = value.split("-");
    setDateN(d1[1] +"-"+ d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }

  if (name === "end_date"){

    setValues({
      ...values,
      rep_end: "date",
    });
    values.rep_end = "date";
    setValues({
      ...values,
      [name]: value,
    });

  }
  if (name === "rep_end" && value === "keep"){
    setValues({
      ...values,
      end_date: "",
      times:"",
    });
    values.end_date = "";
    values.times = "0";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "times" && value !== ""){
    setValues({
      ...values,
      end_date: "",
      rep_end: "times",
    });
    values.end_date = "";
    values.rep_end = "times";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "rep_cycle" && value === "weekly"){
    let date = values.start_date.split("-");
    let from = new Date(date[0], parseInt(date[1]) - 1, date[2]);
    let day = from.getDay();
    let days  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
    setValues({
      ...values,
      rep_week_day: days[day],
    });
    values.rep_week_day = days[day];
    setValues({
      ...values,
      [name]: value,
    });
  }
};
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const subjectChange = (event) => {
    setSubject(event.target.value);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }

  const removeFile = (i) => {
    setFileList([...fileList.filter((_, index) => index !== i)]);
    setFileSize(fileSize-fileList[i].size);
  };





 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {

    
    
    console.log(editorRef.current?.getInstance().getHTML());

    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`subject`, values.subject);
    formData.append(`group`, values.group);
    formData.append(`repeat`, repeat);
    formData.append(`allday`, allday);

    formData.append(`start_date`, values.start_date);
    formData.append(`start_hour`, values.start_hour);
    formData.append(`start_min`, values.start_min);

    if (repeat === true && values.end_date === ""){
      formData.append(`end_date`, "2099-12-31");
    }else{
      formData.append(`end_date`, values.end_date);
    }
    
    formData.append(`end_hour`, values.end_hour);
    formData.append(`end_min`, values.end_min);

    formData.append(`rep_cycle`, values.rep_cycle);
    formData.append(`rep_cycleN`, values.rep_cycleN);
    formData.append(`rep_end`, values.rep_end);
    formData.append(`rep_week_day`, values.rep_week_day);

    if (values.rep_type === "date"){
      formData.append(`rep_month_type`, "date");
      formData.append(`rep_type`, dateN);
    }else{
      formData.append(`rep_month_type`, "nthday");
      formData.append(`rep_type`, nth+"-"+nthDay);
    }
    formData.append(`times`, values.times);
    formData.append(`rep_end_date`, values.rep_end_date);
    let user_nos = [];
    for (let i =0;i<values.participants.length;i++){
      user_nos.push("("+values.participants[i].user_no+")");
    }
    formData.append(`participants`, JSON.stringify(user_nos));
    formData.append(`s_id`, location.state.schedule.s_id);
    formData.append(`del_file`, JSON.stringify(delFile));

    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/scheduleupdate", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          if (location.state.schedule.googleID !==null && location.state.schedule.googleID !=="" && location.state.schedule.googleID !==undefined){
            addEvent();
          }else{
            window.location.href="https://gw.thegmmedical.com/schedule";

          }
          
          //window.location.href="https://gw.thegmmedical.com/schedule";
          //console.log("...." + data);
        });
    };

   fetch();
   


  
  };

  const handleDraftButton = () => {
 
    
    console.log(editorRef.current?.getInstance().getHTML());

    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`subject`, values.subject);
    formData.append(`group`, values.group);
    formData.append(`repeat`, repeat);
    formData.append(`allday`, allday);

    formData.append(`start_date`, values.start_date);
    formData.append(`start_hour`, values.start_hour);
    formData.append(`start_min`, values.start_min);

    if (repeat === true && values.end_date === ""){
      formData.append(`end_date`, "2099-12-31");
    }else{
      formData.append(`end_date`, values.end_date);
    }
    
    formData.append(`end_hour`, values.end_hour);
    formData.append(`end_min`, values.end_min);

    formData.append(`rep_cycle`, values.rep_cycle);
    formData.append(`rep_cycleN`, values.rep_cycleN);
    formData.append(`rep_end`, values.rep_end);
    formData.append(`rep_week_day`, values.rep_week_day);

    if (values.rep_type === "date"){
      formData.append(`rep_month_type`, "date");
      formData.append(`rep_type`, dateN);
    }else{
      formData.append(`rep_month_type`, "nthday");
      formData.append(`rep_type`, nth+"-"+nthDay);
    }
    formData.append(`times`, values.times);
    formData.append(`rep_end_date`, values.rep_end_date);
    let user_nos = [];
    for (let i =0;i<values.participants.length;i++){
      user_nos.push("("+values.participants[i].user_no+")");
    }
    formData.append(`participants`, JSON.stringify(user_nos));

  
    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/schedule_draftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log("what is this");
          window.location.href="https://gw.thegmmedical.com/schedule";
          //console.log("...." + data);
        });
    };

   fetch();
   



  };




  const removeAttached = (i) => {
    setData([...data.filter((_, index) => index !== i)]);
    const tempArray = [...delFile, data[i].a_no];
    setDelFile(tempArray);
    console.log("....",data[i]);
    setFileSize(fileSize-data[i].size);
    /*axios.get("https://gw.thegmmedical.com:5003/api/delete_attach_work/"+attach[i].a_no)
    .then(resp => {
    });*/
      
  };

  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
  window.addEventListener('message', (event) => {
    if (event.data["selectedMembers"] !== undefined){
      
      var temp = event.data["selectedMembers"];
      var tempArray = members;
      for (let i=0;i<temp.length;i++){
        if (!tempArray.includes(temp[i])){
          
          tempArray = [...tempArray, temp[i]];
        }
      }
      const map = new Map(
        tempArray.map(obj => [obj.user_no, obj])
      );

      const deduplicatedArr = [...map.values()];
        console.log("응:<ㅡ",deduplicatedArr);
      setValues({
        ...values,
        participants: deduplicatedArr,
      })
      values.participants = deduplicatedArr;
      
      
    }
 
  });

  
 
  
  
  
  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <ScheduleList/>
        </div>
      )}
     <div className="content-wrap">
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
        <div className="side-menu">
      <ScheduleList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>
      </div>
      <div className="main_content">

      <h3 className="">일정 수정</h3>
      {show ? (
    <DraftsList onChange={(value) => {
      setSubject(value.subject);editorRef.current?.getInstance().setHTML(value.content);setDraftNo(value.s_id);
      const draftValues = {
        subject:value.subject,
        group: value.calendar_no,
        allday:value.allday,
        repeat: value.is_repeat,
        start_date: value.start_date,
        start_hour:value.start_hour,
        start_min:value.start_min,
        end_date:value.end_date,
        end_hour:value.end_hour,
        end_min:value.end_min,
        applicant:value.applicant,
        rep_cycle:value.rep_cycle,
        rep_cycleN:value.rep_cycleN,
        rep_end:value.rep_end,
        rep_week_day: value.rep_week_day,
        rep_type:value.rep_type,
        times:value.times,
        rep_end_date:value.rep_end_date,
        participants:[],
      };
      setValues(draftValues);
    }} closePopup={() => toggleShow()}/>
    ) : null}
      
    

      <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"100%", margin:"0 auto"}}>
              <div className="left">

              
              <button className="btn" onClick={handleRegisterButton} style={{marginRight:"10px"}}>등록</button>

              {/*<button className="btn" onClick={handleDraftButton}>임시저장</button>*/}
              </div>

              <div className="right">
                <button onClick={() => {setShow(!show);}}>임시저장글</button> 

            </div>
            </div>
      <table className="write_table schedule">

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  <tr>
  <td class="table_title2" nowrap="">
    제목
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="text" name="subject" className="formInput" placeholder="제목을 입력하세요." value={values.subject} onChange={handleInputChange}></input>
    </div>
    </td>
  </tr>

  <tr>

  <td class="table_title2" nowrap="">
    캘린더 선택
  </td>
    <td class="table_list2_left">
    <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
     <select
                        id="u_shcate"
                        name="group"
                        className="select"
                        title="검색항목 선택"
                        value={values.group}
                        onChange={handleInputChange}
                      >
                        <option value="all">캘린더 선택</option>
                        {calendars.map((element,index)=>(
                          <option value={element.no}>{element.name}</option>
                          ))}
                        {teamCalendars.map((element,index)=>(
                          <option value={element.no}>{element.name}</option>
                        ))}
                        {userCalendars.map((element,index)=>(
                          <option value={element.no}>{element.name}</option>
                        ))}
                      </select>
    </div>
    </td>
  </tr>
  

  <tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    일시
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <div className="date"> <label for="allday">종일</label>
    <input type="checkbox" name="allday" value={allday} onChange={()=>setAllday(!allday)} checked={allday}></input>
    <label for="repeat">반복</label>
    <input type="checkbox" name="repeat" value={repeat} onChange={()=>setRepeat(!repeat)} checked={repeat}></input></div>
    <div className="date">
      <span>시작 </span>
      <input type="date" name="start_date" className="formInput"  style={{marginLeft:"10px"}} value={values.start_date} onChange={handleInputChange}></input>

      {!allday && (
        <div style={{display:"flex"}}>
<select name="start_hour" className="formInput" style={{marginLeft:"10px"}} value={values.start_hour}
                                  onChange={handleInputChange}>
                                 
  {hour.map((element, index)=>(
      <option value={element}>{element}</option>
  ))}
  </select> 
  <span>:</span>
  <select name="start_min" className="formInput" style={{marginLeft:"10px"}} value={values.start_min}
                                  onChange={handleInputChange}>
  {Array.from(Array(60), (e, i) => {
    if (i >=0 && i <=9){
      return <option value={"0"+i}>0{i}</option>
    }else{
      return <option value={i}>{i}</option>
    }
  })}

  
  </select> 

</div>
      )}   
      
      </div>
    <div className="date"><span>종료</span> 
    <input type="date" name="end_date" className="formInput"  style={{marginLeft:"10px"}} value={values.end_date} onChange={handleInputChange}></input>

    {!allday && (
        <div style={{display:"flex"}}>
    <select name="end_hour" className="formInput" style={{marginLeft:"10px"}} value={values.end_hour} onChange={handleInputChange}>
  {hour.map((element, index)=>(
      <option value={element}>{element}</option>
  ))}

  
  </select> 

  <span>:</span>
  <select name="end_min" className="formInput" style={{marginLeft:"10px"}} value={values.end_min} onChange={handleInputChange}>
  {Array.from(Array(60), (e, i) => {
    if (i >=0 && i <=9){
      return <option value={"0"+i}>0{i}</option>
    }else{
      return <option value={i}>{i}</option>
    }
  })}

  
  </select> 
    
</div>
      )}  
    </div>
    </div>
    </td>
  </tr>

{repeat && (
<table className="repeat" style={{width:"100%", border:"1px solid #ddd", margin:"20px 0"}}>
<tr>
<td class="table_title2" nowrap="">
  반복 주기
</td>
<td class="table_list2_left">
<select name="rep_cycle" className="formInput" value={values.rep_cycle} onChange={handleInputChange}>
<option value="everyday">매일</option>
<option value="weekly_weekday">매주 월-금</option>
<option value="weekly">매주</option>
<option value="monthly">매월</option>
<option value="yearly">매년</option>

</select>

{values.rep_cycle === "weekly" && (
<div style={{marginLeft:"10px", display:"flex"}}>
<label style={{width:"auto", display:"flex"}}>
        <input type="radio" value="sun" name="rep_week_day" checked={values.rep_week_day === "sun"} onChange={handleInputChange}/>
        <span style={{width:"auto", display:"flex", alignItems:"center"}}>
        일
        </span>
      </label>
<label style={{width:"auto", display:"flex"}}>
        <input type="radio" value="mon" name="rep_week_day" checked={values.rep_week_day === "mon"} onChange={handleInputChange}/>
        <span style={{width:"auto", display:"flex", alignItems:"center"}}>
         월
        </span>
      </label>
      <label style={{width:"auto", display:"flex"}}>
        <input type="radio" value="tue" name="rep_week_day" checked={values.rep_week_day === "tue"} onChange={handleInputChange}/>
        <span style={{width:"auto", display:"flex", alignItems:"center"}}>
          화
        </span>
      </label>
      <label style={{width:"auto", display:"flex"}}>
        <input type="radio" value="wed" name="rep_week_day" checked={values.rep_week_day === "wed"} onChange={handleInputChange}/>
        <span style={{width:"auto", display:"flex", alignItems:"center"}}>
        수
        </span>
      </label>
      <label style={{width:"auto", display:"flex"}}>
        <input type="radio" value="thr" name="rep_week_day" checked={values.rep_week_day === "thr"} onChange={handleInputChange}/>
        <span style={{width:"auto", display:"flex", alignItems:"center"}}>
        목
        </span>
      </label>
      <label style={{width:"auto", display:"flex"}}>
        <input type="radio" value="fri" name="rep_week_day" checked={values.rep_week_day === "fri"} onChange={handleInputChange}/>
        <span style={{width:"auto", display:"flex", alignItems:"center"}}>
        금
        </span>
      </label>
      <label style={{width:"auto", display:"flex"}}>
        <input type="radio" value="sat" name="rep_week_day" checked={values.rep_week_day === "sat"} onChange={handleInputChange}/>
        <span style={{width:"auto", display:"flex", alignItems:"center"}}>
        토
        </span>
      </label>
  </div>
)}

{values.rep_cycle === "monthly" && (

<select name="rep_type" className="formInput" value={values.rep_type} onChange={handleInputChange}>
<option value="date"> {dateN}일</option>
<option value="nthday">매월 {nth}번째 {nthDayKr}요일</option>
</select>


)}

{values.rep_cycle === "yearly" && (

<select name="rep_type" className="formInput" value={values.rep_type} onChange={handleInputChange}>
<option value="date"> {dateN.split("-")[0]}월 {dateN.split("-")[1]}일</option>
<option value="nthday"> {dateN.split("-")[0]}월 {nth}번째 {nthDayKr}요일</option>
</select>


)}


</td>


<td class="table_title2" nowrap="">
  주기
</td>
<td class="table_list2_left">
<input type="text" name="rep_cycleN" className="formInput" value={values.rep_cycleN} onChange={handleInputChange}></input>
</td>

</tr>

<tr>

<td class="table_title2" nowrap="">
  종료일
</td>
<td class="table_list2_left">
<div className="input-group radio" style={{display:"flex"}}>
      <label style={{width:"auto", display:"flex"}}>
        <input type="radio" value="keep" name="rep_end" checked={values.rep_end === "keep"} onChange={handleInputChange}/>
        <span style={{width:"auto", display:"flex", alignItems:"center"}}>
         계속 반복
        </span>
      </label>
      <label style={{width:"auto", display:"flex"}}>
        <input type="radio" value="times" name="rep_end" checked={values.rep_end === "times"} onChange={handleInputChange}/>
        <span style={{width:"auto", display:"flex", alignItems:"center"}}>
        <input type="text" style={{width:"50px"}} disalbed ={values.rep_end==="weekly" ? "true" : "false"}
        name="times" value={values.times} onChange={handleInputChange}></input> 번 이후
        </span>
      </label>
      <label style={{width:"auto", display:"flex"}}>
        <input type="radio" value="date" name="rep_end" checked={values.rep_end === "date"} onChange={handleInputChange}/>
        <span style={{width:"auto", display:"flex", alignItems:"center"}}>
        반복 종료일: <input type="date" style={{width:"100px"}} name="end_date" value={values.end_date} onChange={handleInputChange}></input>
        </span>
      </label>
   
  </div>
</td>
</tr>

</table>

)}


  <tr style={{height:"auto"}}>
<td class="table_title2" nowrap="">
  참석자
</td>
<td class="table_list2_left">
        <div className="input-group">
        <div style={{display:"flex",     alignItems:"center",  flexWrap:"wrap"}}>
        {values.participants!== undefined && (values.participants.map(item => (
    <div className=""><li key={item.user_no}>
      {item.name} [{item.email}]; &nbsp;
    </li>
  
    </div>
      )))}
              
          <button onClick={() => popupwindow("/select", "결재라인", 800, 700)} alt=''
          style={{background:"white", border:"1px solid #666", padding:"5px 10px", display:"flex",cursor:"pointer"}}>찾기</button>
        </div>

        

      </div>
      
		</td>
</tr>

  

  <tr>
  <td class="table_title2" nowrap="">
    첨부파일
  </td>
    <td class="table_list2_left">
    <div className="input-group">
    <input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler} multiple/>
    <button style={{cursor:'pointer'}} className="large_file_btn" onClick={()=>onBtnClick()}>
      파일 첨부
    </button>
    
    </div>
   
    </td>
  </tr>
  <tr style={{height:"auto"}}>
  <td class="table_title2" nowrap="">
    
  </td>
    <td class="upload_file_list">

    {(data !==undefined && data.length>0) &&   
<div className="attachment">

  <div className="attachment_box" style={{width:"100%", padding:"10px 0"}}>
    {data.map((element, idx) => {
    return <div className="file_item">
    <p>{element.filename} {formatBytes(element.size)}</p>
    <button type="button" className="remove" onClick={() => {removeAttached(idx);}}>x</button>
  </div>
    })}
    </div>
  </div>

  }

  {fileList.map((file,index) => 
    <div className="file_item">
      <p>{file.name} {formatBytes(file.size)}</p>
      <button type="button" className="remove" onClick={() => {removeFile(index);}}>x</button>
    </div>
    
  )} 

{largeFiles.map((file,index) => 
    <div>
      <p>{file.name} {formatBytes(file.size)} <span>다운로드 가능 기간</span></p>
      
      <button type="button" onClick={() => {removeFile(index);}}>Remove</button>
    </div>
    
  )} 
    </td>
  </tr>

  
 
      </table>
     <div id="response">{links}</div>
 <div className="" style={{ width: "100%", margin: "20px auto", height:"500px", border:"1px solid #ddd"}}>
    {editor === "toast" && (
      <Editor
        ref={editorRef} // DOM 선택용 useRef
        initialValue=" "
        previewStyle="vertical" // 미리보기 스타일 지정
        height="500px" // 에디터 창 높이
        initialEditType="wysiwyg" //
        toolbarItems={[
          // 툴바 옵션 설정
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock']
        ]}
        plugins={[colorSyntax]} 
        useCommandShortcut={false} // 키보드 입력 컨트롤 방지
        hooks={{
          addImageBlobHook: async (blob, callback) => {
            console.log(blob.size);
            if (blob.size > 5000000) {
              alert(
                "업로드에 실패했습니다. 이미지당 5MB까지 업로드할 수 있습니다."
              );
              return;
            }
            const formData = new FormData();
                    formData.append("img", blob);
                    
                    // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
                    // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...
                    await axios.post(
                      "https://gw.thegmmedical.com:5003/api/img",
                      formData
                    ).then(( res ) => {
                      console.log(res);
                      console.log(
                        "성공 시, 백엔드가 보내주는 데이터",
                        res.data.url
                      );
  
                      const IMG_URL = res.data.url;
                      setImgLink(IMG_URL);
                      // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
                      callback(IMG_URL, "test");
                      //alert(IMG_URL);
                      files.push(IMG_URL);
                      setArray(files);
                      //setArray((fileArray) => [...fileArray, IMG_URL]);
                      //alert("fileArray: ", files);
                      console.log(files);
                      var arrayLength = files.length;
                      //alert(arrayLength);
                      //alert("end");
                    
                    });
            //callback('https://gw.thegmmedical.com:5003/img/카레유.png', '카레유');
          }
        }}
      ></Editor>
    )}
    {editor === "excel" && (

      div

    )}
    </div>
      <div style={{width:"100%", margin:"10px auto"}}>
        <select value={editor} onChange={selectEditor}>
        <option value="toast">Toast 에디터</option>
        <option value="excel">엑셀 에디터</option>
        </select>
      </div>
      
      <div className="button_wrap">
      <button className="submit" onClick={handleRegisterButton}>등록</button>
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
    </div>
  );
}
