
import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {FaAddressBook} from "react-icons/fa";
import "../css/setting.css";
const DefaultSetting = ({navigation}) => {
  const [ruleName, setRuleName] = useState("");
  const [subject, setSubject]=useState("");
  const [boxes, setBoxes]=useState([]);

  const [emailAddr, setEmailAddr]=useState("");
  const [forward, setForward]=useState(false);
  const [del, setDel]=useState(true);


  const dataFetch = useRef(false);
  const getInitialState = () => {
    const value = "INBOX";
    return value;
  };
  const [start, setStart] = useState(getInitialState);

  const handleChange = (e) => {
    setStart(e.target.value);
  };

  const [num, setNum] = useState(10);

  const handleNum = (e) => {
    setNum(e.target.value);
  };
  useEffect(() => {
    
    if (dataFetch.current)
    return
    dataFetch.current = true;
    console.log("..,.;??");
    axios.get('http://gw.thegmmedical.com:5003/api/getboxes')
    .then(function (response){
      console.log("getboxes", response);
      
      setBoxes(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  
    
    axios.get('https://gw.thegmmedical.com:5003/api/get_default_mail')
    .then(function (response){
      console.log("default setting,,,,,,", response.data[0]);
      
      if (response.data.length>0){
        let setting = response.data[0];
        setStart(setting.start_box);
        setNum(response.data[0].perpage);
        setEmailAddr(response.data[0].forward_addr);


        if (response.data[0].forward_enabled == 1){
          setForward(true);
        }
        if (response.data[0].forward_delete == 0){
          setDel(false);
        }

      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  
    

  }, []);
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 
 
  function submit_filter(){
  
    const data = {
      start: start,
      num : num,
      forward_addr : emailAddr,
      forward_enabled : forward,
      forward_delete : del

    
    }
    console.log("is it working..?");
    axios.post('http://gw.thegmmedical.com:5003/api/default_setting',data)
    .then(function (response){
      console.log(response);
      alert("설정이 변경되었습니다.");
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };
  

  return (
    <div className="wrapper">
        <div className="sort-setting">
            <table style={{width:"100%"}}>
            <tr>
                    <thead>메일 초기 화면</thead>
                    <td style={{textAlign:"left"}}>
                    <div className="input-group">
                    <select value={start} onChange={handleChange}>
                      {boxes.map((box, idx) => {
                        if (box.name==="INBOX"){
                          return <option value="INBOX">받은 메일함</option>
                        }else if (box.name==="sent"){
                          return <option value="sent">보낸 메일함</option>

                        }else if (box.name==="Stars"){
                          return <option value="Stars">중요 메일함</option>

                        }else if (box.name==="draft"){
                          return <option value="draft">임시 보관함</option>

                        }else if (box.name==="Junk"){
                          return <option value="Junk">스팸 보관함</option>

                        }else if (box.name==="delete"){
                          return <option value="delete">휴지통</option>

                        }else{
                          return <option value={"INBOX."+box.name}>{box.name}</option>
                        }
                        
                      })}
                  
                    </select>
                       
                    </div>
                    </td>
                </tr>
                <tr>
                    <thead>페이지당 표시 개수</thead>
                    <td style={{textAlign:"left"}}>
                    <div className="input-group">
                    <select value={num} onChange={handleNum}>
                      <option>10</option>
                      <option>15</option>
                      <option>20</option>
                      <option>30</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                       
                    </div>
                    </td>
                </tr>
                <tr>
                    <thead>메일 포워딩 자동삭제</thead>
                    <td style={{textAlign:"left", display:"flex"}}>
                    <div className="input-group radio" style={{width:"25px", display:"flex"}}>
                    <input type="checkbox" value="0" name="weekN" checked={del === false} onChange={()=>{setDel(!del)}}
                    style={{marginBottom:"0"}}/>
                    </div>
                    <span>사용</span>
                    </td>
                </tr>

                <tr>
                    <thead>메일 포워딩 사용여부</thead>
                    <td style={{textAlign:"left", display:"flex"}}>
                    <div className="input-group radio" style={{width:"25px", display:"flex"}}>
                    <input type="checkbox" value="1" name="weekN" checked={forward === true} onChange={()=>{setForward(!forward)}}
                    style={{marginBottom:"0"}}/>
                    </div>
                    <span>사용</span>
                    </td>
                </tr>


                <tr>
                    <thead>메일 포워딩 주소</thead>
                    <td style={{textAlign:"left"}}>
                    <div className="input-group">
                  <input type="text" value={emailAddr} onChange={(e)=>setEmailAddr(e.target.value)}
                  style={{border:"1px solid #ddd"}}
                  ></input>
                    </div>
                    </td>
                </tr>


        
               
            </table>
            <div className="btns" style={{display:"flex",justifyContent:"center", width:"auto", margin:"20px"}}>

            <button className="button" onClick={()=> submit_filter()} >저장</button>
            </div>

            
            
      
      </div>

    </div>

  );
};

export default DefaultSetting;
