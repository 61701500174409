import { NavLink, Link,useParams, useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailNav from "../components/side/MailNav";
import './css/line.css';
import { VscTrash } from "react-icons/vsc";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import DocList from "../components/side/DocList";
import AutoCompleteLine from "../components/AutoCompleteLine";
import { GiHamburgerMenu } from "react-icons/gi";

axios.defaults.withCredentials = true; 





const LineList = () => {
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState();
  const [selectedName, setSelectedName] = useState("");
  const [lineList, setLineList] = useState([]);
  const [type, setType] = useState([]);
  const [value, setValue] = useState("");
  const [sidebar, setSidebar] = useState(false);
  const [lineType, setLineType] = useState("개인");

  useEffect(()=>{
    axios.get('https://gw.thegmmedical.com:5003/api/getlines')
    .then(function (response){
    console.log("hey result is!!!!!!!!!!!!", response.data);
    setLines(response.data);

    //setLine(response.data[1]);
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    
}, []);  

function showLineList(ele){
  setSelectedLine(ele.line_no);
  setSelectedName(ele.line_name);
  setLineType(ele.line_type);

  console.log("ele..e.e.e..e.e.e.",ele);
var trTags = document.getElementsByTagName("tr");
var searchText = ele.line_no.toString();
var found;


for (var i = 0; i < trTags.length; i++) {
  if (trTags[i].id === searchText) {
    found = trTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('tr');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';

  axios.get('https://gw.thegmmedical.com:5003/api/getlinelist/'+ele.line_no)
    .then(function (response){
      console.log("getlinelist", response);
     
      let list = response.data;

      list.sort(function (a, b) {
        if(a.hasOwnProperty('order_no')){
          return a.order_no - b.order_no;
        }
      });




      setLineList(list);

     



     
      //window.location.href="/doc_form";
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });




}
const typeSelect = (e) => {
  e.preventDefault();
  var selectedId = Number(e.currentTarget.className);
  console.log(e.currentTarget.className);
  var tmp = lineList;
  tmp[selectedId].type = e.target.value;
  setLineList([...tmp]);
};
const handleInputChange = (e) => {
  e.preventDefault();
  setSelectedName(e.target.value);

};
function compare( a, b ) {
  if ( a.order_no < b.order_no ){
    return -1;
  }
  if ( a.order_no > b.order_no ){
    return 1;
  }
  return 0;
}

function upOrder(e){
  var selectedId = Number(e.currentTarget.className.baseVal);
  var tmp = lineList;
  var tmp_order1 = tmp[selectedId].order_no;
  var tmp_order2 = tmp[selectedId-1].order_no;
  tmp[selectedId].order_no= tmp_order2;
  tmp[selectedId-1].order_no = tmp_order1;
  tmp.sort(compare);
  console.log(tmp);
  setLineList([...tmp]);
}

function downOrder(e){
  var selectedId = Number(e.currentTarget.className.baseVal);
  var tmp = lineList;
  var tmp_order1 = tmp[selectedId].order_no;
  var tmp_order2 = tmp[selectedId+1].order_no;
  tmp[selectedId].order_no= tmp_order2;
  tmp[selectedId+1].order_no = tmp_order1;
  tmp.sort(compare);
  console.log(tmp);
  setLineList([...tmp]);
}

function addLine(){
  let linename = prompt("결재라인명을 입력하세요", "");
  let text;
  if (linename === null || linename === "") {
    text = "User cancelled the prompt.";
  } else {
    const data ={
      name : linename,
    }
    axios.post('https://gw.thegmmedical.com:5003/api/addline', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
}

function delLine(){
  const data ={
    selected : selectedLine,
  }
  var result = window.confirm("정말 삭제하시겠습니까?");
  if(result){
    axios.post('https://gw.thegmmedical.com:5003/api/delline', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
}


function delList(e){

  var selectedId = Number(e.currentTarget.id);
  var tmp = lineList;
  tmp.splice(selectedId, 1);
  for (let i=0;i<tmp.length;i++){
    tmp[i].order_no = i+1;
  }
  console.log(tmp);
  setLineList([...tmp]);
}
function addList(val){
  
var subStr = val.substring(
  val.indexOf("[") + 1, 
  val.lastIndexOf("]")
);
var data={};
axios.get('https://gw.thegmmedical.com:5003/api/getuserinfo/'+subStr)
    .then(function (response){
      console.log(response);
     
      data.d_name = response.data[0].d_name;
      data.job_name = response.data[0].job_name;
      var tmp = lineList;


      for (let i=0;i<tmp.length;i++){
        tmp[i].order_no=tmp[i].order_no+1;
      }
      if (lineList.length > 0){
        console.log("야", lineList);
        data.order_no = 1;
      }else{
        data.order_no=1;
      }
     
      data.type="결재"
      data.name = response.data[0].name;
      data.user_no = parseInt(subStr);
      data.line_no = selectedLine;
      data.line = true;
      data.doc = true;
     
     


      var result = tmp.map(item => (item.user_no));
      console.log("저기저기억지거",result);
      if (!result.includes(parseInt(subStr))){
        tmp.push(data);


        console.log(
          "결재라인 ", data
        );


        tmp.sort(function(a, b) {
          return a.order_no - b.order_no;
        });
        setLineList([...tmp]);
      }
   


    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
};
function saveEdits(){
  var tmp_list = lineList;
  var line_content = tmp_list[0].name + "("+tmp_list[0].job_name+") ~ " + tmp_list[tmp_list.length-1].name+ "("+tmp_list[tmp_list.length-1].job_name+")";
  console.log("tmp_list[0]", tmp_list[0]);
  console.log("tmp_list", tmp_list);

  
  const data ={
    selected : selectedLine,
    name : selectedName,
    line_list : tmp_list,
    line_content : line_content,
    line_no : selectedLine,
    line_type : lineType
  }
  console.log("data..........", data);

  var result = window.confirm("변경 사항을 저장하시겠습니까?");
  if(result){
    axios.post('https://gw.thegmmedical.com:5003/api/edit_linelist', data)
    .then(function (response){
      console.log(response);
      var index = lines.map(function(o) { return o.line_no; }).indexOf(Number(selectedLine));
      let tmp = lines;
      console.log("index,,,,,,,,,,", index);
      tmp[index].line_content = line_content;
      tmp[index].line_type = lineType;
      setLines([...tmp]);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
}

function checkLine(e){
  var selectedId = Number(e.target.className);
  var tmp = lineList;
  console.log(tmp[selectedId]);
  tmp[selectedId][e.target.name] = !tmp[selectedId][e.target.name];
 

  setLineList([...tmp]);
}

  return (

        
        
  <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <DocList/>
        </div>
      )}
    
    <div className="content-wrap">
    <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>

<div className="side-menu">
  <DocList/>
</div>
   

<div className="line_main main_content">

    <h4>결재 라인 관리</h4>
   <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"60%", margin:"0"}}>
              <div className="left">

              
              <button className="btn" style={{marginRight:"10px"}} onClick={()=>addLine()}>신규라인</button>

              <button className="btn" style={{marginRight:"10px"}} onClick={()=>delLine()}>삭제</button>
             
              </div>

          
            </div>


<div className="line_table" style={{flexDirection:"column"}}>
<table width="100%" border="0" cellspacing="0" cellpadding="0">
<thead>	
    <tr>

    <th>번호</th>
    <th>결재라인명</th>
    <th>적용된 양식수</th>
    <th>내용</th>

    </tr>

</thead>
<tbody>

{lines.map((element, index)=>(
  <tr id={element.line_no} className={element.line_name} onClick={()=>showLineList(element)} style={{cursor:"pointer"}}>
  <td>{element.line_no}</td>
  <td>{element.line_name}</td>
  <td>{element.form_no}</td>
  <td>{element.line_content}</td>
  </tr>
))}


</tbody>
</table>

<div style={{display:"flex", alignItems:"center"}}>
<h4>결재라인별 결재자 관리 <span style={{color:"#8aa3ee", fontWeight:"normal"}}>-{selectedName}- </span> </h4>

<div>결재라인 타입
  <select style={{marginLeft:"5px"}}  value={lineType}
                                  onChange={(e)=>{setLineType(e.currentTarget.value)}}>
    <option value="회사">회사</option>
    <option value="부서">부서</option>
    <option value="개인">개인</option>
  </select>
</div>
</div>

<div className="actions" style={{width:"100%", display:"flex", alignItems:"start"}}>
<div style={{display:"flex", alignItems:"center"}} className="">
  <span style={{width:"90px"}}>결재자 추가</span>
  <AutoCompleteLine onChange={(value) => {setValue(value); console.log("hihi", value); addList(value)}}/>
  <button onClick={()=>addList()} style={{marginLeft:"10px"}}>추가</button>
</div>




<div style={{display:"flex", alignItems:"center"}} className="">
  <span style={{width:"90px"}}>결재라인명 변경 </span>
  <input type="text" name="subject" className="formInput" placeholder="제목을 입력하세요." value={selectedName}
          onChange={handleInputChange}></input>
</div>
</div>




<div style={{maxHeight:"200px", overflowY:"auto"}} className="line_table">
<table width="100%" border="0" cellspacing="0" cellpadding="0" margin="0">
<thead>	
    <tr>

    <th>순서</th>
    <th>타입</th>
    <th>부서명</th>
    <th>결재자</th>
    <th>이동</th>
    <th>라인수정</th>
    <th>문서수정</th>
    <th>삭제</th>
    </tr>

</thead>
<tbody>

{lineList.map((element, index)=>(
  <tr>
  <td>{element.order_no}</td>
  <td>
  <select name="form_group" className={index} value={element.type || type} onChange={typeSelect}>
    <option value="결재">결재</option>
    <option value="전결">전결</option>
    <option value="승인">승인</option>
  </select>
  </td>
  <td>{element.d_name}</td>
  <td>{element.name} ({element.job_name})</td>
  <td className="arrows">
    <FaArrowAltCircleUp style={{marginRight:"5px"}} className={index} onClick={(e)=>upOrder(e)} />
    <FaArrowAltCircleDown className={index} onClick={(e)=>downOrder(e)} />
  </td>

  <td>

<input type="checkbox" value="1" name="line" className={index} checked={element.line == true} onChange={(e)=>{checkLine(e)}}
style={{width:"30px"}}/>

</td>
<td>
<input type="checkbox" value="1" name="doc" className={index} checked={element.doc == true} onChange={(e)=>{checkLine(e)}}
style={{width:"30px"}}/>

</td>


  <td><VscTrash style={{fontSize:"20px"}} className="trash" id={index} onClick={(e)=>delList(e)}/></td>
  </tr>
))}
</tbody>
</table>
</div>
<div className="" style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>
    <button className="" onClick={()=>saveEdits()}>변경사항 저장</button>

    </div>


    </div>
      </div>
   </div>
    </div>
</div>

  );
};

export default LineList;
