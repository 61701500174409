import React, { useState, useEffect, useRef } from "react";
import "react-tooltip/dist/react-tooltip.css";
import styled from 'styled-components'


import "./css/share_mail.css";
import axios from "axios";



const SendToBoard = ({ doc_no, closePopup }) => {

  const [peopleArray, setPeopleArray] = useState([]);
  const [inputValue, setInputValue] = useState('')
  const [isHaveInputValue, setIsHaveInputValue] = useState(false)
  const [dropDownList, setDropDownList] = useState(peopleArray)
  const [dropDownItemIndex, setDropDownItemIndex] = useState(-1)

  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const [pic, setPic]= useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [userNo, setUserNo] = useState("0");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [pwCheck, setPwCheck] = useState("");
  const [preview, setPreview] = useState("");
  const [profile, setProfile] = useState("");
  const [department, setDepartment] = useState([]);
  const [selectedD, setSelectedD] = useState(39);
  const [selectedJ, setSelectedJ] = useState(13);
  const [job, setJob] = useState([]);
  const [startDate, setStartDate]=useState("");
  const [startD, setStartD]=useState("");
  const [showPopup, setPopup] = useState(false);
  const [tel, setTel]= useState("");
  const [extension, setExtension]= useState("");
  const [fax, setFax]= useState("");
  const [place, setPlace]= useState("");
  const [recItem, setRecItem] = useState("");
  const [recList, setRecList] = useState([]);
  const [boards, setBoards] = useState([]);

  const togglePopup = () => {
    setPopup(!showPopup);
  };

  const imageRef=useRef();

  const [boardName, setBoardName] = useState("");
  const [boardNo, setBoardNo] = useState("");

  

  
 
    
  

  

  useEffect(() => {
    
fetch("http://gw.thegmmedical.com:5003/api/getboards", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
        setBoards(json);
          
          setBoardNo(json[0].bg_no);
          setBoardName(json[0].bg_name);
        
    })
    .catch(() => {});
    
    
}, []);


const handleRegisterButton = () => {

  if (subject === "") {
    alert("제목을 입력해주세요.");
    return false;
  } 
  if (boardNo === "") {
    alert("게시판을 선택해주세요.");
    return false;
  } 

  const data = {
    doc_no : doc_no,
    bg_no : boardNo,
    subject : subject
  }
  axios.post('http://gw.thegmmedical.com:5003/api/upload_board', data)
  .then(function (response){
    alert("게시판에 등록되었습니다.");
    window.location.reload(true);

  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
    

};

const selectBoard = (e) => {
  e.preventDefault();
  var index = e.nativeEvent.target.selectedIndex;
  setBoardName(e.nativeEvent.target[index].text);
  setBoardNo(e.target.value);
};
  return (
    <div className="popup send_work">
      <div className="inner" style={{height:"auto", maxHeight:"500px", position:"relative",
      left:"50%", transform:"translateX(-50%) translateY(-50%)", top:"50%"}}>
        <h3 style={{textAlign:"left"}}>게시글 업로드</h3>
        <div className="shareMail">
      
        <div className="input-group">
        <select
        style={{border:"1px solid #ddd", marginLeft:"5px", padding:"2px 5px"}}
        className="select"
        value={boardNo}
        onChange={selectBoard}
      >
        <option value="">게시판 선택</option>
        {boards.map((element, index)=>
        <option value={element.bg_no}>{element.bg_name}</option>
        )}
   
      </select>
</div>

      <div className="input-group">

          <input type="text" value={subject} onChange={(e)=>setSubject(e.currentTarget.value)} placeholder="제목을 입력하세요"></input>
        </div>

      
     
      
   
      <div className="buttons">
        <button onClick={()=>handleRegisterButton()}>업로드</button>
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>취소</button>
        </div>


        </div>
        
        
      </div>
      
    </div>
  );
};
const DropDownBox = styled.ul`
  display: block;
  width:475px;
  top:21px;
  position:absolute;
  
  padding: 8px 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 2px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 9999;
`

const DropDownItem = styled.li`
  padding: 0 16px;

  &.selected {
    background-color: lightgray;
  }
`
export default SendToBoard;