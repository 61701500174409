import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import styled from 'styled-components';
// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import TextareaAutosize from 'react-textarea-autosize';

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
 
};

const InputText = styled.textarea`
  all: unset;
  display: block;
  width: 100%;
  height: ${({ row, theme }) => +theme.listSize * row + 4}px;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  resize: none;
`;
function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {name, product_no, unit, standard, quantity,unit_price, amount, user, note }= rowsData;
    return (
      <tr key={index}>
        <td> 
     

<TextareaAutosize style={{width:"95%", border:"none"}} name="name" value={name} onChange={(event) => onValUpdate(index, event)}
  className="form-control"
/>


        </td>
       
        <td>
       
          <TextareaAutosize style={{width:"95%", border:"none"}} name="product_no" value={product_no} onChange={(event) => onValUpdate(index, event)}
  className="form-control"
/>
        </td>
        <td>
   
             <TextareaAutosize style={{width:"95%", border:"none"}} name="unit" value={unit} onChange={(event) => onValUpdate(index, event)}
  className="form-control"
/>
        </td>
        <td>
      

<TextareaAutosize style={{width:"95%", border:"none"}} name="standard" value={standard} onChange={(event) => onValUpdate(index, event)}
  className="form-control"
/>
        </td>

        <td>
       

<TextareaAutosize style={{width:"95%", border:"none"}} name="quantity" value={quantity} onChange={(event) => onValUpdate(index, event)}
  className="form-control"
/>
        </td>
        <td>
       

<TextareaAutosize style={{width:"95%", border:"none"}} name="unit_price" value={unit_price} onChange={(event) => onValUpdate(index, event)}
  className="form-control"
/>
        </td>

        <td>
 
  <TextareaAutosize style={{width:"95%", border:"none"}} name="amount" value={amount} onChange={(event) => onValUpdate(index, event)}
  className="form-control"
/>
        </td>
        <td>
       

<TextareaAutosize style={{width:"95%", border:"none"}} name="user" value={user} onChange={(event) => onValUpdate(index, event)}
  className="form-control"
/>
        </td>

        <td>
        
<TextareaAutosize style={{width:"95%", border:"none"}} name="note" value={note} onChange={(event) => onValUpdate(index, event)}
  className="form-control"
/>
        </td>
        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

const Order = ({doc_no, onChange, line}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [list, setList] = useState([]);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

  
  
  const { state } = useLocation();

  useEffect(()=>{
    //This function will run every time the value of values state changes.
    onChange(values); //Will give you the updated value of values state.
    //Do some other stuff
  }, [values])


  useEffect(() => {
    console.log(doc_no);
    if (doc_no!==undefined){
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/purchase/'+doc_no)
    .then(function (response){
      if (response.data[0]!==undefined){
      let order_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/purchase/'+order_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<15){
          let len = response.data.length;
          while (15-len>0){
          temp.push({name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);


  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    values[name] = value;
    values.arr = rows;
    onChange(values);

  };
 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let initial = [{
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    }];
    initRow(initial);
    
    
  }, []);


  const addRowTable = () => {
    const data = {
        product_name:'', quantity:'',unit_price:'', price:'', memo:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    let val = value.replace(/(?:\r\n|\r|\n)/g,'<br/>');
    const data = [...rows];
    console.log(data, name, val, i);
    data[i][name] = val;
    initRow(data);

    values.arr = rows;
    var total = 0;
    if (name ==="quantity" || name ==="unit_price"){
        for (let j=0;j<data.length;j++){
            var row_total = data[j].quantity * data[j].unit_price;
            if (row_total !==0){
                data[j].amount = row_total.toLocaleString();
            }
            
            total +=row_total;
        }
    }

    values.total = total;
    values.vat = total;
    onChange(values);

  };


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });
  const [textareaHeight, setTextareaHeight] = useState({
    row: 1,
    lineBreak: {},
  });

  const resizeTextarea = e => {
    const { scrollHeight, clientHeight, value } = e.target;
    
    // 줄바꿈이 일어날 때
    if (scrollHeight > clientHeight) {
      setTextareaHeight(prev => ({
        row: prev.row + 1,
        lineBreak: { ...prev.lineBreak, [value.length - 1]: true },
      }));
    }
  
    // 텍스트 지워서 줄바꿈 지점에 도달했을 때
    if (textareaHeight.lineBreak[value.length]) {
      setTextareaHeight(prev => ({
        row: prev.row - 1,
        lineBreak: { ...prev.lineBreak, [value.length]: false },
      }));
    }
  };

  const onKeyEnter = e => {
    if (e.code === 'Enter') {
      setTextareaHeight(prev => ({
        row: prev.row + 1,
        lineBreak: { ...prev.lineBreak, [e.target.value.length]: true },
      }));
    }
  };
  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

            <div className="order_write purchase" style={{padding:"0", margin:"20px auto"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>구입품의서</td>
                   
                  </tr>
              
                  
                </table>

                <table className="" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            부서명
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <TextareaAutosize style={{width:"98%", border:"none"}} name="department" value={values.department} onChange={handleInputChange}/>

                            
                          </td>

                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            작성일자
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input style={{width:"98%", border:"none"}} name="create_date" value={values.create_date} onChange={handleInputChange}/>

                          </td>


                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            작성자
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <TextareaAutosize style={{width:"98%", border:"none"}} name="name" value={values.name} onChange={handleInputChange}/>

                          </td>
                        </tr>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            납품업체명 
                          </th>
                          <td style={{ border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <TextareaAutosize style={{width:"98%", border:"none"}} name="company" value={values.company} onChange={handleInputChange}/>

                          </td>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            업체담당 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <TextareaAutosize style={{width:"98%", border:"none"}} name="charge" value={values.charge} onChange={handleInputChange}/>

                          </td>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            전화번호 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <TextareaAutosize style={{width:"98%", border:"none"}} name="tel" value={values.tel} onChange={handleInputChange}/>

                          </td>
                        </tr>
                       
                        <tr>
                          <th colSpan={4} style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            다음과 같이 구입하고자 하오니 승인하여 주시기 바랍니다. 
                          </th>
                          
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            현금 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <TextareaAutosize style={{width:"98%", border:"none"}} name="cash" value={values.cash} onChange={handleInputChange}/>

                          </td>
                       
                        </tr>


                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            구입명 
                          </th>
                          <td colSpan={3} style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>

                          <TextareaAutosize style={{width:"98%", border:"none"}} name="purchase_name" value={values.purchase_name} onChange={handleInputChange}/>

                          </td>
                      
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            계좌이체 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <TextareaAutosize style={{width:"98%", border:"none"}} name="account" value={values.account} onChange={handleInputChange}/>

                          </td>
                        </tr>


                        <tr height={50}>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            구입금액 (VAT포함)
                          </th>
                          <td colSpan={2} style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" value="" onChange={handleInputChange}></input>

                          </td>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.vat!==undefined && (Number(values.vat).toLocaleString())} 원
                          </td>
                          
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            법인카드 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <TextareaAutosize style={{width:"98%", border:"none"}} name="card" value={values.card} onChange={handleInputChange}/>

                          </td>
                        </tr>


                        <tr height={50}>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            내용 
                          </th>
                          <td colSpan={5} style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <TextareaAutosize style={{width:"98%", border:"none"}} name="content" value={values.content} onChange={handleInputChange}/>

                          </td>
                          
                        </tr>

                      </tbody>
                    </table>

            <table style={{marginTop: '1px', borderCollapse: 'collapse'}} className="forms">{/* Header */} 
          
              <tbody>
                
              

                <tr>
                <div className="btns" style={{display:"flex", justifyContent:"flex-end", margin:"20px 0"}}>
     
        <button className="btn" onClick={addRowTable}>
          추가
        </button>


      </div>
                <table className="form_list order_list" style={{borderCollapse: 'collapse', width:"790px"}}>
                 
          <thead>
            <tr>
              <th>품목</th>
              <th>(드림디포)<br></br>제품번호</th>
              <th>단위</th>
              <th>규격</th>
              <th>수량</th>
              <th>단가</th>
              <th>금액</th>
              <th>사용자</th>
              <th>비고</th>

            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />
            <tr>
            <td colSpan={2}>합계</td>
            <td colSpan={5}> {values.total!==undefined && (Number(values.total).toLocaleString())} 원
</td>

            <td colSpan={3}>VAT포함 {values.vat!==undefined && (Number(values.vat).toLocaleString())} 원
</td>
            </tr>

            <tr>
            <td rowSpan={4}>견적대비</td>
            <td>구분</td>
            <td colSpan={2}>                          

            <TextareaAutosize style={{width:"96%", border:"none"}} name="cate1" value={values.cate1} onChange={handleInputChange}/>

            </td>
            <td colSpan={2}>
            <TextareaAutosize style={{width:"96%", border:"none"}} name="cate2" value={values.cate2} onChange={handleInputChange}/>

            </td>
            <td colSpan={4}>
            <TextareaAutosize style={{width:"96%", border:"none"}} name="cate3" value={values.cate3} onChange={handleInputChange}/>

            </td>

          </tr>


          <tr>
            <td>업체명</td>
            <td colSpan={2}>
            <TextareaAutosize style={{width:"96%", border:"none"}} name="c1" value={values.c1} onChange={handleInputChange}/>


            </td>
            <td colSpan={2}>
            <TextareaAutosize style={{width:"96%", border:"none"}} name="c2" value={values.c2} onChange={handleInputChange}/>

            </td>
            <td colSpan={4}>

            <TextareaAutosize style={{width:"96%", border:"none"}} name="c3" value={values.c3} onChange={handleInputChange}/>

            </td>

          </tr>

          <tr>
            <td>제품단가</td>
            <td colSpan={2}>
            <TextareaAutosize style={{width:"96%", border:"none"}} name="u1" value={values.u1} onChange={handleInputChange}/>

            </td>
            <td colSpan={2}>
            <TextareaAutosize style={{width:"96%", border:"none"}} name="u2" value={values.u2} onChange={handleInputChange}/>

            </td>
            <td colSpan={4}>
            <TextareaAutosize style={{width:"96%", border:"none"}} name="u3" value={values.u3} onChange={handleInputChange}/>

            </td>

          </tr>

          <tr>
            <td>금액</td>
            <td colSpan={2}>
            <TextareaAutosize style={{width:"96%", border:"none"}} name="p1" value={values.p1} onChange={handleInputChange}/>

            </td>
            <td colSpan={2}>
            <TextareaAutosize style={{width:"96%", border:"none"}} name="p2" value={values.p2} onChange={handleInputChange}/>

            </td>
            <td colSpan={4}>
            <TextareaAutosize style={{width:"96%", border:"none"}} name="p3" value={values.p3} onChange={handleInputChange}/>

            </td>

          </tr>

          <tr>
            <td>첨부서류</td>
            <td colSpan={9}>

            <TextareaAutosize style={{width:"98%", border:"none"}} name="link" value={values.link} onChange={handleInputChange}/>

            </td>

          </tr>

          <tr>
            <td>지급일자</td>
            <td colSpan={2}>
            <input type="date" name="pay_date" value={values.pay_date} onChange={handleInputChange}></input>

            </td>
            <td>은행명</td>
            <td colSpan={6}>
            <input type="text" name="bank_name" value={values.bank_name} onChange={handleInputChange}></input>

            </td>
          </tr>

          <tr>
            <td>예금주</td>
            <td colSpan={2}>
            <input type="text" name="holder" value={values.holder} onChange={handleInputChange}></input>

            </td>
            <td>계좌번호</td>
            <td colSpan={6}>
            
            <TextareaAutosize style={{width:"98%", border:"none"}} name="account_no" value={values.account_no} onChange={handleInputChange}/>

            </td>
          </tr>
          </tbody>
        </table>

     
                </tr>
              </tbody>
            </table>

            <h3>의견내용</h3>
            <div style={{border:"1px solid black", padding:"10px"}}>

            <textarea style={{width:"98%", border:"none"}} name="comment" value={values.comment} onChange={handleInputChange}/>

            </div>

          </span></div>
        
        
      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default Order;

