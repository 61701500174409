
import { NavLink, Link } from "react-router-dom";
import FolderTree from 'react-folder-tree';
import React, { useState, useEffect, useRef } from "react";
import AddBoard from "../components/AddBoard";
import axios from "axios";
import "./css/adminLevel.css";
import { RxHamburgerMenu } from "react-icons/rx";

import Sidebar from "../components/side/MobileSidebar";


const AdminLevel = () => {
  const [members, setMembers] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectLevel, setSelectLevel] = useState();
  const [CheckList, setCheckList] = useState([]);
  const [CheckLevels, setCheckLevels] = useState([]);
  const [sidebar, setSidebar]=useState(false);
  const ref = useRef([]);

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  window.addEventListener('message', (event) => {
    if (event.data["selectedMembers"] !== undefined){
      
      var temp = event.data["selectedMembers"];
      var tempArray = members;
      for (let i=0;i<temp.length;i++){
        if (!tempArray.includes(temp[i])){
          
          tempArray = [...tempArray, temp[i]];
        }
      }
      const map = new Map(
        tempArray.map(obj => [obj.user_no, obj])
      );

      const deduplicatedArr = [...map.values()];
      setMembers(deduplicatedArr);
      
    }
 
  });
  function saveSetting(){
    const data={
      selectLevel : selectLevel,
      list : members
    }
    console.log(data);
    axios.post('https://gw.thegmmedical.com:5003/api/savemembers', data)
        .then(function (response){
          console.log(response);
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
  }
  useEffect(() => {
    fetch("https://gw.thegmmedical.com:5003/api/getlevels", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log("level", json);
      setLevels(json);
    })
    .catch(() => {});

    

  }, []);

  function showMembers(selectedLevel){
    console.log(selectedLevel);
    fetch("https://gw.thegmmedical.com:5003/api/getmembers/"+selectedLevel, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log("members: ", json);
      setMembers(json);
    })
    .catch(() => {});
  }

  function selectAll()  {
    let checkboxes 
         = document.getElementsByName('member');
    let all 
         = document.getElementsByName('all');
    console.log(checkboxes);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = all[0].checked;
    })
  
  }
  function selectAllLevel()  {
    let checkboxes 
         = document.getElementsByName('level');
    let all 
         = document.getElementsByName('levelall');
    console.log(checkboxes);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = all[0].checked;
    })
  
  }
  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
       setCheckList([...CheckList, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
       setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }
 }

 const onChangeLevels = (e, id) => {
  // 체크할 시 CheckList에 id값 넣기
  if (e.target.checked) {
     setCheckLevels([...CheckLevels, id]);
  // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
  } else {
     setCheckLevels(CheckLevels.filter((checkedId) => checkedId !== id));
  }
}
function delMembers(){
  let temp = members;
  let filtered = temp.filter( x => !CheckList.includes(x.user_no))
  setMembers(filtered);

      
 }
 function delLevels(){

  var confirm = window.confirm("보안등급을 삭제하시겠습니까?");
  if (confirm){
    const data={
      checkList : CheckLevels
    }
    console.log(data);
    axios.post('https://gw.thegmmedical.com:5003/api/dellevels', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

  }
  
 }
 function addLevels(){

  var confirm = window.confirm("보안등급을 추가하시겠습니까?");
  if (confirm){
    var temp = levels;
    var last = levels[levels.length-1].no;
    var lastItem = {no:last+1, level: last+1, name: (last+1).toString(), in_use:null};
    
    temp = [...temp, lastItem];console.log(temp);
    const data = {
      add : lastItem
    }
    axios.post('https://gw.thegmmedical.com:5003/api/addlevel', data)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    setLevels(temp);
    
  }

  
 }
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents" style={{maxWidth:"1600px"}}>
        <div className="header_txt">
        <h3>보안등급 관리</h3>
        </div>
    
      <div className="admin_wrapper admin_level">
        <div className="main_contents_wrapper">
        <p>보안등급 목록</p>
        <div className="btns" style={{width:"100%",justifyContent:"space-between", display:"flex"}}>
          <button onClick={() => addLevels()} alt=''>등급 추가</button>
        <button onClick={() => delLevels()} alt=''>삭제</button>
        </div>
        
        <div style={{height:"300px", marginTop:"20px",overflowY:"scroll", border:"1px solid #ddd"}}>
        <table className="level_table" style={{width:"100%", margin:"0"}}>
                <thead>
                  <tr>
                  <td><input type='checkbox' name='levelall' value='levelall' onClick={selectAllLevel}/></td>
                  <td>보안등급</td>
                  <td>이름</td>
                  <td>사용</td>
                  </tr>
                </thead>
                <tbody>
                    { levels.map((element, index) => 
                    
                    <tr className={element.no} style={{cursor:"pointer"}}onClick={()=>{
                      console.log("lenth", ref.current.length)
                      for (let i = 0; i < ref.current.length; i++) {

                          ref.current[i].checked = false;
                      }
                      setMembers([]);setSelectLevel(element.level);showMembers(element.no)}}>
            <td> <input type='checkbox'
        name='level' 
        value={element.no}
        onChange={(e) => onChangeLevels(e, element.no)} checked={CheckLevels.includes(element.no)}/> </td>
                      <td className={element.no} key={index}>
                      {element.level}</td>
                      <td>{element.name}등급</td>
                      <td> {element.in_use}</td>
                      </tr>) }

                      </tbody>
                </table>
                
                </div>
                <span style={{fontSize:"12px"}}>*등급 숫자가 작을수록 더 높은 보안 등급입니다.</span>
        </div>

        <div className="main_contents_wrapper">
        <p>열람자 목록</p>
        <div className="btns" style={{width:"100%",justifyContent:"space-between", display:"flex"}}>
          <button onClick={() => popupwindow("/select", "결재라인", 800, 700)} alt=''>열람자 추가</button>
          <span>{selectLevel}</span>
        <button onClick={() => delMembers()} alt=''>삭제</button>
        </div>
        <div style={{height:"300px", marginTop:"20px",overflowY:"scroll", border:"1px solid #ddd"}}>
        <table className="member_table" style={{width:"100%", margin:"0"}}>
                <thead>
                <tr><td><input type='checkbox' name='all' value='all' onClick={selectAll}/></td>
                  <td>부서</td>
                  <td>직급</td>
                  <td>이름</td>
                  </tr>
                </thead>
                <tbody>
                    {members.map((element, index) => 
                    
                    <tr>
            <td>
                      <input type='checkbox' 
        name='member' 
        value={element.user_no}
        onChange={(e) => onChangeEach(e, element.user_no)} checked={CheckList.includes(element.user_no)}/> 
                      </td>
                      <td className={element.user_no} key={index}>
                      {element.d_name}</td>
                      <td>{element.job_name}</td>
                      <td> {element.name}</td>
                      </tr>) }

                      </tbody>
                </table>
                </div>
                        <div className="btns" style={{width:"100%",justifyContent:"center", marginTop:"30px",display:"flex"}}>
                          <button onClick={()=>saveSetting()}>저장</button>
                        </div>
        </div>

        
        </div>
       
     </div> 
    </div>

  );
};

export default AdminLevel;
