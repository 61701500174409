import React, { useEffect, useState, useReducer  } from "react";
import { NavLink, Link,useParams } from "react-router-dom";
import moment from "moment";
import './css/mail.css';
import './css/common.css';
import MailNav from "../components/side/MailNav";
import axios from "axios";
import Search from "./Search";
import Dropdown from "./Dropdown";
import { FaStar, FaRegStar, FaRegEnvelopeOpen, FaPaperPlane } from "react-icons/fa";


import Pagination from "./Pagination";

axios.defaults.withCredentials = true; 

const MailDone = () => {
  const [boxes, setBoxes]=useState([]);
  return (

  <div className="wrapper">
    

  <div className="content-wrap">
  <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%", height:"100vh"}}>

  <MailNav boxes={boxes}/>  
 
<div className="mail_done">
<div style={{display:"flex", alignItems:"center"}}><FaPaperPlane style={{marginRight:"10px"}}/><h2>메일을 전송했습니다.</h2></div>
<table width="100%" border="0" cellspacing="0" cellpadding="0">
<tbody>
<tr height="40">
    <td>메일 제목 : </td><td class="table_list" align="center">발신</td>
</tr>
<tr height="40">

    <td>받는사람 : </td><td class="table_list" align="center">변수연</td>
    
</tr>
<tr height="40">

    <td>발신일자 : </td><td class="table_list" align="center">변수연</td>
    
</tr>
</tbody></table>

<div className="btns" style={{display:"flex",alignItems:"center", justifyContent:"center", width:"1100px", marginTop:"80px"}}>

            

<div className="btn" style={{display:"flex", alignItem:"center",justifyContent:"center",marginRight:"10px", padding:"15px 0"}}>
  확인
</div>
<div className="btn" style={{display:"flex", alignItem:"center", justifyContent:"center",padding:"15px 0"}}>
  메일 쓰기
</div>


</div>

</div>






</div>
</div>
    </div>
  );
};

export default MailDone;
