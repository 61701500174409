import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  addr:"서울 서초구 서초대로 46길 19-7, 201호/Tel:02)2055-2255, Fax:02)2055-1177",
  incharge:"변수연",
  hospital: "",
  inspect_date: "",
  product:"",
  model: "",
  mfr_num: "",
  note:""
};


const InspectView = ({data, line}) => {  
  

  const [links, setLinks]=useState([]);
  const [select, setSelect] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);



 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/inspect/'+data)
    .then(function (response){
      console.log("order.........",response.data[0]);
      if (response.data[0]!==undefined){
      setValues(response.data[0]);
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }, []);


 


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper forms" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>

        <div className="order_write inspect" style={{padding:"50px", boxSizing:"border-box",margin:"20px auto"}}>
       
          <div>
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>점검 소견서</td>
                  
                  </tr>
                  <tr>
                
                  
                  </tr>
                  
                </table>
                <div style={{display:"flex", justifyContent:"space-between", marginBottom:"10px"}}>
                  <span>{values.addr}</span>

                  <span>담당자: {values.incharge}</span>

                </div>
                <hr style={{borderWidth:"5px"}}/>


                <table className="" style={{width:"100%", margin:"20px"}}>
          <tbody>
          <tr>
          <td style={{width:"60px"}}>수신 : </td>
          <td colspan="3">                            
          {values.hospital} 

          </td>
       
          </tr>
          <tr>
          <td style={{width:"60px"}}>소견일 : </td><td colspan="3">
          {values.inspect_date} 
          </td>
          </tr>
          </tbody>
          </table>

<br></br>
<h4 style={{margin:"20px"}}>제 목: 장비 점검 기술소견서</h4>
<hr style={{borderWidth:"2px", color:"#000"}}/>
    <div style={{width:"90%", margin:"20px auto"}}> 
    <br></br>
    <p>1. 귀 원의 무궁한 발전을 기원합니다.</p>          
    <p>2. 사용하시던 장비에 대한 점검내역을 보내드리오니 업무에 참조 바랍니다.</p>  
    </div>
    <br></br><br></br>
                <table className="first_table" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', width:"80%", margin:"0 auto", borderCollapse: 'collapse'}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            품&nbsp;&nbsp;&nbsp;명
                          </th>
                          <td style={{ border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.product}
                          </td>

                       
                        </tr>
                        <tr>
                          <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            모 델 명
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.model}
                          </td>
                        
                          
                        </tr>
                      
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            제조번호
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.mfr_num}
                          </td>

                        </tr>

                        <tr>
                          <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            소견 
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}
                          colSpan={3}>
                          {values.note}
                          </td>
                         
                        </tr>
                      
                      </tbody>
                    </table>


<h2 style={{textAlign:"center", marginTop:"50px"}}>(주)GM메디칼</h2>

      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default InspectView;
