






import { NavLink, Link } from "react-router-dom";
import { Document, Page } from 'react-pdf'

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {FaAddressBook} from "react-icons/fa";
import sign from "../img/user.png";
import "../css/setting.css";

const BasicInfo = ({no, info}) => {
  const [values, setValues]=useState(info);
 

  const inputFileRef = useRef();
  const [imgFileList, setImgFileList] = useState([]);
  const [pdfFileList, setPdfFileList] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoaded = ({ numPages }) => setNumPages(numPages);

  function getExt(filename)
  {
      var ext = filename.split('.').pop();
      if(ext == filename) return "";
      return ext;
  }
  useEffect(()=>{
    if (no !==undefined){
      axios.get('http://gw.thegmmedical.com:5003/api/get_customer_basic/'+no)
      .then(function (response){
        console.log(response);
        setValues(response.data[0]);
      })


      axios.get('http://gw.thegmmedical.com:5003/api/get_customer_files/'+no)
      .then(function (response){
        console.log("get_customer_files....",response.data);
        //setFileList(response.data);
        setFileList(response.data);
        let imgs = [];
        let pdfs = [];
        var imagesExtension = ["png", "jpg", "jpeg"];
  
  
        for (let i=0;i<response.data.length;i++){
          console.log("getExt.....", getExt(response.data[i].filename));
          if (imagesExtension.indexOf(getExt(response.data[i].filename)) !== -1){
            imgs.push(response.data[i]);
          }else if (getExt(response.data[i].filename)==="pdf"){
            pdfs.push(response.data[i]);
          }
        }
        setImgFileList([...imgs]);
        setPdfFileList([...pdfs]);
  
  
      })

    }


    
  },[])





function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0'

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const onBtnClick = () => {
  /*Collecting node-element and performing click*/
  inputFileRef.current.click();
};

function downloadAll(urls) {
  var link = document.createElement('a');

  
  link.style.display = 'none';

  document.body.appendChild(link);

  for (var i = 0; i < urls.length; i++) {
    link.setAttribute('download', urls[i].filename);
    let href = "https://gw.thegmmedical.com/uploads/customer/"+no+"/"+urls[i].filename;
    link.setAttribute('href', href);
    link.click();
  }

  document.body.removeChild(link);
}


  return (

<table className="form_write" style={{margin:"10px auto", width:"100%"}}>

{/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}



        <tr>
<td class="table_title2" nowrap="">
그룹 선택
</td>
<td class="table_list2_left">
<div className="input-group">
{values.type}
</div>
</td>



</tr>

<tr>
<td class="table_title2" nowrap="">
상호(법인명)
</td>
<td class="table_list2_left">
<div className="input-group">
{values.name}
</div>
</td>



</tr>

<tr>


<td class="table_title2" nowrap="">
사업자등록번호
</td>
<td class="table_list2_left">
<div className="input-group">
{values.biz_no}
</div>
</td>

<td class="table_title2 pc" nowrap="">
성명(대표자)
</td>
<td class="table_list2_left pc">
<div className="input-group">
{values.rep}
</div>
</td>
</tr>


<tr className="mobile">
<td class="table_title2" nowrap="">
성명(대표자)
</td>
<td class="table_list2_left">
<div className="input-group">
{values.rep}
</div>
</td>
</tr>


<tr>
<td class="table_title2" nowrap="">
주민(법인)등록번호
</td>
<td class="table_list2_left">
<div className="input-group">
{values.register_no}
</div>
</td>




</tr>

<tr style={{height:"auto"}}>
<td class="table_title2" nowrap="">
사업장소재지
</td>
<td class="table_list2_left">
<div className="input-group">
{values.addr}
</div>
</td>



</tr>

<tr>
<td class="table_title2" nowrap="">
본점소재지
</td>
<td class="table_list2_left">
<div className="input-group">
{values.hq}
</div>

</td>
</tr>


<tr>
<td class="table_title2" nowrap="">
개업일
</td>
<td class="table_list2_left">
<div className="input-group">
{values.open_date}
</div>

</td>
</tr>


<tr>
<td class="table_title2" nowrap="">
사업자등록일
</td>
<td class="table_list2_left">
<div className="input-group">
{values.register_date}
</div>

</td>
</tr>




<tr>
<td class="table_title2" nowrap="">
업태
</td>
<td class="table_list2_left">
<div className="input-group">
{values.biz_type}
</div>

</td>
</tr>
<tr>
<td class="table_title2" nowrap="">
종목
</td>
<td class="table_list2_left">
<div className="input-group">
{values.biz_item}
</div>

</td>
</tr>


<tr>
<td class="table_title2" nowrap="">
메모
</td>
<td class="table_list2_left">
<div className="input-group">
{values.note}
</div>

</td>
</tr>








<tr style={{height:"auto"}}>
<td class="table_title2" nowrap="">

</td>
<td class="upload_file_list">

{imgFileList.length>0 && (
imgFileList.map((file,index) => 
<div className="file_item">
{
  <img src={"https://gw.thegmmedical.com/uploads/customer/"+no+"/"+file} alt={file}></img>
}
</div>


) 
)}


{pdfFileList.length>0 && (
pdfFileList.map((file,index) => 
<div className="file_item">
{
  <Document file={"https://gw.thegmmedical.com/uploads/customer/"+no+"/"+file} onLoadSuccess={onDocumentLoaded}>
  <Page pageNumber={pageNumber} />
  </Document>}
</div>


) 
)}

</td>
</tr>




{fileList.length>0 && (


<tr style={{height:"auto"}}>
<td class="table_title2" nowrap="" style={{flexDirection:"column"}}>
첨부파일
<button onClick={()=>downloadAll(fileList)}>일괄 다운로드</button>
</td>
<td class="upload_file_list" style={{padding:"10px"}}>
{fileList.map((file,index) => 
<div className="file_item">
{
  <a href={"https://gw.thegmmedical.com/uploads/customer/"+no+"/"+file.filename} download>{file.filename}</a>
}
</div>

)} 


</td>
</tr>


)}

</table>


)};

export default BasicInfo;