import React, { useRef, useEffect,useMemo, useState } from "react";
import {GiHamburgerMenu} from "react-icons/gi";
import FolderTree from 'react-folder-tree';
import axios from "axios";
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from 'react-icons/fa';
import "./css/contacts.css";
import { Link, useParams } from "react-router-dom";

import styled from 'styled-components';
import 'react-folder-tree/dist/style.css';
axios.defaults.withCredentials = true; 

const Contacts = () => {
    const onTreeStateChange = (state, event) => console.log(state, event);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [toList, setTo] = useState([]);
    const [ccList, setCC] = useState([]);
    const [bccList, setBCC] = useState([]);
    const [toDel, setToDel] = useState([]);
    const [ccDel, setCCDel] = useState([]);
    const [bccDel, setBCCDel] = useState([]);
    const [folders, setFolders] = useState([]);

    const [addrs, setAddrs] = useState([]);
    const [department, setDepartment] = useState([]);
    const [myGroups, setMyGroups] = useState([]);
    const [currentTab, clickTab] = useState(0);

    function lookingForNodeWithParent( nodes, parentId ) {

        var arrayToReturn = [];
      
        for( var i = 0, length = nodes.length; i < length; i++ ) {
          if( nodes[i].d_parent === parentId ) {
              var node = nodes[i];
            
            arrayToReturn.push({
              name: node.d_name,
              children: lookingForNodeWithParent( nodes, node.d_no )
            });
          }
        }
        
        return arrayToReturn;
      }
      
      
      useEffect(() => {
     
        fetch("http://gw.thegmmedical.com:5003/api/getdepartment_group", {
            method: "get", // 통신방법
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
          })
          .then((res) => res.json())
          .then((json) => {
            console.log(json);  
      console.log(lookingForNodeWithParent( json, null ));
            //setBoxes(json[0].boxes);
            var array = lookingForNodeWithParent( json, null );
            //let newData = array.map(el => el[0]);
                //console.log(newData);
            return setFolders(array[0]);
    
            //console.log(boxes.boxes);
            //console.log(boxes.boxes);
          })
          .catch(() => {});
      




          axios.get('http://gw.thegmmedical.com:5003/api/getaddrs',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    setAddrs(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

   
    axios.get('http://gw.thegmmedical.com:5003/api/get_teamaddr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setDepartment(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('http://gw.thegmmedical.com:5003/api/get_user_addr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setMyGroups(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });



      }, []);
    
   

      const [selectedItem, setSelectedItem] = useState(null);

   

      const onNameClick = ({ defaultOnClick, nodeData }) => {
        defaultOnClick();
      
        const {
          // internal data
          path, name, checked, isOpen, children
          // custom data
        } = nodeData;
      
        

        const item = name;
        const tempArray = [...options, item];
        //setOptions(tempArray);

        axios.post('http://gw.thegmmedical.com:5003/api/getcontacts',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              folder_name: name
            },
          })
    .then(function (response){
      console.log(response.data);
      var tempArray = [];
      for (let i =0;i<response.data.length;i++){
        
        console.log(response.data[i].name);
        tempArray.push("["+response.data[i].name + "] "+ response.data[i].email);
        
      }
      
      setOptions(tempArray);
      var item = {email : "["+response.data[0].name + "] "+ response.data[0].email, id : 0};
      setSelected([item]);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

      };
      const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
        axios.post('http://gw.thegmmedical.com:5003/api/getcontactsbyinput',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              input: e.target.value
            },
          })
            .then(function (response){
            console.log(response.data);
            var tempArray = [];
            for (let i =0;i<response.data.length;i++){
        
                console.log(response.data[i].name);
                tempArray.push("["+response.data[i].name + "] "+ response.data[i].email);
        
            }
            setOptions(tempArray);
            })
            .catch(function (error){
            console.log(error);
            })
            .then(function (){
            //always executed
            });
        }
      }
      const searchword = (e) => {
        const value = e.target.getAttribute("value");
        console.log(value);
        axios.post('http://gw.thegmmedical.com:5003/api/getcontactsbyword',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              input: value
            },
          })
            .then(function (response){
            console.log(response.data);
            var tempArray = [];
            for (let i =0;i<response.data.length;i++){
        
                console.log(response.data[i].name);
                tempArray.push("["+response.data[i].name + "] "+ response.data[i].email);
        
            }
            setOptions(tempArray);
            setSelected();
            })
            .catch(function (error){
            console.log(error);
            })
            .then(function (){
            //always executed
            });
        
      }
      function getSelectValues(select) {
        var result = [];
        var options = select && select.options;
        var opt;
      
        for (var i=0, iLen=options.length; i<iLen; i++) {
          opt = options[i];
          
            var item = {email : opt.value || opt.text, id : opt.index};
          if (opt.selected) {
            console.log("selected");
            result.push(item); console.log("index..", result);
          }
        }
       
        return result;
      }

      
function getUnique(arr, comp) {

  // store the comparison  values in array
const unique =  arr.map(e => e[comp])

// store the indexes of the unique objects
.map((e, i, final) => final.indexOf(e) === i && i)

// eliminate the false indexes & return unique objects
.filter((e) => arr[e]).map(e => arr[e]);

return unique;
}


      function handleChange(e){
        console.log("e.target.........", e.target)
        setSelected(getSelectValues(e.target));
      };
      function handleTo(e){
        setToDel(getSelectValues(e.target));
        console.log("todel", toDel);
      };
      function handleCC(e){
        setCCDel(getSelectValues(e.target));
      };
      function handleBCC(e){
        setBCCDel(getSelectValues(e.target));
      };
      const addTo = () => {
        let temp = [];
        const toListEmail =  toList.map(e => e.email);
        console.log("toListEmail", toListEmail, selected);
        selected.forEach((element) => {
          console.log("hihi", element.email)
          if (!toListEmail.includes(element.email)) {
            temp.push(element);
          }
      });
        setTo([...toList, ...temp]);
        console.log(toList);
      };
      const addCC = () => {
        setCC([...ccList, ...selected]);

        
      };
      const addBCC = () => {
        setBCC([...bccList, ...selected]);
        
      };
      const delTo = () => {
        let temp = toList;
        for (let i=0;i<toDel.length;i++){
          temp = temp.filter(item => item.email !== toDel[i].email);
            
        }
        setTo([...temp]);

        
        
      };
      const delCC = () => {
        let temp = ccList;
        for (let i=0;i<ccDel.length;i++){
          temp = temp.filter(item => item.email !== ccDel[i].email);
            
        }
        setCC([...temp]);
      };

      const delBCC = () => {
        
        let temp = bccList;
        for (let i=0;i<bccDel.length;i++){
          temp = temp.filter(item => item.email !== bccDel[i].email);
            
        }
        setBCC([...temp]);
        
      };
      function register(){

            window.opener.postMessage({ toList: toList, ccList : ccList, bccList : bccList }, '*');
        
          
        
        window.close();
      }


      const TabMenu = styled.ul`
  font-weight: bold;
  box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%),
  inset 0 1px 0 0 rgb(100 121 143 / 12%);
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin:0;
 
 
  }
  .submenu {
  // 기본 Tabmenu 에 대한 CSS를 구현
    display: flex;
    /* justify-content: space-between;
    width: 380px;
    heigth: 30px; */
    padding: 10px;
    font-size: 12px;
    cursor:pointer;
    box-sizing:border-box;
    transition: 0.5s;

  

  }
  .submenu:hover {
  }
  .submenu:first-child {
    margin:0 20px;
    @media only screen and (max-width: 1300px) {
      margin:0;
    
    }
  }
  .focused {
   //선택된 Tabmenu 에만 적용되는 CSS를 구현
    color: rgb(21,20,20);
    border-bottom:2px solid #000;
    @media only screen and (max-width: 1300px) {
      border:none;
      background:#ddd;
    
    }
  }

  & div.desc {
    text-align: center;
  }
`;

const Desc = styled.div`
`;


const selectMenuHandler = (index) => {
  // parameter로 현재 선택한 인덱스 값을 전달해야 하며, 이벤트 객체(event)는 쓰지 않는다
  // 해당 함수가 실행되면 현재 선택된 Tab Menu 가 갱신.
  clickTab(index);
};

function selectAddrGroup(no){
  axios.get("http://gw.thegmmedical.com:5003/api/getaddr/"+no).then((response) => {
    var tempArray = [];
      for (let i =0;i<response.data.length;i++){
        
        if (response.data[i].email !==null && response.data[i].email !==""){
        console.log(response.data[i].name);
        tempArray.push("["+response.data[i].name + "] "+ response.data[i].email);
        }
        
      }
      setOptions(tempArray);
  });
}

var menuArr = [
  { name: '조직도', content: 
  <div className="directory-list">
  <FolderTree
    data={ folders }
    onChange={ onTreeStateChange }
    onNameClick={ onNameClick }
    showCheckbox={ false } 
    readOnly
  />
</div>},
  { name: '주소록', content: 
    <div className="directory-list">
      <ul>
      {addrs.map((element,index)=>(
        <li value={element.no} onClick={()=>selectAddrGroup(element.no)}>{element.g_name}</li>
      ))}
      {department.map((element,index)=>(
        <li value={element.no} onClick={()=>selectAddrGroup(element.no)}>{element.g_name}</li>
      ))}
      {myGroups.map((element,index)=>(
        <li value={element.no} onClick={()=>selectAddrGroup(element.no)}>{element.g_name}</li>
      ))}
      </ul>
    </div> 
  },
];
  



    return (
        <div>
        <div style={{overflowY:"hidden", display:"flex", padding:"20px"}}>
            
            
    


          <div className="directory-wrap">


            <TabMenu>
      
      {menuArr.map((el,index) => (
          <Link to ={el.href}><li className={index === currentTab ? "submenu focused" : "submenu" }
          onClick={() => selectMenuHandler(index)}>{el.name}</li></Link>
        ))}
    </TabMenu>
    <Desc>
      <p>{menuArr[currentTab].content}</p>
    </Desc>
                <div className="directory-select">
                    <div className="directory-search">
                    <div className="search-word">
                        <span onClick={searchword} value="가">ㄱ</span>
                        <span onClick={searchword} value="나">ㄴ</span>
                        <span onClick={searchword} value="다">ㄷ</span>
                        <span onClick={searchword} value="라">ㄹ</span>
                        <span onClick={searchword} value="마">ㅁ</span>
                        <span onClick={searchword} value="바">ㅂ</span>
                        <span onClick={searchword} value="사">ㅅ</span>
                        <span onClick={searchword} value="아">ㅇ</span>
                        <span onClick={searchword} value="자">ㅈ</span>
                        <span onClick={searchword} value="차">ㅊ</span>
                        <span onClick={searchword} value="카">ㅋ</span>
                        <span onClick={searchword} value="타">ㅌ</span>
                        <span onClick={searchword} value="파">ㅍ</span>
                        <span onClick={searchword} value="하">ㅎ</span>
                    </div>
                    <input type="text" onKeyDown={handleOnKeyPress}></input>
                    </div>
                <select style={{width:"95%"}}
                multiple class="m_select" onChange={handleChange}>
                    { options.map((element, index) => <option key={index}>{element}</option>) }
                </select>
                </div>
                
               
            </div>
            <div className="directory-btn">

            <div className="directory-btns">
              <FaArrowAltCircleRight onClick={addTo}/>
              <FaArrowAltCircleLeft onClick={delTo}/>
                    {/*<img src="http://images5.zioyou.com/skin_basic/color_blue/arrow01.gif" style={{cursor:"pointer"}} alt="" onClick={addTo}/>
                    <img src="http://images5.zioyou.com/skin_basic/color_blue/arrow02.gif" style={{cursor:"pointer"}} alt="" onClick={delTo}/>*/}
                </div>
                <div className="directory-btns">
                <FaArrowAltCircleRight onClick={addCC}/>
                <FaArrowAltCircleLeft onClick={delCC}/>
                </div>

                <div className="directory-btns">
                <FaArrowAltCircleRight onClick={addBCC}/>
                <FaArrowAltCircleLeft onClick={delBCC}/>
               </div>
            </div>

            <div className="directory-wrap" style={{marginTop:"35px"}}>
               
            <div className="directory-select">
                <div className="sub-title">받는 사람</div>
                <select name="cmbSelected" 
                multiple class="m_select" onChange={handleTo}>
                    { toList.map((element, index) => <option key={index}>{element.email}</option>) }
                </select>
                </div>
               
                <div className="directory-select">
                <div className="sub-title">참조</div>
                <select name="cmbSelected" 
                multiple class="m_select" onChange={handleCC}>
                    { ccList.map((element, index) => <option key={index}>{element.email}</option>) }
                </select>
                </div>
           
               
               <div className="directory-select">
                 
             
               </div>
              
               <div className="directory-select">
               <div className="sub-title">숨은 참조</div>
               <select name="cmbSelected" 
               multiple class="m_select" onChange={handleBCC}>
                   { bccList.map((element, index) => <option key={index}>{element.email}</option>) }
               </select>
               </div>
           
            
        </div>

        </div>
        <div style={{width:"100%", textAlign:"center"}}>
          <button style={{padding:"5px 20px", border:"none", backgroundColor:"#eee"}} onClick={register}>등록</button>
        </div>
        

        </div>
    )
}
export default Contacts;
