import React, { useState, useEffect, useRef } from "react";


import "./css/checkline.css";
import axios from "axios";


// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import initial from "../pages/data/cell";

import * as XLSX from "xlsx";
import ExcelJS from 'exceljs'
import LuckyExcel from "luckyexcel";

let luckysheet;

const BoardEditorSetting = ({ editor_type, bgNo, bgName, order_no, closePopup }) => {

  const [initialText, setInitialText] = useState([]);

  const [showPopup, setPopup] = useState(false);
 
  const [values, setValues] = useState(false);

  const [editor, setEditor] = useState("text");
  const [__html, setHtml] = useState("");
  const [aa, setAA]= useState();
  const [jsonData, setJsonData] = useState("");
  const [list, setList] = useState([]);
  const [cols, setCols] = useState("");
  const [rows, setRows] = useState("");


  const [imgLink, setImgLink] = useState("");
  const [fileArray, setArray] = useState([]);
  const [initialHtml, setInitialHtml] = useState("");
  const [div, setDiv]=useState(
  );

  const [excelData, setExcelData] = useState();
  var shData=[];
  function nextChar(c) {
    return String.fromCharCode(c.charCodeAt(0) + 1);
  }
  const editorRef = useRef();
    const togglePopup = () => {
    setPopup(!showPopup);
  };
  const luckyCss = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    height: '100%',
    left: '0px',
}
  useEffect(() => {
   
    
    


    axios.get('https://gw.thegmmedical.com:5003/api/get_board_editor/' + bgNo)
      .then(function (res){
        const loadLuckysheet = async () => {
          try {
            // Load Luckysheet dynamically
            const luckysheetModule = await import('luckysheet');
            console.log("luckysheetModule", luckysheetModule);
            luckysheet = luckysheetModule.default;
      
            if (res.data.length>0){
              console.log("heyheyhey", res.data[0], "editor_type", editor_type);
              
              let content = res.data[0].content;
              let type = res.data[0].editor_type;
              if (editor_type==="excel"){
            
              
                if (type==="excel"){
    
                  
                  let content = JSON.parse(res.data[0].content);
                     luckysheet.create({
                      container: "luckysheet",
                      title: 'Luckysheet sphinx Demo', // set the name of the table
                      data: content,
                      showsheetbar : false,
                      showinfobar: false
                  })
                 
               
                
              }else{
                if (type==="text"){
                editorRef.current?.getInstance().setHTML(content);
              
                setInitialText(content);
                }
              }
              
              
            }else{
             
            }
            }else{
    
              if (editor_type==="excel"){
                let sample = [{
                  "name": "Sheet1",
                  "config": {},
                  "index": "1",
                  "status": "1",
                  "order": "0",
                  "luckysheet_select_save": [{
                      "row": [0, 0],
                      "column": [0, 0],
                      "row_focus": 0,
                      "column_focus": 0,
                      "left": 0,
                      "width": 72,
                      "top": 0,
                      "height": 23,
                      "left_move": 0,
                      "width_move": 72,
                      "top_move": 0,
                      "height_move": 23
                  }],
                  "zoomRatio": 1,
                  "showGridLines": "1",
                  "defaultColWidth": 72,
                  "defaultRowHeight": 23,
                  "celldata": [],
                  "calcChain": [],
                  "jfgird_select_save": [],
                  "data": [
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]
                  ],
                  "visibledatarow": [24, 48, 72, 96, 120, 144, 168, 192, 216, 240, 264, 288, 312, 336, 360, 384, 408, 432, 456, 480, 504, 528, 552, 576, 600, 624, 648, 672, 696, 720, 744, 768, 792, 816, 840, 864, 888, 912, 936, 960, 984, 1008, 1032, 1056, 1080, 1104, 1128, 1152, 1176, 1200, 1224, 1248, 1272, 1296, 1320, 1344, 1368, 1392, 1416, 1440, 1464, 1488, 1512, 1536, 1560, 1584, 1608, 1632, 1656, 1680, 1704, 1728, 1752, 1776, 1800, 1824, 1848, 1872, 1896, 1920, 1944, 1968, 1992, 2016],
                  "visibledatacolumn": [73, 146, 219, 292, 365, 438, 511, 584, 657, 730, 803, 876, 949, 1022, 1095, 1168, 1241, 1314, 1387, 1460, 1533, 1606, 1679, 1752, 1825, 1898, 1971, 2044, 2117, 2190, 2263, 2336, 2409, 2482, 2555, 2628, 2701, 2774, 2847, 2920, 2993, 3066, 3139, 3212, 3285, 3358, 3431, 3504, 3577, 3650, 3723, 3796, 3869, 3942, 4015, 4088, 4161, 4234, 4307, 4380],
                  "ch_width": 4500,
                  "rh_height": 2096,
                  "luckysheet_selection_range": []
              }];
                luckysheet.create({
                  container: "luckysheet",
                  title: 'Luckysheet sphinx Demo', // set the name of the table
                  data: sample,
                  showsheetbar : false,
                  showinfobar: false
              })
    
              }
            }
            
      
            // Set the loaded state
          } catch (error) {
            console.error('Error loading Luckysheet:', error);
          }
        };
        loadLuckysheet();

      
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });


 
    
    
   
    
  }, []);
 

  
  const itscell = {
    name: "Sheet1",
    config: {
      columnlen: {
        "0": 131,
        "2": 131,
        "3": 131,
        "4": 131,
        "5": 131,
        "6": 131,
        "7": 131,
        "8": 131,
      },
    },
    id: "0",
    zoomRatio: 1,
    order: "0",
    column: 8,
    row: 38,
    status: 1,
    ch_width: 1361,
    rh_height: 936,
    celldata:[],
    luckysheet_select_save: [
      {
        left: 741,
        width: 138,
        top: 796,
        height: 19,
        left_move: 741,
        width_move: 138,
        top_move: 796,
        height_move: 19,
        row: [33, 33],
        column: [6, 6],
        row_focus: 33,
        column_focus: 6
      }
    ],
    
    scrollLeft: 0,
    scrollTop: 0
  };
  
  async function handleFileAsync(e) {
    setDiv("");
    
    const wb = new ExcelJS.Workbook()
          
          const file = e.target.files[0];
    const exceldata = await file.arrayBuffer();
          wb.xlsx.load(exceldata)
          .then(wb => {
             //console.log(workbook, 'workbook instance');
  
              wb.eachSheet((sheet, id) => {
                 sheet.eachRow((row, rowIndex) => {
                 //console.log(row);
                 });
              });
              const ws = wb.worksheets[0] // 첫 번째 sheet 선택
      
    const options = { includeEmpty: true }
   
     ws.eachRow(options, (row, rowNum) => {
      shData[rowNum-1] = []
      row.eachCell(options, (cell, cellNum) => {
        shData[rowNum-1][cellNum-1] = { value:cell.value, style:cell.style }
      
      
      })
    })
    //console.log("sheeeeeeeeet", sheetData);
    //setShData(sheetData);
    /* data is an ArrayBuffer */
    const workbook = XLSX.readFile(exceldata, {cellStyles:true, cellFormula :true, cellHTML: true});
  
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
      setHtml(htmlData);
      //console.log(htmlData);
  
    const jsondata  = XLSX.utils.sheet_to_json(worksheet, {
      header:1,
      defval:"",
    })
    setJsonData(jsondata);
    
    

    var sheetnames = workbook.SheetNames;

    console.log("workbook sheets", workbook.Sheets);
   
    
  
    
    
    
    
    
    
    
    setCols(jsondata[0]);
    setRows(jsondata[1]);
    var cellData=[];
  
  
  
  
  
  var merges= workbook.Sheets[sheetnames[0]]["!merges"];

  console.log("jasondata...", workbook.Sheets[sheetnames[0]]);

  const merge_config={};

  if (merges!==undefined){
    for (let i=0;i<merges.length;i++){
      var title = merges[i]['s']['r']+"_"+merges[i]['s']['c'];
      var rowsize = merges[i]['e']['r']-merges[i]['s']['r'];
      var col = merges[i]['e']['c']-merges[i]['s']['c'];
      var merge={
          r: merges[i]['s']['r'],
          c: merges[i]['s']['c'],
          rs: rowsize+1,
          cs: col+1
      }
      merge_config[title]=merge;
    
    }

  }


  
  
  var rows= workbook.Sheets[sheetnames[0]]["!rows"];
  const rowht={};

  if (rows!==undefined){

  for (let i=0;i<rows.length;i++){
    if (rows[i]!==undefined){
      var row=rows[i]["hpx"];
    rowht[i]=row;
    }
  
    /**/
  
  }
  }
  var cols= workbook.Sheets[sheetnames[0]]["!cols"];
  const colht={};
  if (cols!==undefined){
  for (let i=0;i<jsondata[0].length;i++){
    if (cols[i]!==undefined){
      var coll=cols[i]["wpx"];
      colht[i]=coll;
    }
  
    /**/
  
  }
  }
  itscell.config.columnlen = colht;
  
  itscell.config.rowlen = rowht;
  itscell.config.merge = merge_config;
  
  
  var calccchain=[];
  var borderinfo=[];
    for (let i = 0;i<jsondata.length;i++){
      var alphabet = "A";    
      for (let j=0;j<jsondata[i].length;j++){
        var styleData; var ht=1; var vt=1; var fs = 11;
        var fa = "General";
        if (shData[i][j]!==undefined){
          
  
          styleData = shData[i][j].style;
          
          console.log("styleData", styleData);
          
          if (styleData.numFmt !==undefined){
            fa = styleData.numFmt;
          }
          if (styleData.alignment!==undefined){
            switch (styleData.alignment.horizontal) {
              case "center":
                ht = 0;
                break;
              case "left":
                ht = 1;
                break;
              case "right":
                ht = 2;
                break;
        
              default:
                ht = 1;
      
            }
            switch (styleData.alignment.vertical) {
              case "middle":
                vt = 0;
                break;
              case "up":
                vt = 1;
                break;
              case "down":
                vt = 2;
                break;
        
              default:
                vt = 1;
      
            }
          }
        
          if (styleData.style!==undefined){
            fs = styleData.font.size;
          }
          
          var border = styleData.border;
          
  
          var bd = {
            rangeType: "cell",
            value: {
              row_index:i,
              col_index:j,
              
            }
          };

          if (border!==undefined){
            if (border.left!==undefined){
              var left = {
                //style: styleData.border.left.style,
                //color: "#000"
                style: 1,
                color: "rgb(0, 0, 0)"
              }
              bd.value.l = left;
            }
            if (border.right!==undefined){
              var right = {
                style: 1,
                color: "rgb(0, 0, 0)"
              }
              bd.value.r = right;
            }
            if (border.top!==undefined){
              var top = {
                style: 1,
                color: "rgb(0, 0, 0)"
              }
              bd.value.t = top;
            }
            if (border.bottom!==undefined){
              var bottom = {
                style: 1,
                color: "rgb(0, 0, 0)"
              }
              bd.value.b = bottom;
            }
          }
         
          if (border!==undefined && Object.keys(border).length !== 0){
            borderinfo.push(bd);
          }
          
          
          
          
        }
       
       
        var bgColor = "#ffffff";
        
        var func = null;
        var mcval = null;
        var num = i+1;
        var tmp = alphabet + num.toString();
        if (workbook.Sheets[sheetnames[0]][tmp]!==undefined){
          var cellst = workbook.Sheets[sheetnames[0]][tmp]["s"]["patternType"];
        
          if (cellst !== "none"){
            var fgcolor = workbook.Sheets[sheetnames[0]][tmp]["s"]["fgColor"];
            
            bgColor = "#"+fgcolor.rgb
          }
          if (workbook.Sheets[sheetnames[0]][tmp]["f"]!==undefined){
            func=workbook.Sheets[sheetnames[0]][tmp]["f"];
            func = "="+func;
            var fff = {
              r: i,
              c: j,
              id: "0",
              func:[true, i, func],
              color:"w",
              parent:null,
              children:{},
              times:0
             
            };
            calccchain.push(fff);
          }
        }
        var tt = (i+"_"+j);
        if (merge_config[tt]!==undefined){
          mcval = merge_config[tt];
        }
        
        
        
        //console.log("row: ", i, " col: ", j , jsondata[i][j]);
        var rowss = {
          r: i,
          c: j,
          v: {
            ct: { fa: fa, t: "n" },
            v : jsondata[i][j],
            bg: bgColor,
            m: jsondata[i][j].toString(),
            bl:0,
            it:0,
            ff:0,
            fs:fs,
            fc:"rgb(51,51,51)",
            ht:ht,
            vt:vt,
          }
        };
        if (func !==null){
          rowss.v.f = "="+workbook.Sheets[sheetnames[0]][tmp]["f"];
        }
        
        if (mcval!==null){
          rowss.v.mc = mcval;
        }
        cellData.push(rowss);
        alphabet = nextChar(alphabet);
  
      }
      
    }
    itscell.config.borderInfo =borderinfo; 
    itscell.calcChain =calccchain; 
    itscell.celldata =cellData; 
    setAA(itscell);
    console.log("result -> ", itscell);
    setDiv(<div style={{width:"100%", height:"500px"}}><Workbook onOp={console.log} data={[itscell]} onChange={(data)=>{console.log("changed, ", data); setExcelData(data)}} /></div>) ;
    //forceUpdate();
  
  
  });
    
    
    
   
  }

  const handleInputChange = (event) => {
    //setReason(event.target.value);
    
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
    setValues[name] = value;

  };

  window.addEventListener('message', (event) => {
    if (event.data["toList"] !== undefined){
      console.log('event.data["toList"]', event.data["toList"]);
      setList(event.data["toList"]);
    }
  });

  function saveEditor(){


    let content;
    if (editor_type==="excel"){
     




    let sheet = luckysheet.getAllSheets();
 
    content = JSON.stringify(sheet);







    }else{
      content = editorRef.current?.getInstance().getHTML();
    }
    axios.post('https://gw.thegmmedical.com:5003/api/save_board_editor',{
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_no : bgNo,
        editor_type : editor_type,
        content : content
      }, 
    })
    .then(function (response){
  
     
      alert("저장되었습니다.")
      //window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }


 
  let files=[];
  return (
    <div className="popup">
      <div className="popup_inner" style={{width:"1300px"}}>

        
        <h3 style={{textAlign:"left", marginLeft:"20px"}}>문서 초기 내용</h3>
        <div className="">

        <div style={{width:"100%", display:"flex", justifyContent:"start"}}>
    
        </div>

        <div className="" style={{ width: "100%", margin: "20px auto", height:"500px", border:"1px solid #ddd"}}>
    {editor_type === "text" && (
        <Editor
        ref={editorRef} // DOM 선택용 useRef
        initialValue={initialHtml}
        previewStyle="vertical" // 미리보기 스타일 지정
        height="500px" // 에디터 창 높이
        initialEditType="wysiwyg" //
        toolbarItems={[
          // 툴바 옵션 설정
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock']
        ]}
        plugins={[colorSyntax]} 
        useCommandShortcut={false} // 키보드 입력 컨트롤 방지
        hooks={{
          addImageBlobHook: async (blob, callback) => {
            console.log(blob.size);
            if (blob.size > 5000000) {
              alert(
                "업로드에 실패했습니다. 이미지당 5MB까지 업로드할 수 있습니다."
              );
              return;
            }
            const formData = new FormData();
                    formData.append("img", blob);
                    
                    // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
                    // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...
                    await axios.post(
                      "https://gw.thegmmedical.com:5003/api/img",
                      formData
                    ).then(( res ) => {
                      console.log(res);
                      console.log(
                        "성공 시, 백엔드가 보내주는 데이터",
                        res.data.url
                      );
  
                      const IMG_URL = res.data.url;
                      setImgLink(IMG_URL);
                      // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
                      callback(IMG_URL, "test");
                      //alert(IMG_URL);
                      files.push(IMG_URL);
                      setArray(files);
                      //setArray((fileArray) => [...fileArray, IMG_URL]);
                      //alert("fileArray: ", files);
                      console.log(files);
                      var arrayLength = files.length;
                      //alert(arrayLength);
                      //alert("end");
                    
                    });
            //callback('https://gw.thegmmedical.com:5003/img/카레유.png', '카레유');
          }
        }}
      ></Editor>

    )}
    {editor_type === "excel" && (
<div style={{width:"100%", height:"100%"}}>
<input type={"file"} onChange={(event) => {
  const files = event.target.files
  LuckyExcel.transformExcelToLucky(files[0], function(exportJson, luckysheetfile){
    let sh = exportJson.sheets[0].config.borderInfo;


    for (let i=1;i<sh.length;i++){
      let r = sh[i].value.row_index;
      let c = sh[i].value.col_index;


      let prevR = sh[i-1].value.row_index;
      let prevC = sh[i-1].value.col_index;


      if (r === prevR){
        if (sh[i].value.t!==undefined && sh[i-1].value.t === undefined){
          delete sh[i].value.t;
        }else if (sh[i].value.t!==undefined && sh[i-1].value.t === undefined){
          delete sh[i-1].value.t;


        }
      }
    }


    exportJson.sheets[0].config.borderInfo = sh;





      if(exportJson.sheets==null || exportJson.sheets.length===0){
          alert("Failed to read the content of the excel file, currently does not support xls files!");
          return;
      }
      luckysheet.destroy();

      luckysheet.create({
          container: 'luckysheet', //luckysheet is the container id
          showinfobar:false,
          data:exportJson.sheets,
          title:exportJson.info.name,
          showsheetbar : false,
          userInfo:exportJson.info.name.creator
      });
  });
}}/>
<div
  id="luckysheet"
  style={luckyCss}
/>

</div>
    )}
    </div>
      

        </div>
        <div className="buttons">
        <button onClick={()=>saveEditor()}>저장</button>
        <button onClick={closePopup} style={{border:"1px solid #ddd", background:"#fff"}}>취소</button>
        </div>
        
      </div>
      
    </div>
  );
};

export default BoardEditorSetting;