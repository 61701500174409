import React, { useEffect, useState, useReducer, useRef  } from "react";
import { NavLink, Link,useParams } from "react-router-dom";
import moment from "moment";
import './css/list.css';
import './css/common.css';
import MailNav from "../components/side/MailNav";
import axios from "axios";
import Search from "./Search";
import Dropdown from "./Dropdown";
import { FaStar, FaRegStar, FaRegEnvelopeOpen, FaEnvelope } from "react-icons/fa";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BsPin } from "react-icons/bs";

import { GiHamburgerMenu } from "react-icons/gi";

import Pagination from "./Pagination";

axios.defaults.withCredentials = true; 

const MailList = () => {
  const [data, setData]=useState([]);
  const [boxes, setBoxes]=useState([]);
  const [is_reversed, setReversed] = useState(false);
  var { name } = useParams();
  const [CheckList, setCheckList] = useState([]);
  const [unseen, setUnseen]=useState();
  const [isTrash, setIsTrash] = useState(false);
  const [dest, setDest] = useState("");
  const [isActive, setActive] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [clicked, setClicked] = useState("");
  const [isPage, setIsPage] = useState(false);
  const [setting, setSetting] = useState([]);
  const [limit, setLimit] = useState(10);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [page, setPage] = useState(1);
  const [folderName, setFolderName] = useState("");
  const [search, isSearch]= useState(false);
  const offset = (page - 1) * limit;
  const [addrs, setAddrs] = useState([]);
  const [draftList, setDraftList] = useState([]);
  const [sidebar, setSidebar] = useState(false);


  const itemsRef = useRef([]);
  const options =[
    { value : "INBOX", label : "받은메일함"},
    { value : "Hello", label : "Hello"},
    { value : "TEST", label : "TEST"},
    { value : "Junk E-Mail", label : "스팸보관홤"},
    { value : "Deleted Items", label : "휴지통"}
  ];
  //console.log(name);

  async function gettoname(to) {

    const msg = {
      email : to

    }
    try {
      const payload = await axios({
        method: "post",
        url: "https://gw.thegmmedical.com:5003/getaddrname",
        data: msg
      });

      if (payload.data.length===0){
        payload.data.push({name : to});
      }
      return payload.data;
    } catch (err) {
      console.log(`error found of type ${err}`);
    }
   

  }

  async function showList(){
    if (name === "delete"){
      setIsTrash(true);
    }
  await fetch("https://gw.thegmmedical.com:5003/maillist/"+name, {
    method: "get", // 통신방법
    headers: {
      "content-type": "application/json",
    },
    credentials: "include",
  })
  .then((res) => res.json())
  .then((json) => {
    console.log(json);
    //setBoxes(json[0].boxes);
    setUnseen(json[0].unseen);
    json.shift();
    console.log("dmd", json[0].data);
    if (json[0].data.length === 0 || json[0].data === []){
      setIsEmpty(true);
    }
    console.log("받은 메일.ㅇ", json[0].data);
    let emails = json[0].data;

    let emailslist = [];
    for (let i=0;i<emails.length;i++){
      let email= emails[i];

      console.log("email....", email);
      var folder = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

      var href = "/"+folder+"/view/"+ email.uid;
      email.href = href;
      console.log("hihi uid ... ",email.uid);
      var formattedDate="";
      var d = new Date();
      var today_year = d.getFullYear();
      var year = moment(email[2]).format('YYYY');
      console.log("window.innerWidth..", window.innerWidth)
      if (today_year === year){
        if (window.innerWidth>1300){
         formattedDate = moment(email.received_date).format('MM-DD HH:mm:ss');


        }else{
          formattedDate = moment(email.received_date).format('MM-DD');


        }
      }else{


        if (window.innerWidth>1300){
          formattedDate = moment(email.received_date).format('YYYY-MM-DD HH:mm:ss');

         }else{
           formattedDate = moment(email.received_date).format('MM-DD');

         }
      }
      email.formattedDate = formattedDate;

     var from = email.from_addr;

     var tos = email.to_addr.split('" "');


     var to;
   
     if (email.from_name !==null && email.from_name !==""){
      from = email.from_name;
     }


     if (tos[1]===undefined){
      to = tos[0];
      to= to.substring(2, to.length - 2);
     }else{
      to = tos[1];
      to= to.substring(2, to.length - 2);
     }

   
     let box = "";
     if (search===true){
      box = "["+renderSettingText(email.mailbox)+"] ";
     }
     if (email.to_name !==null && email.to_name !=="" && email.to_name !==undefined){
      if (JSON.parse(email.to_name)[0].name !==""){
        to = JSON.parse(email.to_name)[0].name;
      }
     
     }

     console.log("check here A");
    
     if ((JSON.parse(emails[i].to_addr)[0].name) !==undefined){

      to = (JSON.parse(emails[i].to_addr)[0].name);
     }else if ((JSON.parse(emails[i].to_addr)[0].address) !==undefined){

      to = (JSON.parse(emails[i].to_addr)[0].address);
     }
     let seen, star;

     if (emails[i].first_to!=="" && emails[i].first_to!==null){
      to = emails[i].first_to;
     }


     if (email.seen==1){
      seen = <FaRegEnvelopeOpen className='fa-envelope'></FaRegEnvelopeOpen>;
     }else{
      seen = <FaEnvelope className='fa-envelope'></FaEnvelope>;
     }
     if (email.is_star===1){
      star = <FaStar className='fa-star star' id={email.message_id} onClick={unstarmail}></FaStar>;
     }else{
      star = <FaStar className='fa-star nostar' id={email.message_id} onClick={starmail}></FaStar>;
     }
     from = from.replace('["','');
     from = from.replace('"]','');
     email.from = from;
     email.to = to;

     emailslist.push(email);


   
    }
    console.log("emaillist...", emailslist);
    var sorted = emailslist.sort((a, b) => { return a.received_date > b.received_date; });

    setData(sorted);
    if (emailslist.length <= 10) {
      setIsPage(false);
    } else {
      setIsPage(true);
    }
   //console.log("emailslist", emailslist);

   
    //console.log(boxes.boxes);
    //console.log(boxes.boxes);
  })
  .catch(() => {});
}
  useEffect(() => {
    console.log("name...", name);
    setFolderName(name);

    axios.get('https://gw.thegmmedical.com:5003/api/getspam')
      .then(function (response){
        console.log("results.,.a,.fsf", response.data[0]);
        if (response.data.length>0){
          let adr = JSON.parse(response.data[0].email_addr);
          setAddrs(adr);
          console.log('adr....', adr);
        }
      })
      .catch(function (error){
        console.log("erros...", error);
      })
      .then(function (){
        //always executed
      });

    axios.get('https://gw.thegmmedical.com:5003/api/get_default_mail')
    .then(function (response){
      console.log("default setting,,,,,,", response.data[0]);
      
      if (response.data.length>0){
        name = response.data[0];
        setSetting(response.data[0]);
        setLimit(response.data[0].perpage);
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

    
  async function sentMails(){
    if (name === "Deleted Items"){
      setIsTrash(true);
    }
  await fetch("https://gw.thegmmedical.com:5003/sent_mail", {
    method: "get", // 통신방법
    headers: {
      "content-type": "application/json",
    },
    credentials: "include",
  })
  .then((res) => res.json())
  .then((json) => {
    console.log(json);
   
    if (json.length === 0 || json === []){
      setIsEmpty(true);
    }
    setData(json);
    if (json.length <= 10) {
      setIsPage(false);
    } else {
      setIsPage(true);
    }
    //console.log(boxes.boxes);
    //console.log(boxes.boxes);
  })
  .catch(() => {});
  }
  if (name === "draft"){
    fetch("https://gw.thegmmedical.com:5003/api/getdrafts_mail", {
          method: "get", // 통신방법
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        })
        .then((res) => res.json())
        .then((json) => {
            console.log(json);
            
            setDraftList(json);
        })
        .catch(() => {});
  }else{
    showList();
  }
    
  
  axios.get('https://gw.thegmmedical.com:5003/api/getboxes')
  .then(function (response){
    //console.log(response);
    setBoxes(response.data);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });
    /*fetch("http://gw.thegmmedical.com:5003/getbox", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      setBoxes(json);
    })
    .catch(() => {});*/

    

  
  }, []);
  
  /*function emails(){
    for (let i = 0; i < data.length; i++) {
        var href = "/view/"+ data[i][3];
      return <a href={href}>
      <div className="emails">
        <div className="subjects">
          {data[i][0]}
        </div>

        <div className="dates">
          {data[i][1]}
        </div>
      </div>
      </a>
    }
  }*/

  function create(){
    axios.post('https://gw.thegmmedical.com:5003/createbox')
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

  function rename(){
    const msg = {
      name : "name"

    }
    console.log(msg);
  /*fetch("http://gw.thegmmedical.com:5003/renamebox", {
    method: "POST", // 통신방법
    headers: {
      "content-type": "application/json",
    },
    body:msg
  })
    .then((res) => res.json())
    .then((json) => {
      //setData(json);
      console.log(json);
    })
    .catch(() => {});*/

    axios.post('https://gw.thegmmedical.com:5003/renamebox',msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

  function delete_box(){
    const msg = {
      name : "name"

    }
    console.log(msg);


    axios.post('https://gw.thegmmedical.com:5003/deletebox',msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  function status(){
    const msg = {
      name : "name"

    }
    console.log(msg);


    axios.post('https://gw.thegmmedical.com:5003/statusbox',msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  function selectAll()  {

    if (name!=="draft"){
      const all 
      = document.getElementsByName('all');
 if (all[0].checked ===true){
   let temp =data.map(function(e) { return e.uid; });
   setCheckList(temp);
 }else{
   setCheckList([]);
 }
    }else{
      const all 
      = document.getElementsByName('all');
 if (all[0].checked ===true){
   let temp =draftList.map(function(e) { return e.draft_no; });
   setCheckList(temp);
 }else{
   setCheckList([]);
 }
    }
   
    
    /*const checkboxes 
         = document.getElementsByName('mail');
    
    //console.log(all[0].checked)
    checkboxes.forEach((checkbox) => {
      checkbox.checked = all[0].checked;
    })*/
    

  }

  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
       setCheckList([...CheckList, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
       setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }
 }
  const starmail = (e) => {
    console.log(e.currentTarget.id);
    //e.currentTarget.style.color = '#6e6e6e';
    let msgid= e.currentTarget.id;
    axios.post('https://gw.thegmmedical.com:5003/api/starmail/'+e.currentTarget.id+'/'+name)
    .then(function (response){
      console.log(response);
      let idx = data.map(function(e) { return e.message_id; }).indexOf(msgid);   
      let temp = data;
      idx = parseInt(idx);
      temp[idx].is_star = 1;
      console.log("temp...",idx, temp[idx]);

      setData([...temp]);
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  }

  const unstarmail = (e) => {
    console.log(e.currentTarget.id);
    //e.currentTarget.style.color = 'white';
    let msgid= e.currentTarget.id;

    axios.post('https://gw.thegmmedical.com:5003/api/unstarmail/'+e.currentTarget.id+'/'+name)
    .then(function (response){
      console.log(response);
      console.log(response);
      let idx = data.map(function(e) { return e.message_id; }).indexOf(msgid);   
      let temp = data;
      temp[idx].is_star = 0;
      console.log("temp...",idx, temp);

      setData([...temp]);      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  
/*
  function mail_list(){
    let array = [];
    let page_data = data.slice(offset, offset + limit);


      for (let i = 0; i < page_data.length; i++) {


        var folder = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        var href = "/"+folder+"/view/"+ page_data[i].uid;
        console.log("hihi uid ... ",page_data[i].uid);
        var formattedDate="";
        var d = new Date();
        var today_year = d.getFullYear();
        var year = moment(page_data[i][2]).format('YYYY');
        console.log("window.innerWidth..", window.innerWidth)
        if (today_year === year){
          if (window.innerWidth>1300){
           formattedDate = moment(page_data[i].received_date).format('MM-DD HH:mm:ss');


          }else{
            formattedDate = moment(page_data[i].received_date).format('MM-DD');


          }
        }else{


          if (window.innerWidth>1300){
            formattedDate = moment(page_data[i].received_date).format('YYYY-MM-DD HH:mm:ss');
 
           }else{
             formattedDate = moment(page_data[i].received_date).format('MM-DD');
 
           }
        }
       
       var froms = page_data[i].from_addr.split('" "');
       var tos = page_data[i].to_addr.split('" "');


       var from, to;
       if (froms[1]===undefined){
        from = froms[0];
        from= from.substring(2, from.length - 2);
       }else{
        from = froms[1];
        from= from.substring(2, from.length - 2);
       }


       if (page_data[i].from_name !==null && page_data[i].from_name !==""){
        from = page_data[i].from_name;
       }


       if (tos[1]===undefined){
        to = tos[0];
        to= to.substring(2, to.length - 2);
       }else{
        to = tos[1];
        to= to.substring(2, to.length - 2);
       }


       let box = "";
       if (search===true){
        if (renderSettingText(page_data[i].mailbox).includes("INBOX.")){
          box = "["+renderSettingText(page_data[i].mailbox).slice(6)+"] ";
        }else{
          box = "["+renderSettingText(page_data[i].mailbox)+"] ";
        }
        
       }
       if (page_data[i].to_name !==null && page_data[i].to_name !=="" && page_data[i].to_name !==undefined){
        if (JSON.parse(page_data[i].to_name)[0].name !==""){
          to = JSON.parse(page_data[i].to_name)[0].name;
        }
       
       }


       let seen, star;
       if (page_data[i].tags.includes("Seen")){
        seen = <FaRegEnvelopeOpen className='fa-envelope'></FaRegEnvelopeOpen>;
       }else{
        seen = <FaEnvelope className='fa-envelope'></FaEnvelope>;
       }
       if (page_data[i].is_star===1){
        star = <FaStar className='fa-star star' id={page_data[i].message_id} onClick={unstarmail}></FaStar>;
       }else{
        star = <FaStar className='fa-star nostar' id={page_data[i].message_id} onClick={starmail}></FaStar>;
       }
       
        array.push(<div className="email_item">
        <input type='checkbox'
        name='mail'
        value={page_data[i].uid}
        onChange={(e) => onChangeEach(e, page_data[i].uid)} checked={CheckList.includes(page_data[i].uid)}/>
        {star}
        <Link to={href} state={{ date: formattedDate }}>
          <div className="emails">


          <div className="froms">
          {name !=="sent" ? from : to }
          </div>


          <div className="subjects" style={{display:"flex"}}>
          <div key={page_data[i].uid}
          ref={el => itemsRef.current[page_data[i].uid] = el} >{seen}</div>
            {page_data[i].tags.includes("Seen") ?
              <span>{box}{page_data[i].subject}</span>
            : <span style={{fontWeight:"bold"}}>{box}{page_data[i].subject}</span>
            }




          </div>
         
          <div className="dates">
            {formattedDate}
          </div>
          </div>
        </Link></div>);
 
       
      }
      return array;
  }

*/
/*
function mail_list(){
  let array = [];
  console.log("mailbox name.........", name);
  let page_data = data.slice(offset, offset + limit);


    for (let i = 0; i < page_data.length; i++) {
      var tos = page_data[i].to_addr.split('" "');


      var to;
    
    
 
      if (tos[1]===undefined){
       to = tos[0];
       to= to.substring(2, to.length - 2);
      }else{
       to = tos[1];
       to= to.substring(2, to.length - 2);
      }
 
    
    
      if (page_data[i].to_name !==null && page_data[i].to_name !=="" && page_data[i].to_name !==undefined){
       if (JSON.parse(page_data[i].to_name)[0].name !==""){
         to = JSON.parse(page_data[i].to_name)[0].name;
       }
      
      }
 
      if ((JSON.parse(page_data[i].to_addr)[0].address) !==undefined){
       to = (JSON.parse(page_data[i].to_addr)[0].address);
      }

    


   
      let box = "";
      if (search===true){
       box = "["+renderSettingText(page_data[i].mailbox)+"] ";
      }
      var folder = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      var href = "/"+folder+"/view/"+ page_data[i].uid;
      console.log("hihi uid ... ",page_data[i].uid);
      var formattedDate="";
      var d = new Date();
      var today_year = d.getFullYear();
      var year = moment(page_data[i][2]).format('YYYY');
      console.log("window.innerWidth..", window.innerWidth)
      if (today_year === year){
        if (window.innerWidth>1300){
         formattedDate = moment(page_data[i].received_date).format('MM-DD HH:mm:ss');


        }else{
          formattedDate = moment(page_data[i].received_date).format('MM-DD');


        }
      }else{


        if (window.innerWidth>1300){
          formattedDate = moment(page_data[i].received_date).format('YYYY-MM-DD HH:mm:ss');

         }else{
           formattedDate = moment(page_data[i].received_date).format('MM-DD');

         }
      }
     
     var from = page_data[i].from_addr;
     if (page_data[i].from_name !==null && page_data[i].from_name !==""){
      from = page_data[i].from_name;
     }


     let seen, star;

     if (page_data[i].seen==1){
      seen = <FaRegEnvelopeOpen className='fa-envelope'></FaRegEnvelopeOpen>;
     }else{
      seen = <FaEnvelope className='fa-envelope'></FaEnvelope>;
     }
     if (page_data[i].is_star===1){
      star = <FaStar className='fa-star star' id={page_data[i].message_id} onClick={unstarmail}></FaStar>;
     }else{
      star = <FaStar className='fa-star nostar' id={page_data[i].message_id} onClick={starmail}></FaStar>;
     }
     from = from.replace('["','');
     from = from.replace('"]','');

      array.push(<div className="email_item">
      <input type='checkbox'
      name='mail'
      value={page_data[i].uid}
      onChange={(e) => onChangeEach(e, page_data[i].uid)} checked={CheckList.includes(page_data[i].uid)}/>
      {star}
      <Link to={href} state={{ date: formattedDate }}>
        <div className="emails">

        <div className="froms">
        {name !=="sent" ? from : to
        
        }




        </div>


        <div className="subjects" style={{display:"flex"}}>
        <div key={page_data[i].uid}
        ref={el => itemsRef.current[page_data[i].uid] = el} >{seen}</div>
          {page_data[i].seen ==1 ?
            <span>{box}{page_data[i].subject}</span>
          : <span style={{fontWeight:"bold"}}>{box}{page_data[i].subject}</span>
          }




        </div>
       
        <div className="dates">
          {formattedDate}
        </div>
        </div>
      </Link></div>);
   

   
    

    

     
    }
    return array;
}
*/












  function mobile_mail_list(){
    let array = [];
    let page_data = data.slice(offset, offset + limit);

      for (let i = 0; i < page_data.length; i++) {

        var folder = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        var href = "/"+folder+"/view/"+ page_data[i].uid;
        console.log("hihi uid ... ",page_data[i].uid);
        var formattedDate="";
        var d = new Date();
        var today_year = d.getFullYear();
        var year = moment(page_data[i][2]).format('YYYY');

        if (today_year === year){
          formattedDate = moment(page_data[i].received_date).format('MM-DD HH:mm:ss');
        }else{
          formattedDate = moment(page_data[i].received_date).format('YYYY-MM-DD HH:mm:ss');
        }
        
       var froms = page_data[i].from_addr.split('" "');
       var from;
       if (froms[1]===undefined){
        from = froms[0];
        from= from.substring(2, from.length - 2);
       }else{
        from = froms[1];
        from= from.substring(2, from.length - 2);
       }

       if (page_data[i].from_name !==null && page_data[i].from_name !=="" && page_data[i].from_name !==undefined){
        from = page_data[i].from_name;
       }
       console.log(from);
       let seen, star;
       if (page_data[i].seen == 1){
        seen = <FaRegEnvelopeOpen className='fa-envelope'></FaRegEnvelopeOpen>;
       }else{
        seen = <FaEnvelope className='fa-envelope'></FaEnvelope>;
       }
       if (page_data[i].is_star===1){
        star = <FaStar className='fa-star star' id={page_data[i].message_id} onClick={unstarmail}></FaStar>;
       }else{
        star = <FaStar className='fa-star nostar' id={page_data[i].message_id} onClick={starmail}></FaStar>;
       }
       
        array.push(<div className="email_item">
        <input type='checkbox' 
        name='mail' 
        value={page_data[i].uid}
        onChange={(e) => onChangeEach(e, page_data[i].uid)} checked={CheckList.includes(page_data[i].uid)}/> 
        {star}
        <Link to={href} state={{ date: formattedDate }}>
          <div className="emails">

          <div className="froms">
            {from}
          </div>

          <div className="subjects" style={{display:"flex"}}>
          <div key={page_data[i].uid} 
          ref={el => itemsRef.current[page_data[i].uid] = el} >{seen}</div>
            <span>{page_data[i].subject}</span>
          </div>
          
          <div className="dates">
            {formattedDate} 
          </div>
          </div>
        </Link></div>);
  
        
      }
      return array;
  }


  function draft_list(){
    let array = [];
    let page_data = draftList.slice(offset, offset + limit);
    for (let i = 0; i < page_data.length; i++) {

      var folder = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      var href = "/write";
      var formattedDate="";
      var d = new Date();
      var today_year = d.getFullYear();
      var year = moment(page_data[i][2]).format('YYYY');
      const reg = /[\{\}\[\]\/?,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

      if (today_year === year){
        formattedDate = moment(page_data[i].sent_date).format('MM-DD HH:mm:ss');
      }else{
        formattedDate = moment(page_data[i].sent_date).format('YYYY-MM-DD HH:mm:ss');
      }
      var froms;
    if (page_data[i].to_addr !==undefined){
      if (page_data[i].to_addr.includes(",")){
        froms = page_data[i].to_addr.split(',');
      }else{
        froms = page_data[i].to_addr.split('" "');
      }
      
    }else{
      froms=["",""];
    }
      console.log("froms....", froms);
     var from;
      from = froms[0];
      from = from.replace(reg,'');
      if (froms.length>1){
        from += " +" + (froms.length-1);
      }

     if (page_data[i].from_name !==null && page_data[i].from_name !=="" && page_data[i].from_name !==undefined){
      from = page_data[i].from_name;
     }
     console.log(from);
     let seen, star;
   
  
      array.push(<div className="email_item">
      <input type='checkbox' 
      name='mail' 
      value={page_data[i].draft_no}
      onChange={(e) => onChangeEach(e, page_data[i].draft_no)} checked={CheckList.includes(page_data[i].draft_no)}/> 
      
      <Link to={href} state={{ draft : page_data[i] }}>
        <div className="emails">

        <div className="froms">
          {from}
        </div>

        <div className="subjects" style={{display:"flex"}}>
        <div key={page_data[i].draft_no} 
        ref={el => itemsRef.current[page_data[i].draft_no] = el} ></div>
          <span>{page_data[i].subject}</span>
        </div>
        
        <div className="dates">
          {formattedDate} 
        </div>
        </div>
      </Link></div>);

      
    }
      return array;
  }


  function sort_newest(){
    if (is_reversed === true){
      data.reverse();
      console.log(data);
      setData(data);
      setReversed(false);
    }
    
  }

  function sort_oldest(){
    data.reverse();
    console.log(data);
    setData(data);
    setReversed(true);
    
  }

  function deletemails(){
    console.log("checklist........", CheckList);
    let temp = data;

    var result = window.confirm("선택된 메일을 삭제하시겠습니까?");
    if (result){
      var array = []
      var array2 = [];

      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
          array.push(checkboxes[i].value)
  
          itemsRef.current[checkboxes[i].value].innerHTML='<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="fa-envelope" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M494.586 164.516c-4.697-3.883-111.723-89.95-135.251-108.657C337.231 38.191 299.437 0 256 0c-43.205 0-80.636 37.717-103.335 55.859-24.463 19.45-131.07 105.195-135.15 108.549A48.004 48.004 0 0 0 0 201.485V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.509a48 48 0 0 0-17.414-36.993zM464 458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V204.347c0-1.813.816-3.526 2.226-4.665 15.87-12.814 108.793-87.554 132.364-106.293C200.755 78.88 232.398 48 256 48c23.693 0 55.857 31.369 73.41 45.389 23.573 18.741 116.503 93.493 132.366 106.316a5.99 5.99 0 0 1 2.224 4.663V458zm-31.991-187.704c4.249 5.159 3.465 12.795-1.745 16.981-28.975 23.283-59.274 47.597-70.929 56.863C336.636 362.283 299.205 400 256 400c-43.452 0-81.287-38.237-103.335-55.86-11.279-8.967-41.744-33.413-70.927-56.865-5.21-4.187-5.993-11.822-1.745-16.981l15.258-18.528c4.178-5.073 11.657-5.843 16.779-1.726 28.618 23.001 58.566 47.035 70.56 56.571C200.143 320.631 232.307 352 256 352c23.602 0 55.246-30.88 73.41-45.389 11.994-9.535 41.944-33.57 70.563-56.568 5.122-4.116 12.601-3.346 16.778 1.727l15.258 18.526z"></path></svg>';
          let index = temp.findIndex(item => item.uid == checkboxes[i].value);
           console.log("idx,,,,", index);
          if (temp[index].seen!==1){
            array2.push(checkboxes[i].value);
          }
         }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
    
      const msg ={
        nos : array,
        unseen : array2,
        folder : name
      }
      axios.post('https://gw.thegmmedical.com:5003/api/deletemails', msg)
      .then(function (response){
        console.log(response);
        window.location.reload(true);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
    
 
  }

  function deletemailsDraft(){

    console.log("checklist........", CheckList);
    var result = window.confirm("선택된 메일을 삭제하시겠습니까?");
    if (result){
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const msg ={
        nos : array,
        folder : name
      }
      axios.post('https://gw.thegmmedical.com:5003/api/deletemails_draft', msg)
      .then(function (response){
        console.log(response);
        window.location.reload(true);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }



  }

  function readmails(){
    console.log(CheckList);
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
    let temp = data;
    for (var i = 0; i < checkboxes.length; i++) {

      if (checkboxes[i].value!=="selectall"){
        
       itemsRef.current[checkboxes[i].value].innerHTML='<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="fa-envelope" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M494.586 164.516c-4.697-3.883-111.723-89.95-135.251-108.657C337.231 38.191 299.437 0 256 0c-43.205 0-80.636 37.717-103.335 55.859-24.463 19.45-131.07 105.195-135.15 108.549A48.004 48.004 0 0 0 0 201.485V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.509a48 48 0 0 0-17.414-36.993zM464 458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V204.347c0-1.813.816-3.526 2.226-4.665 15.87-12.814 108.793-87.554 132.364-106.293C200.755 78.88 232.398 48 256 48c23.693 0 55.857 31.369 73.41 45.389 23.573 18.741 116.503 93.493 132.366 106.316a5.99 5.99 0 0 1 2.224 4.663V458zm-31.991-187.704c4.249 5.159 3.465 12.795-1.745 16.981-28.975 23.283-59.274 47.597-70.929 56.863C336.636 362.283 299.205 400 256 400c-43.452 0-81.287-38.237-103.335-55.86-11.279-8.967-41.744-33.413-70.927-56.865-5.21-4.187-5.993-11.822-1.745-16.981l15.258-18.528c4.178-5.073 11.657-5.843 16.779-1.726 28.618 23.001 58.566 47.035 70.56 56.571C200.143 320.631 232.307 352 256 352c23.602 0 55.246-30.88 73.41-45.389 11.994-9.535 41.944-33.57 70.563-56.568 5.122-4.116 12.601-3.346 16.778 1.727l15.258 18.526z"></path></svg>';
       let index = temp.findIndex(item => item.uid == checkboxes[i].value);
        console.log("idx,,,,", index);
       temp[index].tags += "[Seen]";
       if (temp[index].seen!==1){
        array.push(checkboxes[i].value);
       }
       temp[index].seen = 1;
       setData(temp);
      }
      
    }
    
    console.log("page...", page);
    
    const msg ={
      nos : array,
      folder:name
    }
    axios.post('https://gw.thegmmedical.com:5003/api/readmails', msg)
    .then(function (response){
      console.log(response);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

  }

  function expungemails(){
    console.log(CheckList);
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {

      if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value);
      }
      
    }
    
    console.log(array);
    const msg ={
      nos : array,
      folder:name
    }
    axios.post('https://gw.thegmmedical.com:5003/api/expungemails', msg)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
 
  }
  const handleSelect = (e) => {
    setDest(e.target.value);
  };
  function movemails(){

    //var ele = document.getElementsByName('dest');
    //var dest;
    /*for(let i = 0; i < ele.length; i++) {
      if(ele[i].checked){
        dest = ele[i].value;
        
      }
      
    }*/
    let temp = data;

    console.log("..checklist..", CheckList);
    var array = [];
    var array2 = [];

    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
    
      if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)

        itemsRef.current[checkboxes[i].value].innerHTML='<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="fa-envelope" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M494.586 164.516c-4.697-3.883-111.723-89.95-135.251-108.657C337.231 38.191 299.437 0 256 0c-43.205 0-80.636 37.717-103.335 55.859-24.463 19.45-131.07 105.195-135.15 108.549A48.004 48.004 0 0 0 0 201.485V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.509a48 48 0 0 0-17.414-36.993zM464 458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V204.347c0-1.813.816-3.526 2.226-4.665 15.87-12.814 108.793-87.554 132.364-106.293C200.755 78.88 232.398 48 256 48c23.693 0 55.857 31.369 73.41 45.389 23.573 18.741 116.503 93.493 132.366 106.316a5.99 5.99 0 0 1 2.224 4.663V458zm-31.991-187.704c4.249 5.159 3.465 12.795-1.745 16.981-28.975 23.283-59.274 47.597-70.929 56.863C336.636 362.283 299.205 400 256 400c-43.452 0-81.287-38.237-103.335-55.86-11.279-8.967-41.744-33.413-70.927-56.865-5.21-4.187-5.993-11.822-1.745-16.981l15.258-18.528c4.178-5.073 11.657-5.843 16.779-1.726 28.618 23.001 58.566 47.035 70.56 56.571C200.143 320.631 232.307 352 256 352c23.602 0 55.246-30.88 73.41-45.389 11.994-9.535 41.944-33.57 70.563-56.568 5.122-4.116 12.601-3.346 16.778 1.727l15.258 18.526z"></path></svg>';
        let index = temp.findIndex(item => item.uid == checkboxes[i].value);
         console.log("idx,,,,", index);
        if (temp[index].seen!==1){
          array2.push(checkboxes[i].value);
        }
       }
    }
    console.log(array);
    if (array[0].value==="selectall"){
      array.shift();
    }

    
    console.log("..dest..", clicked);

    const msg ={
      nos : array,
      unseen: array2,
      folder : name,
      dest : clicked
    }
    axios.post('https://gw.thegmmedical.com:5003/api/movemails', msg)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
 
  }

  if (data === undefined){
    return;
  }



  const renderSettingText = (boxname) => {
    switch (boxname) {
        case "all":
            return setting.start_box ;
        case "INBOX":
            return "받은 메일함" ;
        case "sent":
            return "보낸 메일함";
        case "Junk":
            return "스팸 메일함";
        case "draft":
            return "임시 보관";
        case "delete":
            return "휴지통";
        case "Stars":
            return "중요 메일함";
        default:
            return boxname;
    }
};

const addBookmarkPage = () => {
  var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?"+renderSettingText(name));
  if(result){

    const data ={
      name: '',
      num : '',
      link : window.location.pathname,
      bookmark : renderSettingText(name)
    }
    
    console.log(window.location.pathname)
    console.log(data);
    axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
    .then(function (response){
      console.log(response);
      alert("메뉴 바로가기에 추가되었습니다.");

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
};
function addSpam(){

  let temp = CheckList;
  var valueArr = addrs.map(function(item){ return item.text });
  console.log(valueArr);
  let indexes=[];
  for (let i=0;i<temp.length;i++){
    indexes.push(data.map(function(e) { return e.uid; }).indexOf(CheckList[i]));   
  }
  
  for (let y=0;y<indexes.length;y++){
    let email = data[indexes[y]].from_addr;
    var isDuplicate = valueArr.some(function(item, idx){ 
      return valueArr.indexOf(email) != idx 
    });
    if (isDuplicate===true || addrs.length===0){
      let highestId;
      if (addrs.length===0){
        highestId=0;
      }else{
        highestId = Math.max.apply(Math, addrs.map(function(o) { return o.id; }))
      }
      
      let newId = 1; // default in case the array is empty
  
      if (highestId > 0) {
        // generate a new ID based off of the highest existing element ID 
        newId = (highestId + 1);
      }
  
      const item = { id: newId, text: email };
  
  
      const tempArray = [...addrs, item];
        
  
     
      const formdata = {
        keywords: "",
        domains : "",
        addrs : JSON.stringify(tempArray),
       
      }
      console.log("formdata......", formdata);
  
      axios.post('https://gw.thegmmedical.com:5003/api/block_addr', formdata)
      .then(function (response){
        alert("수신 차단되었습니다.");
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }else{
      alert("이미 수신 차단한 주소입니다.")
    }
  }
  
  
  
};
  return (

  <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <MailNav boxes={boxes}/> 
        </div>
      )}

  <div className="content-wrap">
  <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%", height:"100%"}}>
  <div className="side-menu">
    <MailNav boxes={boxes}/>  
  </div>
  
  {/*<button type="button" class="button_task" onClick={()=>create()}>메일함생성</button>
   <button type="button" class="button_task" onClick={()=>rename()}>메일함이름변경</button>
   <button type="button" class="button_task" onClick={()=>delete_box()}>메일함삭제</button>
  <button type="button" class="button_task" onClick={()=>status()}>메일함정보</button>*/}
  

<div className="mail_content">
<div class="contents_header" >
<div className="btns">

<div class="mail_header">


{search===true && (
<h3>
  검색결과 <span style={{fontSize:"12px", color:"gray"}}>{data.length}개</span>
</h3>
)}


{search===false &&(


<div className="btn" style={{display:"flex", alignItem:"center", height:"15px",padding:"5px", marginRight:"7px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>
)}


{search===false &&(
    <h3>{(setting !==undefined && folderName === "all") ? (<span>{renderSettingText(setting.start_box)}</span>
                ): (renderSettingText(folderName))} &nbsp; </h3>
)}


{search===false &&(


<h3>
           
    {(name !== "sent" && name !== "draft" && search===false) && (
      <div><span style={{color:"#346beb"}}>{data.length>0 ? (data.filter((item) => item.seen !== 1).length) : <span>0</span>}</span>  /
      <span style={{color:"gray"}}> {data.length}</span></div>
               
    )}
  </h3>
  )}
  </div>





</div>
  
   <Search onChange={(value) => {
    
    let emails = value.data;

    let emailslist = [];
    for (let i=0;i<emails.length;i++){
      let email= emails[i];
      var folder = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

      var href = "/"+folder+"/view/"+ email.uid;
      email.href = href;
      console.log("hihi uid ... ",email.uid);
      var formattedDate="";
      var d = new Date();
      var today_year = d.getFullYear();
      var year = moment(email[2]).format('YYYY');
      console.log("window.innerWidth..", window.innerWidth)
      if (today_year === year){
        if (window.innerWidth>1300){
         formattedDate = moment(email.received_date).format('MM-DD HH:mm:ss');


        }else{
          formattedDate = moment(email.received_date).format('MM-DD');


        }
      }else{


        if (window.innerWidth>1300){
          formattedDate = moment(email.received_date).format('YYYY-MM-DD HH:mm:ss');

         }else{
           formattedDate = moment(email.received_date).format('MM-DD');

         }
      }
      email.formattedDate = formattedDate;

     var from = email.from_addr;

     var tos = email.to_addr.split('" "');


     var to;
   
     if (email.from_name !==null && email.from_name !==""){
      from = email.from_name;
     }


     if (tos[1]===undefined){
      to = tos[0];
      to= to.substring(2, to.length - 2);
     }else{
      to = tos[1];
      to= to.substring(2, to.length - 2);
     }

   
     let box = "";
     if (search===true){
      box = "["+renderSettingText(email.mailbox)+"] ";
     }
     if (email.to_name !==null && email.to_name !=="" && email.to_name !==undefined){
      if (JSON.parse(email.to_name)[0].name !==""){
        to = JSON.parse(email.to_name)[0].name;
      }
     
     }

     if ((JSON.parse(emails[i].to_addr)[0].name) !==undefined){

      to = (JSON.parse(emails[i].to_addr)[0].name);
     }else if ((JSON.parse(emails[i].to_addr)[0].address) !==undefined){

      to = (JSON.parse(emails[i].to_addr)[0].address);
     }
     let seen, star;

     if (emails[i].first_to!=="" && emails[i].first_to!==null){
      to = emails[i].first_to;
     }


     if (email.seen==1){
      seen = <FaRegEnvelopeOpen className='fa-envelope'></FaRegEnvelopeOpen>;
     }else{
      seen = <FaEnvelope className='fa-envelope'></FaEnvelope>;
     }
     if (email.is_star===1){
      star = <FaStar className='fa-star star' id={email.message_id} onClick={unstarmail}></FaStar>;
     }else{
      star = <FaStar className='fa-star nostar' id={email.message_id} onClick={starmail}></FaStar>;
     }
     from = from.replace('["','');
     from = from.replace('"]','');
     email.from = from;
     email.to = to;
     /*gettoname(to).then(response=>{
      console.log(response);
      if (response[0].name!==null && response[0].name !==""){
        email.to = response[0].name;

      }else{
        email.to = to;

      }
  });*/


  emailslist.push(email);


    }

    setPage(1);
    setData(emailslist);
    
    isSearch(true)

   }}/>
</div>
<div class="mail_toolbar">
<div class="mail_toolbar_count">
<span class="current">
<strong class="count">0</strong>
<span class="text">개 선택</span>
</span>
<span class="total">
<span class="blind">전체</span>30
<span class="blind">개</span>
</span>
</div>
<div class="mail_toolbar_task_main">
<div class="button_group">
<div class="button_optional_select">
<div class="button_checkbox_wrap">
<label for="selection_all">
</label>
</div>

</div>
<div class="button_task_wrap button_select">
<input type='checkbox'
       name='all' 
       value='selectall'
       onClick={selectAll}/>
<br />
</div>


</div>
{name!=="draft" && (
  <div type="button" class="button_task_wrap svg_read" disabled="" onClick={()=>readmails()}>
  <button>
    <span class="text">읽음</span>
  </button>
  
</div>
)}

{ (isTrash && name!="draft") && 
<div class="button_task_wrap">
  <button type="button" class="button_task svg_delete" disabled="" onClick={()=>expungemails()}>
  <span class="text">영구삭제</span>
  </button>
</div>
}
{ (!isTrash&& name!="draft") && 
<div class="button_task_wrap">
  <button type="button" class="button_task svg_delete" disabled="" onClick={()=>deletemails()}>
  <span class="text">삭제</span>
  </button>
</div>
}

{ name=="draft" && 
<div class="button_task_wrap">
  <button type="button" class="button_task svg_delete" disabled="" onClick={()=>deletemailsDraft()}>
  <span class="text">삭제</span>
  </button>
</div>
}



{name!=="draft" && (

<div class="button_task_wrap">
  <button type="button" class="button_task svg_spam" disabled="" onClick={()=>addSpam()}>
  <span class="text">스팸차단</span></button>
</div>

)}
{name!=="draft" && (
<span>
{(data.length>0 && CheckList.length<2) ?
    <Link to="/reply" state={{ uid: CheckList[0], data : data[data.map(function(e) { return e.uid; }).indexOf(CheckList[0])] }} 
    className="button_task_wrap"
    ><button type="button" class="button_task" disabled=""><span class="text">답장</span></button></Link>     
:   <button type="button" class="button_task" disabled=""><span class="text">답장</span></button> }




{(data.length>0 && CheckList.length<2 && name!=="draft") ?
  <Link to="/replyall" state={{ uid: CheckList[0], data :  data[data.map(function(e) { return e.uid; }).indexOf(CheckList[0])] }} 
  className="button_task_wrap"><button type="button" class="button_task" disabled=""><span class="text">전체답장</span></button></Link>
  :   <button type="button" class="button_task" disabled=""><span class="text">전체답장</span></button> }




{(data.length>0 && CheckList.length<2 && name!=="draft") ?
  <Link to="/forward" state={{ uid: CheckList[0], data :  data[data.map(function(e) { return e.uid; }).indexOf(CheckList[0])] }} 
  className="button_task_wrap"><button type="button" class="button_task" disabled=""><span class="text">전달</span></button></Link>
  :   <button type="button" class="button_task" disabled=""><span class="text">전달</span></button> }

</span>

)}
{name!=="draft" && (

  <div class="button_task_wrap">
    <button type="button" class="button_task" onClick={()=>setActive(!isActive)} disabled=""><span class="text">이동</span></button>
    {isActive && (
      <div className="dropdown_div" style={{padding:"20px"}}>

<div className="list">

    
<div className={clicked === "all" ? "box_item clicked" : "box_item"}  onClick={()=>setClicked("all")} ><li><span>받은편지함</span></li></div>
 <ul>

 {boxes.map((box, idx) => {

  if ((box.path).includes("INBOX.")){
   return <li className={clicked === box.path ? "box_item clicked" : "box_item"} onClick={()=>setClicked(box.path)}>
  
     <span>- {box.name}</span>  
      
   </li>
  }})}

  </ul>
  
  <div className={clicked === "sent" ? "box_item clicked" : "box_item"} onClick={()=>setClicked("sent")}><li><span>보낸편지함</span></li></div>
 {/*} <div className={clicked === "draft" ? "box_item clicked" : "box_item"}  onClick={()=>setClicked("draft")}><li><span>임시보관함</span></li></div>*/}
  <div className={clicked === "Junk" ? "box_item clicked" : "box_item"}  onClick={()=>setClicked("Junk")}><li><span>스팸보관함</span></li></div>


<div className={clicked === "delete" ? "box_item clicked" : "box_item"} onClick={()=>setClicked("delete")}><li className="separated"><span>휴지통</span></li></div>
</div>
        
          <button onClick={()=>movemails()}>이동</button>
        </div>
      )}     
</div>
)}

{name!=="draft" && (

<div class="button_task_wrap button_etc">
          <button type="button" className="button_task_dropdown sort_oldest" onClick={()=>sort_oldest()}>
            <span class="text">오래된 순 정렬</span>
          </button>
        
        </div>
)}
{name!=="draft" && (

        <div class="button_task_wrap">
          
          <button type="button" className="button_task_dropdown sort_newest" onClick={()=>sort_newest()}>
            <span class="text">최신 순 정렬</span>
          </button>
        </div>
)}
      {/*<select name="dstfolderA" className="move_to" placeholder="메일함 이동" onChange={handleSelect} value={dest}>
        <option value="" disabled selected>메일함 이동</option>
        <option value="INBOX">받은편지함</option>
        <option value="Hello">Hello</option>
        <option value="TEST">TEST</option>
        <option value="Deleted Items">휴지통</option>
        <option value="Junk E-Mail">스팸편지함</option>
</select>*/}


   
      </div>
       
      </div>


<div className="email_list">

    {name ==="draft" ? draft_list() : 
    data.slice(offset, offset + limit).map((item,index)=>(

      ((name =="Stars" && item.is_star === 1) || name !=="Stars") && (
        <div className="email_item" key={item.uid}>
        <input type='checkbox'
        name='mail'
        value={item.uid}
        onChange={(e) => onChangeEach(e, item.uid)} checked={CheckList.includes(item.uid)}/>
        
      
  {name !=="sent" && (
  (item.is_star ==1 || name ==="Stars") ? <FaStar className='fa-star star' id={item.message_id} onClick={unstarmail}></FaStar>: 
  <FaStar className='fa-star nostar' id={item.message_id} onClick={starmail}></FaStar>
  
  )}
  
  
        <Link to={item.href} state={{ date: item.formattedDate }}>
          <div className="emails">
  
          <div className="froms">
          {name !=="sent" ? item.from : item.to
          
          }
  
  
  
  
          </div>
  
  
          <div className="subjects" style={{display:"flex"}}>
          <div key={item.uid}
          ref={el => itemsRef.current[item.uid] = el} >
  
  {(item.seen ==1 || name === "sent") ?  <FaRegEnvelopeOpen className='fa-envelope'></FaRegEnvelopeOpen> : <FaEnvelope className='fa-envelope'></FaEnvelope>}
          </div>
            {(item.seen ==1 || name === "sent") ?
              <span>
                 {search===true && (
                  "["+renderSettingText(item.mailbox)+"] "
                 )
       }
                
                {item.subject}</span>
            : <span style={{fontWeight:"bold"}}>
                  {search===true && (
                  "["+renderSettingText(item.mailbox)+"] "
                 )
       }
              {item.subject}</span>
            }
  
  
  
  
          </div>
         
          <div className="dates">
            {item.formattedDate}
          </div>
          </div>
        </Link></div>
    )
      
     
    ))
    
    
    }
    {isEmpty && (
    <div style={{ textAlign: "center", height:"80vh", display:"flex"}}>
      <div style={{marginTop:"200px", textAlign:"center", width:"100%"}}>
      <HiOutlineMailOpen style={{fontSize:"55px",marginBottom:"10px", color:"#76b3f5"}}/>
      <div style={{fontSize:"16px"}}>메일이 없습니다.</div>
      </div>
    </div>
    )}

    {search && data.length===0 && (
    <div style={{ textAlign: "center", height:"80vh", display:"flex"}}>
      <div style={{marginTop:"200px", textAlign:"center", width:"100%"}}>
      <HiOutlineMailOpen style={{fontSize:"55px",marginBottom:"10px", color:"#76b3f5"}}/>
      <div style={{fontSize:"16px"}}>검색 결과가 없습니다.</div>
      </div>
    </div>
    )}

</div>

{isPage && data.length > limit && (
  <div className="paging">
    <Pagination
      postsPerPage={limit}
      totalPosts={data.length}
      page={page}
      paginate={setPage}
    ></Pagination>
  </div>
)}

</div>
</div>
    </div>
    </div>
  );
};

export default MailList;