import moment from 'moment';
import React, { useState, useEffect, useRef } from "react";
import './css/docwrite.css';
import axios from "axios";
import LeaveHistory from "../components/LeaveHistory";
import { BiSearchAlt } from "react-icons/bi";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, leaves, user }) {

 
  return rows.map((rowsData, index) => {

  

    var {type,
      workdate,
      start_time,
      end_time,
      name,
      d_name,
      job_name,
      content}= rowsData;



    return (

      <div className="ot_view">
  <table style={{width:"100%"}}>
  <tr>

  <td class="table_title2" nowrap="">
    특근근무
  </td>
    <td class="">
    <div className="input-group" style={{display:"flex", flexShrink:"0", alignItems:"center"}}>
    
{type}
    </div>
    </td>


    
<td class="table_title2" nowrap="">
  근무일시
</td>
  <td class="" colSpan={3}>
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>

    
  {moment(workdate).format("yyyy년 MM월 DD일")}
{"   "}

{start_time}
~
{end_time}


  </div>
  </td>



  </tr>
</table>

<table>
<tr className="">

<td class="table_title2" nowrap="">
  근무자
</td>
  <td class="">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  {name}
  </div>
  </td>



<td class="table_title2" nowrap="">
  부서
</td>
  <td class="">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    {d_name}
  </div>
  </td>



<td class="table_title2" nowrap="">
  직위
  </td>

  <td class="">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
    

  {job_name}
  </div>
  </td>
  </tr>

</table>

<table>
<tr>
  <td class="table_title2" nowrap="">
    근무사유
  </td>
  <td class="" colSpan={5}>
  <div className="input-group">
{content}
 
  </div>
  </td>
    
  </tr>
 
  </table>

</div>
    );
  });
}
const Leave = ({docNo, onChange}) => {  
  
  var todayDate = new Date().toISOString().slice(0, 10);
  console.log(todayDate);
  const inputFileRef = useRef();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [subject, setSubject] = useState("");
  const [expire, setExpire] = useState("1");
  const [vrange, setRange] = useState("1");
  const [vtype, setType] = useState("1");
  const [reason, setReason] = useState("");
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [startHour, setStartHour] = useState("");
  const [startMin, setStartMin] = useState("");
  const [endHour, setEndHour] = useState("");
  const [endMin, setEndMin] = useState("");
  const [info, setInfo]= useState([]);
  const [used, setUsed] = useState("");
  const [remain, setRemain] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData]= useState();
  const [holidays, setHolidays] = useState([]);
  const [rows, initRow] = useState([]);
  const [values, setValues] = useState([]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
};

function getBusinessDaysDiff(startDate, endDate) {
  var diffDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
  var weekends = 0;

  for (var i = 0; i <= diffDays; i++) {
    var currentDate = new Date(startDate.getTime() + i * (1000 * 3600 * 24));

    
    
        
       

    if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
      weekends++;
      console.log("w주말");
    }else{
      for (let i=0;i<holidays.length;i++){
        var from = new Date(holidays[i].start.date); // -1 because months are from 0 to 11
        var to = new Date(holidays[i].end.date); // -1 because months are from 0 to 11
        to.setDate(to.getDate() - 1);

        if (currentDate >= from && currentDate <= to){
          weekends++;
          console.log("휴일", holidays[i]);
          break;
  
        }
        
      }
    }
  }

  var businessDaysDiff = diffDays - weekends;
  console.log("diffDays,,.,.", diffDays, "weekends", weekends);
  return businessDaysDiff;
}


const [leaves, setLeaves] = useState([]);
const [user, setUser]= useState([]);
  useEffect(()=>{

    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
      .then(function (response){
      
        setUser(response.data[0]);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });

      axios.get('https://gw.thegmmedical.com:5003/api/getot/'+docNo)
      .then(function (response){
      
        if (response.data.length>0){
        initRow(response.data);
        }
      
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });



        
      }, []);
  




  const addRowTable = () => {
    const data = {
      type:"휴일",
      workdate:"",
      startHour:"",
      startMin:"",
      endHour:"",
      endMin:"",
      content:""   };
    initRow([...rows, data]);
  };
  const delRowTable = () => {
    let temp = rows;
    temp.pop();
    initRow([...temp]);
  };


  

  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, e) => {
    const { name, value } = e.target;
    
    const data = [...rows];
    //console.log(data, name, value, i);

    
 
    if (name === "startHour"){
      data[i]["startTime"] = value + ":" + data[i].startMin;
    }else if (name === "startMin"){
      data[i]["startTime"] = data[i].startHour + ":" + value;

    }else if (name === "endHour"){
      data[i]["endTime"] = value + ":" + data[i].endMin;
    }else if (name === "endMin"){
      data[i]["endTime"] = data[i].endHour + ":" + value;

    }
    data[i][name] = value;
    
    /*let d1 = ot.workdate + " " + ot.startHour+":"+ot.startMin;
    let d2 = ot.workdate + " " + ot.endHour+":"+ot.endMin;
    let date1 = new Date(d1);
    let date2 = new Date(d2);

    var hours = Math.abs(date1 - date2) / 36e5;
    formData.append(`hours`,hours);*/


   
    initRow(data);
    values.arr = rows;
    onChange(values);

  };

  

  
  return (
    <div className="wrapper">

    
    <div className="" style={{justifyContent:"flex-start"}}>


   <div className="">
      {/*{show ? (
    <DraftsList onChange={(value) => {setSubject(value.subject);editorRef.current?.getInstance().setHTML(value.content);setDraftNo(value.draft_no)}} closePopup={() => toggleShow()}/>
      ) : null}*/}
 
  
            {showPopup ? (
  <LeaveHistory closePopup={() => togglePopup()} />
) : null}
            


 
  <TableRows
    rows={rows}
    tableRowRemove={tableRowRemove}
    onValUpdate={onValUpdate}
    leaves={leaves}
    user = {user}
  />
  
 

 
    

      </div>
   </div>
</div>
  );
};

export default Leave;