

import { NavLink, Link,useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {GiHamburgerMenu} from "react-icons/gi";
import ScheduleList from "../components/side/ScheduleList";
import AutoComplete from "../components/AutoComplete";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import {
  HexColorPicker
} from "react-colorful";

axios.defaults.withCredentials = true; 
const initialValues = {
  name: "",
  color: "",
  group:"all",
  d_no: "0",
  in_use: "use",
  desc:"",
  expire:"",
 
};

let currentData = [];
const CalendarAdd = () => {
  const [values, setValues] = useState(initialValues);
  
  const [color, setColor] = useState("#000000");
  const [colorPicker, setColorPicker] = useState(false);
  const [showDepartments, SetShowDepartments] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [value, setValue] = useState("");
  const [viewers, setViewers] = useState([]);  
  const [lineList, setLineList] = useState([]);
  const [, updateState] = React.useState();
  const [sidebar, setSidebar] = useState(false);
  //const [shData, setShData]= useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [admin, setAdmin]=useState("");
  const [admins, setAdmins]=useState([]);
  const [rec, setRec]=useState("");
  const [writers, setWriters]=useState([]);
  const [users, setUsers] = useState([]);



  useEffect(()=>{
    axios.get('https://gw.thegmmedical.com:5003/api/getusers').then(function (response){
      setUsers(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
      .then(function (){
    });

    console.log("bbbb", currentData, location.state.calendar)
    if(currentData !== location.state.calendar) {setValues(initialValues);setWriters([]); setViewers([]);}
     
    currentData = location.state.calendar;
    console.log("state.....", location.state.calendar.no);


    //setCalendar(location.state.calendar);


    axios.get('http://gw.thegmmedical.com:5003/api/get_calendar/'+location.state.calendar.no)
    .then(function (response){
    console.log("hey result is!!!!!!!!!!!!", response.data);
    let calendar = response.data[0];
    var values = {
      name: calendar.name,
      group:calendar.category,
      d_no: calendar.d_no,
      in_use: calendar.in_use,
      desc:calendar.desc,
      expire:calendar.expire,
      calendarID: calendar.calendarID
    };
    setColor(calendar.color);
    setValues(values);

  
   console.log("viewers", calendar.viewer);

   setViewers(JSON.parse(calendar.viewer));

    let writes = JSON.parse(calendar.writer);
    setWriters(writes);
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });


    
    /*let writer_nos = [];
   for (let i = 0;i<writes.length;i++){
     let writer = writes[i].substring(1, writes[i].length-1);
     writer_nos.push(writer);
   }
   var writer_data ={
    user_nos : writer_nos
   }
   console.log('writer_data', writer_data);

   axios.post("http://gw.thegmmedical.com:5003/api/get_participants", writer_data).then((response) => {
    var id_no = 1;
    var tempArray=[];
    console.log("응응", response.data);
    for (let i=0;i<response.data.length;i++){
      const item = { id: id_no, name: response.data[i].name, email : response.data[i].email, check : false  };
      tempArray = [...tempArray, item];
      console.log(item);
      
      id_no++;


    }
    console.log(tempArray);
    setWriters(tempArray);
     //setViewers(response.data);

      
    });*/
   


    axios.get('http://gw.thegmmedical.com:5003/api/getdepartment')
    .then(function (response){
    console.log("hey result is!!!!!!!!!!!!", response.data);
    setDepartments(response.data);
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
}, [location]);




  const handleRegisterButton = () => {
    console.log(values);
    if (values.name === "") {
      alert("내용을 입력해주세요.");
      return false;
    } 
   
    const formData = new FormData();
   /*const data = {
      subject : values.subject,
      form_name : values.form_name,
      form_group : values.form_group,
      in_use : values.in_use,
      cc : ccs,
      recipient : writers,
      expire : values.expire,
      security : values.security,
      form_data : test

    }*/
   

    formData.append(`subject`, values.name);
    formData.append(`color`, color);
    formData.append(`group`, values.group);

    formData.append(`d_no`, values.d_no);
    formData.append(`in_use`, values.in_use);
    formData.append(`viewer`, JSON.stringify(ccs));
    formData.append(`writer`, JSON.stringify(writers));
    formData.append(`expire`, values.expire);


   const data = {
    name : values.name,
    color: color,
    group : values.group,
    d_no : values.d_no,
    in_use : values.in_use,
    viewer : JSON.stringify(viewers),
    writer : JSON.stringify(writers),
    expire : values.expire,
    calendarID : values.calendarID,
    calendar_no : location.state.calendar.no
   }
   
    console.log(data);
    axios.post('http://gw.thegmmedical.com:5003/api/calendar_update', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
   



  };

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (name==="group" && value==="department"){
      SetShowDepartments(true);
    }else if (name==="group" && value!=="department"){
      SetShowDepartments(false);
    }
  };

  const delCalendar = () =>{
    var result = window.confirm("캘린더를 삭제하시겠습니까?");
    if(result){
      const data ={
        calendar_no : location.state.calendar.no
      }
  
      axios.post('http://gw.thegmmedical.com:5003/api/calendar_delete', data)
      .then(function (response){
        console.log(response);
        window.location.reload(true);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });

    }
    
  }

 

  const generateId_ad = () => {
    const highestId = Math.max.apply(Math, admins.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_ad() {
    if (admin !== '') {
      const tempStr = admin.substring(admin.indexOf("<")+1, admin.indexOf(">"));
      const item = { id: generateId_ad(), text: admin, email : tempStr  };
      console.log("item...", item);
      var valueArr = admins.map(function(item){ return item.text });
      if (valueArr.includes(admin)){
        return false;
      }
      else{
        const tempArray = [...admins, item];
        setAdmins(tempArray);
      }

      
    }
    setAdmin('');
  }

  function addAdmins() {
    console.log(admin);
    createNewToDoItem_ad();
  }
  const deleteItem_ad = id => {
    setAdmins(admins.filter(item => item.id !== id));
  };


  const display_admin = admins.map(item => (
    <div className="admin"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_ad(item.id)}>&times;</span>

    </div>
  ));


  
  

  const generateId_rec = () => {
    const highestId = Math.max.apply(Math, writers.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  function createNewToDoItem_rec() {
    if (rec !== '') {
      const tempStr = rec.substring(rec.indexOf("<")+1, rec.indexOf(">"));
      const item = { id: generateId_rec(), text: rec, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = writers.map(function(item){ return item.text });
      if (valueArr.includes(rec)){
        return false;
      }
      else{
        const tempArray = [...writers, item];
        setWriters(tempArray);
      }

      
    }
    setRec('');
  }

  function addRecs() {
  
  const name = rec.substring(rec.indexOf(",")+1, rec.indexOf("<"));

  const tempStr = rec.substring(rec.indexOf("<")+1, rec.indexOf(">"));
      const item = { id: generateId_rec(), name: name, email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = writers.map(function(item){ return "["+item.email+"]" });
      console.log("valueArr is...", valueArr, "["+tempStr+"]" );
     

      if (valueArr.includes("["+tempStr+"]")){
        console.log("이미 있으셈");
        return false;
      }
      else{
        const tempArray = [...writers, item];
        setWriters(tempArray);
      }

      
  


  }
  const deleteItem_writer = id => {
    setWriters(writers.filter(item => item.id !== id));
  };


  const display_writer= writers.map(item => (
    <div className="admin"><li key={item.id}>
      <span>{item.name} [{item.email}]</span>
    </li>
    <span className="del_btn" onClick={() => deleteItem_writer(item.id)}>&times;</span>

    </div>
  ));

  const [cc, setCc]=useState("");
  const [ccs, setCcs]=useState([]);
  

  const generateId_viewer = () => {
    const highestId = Math.max.apply(Math, viewers.map(function (element) {
           return element.id;
       }));
       let newId = 1; // default in case the array is empty

       if (highestId > 0) {
           // generate a new ID based off of the highest existing element ID 
           newId = (highestId + 1);
       }
       return newId;
 };

  

  function addViewers() {
   
  const name = value.substring(value.indexOf(",")+1, value.indexOf("<"));

  const tempStr = value.substring(value.indexOf("<")+1, value.indexOf(">"));
      const item = { id: generateId_viewer(), name: name,  email : tempStr, check : false  };
      console.log("item...", item);
      var valueArr = viewers.map(function(item){ return "["+item.email+"]" });
      console.log("valueArr is...", valueArr, "["+tempStr+"]" );
     

      if (valueArr.includes("["+tempStr+"]")){
        console.log("이미 있으셈");
        return false;
      }
      else{
        const tempArray = [...viewers, item];
        console.log("tempArray is...", viewers);
        setViewers(tempArray);
      }



    //createNewToDoItem_cc();
  }
  
  const deleteItem_cc= id => {
    setViewers(viewers.filter(item => item.id !== id));
  };


  const display_viewers = viewers.map(item => (
    <div className="admin"><li key={item.id}>
      <span>{item.name} [{item.email}]</span>
    </li>
    <span className="del_btn" onClick={() => deleteItem_cc(item.id)}>&times;</span>

    </div>
  ));

 



  var CLIENT_ID = "103373930583-rnu1qv7i5vr7ng7b2ma5o5r2p9vaiqu4.apps.googleusercontent.com"
    var API_KEY = "AIzaSyCDeeCLkx3dIqf-6aQ_CRJ6vopUxJXwnzE"
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
    var SCOPES = "https://www.googleapis.com/auth/calendar"
    var calendarID=values.calendarID;
var gapi = window.gapi;




const synchronize = () =>{
  gapi.load('client:auth2', () => {
    console.log('loaded client')


    gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
      plugin_name:'App Name that you used in google developer console API'


    })
    .then(function () {
      return gapi.client.request({
        path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
      });
    })
    .then(
      (response) => {
        let events = response.result.items;
        console.log("events....", events);




        let schedules=[];
        for (let i=0;i<events.length;i++){


          let days  = {'SU':'sun', 'MO':'mon', 'TU':'tue', 'WE':'wed', 'TH':'thr', 'FR':'fri', 'SA':'sat'};




          let schedule = new Object;
          if (events[i].end.date ===undefined){
            schedule.end_date = events[i].end.dateTime;




            let hour = new Date(events[i].end.dateTime).getHours();
            let min = new Date(events[i].end.dateTime).getMinutes();


            schedule.end_hour = hour;
            schedule.end_min = min;
          }else{
            schedule.end_date = events[i].end.date;
            schedule.allday = 1;
          }
          if (events[i].start.date ===undefined){
            schedule.start_date = events[i].start.dateTime;


            let hour = new Date(events[i].start.dateTime).getHours();
            let min = new Date(events[i].start.dateTime).getMinutes();


            schedule.start_hour = hour;
            schedule.start_min = min;
          }else{
            schedule.start_date = events[i].start.date;
          }
          schedule.subject = events[i].summary;
          schedule.content = events[i].description;
          schedule.iCalUID = events[i].id;


          if (events[i].attendees !==undefined){
            let attendees = events[i].attendees;
            var emails = attendees.map(function(item) {
              let val;
              
              var result = users.filter(obj => {
                
                return obj.email === item['email'];
              })


              console.log(result);
              if (result.length>0){
                val ="("+result[0].user_no+")";
              }else{
                val = "("+item['email']+")";
              }


              return val;
            });


            console.log("emails", emails);
            schedule.participants = JSON.stringify(emails);


          }else{
            schedule.participants="[]";
          }
          if (events[i].recurrence!==undefined){
            let recur = events[i].recurrence[0];
            schedule.is_repeat = 1;


            let rep_cycle = recur.split("FREQ=")[1].split(";")[0];


            let bymonth="";
            let bymonthday="";
            let byday="";
            schedule.rep_cycle = rep_cycle;
            let interval = recur.split("INTERVAL=")[1].split(";")[0];
            let count = recur.split("COUNT=")[1];
            let until = "";
            if (recur.split("UNTIL=")[1] !==undefined){
              until = recur.split("UNTIL=")[1].split(";")[0];
            }
            
            if (until !==""){
              schedule.end_date = until.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
            }
            if (count !==undefined){
              count = recur.split("COUNT=")[1].split(";")[0];
              schedule.count = count;
              schedule.rep_end = "times";
            }else{
              schedule.rep_end = "keep";
            }
            
            schedule.rep_cycleN = interval;
console.log("rep_cycle", rep_cycle);
console.log("recur", recur);
            if (recur.split("BYMONTH=")[1]!==undefined){
              bymonth = recur.split("BYMONTH=")[1].split(";")[0];


            }
            if (recur.split("BYMONTHDAY=")[1]!==undefined){
              bymonthday = recur.split("BYMONTHDAY=")[1]


            }


            if (recur.split("BYDAY=")[1]!==undefined){
              byday = recur.split("BYDAY=")[1];
              console.log("bydaybydaybyday",byday);


            }
            if (bymonthday !==""){
              schedule.rep_type = bymonth+"-"+bymonthday;
              schedule.rep_month_type = "date";


            }else if (byday !==""){


              console.log("byday.slice(-2)",byday.slice(-2));


              schedule.rep_type = byday.substring(0,1)+"-"+days[byday.slice(-2)];
              schedule.rep_month_type = "nthday";
            }else{
              schedule.rep_type="";
            }
          }
         
          schedule.calendar_no=location.state.calendar.no;


          
          schedules.push(schedule);
        }
        
        console.log(schedules);
        //setEvents(events);
        const data ={
          event_info : JSON.stringify(schedules)
        }
        axios.post('https://gw.thegmmedical.com:5003/api/insert_google',data)
        .then(function (response){
          //setBoxes(json[0].boxes);
          alert("일정이 업데이트되었습니다.");
        
          
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
        });


      },
      function (err) {
        return [false, err];
      }
    );
    gapi.client.load('calendar', 'v3', () => console.log('bam!'))


    /*gapi.auth2.getAuthInstance().signIn()
    .then(() => {
      
      var event = {
        'summary': 'Awesome Event!',
        'location': '800 Howard St., San Francisco, CA 94103',
        'description': 'Really great refreshments',
        'start': {
          'dateTime': '2024-03-20T09:00:00-07:00',
          'timeZone': 'America/Los_Angeles'
        },
        'end': {
          'dateTime': '2024-03-21T17:00:00-07:00',
          'timeZone': 'America/Los_Angeles'
        },
        'recurrence': [
          'RRULE:FREQ=DAILY;COUNT=2'
        ],
        'attendees': [
          {'email': 'lpage@example.com'},
          {'email': 'sbrin@example.com'}
        ],
        'reminders': {
          'useDefault': false,
          'overrides': [
            {'method': 'email', 'minutes': 24 * 60},
            {'method': 'popup', 'minutes': 10}
          ]
        }
      }


      var request = gapi.client.calendar.events.insert({
        'calendarId': 'primary',
        'resource': event,
      })


      request.execute(event => {
        console.log(event)
        window.open(event.htmlLink)
      })
      


   
      // get events
      gapi.client.calendar.events.list({
        'calendarId': 'primary',
        'timeMin': (new Date()).toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 10,
        'orderBy': 'startTime'
      }).then(response => {
        const events = response.result.items
        console.log('EVENTS: ', events)
      })
      
  


    })*/
  })
}

 

  return (
    <div className="wrapper">
    <div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <ScheduleList/>
        </div>
      )}
   
<div className="content-wrap">
    
      
    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
        <div className="side-menu">
    <ScheduleList/>
</div>
    <div className="main_content">
  
    

    <div style={{maxWidth:"800px"}}>

            
      <div style={{display:"flex",alignItems:"center"}}>
      <h4>새 캘린더 추가 </h4>
      <button className="btn" style={{height:"30px", marginLeft:"10px"}} onClick={()=>delCalendar()}>삭제</button>
      </div>
          <div style={{background:"#fff"}}>
          {lineList.length >0  && (
            <div style={{display:"flex"}} className="line">
                {lineList.map((element, index)=> (
                  <table>
                    <tr><td rowSpan={3} className="type">{element.type}</td><td className="job">{element.job_name}</td></tr>
                    <tr><td className="name">{element.name}</td></tr>
                    <tr></tr>
                  </table>
                ))}
            </div>
          )}
          <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
          

         
          <table className="form_write" style={{margin:"10px 0", width:"100%"}}>

      {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
      
      


<tr>
<td class="table_title2" nowrap="">
  캘린더 이름
</td>
  <td class="table_list2_left">
  <div className="input-group">

    {values.name==="내일정" ? <span>{values.name}</span>: <input type="text" name="name" className="formInput" placeholder="이름을 입력하세요." value={values.name}
          onChange={handleInputChange}></input>}
  
  </div>
  </td>

  <td class="table_title2 pc" nowrap="">
  색상
</td>
  <td class="table_list2_left pc">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <input type="text" name="subject" className="formInput" style={{backgroundColor:color, width:"150px"}} value={color} onClick={()=>setColorPicker(!colorPicker)}></input>
  </div>
  {colorPicker && ( 
    <HexColorPicker color={color} onChange={setColor} />
  )}
 

  </td>

</tr>
<tr className="mobile">
<td class="table_title2" nowrap="">
  색상
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <input type="text" name="subject" className="formInput" style={{backgroundColor:color}} value={color} onClick={()=>setColorPicker(!colorPicker)}></input>
  </div>
  {colorPicker && ( 
    <HexColorPicker color={color} onChange={setColor} />
  )}
 

  </td>
</tr>

<tr>
<td class="table_title2" nowrap="">
  캘린더 분류
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>

  {values.name==="내일정" ? <span>내 캘린더</span>: 
    <select name="group" className="formInput" value={values.group ||''}
    onChange={handleInputChange}>    
<option value="all">전사 캘린더</option>
<option value="department">부서 캘린더</option>
<option value="my">내 캘린더</option>
<option value="group">그룹 캘린더</option>

</select>
  }




  {showDepartments && (
    <select name="d_no" className="formInput" value={values.d_no ||''}
    onChange={handleInputChange}>    
{departments.map((element, index)=>(
      <option value={element.d_no}>{element.d_name}</option>
    ))}

</select>
  )}
  </div>
  </td>


  <Tooltip
        id="date_guide"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>양식의 상태를 설정합니다.</span>
          </div>
        </Tooltip>
  <td class="table_title2 pc" nowrap="">
    사용여부<BiInfoCircle data-tooltip-id="date_guide" style={{marginLeft:"5px"}}/>
  </td>
  <td class="table_list2_left pc">

  {values.name==="내일정" ? <span>사용</span>: 
    <div className="input-group radio" style={{display:"flex"}}>
      
    <input type="radio" value="use" name="in_use" checked={values.in_use === "use"} onChange={handleInputChange}/><label><span>사용</span>
  </label>
  <label>
    <input type="radio" value="no" name="in_use" checked={values.in_use === "no"} onChange={handleInputChange}/><span>사용안함</span>
  </label>
</div>
  }


 
  </td>

</tr>

<tr className="mobile">
<td class="table_title2" nowrap="">
    사용여부<BiInfoCircle data-tooltip-id="date_guide" style={{marginLeft:"5px"}}/>
  </td>
  <td class="table_list2_left">
  {values.name==="내일정" ? <span>사용</span>: 
    <div className="input-group radio" style={{display:"flex"}}>
      
    <input type="radio" value="use" name="in_use" checked={values.in_use === "use"} onChange={handleInputChange}/><label><span>사용</span>
  </label>
  <label>
    <input type="radio" value="no" name="in_use" checked={values.in_use === "no"} onChange={handleInputChange}/><span>사용안함</span>
  </label>
</div>
  }
  </td>
</tr>

<tr>
<td class="table_title2" nowrap="">
  캘린더 설명
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <input type="text" name="desc" className="formInput" placeholder="" value={values.desc}
          onChange={handleInputChange}></input>
  </div>
  </td>

 

</tr>




<tr>
<Tooltip
        id="cc"
        place="bottom"
        >
          <div style={{display:"flex", flexDirection:"column", textAlign:"left"}}>
            <span>문서참조자는 결재문서와 결재상태를 확인할 수 있습니다.</span>
          </div>
        </Tooltip>

<td class="table_title2" nowrap="">
  열람권한자<BiInfoCircle data-tooltip-id="cc" style={{marginLeft:"5px"}}/>
</td>
<td class="table_list2_left" >
        <div className="input-group">
        <div style={{display:"flex"}}>
               <AutoComplete onChange={(value) => {setValue(value); console.log("hihi", value)}}/>
                <button onClick={()=>addViewers()} style={{marginLeft:"10px"}}>추가</button>
            </div>
      </div>
      {viewers.length >0 &&(

      <div class="admin_list" style={{flexGrow:"0"}}>
            <ul>
                      {display_viewers}
                        </ul>
                    </div>
      )}
		</td>
</tr>

<tr>
<td class="table_title2" nowrap="">
  쓰기권한자
</td>
<td class="table_list2_left">
        <div className="input-group">
        <div style={{display:"flex"}}>
               <AutoComplete onChange={(value) => {setRec(value); console.log("hihi", value)}}/>
                <button onClick={addRecs} style={{marginLeft:"10px"}}>추가</button>
            </div>
      </div>
      {writers.length >0 &&(

      <div class="admin_list">
            <ul>
                      {display_writer}
                        </ul>
                    </div>
      )}
		</td>
</tr>

<tr>

<td class="table_title2" nowrap="">
  구글 캘린더 ID
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <input name="calendarID" type="text" className="formInput" value={values.calendarID}
                                  onChange={handleInputChange}>
 
  </input> 
  <button onClick={()=>synchronize()} style={{width:"100px"}}>동기화</button>

  </div>
  </td>
 

</tr>







    </table>


    </div>

   

    </div>


    

    <div className="button_wrap">
    <button onClick={handleRegisterButton}>등록</button>
    
    {/*</form>*/}
    </div>
    </div>
  </div>
  </div></div>

</div>
  );
};

export default CalendarAdd;

