import { NavLink, Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import { BsFillSquareFill } from "react-icons/bs";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import {BsPin} from "react-icons/bs";
import ScheduleList from "../components/side/ScheduleList";
import LeaveList from "../components/LeaveList";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";
import addDays from "date-fns/addDays";
import {GiHamburgerMenu} from "react-icons/gi";
import {
  format,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  parse,
  getMonth,
  getYear,
  addWeeks,
  addYears,
  subWeeks,
  subDays,
  getWeek,
  isWeekend,
  getDay,
  differenceInCalendarWeeks,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears
  
} from "date-fns";
import "./css/schedule.css";

axios.defaults.withCredentials = true;


let currentData="";
const Schedule = () => {
  const [leave, setLeaves] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [checkDate, setCheckDate] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const monthStart = startOfMonth(new Date());
  const monthEnd = endOfMonth(monthStart);

  const [startDate, setStartDate]=useState(startOfWeek(monthStart));
  const [endDate, setEndDate]=useState(endOfWeek(monthEnd));
  const [start, setStart]=useState(format(startOfWeek(monthStart), "yyyy-MM-dd"));
  const [end, setEnd]=useState(format(endOfWeek(monthEnd), "yyyy-MM-dd"));

  const [calendars, setCalendars]=useState([]);
  const [teamCalendars, setTeamCalendars]=useState([]);
  const [userCalendars, setUserCalendars]=useState([]);
  const [groupCalendars, setGroupCalendars]=useState([]);

  const [schedules, setSchedules] = useState([]);
  const location = useLocation();

  const [calendar, setCalendar] = useState("");
  const [prevCalendar, setPrevCalendar] = useState("");
  const [teamSchedule, setTeamSchedule] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOption] = useState("all");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateText, setSelectedDateText] = useState(format(new Date(), "yyyy-MM-dd"));

  const [filterData, setFilterData]=useState([]);
  const [CheckList, setCheckList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [leaveList, setLeaveList]=useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  
  const [startDay, setStartDay] = useState("sunday");
  const [showWeekNum, setShowWeekNum] = useState("0");
  const [currentWeekText, setCurrentWeekText] = useState("");

  const [holidays, setHolidays] = useState([]);
  
  const offset = (page - 1) * limit;

  const togglePopup = (check) => {
    setCheckDate(check);
    setShowPopup(!showPopup);
  }

    const changeDayHandle = (btnType) => {
      if (btnType === "prev") {
        setSelectedDate(subDays(selectedDate, 1));
        setSelectedDateText(format(subDays(selectedDate, 1), "yyyy-MM-dd") );
        
      }
      if (btnType === "next") {
        setSelectedDate(addDays(selectedDate, 1));
        setSelectedDateText(format(addDays(selectedDate, 1), "yyyy-MM-dd") );
        /*let leave_temp=[];
        for (let j = 0; j < leave.length; j++) {
          if (CheckList.includes(2)) {
            let d1 = leave[j].start_date.split("-");
            let c = format(addDays(selectedDate, 1), "yyyy-MM-dd").split("-");
            let d2 = leave[j].end_date.split("-");
            
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
  
            if (check >= from && check <= to) {
              
              leave_temp.push(leave[j]);
              
            }
          }
        }
        setLeaveList(leave_temp);*/
      }
    };
  
    const changeWeekHandle = (btnType) => {
      //console.log("current week", currentWeek);
      if (btnType === "prev") {
        //console.log(subWeeks(currentMonth, 1));
        setCurrentMonth(subWeeks(currentMonth, 1));
        setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
        
        let dateFormat = "yyyy-MM-dd";
        let start = format(startOfWeek(subWeeks(currentMonth, 1)), dateFormat);
        let end = format(endOfWeek(subWeeks(currentMonth, 1)), dateFormat);

        setCurrentWeekText(start+" ~ "+end);
      }
      if (btnType === "next") {
        //console.log(addWeeks(currentMonth, 1));
        setCurrentMonth(addWeeks(currentMonth, 1));
        setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
        setCurrentWeekText();
        let dateFormat = "yyyy-MM-dd";
        let start = format(startOfWeek(addWeeks(currentMonth, 1)), dateFormat);
        let end = format(endOfWeek(addWeeks(currentMonth, 1)), dateFormat);

        setCurrentWeekText(start+" ~ "+end);
      }
    };

  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const onChangeStart= (e) => {
    e.preventDefault();
    setStart(e.target.value);
    
  };
  const onChangeEnd= (e) => {
    e.preventDefault();
    setEnd(e.target.value);
    
  };
  

  const onChangeDay= (e) => {
    e.preventDefault();
    setSelectedDateText(e.target.value);
    
  };
  const onSearch = (e) => {
    setIsSearch(true);
    setCalendar();
    e.preventDefault();

    if (options === "all") {
      const filtered = schedules.filter((itemList) => {
        return itemList.subject.toUpperCase().includes(search.toUpperCase());
      });

      setFilterData(filtered);
    }else{
      const noFiltered = schedules.filter((itemList) => {
        return itemList.calendar_no == options;
      });
      const filtered = noFiltered.filter((itemList) => {
        return itemList.subject.toUpperCase().includes(search.toUpperCase());
      });

      setFilterData(filtered);
    }
      
  };

  useEffect(() => {
    if (location.state !==null){
    if(currentData !== location.state.type) {
      setCalendar("");
    }
     
    currentData = location.state.type;
    }

    axios.get('https://gw.thegmmedical.com:5003/api/get_calendar_setting')
    .then(function (response){
   
      let setting = JSON.parse(response.data[0].selections);


      setting.push("0");
      setting.push("00");
      setCheckList(setting);

    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    var user_calendar=[], group_calendar=[];
    const BASE_CALENDAR_URL = "https://www.googleapis.com/calendar/v3/calendars";
const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY =
  "holiday@group.v.calendar.google.com";
const API_KEY = "AIzaSyAblXK6hywzoPW3Vp_4ASRDogoyEufpITA";
const CALENDAR_REGION = "ko.south_korea";
const currentYear = new Date().getFullYear();
const timeMin = new Date(`${currentYear}-01-01`).toISOString();
const timeMax = new Date(`${currentYear}-12-31`).toISOString();

//https://calendar.google.com/calendar/embed?src=ko.south_korea.official%23holiday%40group.v.calendar.google.com&ctz=Asia%2FSeoul

fetch(
  `${BASE_CALENDAR_URL}/${CALENDAR_REGION}.official%23${BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY}/events?key=${API_KEY}&timeMin=${timeMin}&timeMax=${timeMax}`
)
  .then((response) => response.json())
  .then((response) => {
    const formattedResponse = response.items
      .map(({ summary, start, end }) => ({ summary, start, end }))
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    setHolidays(formattedResponse);
  });

  
    axios.get("https://gw.thegmmedical.com:5003/api/getcalendar").then((response) => {
      // setNotice(response.data);
      setCalendars(response.data);
       
     });
     axios.get("https://gw.thegmmedical.com:5003/api/get_teamcalendar").then((response) => {
      // setNotice(response.data);
      setTeamCalendars(response.data);
       
     });
     axios.get("https://gw.thegmmedical.com:5003/api/get_user_calendar").then((response) => {
      // setNotice(response.data);
      user_calendar = response.data;
      setUserCalendars(response.data);
       
     });
      axios.get("https://gw.thegmmedical.com:5003/api/get_invited_calendar").then((response) => {
      // setNotice(response.data);
      group_calendar = response.data;
      console.log("groupcalenar ", response.data);
      setGroupCalendars(response.data);
       
     });

     axios.get("https://gw.thegmmedical.com:5003/api/get_calendar_setting").then((response) => {
      // setNotice(response.data);
      let setting = response.data[0];
      //console.log("어", response.data)
      if (response.data.length>0){
        setShowWeekNum(setting.show_weeknum);
        setStartDay(setting.start_day);
      
      if (location.state !== null && location.state.type==="daily"){
        setCalendar("daily");
      }else{
        setCalendar(setting.calendar_type);
        
      }
      setPrevCalendar(setting.calendar_type);
      }else{
        
        if (location.state !== null && location.state.type==="daily"){
          setCalendar("daily");
        }else if (location.state === null ){
          console.log("setting...", setting.calendar_type);
         
        }else{
           setCalendar("monthly");
        }
      }
      
       
     });
  
    
    var schedule=[];
    var team_schedule=[];
    var user_schedule=[];
    var group_schedule=[];
    var invited_schedule=[];
    var final_schedule=[];
    axios.get("https://gw.thegmmedical.com:5003/api/get_schedule").then((response) => {
      schedule=response.data;
      final_schedule = final_schedule.concat(schedule);
      //setSchedule(response.data);
      axios
      .get("https://gw.thegmmedical.com:5003/api/get_team_schedule")
      .then((response) => {
        team_schedule=response.data;
        final_schedule = final_schedule.concat(team_schedule);
        //setTeamSchedule(response.data);
      axios
      .get("https://gw.thegmmedical.com:5003/api/get_user_schedule")
      .then((response) => {
        user_schedule=response.data;
        final_schedule = final_schedule.concat(user_schedule);
        //setSchedules(final_schedule);
        
        
        axios
      .get("https://gw.thegmmedical.com:5003/api/get_invited_schedule")
      .then((response) => {
        console.log("invited", response.data);
        invited_schedule=response.data;
        if (response.data !==undefined){
          for (var i in invited_schedule) {
          invited_schedule[i].calendar_no = user_calendar[0].no;
          invited_schedule[i].color = user_calendar[0].color;
          }
        }
        
        const uniqueIds = [];
        
        final_schedule = final_schedule.concat(invited_schedule);
        console.log("invited", final_schedule);

        var valueArr = group_calendar.map(function(item){ return item.no });

        if (valueArr.length >0){
          const data={
            group_nos: valueArr
          }
          axios.post("https://gw.thegmmedical.com:5003/api/get_group_schedule", data).then((response) => {
            console.log("gruop schedule", response.data);
          group_schedule=response.data;
          final_schedule = final_schedule.concat(group_schedule);
          });
        }
        console.log("final_schedules.......", final_schedule);
        const unique = final_schedule.filter(element => {
          const isDuplicate = uniqueIds.includes(element.s_id);
        
          if (!isDuplicate) {
            uniqueIds.push(element.s_id);
        
            return true;
          }
        
          return false;
        });

        for (let i=0;i<unique.length;i++){
          if (unique[i].start_hour < 10){
            unique[i].start_hour = "0"+unique[i].start_hour;
          }
          if (unique[i].start_min < 10){
            unique[i].start_min = "0"+unique[i].start_min;
          }
          if (unique[i].end_hour < 10){
            unique[i].end_hour = "0"+unique[i].end_hour;
          }
          if (unique[i].end_min < 10){
            unique[i].end_min = "0"+unique[i].end_min;
          }
      
        }
        
        setSchedules(unique);
      });
          
          
        });

        
      });
    });

    
    

      
    let monthstart = getMonth(currentDate) + 1;
    if (monthstart < 10) {
      monthstart = "0" + monthstart;
    }
    let currentyear = getYear(currentDate);
    let value = currentyear + "-" + monthstart;
    axios
      .get("https://gw.thegmmedical.com:5003/api/getleaves/" + value)
      .then((response) => {

        //console.log("getleaves....", response.data);
        
        let leave_temp=[];
        for (let j = 0; j < response.data.length; j++) {
          
            let d1 = moment(response.data[j].start_date).format("YYYY-MM-DD").split("-");
            let c = format(addDays(selectedDate, 1), "yyyy-MM-dd").split("-");
            let d2 = moment(response.data[j].end_date).format("YYYY-MM-DD").split("-");
            
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
  
           
            if (check >= from && check <= to) {
              
              leave_temp.push(response.data[j]);
              
            
          }
        }
        setLeaveList(leave_temp);
        setLeaves(response.data);
        //setSchedule(response.data);
      });
    let dateFormat = "yyyy-MM-dd";
    let start = format(startOfWeek(currentDate), dateFormat);
    let end = format(endOfWeek(currentDate), dateFormat);
    setCurrentWeekText(start+" ~ "+end);


    


  }, [location]);

  const header = () => {
    const dateFormat = "yyyy년 M월";
    return (
      <div className="header row flex-middle">
        <div className="column col-start">
          <div className="icon" onClick={prevMonth}>
            <MdArrowBackIos />
          </div>
        </div>
        <div className="column col-center" style={{ textAlign: "center" }}>
          <span>{format(currentDate, dateFormat)}</span>
        </div>
        <div className="column col-end" style={{ textAlign: "right" }}>
          <div className="icon" onClick={nextMonth}>
            <MdArrowForwardIos />
          </div>
        </div>
      </div>
    );
  };
  const days = () => {
    const dateFormat = "ddd";
    var values;
    if (startDay === "sunday"){
      values = ["일", "월", "화", "수", "목", "금", "토"];
    }else{
      values = ["월", "화", "수", "목", "금", "토", "일"];
    }
    
    const days = [];
    let startDate = startOfWeek(currentDate);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="column col-center" key={i}>
          {values[i]}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  function compare( a, b ) {
    if ( a.start_hour < b.start_hour ){
      return -1;
    }
    if ( a.start_hour > b.start_hour ){
      return 1;
    }
    if (a.start_min < b.start_min) { 
      return -1;
  } else if (a.start_min > b.start_min) {
      return 1
  } else { // nothing to split them
      return 0;
  }
   
  }
/*
  const weekdays = () => {
    var values;
    const weekday = [];
    let dateFormat = "yyyy-MM-dd";
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
    let day = startDate;
    if (startDay === "sunday"){
      values = ["일", "월", "화", "수", "목", "금", "토"];
    }else{
      day = addDays(day,1);
      values = ["월", "화", "수", "목", "금", "토", "일"];
    }
    
    //const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    
    
    
    const monthStart = startOfMonth(currentDate);
    let value = format(startDate, dateFormat);
    for (let i = 0; i < 7; i++) {
      let temp = [];
      let leave_temp = [];
     

      leave.forEach(function(ele) {
        if (CheckList.includes(2)) {
        let d1 = ele.start_date.split("-");
        let c = value.split("-");
        let d2 = ele.end_date.split("-");
  
        var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
        var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
        var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
  
          if (check >= from && check <= to){
            leave_temp.push(ele);
  
          }
        }
        });

       
          for (let i=0;i<schedules.length;i++){
            if (CheckList.includes(schedules[i].calendar_no)) {

              let d1 = schedules[i].start_date.split("-");
          let c = value.split("-");
          let d2 = schedules[i].end_date.split("-");
    
          var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
          var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
          var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
          if (check >= from && check <= to){
          if (schedules[i].is_repeat === 1) {
            if (schedules[i].rep_cycle === "DAILY") {
              let cycle_num = schedules[i].rep_cycleN;
              let times = schedules[i].times;
              let diff_week = differenceInCalendarDays(day, from);

              //if (())
              if (schedules[i].rep_end === "times"){
                let total = times * cycle_num;
                let maxdate = addDays(from, total);
                if (check.getTime() < maxdate.getTime()){
                  if ((diff_week%cycle_num)===0){

                    temp.push(schedules[i]);
                    


                  }
                }
              }else{
                if ((diff_week%cycle_num)===0){
                  temp.push(schedules[i]);
                }
              }
            }else if (schedules[i].rep_cycle === "weekly_weekday"){
              let cycle_num = schedules[i].rep_cycleN; // 주기
              let diff_week = differenceInCalendarWeeks(day, from);

              if (schedules[i].rep_end === "times"){
                let times = schedules[i].times;
                let total = times * cycle_num;
                let maxdate = addWeeks(from, total);
                if (check.getTime() < maxdate.getTime()){

              if (!isWeekend(day) && ((diff_week%cycle_num)===0)){
                temp.push(schedules[i]);
              }
              }
              }else{

                if (!isWeekend(day) && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[i]);
                }
              }
            }else if (schedules[i].rep_cycle === "WEEKLY"){

              let cycle_num = schedules[i].rep_cycleN;
              let rep_weekday = schedules[i].rep_week_day;
              let diff_week = differenceInCalendarWeeks(day, from);
              let letterDays = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"];

              if (schedules[i].rep_end === "times"){
                let times = schedules[i].times;
                let total = times * cycle_num;
                let maxdate = addWeeks(from, total);
                if (check.getTime() < maxdate.getTime()){
              
                if (letterDays[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[i]);
                }
                }
              }else{
                if (letterDays[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                  //console.log("매주 반복 나오셈", pushSchedule(day, days, schedules[i]));
                  temp.push(schedules[i]);

                }
              }

            }else if (schedules[i].rep_cycle === "MONTHLY"){
              let cycle_num = schedules[i].rep_cycleN;
              let rep_type = schedules[i].rep_type;
              let rep_month_type = schedules[i].rep_month_type;
              let diff_week = differenceInCalendarMonths(day, from);
              
              if (schedules[i].rep_end === "times"){
                let times = schedules[i].times;
                let total = times * cycle_num;
                let maxdate = addMonths(from, total);
                if (check.getTime() < maxdate.getTime()){
                
                  if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                    temp.push(schedules[i]);
                  }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                    temp.push(schedules[i]);
                  }
                }
              }else{
                if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[i]);
                }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[i]);
                }
              }
            }else if (schedules[i].rep_cycle === "YEARLY"){
              let cycle_num = schedules[i].rep_cycleN;
              let rep_type = schedules[i].rep_type;
              let rep_month_type = schedules[i].rep_month_type;
              let diff_week = differenceInCalendarYears(day, from);
              
              if (schedules[i].rep_end === "times"){
                let times = schedules[i].times;
                let total = times * cycle_num;
                let maxdate = addYears(from, total);
                if (check.getTime() < maxdate.getTime()){
                  if (rep_month_type === "date" && parseInt(c[2])== rep_type.split("-")[1] && parseInt(c[1]) ==  rep_type.split("-")[0] && ((diff_week%cycle_num)===0)){
                    temp.push(schedules[i]);
                  }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                    let nth = rep_type.split("-");
                    var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                    d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                    date = d.getDate(),
                    getday  = d.getDay(),
                    n    = Math.ceil(date / 7);
                    if (nth[0]==n && nth[1] == days_en[getday]){
                      temp.push(schedules[i]);
                    }
                  }
                }
              }else{

                if (rep_month_type === "date" && parseInt(c[2])=== parseInt(rep_type.split("-")[1]) && parseInt(c[1]) ===  parseInt(rep_type.split("-")[0]) && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[i]);
                }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                  let nth = rep_type.split("-");
                  var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                  d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                  date = d.getDate(),
                  getday  = d.getDay(),
                  n    = Math.ceil(date / 7);
                  if (nth[0]==n && nth[1] == days_en[getday]){
                    temp.push(schedules[i]);
                  }
                }
              }
            }
          }else{
            temp.push(schedules[i]);
          }
        }
        }
        }
 
      temp.sort(compare);
      let holiday =[];
      for (let i = 0; i < holidays.length; i++) {
        //if (CheckList.includes(holidays[i].calendar_no)) {
          let d1 = holidays[i].start.date.split("-");
          let c = value.split("-");
          let d2 = holidays[i].end.date.split("-");

          let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
          let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
          let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
          to = subDays(to, 1);
          if (check >= from && check <= to) {
            

        
              holiday.push(
                  <div style={{ display: "flex" }}>
                    {holidays[i].summary}
                  </div>
            
              );
            
          } else {
          }
        //}
      }
      

      weekday.push(
        <tr className={`column cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}>
          <td class="tg-0lax">{value} [{values[i]}] <span style={{color:"red"}}>{holiday}</span></td>
          <td class="tg-0lax">
          {leave_temp.map((element, index)=><li><div
                  className={element.vtype}
                  style={{ color: "aqua", display:"flex", alignItems:"center" }}
                >
                <BsFillSquareFill style={{marginRight:"5px"}}/>
                </div>{element.name}
                {element.vtype === "0" && (
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[공가]</span>
                )}
                {element.vtype === "0.5" && (
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[{element.vrange == "2" ? "오후 " : "오전 "}반차]</span>
                )}
                {element.vtype === "1" && (
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[연차]</span>
                )}
                {element.vtype === "0.25" && (
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[반반차]</span>
                )}
                
                </li>)} 
            
            
                {temp.map((element, index)=>
                <Link  to="/schedule_v" state={{schedule: element}}><li><div
                  className={element.type}
                  style={{ color: `${element.color}`, display:"flex", alignItems:"center" }}
                >
                  <BsFillSquareFill style={{marginRight:"5px"}}/>
                </div>{element.subject}
                {element.start_hour!==null  && (
                  <span style={{ color: "#666" }}>
                     &nbsp;&nbsp;&nbsp;&nbsp;[{element.start_hour}:{element.start_min}]
                    </span>
                )}
                
                
                </li></Link>)}
                
          </td>
          
        </tr>
      );
      startDate.setDate(startDate.getDate() + 1);
      value =format(startDate, dateFormat);

    }
    return weekday;
  };
*/


const weekdays = () => {
  var values;
  const weekday = [];
  let dateFormat = "yyyy-MM-d";
  const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
  let day = startDate;
  if (startDay === "sunday"){
    values = ["일", "월", "화", "수", "목", "금", "토"];
  }else{
    day = addDays(day,1);
    values = ["월", "화", "수", "목", "금", "토", "일"];
  }
 
  //const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
 
 
 
  const monthStart = startOfMonth(currentDate);
  let value = format(startDate, dateFormat);
  for (let i = 0; i < 7; i++) {
    let temp = [];
    let leave_temp = [];
   


    var leaves = [];
    if (CheckList.includes("0")) {
      for (let i = 0; i < leave.length; i++) {


       
        let d1 = moment(leave[i].start_date).format("YYYY-MM-DD").split("-");
        let c = value.split("-");
        let d2 = moment(leave[i].end_date).format("YYYY-MM-DD").split("-");


        let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
        let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
        let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
        var weekend = isWeekend(check);
       
        if (weekend === false) {
          if (check >= from && check <= to) {
            let vtype;
            if (leave[i].vtype === "1") {
              vtype = "연차";
            }
            if (leave[i].vtype === "0.5") {


              if (leave[i].vrange == "2"){
                vtype = "오후 반차";


              }else{
                vtype = "오전 반차";
              }
             
            }
            if (leave[i].vtype === "0.25") {
              vtype = "반반차";
            }
            if (leave[i].vtype === "0") {
              vtype = "공가";
            }
            /*leave_temp.push(
              <div className="leave_list" onClick={()=>togglePopup()}>
                <div style={{ display: "flex" }}>
                  <div>
                    <BsFillSquareFill />
                  </div>
                  [{vtype}] {leave[i].name}
                </div>
              </div>
            );*/




            leave_temp.push(
              leave[i]
            );


          } else {
          }
        }
      }
    }


      /*schedules.forEach(function(ele) {
        if (CheckList.includes(ele.calendar_no)) {
        let d1 = ele.start_date.split("-");
        let c = value.split("-");
        let d2 = ele.end_date.split("-");
 
        var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
        var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
        var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
 
          if (check >= from && check <= to){
            console.log("hey");
            temp.push(ele);
 
          }
        }
        });*/
        for (let i=0;i<schedules.length;i++){
          if (CheckList.includes(schedules[i].calendar_no)) {


            let d1 = schedules[i].start_date.split("-");
        let c = value.split("-");
        let d2 = schedules[i].end_date.split("-");
 
        var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
        var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
        var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
        if (check >= from && check <= to){
        if (schedules[i].is_repeat === 1) {
          if (schedules[i].rep_cycle === "DAILY") {
            let cycle_num = schedules[i].rep_cycleN;
            let times = schedules[i].times;
            let diff_week = differenceInCalendarDays(day, from);


            //if (())
            if (schedules[i].rep_end === "times"){
              let total = times * cycle_num;
              let maxdate = addDays(from, total);
              if (check.getTime() < maxdate.getTime()){
                if ((diff_week%cycle_num)===0){


                  temp.push(schedules[i]);
                 




                }
              }
            }else{
              if ((diff_week%cycle_num)===0){
                temp.push(schedules[i]);
              }
            }
          }else if (schedules[i].rep_cycle === "weekly_weekday"){
            let cycle_num = schedules[i].rep_cycleN; // 주기
            let diff_week = differenceInCalendarWeeks(day, from);


            if (schedules[i].rep_end === "times"){
              let times = schedules[i].times;
              let total = times * cycle_num;
              let maxdate = addWeeks(from, total);
              if (check.getTime() < maxdate.getTime()){


            if (!isWeekend(day) && ((diff_week%cycle_num)===0)){
              temp.push(schedules[i]);
            }
            }
            }else{


              if (!isWeekend(day) && ((diff_week%cycle_num)===0)){
                temp.push(schedules[i]);
              }
            }
          }else if (schedules[i].rep_cycle === "WEEKLY"){


            let cycle_num = schedules[i].rep_cycleN;
            let rep_weekday = schedules[i].rep_week_day;
            let diff_week = differenceInCalendarWeeks(day, from);
            let letterDays = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"];


            if (schedules[i].rep_end === "times"){
              let times = schedules[i].times;
              let total = times * cycle_num;
              let maxdate = addWeeks(from, total);
              if (check.getTime() < maxdate.getTime()){
           
              if (letterDays[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                temp.push(schedules[i]);
              }
              }
            }else{
              if (letterDays[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                //console.log("매주 반복 나오셈", pushSchedule(day, days, schedules[i]));
                temp.push(schedules[i]);


              }
            }


          }else if (schedules[i].rep_cycle === "MONTHLY"){
            let cycle_num = schedules[i].rep_cycleN;
            let rep_type = schedules[i].rep_type;
            let rep_month_type = schedules[i].rep_month_type;
            let diff_week = differenceInCalendarMonths(day, from);
           
            if (schedules[i].rep_end === "times"){
              let times = schedules[i].times;
              let total = times * cycle_num;
              let maxdate = addMonths(from, total);
              if (check.getTime() < maxdate.getTime()){
             
                if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[i]);
                }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[i]);
                }
              }
            }else{
              if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                temp.push(schedules[i]);
              }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                temp.push(schedules[i]);
              }
            }
          }else if (schedules[i].rep_cycle === "YEARLY"){
            let cycle_num = schedules[i].rep_cycleN;
            let rep_type = schedules[i].rep_type;
            let rep_month_type = schedules[i].rep_month_type;
            let diff_week = differenceInCalendarYears(day, from);
           
            if (schedules[i].rep_end === "times"){
              let times = schedules[i].times;
              let total = times * cycle_num;
              let maxdate = addYears(from, total);
              if (check.getTime() < maxdate.getTime()){
                if (rep_month_type === "date" && parseInt(c[2])== rep_type.split("-")[1] && parseInt(c[1]) ==  rep_type.split("-")[0] && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[i]);
                }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                  let nth = rep_type.split("-");
                  var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                  d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                  date = d.getDate(),
                  getday  = d.getDay(),
                  n    = Math.ceil(date / 7);
                  if (nth[0]==n && nth[1] == days_en[getday]){
                    temp.push(schedules[i]);
                  }
                }
              }
            }else{


              if (rep_month_type === "date" && parseInt(c[2])=== parseInt(rep_type.split("-")[1]) && parseInt(c[1]) ===  parseInt(rep_type.split("-")[0]) && ((diff_week%cycle_num)===0)){
                temp.push(schedules[i]);
              }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                let nth = rep_type.split("-");
                var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                date = d.getDate(),
                getday  = d.getDay(),
                n    = Math.ceil(date / 7);
                if (nth[0]==n && nth[1] == days_en[getday]){
                  temp.push(schedules[i]);
                }
              }
            }
          }
        }else{
          temp.push(schedules[i]);
        }
      }
      }
      }

    temp.sort(compare);
    let holiday =[];
    for (let i = 0; i < holidays.length; i++) {
      if (CheckList.includes("00")) {
        let d1 = holidays[i].start.date.split("-");
        let c = value.split("-");
        let d2 = holidays[i].end.date.split("-");


        let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
        let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
        let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
        to = subDays(to, 1);
        if (check >= from && check <= to) {
 
          holiday.push(
            holidays[i]
          );
         
        } else {
        }
      }
    }
   
    console.log("weekday holiday -----", holiday);
    weekday.push(
      <tr className={`column cell ${
            !isSameMonth(day, monthStart)
              ? "disabled"
              : isSameDay(day, selectedDate)
              ? "selected"
              : ""
          }`}>
        <td class="tg-0lax">{value} [{values[i]}]
        {/*  
          <span style={{color:"red"}}>{holiday}</span>
        */}  
        </td>
        <td class="tg-0lax">
        {leave_temp.map((element, index)=><li><div
                className={element.vtype}
                style={{ color: "aqua", display:"flex", alignItems:"center" }}
              >
              <BsFillSquareFill style={{marginRight:"5px"}}/>
              </div>{element.name}
              {element.vtype === "0" && (
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[공가]</span>
              )}
              {element.vtype === "0.5" && (
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[{element.vrange == "2" ? "오후" : "오전"}반차]</span>
              )}
              {element.vtype === "1" && (
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[연차]</span>
              )}
              {element.vtype === "0.25" && (
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[반반차]</span>
              )}
             
              </li>)}
             
               
              {holiday.map((element, index)=>                
              <div style={{ display: "flex" }}>
              <div
                className={element.vtype}
                style={{ color: "red", display:"flex", alignItems:"center" }}
              >
              <BsFillSquareFill style={{marginRight:"5px"}}/>
              </div>{element.summary}
              </div>
       
              )}
             
         
              {temp.map((element, index)=>
              <Link  to="/schedule_v" state={{schedule: element}}><li><div
                className={element.type}
                style={{ color: `${element.color}`, display:"flex", alignItems:"center" }}
              >
                <BsFillSquareFill style={{marginRight:"5px"}}/>
              </div>{element.subject}
              {element.start_hour!==null  && (
                <span style={{ color: "#666" }}>
                   &nbsp;&nbsp;&nbsp;&nbsp;[{element.start_hour}:{element.start_min}]
                  </span>
              )}
             
             
              </li></Link>)}
             
        </td>
       
      </tr>
    );
    startDate.setDate(startDate.getDate() + 1);
    value =format(startDate, dateFormat);


  }
  return weekday;
};




  const cells = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";

    let monthstart = getMonth(currentDate) + 1;
    if (monthstart < 10) {
      monthstart = "0" + monthstart;
    }
    let currentyear = getYear(currentDate);
    const rows = [];
    let days = [];
    let day = startDate;
    if (startDay !== "sunday"){

      day = addDays(day, 1);
    }
    //console.log("checklist checkecheck", CheckList);
    let formattedDate = "";

    var type;
    while (day <= endDate) {
      var weekN;
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        var checkDate = format(day, "yyyy-MM-dd");
        var todo = [];

        for (let i = 0; i < schedules.length; i++) {
          


          if (CheckList.includes(schedules[i].calendar_no)) {
            
            var d1 = schedules[i].start_date.split("-");
            var c = checkDate.split("-");
            var d2 = schedules[i].end_date.split("-");

            var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

            if (check >= from && check <= to) {

              type = (
                <div
                  className={schedules[i].type}
                  style={{ color: `${schedules[i].color}` }}
                >
                  <BsFillSquareFill />
                </div>
              );
              if (schedules[i].is_repeat === 1 || schedules[i].is_repeat === "1") {
                if (schedules[i].rep_cycle === "DAILY") {
                  let cycle_num = schedules[i].rep_cycleN;
                  let times = schedules[i].times;
                  let diff_week = differenceInCalendarDays(day, from);

                  //if (())
                  if (schedules[i].rep_end === "times"){
                    let total = times * cycle_num;
                    let maxdate = addDays(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if ((diff_week%cycle_num)===0){
                        if (schedules[i].allday !== 1) {
                          todo.push(
                            <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                              <div style={{ display: "flex" }}>
                                {type}
                                {schedules[i].subject}
                              </div>
                              <span style={{ color: "#666" }}>
                                {schedules[i].start_hour}:{schedules[i].start_min}
                              </span>
                            </div>
                            </Link>
                          );
                        }
                        if (schedules[i].allday === 1) {
                          todo.push(
                            <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                              <div
                                className="all allday"
                                style={{ backgroundColor: `${schedules[i].color}` }}
                              >
                        {schedules[i].subject}
                              </div>
                            </div></Link>
                          );
                        }
      
                        }
                    }
                  }else{
                    if ((diff_week%cycle_num)===0){
                      if (schedules[i].allday !== 1) {
                        todo.push(
                          <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                            <div style={{ display: "flex" }}>
                              {type}
                             {schedules[i].subject}
                            </div>
                            <span style={{ color: "#666" }}>
                              {schedules[i].start_hour}:{schedules[i].start_min}
                            </span>
                          </div></Link>
                        );
                      }
                      if (schedules[i].allday === 1) {
                        todo.push(
                          <Link to="/schedule_v" state={{schedule: schedules[i]}}>  <div className="schedule_list">
                            <div
                              className="all allday"
                              style={{ backgroundColor: `${schedules[i].color}` }}
                            >
                        {schedules[i].subject}
                            </div>
                          </div></Link>
                        );
                      }
    
                      }
                  }
                  
                }else if (schedules[i].rep_cycle === "weekly_weekday"){
                  let cycle_num = schedules[i].rep_cycleN; // 주기
                  let diff_week = differenceInCalendarWeeks(day, from);

                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){

                  if (!isWeekend(day) && ((diff_week%cycle_num)===0)){

                    if (schedules[i].allday !== 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div style={{ display: "flex" }}>
                            {type}
                            {schedules[i].subject}
                          </div>
                          <span style={{ color: "#666" }}>
                            {schedules[i].start_hour}:{schedules[i].start_min}
                          </span>
                        </div></Link>
                      );
                    }
                    if (schedules[i].allday === 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div
                            className="all allday"
                            style={{ backgroundColor: `${schedules[i].color}` }}
                          >
                        {schedules[i].subject}
                          </div>
                        </div></Link>
                      );
                    }
                    
                  }
                }
              }else{

                if (!isWeekend(day) && ((diff_week%cycle_num)===0)){

                  if (schedules[i].allday !== 1) {
                    todo.push(
                      <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list" >
                        <div style={{ display: "flex" }}>
                          {type}
                          {schedules[i].subject}
                        </div>
                        <span style={{ color: "#666" }}>
                          {schedules[i].start_hour}:{schedules[i].start_min}
                        </span>
                      </div></Link>
                    );
                  }
                  if (schedules[i].allday === 1) {
                    todo.push(
                      <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                        <div
                          className="all allday"
                          style={{ backgroundColor: `${schedules[i].color}` }}
                        >
                        {schedules[i].subject}
                        </div>
                      </div></Link>
                    );
                  }
                  
                }

              }
                }else if (schedules[i].rep_cycle === "WEEKLY"){
                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_weekday = schedules[i].rep_week_day;
                  let diff_week = differenceInCalendarWeeks(day, from);
                  let days = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"];

                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){
                  
                  if (days[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                    if (schedules[i].allday !== 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div style={{ display: "flex" }}>
                            {type}
                            {schedules[i].subject}
                          </div>
                          <span style={{ color: "#666" }}>
                            {schedules[i].start_hour}:{schedules[i].start_min}
                          </span>
                        </div></Link>
                      );
                    }
                    if (schedules[i].allday === 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div
                            className="all allday"
                            style={{ backgroundColor: `${schedules[i].color}` }}
                          >
                        {schedules[i].subject}
                          </div>
                        </div></Link>
                      );
                    }
                  }
                }}else{
                  if (days[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                    if (schedules[i].allday !== 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div style={{ display: "flex" }}>
                            {type}
                            {schedules[i].subject}
                          </div>
                          <span style={{ color: "#666" }}>
                            {schedules[i].start_hour}:{schedules[i].start_min}
                          </span>
                        </div></Link>
                      );
                    }
                    if (schedules[i].allday === 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div
                            className="all allday"
                            style={{ backgroundColor: `${schedules[i].color}` }}
                          >
                        {schedules[i].subject}
                          </div>
                        </div></Link>
                      );
                    }
                  }

                }
                }else if (schedules[i].rep_cycle === "MONTHLY"){
                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_type = schedules[i].rep_type;
                  let rep_month_type = schedules[i].rep_month_type;
                  let diff_week = differenceInCalendarMonths(day, from);
                  
                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addMonths(from, total);
                    if (check.getTime() < maxdate.getTime()){
                    
                  if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                    if (schedules[i].allday !== 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div style={{ display: "flex" }}>
                            {type}
                            {schedules[i].subject}
                          </div>
                          <span style={{ color: "#666" }}>
                            {schedules[i].start_hour}:{schedules[i].start_min}
                          </span>
                        </div></Link>
                      );
                    }
                    if (schedules[i].allday === 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div
                            className="all allday"
                            style={{ backgroundColor: `${schedules[i].color}` }}
                          >
                        {schedules[i].subject}
                          </div>
                        </div></Link>
                      );
                    }
                  }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                    let nth = rep_type.split("-");
                    var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                    d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                    date = d.getDate(),
                    getday  = d.getDay(),
                    n    = Math.ceil(date / 7);
                    if (nth[0]==n && nth[1] == days_en[getday]){
                      if (schedules[i].allday !== 1) {
                        todo.push(
                          <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                            <div style={{ display: "flex" }}>
                              {type}
                              {schedules[i].subject}
                            </div>
                            <span style={{ color: "#666" }}>
                              {schedules[i].start_hour}:{schedules[i].start_min}
                            </span>
                          </div></Link>
                        );
                      }
                      if (schedules[i].allday === 1) {
                        todo.push(
                          <Link to="/schedule_v" state={{schedule: schedules[i]}}>  <div className="schedule_list">
                            <div
                              className="all allday"
                              style={{ backgroundColor: `${schedules[i].color}` }}
                            >
                        {schedules[i].subject}
                            </div>
                          </div></Link>
                        );
                      }
                    }
                  }
                }}else{

                  if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                    if (schedules[i].allday !== 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div style={{ display: "flex" }}>
                            {type}
                            {schedules[i].subject}
                          </div>
                          <span style={{ color: "#666" }}>
                            {schedules[i].start_hour}:{schedules[i].start_min}
                          </span>
                        </div></Link>
                      );
                    }
                    if (schedules[i].allday === 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div
                            className="all allday"
                            style={{ backgroundColor: `${schedules[i].color}` }}
                          >
                        {schedules[i].subject}
                          </div>
                        </div></Link>
                      );
                    }
                  }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                    let nth = rep_type.split("-");
                    var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                    d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                    date = d.getDate(),
                    getday  = d.getDay(),
                    n    = Math.ceil(date / 7);
                    if (nth[0]==n && nth[1] == days_en[getday]){
                      if (schedules[i].allday !== 1) {
                        todo.push(
                          <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                            <div style={{ display: "flex" }}>
                              {type}
                              {schedules[i].subject}
                            </div>
                            <span style={{ color: "#666" }}>
                              {schedules[i].start_hour}:{schedules[i].start_min}
                            </span>
                          </div></Link>
                        );
                      }
                      if (schedules[i].allday === 1) {
                        todo.push(
                          <Link to="/schedule_v" state={{schedule: schedules[i]}}>  <div className="schedule_list">
                            <div
                              className="all allday"
                              style={{ backgroundColor: `${schedules[i].color}` }}
                            >
                        {schedules[i].subject}
                            </div>
                          </div></Link>
                        );
                      }
                    }
                  }

                }
                }else if (schedules[i].rep_cycle === "YEARLY"){

                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_type = schedules[i].rep_type;
                  let rep_month_type = schedules[i].rep_month_type;
                  let diff_week = differenceInCalendarYears(day, from);
                  
                  
                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addYears(from, total);
                    if (check.getTime() < maxdate.getTime()){
                  if (rep_month_type === "date" && c[2]== rep_type.split("-")[1] && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                    if (schedules[i].allday !== 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div style={{ display: "flex" }}>
                            {type}
                            {schedules[i].subject}
                          </div>
                          <span style={{ color: "#666" }}>
                            {schedules[i].start_hour}:{schedules[i].start_min}
                          </span>
                        </div></Link>
                      );
                    }
                    if (schedules[i].allday === 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div
                            className="all allday"
                            style={{ backgroundColor: `${schedules[i].color}` }}
                          >
                        {schedules[i].subject}
                          </div>
                        </div></Link>
                      );
                    }
                  }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                    let nth = rep_type.split("-");
                    var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                    d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                    date = d.getDate(),
                    getday  = d.getDay(),
                    n    = Math.ceil(date / 7);
                    if (nth[0]==n && nth[1] == days_en[getday]){
                      if (schedules[i].allday !== 1) {
                        todo.push(
                          <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                            <div style={{ display: "flex" }}>
                              {type}
                              {schedules[i].subject}
                            </div>
                            <span style={{ color: "#666" }}>
                              {schedules[i].start_hour}:{schedules[i].start_min}
                            </span>
                          </div></Link>
                        );
                      }
                      if (schedules[i].allday === 1) {
                        todo.push(
                          <Link to="/schedule_v" state={{schedule: schedules[i]}}>  <div className="schedule_list">
                            <div
                              className="all allday"
                              style={{ backgroundColor: `${schedules[i].color}` }}
                            >
                        {schedules[i].subject}
                            </div>
                          </div></Link>
                        );
                      }
                    }
                  }

                }}else{

                  if (rep_month_type === "date" && c[2]== rep_type.split("-")[1] && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                    if (schedules[i].allday !== 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div style={{ display: "flex" }}>
                            {type}
                            {schedules[i].subject}
                          </div>
                          <span style={{ color: "#666" }}>
                            {schedules[i].start_hour}:{schedules[i].start_min}
                          </span>
                        </div></Link>
                      );
                    }
                    if (schedules[i].allday === 1) {
                      todo.push(
                        <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                          <div
                            className="all allday"
                            style={{ backgroundColor: `${schedules[i].color}` }}
                          >
                        {schedules[i].subject}
                          </div>
                        </div></Link>
                      );
                    }
                  }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                    let nth = rep_type.split("-");
                    var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                    d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                    date = d.getDate(),
                    getday  = d.getDay(),
                    n    = Math.ceil(date / 7);
                    if (nth[0]==n && nth[1] == days_en[getday]){
                      if (schedules[i].allday !== 1) {
                        todo.push(
                          <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                            <div style={{ display: "flex" }}>
                              {type}
                              {schedules[i].subject}
                            </div>
                            <span style={{ color: "#666" }}>
                              {schedules[i].start_hour}:{schedules[i].start_min}
                            </span>
                          </div></Link>
                        );
                      }
                      if (schedules[i].allday === 1) {
                        todo.push(
                          <Link to="/schedule_v" state={{schedule: schedules[i]}}>  <div className="schedule_list">
                            <div
                              className="all allday"
                              style={{ backgroundColor: `${schedules[i].color}` }}
                            >
                        {schedules[i].subject}
                            </div>
                          </div></Link>
                        );
                      }
                    }
                  }

                }

                }
              } else {
                
                if (schedules[i].allday == 0) {
                  todo.push(
                    <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                      <div style={{ display: "flex" }}>
                        {type}
                        {schedules[i].subject}
                      </div>
                      <span style={{ color: "#666" }}>
                        {schedules[i].start_hour}:{schedules[i].start_min}
                      </span>
                    </div></Link>
                  );
                }
                if (schedules[i].allday == 1) {
                  todo.push(
                    <Link to="/schedule_v" state={{schedule: schedules[i]}}><div className="schedule_list">
                      <div
                        className="all allday"
                        style={{ backgroundColor: `${schedules[i].color}` }}
                      >
                        {schedules[i].subject}
                      </div>
                    </div></Link>
                  );
                }
              }


              


              /*if (schedules[i].allday === 1) {
                todo.push(
                  <div className="schedule_list">
                    <div
                      className="all allday"
                      style={{ backgroundColor: `${schedules[i].color}` }}
                    >
                      {schedules[i].subject}
                    </div>
                  </div>
                );
              }
      
            if (schedules[i].allday !== 1) {
              todo.push(
                <div className="schedule_list" style={{ padding: "0 5px" }}>
                  <div style={{ display: "flex" }}>
                    {type}
                    {schedules[i].subject}
                  </div>
                  <span style={{ color: "#666" }}>
                    {schedules[i].start_hour}:{schedules[i].start_min}
                  </span>
                </div>
              );
            }*/
            } else {
            }
          }else{
            
          }
        }


        for (let i = 0; i < holidays.length; i++) {
          if (CheckList.includes("00")) {
            let d1 = holidays[i].start.date.split("-");
            let c = checkDate.split("-");
            let d2 = holidays[i].end.date.split("-");

            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
            to = subDays(to, 1);
            if (check >= from && check <= to) {
              type = (
                <div
                  style={{ color: "red" }}
                >
                  <BsFillSquareFill />
                </div>
              );

          
                todo.push(
                  <div className="schedule_list">
                    <div style={{ display: "flex" }}>
                      {type}
                      {holidays[i].summary}
                    </div>
              
                  </div>
                );
              
            } else {
            }
          }
        }
        

        var leaves = [];
        if (CheckList.includes("0")) {
          for (let i = 0; i < leave.length; i++) {
            let d1 = moment(leave[i].start_date).format("YYYY-MM-DD").split("-");
            let c = checkDate.split("-");
            let d2 = moment(leave[i].end_date).format("YYYY-MM-DD").split("-");



            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
            var weekend = isWeekend(check);
            let isHoliday=false;


            for (let j = 0; j< holidays.length; j++) {
                let h_d1 = holidays[j].start.date.split("-");
                let h_d2 = holidays[j].end.date.split("-");
                let holiday_from = new Date(d1[0], parseInt(h_d1[1]) - 1, h_d1[2]); // -1 because months are from 0 to 11
                let holiday_to = new Date(d2[0], parseInt(h_d2[1]) - 1, h_d2[2]);
                holiday_to = subDays(holiday_to, 1);
              if (check >= holiday_from && check <= holiday_to) {
                console.log("holiday", holiday_from, check, holiday_to);


                isHoliday=true;
              }else{
                //isHoliday=false;
             
              }
            }
            //console.log("isHoliday", isHoliday);


            if (weekend === false && isHoliday===false) {
              if (check >= from && check <= to) {
                let vtype;
                if (leave[i].vtype === "1") {
                  vtype = "연차";
                }
                if (leave[i].vtype === "0.5") {


                  if (leave[i].vrange == "2"){
                    vtype = "오후 반차";


                  }else{
                    vtype = "오전 반차";
                  }
                 
                }
                if (leave[i].vtype === "0.25") {
                  vtype = "반반차";
                }
                if (leave[i].vtype === "0") {
                  vtype = "공가";
                }
         
                  leaves.push(
                    <div className="leave_list" onClick={()=>togglePopup(check)}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <BsFillSquareFill />
                        </div>
                        [{vtype}] {leave[i].name}
                      </div>
                    </div>
                  );
               
               
              } else {
              }
            }



          }
        }

        const cloneDay = day;
        days.push(
          <div
            className={`column cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            //onClick={() => onDateClick(parse(cloneDay))}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
            <div onClick={()=>setClicked(true)}>{todo}</div>
            {clicked && (
              <div>
                
              </div>
            )}
            {leaves}
          </div>
        );
        day = addDays(day, 1);
       weekN = getWeek(day);
      }
      if (showWeekNum===1){
        rows.push(
        <div className="row" style={{padding:"5px 10px", fontSize:"12px", color:"#b6b6b6"}}>
          <span>{weekN}주</span>
        </div>
      );
      }
      
   
      rows.push(
        <div className="row" key={day}>
          {" "}
          {days}{" "}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const leaveSchedule = () => {
    let leave_temp=[];        
    for (let j = 0; j < leave.length; j++) {
      if (CheckList.includes("0")) {
        let d1 = leave[j].start_date.split("-");
        let c = selectedDateText.split("-");
        let d2 = leave[j].end_date.split("-");
  
        let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
        let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
        let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
  
        if (check >= from && check <= to) {
        leave_temp.push(
          <li><div
          className={leave[j].vtype}
          style={{ color: "aqua", display:"flex", alignItems:"center" }}
        >
          <BsFillSquareFill style={{marginRight:"5px"}}/>
        </div>{leave[j].name}
        {leave[j].vtype === "0" && (
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[공가]</span>
        )}
        {leave[j].vtype === "0.5" && (
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[{leave[j].vrange == "2" ? "오후 " : "오전 "}반차]</span>
        )}
        {leave[j].vtype === "1" && (
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[연차]</span>
        )}
        {leave[j].vtype === "0.25" && (
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[반반차]</span>
        )}
        
        </li>
        );
              
        }
      }
    }
    return leave_temp;
  }

  const timeNotSpecified = () => {
    const monthStart = startOfMonth(currentDate);
    const values = ["일", "월", "화", "수", "목", "금", "토"];
    let todos = [];
    let startDate = startOfWeek(currentDate);

    let temp = [];
    console.log("스케줄이...", schedules);
    for (let j = 0; j < schedules.length; j++) {
      
      if ((CheckList.includes(schedules[j].calendar_no) && (schedules[j].allday === 1))) {
        console.log(schedules[j]);
        var d1 = schedules[j].start_date.split("-");
        var c = selectedDateText.split("-");
        var d2 = schedules[j].end_date.split("-");
        var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
        var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
        var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
        
        if (check >= from && check <= to) {

          console.log("어 있엉!!!!!!!!!!!!!!!!!",check);

          //noTime.push(schedules[j]);
          if (schedules[j].is_repeat === 1) {
            //console.log("..........",  c, d1, d2, schedules[j].subject );
            if (schedules[j].rep_cycle === "DAILY") {
              let cycle_num = schedules[j].rep_cycleN;
              let times = schedules[j].times;
              let diff_week = differenceInCalendarDays(check, from);

              //if (())
              if (schedules[j].rep_end === "times"){
                let total = times * cycle_num;
                let maxdate = addDays(from, total);
                if (check.getTime() < maxdate.getTime()){
                  if ((diff_week%cycle_num)===0){

                    temp.push(schedules[j]);
                    


                  }
                }
              }else{
                if ((diff_week%cycle_num)===0){
                  temp.push(schedules[j]);
                }
              }
            }else if (schedules[j].rep_cycle === "weekly_weekday"){
              let cycle_num = schedules[j].rep_cycleN; // 주기
              let diff_week = differenceInCalendarWeeks(check, from);

              if (schedules[j].rep_end === "times"){
                let times = schedules[j].times;
                let total = times * cycle_num;
                let maxdate = addWeeks(from, total);
                if (check.getTime() < maxdate.getTime()){

              if (!isWeekend(check) && ((diff_week%cycle_num)===0)){
                temp.push(schedules[j]);
              }
              }
              }else{

                if (!isWeekend(check) && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[j]);
                }
              }
            }else if (schedules[j].rep_cycle === "WEEKLY"){

              let cycle_num = schedules[j].rep_cycleN;
              let rep_weekday = schedules[j].rep_week_day;
              let diff_week = differenceInCalendarWeeks(check, from);
              let letterDays = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"];
              
              if (schedules[j].rep_end === "times"){
                let times = schedules[j].times;
                let total = times * cycle_num;
                let maxdate = addWeeks(from, total);
                if (check.getTime() < maxdate.getTime()){
              
                if (letterDays[getDay(check)]===rep_weekday && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[j]);
                }
                }
              }else{
                if (letterDays[getDay(check)]===rep_weekday && ((diff_week%cycle_num)===0)){
                  //console.log("매주 반복 나오셈", pushSchedule(day, days, schedules[i]));
                  temp.push(schedules[j]);

                }
              }

            }else if (schedules[j].rep_cycle === "MONTHLY"){
              let cycle_num = schedules[j].rep_cycleN;
              let rep_type = schedules[j].rep_type;
              let rep_month_type = schedules[j].rep_month_type;
              let diff_week = differenceInCalendarMonths(check, from);
              
              if (schedules[j].rep_end === "times"){
                let times = schedules[j].times;
                let total = times * cycle_num;
                let maxdate = addMonths(from, total);
                if (check.getTime() < maxdate.getTime()){
                
                  if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                    temp.push(schedules[j]);
                  }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                    temp.push(schedules[j]);
                  }
                }
              }else{
                if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[j]);
                }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[j]);
                }
              }
            }else if (schedules[j].rep_cycle === "YEARLY"){
              
              let cycle_num = parseInt(schedules[j].rep_cycleN);
              let rep_type = schedules[j].rep_type;
              let rep_month_type = schedules[j].rep_month_type;
              let diff_week = differenceInCalendarYears(check, from);
              if (schedules[j].rep_end === "times"){
                let times = schedules[j].times;
                let total = times * cycle_num;
                let maxdate = addYears(from, total);
                

                if (check.getTime() < maxdate.getTime()){
                  if (rep_month_type === "date" && parseInt(c[2])=== parseInt(rep_type.split("-")[1]) && parseInt(c[1]) ===  parseInt(rep_type.split("-")[0]) && ((diff_week%cycle_num)===0)){
                    temp.push(schedules[j]);
                  }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                    let nth = rep_type.split("-");
                    var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                    d    = check ? check instanceof Date ? check : new Date(check) : new Date(),
                    date = d.getDate(),
                    getday  = d.getDay(),
                    n    = Math.ceil(date / 7);
                    if (nth[0]==n && nth[1] == days_en[getday]){
                      temp.push(schedules[j]);
                    }
                  }
                }
              }else{

                if (rep_month_type === "date" && parseInt(c[2])=== parseInt(rep_type.split("-")[1]) && parseInt(c[1]) ===  parseInt(rep_type.split("-")[0]) && ((diff_week%cycle_num)===0)){
                  temp.push(schedules[j]);
                }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                  let nth = rep_type.split("-");
                  var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                  d    = check ? check instanceof Date ? check : new Date(check) : new Date(),
                  date = d.getDate(),
                  getday  = d.getDay(),
                  n    = Math.ceil(date / 7);
                  if (nth[0]==n && nth[1] == days_en[getday]){
                    temp.push(schedules[j]);
                  }
                }
              }
            }
          }else{
            temp.push(schedules[j]);
          }
          
        }
      }
    }
    let holiday =[];
    for (let i = 0; i < holidays.length; i++) {
      //if (CheckList.includes(holidays[i].calendar_no)) {
        let d1 = holidays[i].start.date.split("-");
        let c = selectedDateText.split("-");
        let d2 = holidays[i].end.date.split("-");

        let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
        let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
        let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
        to = subDays(to, 1);
        if (check >= from && check <= to) {
          

      
            holiday.push(
                <div style={{ display: "flex" }}>
                  {holidays[i].summary}
                </div>
          
            );
          
        } else {
        }
      //}
    }
    if (holiday.length>0){
      todos.push(
      <tr>
        <td colSpan="2" style={{padding:"10px", color:"red"}}>{holiday}</td>
      </tr>
    )
    }
    
    todos.push(

      
      <tr>
        
        <td class="tg-0lax">할 일</td>
        <td class="tg-0lax">
        
        {temp.map((element, index)=><li><div
                className={element.vtype}
                style={{ color: `${element.color}`, display:"flex", alignItems:"center" }}
              >
                <BsFillSquareFill style={{marginRight:"5px"}}/>
              </div><Link to="/schedule_v" state={{schedule: element}}>{element.subject}</Link>
            
              
              </li>)} 
          
        
             
              
        </td>
        
      </tr>
    )
    return todos;
  }
  const searchPeriod = () => {
    let start_date = start.split("-");
    let end_date = end.split("-");
    let s =  new Date(start_date[0], parseInt(start_date[1]) - 1, start_date[2]);
    setStartDate(s);
    let e =  new Date(end_date[0], parseInt(end_date[1]) - 1, end_date[2]);
    setEndDate(e);
  }


  const pushSchedule = (day, days, schedule_info) => {
    console.log(day);
    let checkDate = format(day, "yyyy-MM-dd");
    
    
    

    days.push(
      <tr
        className={`column cell ${
          !isSameMonth(day, monthStart)
            ? "disabled"
            : isSameDay(day, selectedDate)
            ? "selected" 
            : ""
        }`}
       
      >
        <td className="number">{format(day, "yyyy-MM-dd")}</td>
        <td className="number">{schedule_info.start_hour !==null &&
          ( schedule_info.start_hour )} 
        {schedule_info.start_hour !==null &&(<span>:</span>)}
        {schedule_info.start_hour !==null && ( schedule_info.start_min )
        }
        {schedule_info.start_hour ===null && ( <span>00:00~24:00</span> )
        }
        </td>
        <td className="number">{schedule_info.name}</td>
        <td className="number" style={{display:"flex", alignItems:"center", paddingLeft:"10px"}}><div
                  className={schedule_info.type}
                  style={{ color: `${schedule_info.color}`, display:"flex", alignItems:"center" }}
                >
                  <BsFillSquareFill style={{marginTop:"3px"}}/>
                </div>
                  <span style={{marginLeft:"10px"}}><Link to="/schedule_v" state={{schedule: schedule_info}}>{schedule_info.subject}</Link></span></td>
      </tr>
    );
    return days;
  }

  const listSchedule = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    //const startDate = startOfWeek(monthStart);
    //const endDate = endOfWeek(monthEnd);
    const dateFormat = "yyyy-MM-dd";


    let monthstart = getMonth(currentDate) + 1;
    if (monthstart < 10) {
      monthstart = "0" + monthstart;
    }
    let currentyear = getYear(currentDate);
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    /*let start_date = start.split("-");
    let end_date = end.split("-");
    let s =  new Date(start_date[0], parseInt(start_date[1]) - 1, start_date[2]);
    let e =  new Date(end_date[0], parseInt(end_date[1]) - 1, end_date[2]);*/
   
    var type;
    while (day <= endDate) {
     
        formattedDate = format(day, dateFormat);
        var checkDate = formattedDate;
        var todo = [];
        let holiday =[];
        for (let i = 0; i < holidays.length; i++) {
          //if (CheckList.includes(holidays[i].calendar_no)) {
            let d1 = holidays[i].start.date.split("-");
            let c = checkDate.split("-");
            let d2 = holidays[i].end.date.split("-");
   
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
            to = subDays(to, 1);
            if (check >= from && check <= to) {
                holiday.push(
                    <div style={{color:"black"}}>
                      {holidays[i].summary}
                    </div>
             
                );
             
            } else {
            }
          //}
          }
          if (holiday.length>0){
             days.push(
              <tr
              className={`column cell ${
                !isSameMonth(day, monthStart)
                  ? "disabled"
                  : isSameDay(day, selectedDate)
                  ? "selected"
                  : ""
              }`}
           
            >
              <td className="number">{format(day, "yyyy-MM-dd")}</td>
              <td className="number">
              </td>
              <td className="number">공휴일</td>
              <td className="number" style={{color:"red", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <BsFillSquareFill style={{marginRight:"5px"}}/>


                {holiday}</td>
            </tr>
            )
   
          }






          let leave_temp=[];  
          for (let j = 0; j < leave.length; j++) {
            if (CheckList.includes("0")) {
              let d1 = moment(leave[j].start_date).format("YYYY-MM-DD").split("-");
              let c = checkDate.split("-");
              let d2 = moment(leave[j].end_date).format("YYYY-MM-DD").split("-");
     
              let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
              let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
              let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
              if (check >= from && check <= to) {


                               
                let vtype;
                if (leave[j].vtype === "1") {
                  vtype = "연차";
                }
                if (leave[j].vtype === "0.5") {


                  if (leave[j].vrange == "2"){
                    vtype = "오후 반차";


                  }else{
                    vtype = "오전 반차";
                  }
                 
                }
                if (leave[j].vtype === "0.25") {
                  vtype = "반반차";
                }
                if (leave[j].vtype === "0") {
                  vtype = "공가";
                }


                leave_temp.push(
                  <div style={{color:"aqua", marginRight:"5px"}}><BsFillSquareFill style={{marginRight:"5px"}}/> [{vtype}] {leave[j].name}</div>
              );
                   
              }
            }
          }
          if (leave_temp.length>0){
            days.push(
             <tr
             className={`column cell ${
               !isSameMonth(day, monthStart)
                 ? "disabled"
                 : isSameDay(day, selectedDate)
                 ? "selected"
                 : ""
             }`}
           
           >
             <td className="number">{format(day, "yyyy-MM-dd")}</td>
             <td className="number">
             </td>
             <td className="number">연차</td>
             <td className="number list_leave" style={{color:"aqua", display:"flex", flexWrap:"wrap", justifyContent:"center", alignItems:"center"}}>
             


              {leave_temp}
            </td>
           </tr>
           )
   
         }


         
   
        for (let i = 0; i < schedules.length; i++) {
          if (CheckList.includes(schedules[i].calendar_no)) {
            var d1 = schedules[i].start_date.split("-");
            var c = checkDate.split("-");
            var d2 = schedules[i].end_date.split("-");


            var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);


            let schedule_info=[];
            console.log("dfdsfsdf[i]...........",check, from, to);
            if (check >= from && check <= to) {
            console.log("schedules[i]...........",schedules[i]);


              if (schedules[i].is_repeat === 1) {
                if (schedules[i].rep_cycle === "DAILY") {
                  let cycle_num = schedules[i].rep_cycleN;
                  let times = schedules[i].times;
                  let diff_week = differenceInCalendarDays(day, from);


                  //if (())
                  if (schedules[i].rep_end === "times"){
                    let total = times * cycle_num;
                    let maxdate = addDays(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if ((diff_week%cycle_num)===0){


                        days = pushSchedule(day, days, schedules[i]);
                       




                      }
                    }
                  }else{
                    if ((diff_week%cycle_num)===0){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                  }
                }else if (schedules[i].rep_cycle === "weekly_weekday"){
                  let cycle_num = schedules[i].rep_cycleN; // 주기
                  let diff_week = differenceInCalendarWeeks(day, from);


                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){


                  if (!isWeekend(day) && ((diff_week%cycle_num)===0)){
                    days = pushSchedule(day, days, schedules[i]);
                  }
                  }
                  }else{


                    if (!isWeekend(day) && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                  }
                }else if (schedules[i].rep_cycle === "WEEKLY"){


                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_weekday = schedules[i].rep_week_day;
                  let diff_week = differenceInCalendarWeeks(day, from);
                  let letterDays = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"];


                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){
                 
                    if (letterDays[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                    }
                  }else{
                    if (letterDays[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                      //console.log("매주 반복 나오셈", pushSchedule(day, days, schedules[i]));
                      days = pushSchedule(day, days, schedules[i]);


                    }
                  }


                }else if (schedules[i].rep_cycle === "MONTHLY"){
                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_type = schedules[i].rep_type;
                  let rep_month_type = schedules[i].rep_month_type;
                  let diff_week = differenceInCalendarMonths(day, from);
                 
                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addMonths(from, total);
                    if (check.getTime() < maxdate.getTime()){
                   
                      if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                        days = pushSchedule(day, days, schedules[i]);
                      }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                        days = pushSchedule(day, days, schedules[i]);
                      }
                    }
                  }else{
                    if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                  }
                }else if (schedules[i].rep_cycle === "YEARLY"){
                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_type = schedules[i].rep_type;
                  let rep_month_type = schedules[i].rep_month_type;
                  let diff_week = differenceInCalendarYears(day, from);
                 
                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addYears(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if (rep_month_type === "date" && c[2]== rep_type.split("-")[1] && c[1] ==  rep_type.split("-")[0] && ((diff_week%cycle_num)===0)){
                        days = pushSchedule(day, days, schedules[i]);
                      }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                        let nth = rep_type.split("-");
                        var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                        d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                        date = d.getDate(),
                        getday  = d.getDay(),
                        n    = Math.ceil(date / 7);
                        if (nth[0]==n && nth[1] == days_en[getday]){
                          days = pushSchedule(day, days, schedules[i]);
                        }
                      }
                    }
                  }else{


                    if (rep_month_type === "date" && parseInt(c[2])=== parseInt(rep_type.split("-")[1]) && parseInt(c[1]) ===  parseInt(rep_type.split("-")[0]) && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                      let nth = rep_type.split("-");
                      var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                      d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                      date = d.getDate(),
                      getday  = d.getDay(),
                      n    = Math.ceil(date / 7);
                      if (nth[0]==n && nth[1] == days_en[getday]){
                        days = pushSchedule(day, days, schedules[i]);
                      }
                    }
                  }
                }
              }else{
               
                days = pushSchedule(day, days, schedules[i]);
              }
              type = (
                <div
                  className={schedule_info.type}
                  style={{ color: `${schedule_info.color}` }}
                >
                  <BsFillSquareFill />
                </div>
              );


              const cloneDay = day;
             
             
             
              }
           
          }
        }


       
       
        day = addDays(day, 1);
     
     
     
    }

    if (days.length===0){
      days = <td colSpan={4} style={{height:"100px"}}><span>일정이 없습니다.</span></td>
    }

    return days;




  }





/*
  const listSchedule = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    //const startDate = startOfWeek(monthStart);
    //const endDate = endOfWeek(monthEnd);
    const dateFormat = "yyyy-MM-dd";

    let monthstart = getMonth(currentDate) + 1;
    if (monthstart < 10) {
      monthstart = "0" + monthstart;
    }
    let currentyear = getYear(currentDate);
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
   
    
    var type;
    while (day <= endDate) {
      
        formattedDate = format(day, dateFormat);
        var checkDate = formattedDate;
        var todo = [];
        let holiday =[];
        for (let i = 0; i < holidays.length; i++) {
          //if (CheckList.includes(holidays[i].calendar_no)) {
            let d1 = holidays[i].start.date.split("-");
            let c = checkDate.split("-");
            let d2 = holidays[i].end.date.split("-");
    
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
            to = subDays(to, 1);
            if (check >= from && check <= to) {
              
    
          
                holiday.push(
                    <div>
                      {holidays[i].summary}
                    </div>
              
                );
              
            } else {
            }
          //}
          }
          if (holiday.length>0){
             days.push(
              <tr
              className={`column cell ${
                !isSameMonth(day, monthStart)
                  ? "disabled"
                  : isSameDay(day, selectedDate)
                  ? "selected" 
                  : ""
              }`}
            
            >
              <td className="number">{format(day, "yyyy-MM-dd")}</td>
              <td className="number">
              </td>
              <td className="number">공휴일</td>
              <td className="number" style={{color:"red"}}>{holiday}</td>
            </tr>
            )
    
          }
         
    
        for (let i = 0; i < schedules.length; i++) {
          if (CheckList.includes(schedules[i].calendar_no)) {
            var d1 = schedules[i].start_date.split("-");
            var c = checkDate.split("-");
            var d2 = schedules[i].end_date.split("-");

            var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

            let schedule_info=[];
            console.log("dfdsfsdf[i]...........",check, from, to);
            if (check >= from && check <= to) {
            console.log("schedules[i]...........",schedules[i]);

              if (schedules[i].is_repeat === 1) {
                if (schedules[i].rep_cycle === "DAILY") {
                  let cycle_num = schedules[i].rep_cycleN;
                  let times = schedules[i].times;
                  let diff_week = differenceInCalendarDays(day, from);

                  //if (())
                  if (schedules[i].rep_end === "times"){
                    let total = times * cycle_num;
                    let maxdate = addDays(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if ((diff_week%cycle_num)===0){

                        days = pushSchedule(day, days, schedules[i]);
                        


                      }
                    }
                  }else{
                    if ((diff_week%cycle_num)===0){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                  }
                }else if (schedules[i].rep_cycle === "weekly_weekday"){
                  let cycle_num = schedules[i].rep_cycleN; // 주기
                  let diff_week = differenceInCalendarWeeks(day, from);

                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){

                  if (!isWeekend(day) && ((diff_week%cycle_num)===0)){
                    days = pushSchedule(day, days, schedules[i]);
                  }
                  }
                  }else{

                    if (!isWeekend(day) && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                  }
                }else if (schedules[i].rep_cycle === "WEEKLY"){

                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_weekday = schedules[i].rep_week_day;
                  let diff_week = differenceInCalendarWeeks(day, from);
                  let letterDays = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"];

                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){
                  
                    if (letterDays[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                    }
                  }else{
                    if (letterDays[getDay(day)]===rep_weekday && ((diff_week%cycle_num)===0)){
                      //console.log("매주 반복 나오셈", pushSchedule(day, days, schedules[i]));
                      days = pushSchedule(day, days, schedules[i]);

                    }
                  }

                }else if (schedules[i].rep_cycle === "MONTHLY"){
                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_type = schedules[i].rep_type;
                  let rep_month_type = schedules[i].rep_month_type;
                  let diff_week = differenceInCalendarMonths(day, from);
                  
                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addMonths(from, total);
                    if (check.getTime() < maxdate.getTime()){
                    
                      if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                        days = pushSchedule(day, days, schedules[i]);
                      }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                        days = pushSchedule(day, days, schedules[i]);
                      }
                    }
                  }else{
                    if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }
                  }
                }else if (schedules[i].rep_cycle === "YEARLY"){
                  let cycle_num = schedules[i].rep_cycleN;
                  let rep_type = schedules[i].rep_type;
                  let rep_month_type = schedules[i].rep_month_type;
                  let diff_week = differenceInCalendarYears(day, from);
                  
                  if (schedules[i].rep_end === "times"){
                    let times = schedules[i].times;
                    let total = times * cycle_num;
                    let maxdate = addYears(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if (rep_month_type === "date" && c[2]== rep_type.split("-")[1] && c[1] ==  rep_type.split("-")[0] && ((diff_week%cycle_num)===0)){
                        days = pushSchedule(day, days, schedules[i]);
                      }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                        let nth = rep_type.split("-");
                        var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                        d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                        date = d.getDate(),
                        getday  = d.getDay(),
                        n    = Math.ceil(date / 7);
                        if (nth[0]==n && nth[1] == days_en[getday]){
                          days = pushSchedule(day, days, schedules[i]);
                        }
                      }
                    }
                  }else{

                    if (rep_month_type === "date" && parseInt(c[2])=== parseInt(rep_type.split("-")[1]) && parseInt(c[1]) ===  parseInt(rep_type.split("-")[0]) && ((diff_week%cycle_num)===0)){
                      days = pushSchedule(day, days, schedules[i]);
                    }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                      let nth = rep_type.split("-");
                      var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                      d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                      date = d.getDate(),
                      getday  = d.getDay(),
                      n    = Math.ceil(date / 7);
                      if (nth[0]==n && nth[1] == days_en[getday]){
                        days = pushSchedule(day, days, schedules[i]);
                      }
                    }
                  }
                }
              }else{
                
                days = pushSchedule(day, days, schedules[i]);
              }
              type = (
                <div
                  className={schedule_info.type}
                  style={{ color: `${schedule_info.color}` }}
                >
                  <BsFillSquareFill />
                </div>
              );

              const cloneDay = day;
              
             
             
              }
            
          }
        }

        
        
        day = addDays(day, 1);
      
      
     
    }
    return days;


  }

  */
  const daySchedule = () => {
    const monthStart = startOfMonth(currentDate);
    const values = ["일", "월", "화", "수", "목", "금", "토"];
    let day = [];
    let startDate = startOfWeek(currentDate);
    
    for (let i = 8; i < 24; i++) {
      let temp=[];
      
      for (let j = 0; j < schedules.length; j++) {
        if ((CheckList.includes(schedules[j].calendar_no) && (schedules[j].allday === 0))) {
          //console.log("야");
          let d1 = moment(schedules[j].start_date).format("YYYY-MM-DD").split("-");
          let c = selectedDateText.split("-");
          let d2 = moment(schedules[j].end_date).format("YYYY-MM-DD").split("-");

          let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
          let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
          let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

          if (check >= from && check <= to) {
            let compare_hour =i;
            if (i < 10){
              compare_hour = "0"+i;
            }
            if (compare_hour === schedules[j].start_hour){
              
              if (schedules[j].is_repeat === 1) {
                if (schedules[j].rep_cycle === "DAILY") {
                  let cycle_num = schedules[j].rep_cycleN;
                  let times = schedules[j].times;
                  let diff_week = differenceInCalendarDays(check, from);
                  //if (())
                  if (schedules[j].rep_end === "times"){
                    let total = times * cycle_num;
                    let maxdate = addDays(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if ((diff_week%cycle_num)===0){
    
                        temp.push(schedules[j]);
                        
    
    
                      }
                    }
                  }else{
                    if ((diff_week%cycle_num)===0){
                      temp.push(schedules[j]);
                    }
                  }
                }else if (schedules[j].rep_cycle === "weekly_weekday"){
                  let cycle_num = schedules[j].rep_cycleN; // 주기
                  let diff_week = differenceInCalendarWeeks(check, from);
    
                  if (schedules[j].rep_end === "times"){
                    let times = schedules[j].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){
    
                  if (!isWeekend(check) && ((diff_week%cycle_num)===0)){
                    temp.push(schedules[j]);
                  }
                  }
                  }else{
    
                    if (!isWeekend(check) && ((diff_week%cycle_num)===0)){
                      temp.push(schedules[j]);
                    }
                  }
                }else if (schedules[j].rep_cycle === "WEEKLY"){
    
                  let cycle_num = schedules[j].rep_cycleN;
                  let rep_weekday = schedules[j].rep_week_day;
                  let diff_week = differenceInCalendarWeeks(check, from);
                  let letterDays = ["sun", "mon", "tue", "wed", "thr", "fri", "sat"];
    
                  if (schedules[j].rep_end === "times"){
                    let times = schedules[j].times;
                    let total = times * cycle_num;
                    let maxdate = addWeeks(from, total);
                    if (check.getTime() < maxdate.getTime()){
                  
                    if (letterDays[getDay(check)]===rep_weekday && ((diff_week%cycle_num)===0)){
                      temp.push(schedules[j]);
                    }
                    }
                  }else{
                    if (letterDays[getDay(check)]===rep_weekday && ((diff_week%cycle_num)===0)){
                      //console.log("매주 반복 나오셈", pushSchedule(day, days, schedules[i]));
                      temp.push(schedules[j]);
    
                    }
                  }
    
                }else if (schedules[j].rep_cycle === "MONTHLY"){
                  let cycle_num = schedules[j].rep_cycleN;
                  let rep_type = schedules[j].rep_type;
                  let rep_month_type = schedules[j].rep_month_type;
                  let diff_week = differenceInCalendarMonths(check, from);
                  
                  if (schedules[j].rep_end === "times"){
                    let times = schedules[j].times;
                    let total = times * cycle_num;
                    let maxdate = addMonths(from, total);
                    if (check.getTime() < maxdate.getTime()){
                    
                      if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                        temp.push(schedules[j]);
                      }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                        temp.push(schedules[j]);
                      }
                    }
                  }else{
                    if (rep_month_type === "date" && c[2]== rep_type && ((diff_week%cycle_num)===0)){
                      temp.push(schedules[j]);
                    }else if (rep_month_type === "nthday" && ((diff_week%cycle_num)===0)){
                      temp.push(schedules[j]);
                    }
                  }
                }else if (schedules[j].rep_cycle === "YEARLY"){
                  let cycle_num = schedules[j].rep_cycleN;
                  let rep_type = schedules[j].rep_type;
                  let rep_month_type = schedules[j].rep_month_type;
                  let diff_week = differenceInCalendarYears(check, from);
                  
                  if (schedules[j].rep_end === "times"){
                    let times = schedules[j].times;
                    let total = times * cycle_num;
                    let maxdate = addYears(from, total);
                    if (check.getTime() < maxdate.getTime()){
                      if (rep_month_type === "date" && parseInt(c[2])== rep_type.split("-")[1] && parseInt(c[1]) ==  rep_type.split("-")[0] && ((diff_week%cycle_num)===0)){
                        temp.push(schedules[j]);
                      }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                        let nth = rep_type.split("-");
                        var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                        d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                        date = d.getDate(),
                        getday  = d.getDay(),
                        n    = Math.ceil(date / 7);
                        if (nth[0]==n && nth[1] == days_en[getday]){
                          temp.push(schedules[j]);
                        }
                      }
                    }
                  }else{
    
                    if (rep_month_type === "date" && parseInt(c[2])===parseInt(rep_type.split("-")[1]) && parseInt(c[1]) === parseInt(rep_type.split("-")[0]) && ((diff_week%cycle_num)===0)){
                      temp.push(schedules[j]);
                    }else if (rep_month_type === "nthday" && c[1] ==  d1[1] && ((diff_week%cycle_num)===0)){
                      let nth = rep_type.split("-");
                      var days_en = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
                      d    = day ? day instanceof Date ? day : new Date(day) : new Date(),
                      date = d.getDate(),
                      getday  = d.getDay(),
                      n    = Math.ceil(date / 7);
                      if (nth[0]==n && nth[1] == days_en[getday]){
                        temp.push(schedules[j]);
                      }
                    }
                  }
                }
              }else{
                temp.push(schedules[j]);
              }
            }else{

            }
          }
        }

        var leave_temp = [];
        if (CheckList.includes("0")) {
          for (let i = 0; i < leave.length; i++) {
 
           
            let d1 = moment(leave[i].start_date).format("YYYY-MM-DD").split("-");
            let c = selectedDateText.split("-");
            let d2 = moment(leave[i].end_date).format("YYYY-MM-DD").split("-");
 
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
            var weekend = isWeekend(check);
           
            if (weekend === false) {
              if (check >= from && check <= to) {
                let vtype;
                if (leave[i].vtype === "1") {
                  vtype = "연차";
                }
                if (leave[i].vtype === "0.5") {
 
                  if (leave[i].vrange == "2"){
                    vtype = "오후 반차";
 
                  }else{
                    vtype = "오전 반차";
                  }
                 
                }
                if (leave[i].vtype === "0.25") {
                  vtype = "반반차";
                }
                if (leave[i].vtype === "0") {
                  vtype = "공가";
                }
           
 
                leave_temp.push(
                  leave[i]
                );
 
              } else {
              }
            }
          }
        }




      }

      
      day.push(

        
        <tr className={`column cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}>
          <td class="tg-0lax">{i}:00</td>
          <td class="tg-0lax">
          
          {temp.map((element, index)=>
          
          
          <Link  to="/schedule_v" state={{schedule: element}}><li><div
                  className={element.vtype}
                  style={{ color: `${element.color}`, display:"flex", alignItems:"center" }}
                >
                  <BsFillSquareFill style={{marginRight:"5px"}}/>
                </div>{element.subject}
              
                
                </li></Link>)} 
            
          
               
                
          </td>
          
        </tr>
      )
    }
    return day;
  };


  const nextMonth = () => {
    let monthstart = getMonth(addMonths(currentDate, 1)) + 1;
    if (monthstart < 10) {
      monthstart = "0" + monthstart;
    }
    let currentyear = getYear(addMonths(currentDate, 1));
    let value = currentyear + "-" + monthstart;
    axios
      .get("https://gw.thegmmedical.com:5003/api/getleaves/" + value)
      .then((response) => {


        console.log("getleaves....", response.data);
       
        let leave_temp=[];
        for (let j = 0; j < response.data.length; j++) {
          console.log("dddd1111......", moment(response.data[j].start_date).format("YYYY-MM-DD"));
            let d1 = moment(response.data[j].start_date).format("YYYY-MM-DD").split("-");
            let c = format(addDays(selectedDate, 1), "yyyy-MM-dd").split("-");
            let d2 = moment(response.data[j].end_date).format("YYYY-MM-DD").split("-");
           
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
 
           
            if (check >= from && check <= to) {
             
              leave_temp.push(response.data[j]);
             
           
          }
        }
        setLeaveList(leave_temp);
        setLeaves(response.data);
        //setSchedule(response.data);
        setCurrentDate(addMonths(currentDate, 1));


      });
  };
  const prevMonth = () => {


    let monthstart = getMonth(subMonths(currentDate, 1)) + 1;
    if (monthstart < 10) {
      monthstart = "0" + monthstart;
    }
    let currentyear = getYear(subMonths(currentDate, 1));
    let value = currentyear + "-" + monthstart;
    axios
      .get("https://gw.thegmmedical.com:5003/api/getleaves/" + value)
      .then((response) => {


        console.log("getleaves....", response.data);
       
        let leave_temp=[];
        for (let j = 0; j < response.data.length; j++) {
          console.log("dddd1111......", moment(response.data[j].start_date).format("YYYY-MM-DD"));
            let d1 = moment(response.data[j].start_date).format("YYYY-MM-DD").split("-");
            let c = format(addDays(selectedDate, 1), "yyyy-MM-dd").split("-");
            let d2 = moment(response.data[j].end_date).format("YYYY-MM-DD").split("-");
           
            let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
            let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);
 
           
            if (check >= from && check <= to) {
             
              leave_temp.push(response.data[j]);
             
           
          }
        }
        setLeaveList(leave_temp);
        setLeaves(response.data);
        //setSchedule(response.data);
        setCurrentDate(subMonths(currentDate, 1));


      });
  };




  const onDateClick = (day) => {
    setSelectedDate(day);
  };

  const selectHandle = (e) => {
    console.log("changed", e.target.value);

    setOption(e.target.value);
  };

  const addBookmarkPage = () => {
    var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
    if(result){

      const data ={
        name: '',
        num : '',
        link : window.location.pathname,
        bookmark : "일정 관리"
      }
      
      console.log(window.location.pathname)
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
      .then(function (response){
        console.log(response);
        alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };

  return (
    <div className="wrapper">
{showPopup ? (
 <LeaveList date={checkDate} closePopup={() => togglePopup()} />
): null}
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <ScheduleList/>
        </div>
      )}


      <div className="content-wrap">
        <div className="outer_wrapper" style={{ justifyContent: "flex-start" }}>
          <div className="side-menu">
          <ScheduleList
            onChange={(value) => {
              //console.log("어", value);
              setCheckList(value);
            }}
          />
          </div>

<div className="schedule main_content">

<div className="btns" style={{display:"flex",flexDirection:"row",justifyContent:"start", alignItems:"center"}}>

            
<div className="btn" style={{display:"flex", alignItem:"center", height:"15px",padding:"5px", marginRight:"7px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>

<h3>일정 관리</h3>


</div>

            <div className="board_top">
           


              <div
                className="btns"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="left">
                  <button className="btn" onClick={()=>{setCalendar("daily");setPrevCalendar("daily");setIsSearch(false);}}>일간</button>
                  <button className="btn" onClick={()=>{setCalendar("weekly");setPrevCalendar("weekly");setIsSearch(false);}}>주간</button>
                  <button className="btn" onClick={()=>{setCalendar("monthly");setPrevCalendar("monthly");setIsSearch(false);}}>월간</button>
                  <button className="btn" onClick={()=>{setCalendar("list");setPrevCalendar("list");setIsSearch(false);}}>목록</button>
                </div>

                <div className="right">

                  
                  <div className="bt_srch" style={{width:"100%"}}>
                    {isSearch === true && (
                      <button onClick={()=>{setIsSearch(false);setCalendar(prevCalendar)}}>이전으로 돌아가기</button>
                    )}
                    
                    <div className="bts_slct">
                      <select
                        id="u_shcate"
                        name="search_item"
                        className="select"
                        title="검색항목 선택"
                        value={options}
                        onChange={selectHandle}
                      >
                        <option value="all">캘린더 선택</option>
                        {calendars.map((element,index)=>(
                          <option value={element.no}>{element.name}</option>
                          ))}
                        {teamCalendars.map((element,index)=>(
                          <option value={element.no}>{element.name}</option>
                        ))}
                        {userCalendars.map((element,index)=>(
                          <option value={element.no}>{element.name}</option>
                        ))}
                        {groupCalendars.map((element,index)=>(
                          <option value={element.no}>{element.name}</option>
                        ))}
                      </select>
                    </div>
                  
                    <div className="bt_input">
                      <input
                        type="text"
                        id="search_content"
                        name="search_content"
                        value={search || ""}
                        placeholder="검색어를 입력하세요"
                        onChange={onChangeSearch}
                        style={{width:"180px"}}
                      />
                    </div>

                    <button
                      type="submit"
                      className="search_btn"
                      value="검색"
                      onClick={onSearch}
                    >
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isSearch ===false && calendar ==="monthly" && (
               <div className="calendar">
              <div>{header()}</div>
              <div>{days()}</div>
              <div>{cells()}</div>
            </div>
            )}
           
           



{calendar ==="weekly" && (

  <div className="calendar">

<div className="header row flex-middle">
        <div className="column col-start">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            <MdArrowBackIos />
          </div>
        </div>
        <div className="column col-center" style={{ textAlign: "center" }}>
          <span>{currentWeekText}  - [{currentWeek}주]</span>
        </div>
        <div className="column col-end" style={{ textAlign: "right" }}>
          <div className="icon" onClick={() => changeWeekHandle("next")}>
            <MdArrowForwardIos />
          </div>
        </div>
      </div>



  <table class="tg">
<thead>
  <tr>
    <th class="tg-0lax">일자</th>
    <th class="tg-0lax">내용</th>
  </tr>
</thead>
<tbody>
  <tr>

  </tr>
  {weekdays()}
</tbody>
</table>

</div>
)}

{calendar ==="daily" && (

<div className="calendar">

<div className="header row flex-middle">
        <div className="column col-start">
          <div className="icon" onClick={() => changeDayHandle("prev")}>
            <MdArrowBackIos />
          </div>
        </div>
        <div className="column col-center" style={{ textAlign: "center" }}>
          <span><input type="date" value={selectedDateText} onChange={onChangeDay}></input></span>
        </div>
        <div className="column col-end" style={{ textAlign: "right" }}>
          <div className="icon" onClick={() => changeDayHandle("next")}>
            <MdArrowForwardIos />
          </div>
        </div>
      </div>



  <table class="tg">
<thead>
  <tr>
    <th class="tg-0lax">시간</th>
    <th class="tg-0lax">내용</th>
  </tr>
</thead>
<tbody>
{timeNotSpecified()}
  <tr style={{backgroundColor:"#f7f7f7"}}>
  {/*<td class="tg-0lax">0:00 <br></br>~<br></br> 8:00</td>*/}
  <td class="tg-0lax">연차</td>
  <td class="tg-0lax">
  {leaveSchedule()}
  </td>  
  </tr>
  {daySchedule()}

</tbody>
</table>

</div>


)}


{calendar ==="list" && (

<div className="calendar">

<div className="header row flex-middle">
        <div className="column col-start">
          <div className="icon" onClick={() => changeDayHandle("prev")}>
            <MdArrowBackIos />
          </div>
        </div>
        <div className="column col-center" style={{ textAlign: "center" }}>
          <span style={{display:"flex", justifyContent:"center"}}>
            <input type="date" value={start || ''} onChange={onChangeStart}/>~
          <input type="date" value={end || ''} onChange={onChangeEnd}/>

        
          <button className="btn" onClick={()=>searchPeriod()} style={{background:"#fff", border:"1px solid #ddd"}}>검색</button></span>
        </div>
        <div className="column col-end" style={{ textAlign: "right" }}>
          <div className="icon" onClick={() => changeDayHandle("next")}>
            <MdArrowForwardIos />
          </div>
        </div>
      </div>



  <table class="tg">
<thead>
  <tr>
    <th class="tg-0lax">일자</th>
    <th class="tg-0lax">시간</th>
    <th class="tg-0lax">캘린더</th>
    <th class="tg-0lax">내용</th>
  </tr>
</thead>
<tbody>

  {listSchedule()}

</tbody>
</table>

</div>


)}


{isSearch ===true && (
            <table class="table table-hover bd-b">
              <thead class="thead-dark">
                <tr class="tx-center">
                  <th class="wd-80 xl-hidden">번호</th>
                  <th class="wd-80 xl-hidden">캘린더</th>
                  <th class="">제목</th>
                  <th class="wd-150 xl-hidden">작성자</th>
                  <th class="wd-120 xl-hidden">작성일</th>
                  <th class="wd-80 xl-hidden">시작일</th>
                  <th class="wd-80 xl-hidden">종료일</th>
                  
                </tr>
              </thead>
              <tbody>
                {filterData.length===0 && (
                  <tr style={{ textAlign: "center" }}>
                    <td colSpan={5} style={{ padding: "50px 0" }}>
                      게시물이 없습니다.
                    </td>
                  </tr>
                )}
                {filterData.slice(offset, offset + limit).map(
                  ({
                    s_id, user_no, subject, content, create_date, start_date, end_date, name
                  }) =>
                      <tr key={s_id}>
                        <td>{s_id}</td>
                        <td>{name}</td>
                        <td>
                          
                            {subject}
                        </td>
                        <td>{user_no}</td>
                        <td>{create_date}</td>
                        <td>{start_date}</td>
                        <td>{end_date}</td>
                      </tr>
                    
                )}
              </tbody>
            </table>
            )}



          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
