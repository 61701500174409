import Dropdown from './Dropdown';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';


const MenuItems = ({ items }) => {
  const [folder, setFolder]= useState("all");


  useEffect(()=>{
    axios.get('https://gw.thegmmedical.com:5003/api/get_default_mail')
    .then(function (response){
      console.log("default setting,,,,,,", response.data[0]);
     
      if (response.data.length>0){


        setFolder(response.data[0].start_box);
       
      }
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  },[])


  return (
    <li className="menu-items">
      {items.submenu ? (
        <>
          <button type="button" aria-haspopup="menu">
            {items.title}{' '}
          </button>
          <Dropdown submenus={items.submenu} />
        </>
      ) : (
        items.title === "전자메일" ?(
           <Link to={"/list/"+folder} state={{type:""}}>{items.title}</Link>
        ) : (
          <Link to={items.url} state={{type:""}}>{items.title}</Link>


        )
         
      )}
    </li>
  );
};


export default MenuItems;