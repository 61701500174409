
import { NavLink, Link, useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import BoardList from "../components/side/BoardList";
import axios from "axios";
import moment from "moment";

import "./css/board.css";
import {Cookies} from "react-cookie";
import { HiPencilAlt } from "react-icons/hi";
import { BsPin } from "react-icons/bs";
import {GiHamburgerMenu} from "react-icons/gi";
import Pagination from "./Pagination";
//import hi from '../excel/93.json';
import Modal from "../components/Modal";

const cookies = new Cookies();

var currentData=[];
const Board = () => {
    const [data, setData] = useState([]);
    var { no } = useParams();

    const [history, setHistory] = useState(false);

    const [isActive, setActive] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [parent, setParent] = useState("");
    const [name, setName] =useState("");
    const [num, setNum] = useState(0);
    const [lev, setLev] = useState(0);
    const [selected, setSelected]= useState();
    const [search, setSearch] = useState(null);
    const [boardN, setBoardN] = useState("");
    const [bgNo, setBgNo]=useState(no);
    const [bgName, setBgName]= useState();
    const [boardNum, setBoardNum] = useState();
    const [boardType, setBoardType] = useState("");
    const [options, setOption] = useState("subject");
    const [filterData, setfilterData] = useState([]);
    const [boardGroup, setBoardGroup] = useState([]);
    const [teamBoard, setTeamBoard] = useState([]);
    const [selectedBoard, setSelectedBoard] = useState("");
    const [notice, setNotice] = useState([]);
    const [isEmpty, setEmpty] = useState(false);
    const [isPage, setIsPage] = useState(false);
    const [isloggedIn, setLoggedIn] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [noticeContent, setNoticeContent] = useState([]);
    const offset = (page - 1) * limit;
    const { state } = useLocation();
    const [modalOpen, setModalOpen] = useState(true);
    const [popUpContent, setPopUpContent] = useState([]);
    const [hasCookie, setHasCookie] = useState(true);
    const [main, setMain] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [boardAdmin, setBoardAdmin] = useState();
    const [user, setUser] = useState([]);

    
    const openModal = () => {
      setModalOpen(true);
    };
    const closeModal = () => {
      setModalOpen(false);
    };


    const removePopup = () => {
      const data ={
          viewers : popUpContent.viewers,
          b_no : popUpContent.b_no,
          no : popUpContent.no
      }
      axios.post('https://gw.thegmmedical.com:5003/api/remove_popup', data)
      .then(function (response){

        console.log(response);
        setModalOpen(false);

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
  }
    const toggleText = () => {
      setActive(!isActive);
    };
    const onChangeSearch = (e) => {
      e.preventDefault();
      setSearch(e.target.value);
    };
    const setCookie = (name, value, options) => {
      return cookies.set(name, value, {...options}); 
   }
   const getCookie = (name) => {
    return cookies.get(name); 
   }
    const getExpiredPopUpDate = (days) => {
      const date = new Date();
      date.setDate(date.getDate() + days); // 일
      return date;
    };
  
    const closeModalUntilExpires = () => {
      // if (!appCookies) return;
      const expires = getExpiredPopUpDate(1); // 1일
      setCookie('MODAL_EXPIRES', true, { path: '/', expires });
      setCookie('ModalNum', popUpContent.no, { path: '/', expires });

      setModalOpen(false);
    };
    
    useEffect(() => {
      
      axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
    
      setUser(response.data[0]);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

      axios.get("https://gw.thegmmedical.com:5003/api/get_popup").then((response) => {
      console.log("get_popup.....", response.data);
      if (response.data.length>0){
        setPopUpContent(response.data[0]);
        
      if (getCookie('MODAL_EXPIRES') && getCookie('ModalNum') === response.data[0].no) {
        setHasCookie(true);
      }else{
        setHasCookie(false);
      }
      console.log(getCookie('MODAL_EXPIRES'));
      }
        
        
      });

      axios.get("https://gw.thegmmedical.com:5003/api/get_modal_notice").then((response) => {
        setNoticeContent(response.data[0]);
        
      });

 
     
      

axios.get('https://gw.thegmmedical.com:5003/api/getboardgroup',{})
.then(function (response){

  
setBoardGroup(response.data.filter(item=>item.bg_type ==="board"));

})
.catch(function (error){
  console.log(error);
})
.then(function (){
//always executed
});
axios.get('https://gw.thegmmedical.com:5003/api/get_teamboard',{
      
})
.then(function (response){

  setTeamBoard(response.data);
    
})
.catch(function (error){
console.log(error);
})
.then(function (){
//always executed
});


axios.post("https://gw.thegmmedical.com:5003/api/getboard",{
  data: {
    // 서버에서 req.body.{} 로 확인할 수 있다.
    bg_no: no
  },
  }).then((response) => {
    console.log("getboard", response.data);
    
    setfilterData(response.data)
    setData(response.data);
    //setNotice(response.data);
    //navigate("/board", {state: { boardName : name}});
    
  });


  axios.post('https://gw.thegmmedical.com:5003/api/get_boardadmin', {
    data: {
      // 서버에서 req.body.{} 로 확인할 수 있다.
      bg_no : no
    },
  })
  .then(function (response){
    console.log("admin is", response.data);
    if (response.data[0]!==undefined){
      if (response.data[0].board_type !== null){
        setBoardType(response.data[0].board_type);
      }
    }
   
    let adms = response.data;
    adms = adms.map((ele,idx)=>{return ele.admin_email});
    console.log("adms", adms);
    setAdmins(adms);
  })
  .catch(function (error){
  console.log(error);
  })
  .then(function (){
  //always executed
  });


/*
if (state !==null){
  if(state.boardName!==undefined) {
  currentData = state;
  setBoardN(state.boardName);

  console.log("아놔", currentData);
  axios.post("https://gw.thegmmedical.com:5003/api/getboard",{
    data: {
      // 서버에서 req.body.{} 로 확인할 수 있다.
      bg_name: currentData.boardName
    },
    }).then((response) => {
      console.log("getboard", response.data);
      
      if (state.data!==undefined){
        setfilterData(state.data);
        setData(state.data);
      }else{
        setfilterData(response.data)
        setData(response.data);
      }
      //setNotice(response.data);
      //navigate("/board", {state: { boardName : name}});
      
    });




  }else{
    axios.get("https://gw.thegmmedical.com:5003/api/news").then((response) => {
      setfilterData(response.data);
      setData(response.data);
      setMain(true);
      //setNotice(response.data);
      //navigate("/board", {state: { boardName : name}});
      
    });
  }




  axios.post('https://gw.thegmmedical.com:5003/api/get_boardadmin', {
    data: {
      // 서버에서 req.body.{} 로 확인할 수 있다.
      bg_no : state.bg_no
    },
  })
  .then(function (response){
    console.log("admin is", response.data);
    if (response.data[0]!==undefined){
      if (response.data[0].board_type !== null){
        setBoardType(response.data[0].board_type);
      }
    }
   
    let adms = response.data;
    adms = adms.map((ele,idx)=>{return ele.admin_email});
    console.log("adms", adms);
    setAdmins(adms);
  })
  .catch(function (error){
  console.log(error);
  })
  .then(function (){
  //always executed
  });






}else{
  axios.get("https://gw.thegmmedical.com:5003/api/news").then((response) => {
      setfilterData(response.data);
      setData(response.data);
      setMain(true);
      //setNotice(response.data);
      //navigate("/board", {state: { boardName : name}});
      
    });
}
*/



axios.get('https://gw.thegmmedical.com:5003/api/check_boardadmin')
      .then(function (response){
        console.log("user is", response.data);
        if (response.data.length>0){
          setBoardAdmin(true);
        }
        /*var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
        setname(name);
        setUserNo(response.data[0].user_no);*/
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });



    }, []);


   



  const deleteDepartment = () => {
    const data ={
      name : name,
      parent : parent,
      num : num,
      level : lev
    }
    axios.post('https://gw.thegmmedical.com:5003/api/delete_department', data)
    .then(function (response){
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };


  const assign = () => {
    if (selected.name ===undefined){
    }
    var array = []
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value!=="selectall"){
      array.push(checkboxes[i].value)
      }
    }
    if (array[0].value==="selectall"){
      array.shift();
    }
    const data ={
      nos : array,
      folderName : selected.name,
      folderNo : selected.num
    }
    
    var result = window.confirm("선택된 사용자를 [" + selected.name+"] 부서에 지정합니다.");
    if(result){
      axios.post('https://gw.thegmmedical.com:5003/api/assign', data)
    .then(function (response){
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }else{
    }
    
  };
  const onNameClick = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();
    var notice_data=[];
    var board_data=[];
    const {
      // internal data
      path, name, num, level, type, isOpen, children
      // custom data
    } = nodeData;
    setName(name);
      setNum(num);
    if (type!=="group"){
      var dropdown = document.getElementsByClassName('dropdown_div');
      var aTags = dropdown[0].getElementsByTagName("span");
      
var searchText;
var found;

        searchText=name;
      
for (var i = 0; i < aTags.length; i++) {
  if (aTags[i].textContent === searchText) {
    found = aTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('.editableNameContainer');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';
    console.log(name);
      
    

    }
    
};
  const addBookmark = () => {
    var result = window.confirm("즐겨찾기 게시판에 추가하시겠습니까?");
    if(result){
      const data ={
        name: boardN,
        num : boardNum,
        table : "board"
      }
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark', data)
    .then(function (response){
      console.log(response);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  };
  const addBookmarkPage = () => {
    var result = window.confirm("메뉴 바로가기에 추가하시겠습니까?");
    if(result){
      const data ={
        name: boardN,
        num : boardNum,
        link : window.location.pathname,
        bookmark : boardN + " 게시판"
      }
      console.log(window.location.pathname)
      console.log(data);
      axios.post('https://gw.thegmmedical.com:5003/api/add_bookmark_page', data)
      .then(function (response){
        console.log(response);
        alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  };
  const selectHandle = (e) => {
    console.log("changed", e.target.value);

    setOption(e.target.value);
  };

  const selectBoard = (e) => {

    setSelectedBoard(e.target.value);
  };
  const onSearch = (e) => {
    e.preventDefault();

    let temp = data;
    if (history === false){
      temp.filter((item)=>(item.end_date >= moment(new Date()).format("YYYY-MM-DD") || item.end_date==="" || item.end_date ===null ));
    }else{
      temp.filter((item)=>(item.end_date < moment(new Date()).format("YYYY-MM-DD") && item.end_date!=="" && item.end_date !==null ));

    }
   
    if (options === "subject") {
      const filtered = temp.filter((itemList) => {
        return itemList.subject.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    } else if (options === "all"){
      
        
      const filtered = temp.filter((itemList) => {
        var tmp = "";
        tmp+=itemList.content;
        tmp+=itemList.subject;
        return tmp.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }else if (options === "content") {
      const filtered = temp.filter((itemList) => {
        return itemList.content.toUpperCase().includes(search.toUpperCase());
      });
      setfilterData(filtered);
      if (filtered.length === 0) {
        setEmpty(true);
        setIsPage(false);
      } else {
        setEmpty(false);
        if (filtered.length <= 10) {
          setIsPage(false);
        } else {
          setIsPage(true);
        }
      }
    }
  };
  const handleShowNavbar = () => {
    //setShowNavbar(!showNavbar)
  }
  return (
    <div className="wrapper">
    {(popUpContent.length !==0 && !hasCookie) && (
      <Modal open={modalOpen} close={closeModal} remove={removePopup} modalData = {popUpContent} closeModalUntilExpires={closeModalUntilExpires}>
      {/*<span dangerouslySetInnerHTML={{ __html: popUpContent.content }}></span>*/}
      </Modal>
    )}
      
    


<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} 

setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      
        <div className="side-mobile-menu" style={{display: sidebar ? 'block' : 'none' }}>
          {bgNo!==undefined && (
            <BoardList changeBoard={(value)=>{
  console.log("boardvalue,,,", value);
    setfilterData(value);setData(value);setMain(false);
  
  
        if (value.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
          if (value.length <= 10) {
            setIsPage(false);
          } else {
            setIsPage(true);
          }
        }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{setBoardN(value);setBgName(value);}} 
        changeBoardNum={(value)=>{
          setBoardNum(value);setSidebar(false);
          axios.post('https://gw.thegmmedical.com:5003/api/get_boardadmin', {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              bg_no : value
            },
          })
          .then(function (response){
            console.log("admin is", response.data);
            if (response.data[0].board_type !== null){
            setBoardType(response.data[0].board_type);
            }
            let adms = response.data;
            adms = adms.map((ele,idx)=>{return ele.admin_email});
            console.log("adms", adms);
            setAdmins(adms);
          })
          .catch(function (error){
          console.log(error);
          })
          .then(function (){
          //always executed
          });
        }}
        
        boardN={bgNo} boardName={bgName}
        
        />
          )}

            </div>
      
     <div className="content-wrap">
     
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>
        {/*<div className="menu-icon" onClick={handleShowNavbar}>
          <GiHamburgerMenu/>
        </div>*/}
        <div className="side-menu">

          {bgNo !==undefined && (
 <BoardList changeBoard={(value)=>{setfilterData(value);setData(value);setMain(false);
  if (value.length === 0) {
    setEmpty(true);
  } else {
    setEmpty(false);
    if (value.length <= 10) {
      setIsPage(false);
    } else {
      setIsPage(true);
    }
  }}} changeNotice={(value)=>{setNotice(value);}} changeBoardN={(value)=>{console.log("boardN", value);setBoardN(value);setBgName(value);}} 
  changeBoardNum={(value)=>{
    console.log("boardNum", value);setBoardNum(value);
    axios.post('https://gw.thegmmedical.com:5003/api/get_boardadmin', {
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        bg_no : value
      },
    })
    .then(function (response){
      console.log("admin is", response.data);
      if (response.data[0].board_type !== null){
      setBoardType(response.data[0].board_type);
      }
      let adms = response.data;
      adms = adms.map((ele,idx)=>{return ele.admin_email});
      console.log("adms", adms);
      setAdmins(adms);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

  }}  boardN={bgNo} boardName={bgName}/>
          )}
       
    
    </div>
        <div className="main_content">
        
        <div className="board_wrapper">
          <form name="form1" id="form1" method="post">
          <div className="board_container">
            <div id="content" className="content">
                          
              <div className="board_top">
              <div className="btns">

            
<div className="btn" style={{marginRight:"10px"}} onClick={()=>addBookmark()}>즐겨찾기 추가</div>
<div className="btn" style={{display:"flex", alignItem:"center", padding:"5px"}}  onClick={()=>addBookmarkPage()}><BsPin/></div>
{history === false ? <div className="btn" style={{display:"flex", alignItem:"center", padding:"5px", marginLeft:"10px"}}  onClick={()=>setHistory(true)}>지난자료 보기</div>

:<div className="btn" style={{display:"flex", alignItem:"center", padding:"5px"}}  onClick={()=>setHistory(false)}>이전</div>}

</div>

<div className="board_header">{bgName!==undefined && (bgName)}</div>
                              {/*<label>
                            페이지 당 게시물 수:&nbsp;
                            <select
                            type="number"
                            value={limit}
                            onChange={({ target: { value } }) => setLimit(Number(value))}
                            >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </label>*/}
                             <div className="bt_srch" style={{display:"flex", justifyContent:"end"}}>
                              {(boardAdmin || admins.includes(user.email)) &&(
                            <Link to={"/menu/"+boardType+"board"} state={{bg_no : boardNum}}>
                            <div style={{background:"#708fc0", color:"white", borderRadius:"5px", height:"33px", border:"none", width:"100px",
                            textAlign:"center", lineHeight:"33px"}}>방속성</div></Link>
                         )}


                                <div className="bts_slct">
                                  <select
                                    id="u_shcate"
                                    className="select"
                                    value={options}
                                    onChange={selectHandle}
                                  >
                                    <option value="all">제목+내용</option>
                                    <option value="subject">제목</option>
                                    <option value="content">내용</option>
                                  </select>


                                </div>
                                <div className="bt_input">
                                  <input
                                    type="text"
                                    id="search_content"
                                    name="search_content"
                                    value={search || ''}
                                    placeholder="검색어를 입력하세요"
                                    onChange={onChangeSearch}
                                  />
                                </div>


                                <button
                                  type="submit"
                                  className="search_btn"
                                  value="검색"
                                  onClick={onSearch}
                                >
                                  검색
                                </button>
                              </div>



                          </div>

{history === false ? 
                          <div className="board_list">
                         
                          
                  <table class="table table-hover bd-b">
									<thead class="thead-dark">
									<tr class="tx-center">
										<th class="wd-80 xl-hidden" style={{width:"5%"}}>번호</th>
                    {main && (
                                         <th style={{width:"8%"}}>
                                        게시판
                                        </th>

                                      )}
										<th class="" style={{width:"25%"}}>제목</th>
										<th class="wd-150 xl-hidden">작성자</th>
                    <th class="wd-120 xl-hidden">분류</th>

										<th class="wd-120 xl-hidden">작성일</th>
									</tr>
									</thead>
									<tbody>
                  {filterData.filter((item)=>(item.end_date >= moment(new Date()).format("YYYY-MM-DD") || item.end_date==="" || item.end_date ===null ))
                                  .map(
                                    ({
                                      b_no,bg_name,
                                      subject,name,job_name, d_name, 
                                      date_created, category,
                                      bg_no, is_notice
                                    }) => (
                                      is_notice ===1 &&( <tr key={b_no} className="noticeboard">
                                      

                                        <td>
                                        <span>공지</span>
                                        </td>
                                        {main && (
                                         <th>
                                        <span style={{border:"1px solid #ddd", padding:"2px"}}>{bg_name}</span>
                                        </th>

                                        )}
                                        <td>
                                        <Link to={"/boardview/"+b_no} state={{boardName : bg_name, bg_no: bg_no}}>{subject}</Link>
                                        </td>
                                        <td>
                                        {name} {job_name} ({d_name})
                                        </td>

                                        <td>
                                        {category}
                                        </td>
                                        <td>
                                        {date_created}
                                        </td>
                                      
                                      </tr>)
                                     
                                    )
                                  )}
                                 
                                  
                                 {filterData.filter((item)=>(item.end_date >= moment(new Date()).format("YYYY-MM-DD") || item.end_date==="" || item.end_date ===null ))
                                  .slice(offset, offset + limit)
                                  .map(
                                    ({
                                      b_no,bg_name,bg_no,
                                      subject,name,job_name, d_name, 
                                      date_created, category,
                                      hit, is_notice
                                    }) => (
                                      is_notice !==1 &&( <tr key={b_no}>
                                      

                                        <td>
                                        {b_no}
                                        </td>
                                      
                                      {main && (
                                         <td>
                                        <span style={{border:"1px solid #ddd", padding:"2px"}}>{bg_name}</span>
                                        </td>

                                      )}
                                       
                                        <td>
                                        <Link to={"/boardview/"+b_no} state={{boardName : bg_name, bg_no: bg_no}}>{subject}</Link>
                                        </td>
                                        <td>
                                        {name} {job_name} ({d_name})
                                        </td>
                                        <td>
                                        {category}
                                        </td>

                                        <td>
                                        {date_created}
                                        </td>
                                    
                                      </tr>)
                                     
                                    )
                                  )}

									</tbody>
								</table>
                            <table>

                              <colgroup>
                                <col className="m_dp_n table_no" />
                                <col className="" />
                                <col className="ta_l" />
                                <col className="m_dp_n table_date" />
                              </colgroup>
                              {/*<thead>
                        <tr>
                            <th className="m_dp_n table_no" scope="col">NO.</th>
                            <th scope="col"></th>
                            <th className="ta_l" scope="col">제목</th>
                            <th className="m_dp_n table_date" scope="col">날짜</th>
                        </tr>
                    </thead>*/}
                              <tbody>
                               
                              </tbody>
                            </table>

                            {filterData.length===0 && (
                                  <div style={{ textAlign: "center", padding:"50px 0", width:"100%", borderBottom:"1px solid #ddd" }}>
                                    <p>게시물이 없습니다.</p>
                                  </div>
                                )}

                            <div style={{width:"100%",textAlign:"right"}}>
                    {isloggedIn && (
                      <Link to="write"
                        style={{
                          padding: "10px 30px",
                          backgroundColor: "#555",
                          color: "white",
                          textAlign: "center",
                          position:"relative",
                          display:"inline-block",
                          width:"105px",
                          cursor: "pointer",
                        }}
                      >
                        <HiPencilAlt/>글쓰기
                      </Link>
                    )}
                    </div>
                  </div>
:



<div className="board_list">
                         
                          
<table class="table table-hover bd-b">
<thead class="thead-dark">
<tr class="tx-center">
  <th class="wd-80 xl-hidden" style={{width:"5%"}}>번호</th>
  {main && (
                       <th style={{width:"8%"}}>
                      게시판
                      </th>

                    )}
  <th class="" style={{width:"25%"}}>제목</th>
  <th class="wd-150 xl-hidden">작성자</th>
  <th class="wd-120 xl-hidden">분류</th>

  <th class="wd-120 xl-hidden">작성일</th>
</tr>
</thead>
<tbody>
{filterData.filter((item)=>(item.end_date !=="" && item.end_date !== null && item.end_date < moment(new Date()).format("YYYY-MM-DD")))
                .map(
                  ({
                    b_no,bg_name,
                    subject,name,job_name, d_name, 
                    date_created, category,
                    bg_no, is_notice
                  }) => (
                    is_notice ===1 &&( <tr key={b_no} className="noticeboard">
                    

                      <td>
                      <span>공지</span>
                      </td>
                      {main && (
                       <th>
                      <span style={{border:"1px solid #ddd", padding:"2px"}}>{bg_name}</span>
                      </th>

                      )}
                      <td>
                      <Link to={"/boardview/"+b_no} state={{boardName : bg_name, bg_no: bg_no}}>{subject}</Link>
                      </td>
                      <td>
                      {name} {job_name} ({d_name})
                      </td>

                      <td>
                      {category}
                      </td>
                      <td>
                      {date_created}
                      </td>
                    
                    </tr>)
                   
                  )
                )}
               
                
               {filterData.filter((item)=>(item.end_date !=="" && item.end_date !== null && item.end_date < moment(new Date()).format("YYYY-MM-DD")))
                .slice(offset, offset + limit)
                .map(
                  ({
                    b_no,bg_name,bg_no,
                    subject,name,job_name, d_name, 
                    date_created, category,
                    hit, is_notice
                  }) => (
                    is_notice !==1 &&( <tr key={b_no}>
                    

                      <td>
                      {b_no}
                      </td>
                    
                    {main && (
                       <td>
                      <span style={{border:"1px solid #ddd", padding:"2px"}}>{bg_name}</span>
                      </td>

                    )}
                     
                      <td>
                      <Link to={"/boardview/"+b_no} state={{boardName : bg_name, bg_no: bg_no}}>{subject}</Link>
                      </td>
                      <td>
                      {name} {job_name} ({d_name})
                      </td>
                      <td>
                      {category}
                      </td>

                      <td>
                      {date_created}
                      </td>
                  
                    </tr>)
                   
                  )
                )}

</tbody>
</table>
          <table>

            <colgroup>
              <col className="m_dp_n table_no" />
              <col className="" />
              <col className="ta_l" />
              <col className="m_dp_n table_date" />
            </colgroup>
            {/*<thead>
      <tr>
          <th className="m_dp_n table_no" scope="col">NO.</th>
          <th scope="col"></th>
          <th className="ta_l" scope="col">제목</th>
          <th className="m_dp_n table_date" scope="col">날짜</th>
      </tr>
  </thead>*/}
            <tbody>
             
            </tbody>
          </table>

          {filterData.length===0 && (
                <div style={{ textAlign: "center", padding:"50px 0", width:"100%", borderBottom:"1px solid #ddd" }}>
                  <p>게시물이 없습니다.</p>
                </div>
              )}

  <div style={{width:"100%",textAlign:"right"}}>
    {isloggedIn && (
      <Link to="write"
        style={{
          padding: "10px 30px",
          backgroundColor: "#555",
          color: "white",
          textAlign: "center",
          position:"relative",
          display:"inline-block",
          width:"105px",
          cursor: "pointer",
        }}
      >
        <HiPencilAlt/>글쓰기
      </Link>
    )}
  </div>
</div>



}


{history === true ? 

filterData.filter((item)=>(item.end_date !=="" && item.end_date !== null && item.end_date < moment(new Date()).format("YYYY-MM-DD"))).length > 20 && (
  <div className="paging">
    <Pagination
      postsPerPage={limit}
      totalPosts={filterData.filter((item)=>(item.end_date !=="" && item.end_date !== null && item.end_date < moment(new Date()).format("YYYY-MM-DD"))).length}
      page={page}
      paginate={setPage}
    ></Pagination>
  </div>
)
:

filterData.filter((item)=>(item.end_date >= moment(new Date()).format("YYYY-MM-DD") || item.end_date==="" || item.end_date===null)).length > 20 && (
  <div className="paging">
    <Pagination
      postsPerPage={limit}
      totalPosts={filterData.filter((item)=>(item.end_date >= moment(new Date()).format("YYYY-MM-DD") || item.end_date==="" || item.end_date===null)).length}
      page={page}
      paginate={setPage}
    ></Pagination>
  </div>

)
}

                  
                      </div>
                  </div>
          </form>
        </div>
      </div>
            
            
                
                
                

        </div>
     </div>
    </div>

  );
};

export default Board;
