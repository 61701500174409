import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import "./css/expenditure.css";

//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 



const Receipt = ({doc_no, onChange, line}) => {  
  

  const [links, setLinks]=useState([]);
  const [select, setSelect] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState([]);

  
 
 

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    onChange(values);

  };
 


  useEffect(() => {
    if (doc_no!==undefined){
    axios.get('http://gw.thegmmedical.com:5003/api/get_forms_data/receipt/'+doc_no)
    .then(function (response){
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
    
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);



  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     
     

    

            <div className="order_write forms" style={{padding:"0", margin:"20px auto"}}>
          

      <div className="rent">
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
        
            
                <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" 
                    style={{border:"none"}}><input style={{width:"50px", fontSize:"22px", textAlign:"center", fontWeight:"700"}} type="text"
                    name="year" value={values.year} onChange={handleInputChange}></input>년 [<input style={{width:"50px", fontSize:"22px", textAlign:"center", fontWeight:"700"}} type="text"
                    name="month" value={values.month} onChange={handleInputChange}></input>]월 개인경비영수증 제출내역서</td>
                  </tr>

                  
                </table>

                    <table className="receipt" style={{fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th colSpan={6} style={{padding: '5px', height: '18px', textAlign: 'center', background: '#ccecff', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            기재사항
                          </th>
                        
                        </tr>
                        <tr>
                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            제출자
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="submitter" value={values.submitter} onChange={handleInputChange}></input>
                          </td>

                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            소속법인
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="corp" value={values.corp} onChange={handleInputChange}></input>
                          </td>

                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            차량번호
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="vehicle" value={values.vehicle} onChange={handleInputChange}></input>
                          </td>
                        </tr>
                     
                        <tr>
                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            [차량유지비] 총 금액
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="maintenance" value={values.maintenance} onChange={handleInputChange}></input>
                          </td>

                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            [복리후생비] 총 금액
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="welfare" value={values.welfare} onChange={handleInputChange}></input>
                          </td>

                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            [여비교통비] 총 금액
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="transportation" value={values.transportation} onChange={handleInputChange}></input>
                          </td>
                        </tr>

                        <tr>
                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            [소모품비] 총 금액
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="consumption" value={values.consumption} onChange={handleInputChange}></input>
                          </td>

                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            [  <input type="text" name="header1" value={values.header1} onChange={handleInputChange}></input>     ] 총 금액
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="value1" value={values.value1} onChange={handleInputChange}></input>
                          </td>

                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            [  <input type="text" name="header2" value={values.header2} onChange={handleInputChange}></input>     ] 총 금액
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="value2" value={values.value2} onChange={handleInputChange}></input>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <p></p>
                    <p></p>
                    <p>영수증 제출 유의사항</p>
                    <p>1. [ &#32;&#32;&#32;  ] --❯ 공란에 계정과목을 표기 후 해당계정과목에 해당되는 영수증을 일자 순으로 붙인 후 해당계정과목의 총 금액을 기입해 주시길 바랍니다.</p>
                    <p>ex: 식대의 경우 [ 복리후생비 ] 라고 표기, 일자순/계정과목별로 영수증을 붙인 후 기재사항에 복리후생비 총 금액을 표기해주시면 됩니다.</p>
                    <p>2-1. 지급일 기준 이후의 영수증을 가급적 사용 부탁드립니다. ex: 1월 3일 경비지급의 경우 1월 3일 이후 영수증을 붙여주시면 됩니다.</p>
                    <p>2-2. 제출일자는 매달 15일로 지정합니다. ex: 1월 지급분 -❯ 2월 15일 제출 (15일이 주말일 경우 익일 제출)</p>
                    <p>3. 22년도부터 가사경비(마트구매), 병원, 약국, 모텔 등 회사에 청구해서는 안되는 영수증은 제출하시면 안됩니다. (음식점, 카페, 주유, 하이패스, 택시비 등 가능) </p>
                    <p>4. 차량유지비(주유, 하이패스) 영수증 제출 시 차량번호를 꼭 기입 부탁드립니다.</p>
                    <p>5. 개인경비청구의 경우 기존의 지출요구서 별도 서식을 이용해 주시길 바랍니다.</p>
                    <p>6. 영수증에는 결제일자, 상호, 사업자번호, 결제금액이 꼭 표기되어 있어야 합니다.</p>
                    <p>7. 공휴일 영수증은 제출 불가이며, 영수증은 복사본으로 첨부 부탁드립니다.</p>



              

      </div>

      </div>
      
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
    </div>

  );
};

export default Receipt;

