import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  addr:"서울 서초구 서초대로 46길 19-7, 201호/Tel:02)2055-2255, Fax:02)2055-1177",
  incharge:"변수연",
  hospital: "",
  inspect_date: "",
  product:"",
  model: "",
  mfr_num: "",
  note:""
};


const Inspect = ({doc_no, onChange, line}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [list, setList] = useState([]);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

  useEffect(()=>{
    //This function will run every time the value of values state changes.
    onChange(values); //Will give you the updated value of values state.
    //Do some other stuff
  }, [values])
  
  useEffect(() => {
    console.log(doc_no);
    if (doc_no!==undefined){
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/inspect/'+doc_no)
    .then(function (response){
      console.log("order.........",response.data[0]);
      if (response.data[0]!==undefined){
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);


  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };

  const toggleShow = () => {
    setShow(!show);
};
  const onTypeChange = e => {
    setType(e.target.value)
  }
  const endDateChange = e => {
    setEndDate(e.target.value)
  }
  const { state } = useLocation();
  useEffect(() => {
  
}, []);



function popupwindow(url, title, w, h) {
  var left = (window.screen.width/2)-(w/2);
  var top = (window.screen.height/2)-(h/2);
  return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
} 



 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {
 
    /*values.arr = rows;

    console.log("values........", values);
    axios.post('https://gw.thegmmedical.com:5003/api/orderinsert', values)
      .then(function (response){
        console.log(response);
        //alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
   */



  };

  const handleDraftButton = () => {
 
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    formData.append(`is_notice`, type);
    formData.append(`name`, "변수연");
    formData.append(`bg_no`, state.board_no);
    formData.append(`end_date`, endDate);
    formData.append(`category`, option);
    if (draftNo !==undefined){
      formData.append(`draft_no`, draftNo);
    }
    const fetch = () => {
      axios
        .post("https://gw.thegmmedical.com:5003/api/draftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response){
          setDraftNo(response.data);
          //window.location.href="https://gw.thegmmedical.com/board";
          //console.log("...." + data);
        });
    };
    fetch();



  };
  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const downloadEmployeeData = () => {
    fetch('https://gw.thegmmedical.com:5003/api/download')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = '이호MH호텔_셀렉 A컷.zip';
                    a.click();
                });
                //window.location.href = response.url;
        });
  }
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";


  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    values.arr = rows;
    onChange(values);

  };
 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let initial = [{
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    },
    {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    }];
    initRow(initial);
    
  }, []);


  const addRowTable = () => {
    const data = {
      product_name:'', model:'',manufacturer:'', create_date:'', quantity:'', mfr_num:'', memo:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);

    values.arr = rows;
    onChange(values);

  };


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

            <div className="order_write forms" style={{padding:"0", margin:"20px auto"}}>
          

          <div>
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>점검 소견서</td>
                  
                  </tr>
                  <tr>
                
                  
                  </tr>
                  
                </table>
                <div style={{display:"flex", justifyContent:"space-between", marginBottom:"10px"}}>
                  <input type="text" name="addr" value={values.addr} onChange={handleInputChange}
                  style={{border:"none", width: "70%"}}
                  ></input>

                  <span>담당자: <input type="text" name="incharge" value={values.incharge} onChange={handleInputChange}
                  style={{border:"none", width:"50px", textAlign:"right"}}
                  ></input></span>

                </div>
                <hr style={{borderWidth:"5px"}}/>


                <table className="" style={{width:"100%", margin:"20px"}}>
          <tbody>
          <tr>
          <td style={{width:"60px"}}>수신 : </td>
          <td colspan="3">                            
          <input type="text" name="hospital" value={values.hospital} onChange={handleInputChange}
          style={{borderBottom:"1px solid #ddd", width:"300px"}}></input>

          </td>
       
          </tr>
          <tr>
          <td style={{width:"60px"}}>소견일 : </td><td colspan="3">
          <input type="date" name="inspect_date" value={values.inspect_date} onChange={handleInputChange}
          style={{borderBottom:"1px solid #ddd", width:"300px"}}></input>
          </td>
          </tr>
          </tbody>
          </table>

<br></br>
<h4 style={{margin:"20px"}}>제 목: 장비 점검 기술소견서</h4>
<hr style={{borderWidth:"2px", color:"#000"}}/>
    <div style={{width:"90%", margin:"20px auto"}}> 
    <br></br>
    <p>1. 귀 원의 무궁한 발전을 기원합니다.</p>          
    <p>2. 사용하시던 장비에 대한 점검내역을 보내드리오니 업무에 참조 바랍니다.</p>  
    </div>
    <br></br><br></br>
                <table className="" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', width:"80%", margin:"0 auto", borderCollapse: 'collapse'}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            품&nbsp;&nbsp;&nbsp;명
                          </th>
                          <td style={{ border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="product" value={values.product} onChange={handleInputChange}></input>
                          </td>

                       
                        </tr>
                        <tr>
                          <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            모 델 명
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="model" value={values.model} onChange={handleInputChange}></input>
                          </td>
                        
                          
                        </tr>
                      
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            제조번호
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="mfr_num" value={values.mfr_num} onChange={handleInputChange}></input>
                          </td>

                        </tr>

                        <tr>
                          <th style={{ padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            소견 
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}
                          colSpan={3}>
                          <textarea type="text" name="note" value={values.note} onChange={handleInputChange} 
                          style={{height:"60px", width:"99%"}}></textarea>
                          </td>
                         
                        </tr>
                      
                      </tbody>
                    </table>


<h2 style={{textAlign:"center", marginTop:"50px"}}>(주)GM메디칼</h2>

      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default Inspect;

