
import React, { useState, useEffect, useRef } from "react";
import Popup from "../components/Popup";
import axios from "axios";
import "./css/adminUsers.css";
import Pagination from "./Pagination";
import addDays from "date-fns/addDays";
import {differenceInCalendarYears, differenceInCalendarMonths} from "date-fns";
import Sidebar from "../components/side/MobileSidebar";
import { RxHamburgerMenu } from "react-icons/rx";
import LeaveUserHistory from "../components/LeaveUserHistory";

const AdminLeave = () => {
    const [sidebar, setSidebar] = useState(false);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showPopup, setPopup] = useState(false);
    const [showCreate, setCreate] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [used, setUsed] = useState([]);
    const [showLeave, setLeave] = useState(false);
    const [leaveUser, setLeaveUser] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [dNo, setDNo] = useState("");

  
    
  
    const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const offset = (page - 1) * limit;
  const toggleLeave = () => {
    setLeave(!showLeave);
    

};
    const togglePopup = () => {
        setPopup(!showPopup);
        

    };
    const toggleCreate = () => {
      setCreate(!showCreate);
      

  };
    const updateUser = () => {
      console.log("checklist...", checkList);
        for (var i=0;i<data.length;i++){
          if (data[i].email === checkList[0]){
            setSelected(data[i]);
          }
        }
      setPopup(!showPopup);
      
    };
    const deleteLog = () => {
      
      var result = window.confirm("모든 로그인 오류 기록을 삭제하고 차단 해제 하겠습니까?");
      if(result){
        axios.get('https://gw.thegmmedical.com:5003/api/del_pwerror')
        .then(function (response){
          console.log(response);
          window.location.reload(true);
          
          
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const stopUser = () => {
      console.log(checkList);
      var array = []
      var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
  
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].value!=="selectall"){
        array.push(checkboxes[i].value)
        }
      }
      console.log(array);
      if (array[0].value==="selectall"){
        array.shift();
      }
      const data ={
        nos : array
      }
      var result = window.confirm("선택된 사용자를 삭제하시겠습니까?");
      if(result){
        axios.post('https://gw.thegmmedical.com:5003/api/stopUsers', data)
        .then(function (response){
          console.log(response);
          getUser();
     
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
    };
    const allInsert = () => {
      let temp = data;
      for (let i=0;i<temp.length;i++){
        let num;
        if (temp[i].workYear <1){
          num = differenceInCalendarMonths(new Date(new Date().getFullYear(), 11, 31), new Date(temp[i].start));
        }else{
          num = 15 + parseInt((temp[i].workYear-1)/2);

        }
        temp[i].curNum = num;
      }
      console.log("temp....", temp);

      setFilterData([...temp]);
    };


    const autoInsert = () => {
      console.log(checkList);
      let temp = data;
      for (let i=0;i<temp.length;i++){
        
        if (temp[i].number === 0){
          let num;
           if (temp[i].workYear <1){
          num = differenceInCalendarMonths(new Date(new Date().getFullYear(), 11, 31), new Date(temp[i].start));
          }else{
            num = 15 + parseInt((temp[i].workYear-1)/2);

          } 
          temp[i].curNum = num;
        }
       
       
      }
      console.log("temp....", temp);

      setFilterData([...temp]);
    };


    function getUser(){
      fetch("https://gw.thegmmedical.com:5003/api/getuser_leaves/", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("json", json);
        let temp = json;
        for (let i=0;i<temp.length;i++){
          temp[i].prevNum = getNum(temp[i].num, new Date().getFullYear() - 1);

          temp[i].curNum = getNum(temp[i].num, new Date().getFullYear());

          temp[i].nextNum = getNum(temp[i].num, new Date().getFullYear()+1);

          if (temp[i].start ==''){
            temp[i].workYear="";
            temp[i].workMonth="";
          }else{
            temp[i].workYear=differenceInCalendarYears(new Date(), new Date(temp[i].start));
            temp[i].workMonth=differenceInCalendarMonths(new Date(), new Date(temp[i].start));
          }
          
          
        }
        console.log(temp);
       
        setData(temp);
      
        
        setFilterData(temp);
        
      })
      .catch(() => {});
    
    }


    function getUsed()  {
      const checkboxes 
           = document.getElementsByName('user');
      const all 
           = document.getElementsByName('userall');
      //console.log(all[0].checked)
      checkboxes.forEach((checkbox) => {
        checkbox.checked = all[0].checked;
      })
    }
    

    useEffect(() => {
       
      getUser();
      


      axios.get('https://gw.thegmmedical.com:5003/api/getdepartment_group/')
      .then(function (response){
        console.log(response);
        //getUser();
        setDepartments(response.data);

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
        

      axios.get('https://gw.thegmmedical.com:5003/api/getused_leaves/')
      .then(function (response){
        console.log(response);
        //getUser();
        setUsed(response.data);

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
        
    
      
      }, []);
      function selectAll()  {
        const checkboxes 
             = document.getElementsByName('user');
        const all 
             = document.getElementsByName('userall');
        //console.log(all[0].checked)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = all[0].checked;
        })
      }

      const onChangeEach = (e, id) => {
        // 체크할 시 CheckList에 id값 넣기
        if (e.target.checked) {
           setCheckList([...checkList, id]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
        } else {
           setCheckList(checkList.filter((checkedId) => checkedId !== id));
        }
        
     }

     const unBlock  = (no) => {
      var result = window.confirm("선택된 사용자를 차단 해제합니다");
      if(result){
        axios.get('https://gw.thegmmedical.com:5003/api/unblock/'+no)
        .then(function (response){
          console.log(response);
          //getUser();
          alert("차단이 해제되었습니다.");
          window.location.reload(true);
  
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      }
     }

     const filterDates  = (no) => {
      setFilterData(data.filter(item=> new Date(item.error_date) >= new Date(startDate) && new Date(item.error_date) <=addDays(new Date(endDate),1)));


     };

     function getNum(arr, year){
      if (arr !==null && arr !==undefined){
        let temp = arr.split(",");
        console.log(temp);
        let value = temp.filter(item => item.includes(year));
        if (value[0] !==undefined){
          let final =value[0].split("-");
          console.log(final);
          return final[1];
        
        }
        
      }
     }
    

function saveAll(){
  let arr = [];
  let year = new Date().getFullYear();
  let temp = filterData;
  for (let i=0;i<temp.length;i++){
    arr.push([temp[i].curNum, temp[i].userNo,
      year]);
  }
  console.log(arr);

  const msg = {
    arr : arr
  }
  axios.post('https://gw.thegmmedical.com:5003/api/save_all_leaves',msg)
  .then(function (response){
    console.log(response);
    //getUser();
    alert("저장되었습니다.");
    window.location.reload(true);

  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
    //always executed
  });

}







const [category, setCategory] = useState("");
const [upDown, setUpDown] = useState(true);




const compare = ((a, b) => {
  const eleA = a[category]; // ignore upper and lowercase
  const eleB = b[category]; // ignore upper and lowercase
  if (upDown === true){
    if (eleA > eleB) {
      return -1;
    }
    if (eleA < eleB) {
      return 1;
    }
  }else{
    if (eleA < eleB) {
      return -1;
    }
    if (eleA > eleB) {
      return 1;
    }
  }


  // names must be equal
  return 0;
});










  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7"}}>
    {showLeave ? (
  <LeaveUserHistory closePopup={() => toggleLeave()} user={leaveUser} />
) : null}
    
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
     <div className="contents admin_leave" style={{maxWidth:"1600px"}}>
        <div className="header_txt">
        <h3>연차 관리</h3>
        </div>
    

        <div className="main_contents_wrapper" style={{width:"100%"}}>
            <div className="btns" style={{display:"flex", justifyContent:"space-between"}}>
             

              <div className="left">
                <button onClick={() => allInsert()}>전체 직원 자동 할당</button> 

                <button onClick={() => autoInsert()}>신규 직원 자동 할당</button>
            </div>

            <div className="right">
                
                <select value={dNo} onChange={(e)=>{setDNo(e.currentTarget.value);
                let tmp = data;
                if (e.currentTarget.value==="0"){
                  setFilterData([...tmp]);
                }else{
                  setFilterData([...tmp.filter(x=> x.d_name == e.currentTarget.value)]);
                }
                
                }}>
                  <option value="0">부서를 선택해주세요.</option>
                {departments.map((ele,idx)=>(
                  <option value={ele.d_name}>{ele.d_name}</option>
                ))}
                </select>

            </div>
            </div>

{showCreate ? (
  <Popup text="Title" closePopup={() => {toggleCreate();getUser();}} />
) : null}           

{showPopup ? (
  <Popup member={selected} text="Title" closePopup={() => {togglePopup();getUser();}} />
) : null}



<div className="table_wrap">
<table className="admin_leave_table">
  <thead>
    <tr>
  
    <th class="wd-150 xl-hidden">
      <span onClick={()=>{if (category !=="name"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("name"); filterData.sort(compare);}}>이름</span>                              
    </th>
    <th class="wd-150 xl-hidden">
      <span onClick={()=>{if (category !=="d_name"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("d_name"); filterData.sort(compare);}}>부서</span>                              
    </th>


    <th class="wd-150 xl-hidden">
      <span onClick={()=>{if (category !=="job_name"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("job_name"); filterData.sort(compare);}}>직위</span>                              
    </th>
    <th class="wd-150 xl-hidden">
      <span onClick={()=>{if (category !=="start"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("start"); filterData.sort(compare);}}>입사일자</span>                              
    </th>


    <th class="wd-150 xl-hidden">
      <span onClick={()=>{if (category !=="workYear"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("workYear"); filterData.sort(compare);}}>근속년차</span>                              
    </th>
    <th class="wd-150 xl-hidden">
      <span onClick={()=>{if (category !=="workMonth"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("workMonth"); filterData.sort(compare);}}>총근속개월</span>                              
    </th>
    <th class="wd-150 xl-hidden">
      <span onClick={()=>{if (category !=="prevNum"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("prevNum"); filterData.sort(compare);}}>
      전년도({new Date().getFullYear() - 1}년)</span>                              
    </th>
    <th class="wd-150 xl-hidden">
      <span onClick={()=>{if (category !=="curNum"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("curNum"); filterData.sort(compare);}}>
      당해년도({new Date().getFullYear()}년)</span>                              
    </th>
    <th class="wd-150 xl-hidden">
      <span onClick={()=>{if (category !=="nextNum"){setUpDown(true)}else{setUpDown(!upDown);}setCategory("nextNum"); filterData.sort(compare);}}>
      후년도({new Date().getFullYear() + 1}년)</span>                              
    </th>
   




    </tr>
  </thead>
  <tbody>
  
  { filterData.map((element, index) => 
    <tr key={index}>
   
      
    <td data-label="이름">{element.name}</td>
    <td data-label="부서">{element.d_name}</td>
    <td data-label="직위">{element.job_name}</td>
    <td data-label="입사일자">{element.start}</td>
    <td data-label="근속년차">{element.workYear}년</td>
    <td data-label="총 근속개월">{element.workMonth}개월</td>
    <td className="leaves" data-label="전년도()"><span>할당: {element.prevNum}</span>
    <span style={{margin:"0 10px"}}>|</span>

    <span>잔여: {used.filter((item)=> (item.user_no == element.user_no && item.year == new Date().getFullYear()-1))[0] !==undefined 
    && (element.prevNum - used.filter((item)=> (item.user_no == element.user_no && item.year == new Date().getFullYear()-1))[0].used )} </span></td>
    <td className="leaves"  data-label="당해년도"><span>할당: 
    <input type="number" value={element.curNum} onChange={(e)=>{
      let temp = filterData;
      temp[offset+index].curNum = e.target.value;
      setFilterData([...temp]);
    }}
    style={{width:"35px"}}></input> </span>
    <span style={{margin:"0 10px"}}>|</span>
    <span onClick={()=>{setLeaveUser(element.user_no);toggleLeave()}} style={{cursor:"pointer"}}>잔여:
    {used.filter((item)=> (item.user_no == element.user_no && item.year == new Date().getFullYear()))[0] !==undefined 
    && (element.curNum - used.filter((item)=> (item.user_no == element.user_no && item.year == new Date().getFullYear()))[0].used)}
      </span></td>
      
    <td className="leaves" data-label="후년도"><span>할당: {element.nextNum}</span> 
    <span style={{margin:"0 10px"}}>|</span>

    <span>잔여: 
    {used.filter((item)=> (item.user_no == element.user_no && item.year == new Date().getFullYear()+1))[0] !==undefined 
    && (element.nextNum - used.filter((item)=> (item.user_no == element.user_no && item.year == new Date().getFullYear()+1))[0].used)}
      </span></td>


    

    </tr>) }
   
    {filterData.length === 0 && (<tr><td colSpan={11} style={{padding:"50px 0"}}>실패 기록이 없습니다.</td></tr>)}
  </tbody>
</table>
</div>
  </div>

  <div className="btns" style={{display:"flex",justifyContent:"center", marginBottom:"15px"}}>
        <button className="save" onClick={()=>saveAll()}>저장</button>
        </div>
        
     </div>
    
    </div>

  );
};

export default AdminLeave;