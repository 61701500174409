

import { NavLink, Link,useParams, useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailNav from "../components/side/MailNav";
import './css/form.css';
import { VscTrash } from "react-icons/vsc";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import DocList from "../components/side/DocList";
import AutoCompleteLine from "../components/AutoCompleteLine";

axios.defaults.withCredentials = true; 





const LineList = ({ doc_no, data, lineName, lineNo, onChange,closePopup}) => {
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState();
  const [selectedName, setSelectedName] = useState("");
  const [lineList, setLineList] = useState([]);
  const [type, setType] = useState([]);
  const [value, setValue] = useState("");
  const [rows, initRow] = useState([]);

  useEffect(()=>{
    
    axios.get('http://gw.thegmmedical.com:5003/api/getlines')
    .then(function (response){
    console.log("hey result is!!!!!!!!!!!!", response.data);
    setLines(response.data);

    //setLine(response.data[1]);
    

    console.log("lineList data....", data, lineNo);
    if (data.length>0){
      setLineList(data);

      var trTags = document.getElementsByTagName("tr");
var searchText = data[0].line_no;
var found;

for (var i = 0; i < trTags.length; i++) {
  if (trTags[i].id == searchText) {
    
    found = trTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('tr');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';



      setSelectedName(data[0].line_name);
    }


    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    
}, []);  

function showLineList(e){
  setSelectedLine(e.currentTarget.id);
  setSelectedName(e.currentTarget.className);

var trTags = document.getElementsByTagName("tr");
var searchText = e.currentTarget.id;
var found;

for (var i = 0; i < trTags.length; i++) {
  if (trTags[i].id === searchText) {
    found = trTags[i];
    break;
  }
}
const boxes = document.querySelectorAll('tr');

boxes.forEach(box => {
  box.style.backgroundColor = 'transparent';
});
found.style.backgroundColor = '#BEFDFD';

  axios.get('http://gw.thegmmedical.com:5003/api/getlinelist/'+e.currentTarget.id)
    .then(function (response){
      console.log(response);
      let data = response.data;
      for (let i=0;i<data.length;i++){
        data[i].decider=null;
        data[i].status=0;
      }
      setLineList(data);
      //window.location.href="/doc_form";
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
}

const typeSelect = (e) => {
  e.preventDefault();
  var selectedId = Number(e.currentTarget.className);
  console.log(e.currentTarget.className);
  var tmp = lineList;
  tmp[selectedId].type = e.target.value;
  setLineList([...tmp]);
};
const handleInputChange = (e) => {
  e.preventDefault();
  setSelectedName(e.target.value);

};
function compare( a, b ) {
  if ( a.order_no < b.order_no ){
    return -1;
  }
  if ( a.order_no > b.order_no ){
    return 1;
  }
  return 0;
}

function upOrder(e){
  var selectedId = Number(e.currentTarget.className.baseVal);
  var tmp = lineList;
  var tmp_order1 = tmp[selectedId].order_no;
  var tmp_order2 = tmp[selectedId-1].order_no;
  tmp[selectedId].order_no= tmp_order2;
  tmp[selectedId-1].order_no = tmp_order1;
  tmp.sort(compare);
  console.log(tmp);
  setLineList([...tmp]);
}

function downOrder(e){
  var selectedId = Number(e.currentTarget.className.baseVal);
  var tmp = lineList;
  var tmp_order1 = tmp[selectedId].order_no;
  var tmp_order2 = tmp[selectedId+1].order_no;
  tmp[selectedId].order_no= tmp_order2;
  tmp[selectedId+1].order_no = tmp_order1;
  tmp.sort(compare);
  console.log(tmp);
  setLineList([...tmp]);
}

function checkLine(e){
  var selectedId = Number(e.target.className);
  var tmp = lineList;
  console.log(tmp[selectedId]);
  tmp[selectedId][e.target.name] = !tmp[selectedId][e.target.name];
 

  setLineList([...tmp]);
}


function addLine(){
  let linename = prompt("결재라인명을 입력하세요", "");
  let text;
  if (linename === null || linename === "") {
    text = "User cancelled the prompt.";
  } else {
    const data ={
      name : linename,
    }
    axios.post('http://gw.thegmmedical.com:5003/api/addline', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
}

function delLine(){
  const data ={
    selected : selectedLine,
  }
  var result = window.confirm("정말 삭제하시겠습니까?");
  if(result){
    axios.post('http://gw.thegmmedical.com:5003/api/delline', data)
    .then(function (response){
      console.log(response);
      //window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
}


function delList(e){

  var selectedId = Number(e.currentTarget.id);
  var tmp = lineList;
  tmp.splice(selectedId, 1);
  for (let i=0;i<tmp.length;i++){
    tmp[i].order_no = i+1;
  }
  console.log(tmp);
  setLineList([...tmp]);
}
function addList(val){
  
  var subStr = val.substring(
    val.indexOf("[") + 1, 
    val.lastIndexOf("]")
);console.log(subStr);
 var data={};
axios.get('http://gw.thegmmedical.com:5003/api/getuserinfo/'+subStr)
    .then(function (response){
      console.log(response);
      
      data.d_name = response.data[0].d_name;
      data.job_name = response.data[0].job_name;
      if (lineList.length > 0){
        console.log("야", lineList);
        data.order_no = lineList[lineList.length-1].order_no+1;
      }else{
        data.order_no=1;
      }
      
      data.type="결재"
      data.name = response.data[0].name;
      data.user_no = subStr;
      data.line_no = selectedLine;
      var tmp = lineList;

      tmp.push(data);
      setLineList([...tmp]);
      //setLineList(response.data);
      //window.location.href="/doc_form";
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
};
function saveEdits(){
  var tmp_list = lineList;
  var line_content = tmp_list[0].name + "("+tmp_list[0].job_name+") ~ " + tmp_list[tmp_list.length-1].name+ "("+tmp_list[tmp_list.length-1].job_name+")";
  console.log(tmp_list[0]);
  console.log(tmp_list);
  tmp_list.forEach(tmp => {
    delete tmp.d_name;
  delete tmp.doc_no;
  delete tmp.job_name;
  delete tmp.no;
  delete tmp.name;
});
  
  const data ={
    selected : selectedLine,
    name : selectedName,
    line_list : tmp_list,
    line_content : line_content,
    line_no : selectedLine
  }
  var result = window.confirm("변경 사항을 저장하시겠습니까?");
  if(result){
    axios.post('http://gw.thegmmedical.com:5003/api/edit_linelist', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
}

function popupwindow(url, title, w, h) {
  var left = (window.screen.width/2)-(w/2);
  var top = (window.screen.height/2)-(h/2);
  return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
} 

function register(){
 
   
      
        onChange({ line_no: selectedLine, line_name : selectedName, line_list : lineList });
       
  
  
}
/*
function register(){
//  window.opener.postMessage({ line_no: selectedLine, line_name : selectedName, line_list : lineList }, '*');
        
          
        
  //window.close();
  //onChange({ line_no: selectedLine, line_name : selectedName, line_list : lineList });
  const data ={

    line_list : JSON.stringify(lineList),
    doc_no : doc_no
 
  }
  var result = window.confirm("결재 라인을 변경하시겠습니까?");
  if(result){
    axios.post('http://gw.thegmmedical.com:5003/api/edit_docline', data)
    .then(function (response){
      console.log(response);
      alert("변경되었습니다.");
      onChange({ line_no: selectedLine, line_name : selectedName, line_list : lineList });
      closePopup();

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }

}*/


  return (

    <div className="popup">
    <div className="inner" style={{height:"auto", maxWidth:"1300px", position:"relative",
         left:"50%", transform:"translateX(-50%) translateY(-50%)", top:"50%"}}>
    <div className="wrapper">

    
    <div className="outer_wrapper" style={{justifyContent:"flex-start"}}>


   <div  className="line_main" style={{width:"90%", margin:"30px auto", padding:"0 5px"}}>

    <h4>결재 라인 관리</h4>
   <div className="btns" style={{display:"flex", justifyContent:"space-between", width:"60%", margin:"0"}}>
              <div className="left" style={{display:"flex"}}>

              
              <div className="btn" style={{marginRight:"10px"}} onClick={()=>addLine()}>신규라인</div>

              <div className="btn" style={{marginRight:"10px"}} onClick={()=>delLine()}>삭제</div>
             
              </div>

          
            </div>


<div className="row" style={{flexDirection:"column"}}>
<table width="100%" border="0" cellspacing="0" cellpadding="0" className="line_table">
<thead>	
    <tr>

    <th>번호</th>
    <th>결재라인명</th>
    <th>적용된 양식수</th>
    <th>내용</th>

    </tr>

</thead>
<tbody>

{lines.map((element, index)=>(
  <tr id={element.line_no} className={element.line_name} onClick={(e)=>showLineList(e)} style={{cursor:"pointer"}}>
  <td>{element.line_no}</td>
  <td>{element.line_name}</td>
  <td>{element.form_no}</td>
  <td>{element.line_content}</td>
  </tr>
))}


</tbody>
</table>

<h4>결재라인별 결재자 관리 <span style={{color:"#8aa3ee", fontWeight:"normal"}}>-{selectedName}-</span> </h4>

<div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
<div style={{display:"flex", alignItems:"center"}} className="btns">
  <span>결재자 추가</span>
  <AutoCompleteLine onChange={(value) => {setValue(value); console.log("hihi", value); addList(value)}}/>
  <div className="btn" onClick={()=>addList()} style={{marginLeft:"10px"}}>추가</div>
</div>

</div>

<div style={{height:"180px", overflowY:"auto"}}>
<table width="100%" border="0" cellspacing="0" cellpadding="0" className="line_table">
<thead>	
    <tr>

    <th>순서</th>
    <th>타입</th>
    <th>부서명</th>
    <th>결재자</th>
    <th>이동</th>
    <th>라인수정</th>
    <th>문서수정</th>

    <th>삭제</th>
    </tr>

</thead>
<tbody>

{lineList.map((element, index)=>(
  <tr>
  <td>{element.order_no}</td>
  <td>
  <select name="form_group" className={index} value={element.type || type} onChange={typeSelect}>
    <option value="결재">결재</option>
    <option value="전결">전결</option>
    <option value="승인">승인</option>
  </select>
  </td>
  <td>{element.d_name}</td>
  <td>{element.name} ({element.job_name})</td>
  <td className="arrows">
    <FaArrowAltCircleUp style={{marginRight:"5px"}} className={index} onClick={(e)=>upOrder(e)} />
    <FaArrowAltCircleDown className={index} onClick={(e)=>downOrder(e)} />
  </td>


  <td>

        <input type="checkbox" value="1" name="line" className={index} checked={element.line == true} onChange={(e)=>{checkLine(e)}}
        style={{width:"30px"}}/>

  </td>
  <td>
  <input type="checkbox" value="1" name="doc" className={index} checked={element.doc == true} onChange={(e)=>{checkLine(e)}}
        style={{width:"30px"}}/>

  </td>

  <td><VscTrash style={{fontSize:"20px"}} className="trash" id={index} onClick={(e)=>delList(e)}/></td>
  </tr>
))}
</tbody>
</table>

</div>


</div>
<div className="btns" style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>
    <div className="btn" onClick={()=>register()}>적용</div>
    <div className="btn" onClick={closePopup}>취소</div>


    </div>


   
      </div>
   </div>
  </div>
  </div>
  </div>

  );
};

export default LineList;

