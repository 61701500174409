
import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {FaAddressBook} from "react-icons/fa";
import "../css/setting.css";
const SortSetting = () => {
  const [ruleName, setRuleName] = useState("");
  const [subject, setSubject]=useState("");
  const [sort, setSort]=useState([]);
  const [dest, setDest] = useState("move");
  const [category, setCategory] = useState("");
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    axios.get('http://gw.thegmmedical.com:5003/api/getboxes')
    .then(function (response){
      console.log("getboxes", response);
      
      setBoxes(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    fetch("http://gw.thegmmedical.com:5003/api/getfilters", {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      setSort(json);
      
      
    })
    .catch(() => {});
  
  }, []);

  const [sendItem, setsendItem] = useState("");
  const [sendList, setSendList] = useState([]);

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      //alert("You have entered an invalid email address!")
      return (false)
  }
  const generateId = () => {
     const highestId = Math.max.apply(Math, sendList.map(function (element) {
            return element.id;
        }));
        let newId = 1; // default in case the array is empty
        console.log("highest Id is...", highestId);
        if (highestId > 0) {
            // generate a new ID based off of the highest existing element ID 
            newId = (highestId + 1);
        }
        return newId;
  };

  function createNewToDoItem() {
    if (sendItem !== '') {
      const item = { id: generateId(), text: sendItem };
      const tempArray = [...sendList, item];
      setSendList(tempArray);
    }
    setsendItem('');
  }

  function handleAdd(e) {
    e.preventDefault();
    createNewToDoItem();
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      createNewToDoItem();
    }
  };

  const deleteItem = id => {
    setSendList(sendList.filter(item => item.id !== id));
  };

  const display = sendList.map(item => (
    
    <div className="">
      {ValidateEmail(item.text) ? (
        <div className="addr_list">
        <li key={item.id} className="valid">
          {item.text}
          </li>
          <span className="del_btn" onClick={() => deleteItem(item.id)}>X</span>
    </div>
      ) : (
        <div className="addr_list unvalid">
        <li key={item.id}>
          {item.text}
          </li>
          <span className="del_btn" onClick={() => deleteItem(item.id)}>X</span>
    </div>
      )}
    </div>
    
  ));

  const [recItem, setRecItem] = useState("");
  const [recList, setRecList] = useState([]);

  const generateId_rec = () => {
    console.log("rec................",recList);
     const highestId = Math.max.apply(Math, recList.map(function (element) {
            return element.id;
        }));
        let newId = 1; // default in case the array is empty

        if (highestId > 0) {
            // generate a new ID based off of the highest existing element ID 
            newId = (highestId + 1);
        }
        return newId;
  };

  function createNewToDoItem_rec() {

    if (recItem !== '') {
      const item = { id: generateId_rec(), text: recItem };
      const tempArray = [...recList, item];
      setRecList(tempArray);
    }
    setRecItem('');
  }
 
  function handleAdd_rec(e) {
    e.preventDefault();
    createNewToDoItem_rec();
  }

  const handleKeyPress_rec = e => {
    
    //console.log("keycode is " , e.key);
    if (e.key === 'Enter') {
      createNewToDoItem_rec();
    }else if (e.key === ' '){
      createNewToDoItem_rec();
    }else if (e.key === 'Tab'){
      createNewToDoItem_rec();
    }
    /*e.target.addEventListener("blur" , e =>{
      
      createNewToDoItem_rec2(e.target.value);
    });*/
    
    
    
  };
  const handleBlur_rec = e => {
    //console.log("keycode is " , e.key);
      createNewToDoItem_rec();
   
    /*e.target.addEventListener("blur" , e =>{
      
      createNewToDoItem_rec2(e.target.value);
    });*/
    
    
    
  };
  const deleteItem_rec = id => {
    setRecList(recList.filter(item => item.id !== id));
  };

  const display_rec = recList.map(item => (
    
    <div className="">
      {ValidateEmail(item.text) ? (
        <div className="user_list">
        <li key={item.id} className="valid">
          {item.text}
          </li>
          <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>X</span>
    </div>
      ) : (
        <div className="user_list unvalid">
        <li key={item.id}>
          {item.text}
          </li>
          <span className="del_btn" onClick={() => deleteItem_rec(item.id)}>X</span>
    </div>
      )}
    </div>
    
  ));

  const [ccItem, setCcItem] = useState("");
  const [ccList, setCcList] = useState([]);

  const generateId_cc = () => {
     const highestId = Math.max.apply(Math, ccList.map(function (element) {
            return element.id;
        }));
        let newId = 1; // default in case the array is empty

        if (highestId > 0) {
            // generate a new ID based off of the highest existing element ID 
            newId = (highestId + 1);
        }
        return newId;
  };

  function createNewToDoItem_cc() {
    if (ccItem !== '') {
      const item = { id: generateId_cc(), text: ccItem };
      const tempArray = [...ccList, item];
      setCcList(tempArray);
    }
    setCcItem('');
  }

  function handleAdd_cc(e) {
    e.preventDefault();
    createNewToDoItem_cc();
  }

  const handleKeyPress_cc = e => {
    if (e.key === 'Enter') {
        createNewToDoItem_cc();
    }else if (e.key === ' '){
      createNewToDoItem_cc();
    }else if (e.key === 'Tab'){
      createNewToDoItem_cc();
    }
  };

  const deleteItem_cc = id => {
    setCcList(ccList.filter(item => item.id !== id));
  };

  const display_cc = ccList.map(item => (
    <div className="cc_list"><li key={item.id}>
      {item.text}
    </li>
    <span className="del_btn" onClick={() => deleteItem_cc(item.id)}>X</span>
    </div>
  ));

  

  const [selected, setSelected] = useState("inbox");

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };
  const [input, setInput] = useState('');

  const handleInput = event => {
    setInput(event.target.value);

  };
  function submit_filter(){
    var destination = "";
    if (dest === "move"){
      destination= selected;
    } else{
      destination= input;
    }
    const data = {
      ruleName: ruleName,
      subject : subject,
      dest : destination,
      sendList : sendList,
      recList : recList,
      ccList : ccList

    
    }

    axios.post('http://gw.thegmmedical.com:5003/save_auto_filters',data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  };
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  }
  function display_rule(list){
    return list.map(item => (
      <div className=""><li key={item.id}>
        {item.text}
      </li>
  
      </div>
    ));
  }
  window.addEventListener('message', (event) => {
    if (event.data["users"] !== undefined){
      /*if (category === "send"){
        setSendList(event.data["selectedMember"])
      }else if (category === "rec"){

      }else if (category === "cc"){

      }
      setUser(event.data["selectedMember"]);
      data.name = event.data["selectedMember"].name;
      data.user_num = event.data["selectedMember"].user_no;*/
      console.log(event.data["users"]);
      var users = event.data["users"];
      if (category === "send"){
        let tempArray = [];
        for (let i=0;i<users.length;i++){
          console.log("user is....", users[i]);
          const item = { id: generateId(), text: users[i].email };
          tempArray = [...tempArray, item];
          console.log("hey", tempArray);
          
        }
        let array = sendList.concat(tempArray);
        console.log("array is...", array);
        setSendList(array);
      }else if (category === "rec"){
        let tempArray = [];
        for (let i=0;i<users.length;i++){
          console.log("user is....", users[i]);
          const item = { id: generateId_rec(), text: users[i].email };
          tempArray = [...tempArray, item];
          console.log("hey", tempArray);
          
        }
        let array = recList.concat(tempArray);
        console.log("array is...", array);
        setRecList(array);
      }else if (category === "cc"){
        let tempArray = [];
        for (let i=0;i<users.length;i++){
          console.log("user is....", users[i]);
          const item = { id: generateId_cc(), text: users[i].email };
          tempArray = [...tempArray, item];
          console.log("hey", tempArray);
          
        }
        let array = ccList.concat(tempArray);
        console.log("array is...", array);
        setCcList(array);
      }
     
    }
    
  });

  function delRule(id){
    var result = window.confirm("삭제하시겠습니까?");
    if (result){
      const data = {
        id: id
      }
  
      axios.post('https://gw.thegmmedical.com:5003/delete_filter',data)
      .then(function (response){
        console.log(response);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
    }
  }


  const renderSettingText = (boxname) => {
    switch (boxname) {
        
        case "INBOX":
            return "받은 메일함" ;
        case "sent":
            return "보낸 메일함";
        case "Junk":
            return "스팸 메일함";
        case "draft":
            return "임시 보관";
        case "delete":
            return "휴지통";
        case "Stars":
            return "중요 메일함";
        default:
            return boxname.slice(6);
    }
  };
  return (
    <div className="wrapper">
        
  <table className="rules">
    <thead>
      <tr>
      <th className="rulename">규칙이름</th>
      <th className="title">제목</th>
      <th className="title">메일함</th>

      <th className="content">메일 주소</th>
      <th className="content">삭제</th>
     </tr>
    </thead>
    <tbody>
{ sort.map((element, index) => 
<tr>
  <td>{element.rule_name}</td>
  <td>{element.subject}</td>
  <td>{renderSettingText(element.dest)}</td>
  <td>
  {display_rule(JSON.parse(element.send_list))}
  {display_rule(JSON.parse(element.rec_list))}
  {display_rule(JSON.parse(element.cc_list))}
  </td>
  <td><button onClick={()=>delRule(sort.id)}>삭제</button></td>
</tr>
)}
</tbody>
</table>
        <div className="sort-setting">
        <table style={{width:"100%"}}>
                <tr>
                    <thead>규칙이름</thead>
                    <td>
                    <div className="input-group setting-input">

                        <input type="text" value = {ruleName} onChange={e=>{
                            setRuleName(e.currentTarget.value);
                        }}></input>
                        </div>
                    </td>
                </tr>

                <tr>
                    <thead>
                    <FaAddressBook id='' style={{cursor:'pointer'}} onClick={() => {popupwindow("/people", "보낸사람", 800, 800); setCategory("send")}} alt=''/>

                      보낸사람</thead>
                    <td>
             
                    <div className="input-group setting-input">
      <ul className="rec_list">{display}
        <li><input
          type="text"
          placeHolder="메일 주소 입력"
          name="todoItem2"
          value={sendItem}
          onChange={e => {
            setsendItem(e.currentTarget.value);
          }}
          onKeyPress={handleKeyPress}
          className="form-control"
        />
        </li>
        </ul>

        <div className="input-group-append">
          <button type="button" onClick={handleAdd}  hidden>
            Add Todo
          </button>
        </div>
      </div>
                    </td>
                </tr>

                <tr>
                    <thead>
                    <FaAddressBook id='' style={{cursor:'pointer'}} onClick={() => {popupwindow("/people", "받는사람", 800, 800); setCategory("rec")}} alt=''/>

                      받는사람</thead>
                    <td>

                    <div className="input-group setting-input">
        <ul className="rec_list">{display_rec}
        
        
        <li><input
          type="text"
          placeHolder="메일 주소 입력"
          name="todoItem2"
          value={recItem}
          onChange={e => {
            setRecItem(e.currentTarget.value);
          }}
          onKeyDown={handleKeyPress_rec}
          onBlur={handleBlur_rec}
          className="form-control"
        />
        </li>
        </ul> 
        

        <div className="input-group-append">
          <button type="button" onClick={handleAdd_rec}  hidden>
            Add Todo
          </button>
        </div>
      </div>
                        
                    </td>
                </tr>
                <tr>

                    <thead>
                    <FaAddressBook id='' style={{cursor:'pointer'}} onClick={() => {popupwindow("/people", "참조", 800, 800); setCategory("cc")}} alt=''/>

                      참조</thead>
                    <td>
                        
                        
                    <div className="input-group setting-input">
      <ul className="rec_list">{display_cc}
        <li><input
          type="text"
          placeHolder="메일 주소 입력"
          name="todoItem2"
          value={ccItem}
          onChange={e => {
            setCcItem(e.currentTarget.value);
          }}
          onKeyPress={handleKeyPress_cc}
          className="form-control"
        />
        </li>
        </ul>

        <div className="input-group-append">
          <button type="button" onClick={handleAdd_cc}  hidden>
            Add Todo
          </button>
        </div>
      </div>
                    </td>
                </tr>
                <tr>
                    <thead>제목</thead>
                    <td>

                    <div className="input-group setting-input">
                    <input type="text" value = {subject} onChange={e=>{
                            setSubject(e.currentTarget.value);
                        }}>
                    </input>
                    </div>
                    </td>
                </tr>

              
               
            </table>


            
            
      <div className="action">
        <div className="move">
            <input
        type="checkbox" id="move" name="move"
        checked={dest === "move"}
        onChange={() => setDest("move")}
      /><label for="move">이동</label><div><select value = {selected} className="boxes"  disabled={dest==="create"  ? true: false} onChange={handleSelect}>
      {boxes.map((box, idx) => {
                        if (box.name==="INBOX"){
                          return <option value="INBOX">받은 메일함</option>
                        }else if (box.name==="sent"){
                          return <option value="sent">보낸 메일함</option>

                        }else if (box.name==="Stars"){
                          return <option value="Stars">중요 메일함</option>

                        }else if (box.name==="draft"){
                          return <option value="draft">임시 보관함</option>

                        }else if (box.name==="Junk"){
                          return <option value="Junk">스팸 보관함</option>

                        }else if (box.name==="delete"){
                          return <option value="delete">휴지통</option>

                        }else{
                          return <option value={"INBOX."+box.name}>{box.name}</option>
                        }
                        
                      })}
    
    </select>으로 이동</div>
    </div>
    <div className="create">
      <input
        type="checkbox" id="create" name="create"
        checked={dest === "create"}
        onChange={() => setDest("create")}
      /><label for="create">생성</label><div>새 메일함에 저장 <input type="text" className="create_box" onChange={handleInput} value= {input} disabled={dest==="move" ? true : false}></input></div>
</div>
</div>

  <div className="btns" style={{display:"flex",justifyContent:"center", width:"auto", margin:"20px"}}>
  <button className="button" onClick={()=> submit_filter()} >저장</button>
  </div>
</div>

    </div>

  );
};

export default SortSetting;
