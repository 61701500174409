import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";
import Sign from "../../components/Sign";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  order_client: "",
  order_date: "",
  issuer:"",
  issuer_contact: "",
  hospital_name: "",
  addr:"",
  pic:"",
  pic_contact:0,
  delivery:""
};


function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {product_name, quantity, unit_price, price, memo}= rowsData;
    return (
      <tr key={index}>
        <td>{index+1}</td>
       
        <td>
          {product_name}
           
        </td>
        <td>
          {quantity}
            
        </td>
        <td>
          {unit_price}
           
        </td>
        <td>
          {price}
           
        </td>

        <td>
          {memo}
           
        </td>
      </tr>
    );
  });
}

const OrderView = ({data, line}) => {  
  const { doc_no } = useParams();
  const [showSign, setShowSign] = useState(false);

  const editorRef = useRef();

  const [user, setUser] = useState([]);
  const [name, setname] = useState("");
  const [userNo, setUserNo] = useState("");
  const [orderNo, setOrderNo] = useState("");

  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [absent, setAbsent] = useState([]);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);


  const [values, setValues] = useState(initialValues);

  

  const selectHandle = (e) => {
    e.preventDefault();
    setOption(e.target.value);
  };

  const toggleShow = () => {
    setShow(!show);
};
  const onTypeChange = e => {
    setType(e.target.value)
  }
  const endDateChange = e => {
    setEndDate(e.target.value)
  }
  const { state } = useLocation();
  useEffect(() => {
  
}, []);



function popupwindow(url, title, w, h) {
  var left = (window.screen.width/2)-(w/2);
  var top = (window.screen.height/2)-(h/2);
  return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
} 
const togglePopup = () => {

  setShowSign(!showSign);

  
  //
};

const openSign = () =>  {
  if (data.secured === 1){
    let pw = prompt("결재 암호를 입력하세요", "");
    if (pw === user.e_sign_pw){
      togglePopup();
    }else{
      alert("암호가 일치하지 않습니다.")
    }
    
  }else{
    togglePopup();
  }
}
 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {
 
    /*values.arr = rows;

    console.log("values........", values);
    axios.post('https://gw.thegmmedical.com:5003/api/orderinsert', values)
      .then(function (response){
        console.log(response);
        //alert("메뉴 바로가기에 추가되었습니다.");

      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
   */



  };

  const handleDraftButton = () => {
 
    console.log(editorRef.current?.getInstance().getHTML());
    if (subject === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (
      editorRef.current?.getInstance().getHTML() ===
      '<p><br className="ProseMirror-trailingBreak"></p>'
    ) {
      alert("내용을 입력해주세요.");
      return false;
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
              board_name: boardName,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`date_time`, date_time);
    formData.append(`subject`, subject);
    formData.append(`file`, mailFile);
    formData.append(`is_notice`, type);
    formData.append(`name`, "변수연");
    formData.append(`bg_no`, state.board_no);
    formData.append(`end_date`, endDate);
    formData.append(`category`, option);
    if (draftNo !==undefined){
      formData.append(`draft_no`, draftNo);
    }
    const fetch = () => {
      axios
        .post("https://gw.thegmmedical.com:5003/api/draftinsert", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response){
          setDraftNo(response.data);
          //window.location.href="http://gw.thegmmedical.com/board";
          //console.log("...." + data);
        });
    };
    fetch();



  };
  const fileChangedHandler = (event) => {
    //let file_size = event.target.files[0].size;
  
    var newFiles = [];
    const LargeFs = [];
    var file__size=0;
    var fs = 0;
    for(let i = 0; i < event.target.files.length; i++){
      file__size+=event.target.files[i].size;
      
      //filesize+=event.target.files[i].size;
      console.log(fileSize);
      if ((fileSize+file__size)>20971520 || file__size > 20971520){
        LargeFs.push(event.target.files[i]);
        
       
      }else{
        
        fs+=event.target.files[i].size;
        newFiles.push(event.target.files[i]);
        
      }
    }
    setFileSize(fileSize+fs);
    var newArray = fileList.concat(newFiles);
    setLargeFiles(LargeFs);
    setFileList(newArray);
    console.log(largeFiles);
  
  };

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  const downloadEmployeeData = () => {
    fetch('https://gw.thegmmedical.com:5003/api/download')
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = '이호MH호텔_셀렉 A컷.zip';
                    a.click();
                });
                //window.location.href = response.url;
        });
  }
  const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";


  const [mailFile, setMailFile] = useState();
  const [isVerified, setIsVerified] = useState();

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    values.arr = rows;

  };

  const [rows, initRow] = useState([]);
  console.log("....", data);
  useEffect(() => {
    console.log("line.........", line);
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/order/'+data)
    .then(function (response){
      console.log("order.........",response.data[0]);

      if (response.data[0]!==undefined){

      setValues(response.data[0]);
      let order_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/order/'+order_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({product_name:'', quantity:'',unit_price:'', price:'', memo:''});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
    axios.get('https://gw.thegmmedical.com:5003/api/getuser')
    .then(function (response){
      console.log("user is", response.data);
    setUser(response.data[0]);
    var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
    setname(name);
    setUserNo(response.data[0].user_no);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/checkabsent')
    .then(function (response){
      console.log("absent is", response.data);
      var user_nos = response.data.map(function(item) {
        return item['user_no'];
      })
    console.log("absent...", user_nos);
    setAbsent(user_nos);
    
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
  }, []);


  const addRowTable = () => {
    const data = {
        product_name:'', quantity:'',unit_price:'', price:'', memo:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);

    values.arr = rows;

  };


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    {showSign && absent.includes(user.user_no)? (
  <Sign docNo= {data} orderNo = {orderNo} subject="부품 발주서" decider = {user} userNo = {userNo} closePopup={() => togglePopup()} />
  ) : null}
{showSign && !absent.includes(user.user_no)? (
  <Sign docNo= {data} orderNo = {orderNo} subject="부품 발주서" decider = {undefined} userNo = {userNo} closePopup={() => togglePopup()} />
  ) : null}
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

            <div className="order_write" style={{padding:"50px", boxSizing:"border-box",margin:"20px auto"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
      
                
                  <table className="header" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            발주처
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.order_client}
                          </td>

                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병원명
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.hospital_name} 
                          </td>
                        </tr>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            발주일 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.order_date}
                          </td>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            주소지 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.addr} 
                          </td>
                          
                        </tr>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                             
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          </td>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            담당자 
                          </th>
                          <td colSpan="3" style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.pic}
                          </td>

                        </tr>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            발주자
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.issuer}
                          </td>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            연락처 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.pic_contact}
                          </td>
                        </tr>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            연락처 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.issuer_contact}
                          </td>
                         
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            택배/직납 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.delivery}
                          </td>

                        </tr>
                      </tbody>
                    </table>
                
            
                <table className="form_list order_list" style={{borderCollapse: 'collapse', width:"100%"}}>
                 
          <thead>
            <tr>
              <th>No</th>
              <th>품명</th>
              <th>수량</th>
              <th>단가</th>
              <th>금액</th>
              <th>비고</th>
            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />
           
          </tbody>
        </table>
              </div>
        
        
      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default OrderView;

