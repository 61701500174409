import { NavLink, Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/SidebarSetting";
import axios from "axios";
axios.defaults.withCredentials = true; 

const Home = () => {
  const [pw, setPw] = useState("");
  const [changePw, setChangePw] = useState("");
  const [checkPw, setCheckPw] = useState("");

  function reply(){

    const msg = {
      subject : "subject",
      date : "date",
      html : "html"

    }
    console.log(msg);
  fetch("https://gw.thegmmedical.com:5003/send", {
    method: "POST", // 통신방법
    headers: {
      "content-type": "application/json",
    },
    credentials: "include",
    body:msg,
  })
    .then((res) => res.json())
    .then((json) => {
      //setData(json);
      console.log(json);
    })
    .catch(() => {});
 
  }
const onChangePw = (e) => {
    	setPw(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangePwChange = (e) => {
    	setChangePw(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    const onChangePwCheck = (e) => {
    	setCheckPw(e.target.value)		//이벤트 발생한 value값으로 {text} 변경
    }
    var checkPassword = function(password){
      return !!password.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%* #+=\(\)\^?&])[A-Za-z\d$@$!%* #+=\(\)\^?&]{3,}$/);
  };


  function chkPW(password){
    var num = password.search(/[0-9]/g);
    var eng = password.search(/[a-z]/ig);
    var spe = password.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
   
    if(password.length < 8 || password.length > 20){
   
     alert("8자리 ~ 20자리 이내로 입력해주세요.");
     return false;
    }else if(password.search(/\s/) != -1){
     alert("비밀번호는 공백 없이 입력해주세요.");
     return false;
    }else if(num < 0 || eng < 0 || spe < 0 ){
     alert("영문,숫자, 특수문자를 혼합하여 입력해주세요.");
     return false;
    }else {
     console.log("통과"); 
       return true;
    }
   
   }

  const updatepw = () =>  {
    if (changePw !== checkPw){
      document.getElementById('check').innerHTML='비밀번호가 일치하지 않습니다.';
      document.getElementById('check').style.color='red';
      return false;
    }else{
      document.getElementById('check').innerHTML='';
      if (!checkPassword(changePw)){
        document.getElementById('check').innerHTML='비밀번호 규칙에 어긋납니다.';
        document.getElementById('check').style.color='red';
      }else{
        document.getElementById('check').innerHTML='';
      }
    }
    if (chkPW(changePw)===false){
      return false;
    }
    const msg = {
      pw : pw,
      changePw : changePw,

    }
    axios.post('https://gw.thegmmedical.com:5003/api/updatepw',msg)
    .then(function (response){
      console.log(response);
      alert("비밀번호가 변경되었습니다.");
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
      alert("비밀번호가 맞지 않습니다");
    })
    .then(function (){
      //always executed
    });
    
  }
  return (
    <div className="wrapper">
        <Sidebar/>
        <div className="setting_wrap user_info">
        <h2 id="title" class="screen_out">비밀번호 변경</h2>
        <table style={{border:"1px solid #ddd"}}>
	<tbody><tr height="34">
		<td nowrap="" class="table_title2_topline" align="right">* 기존 비밀번호</td>
		<td class="">
    <input type="password" id="prepwd" value={pw} onChange={onChangePw}
    name="prepwd" size="90%" maxlength="20" class="tform"placeholder="기존 비밀번호를 입력하세요."/></td>
	</tr>
	<tr height="34">
		<td nowrap="" class="table_title2_topline" align="right">* 변경 비밀번호</td>
		<td class="">
    <input type="password" name="pwd" value={changePw} onChange={onChangePwChange}
    size="90%" maxlength="20" class="tform"
            placeholder="새로운 비밀번호를 입력하세요.(최대20자)"/></td>
	</tr>
	<tr height="34">
		<td nowrap="" class="table_title2_topline" align="right">* 변경 비밀번호 확인</td>
		<td class="">
    <input type="password" name="repwd" value={checkPw} onChange={onChangePwCheck}
    size="90%" maxlength="20" class="tform"
             placeholder="검증을 위해, 한번더 입력하세요.(최대20자)"/>
    <span id="check"></span>         
    </td>
	</tr>
	<tr height="30">
		<td nowrap=""class="table_title2_topline" align="right">비밀번호 규칙</td>
		<td class="" style={{fontSize:"13px"}}>
            ① 최소 9자 이상되어야 합니다.<br/>② 영문자는 반드시 1개 이상 존재해야 합니다.<br/>
            ③ 특수문자가 반드시 1개 이상 존재해야 합니다.<br/>&nbsp;&nbsp;&nbsp;&nbsp;(?물음표,&amp;엔퍼센트,%퍼센트,싱글쿼테이션,더블쿼테이션은 사용할 수 없습니다)
            <br/>④ 숫자가 반드시 1개 이상 존재해야 합니다.<br/></td>
	</tr>
	
</tbody></table>
<div style={{position:"relative", width:"100%"}}>
          <button className="submit" 
          style={{ width:"80px",position:"absolute", top:"30px",left:"0", right:"0", margin:"auto"}}
          onClick={()=>updatepw()}>저장</button>
       </div>
</div>
    </div>

  );
};

export default Home;