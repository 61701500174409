import "./css/login.css";
import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import login from "./img/login.jpeg";
import firebase from "firebase";
const cookies = new Cookies();
axios.defaults.withCredentials = true; 

const AdminLogin = () => {
  let body  = document.getElementsByTagName("BODY")[0];let width = body.offsetWidth;

  const [user_id, setUserId] = useState("");
  const userIdChange = (event) => {
    setUserId(event.target.value);
  };

  const [password, setPassword] = useState("");

  const passwordChange = (event) => {
    setPassword(event.target.value);
  };
  const onCheckEnter = (e) => {
    if(e.key === 'Enter') {
      onLogin();
    }
  }
  useEffect(() => {
    /*let login_attempt = cookies.get('login_attempt');
    if (login_attempt === undefined){
      cookies.set('login_attempt', Number(0), { maxAge: 3600 * 1000, sameSite: true });
    }
    let isloggedIn = sessionStorage.getItem("isloggedIn");
    if (isloggedIn === null) {
      console.log(isloggedIn);
      isloggedIn = cookies.get("isloggedIn");
      if (isloggedIn === "true") {
        navigate("/board");
      }
    }
    if (isloggedIn === "true") {
      navigate("/board");
    }*/
  }, []);

  async function onLogin(e) {
    const data = {
      user_id: user_id,
      password: password,
    };
    if (user_id.length === 0) {
      alert("아이디를 입력해주세요");
      return;
    }
    if (password.length === 0) {
      alert("비밀번호를 입력해주세요");
      return;
    }
    
  
    
    axios.get("https://gw.thegmmedical.com:5003/api/get_err_cnt/"+user_id, {

    })
    .then((res) => {
      if (res.data.length===0){
        console.log("nnn");
        axios.post("https://gw.thegmmedical.com:5003/api/login", data, {
          withCredentials: true,
          credentials: "include", // Now this is was the missing piece in the client side
        })
        .then((res) => {
          window.location.href="https://gw.thegmmedical.com";
          
        })
        .catch((error) => {
          //cookies.set('login_attempt', Number(login_attempt)+1);
          alert("로그인에 실패하였습니다.");
        });
      }
      if (res.data[0].is_blocked === 1){
        return alert("로그인 시도 차단됨.");
      }else{
        console.log("hhh");
        axios.post("http://gw.thegmmedical.com:5003/api/login", data, {
        })
        .then((res) => {
          window.location.href="https://gw.thegmmedical.com";
        })
        .catch((error) => {
          //cookies.set('login_attempt', Number(login_attempt)+1);
          console.log("error...", error);
          alert("로그인에 실패하였습니다.");
        });
      }
    })
    .catch((error) => {
    });
  }

  /*onSilentRefresh = () => {
        axios.post('/silent-refresh', data)
            .then(onLoginSuccess)
            .catch(error => {
                // ... 로그인 실패 처리
            });
    }
    
    onLoginSuccess = response => {
        const { accessToken } = response.data;
    
        // accessToken 설정
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    
        // accessToken 만료하기 1분 전에 로그인 연장
        setTimeout(onSilentRefresh, JWT_EXPIRRY_TIME - 60000);
    }*/
  
    const requestPermission = async() => {
      let permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted. Requesting for token.");

        if (firebase.messaging.isSupported()){ 
          let messaging = firebase.messaging(); 
          let token = await messaging.getToken({
            vapidKey: "BHBOyeWYKkwGfvXolZnj3C7VMMGABSRDZKuQnMovXtad4Un4MRF6jFMY0LWaW2wbwQQLtEh9zVVjt_bgokjYhwQ",
          });
          alert(token);
          const data = {
            token: token
          }
            axios.post('http://gw.thegmmedical.com:5003/api/updateTokenTEST', data).then(function (response){
            console.log("updated");
            })
            .catch(function (error){
              console.log(error);
            })
              .then(function (){
            });
        }
       
        // do something with the FCM token
      } else {
        console.log("Notification permission denied");
        // Handle denied permission
      }
    }
  
  return (
    <div className="wrap">
   
    <div className="login_wrap" style={{display:"flex", height:"100vh"}}>
    

    
    <div className="login1">
      <img src={login} alt="login.jpg"></img>
    </div>
    
    <div className="login2">
    <form onKeyPress={onCheckEnter} style={{width:"100%"}}>
    <div className="login">
      <h2 className="headerTxt">로그인</h2>

      <div className="loginForm">
        <div className="user_id">
          <input
            text="user_id"
            id="user_id"
            placeholder="아이디를 입력해주세요"
            onChange={userIdChange}
            value={user_id}
          />
          <br />
        </div>
        <div className="password">
          <input
            text="password"
            id="password"
            type="password"
            placeholder="비밀번호를 입력해주세요"
            onChange={passwordChange}
            value={password}
          />
          <br />
        </div>
        <div className="div_button">
          <div className="btn-st2" onClick={onLogin}>
            로그인
          </div>
        </div>
      </div>
    </div>
    </form>
    </div>
    </div>
    
  </div>
  );
};

export default AdminLogin;
