import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

import "./css/order.css";
import { left } from "@popperjs/core";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 


function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {type, summary, part, quantity, unit_price, total, memo}= rowsData;


    return (
      <tr key={index}>
       
        <td>
          {type}
          
        </td>
        <td>
          {summary}
           
        </td>
        <td>
          {part}
         
        </td>
        <td>
          {quantity}
           
        </td>

        <td>
          {unit_price}
          
        </td>
        <td>
          {total}
            
        </td>
        <td>
          {memo}
           
        </td>
      
      </tr>
    );
  });
}

const ServiceView = ({data, line}) => {  
  

  const [links, setLinks]=useState([]);
  const [select, setSelect] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState([]);

  
 
 
 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/service/'+data)
    .then(function (response){
      console.log("order.........",response.data[0]);
      if (response.data.length>0){

      setValues(response.data[0]);
      let order_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/service/'+order_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  }, []);




  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     
     

    

            <div className="order_write forms" style={{padding:"50px", boxSizing:"border-box",margin:"20px auto"}}>
          

      <div className="service">
        <div style={{fontFamily: '돋움', display: 'flex', justifyContent: 'space-between', marginTop: '0px', marginBottom: '0px'}}>
          <div style={{border:"1px solid #ddd", display:"flex"}}>
                <div style={{padding:"10px"}}><h2>고객 서비스 카드</h2><br></br><span>[A/S 기록표]</span></div>
                <table border="1" style={{borderCollapse:"collapse"}} className="table1">
                        <tr><th>접수번호</th>
                        <td>
                        {values.receipt_num} 
                        </td>
                        </tr>
                        <tr><th>점 검 자</th>
                        <td>
                        {values.inspector} 

                        </td>
                        </tr>
                        <tr><th>점 검 일</th>
                        <td>
                        {values.inspect_date}

                        </td>
                        </tr>
                        <tr><th>종 료 일</th>
                        <td>
                        {values.end_date}

                        </td>
                        </tr>


                </table>
          </div>

          
        </div>
        <table className="" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '30px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th>
                            병원명
                          </th>
                          <td colSpan={3} style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.hospital_name}
                          </td>

                          <th>
                            모델명
                          </th>
                          <td colSpan={3} style={{ border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.model}
                          </td>

                          <th>
                            설치일
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.install_date}
                          </td>

                        </tr>
                        <tr>
                        <th>
                            시리얼 
                          </th>
                          <td colSpan={9} style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.serial_num}
                          </td>
                     
                          
                        </tr>
                        <tr>
                        <th>
                           작업유형  
                        </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.as_type}

                          </td>
                          <th>
                            보증유형 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.warranty_type} 
                          </td>

                          <th>
                            서비스 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.service}
                          </td>
                          <th>
                            랭크 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.rank} 
                          </td>

                          <th>
                            금액 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            {values.price}
                          </td>

                        </tr>
                        <tr>
                        <th>
                            접수내용
                          </th>
                          <td colSpan={9} style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.content}
                          </td>
                        
                        </tr>
                  
                      </tbody>
                    </table>
                
              <table className="table2" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '30px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                    <thead>
                      <tr>
                        <th></th>
                        <th colSpan={4}>일자</th>
                        <th>내역</th>
                      </tr>
                    </thead>
                      <tbody>
                        <tr>
                          <th>
                            점검
                          </th>
                          <td>
                          </td>

                          <td>
                            {values.inspection_date}
                          </td>

                          <td>
                          </td>

                          <td>
                          </td>


                          <td>
                          {values.inspection_details}

                          </td>

                        

                        </tr>
                     
                        <tr>
                          <th>
                            견적안내
                          </th>
                          <td>
                          </td>
                          <td>
                            {values.estimate_date}
                          </td>

                          <td>
                          </td>

                          <td>
                          </td>


                        <td>
                        {values.estimate_details}

                        </td>

                        

                        </tr>

                        <tr>
                          <th>
                            작업진행
                          </th>
                          <td>
                          </td>
                          <td>
                            {values.proceed_date}
                          </td>

                          <td>
                          </td>

                          <td>
                          </td>


                        <td>
                        {values.proceed_details}

                        </td>

                        

                        </tr>
                        <tr>
                          <th>
                            작업종료
                          </th>
                          <td>
                          </td>
                          <td>
                            {values.complete_date}
                          </td>

                          <td>
                          </td>

                          <td>
                          </td>


                        <td>
                        {values.complete_details}

                        </td>

                        

                        </tr>
                      <tr><th colSpan={6}>세부내역 및 특이사항</th></tr>
                      <tr><td colSpan={6}>
                        {values.other_details}
                        </td></tr>
                      </tbody>
                    </table>
                 
              
                    <table className="table3" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                    <thead>
                      <tr style={{border:"none"}}><td colSpan={7}>
                      <span>구분(적요) : 수리(수리 등) | 관리(정기/개선/예방 등) | 기타(고객 요청/기타설치/외부요인 등)</span>

                        </td></tr>
                      <tr>
                        <th>구분</th>
                        <th>적요</th>
                        <th>부품</th>
                        <th>수량</th>
                        <th>단가</th>
                        <th>계</th>
                        <th>비고</th>

                      </tr>
                    </thead>
                      <tbody>
                      <TableRows
                      rows={rows}
              
                      />
                      </tbody>
                    </table>



                <table className="" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */}                       
                  <tr>
                    <td style={{minWidth:"unset",width:"20px", textAlign:"center"}}>*</td>
                      <td style={{textAlign:"center", padding:"5px", fontWeight:"bold"}}>
                      수리에 관한 보증은 동일한 부위 같은 부품에 한하여 3개월간 보증합니다.<br></br>
                      그러나 낙하/충격 등 사용 중 부주의로 인한 고장 발생 시 보증 제외되며, 유상 청구됩니다.
                      
                      </td>
                    <td style={{minWidth:"unset",width:"20px", textAlign:"center"}}>*</td>
                  </tr>
                </table>
                    <p>폐사에서 제공하는 서비스에 대한 만족도를 평가해 주시기 바랍니다.</p>
                    <table className="table4" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                    <thead>
                     
                        <th>서비스 고객만족도 항목</th>
                        <th>매우 만족</th>
                        <th>만족</th>
                        <th>보통</th>
                        <th>불만족</th>
                        <th>매우 불만족</th>
                    </thead>

                    <tbody>
                      <tr>
                        <td>1. 고객의 제품에 대한 방문서비스에 전반적으로 만족하셨나요?</td>
                        <td>
                          <input type="checkbox" name="q1" value="1" checked={values.q1 === "1"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q1" value="2" checked={values.q1 === "2"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q1" value="3" checked={values.q1 === "3"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q1" value="4" checked={values.q1 === "4"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q1" value="5" checked={values.q1 === "5"} disabled/>
                        </td>
                      </tr>

                      <tr>
                        <td>2. 방문 서비스를 신청하시기에 편리했나요?</td>
                        <td>
                          <input type="checkbox" name="q2" value="1" checked={values.q2 === "1"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q2" value="2" checked={values.q2 === "2"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q2" value="3" checked={values.q2 === "3"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q2" value="4" checked={values.q2 === "4"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q2" value="5" checked={values.q2 === "5"} disabled/>
                        </td>
                      </tr>
                      <tr>
                        <td>3. 서비스를 실시한 담당자가 고객의 요구사항에 대한 응대태도가 친절하였나요?</td>
                        <td>
                          <input type="checkbox" name="q3" value="1" checked={values.q3 === "1"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q3" value="2" checked={values.q3 === "2"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q3" value="3" checked={values.q3 === "3"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q3" value="4" checked={values.q3 === "4"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q3" value="5" checked={values.q3 === "5"} disabled/>
                        </td>
                      </tr>
                      <tr>
                        <td>4. 해당 제품의 부품 및 기계 작동에 대한 서비스 후 작동이 잘 되었나요?</td>
                        <td>
                          <input type="checkbox" name="q4" value="1" checked={values.q4 === "1"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q4" value="2" checked={values.q4 === "2"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q4" value="3" checked={values.q4 === "3"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q4" value="4" checked={values.q4 === "4"} disabled/>
                        </td>
                        <td>
                        <input type="checkbox" name="q4" value="5" checked={values.q4 === "5"} disabled/>
                        </td>
                      </tr>

                      <tr>
                        <td>★ 서비스 개선을 위한 건의 사항이 있다면 남겨주세요.</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          ({values.suggest})
                        </td>
                      </tr>

                    </tbody>
                    </table>

                <table className="" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */}                       
                  <tr>
                    <td style={{height:"40px", textAlign:"center"}}>소속</td>
                    <td style={{textAlign:"center", padding:"5px", fontWeight:"bold"}}>
                      <input type="text"></input>
                      
                    </td>
                    <td style={{height:"40px", textAlign:"center"}}>확인자</td>
                    <td style={{textAlign:"center", padding:"5px", fontWeight:"bold"}}>
                      <input type="text"></input>
                      
                    </td> 
                    <td style={{textAlign:"center"}}>(인)</td>                 
                  </tr>
                </table>

      </div>
      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
    </div>

  );
};

export default ServiceView;

