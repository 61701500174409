
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./css/adminMail.css";
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/side/MobileSidebar";
import PopupEditor from "../components/PopupEditor";

const AdminLevel = () => {
  const [members, setMembers] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectLevel, setSelectLevel] = useState();
  const [CheckList, setCheckList] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [showPopup, setShowPopup]  = useState(false);
  const [signUse, setSignUse]  = useState("0");
  const [footerUse, setFooterUse]  = useState("0");
  const [footerContent, setFootercontent]=useState("");
  const [signContent, setSignContent]=useState("");

  const ref = useRef([]);

  function popupwindow(url, title, w, h) {
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 

  useEffect(() => {
    axios.get("https://gw.thegmmedical.com:5003/api/get_mail_footer").then((response) => {
      setFootercontent(response.data[0].content);
      setFooterUse(response.data[0].in_use.toString());
    });

    axios.get("https://gw.thegmmedical.com:5003/api/get_mail_sign").then((response) => {
      setSignContent(response.data[0].content);
      setSignUse(response.data[0].in_use.toString());
    });

  }, []);

 

 const toggleUser = () => {
  setShowPopup(!showPopup);
};
  const saveSetting = () =>{
    const msg = {
      signUse : signUse,
      footerUse : footerUse,
      footerContent : footerContent
    }
    axios.post("https://gw.thegmmedical.com:5003/api/add_footer", msg).then((response) => {
      // setNotice(response.data);
       alert("저장되었습니다.")
    });
  }
  return (
    <div className="wrapper" style={{width:"100%",backgroundColor:"#f7f7f7", minHeight:"100vh"}}>
    {sidebar && ( <Sidebar style={{display:"block"}}/>)}
     
    <div className="menu_icon" style={{width:"100%", height:"30px", zIndex:"1000", position:"relative"}}>
      <RxHamburgerMenu className={(sidebar ? "active" : "")} onClick={()=>setSidebar(!sidebar)} style={{zIndex:"1000"}}/>
      </div>
{showPopup ? (
  <PopupEditor onChange={(value)=>setSignContent(value)}closePopup={() => {
    toggleUser();
  }}/>
) : null}
     <div className="contents">
        <div className="header_txt">
        <h3>메일주석 관리</h3>
        </div>
    
        <div className="mail_sign" style={{margin:"20px 0", display:"flex", flexDirection:"column", 
        alignItems:"center", justifyContent:"center"}}>
        <table style={{border:"1px solid #ddd", marginTop:"20px"}} className="mail_sign_table">
	<tbody><tr height="34">
		<th nowrap="" width="26%" height="30" class="table_title2_topline" align="right">* 회사기본 서명 사용 여부</th>
		<td class="">
    <input type="radio" value="1" name="sign_use" checked={signUse === "1"} onChange={(e)=>setSignUse(e.target.value)}/><label><span>사용</span>
      </label>
      <label>
        <input type="radio" value="0" name="sign_use" checked={signUse === "0"} onChange={(e)=>setSignUse(e.target.value)}/><span>사용안함</span>
      </label>
    </td>
	</tr>
	<tr height="34">
		<th nowrap="" class="table_title2_topline" align="right">* 서명 <button onClick={()=>toggleUser()}>추가</button></th>
		<td class="">
    <div
      dangerouslySetInnerHTML={{__html: signContent}} id="sample"
    />

    </td>
	</tr>
	<tr height="34">
		<th nowrap="" class="table_title2_topline" align="right">* 자동 Footer 사용 여부</th>
		<td class="">
      
        <input type="radio" value="1" name="in_use" checked={footerUse === "1"} onChange={(e)=>setFooterUse(e.target.value)}/><label><span>사용</span>
      </label>
      <label>
        <input type="radio" value="0" name="in_use" checked={footerUse === "0"} onChange={(e)=>setFooterUse(e.target.value)}/><span>사용안함</span>
      </label>
    <span id="check"></span>         
    </td>
	</tr>
	<tr height="30">
		<th nowrap=""class="table_title2_topline" align="right">자동 Footer 내용</th>
		
    <td class="" style={{fontSize:"13px"}}>
    <textarea value={footerContent} onChange={(e)=>setFootercontent(e.target.value)}></textarea>
    
    </td>
	</tr>
	
</tbody></table>

<button className="submit" onClick={()=>saveSetting()}
        
        >저장</button>
<div>
         
       </div>
</div>
       
     </div> 
    </div>

  );
};

export default AdminLevel;
