import { NavLink, Link,useParams, useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailNav from "../components/side/MailNav";
import { VscTrash } from "react-icons/vsc";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import DocList from "../components/side/DocList";
import AutoCompleteLine from "../components/AutoCompleteLine";
import { GiHamburgerMenu } from "react-icons/gi";

axios.defaults.withCredentials = true; 





const OrderList = ({closePopup, onChange}) => {
  const [orders, setOrders] = useState([]);
  const [value, setValue] = useState("");
  const [sidebar, setSidebar] = useState(false);

  useEffect(()=>{
    axios.get('https://gw.thegmmedical.com:5003/api/get_orders')
    .then(function (response){
    console.log("hey result is!!!!!!!!!!!!", response.data);
    setOrders(response.data);

    //setLine(response.data[1]);
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    
    

}, []);  



  function selectOrder(ele){


    axios.get('https://gw.thegmmedical.com:5003/api/get_orders_list/'+ele.no)
    .then(function (response){
    console.log("hey rddddddd!", response.data);
    onChange({order: ele, order_list: response.data});


    //setLine(response.data[1]);
    
    
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });


  }

  return (
<div className="popup">
        <div className="popup_inner drafts" style={{height:"600px"}}>  

   

<div className="line_main" style={{padding:"0 20px"}}>

    <h4>결재완료 발주서</h4>



<div className="row" style={{flexDirection:"column"}}>
<table width="100%" border="0" cellspacing="0" cellpadding="0" className="line_table">
<thead>	
    <tr>

    <th>번호</th>
    <th>제목</th>
    <th>상호명</th>
    <th>발주일</th>

    </tr>

</thead>
<tbody>

{orders.map((element, index)=>(
  <tr id={element.no} style={{cursor:"pointer"}} onClick={()=>{selectOrder(element);}}>
  <td>{element.no}</td>
  <td>{element.subject}</td>
  <td>{element.company}</td>
  <td>{element.order_date}</td>
  </tr>
))}


</tbody>
</table>





</div>
<div className="" style={{display:"flex", justifyContent:"center", 
bottom:"20px", position:"absolute", left:"0", right:"0"}}>
<button onClick={closePopup}>닫기</button>

    </div>


   
      </div>
    </div>
</div>
  );
};

export default OrderList;
