import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    const {bank_name, account_holder, account_no}= rowsData;
    return (
      <tr key={index}>
       <td>
          <input
            type="text"
            value={bank_name}
            onChange={(event) => onValUpdate(index, event)}
            name="bank_name"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={account_holder}
            onChange={(event) => onValUpdate(index, event)}
            name="account_holder"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={account_no}
            onChange={(event) => onValUpdate(index, event)}
            name="account_no"
            className="form-control"
          />
        </td>
    

        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      
      </tr>
    );
  });
}
function AccountInfo({account, onChange }) {
  const [rows, initRow] = useState(account);

  useEffect(() => {
   
    
    
  }, []);

  const addRowTable = () => {
    const data = {
        bank_name:"", account_holder:"", account_no:""
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
    onChange(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);

    onChange(data);
  };


  return (
    <>

<div className="" style={{display:"flex", justifyContent:"flex-end", marginBottom:"20px"}}>

   
<button className="btn" onClick={addRowTable} style={{border:"1px solid"}}>
  추가
</button>
</div>

      <table style={{width:"100%"}} className="info_summary">
        <thead>
          <tr>
                <th>은행명</th>
                <th>예금주</th>
                <th>계좌번호</th>
                <th className="del">삭제</th>

          </tr>
        </thead>
        <tbody>
        <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
          />
        </tbody>
      </table>
{/*<div className="btns" style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>

<button className="btn" onClick={()=>addExperience()}>
          저장
        </button>

  </div>*/}
    </>
  );
}
export default AccountInfo;