import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    const {name, job, tel, email, fax}= rowsData;
    return (
      <tr key={index}>
       <td>
          {name}
            
        </td>
        <td>
          {job}
           
        </td>
        <td>
          {tel}
          
        </td>
     
    
        <td>
          {email}
           
        </td>

        <td>
          {fax}
            
        </td>

        
      </tr>
    );
  });
}
function PICInfo({pic}) {
  const [rows, initRow] = useState(pic);

 


  return (
    <>



      <table style={{width:"100%"}} className="info_summary">
        <thead>
          <tr>
                <th>담당자명</th>
                <th>직위</th>
                <th>전화번호</th>
                <th>이메일</th>
                <th>FAX</th>

          </tr>
        </thead>
        <tbody>
        <TableRows
            rows={rows}
      
          />
        </tbody>
      </table>
{/*<div className="btns" style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>

<button className="btn" onClick={()=>addExperience()}>
          저장
        </button>

  </div>*/}
    </>
  );
}
export default PICInfo;