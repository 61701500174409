import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 



const Request = ({doc_no, onChange, line}) => {  
  

  const [links, setLinks]=useState([]);
  const [select, setSelect] = useState("");

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState([]);

  
 
  useEffect(()=>{
    //This function will run every time the value of values state changes.
    onChange(values); //Will give you the updated value of values state.
    //Do some other stuff
  }, [values])

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    onChange(values);

  };
 


  useEffect(() => {
    if (doc_no!==undefined){
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/rent/'+doc_no)
    .then(function (response){

      if (response.data.length>0){
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);



  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     
     

    

            <div className="order_write forms" style={{padding:"0", margin:"20px auto"}}>
          

      <div className="rent">
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>지출 요구서</td>
                    <td rowSpan={2} className="approve">결재</td>
                    <td className="approve">담당</td>
                    <td className="approve">부장</td>
                    <td className="approve">부사장</td>
                    <td className="approve">대표이사</td>
                  </tr>
                  <tr>
                  <td>
                     {line[0]!==undefined && (<span>{line[0].name}<br/>{line[0].job_name}</span>)}
                    </td>
                    <td>
                    {line[1]!==undefined && (<span>{line[1].name}<br/>{line[1].job_name}</span>)}

                    </td>
                    <td>
                    {line[2]!==undefined && (<span>{line[2].name}<br/>{line[2].job_name}</span>)}
           
                    </td>

                    <td>
                    {line[3]!==undefined && (<span>{line[3].name}<br/>{line[3].job_name}</span>)}
           
                    </td>
                
                  </tr>
                </table>
            
                    <table className="" style={{fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                    <thead>
                      <tr><th style={{textAlign:"center", borderBottom:"1px solid #000",borderTop:"1px solid #000", padding:"5px 0", background:"#f2f2f2"}} colSpan={4}>대여 정보</th></tr>
                    </thead>
                      <tbody>
                      <tr style={{height:"10px"}}></tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            지출건명
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="mgmt_num" value={values.mgmt_num} onChange={handleInputChange}></input>
                          </td>

                          <th style={{padding: '5px',  height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                           
                          </th>
                          <td style={{ textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <span> 1.</span>
                            <input type="text" name="rent_type" value={values.rent_type} onChange={handleInputChange}></input>
                          </td>
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <span> 2.</span>
                            <input type="date" name="request_date" value={values.request_date} onChange={handleInputChange} style={{width:"100px"}}></input>
                          </td>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <span>3. 지출 내역</span>
                            <input type="text" name="purpose" value={values.purpose} onChange={handleInputChange}></input>
                          </td>
                          
                        </tr>
                        <tr style={{height:"10px"}}></tr>

                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                  
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <span>가. 금액 : </span>
                            <input type="text" name="hospital_name" value={values.hospital_name} onChange={handleInputChange}></input>
                          </td>


                         
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <span>지출처</span>
                          <input type="text" name="model" value={values.model} onChange={handleInputChange}></input>
                          </td>


                         
                        </tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <span>지출일자</span>
                            <input type="text" name="mfr_num" value={values.mfr_num} onChange={handleInputChange}></input>
                          </td>


                         
                        </tr>
                       
                       

                        <tr style={{height:"10px"}}></tr>
                      </tbody>
                    </table>

                    <table className="" style={{fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                    <thead>
                      <tr><th style={{textAlign:"center", borderBottom:"1px solid #000",borderTop:"1px solid #000", padding:"5px 0", background:"#f2f2f2"}} colSpan={4}>인수</th></tr>
                    </thead>
                      <tbody>
                      <tr style={{height:"10px"}}></tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            인수일자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="date" name="acquire_date" value={values.acquire_date} onChange={handleInputChange} style={{width:"100px"}}></input>
                          </td>

                        </tr>
                        <tr>
                          <td colSpan={4}><hr></hr></td>
                        </tr>
                        <tr>
                          <td colSpan={2}></td><td colSpan={2}>상기 장비를 인수하였음을 확인합니다.</td>
                        </tr>

                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            대여자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="borrower" value={values.borrower} onChange={handleInputChange}></input>
                          </td>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병원명 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="hospital1" value={values.hospital1} onChange={handleInputChange}></input>
                          </td>
                          
                        </tr>
                        <tr style={{height:"10px"}}></tr>

                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            담당자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="borrower_incharge" value={values.borrower_incharge} onChange={handleInputChange}></input>
                          </td>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            담당자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle', display:"flex"}}>
                          <input type="text" name="hospital_incharge1" value={values.hospital_incharge1} onChange={handleInputChange}></input><span>(인)</span>
                          </td>
                          
                        </tr>
 
                        <tr style={{height:"10px"}}></tr>
                      </tbody>
                    </table>
              
                    <table className="" style={{fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '20px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                    <thead>
                      <tr><th style={{textAlign:"center", borderBottom:"1px solid #000",borderTop:"1px solid #000", padding:"5px 0", background:"#f2f2f2"}} colSpan={4}>반납</th></tr>
                    </thead>
                      <tbody>
                      <tr style={{height:"10px"}}></tr>
                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            반납일자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="date" name="return_date" value={values.return_date} onChange={handleInputChange} style={{width:"100px"}}></input>
                          </td>

                        </tr>
                        <tr>
                          <td colSpan={4}><hr></hr></td>
                        </tr>

                        <tr>
                          <td colSpan={2}></td><td colSpan={2}>상기 장비를 반납하였음을 확인합니다.</td>
                        </tr>

                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            대여자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="borrower" value={values.borrower} onChange={handleInputChange}></input>
                          </td>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병원명 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="hospital1" value={values.hospital1} onChange={handleInputChange}></input>
                          </td>
                          
                        </tr>
                        <tr style={{height:"10px"}}></tr>

                        <tr>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            담당자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="borrower_incharge" value={values.borrower_incharge} onChange={handleInputChange}></input>
                          </td>
                          <th style={{padding: '5px', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            담당자 :
                          </th>
                          <td style={{textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle', display:"flex"}}>
                          <input type="text" name="hospital_incharge1" value={values.hospital_incharge1} onChange={handleInputChange}></input><span>(인)</span>
                          </td>
                          
                        </tr>
 
                        <tr style={{height:"10px"}}></tr>
                      </tbody>
                    </table>
              

      </div>

      </div>
      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
    </div>

  );
};

export default Request;

