import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import { Sheet, Op, Selection, colors } from "@fortune-sheet/core";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"


import axios from "axios";
import moment from "moment";
import {gapi} from 'gapi-script';

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../components/ScheduleDraftsList";
import ScheduleList from "../components/side/ScheduleList";
import {GiHamburgerMenu} from "react-icons/gi";
import { BsPin } from "react-icons/bs";
import {
  format
  
} from "date-fns";



//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 

var today = new Date();

const initialValues = {
  subject: "",
  delivery_date:"",
  place:"",
  note:""
};



function TableRows({ rows, tableRowRemove, onValUpdate, addRowTable }) {
  return rows.map((rowsData, index) => {
    const {name, quantity}= rowsData;
    return (
      <div style={{display:"flex"}}>
  
      <input type="text" name="name" className="formInput" value={name} onChange={(event) => onValUpdate(index, event)}
      placeholder="물품명"></input>
      <input type="text" name="quantity" className="formInput" value={quantity} onChange={(event) => onValUpdate(index, event)}
      placeholder="수량"
      style={{width:"100px"}}></input>
      
      {index !== 0 ? <span
            onClick={() => tableRowRemove(index)}
      style={{fontSize:"11px"}}
      >
            x
          </span> : <span onClick={()=>{addRowTable();}}>+</span>}


    </div>
  
    );
  });
}



export default function ScheduleWrite({date, delivery, closePopup}) {
  const { state } = useLocation();
  const hour = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  const [sidebar, setSidebar] = useState(false);
  const editorRef = useRef();
  const excelRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [div, setDiv]=useState() ;
  const [members, setMembers] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [imgLink, setImgLink] = useState("");
  const [excelData, setExcelData] = useState();
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [editor, setEditor] = useState();
  
  const [boards, setBoards] = useState([]);
  const [allday, setAllday] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [dateN, setDateN] = useState("");
  const [nth, setNth] = useState("");
  const [nthDay, setNthDay] = useState("");
  const [nthDayKr, setNthDayKr] = useState("");
  const [calendars, setCalendars]=useState([]);
  const [teamCalendars, setTeamCalendars]=useState([]);
  const [userCalendars, setUserCalendars]=useState([]);
  const [groupCalendars, setGroupCalendars]=useState([]);
  const [HTML, setHTML]=useState();
  const [rows, initRow] = useState([{name:'', quantity:''}]);

  var CLIENT_ID = "103373930583-rnu1qv7i5vr7ng7b2ma5o5r2p9vaiqu4.apps.googleusercontent.com"
  var API_KEY = "AIzaSyCDeeCLkx3dIqf-6aQ_CRJ6vopUxJXwnzE"
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar"

  const addRowTable = () => {
    const data = {
      name:'', quantity:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);


    values.arr = rows;




  };

  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
   
    const data = [...rows];
    data[i][name] = value;
    initRow(data);


    values.arr = rows;

    console.log(values);

  };

  const addEvent = () => {

   //gapi.load("client:auth2", () => { gapi.client.init({ clientId: "*****.apps.googleusercontent.com", plugin_name: "chat", }); });




  }



  useEffect(() => {
    console.log("state...........",delivery);
    if (delivery.product !=='undefined'){
      initRow(JSON.parse(delivery.product));
    }
    
    setValues(delivery);

}, []);

function getNth(dat) {
  var days = ['일', '월', '화', '수', '목', '금','토'],
      days_en  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'],
      d    = dat ? dat instanceof Date ? dat : new Date(dat) : new Date(),
      date = d.getDate(),
      day  = d.getDay(),
      n    = Math.ceil(date / 7);
  setNth(n);
  setNthDay(days_en[day]);
  setNthDayKr(days[day]);
  return n + ' ' + days[day];
}

const handleInputChange = (e) => {
  //const name = e.target.name 
  //const value = e.target.value 
  const { name, value } = e.target;


  
  if (name === "start_date"){
    let date = value.split("-");
    let from = new Date(date[0], parseInt(date[1]) - 1, date[2]);
    let day = from.getDay();
    let days  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
    setValues({
      ...values,
      start_date:value,
      rep_week_day: days[day],
    });
    values.rep_week_day = days[day];
    values.start_date=value;
  }
  if (name === "rep_cycle" && value=== "MONTHLY"){
    let d1 = values.start_date.split("-");
    setDateN(d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }else if (name === "rep_cycle" && value=== "YEARLY"){
    let d1 = values.start_date.split("-");
    setDateN(d1[1] +"-"+ d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }

  if (name === "start_date" && values.rep_cycle=== "MONTHLY"){
    let d1 = value.split("-");
    setDateN(d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }else if (name === "start_date" && values.rep_cycle=== "YEARLY"){
    let d1 = value.split("-");
    setDateN(d1[1] +"-"+ d1[2]);
    let start_date = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    getNth(start_date);
  }
  if (name === "start_date" && values.end_date===""){
    values.end_date = value;
    setValues({
      ...values,
      end_date: value,
      start_date:value
    });
  }


  


  if (name === "start_date" && values.end_date!=="" && value > values.end_date){
    values.end_date = value;
    
    setValues({
      ...values,
      end_date: value,
      start_date:value
    });
  }

  if (name === "end_date"){

    setValues({
      ...values,
      rep_end: "keep",

    });
    values.rep_end = "keep";
    setValues({
      ...values,
      [name]: value,
    });

  }
  if (name === "rep_end" && value === "keep"){
    setValues({
      ...values,
      end_date: "",
      times:"",
    });
    values.end_date = "";
    values.times = "";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "times" && value !== ""){
    setValues({
      ...values,
      end_date: "",
      rep_end: "times",
    });
    values.end_date = "";
    values.rep_end = "times";
    setValues({
      ...values,
      [name]: value,
    });
  }
  if (name === "rep_cycle" && value === "WEEKLY"){
    let date = values.start_date.split("-");
    let from = new Date(date[0], parseInt(date[1]) - 1, date[2]);
    let day = from.getDay();
    let days  = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
    setValues({
      ...values,
      rep_week_day: days[day],
    });
    values.rep_week_day = days[day];
    setValues({
      ...values,
      [name]: value,
    });
  }

  setValues({
    ...values,
    [name]: value,
  });
  
  if (name === "group"){
    setValues({
      ...values,
      group_str : value,
      group: JSON.parse(value).no,
      calendarID : JSON.parse(value).calendarID
    });
    values.group_str = value;
    values.group = JSON.parse(value).no;
    values.calendarID =JSON.parse(value).calendarID;
    console.log(JSON.parse(value));
  }
};


 
  let files = [];
  // 등록 버튼 핸들러
  const handleRegisterButton = () => {


    
 

    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {
      let result = extractStr.match(fileArray[i]);
      console.log("result: " + result);
      if (result == null) {
        var filename = fileArray[i].substring(
          fileArray[i].lastIndexOf("/") + 1
        );

        axios
          .delete("https://gw.thegmmedical.com:5003/api/deletepic", {
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename,
            },
          })
          .then(() => this.setState({ status: "Delete successful" }));
      }
      //Do something
    }

    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
   
    for (let i = 0; i<fileList.length;i++){
      console.log(fileList[i].name);
      formData.append(`file[${i}]`, fileList[i]);
      formData.append(`filename[${i}]`, fileList[i].name);
    }
    for (let i = 0; i<largeFiles.length;i++){
      formData.append(`lfile[${i}]`, largeFiles[i]);
      formData.append(`lfilename[${i}]`, largeFiles[i].name);
    }
    formData.append(`content`, editorRef.current?.getInstance().getHTML());
    formData.append(`content_txt`, editorRef.current
    ?.getInstance()
    .getHTML()
    .replace(/<\/?[^>]+(>|$)/g, ""));
    formData.append(`subject`, values.subject);
    formData.append(`product`, JSON.stringify(values.arr));
    formData.append(`delivery_date`, values.delivery_date);

    formData.append(`place`, values.place);
    formData.append(`content_type`, editor);

    formData.append(`note`, values.note);

    formData.append(`s_id`, values.s_id);

  

    const fetch = async () => {
      await axios
        .post("https://gw.thegmmedical.com:5003/api/delivery_scheduleupdate", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {

          closePopup(values);
         

          console.log("what is this");
         
            
          

          //
        });
    };

   fetch();
   


  
  };







 

  
  

  return (
    <div className="popup">
  
     <div className="popup_inner" style={{height:"250px"}}>
    
      
      <div className="delivery_write" style={{justifyContent:"flex-start", width:"100%", padding:"20px"}}>
     
      <div className="">

    

  
      <div className="write_table schedule">

        {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
        
        
 

  <div style={{display:"flex"}}>
 
    <input type="text" name="subject" className="formInput" placeholder="제목을 입력하세요." value={values.subject} onChange={handleInputChange}></input>
  </div>
  <div style={{display:"flex"}}>
 
    <input type="date" name="delivery_date" className="formInput" value={values.delivery_date} onChange={handleInputChange}></input>
  </div>





<TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            addRowTable = {addRowTable}
            />
<div style={{display:"flex"}}>

    <input type="text" name="place" className="formInput" value={values.place} onChange={handleInputChange} placeholder="납품장소"></input>
</div>

<div style={{display:"flex"}}>

    <input type="text" name="note" className="formInput" value={values.note} onChange={handleInputChange} placeholder="비고"></input>
</div>



  
 
      </div>

     
      
      <div className="button_wrap" style={{position: "absolute",
    bottom: "10px",
    left: "0",
    right: "0"}}>
      <button className="" onClick={handleRegisterButton}>등록</button>

      <button className="" onClick={closePopup}>닫기</button>
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
    </div>
  );
}
