
import { NavLink, Link } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";

const Password = () => {
    const [isVerified, setIsVerified] = useState(false);

    const checkPw = () => {
        // gets the current input value
        
    };
    function download(){
        var pageHTML = document.documentElement.outerHTML;
        console.log(pageHTML);
        var tempEl = document.createElement('a');
        
        tempEl.href = 'data:attachment/text,' + pageHTML;
        tempEl.target = '_blank';
        tempEl.download = 'thispage.html';
        tempEl.click();
    }
    

    function download2(){

        const answer = document.getElementById("password").value;
    
        if (answer === "940607") { 
        setIsVerified(true);
        } else {
        alert("Sorry, that's not it");
        }

        var pageHTML = window.document.getElementById('divToPDF').innerHTML;
        console.log(pageHTML);
        let data = new Blob([pageHTML], {type: 'data:attachment/text,'});
        let csvURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'Graph.html');
        tempLink.click();
    }
    

    return (
    <div className="wrapper" id="divToPDF" style={{height:"100%", display:"flex", alignItems:"center"}}>
    <div className="contents_wrap">
    {isVerified ? 
    <div>
        <table width="552" border="0" cellpadding="0" cellspacing="0" align="center"
        style={{backgroundColor:"white", border:"1px solid #cfcfcf;", margin:"50px auto", padding:"35px 30px 25px"}}>                    
        <tbody><tr>                        
        <td>                            
        <table border="0" cellpadding="0" cellspacing="0" align="center" width="500">                                
        <tbody><tr>                                    
        <td style={{fontSize:"20px", padding:"0 0 10px 0"}}><b>2022년 09월분 임금명세서</b></td>                                
        </tr>                                
        <tr>                                    
        <td style={{borderTop:"2px solid #467f9a"}}></td>                                
        </tr>                            
        </tbody>
        </table>                            
        <p style={{textAlign:"right", fontSize:"13px", padding:"5px 0 10px 0"}}> <b>지급일 : 2022년 09월 15일</b></p>                            
        <table width="500" cellpadding="0" cellspacing="0" border="1"
        style={{borderCollapse:"collapse", margin:"0 auto", fontSize:"12px"}}>                                
        <tbody><tr>                                    
        <td width="90" height="28" align="center"
        style={{padding:"2px 0", backgroundColor:"#f5f5f5", display:"table-cell", verticalAlign:"middle"}}><b>사원번호</b></td>
                                            
        <td width="130" align="right" style={{paddingRight:"10px"}}> 122 </td>                                    
        <td width="90" align="center" style={{backgroundColor:"#f5f5f5"}}> <b>성명</b></td>                                    
        <td width="130" align="right"  style={{paddingRight:"10px"}}> 변수연 </td>                                
        </tr>                                
        <tr>                                    
        <td width="90" height="28" align="center"
        style={{padding:"2px 0", backgroundColor:"#f5f5f5", display:"table-cell", verticalAlign:"middle"}}><b>직위</b></td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> 사원 </td>                                    
        <td width="90" align="center" style={{backgroundColor:"#f5f5f5"}}> <b>생년월일</b></td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> 1994.06.07 </td>                                
        </tr>                                
        <tr>                                    
        <td width="90" height="28" align="center" style={{padding:"2px 0", backgroundColor:"#f5f5f5"}}><b>소속</b></td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> IT개발본부 </td>                                    
        <td width="90" align="center" style={{backgroundColor:"#f5f5f5"}}> <b>입사일</b></td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> 2022년 04월 05일 </td>                                
        </tr>                                
        <tr>                                    
        <td height="25px;" style={{borderRight:"1px solid #fff", borderLeft:"1px solid #fff"}} colspan="4"></td>                                
        </tr>								
        <tr>										
        <td colspan="4" style={{border:"none"}}>	                                        
        <table width="500" cellpadding="0" cellspacing="0" border="1"
        style={{borderCollapse:"collapse", margin:"0 auto", fontSize:"12px"}}>         
        <tbody><tr align="center">                                                
        <td width="100" height="28" align="center" style={{padding:"2px 0"}} colspan="2"> <b>지급내역</b> </td>                                                 
        <td width="150" align="center" style={{paddingRight:"10px"}}> <b> 지급액</b> </td>                                                
        <td width="100" align="center"> <b>공제내역</b> </td>                                                 
        <td width="150" align="center" style={{paddingRight:"10px"}}> <b> 공제액</b> </td>                                            
        </tr>                                                                            
        <tr>                                                                        
        <td width="45" align="center" rowspan="2">매월 지급</td>                                    
        <td width="90" height="28" align="center" style={{padding:"2px 0", backgroundColor:"#f5f5f5"}}>기본급</td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> 3,333,333</td>                                     
        <td width="90" align="center" style={{backgroundColor:"#f5f5f5"}}>소득세</td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> 120,210</td>                                
        </tr>                                
        <tr>                                                                        
        <td width="90" height="28" align="center" style={{padding:"2px 0", backgroundColor:"#f5f5f5"}}></td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> </td>                                     
        <td width="90" align="center" style={{backgroundColor:"#f5f5f5"}}>지방소득세</td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> 12,020</td>                                
        </tr>                                
        <tr>                                                                        
        <td width="45" align="center" rowspan="4">격월 또는 부정기 지급</td>                                    
        <td width="90" height="28" align="center" style={{padding:"2px 0", backgroundColor:"#f5f5f5"}}></td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> </td>                                     
        <td width="90" align="center" style={{backgroundColor:"#f5f5f5"}}>국민연금</td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> 149,980</td>                                
        </tr>                                
        <tr>                                                                        
        <td width="90" height="28" align="center" style={{padding:"2px 0", backgroundColor:"#f5f5f5"}}></td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> </td>                                     
        <td width="90" align="center" style={{backgroundColor:"#f5f5f5"}}>건강보험</td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> 116,490</td>                                
        </tr>                                
        <tr>                                                                        
        <td width="90" height="28" align="center" style={{padding:"2px 0", backgroundColor:"#f5f5f5"}}></td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> </td>                                     
        <td width="90" align="center" style={{backgroundColor:"#f5f5f5"}}>고용보험</td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> 29,990</td>                                
        </tr>                                
        <tr>                                                                        
        <td width="90" height="28" align="center" style={{padding:"2px 0", backgroundColor:"#f5f5f5"}}></td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> </td>                                     
        <td width="90" align="center" style={{backgroundColor:"#f5f5f5"}}>장기요양보험료</td>                                    
        <td width="130" align="right" style={{paddingRight:"10px"}}> 14,290</td>                                
        </tr>	
        </tbody></table>									
        </td>								
        </tr>                                                                                                
                                                                       
                                                                           
                                             
                                     
                                                               
        </tbody></table>                                                        
      						
            <table border="0" cellpadding="0" cellspacing="0" align="center" width="500">								
            <tbody><tr>                                    
                </tr>							
                </tbody></table>                        
                </td>                    
                </tr>                
                </tbody>
                </table>
    </div>
    :
    (
        <td style={{textAlign:"center", paddingBottom:"35px"}}>  
        <div style={{float:"left", marginLeft:"99px"}}>
           <input maxlength="15" oninput="numberMaxLength(this);" name="submitBirth" id="password" 
           style={{height:"38px", border:"1px solid #d9d9d9", color:"#1a1a1a",     width:"249px", paddingLeft:"15px"}}
           type="password" placeholder=" 예 : 890101 " onkeydown="keyDown()"/>
           </div>  								
           <div onClick={()=>download2()} style={{float:"left"}}><button type="button" 
           style={{border:"0", outline:"0", backgroundColor:"white" }}>
               <img src="http://www.iquest.co.kr/images/mailform/btn_pay_submit.png" alt="absmiddle" border="0"/></button>
               </div>  							
           </td>
    )
    }
     
    </div>
    </div>

  );
};

export default Password;
