import { NavLink, Link,useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef, useMemo } from "react";
import BoardList from "../components/side/DataList";
import BoardDocView from "./BoardDocView";
import Overlay from "../components/Overlay";
import BoardViewHistory from "../components/BoardViewHistory";

import ConfirmBoard from "../components/ConfirmBoard";
import { BiCommentDetail } from "react-icons/bi";
import ShowComment from "../components/ShowComment";
import {Document, Page} from 'react-pdf';


import FolderTree, { testData } from 'react-folder-tree';
import {GiHamburgerMenu} from "react-icons/gi";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css"
import { IoIosArrowDown, IoIosArrowUp, IoMdMail  } from "react-icons/io";
import { FaCheck, FaReply, FaPencil, FaTrash, FaClipboard, FaCalendarPlus, FaList  } from "react-icons/fa6";
import { HiDotsVertical } from "react-icons/hi";
import { FaArrowLeft } from "react-icons/fa";

import './css/view.css';
import userPic from "../components/img/user.png";
import { RiAttachmentFill } from "react-icons/ri";


axios.defaults.withCredentials = true; 

const BoardView = () => {
  const [profile, setProfile] = useState("");
  const [data, setData]=useState(null);
  const [prev, setPrev]=useState([]);
  const [next, setNext]=useState([]);
  const [isActive, setActive] = useState(false);
  const [__html, setHtml] = useState("");
  const [sidebar, setSidebar] = useState(false);
  const [link, setLink] = useState("");

  const [user, setUser]=useState([]);
  const { b_no } = useParams();
  const [admins, setAdmins]=useState([]);
  const location = useLocation();
  const [toggle, setToggle] = useState(true);
  const [name, setName] = useState("");
  const [num, setNum] = useState();

  const [bgNo, setBgNo] = useState();
  const [bgName, setBgName]=useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [folders, setFolders] = useState([]);
  const [updateIndex, setUpdateIndex] = useState(0);
  const [updateComment, setUpdateComment] = useState("");
  const [boardAdmin, setBoardAdmin] = useState(false);
  const [cateList, setCateList] = useState([]);
  const [category, setCategory] = useState("");
  const [checkLines, setCheckLines] = useState([]);
  const [HTMLContent, setHTMLContent] = useState("");
  const [subject, setSubject] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const toggleText = () => {
    setActive(!isActive);
  };

  const [copy, setCopy] = useState(false);

  const toggleCopy = () => {
    setCopy(!copy);
  };
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState();
  const [id, setId] = useState(1);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [boardViews, setBoardViews] = useState([]);
  const [boardNoViews, setBoardNoViews] = useState([]);
  const [list, setList] = useState([]);
  const [dataType, setDataType] =useState("");
  const [commentArray, setCommentArray] = useState([
    {
      id: 0,
      content: "",
    },
  ]);
  const [checker, setChecker] = useState("");

  const [showPopup, setPopup] = useState(false);
  
  const togglePopup = () => {
    setPopup(!showPopup);
  };

  const [showConfirm, setConfirm] = useState(false);
  const [status, setStatus] = useState("");
  const [orderNo, setOrderNo] = useState("");

  const toggleConfirm = () => {
    setConfirm(!showConfirm);
  };
    
  const [showComment, setShowComment] = useState(false);
  const [commentData, setCommentData] = useState([]);

  const navigate = useNavigate();
  const toggleComment = () => {
    setShowComment(!showComment);
  };
    

const addComment = () => {
	setId(id + 1);

  
  const formData = new FormData();
     
  for (let i = 0; i<fileList.length;i++){
    console.log(fileList[i].name);
    formData.append(`file[${i}]`, fileList[i]);
    formData.append(`filename[${i}]`, fileList[i].name);
  }
  for (let i = 0; i<largeFiles.length;i++){
    formData.append(`lfile[${i}]`, largeFiles[i]);
    formData.append(`lfilename[${i}]`, largeFiles[i].name);
  }
  formData.append(`id`, id);
  formData.append(`b_no`, b_no);
  formData.append(`comment`, comment);
 


  	const newComment = {
    	id: id,
      b_no : b_no,
    	content: comment,
      
    };
    setCommentArray([...commentArray, newComment]);
   
    
    axios.post('https://gw.thegmmedical.com:5003/api/add_comment_data', formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response){
      console.log(response);
      window.location.reload(true);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
};

function updateCmt(cmtNo){
  const newComment = {
    no: cmtNo,
    b_no : b_no,
    content: updateComment

    
  };
  axios.post('https://gw.thegmmedical.com:5003/api/update_comment_data', newComment)
  .then(function (response){
    console.log(response);
    window.location.reload(true);
  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
  });
}


function deleteCmt(cmtNo){
  const data = {
    no: cmtNo,
    b_no : b_no,
    
  };
  axios.post('https://gw.thegmmedical.com:5003/api/delete_comment_data', data)
  .then(function (response){
    console.log(response);
    window.location.reload(true);

  })
  .catch(function (error){
    console.log(error);
  })
  .then(function (){
  });
}


    function lookingForNodeWithParent( nodes, parentId ) {

      var arrayToReturn = [];
    
      for( var i = 0, length = nodes.length; i < length; i++ ) {
        if( nodes[i].bg_parent === parentId ) {
            var node = nodes[i];
          
          arrayToReturn.push({
            name: node.bg_name,
            parentNo : node.bg_parent,
            num : node.bg_no,
            level : node.bg_level,
            order : node.bg_order,
            dNo : node.d_no,
            type : node.bg_type,
            children: lookingForNodeWithParent( nodes, node.bg_no )
          });
        }
      }
      
      return arrayToReturn;
    }
    
      function clean(object) {
        Object
            .entries(object)
            .forEach(([k, v]) => {
              
                if (v && typeof v === 'object') {
                    clean(v);
                }
                if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
                    if (Array.isArray(object)) {
                        object.splice(k, 1);
                        
                    } else {
                        delete object[k];
                    }
                }
            });
        return object;
    }
    const moveBoard = () => {
      var result = window.confirm("이동하시겠습니까");
      if(result){
        const data ={
          boardName: name,
          boardNum : num,
          b_no: b_no
        }
        console.log(data);
        axios.post('https://gw.thegmmedical.com:5003/api/move_data', data)
      .then(function (response){
        console.log(response);
        alert("이동되었습니다.");
        setActive(false);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
    };


    const copyBoard = () => {
      var result = window.confirm("복사하시겠습니까");
      if(result){
        const data ={
          boardName: name,
          boardNum : num,
          b_no: b_no
        }
        console.log(data);
        axios.post('https://gw.thegmmedical.com:5003/api/copy_data', data)
      .then(function (response){
        console.log(response);
        alert("복사되었습니다.");
        setCopy(false);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
    };


    const onBtnClick = () => {
        /*Collecting node-element and performing click*/
      inputFileRef.current.click();
    };

    const saveToPopUp = () => {
      var result = window.confirm("팝업 공지로 설정하시겠습니까?");
      if(result){
        const insertData ={
          content: HTMLContent,
          subject : subject,
          b_no: b_no
        }
        console.log(data);
        axios.post('https://gw.thegmmedical.com:5003/api/save_popup', insertData)
      .then(function (response){
        console.log(response);
  
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      }
    };

    const toggleOverlay = () => {
      setIsOpen(!isOpen);
    };
    useEffect(() => {
      

      if (location.state  !==null){
        setBgNo(location.state.bg_no);
        setBgName(location.state.bg_name);
      }



      axios.get('https://gw.thegmmedical.com:5003/api/get_view_data/'+b_no)
      .then(function (response){
        console.log(response);
        setBoardViews(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });

      axios.get('https://gw.thegmmedical.com:5003/api/get_no_view_data/'+b_no)
      .then(function (response){
        console.log(response);
        setBoardNoViews(response.data);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });

      

      axios.get("https://gw.thegmmedical.com:5003/dataview/"+b_no)
      .then(function (response){
        console.log("response.data.............", response.data[0]);
        setProfile(response.data[0].profile_link);
        setData(response.data);
        setHTMLContent(response.data[0].content);
        setSubject(response.data[0].subject);
        console.log("data.data...", response.data);
        setBgNo(response.data[0].bg_no);
        setBgName(response.data[0].bg_name);
        if (response.data[0].category_list!==null && response.data[0].category_list!==undefined){
          setCateList(JSON.parse(response.data[0].category_list));
        }
        
        setCategory(response.data[0].category);

        var boardData = response.data;
        
        if (response.data[0].content_type === "excel"){
          //const htmlData = XLSX.utils.sheet_to_html(worksheet); // generate HTML
          const htmlData = JSON.parse(response.data[0].content);
          htmlData.showGridLines=0;
          htmlData.ch_width = 600;
          console.log("htmldata", htmlData);
          setHtml(htmlData);
          const rootElement = document.getElementsByClassName('fortune-container');
          //rootElement[0].addEventListener('contextmenu', handleContextMenu);
        }
        let usersList, departmentsList;
        axios.get('https://gw.thegmmedical.com:5003/api/getusers')
        .then(function (response){
          console.log(response);
          usersList = response.data;
          setUsers(response.data);
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
        axios.get('https://gw.thegmmedical.com:5003/api/getdepartment_group')
        .then(function (response){
          console.log(response);
          departmentsList = response.data;
          setDepartments(response.data);
        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
        

        
        fetch("https://gw.thegmmedical.com:5003/api/getuser", {
        method: "get", // 통신방법
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      })
      .then((res) => res.json())
      .then((json) => {
        console.log("user...", json[0])
        setUser(json[0]);
        
       
      })
      .catch(() => {});

      axios.get('https://gw.thegmmedical.com:5003/api/getuser')
      .then(function (response){
        console.log("user is", response.data);
        //setUser(response.data[0]);
        let userData=response.data[0];
        axios.get('https://gw.thegmmedical.com:5003/api/get_checkline/'+b_no)
        .then(function (response){
          setCheckLines(response.data);

          
          var index = response.data.map(function(e) { return e.next; }).indexOf(1);
          let checkline = response.data[index];
          console.log("checkline...dsfsdfsdf", checkline);
          if (checkline.auto === 1){

            let dHeadNo;
            if (!isNaN(boardData[0].user_no)){
              var index = usersList.map(function(e) { return e.user_no; }).indexOf(parseInt(boardData[0].user_no));
              let dno = usersList[index].d_no;
        
        
              var index2 = departmentsList.map(function(e) { return e.d_no; }).indexOf(parseInt(dno));
        
              dHeadNo = departmentsList[index2].d_head;
        
            }else{
              dHeadNo = boardData[0].user_no;
            }
            let dNo;
            if (!isNaN(boardData[0].user_no)){
              var index = usersList.map(function(e) { return e.user_no; }).indexOf(parseInt(boardData[0].user_no));
              dNo= usersList[index];
            }else{
              dNo= boardData[0].user_no;
            }

            if (JSON.parse(checkline.checkers).includes(userData.user_no)){
              checkBoard(checkline.status, checkline.order_no);
            }else if (JSON.parse(checkline.checkers).includes("전사원")){
              checkBoard(checkline.status, checkline.order_no);
            }else if (JSON.parse(checkline.checkers).includes("부서원") && userData.d_no === getUser(boardData[0].user_no).d_no){
              checkBoard(checkline.status, checkline.order_no);
            }else if (JSON.parse(checkline.checkers).includes("작성자") && userData.user_no === boardData[0].user_no){
              checkBoard(checkline.status, checkline.order_no);
            }else if (JSON.parse(checkline.checkers).includes("부서장") && userData.user_no === dHeadNo){
              checkBoard(checkline.status, checkline.order_no);
            }
          }
       

        })
        .catch(function (error){
          console.log(error);
        })
        .then(function (){
          //always executed
        });
      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });







      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });




      axios.get('https://gw.thegmmedical.com:5003/api/add_view_data/'+b_no+"/"+location.state.bg_no)
      .then(function (response){
        console.log(response);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });


      function handleContextMenu(e) {
        e.preventDefault(); // prevents the default right-click menu from appearing
      }
      // add the event listener to the component's root element

      axios.get('https://gw.thegmmedical.com:5003/api/get_comment_data/'+ b_no)
      .then(function (response){
        console.log("get_comment", response.data);
        setComments(response.data);
    
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
      //always executed
      });
      
      const data = {
        b_no : b_no,
        bg_no : location.state.bg_no
      }
      axios.post('https://gw.thegmmedical.com:5003/api/data_prev_next', data)
      .then(function (response){
        
        if (response.data[0]!==undefined  && response.data[1]!==undefined){
          setPrev(response.data[0]);
          setNext(response.data[1]);

          
          
        }
        if (response.data[0]!==undefined){
          if (response.data[1] === undefined){
            if (response.data[0].b_no < b_no){
              setPrev(response.data[0]);
              setNext([]);
            }else{
              setNext(response.data[0]);
              setPrev([]);
            }
          }
        }
    
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
      //always executed
      });

    
    
   
      
      axios.post('https://gw.thegmmedical.com:5003/api/get_dataadmin', {
        data: {
          // 서버에서 req.body.{} 로 확인할 수 있다.
          bg_no : location.state.bg_no
        },
      })
      .then(function (response){
        console.log("admin is", response.data);
        let adms = response.data;
        adms = adms.map((ele,idx)=>{return ele.admin_email});
        console.log("adms", adms);
        setAdmins(adms);
      /*var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
      setname(name);
      setUserNo(response.data[0].user_no);*/


      if (response.data[0].category !==null && response.data[0].category !==undefined && response.data[0].category !==""){
        setCateList(JSON.parse(response.data[0].category));
      }else{
        setCateList([]);
      }


      })
      .catch(function (error){
      console.log(error);
      })
      .then(function (){
      //always executed
      });




    /*fetch("https://gw.thegmmedical.com:5003/view/"+no+"/"+folder,  {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        //setData(json[0].parsed);
        console.log(json);
      
        //setData(json.parsed);
        setData(json[0]["parsed"]);
        setPrev(json[0]["prev"]);
        setNext(json[0]["next"]);
       
        //setData(json);
        //console.log(json[0]["parsed"].attachments[0].filename);
      })
      .catch(() => {});*/
      axios.get('https://gw.thegmmedical.com:5003/api/getdatalist',{
          
    })
.then(function (response){
//setBoxes(json[0].boxes);
var array = lookingForNodeWithParent( response.data, null );
   
     
//let newData = array.map(el => el[0]);
    //console.log(newData);
    setFolders(clean(array));
})
.catch(function (error){
console.log(error);
})
.then(function (){
//always executed
});


    axios.get('https://gw.thegmmedical.com:5003/api/check_boardadmin')
    .then(function (response){
      console.log("user is", response.data);
      if (response.data.length>0){
        setBoardAdmin(true);
      }
      /*var name = response.data[0].name + " " + response.data[0].job_name+" ("+response.data[0].d_name+")";
      setname(name);
      setUserNo(response.data[0].user_no);*/
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

    }, [b_no]);
    const onNameClick = ({ defaultOnClick, nodeData }) => {
      defaultOnClick();
      var notice_data=[];
      var board_data=[];
      const {
        // internal data
        path, name, num, level, type, isOpen, children
        // custom data
      } = nodeData;
      setName(name);
        setNum(num);
      if (type!=="group"){
        var dropdown = document.getElementsByClassName('dropdown_div');
        var aTags = dropdown[0].getElementsByTagName("span");
        
  var searchText;
  var found;
  
          searchText=name;
        
  for (var i = 0; i < aTags.length; i++) {
    if (aTags[i].textContent === searchText) {
      found = aTags[i];
      break;
    }
  }
  const boxes = document.querySelectorAll('.editableNameContainer');
  
  boxes.forEach(box => {
    box.style.backgroundColor = 'transparent';
  });
  found.style.backgroundColor = '#BEFDFD';
      console.log(name);
        
      
  
      }
      
  };
   
    function delete_board(){
      var result = window.confirm("게시물을 삭제하시겠습니까?");
if (result) {
  for (let i=0;i<data.length;i++){
    axios
    .delete("https://gw.thegmmedical.com:5003/api/deletefile_data", {
      data: {
        // 서버에서 req.body.{} 로 확인할 수 있다.
        file_name: data[i].filename,
        b_no: b_no,
      },
    })
    .then(() =>console.log("good"));
  }
  fetch("https://gw.thegmmedical.com:5003/api/deletedata/"+b_no, {
    method: "GET", // 통신방법
  headers: {
    "content-type": "application/json",
  },
  credentials: "include"
  })
  .then((res) => res.json())
  .then((json) => {
    console.log(json);
    window.location.href="https://gw.thegmmedical.com/data";
  })
  .catch(() => {});
}
      
    }
    function checkBoard(status, order_no){
      const data = {
        b_no : b_no,
        bg_no : location.state.bg_no,
        status: status,
        order_no : order_no
      }
      axios.post('https://gw.thegmmedical.com:5003/api/check_board', data)
      .then(function (response){
        
       
    
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
      //always executed
      });



    }


    

    function getUserInfo(userno){
      if (!isNaN(userno)){
        var index = users.map(function(e) { return e.user_no; }).indexOf(parseInt(userno));
        console.log("users.......ddd.", users, index);
        return users[index].name + users[index].job_name;
      }else{
        return userno;
      }
      
    };


    function getUser(userno){
      if (!isNaN(userno)){
        var index = users.map(function(e) { return e.user_no; }).indexOf(parseInt(userno));
        console.log("getUserDno", users[index]);
        return users[index];
      }else{
        return userno;
      }
      
    };

    function getDepartmentHead(userno){

      if (!isNaN(userno)){
      var index = users.map(function(e) { return e.user_no; }).indexOf(parseInt(userno));
      let dno = users[index].d_no;


      var index2 = departments.map(function(e) { return e.d_no; }).indexOf(parseInt(dno));

      return departments[index2].d_head;

      }else{
        return userno;
      }

    }

    function checkExtension(filename){
      var parts = filename.split('.');
      var extension = parts[parts.length-1];
      if (extension === "doc" || extension === "docx" || extension === "docm" || extension === "dot" || extension === "dotx"
      || extension === "odt" || extension === "xls" || extension === "xlsx" || extension === "xlsm"|| extension === "xlsb"
      || extension === "ppt"|| extension === "pptm"|| extension === "pptx"){
        return true;
      }else{
        return false;
      }
    }


    const [fileSize, setFileSize] = useState("");
    const [largeFiles, setLargeFiles] = useState([]);
    const [fileList, setFileList] = useState([]);
    const fileChangedHandler = (event) => {
      //let file_size = event.target.files[0].size;
    
      var newFiles = [];
      const LargeFs = [];
      var file__size=0;
      var fs = 0;
      for(let i = 0; i < event.target.files.length; i++){
        file__size+=event.target.files[i].size;
        
        //filesize+=event.target.files[i].size;
        console.log(fileSize);
        if ((fileSize+file__size)>20971520 || file__size > 20971520){
          LargeFs.push(event.target.files[i]);
          
         
        }else{
          
          fs+=event.target.files[i].size;
          newFiles.push(event.target.files[i]);
          
        }
      }
      setFileSize(fileSize+fs);
      var newArray = fileList.concat(newFiles);
      setLargeFiles(LargeFs);
      setFileList(newArray);
      console.log(largeFiles);
    
    };
    const inputFileRef = useRef();


   
    function formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'
  
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k))
  
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
  const getCateboard = (category) =>{

    const data = {
      category : category,
      bg_no : bgNo
    }

    axios.post('https://gw.thegmmedical.com:5003/api/data_category', data)
    .then(function (response){
      
     console.log("response...", response.data);
     navigate("/data", {state: { boardName : bgName, bg_no: bgNo, data:response.data}});
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
    //always executed
    });
  }
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
   };

   function onDocumentLoadSuccess(numPages) {
    setNumPages(numPages);
}



  return data && (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <BoardList/>
        </div>
      )}
    <div className="content-wrap">
    
     
     <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
      <div className="side-menu">
     <BoardList changeBoard={(value)=>{console.log(value);
        if (value.length === 0) {
          console.log(true);
        } else {
          console.log(false);
          if (value.length <= 10) {
            console.log(false);
          } else {
            console.log(true);
          }
        }}} changeNotice={(value)=>{console.log(value);}} changeBoardN={(value)=>{console.log(value);}} changeBoardNum={(value)=>{console.log(value);}}/>
</div>
<div id="content" style={{width:"100%"}}>
{(checkLines.length >0) && (
              <div style={{display:"flex", justifyContent:"end", margin:"20px 25px 0 25px", padding:"0 10px 20px"}} className="line">
                {checkLines.map((element, index)=> (
                  (() => {
                if (element.checked === 1) {
                  return (
                        <>
                        <table>
                          <tr><td className="job">{element.status}</td></tr>
            <tr><td className="">
              <p>{element.name} {element.job_name}
              {element.comment!==null && (
                 <span onClick={()=>{setCommentData(element); toggleComment();}}> <BiCommentDetail/> </span>
              )}
             
              </p>
              <p>{element.d_name}
              </p>
              <p>확인 완료
              </p>

            </td></tr>
            <tr></tr>
            </table>
            </>
                )
                    } else if (JSON.parse(element.checkers).includes(user.user_no) && element.next===1){

                      return (<table>
                          <tr><td className="job">{element.status}</td></tr>
            <tr><td className="">

            <p>{getUserInfo(JSON.parse(element.checkers)[0])}</p>
            {JSON.parse(element.checkers).length>1 && (<p>외 {JSON.parse(element.checkers).length-1}명</p>)}

            </td></tr>
            <tr style={{height:"0"}}>
              
              <button onClick={()=>{setStatus(element.status);setOrderNo(element.order_no); 
              if (JSON.parse(element.checkers).length > 1){
                setChecker(getUserInfo(JSON.parse(element.checkers)[0])+ "외"+ (JSON.parse(element.checkers).length-1).toString() +"명");
              }else{
                setChecker(getUserInfo(JSON.parse(element.checkers)[0]));
              }
              toggleConfirm();
              
                }} style={{margin:"10px"}}>확인</button>
            </tr>
            </table>)
                    }else if (JSON.parse(element.checkers).includes("전사원") && element.next===1){

                      return (<table>
                          <tr><td className="job">{element.status}</td></tr>
            <tr><td className="">

            {getUserInfo(JSON.parse(element.checkers)[0])}
            {JSON.parse(element.checkers).length>1 && (<span>외 {JSON.parse(element.checkers).length-1}명</span>)}

            </td></tr>
            <tr style={{height:"0"}}>
              <button onClick={()=>{setStatus(element.status);setOrderNo(element.order_no);
                if (JSON.parse(element.checkers).length > 1){

                  setChecker(getUserInfo(JSON.parse(element.checkers)[0])+ "외"+ (JSON.parse(element.checkers).length-1).toString() +"명");
                }else{
                  setChecker(getUserInfo(JSON.parse(element.checkers)[0]));
                }
                toggleConfirm();
                }} style={{margin:"10px"}}>확인</button>
            </tr>
            </table>)
                    }else if (JSON.parse(element.checkers).includes("부서원") && user.d_no === getUser(data[0].user_no).d_no && element.next===1){
                      return (<table>
                        <tr><td className="job">{element.status}</td></tr>
                      <tr><td className="">

                      {getUserInfo(JSON.parse(element.checkers)[0])}
                      {JSON.parse(element.checkers).length>1 && (<span>외 {JSON.parse(element.checkers).length-1}명</span>)}

                      </td></tr>
                      <tr style={{height:"0"}}>
                        <button onClick={()=>{setStatus(element.status);setOrderNo(element.order_no);
                          if (JSON.parse(element.checkers).length > 1){
                            setChecker(getUserInfo(JSON.parse(element.checkers)[0])+ "외"+ (JSON.parse(element.checkers).length-1).toString() +"명");
                          }else{
                            setChecker(getUserInfo(JSON.parse(element.checkers)[0]));
                          }
                          toggleConfirm();
                          }} style={{margin:"10px"}}>확인</button>
                      </tr>
                      </table>)
                    }else if (JSON.parse(element.checkers).includes("작성자") && user.user_no === data[0].user_no && element.next===1){
                      return (<table>
                        <tr><td className="job">{element.status}</td></tr>
                      <tr><td className="">

                      {getUserInfo(JSON.parse(element.checkers)[0])}
                      {JSON.parse(element.checkers).length>1 && (<span>외 {JSON.parse(element.checkers).length-1}명</span>)}

                      </td></tr>
                      <tr style={{height:"0"}}>
                        <button onClick={()=>{setStatus(element.status);setOrderNo(element.order_no);
                          if (JSON.parse(element.checkers).length > 1){
                            setChecker(getUserInfo(JSON.parse(element.checkers)[0])+ "외"+ (JSON.parse(element.checkers).length-1).toString() +"명");
                          }else{
                            setChecker(getUserInfo(JSON.parse(element.checkers)[0]));
                          }
                          toggleConfirm();
                          }} style={{margin:"10px"}}>확인</button></tr>
                      </table>)
                    }else if (JSON.parse(element.checkers).includes("부서장") && user.user_no === getDepartmentHead(data[0].user_no) && element.next===1){
                      return (<table>
                        <tr><td className="job">{element.status}</td></tr>
                      <tr><td className="">

                      {getUserInfo(JSON.parse(element.checkers)[0])}
                      {JSON.parse(element.checkers).length>1 && (<span>외 {JSON.parse(element.checkers).length-1}명</span>)}

                      </td></tr>
                      <tr style={{height:"0"}}>
                        <button onClick={()=>{setStatus(element.status);setOrderNo(element.order_no);
                        console.log(JSON.parse(element.checkers)[0]+ "외"+ (JSON.parse(element.checkers).length-1).toString() +"명");
                          if (JSON.parse(element.checkers).length > 1){
                            setChecker(getUserInfo(JSON.parse(element.checkers)[0])+ "외"+ (JSON.parse(element.checkers).length-1).toString() +"명");
                          }else{
                            setChecker(getUserInfo(JSON.parse(element.checkers)[0]));
                          }
                          toggleConfirm();
                          
                          }} style={{margin:"10px"}}>확인</button></tr>
                      </table>)
                    }else{
                      return (<table>
                          <tr><td className="job">{element.status}</td></tr>
            
                        <tr><td className="">
                        {getUserInfo(JSON.parse(element.checkers)[0])}
            {JSON.parse(element.checkers).length>1 && (<span>외 {JSON.parse(element.checkers).length-1}명</span>)}
                        
                        </td></tr>
                        <tr></tr>
                        </table>)
                    }
                  })()
                ))}
            </div>
          )}








  <div>

{showPopup ? (
  <BoardViewHistory b_no={b_no} data={list} dataType={dataType} closePopup={() => togglePopup()} />
) : null}

{showConfirm ? (
  <ConfirmBoard subject={data[0].subject} status={status} b_no={b_no} bg_no={bgNo} order_no={orderNo} checker={checker} closePopup={() => toggleConfirm()} />
) : null}

{showComment ? (
  <ShowComment data={commentData} closePopup={() => toggleComment()} />
) : null}
<div class="board_toolbar_task_mobile">
<FaArrowLeft onClick={()=> navigate("/data", {state: { boardName : bgName, bg_no: bgNo}})}/>

<HiDotsVertical onClick={()=>toggleMenu()}/>

{showMenu ? (
  <div className="menu">
      <div class="btn">
          
          <Link to="/datareply" state={{ bno: b_no, data : data, bg_name : bgName, bg_no : bgNo }} >
          <FaReply /><span class="text">답글</span>
          </Link>

        </div>
        
        {((user.user_no === data[0].user_no) || (admins.includes(user.email)) || boardAdmin === true) && (
          <div class="">
            <div class="btn">
          <Link to="/dataupdate" state={{ bno: b_no, data : data, bg_name : bgName, bg_no : bgNo }} >
            <FaPencil /><span class="text">수정</span>
          </Link>
          </div>
          <div class="btn">
          <FaTrash /><span class="text" onClick={()=>delete_board()}>삭제</span>
          </div>
          
          {/*
          <div class="">
          <div class="btn">
          <span class="text" onClick={toggleText}>이동</span>
          </div>
          {isActive && (
        <div className="dropdown_div" style={{textAlign:"left", padding:"20px"}}>

<div className="folder">
<span className="folder_title">전사 게시판</span>
    
    { folders.map((element, index) =>
    
    <FolderTree
      data={ element }
      showCheckbox={ false } 
      onNameClick={ onNameClick }
      indentPixels={ 15 }
      readOnly
      />
    ) }

      </div>
      
      <button type="button" class="button_task delete_email" onClick={()=>moveBoard()}><span class="text">이동</span></button>

      </div>
    )}     

</div>*/}
        
        </div>
     

        )}
        
        {/*<div class="">
          <div className="btn">
          <span class="text" onClick={toggleCopy}>복사</span>
          </div>
          {copy && (
        <div className="dropdown_div" style={{textAlign:"left", padding:"20px"}}>

<div className="folder">
<span className="folder_title">전사 게시판</span>
    
    { folders.map((element, index) =>
    
    <FolderTree
      data={ element }
      showCheckbox={ false } 
      onNameClick={ onNameClick }
      indentPixels={ 15 }
      readOnly
      />
    ) }

      </div>
      <div class="">
      <button type="button" class="button_task delete_email" onClick={()=>copyBoard()}><span class="text">복사</span></button>
      </div>
      </div>
    )}     

</div>*/}

        {((admins.includes(user.email)) || boardAdmin === true) && (
        <div class="btn" style={{display:"flex"}}>
         <FaClipboard /><span class="text" onClick={()=>saveToPopUp()}>팝업으로 공지</span>
        </div>
        )}
  

      
    
        {data[0].content_type !== "excel" && (
          <div class="btn">
        <Link to="/write" state={{ data : data }} >
          <IoMdMail /><span class="text">메일전달</span>
          </Link>
        </div>

        )}
      {data[0].content_type === "excel" && (
        <div class="btn" onClick={()=>{alert("메일로 전달할 수 있는 형식이 아닙니다.")}}>
         <IoMdMail /><span class="text">메일전달</span>
        </div>

      )}

      
 <div class="btn">
<FaCalendarPlus />
 <Link to="/schedule_w" state={{data : data }}><span>일정으로 등록</span></Link>

      
      {/*<button type="button" class="button_previous_mail svg_previous"><span class="blind">이전 메일 보기</span></button>
      <button type="button" class="button_next_mail svg_next"><span class="blind">다음 메일 보기</span></button>*/}
    </div>

    <div className="btn">
    <FaList /><Link to =  "/data" state= {{ boardName : bgName, bg_no: bgNo}}><span class="text">목록</span></Link>
    </div>
  </div>
) : null}


</div>

    <div class="board_toolbar_task">
        <div class="btn">
          
          <Link to="/datareply" state={{ bno: b_no, data : data, bg_name : bgName, bg_no : bgNo }} >
          <FaReply /><span class="text">답장</span>
          </Link>

        </div>
        
        {((user.user_no === data[0].user_no) || (admins.includes(user.email)) || boardAdmin === true) && (
          <div class="" style={{display:"flex"}}>
            <div class="btn">
          <Link to="/dataupdate" state={{ bno: b_no, data : data, bg_name : bgName, bg_no : bgNo }} >
            <FaPencil /><span class="text">수정</span>
          </Link>
          </div>
          <div class="btn">
          <FaTrash /><span class="text" onClick={()=>delete_board()}>삭제</span>
          </div>
          
          {/*
          <div class="">
          <div class="btn">
          <span class="text" onClick={toggleText}>이동</span>
          </div>
          {isActive && (
        <div className="dropdown_div" style={{textAlign:"left", padding:"20px"}}>

<div className="folder">
<span className="folder_title">전사 게시판</span>
    
    { folders.map((element, index) =>
    
    <FolderTree
      data={ element }
      showCheckbox={ false } 
      onNameClick={ onNameClick }
      indentPixels={ 15 }
      readOnly
      />
    ) }

      </div>
      
      <button type="button" class="button_task delete_email" onClick={()=>moveBoard()}><span class="text">이동</span></button>

      </div>
    )}     

</div>*/}
        
        </div>
     

        )}
        
        {/*<div class="">
          <div className="btn">
          <span class="text" onClick={toggleCopy}>복사</span>
          </div>
          {copy && (
        <div className="dropdown_div" style={{textAlign:"left", padding:"20px"}}>

<div className="folder">
<span className="folder_title">전사 게시판</span>
    
    { folders.map((element, index) =>
    
    <FolderTree
      data={ element }
      showCheckbox={ false } 
      onNameClick={ onNameClick }
      indentPixels={ 15 }
      readOnly
      />
    ) }

      </div>
      <div class="">
      <button type="button" class="button_task delete_email" onClick={()=>copyBoard()}><span class="text">복사</span></button>
      </div>
      </div>
    )}     

</div>*/}

        {((admins.includes(user.email)) || boardAdmin === true) && (
        <div class="btn" style={{display:"flex"}}>
         <FaClipboard /><span class="text" onClick={()=>saveToPopUp()}>팝업으로 공지</span>
        </div>
        )}
  

      
    
        {data[0].content_type !== "excel" && (
          <div class="btn">
        <Link to="/write" state={{ data : data }} >
          <IoMdMail /><span class="text">메일전달</span>
          </Link>
        </div>

        )}
      {data[0].content_type === "excel" && (
        <div class="btn" onClick={()=>{alert("메일로 전달할 수 있는 형식이 아닙니다.")}}>
         <IoMdMail /><span class="text">메일전달</span>
        </div>

      )}

      
 <div class="btn">
<FaCalendarPlus />
 <Link to="/schedule_w" state={{data : data }}><span>일정으로 등록</span></Link>

      
      {/*<button type="button" class="button_previous_mail svg_previous"><span class="blind">이전 메일 보기</span></button>
      <button type="button" class="button_next_mail svg_next"><span class="blind">다음 메일 보기</span></button>*/}
    </div>

    <div className="btn">
    <FaList /><Link to =  "/data" state= {{ boardName : bgName, bg_no: bgNo}}><span class="text">목록</span></Link>
    </div>
    </div>
   
  </div>
  <div class="mail_view_wrap">
    
    <div class="board_view_header">
    <table>
      <tr>
        <th>제목</th>
        <td>{data[0].b_seq}. {data[0].subject}</td>
      </tr>

      <tr>
        <th>작성자</th>
        <td>
          <div className="text_info">
      <div><span className="user">{data[0].name} {data[0].job_name} ({data[0].d_name})</span>
      <span className="date">{data[0].date_created}</span></div>


    </div>
  

        </td>
      </tr>


      <tr>
        <th>게시유효일</th>
        <td>{data[0].end_date}</td>
      </tr>

{cateList.length>0 && (
   <tr>
        <th>문서분류</th>
        <td className="cate_list">{cateList.map((ele,idx)=>(
        category == ele ? <span className="selected" onClick={()=>getCateboard(ele)}><FaCheck/> {ele}</span> : 
        <span onClick={()=>getCateboard(ele)}>{ele}</span>
        ))}</td>
      </tr>
)}
     



    </table>
    </div>

    {/*
    <div class="mail_view_header">

      <h3 class="">{data[0].b_seq}. {data[0].subject}</h3>
      

  <div className="board_header">
  <div className="custom-file-upload">          
{profile !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={profile} alt="profile_pic" style={{width:"40px", height:"40px"}}/>
</div>
: <img for="photo-upload" src={userPic} alt="profile_pic" style={{width:"20px", height:"20px"}}/>
}
</div>

<div className="text_info">
      <div><span className="user">{data[0].name} {data[0].job_name} ({data[0].d_name})</span></div>
      <div><span className="date">{data[0].date_created}</span></div>


    </div>

    {data[0].end_date &&(
      <div className="text_info">
    <div><span className="user">게시유효일</span></div>
    <div><span className="date">{data[0].end_date}</span></div>

    </div>
    )}
    
    {data[0].category &&(

    <div className="text_info">
    <div><span className="user">문서분류</span></div>
    <div><span className="date">{data[0].category}</span></div>

    </div>
     )}
  </div>
  </div>
 */}


    <div class="mail_view_body">
      <div class="mail_view_contents">

       {data[0].content_type==="doc" && (
        <BoardDocView doc_no = {data[0].doc_no}></BoardDocView>
       )}

        {/*} {attachments !=="" && <div>{attachments.content}</div>}
        {data.attachments[0].filename}
  {data.attachments[0].content}
  
  <iframe title="office viewer"
    src="https://docs.google.com/viewer?url=http://example.com/my_file.txt&embedded=true" style={{width:"600px", height:"500px"}} frameborder="0"></iframe>*/}

{(data[0].filename !=="" && data[0].filename !==undefined) &&   
<div className="attachment">
  <IoIosArrowDown onClick={() => setToggle(!toggle)} /><span>첨부파일 {data.length}개 </span>
  {toggle && (

  <div className="attachment_box">
    {data.map((element, idx) => {


    return <div><a href={"https://gw.thegmmedical.com/uploads/data/"+b_no +"/"+element.filename} download>{element.filename} &nbsp;&nbsp;&nbsp;
    <span className="attachment_size">{element.size} Bytes</span></a> &nbsp;&nbsp;&nbsp;
      {checkExtension(element.filename) && (
        <button onClick={()=>{toggleOverlay(); setLink("https://gw.thegmmedical.com/uploads/data/"+b_no +"/"+element.filename);}}>오피스뷰어</button>
      )}
</div>
    })}
    </div>)}
  </div>
}

<Overlay isOpen={isOpen} onClose={toggleOverlay} link = {link}>
  <h1>Content in overlay</h1>
</Overlay>

    {data[0].content_type !== "excel" && (
      <div className="toastui-editor-contents" dangerouslySetInnerHTML={ {__html: data[0].content} }></div>
    )}
    {data[0].content_type === "excel" && (
      <div style={{width:"100%", height:"800px"}} draggable={false}>
      <Workbook onOp={console.log} data={[__html]} draggable={false} onChange={(data)=>{console.log("changed, ", data);}} onContextMenu={e=>{e.preventDefault();}} showToolbar={false} showFormulaBar={false} showSheetTabs={false}
      allowEdit={false} rowHeaderWidth={0} columnHeaderHeight={0}/>

      </div>
    )}


{(data[0].filename !=="" && data[0].filename !==undefined) &&   
<div className="attachment">

  <div className="">
    {data.map((element, idx) => {
    var parts = element.filename.split('.');
    var extension = parts[parts.length-1];
      if (extension==="jpg" || extension==="png"){
         
        return <img
  class="fit-picture"
  src={"https://gw.thegmmedical.com/uploads/data/"+b_no +"/"+element.filename}
  alt={element.filename} />

       
      }else if (extension==="pdf"){
        return <Document
file={"https://gw.thegmmedical.com/uploads/data/"+b_no +"/"+element.filename}
onLoadSuccess={({ numPages })=>setNumPages(numPages)}
>
{Array.apply(null, Array(numPages))
.map((x, i)=>i+1)
.map(page => <Page pageNumber={page}/>)}
</Document>



      }
   
    })}
    </div>
  </div>
}



    </div>
  


  <div className="comment" style={{borderTop:"1px solid #ddd", borderBottom:"1px solid #ddd",
 
    boxSizing: "border-box"}}>

    <h3>댓글 {comments.length}개</h3>

    <div className="comment_write">
    <div style={{background:"#eee", padding:"5px"}}>
      <div style={{display:"flex"}}>
          <input className="comments-input" type="text" placeholder="댓글 달기..."
          onChange={(e) => {setComment(e.target.value)}} style={{width:"calc(100% - 80px)"}}/>
          <button className="upload-button" onClick={addComment} style={{width:"80px", border:"none", background:"#a5d5ff"}}>
            게시
          </button>
      </div>
      <input type="file" id="files" name="files" style={{display:"none"}} ref={inputFileRef} onChange={fileChangedHandler}/>
      <div style={{display:"flex",marginTop:"5px"}}>
      <RiAttachmentFill onClick={()=>onBtnClick()} style={{color:"#46abde", fontSize:"17px"}}/>
      {fileList.map((file,index) => 
        <div className="file_item">
          <span>{file.name} {formatBytes(file.size)}</span>
          <button style={{background:"transparent"}} type="button" className="remove" onClick={() => {setFileList([])}}>x</button>
        </div>
        
        )} 
      </div>
    </div>
  <div className="comment_list" style={{borderTop:"1px solid #ddd", marginTop:"20px", background:"#fff"}}>

    {comments.map((ele,idx)=>
      <div className="cmt" style={{borderTop:"1px solid #ddd", padding:"20px 0"}}>

      <div className="custom-file-upload" style={{display:"flex", alignItems:"center", width:"auto"}}>          
      {(ele.profile_link !=="" && ele.profile_link !== null) ? 
      <div className="img-wrap img-upload" style={{width:'auto'}}><img for="photo-upload" src={ele.profile_link} alt="profile_pic" style={{width:"40px", height:"40px"}}/>
      </div>
      : 
      <img for="photo-upload" src={userPic} alt="profile_pic" style={{width:"20px", height:"20px"}}/>
      }
      <span style={{marginLeft:"5px"}}>{ele.name} {ele.job_name} ({ele.write_date})</span>

      {ele.user_no === user.user_no && (
        <div className="btns">
          <span onClick={()=>{setUpdateIndex(ele.no); setUpdateComment(ele.content);}}>수정</span>
          <span>|</span>
          <span onClick={()=>deleteCmt(ele.no)}>삭제</span>
        </div>

      )}

      </div>


        


      <div className="cmt_content">


      {updateIndex == ele.no ? 
        <div className="comment_write" style={{display:"flex", marginTop:"10px"}}>
        <input className="comments-input" type="text" placeholder="댓글 수정..."
        onChange={(e) => {setUpdateComment(e.target.value)}} value={updateComment} 
        style={{width:"calc(100% - 80px)"}}/>
        <button className="upload-button"  onClick={()=>updateCmt(ele.no)} style={{width:"50px"}}>
          수정
        </button>

        <button className="upload-button" onClick={()=>setUpdateIndex(0)} style={{width:"50px"}}>
          취소
        </button>
    </div>
      : <p>{ele.content}
      {ele.filename!==null && (
        <div>
          <a href={"https://gw.thegmmedical.com/uploads/comment/"+ele.no +"/"+ele.filename} download>{ele.filename} &nbsp;&nbsp;&nbsp;
          </a>
          {checkExtension(ele.filename) && (
            <button onClick={()=>{toggleOverlay(); setLink("https://gw.thegmmedical.com/uploads/comment/"+ele.no +"/"+ele.filename);}}
            style={{padding:"0",fontSize:"11px"}}>오피스뷰어</button>
          )}
        </div>
      )}
      
</p>}

       

      
      </div>
      
    
        


      </div>

     

     
    )}


  </div>
</div>

  </div>


<div className="view_table">
<h3>열람현황</h3>
  <table className="table boardview" style={{background:"#eee"}}>
    <tr style={{background:"#343535"}}>
      <th>
        전체대상자 
      </th>
      <th>
        열람자
      </th>
      <th>
        미열람자
      </th>

    </tr>


    <tr>
      <td>
      {users.length>0 && (
         <span onClick={()=>{setList(users);setDataType("");togglePopup()}}>
        {users.length}명 
        </span>
      )}
      </td>

      <td>
      {boardViews.length>0 && (
         <span onClick={()=>{setList(boardViews);setDataType("view");togglePopup()}}>
        {boardViews.length}명 
        </span>
      )}
      </td>
      <td>
        {boardNoViews.length>0 && (
           <span onClick={()=>{setList(boardNoViews);setDataType("");togglePopup()}}>
        {boardNoViews.length}명
           </span>
        )}
      </td>


    </tr>

  </table>

  </div>

  <div className="bbsbotm_tbl">
              <div className="tbl_basic">
                <table>
          
                  <tbody>
                  
                    {next.length !==0 && (
                      <tr>
                        <td style={{width:"3%"}}>
                          <IoIosArrowUp/>
                        </td>
                    
                        <td className="subject" style={{width:"70%"}}>
                        <Link to={"/dataview/"+next.b_no} state={{bg_name : next.bg_name, bg_no: next.bg_no}}>{next.subject}</Link>
                        </td>
                        <td className="hide-m" style={{width:"17%"}}>{next.date_created}</td>
                      </tr>
                    )}
    
                    {prev.length !==0  && (
                      <tr>
                        <td style={{width:"3%"}}>
                          <IoIosArrowDown/>
                        </td>
                      
                        <td className="subject" style={{width:"70%"}}>
                        <Link to={"/dataview/"+prev.b_no} state={{bg_name : prev.bg_name, bg_no: prev.bg_no}}>{prev.subject}</Link>
                        </td>
                        <td className="hide-m" style={{width:"17%"}}>{prev.date_created}</td>
                      </tr>
                    )}
    
                  </tbody>
                </table>
              </div>
            </div>










</div>

</div>



  </div>
    </div>
    </div> 
    </div>
  );
};

export default BoardView;
