import axios from "axios";
import React, { useState, useEffect, useRef } from "react";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {start, end, company, job, content, resign, user_no}= rowsData;
    return (
      <tr key={index}>
        <td>
          <input
            type="date"
            value={start}
            onChange={(event) => onValUpdate(index, event)}
            name="start"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="date"
            value={end}
            onChange={(event) => onValUpdate(index, event)}
            name="end"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={company}
            onChange={(event) => onValUpdate(index, event)}
            name="company"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={job}
            onChange={(event) => onValUpdate(index, event)}
            name="job"
            className="form-control"
          />
        </td>

        <td>
          <input
            type="text"
            value={content}
            onChange={(event) => onValUpdate(index, event)}
            name="content"
            className="form-control"
          />
        </td>

        <td>
          <input
            type="text"
            value={resign}
            onChange={(event) => onValUpdate(index, event)}
            name="resign"
            className="form-control"
          />
        </td>
        <td style={{display:"none"}}>
          <input
            type="text"
            value={user_no}
            onChange={(event) => onValUpdate(index, event)}
            name="user_no"
            className="form-control"
            
          />
        </td>
        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}
function Experience({ user_no }) {
  const [rows, initRow] = useState([]);

  useEffect(() => {
    console.log("userno", user_no);
    fetch("http://gw.thegmmedical.com:5003/api/get_userinfo/experience/"+user_no, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log(json);  
      initRow(json);

      //console.log(boxes.boxes);
      //console.log(boxes.boxes);
    })
    .catch(() => {});
    
  }, []);

  const addRowTable = () => {
    const data = {
        start:'', end:'',company:'', job:'', content:'', resign:'', user_no: user_no
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  function addExperience(){
    for (var i=0;i<rows.length;i++){
      console.log(rows[i]);
    }

    console.log("last rows", rows);
    axios.post('http://gw.thegmmedical.com:5003/api/add_userinfo/experience/'+user_no, rows)
    .then(function (response){
      console.log(response);
      alert("저장되었습니다.");


    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  return (
    <>

      <div className="btns" style={{display:"flex", justifyContent:"flex-end", marginBottom:"20px"}}>
        <button className="btn" onClick={addRowTable}>
          추가
        </button>
      </div>
           
      <table style={{width:"100%"}} className="info_summary">
        <thead>
          <tr>
          <th>시작일</th>
          <th>종료일</th>
                <th>회사명</th>
                <th>직위(급)</th>
                <th>업무 내용</th>
                <th>퇴사 사유</th>
                <th className="info_summary">삭제</th>
            
          </tr>
        </thead>
        <tbody>
          <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            userNo = {user_no}
          />
        </tbody>
      </table>
      <div className="btns" style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>

      <button className="btn" onClick={()=>addExperience()}>
                저장
              </button>

      </div>
      
    </>
  );
}
export default Experience;