import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  order_client: "",
  order_date: "",
  issuer:"",
  issuer_contact: "",
  hospital_name: "",
  addr:"",
  pic:"",
  pic_contact:0,
  delivery:""
};


function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {product_name, quantity, unit_price, price, memo}= rowsData;
    return (
      <tr key={index}>
        <td>{index+1}</td>
       
        <td>
          <input
            type="text"
            value={product_name}
            onChange={(event) => onValUpdate(index, event)}
            name="product_name"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={quantity}
            onChange={(event) => onValUpdate(index, event)}
            name="quantity"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={unit_price}
            onChange={(event) => onValUpdate(index, event)}
            name="unit_price"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={price}
            onChange={(event) => onValUpdate(index, event)}
            name="price"
            className="form-control"
          />
        </td>

        <td>
          <input
            type="text"
            value={memo}
            onChange={(event) => onValUpdate(index, event)}
            name="memo"
            className="form-control"
          />
        </td>

        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

const Order = ({doc_no, onChange, line}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [list, setList] = useState([]);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useState(initialValues);

  
  
  const { state } = useLocation();

  useEffect(()=>{
    //This function will run every time the value of values state changes.
    onChange(values); //Will give you the updated value of values state.
    //Do some other stuff
  }, [values])

  useEffect(() => {
    console.log(doc_no);
    if (doc_no!==undefined){
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/order/'+doc_no)
    .then(function (response){
      if (response.data[0]!==undefined){
      let order_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/order/'+order_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({product_name:'', quantity:'',unit_price:'', price:'', memo:''});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
      }
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);


  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    values.arr = rows;
    onChange(values);

  };
 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let initial = [{
      product_name:'', quantity:'',unit_price:'', price:'', memo:''
    },
    {
      product_name:'', quantity:'',unit_price:'', price:'', memo:''
    },
    {
      product_name:'', quantity:'',unit_price:'', price:'', memo:''
    },
    {
      product_name:'', quantity:'',unit_price:'', price:'', memo:''
    },
    {
      product_name:'', quantity:'',unit_price:'', price:'', memo:''
    },
    {
      product_name:'', quantity:'',unit_price:'', price:'', memo:''
    },
    {
      product_name:'', quantity:'',unit_price:'', price:'', memo:''
    },
    {
      product_name:'', quantity:'',unit_price:'', price:'', memo:''
    },
    {
      product_name:'', quantity:'',unit_price:'', price:'', memo:''
    },
    {
      product_name:'', quantity:'',unit_price:'', price:'', memo:''
    }];
    initRow(initial);
    
    
  }, []);


  const addRowTable = () => {
    const data = {
        product_name:'', quantity:'',unit_price:'', price:'', memo:''
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    
    const data = [...rows];
    console.log(data, name, value, i);
    data[i][name] = value;
    initRow(data);

    values.arr = rows;
    onChange(values);

  };


  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

            <div className="order_write" style={{padding:"0", margin:"20px auto"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
          <table style={{width:"100%", border:"none"}} className="headers-1">
                  <tr>
                    <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>발주서</td>
                    <td className="approve">담당</td>
                    <td className="approve">검토</td>
                    <td className="approve">승인</td>
                  </tr>
                  <tr>
                    <td>
                     {line[0]!==undefined && (<span>{line[0].name}<br/>{line[0].job_name}</span>)}
                    </td>
                    <td>
                    {line[1]!==undefined && (<span>{line[1].name}<br/>{line[1].job_name}</span>)}

                    </td>
                    <td>
                    {line[2]!==undefined && (<span>{line[2].name}<br/>{line[2].job_name}</span>)}
           
                    </td>
                  </tr>
                  
                </table>
            <table style={{marginTop: '1px', borderCollapse: 'collapse'}} className="forms">{/* Header */} 
              <thead style={{height:"40px"}}>
                <tr>
             
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td style={{background: 'white', width:"100%", display:"flex", padding: '0px !important', border: 'currentColor', textAlign: 'left', color: 'black', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'top'}}>
                    <table className="header" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '1px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            발주처
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="order_client" value={values.order_client} onChange={handleInputChange}></input>
                          </td>

                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            병원명
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="hospital_name" value={values.hospital_name} onChange={handleInputChange}></input>
                          </td>
                        </tr>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            발주일 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="date" name="order_date" value={values.order_date} onChange={handleInputChange}></input>
                          </td>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            주소지 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="addr" value={values.addr} onChange={handleInputChange}></input>
                          </td>
                          
                        </tr>
                        <tr>
                        <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                             
                        </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          </td>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            담당자 
                          </th>
                          <td colSpan="3" style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                            <input type="text" name="pic" value={values.pic} onChange={handleInputChange}></input>
                          </td>

                        </tr>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            발주자
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="issuer" value={values.issuer} onChange={handleInputChange}></input>
                          </td>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            연락처 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="pic_contact" value={values.pic_contact} onChange={handleInputChange}></input>
                          </td>
                        </tr>
                        <tr>
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            연락처 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="issuer_contact" value={values.issuer_contact} onChange={handleInputChange}></input>
                          </td>
                         
                          <th style={{background: 'rgb(226, 226, 226)', padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            택배/직납 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          <input type="text" name="delivery" value={values.delivery} onChange={handleInputChange}></input>
                          </td>

                        </tr>
                      </tbody>
                    </table>

                  </td>
                  
                </tr>

                <tr>
                <div className="btns" style={{display:"flex", justifyContent:"flex-end", margin:"20px 0"}}>
     
        <button className="btn" onClick={addRowTable}>
          추가
        </button>


      </div>
                <table className="form_list order_list" style={{borderCollapse: 'collapse', width:"790px"}}>
                 
          <thead>
            <tr>
              <th>No</th>
              <th>품명</th>
              <th>수량</th>
              <th>단가</th>
              <th>금액</th>
              <th>비고</th>
              <th>삭제</th>
            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />
           
          </tbody>
        </table>
                </tr>
              </tbody>
            </table>
          </span></div>
        
        
      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default Order;

