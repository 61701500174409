import axios from "axios";
import React, { useState, useEffect, useRef } from "react";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {name, test, score, testDate, speaking, writing, user_no}= rowsData;
    return (
      <tr key={index}>
        <td>
          <input
            type="text"
            value={name}
            onChange={(event) => onValUpdate(index, event)}
            name="name"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={test}
            onChange={(event) => onValUpdate(index, event)}
            name="test"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={score}
            onChange={(event) => onValUpdate(index, event)}
            name="score"
            className="form-control"
          />
        </td>
     
    
        <td>
          <input
            type="date"
            value={testDate}
            onChange={(event) => onValUpdate(index, event)}
            name="testDate"
            className="form-control"
            
          />
        </td>

        <td>
          <input
            type="text"
            value={speaking}
            onChange={(event) => onValUpdate(index, event)}
            name="speaking"
            className="form-control"
            
          />
        </td>

        <td>
          <input
            type="text"
            value={writing}
            onChange={(event) => onValUpdate(index, event)}
            name="writing"
            className="form-control"
            
          />
        </td>
        <td style={{display:"none"}}>
          <input
            type="text"
            value={user_no}
            onChange={(event) => onValUpdate(index, event)}
            name="user_no"
            className="form-control"
            hidden
          />
        </td>
        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}
function Languages({ user_no }) {
  const [rows, initRow] = useState([]);

  useEffect(() => {
    console.log("userno", user_no);
    fetch("http://gw.thegmmedical.com:5003/api/get_userinfo/languages/"+user_no, {
      method: "get", // 통신방법
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((json) => {
      console.log(json);  
      initRow(json);

      //console.log(boxes.boxes);
      //console.log(boxes.boxes);
    })
    .catch(() => {});
    
  }, []);

  const addRowTable = () => {
    const data = {
        name: "", test:"", score:"", testDate:"", speaking:"", writing:"", user_no: user_no
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  function addExperience(){
    for (var i=0;i<rows.length;i++){
      console.log(rows[i]);
    }

    console.log("last rows", rows);
    axios.post('http://gw.thegmmedical.com:5003/api/add_userinfo/languages/'+user_no, rows)
    .then(function (response){
      console.log(response);
      alert("저장되었습니다.");


    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
  }
  return (
    <>

              <div className="btns" style={{display:"flex", justifyContent:"flex-end", marginBottom:"20px"}}>

   
              <button className="btn" onClick={addRowTable}>
                추가
              </button>
              </div>
           
      <table style={{width:"100%"}} className="info_summary">
        <thead>
          <tr>
          <th>언어</th>
          <th>공인시험</th>
          <th>점수</th>
          <th>시험날짜</th>
          <th>회화능력</th>
          <th>작문능력</th>
                <th className="del">삭제</th>
          </tr>
        </thead>
        <tbody>
          <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            userNo = {user_no}
          />
        </tbody>
      </table>
      <div className="btns" style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>

<button className="btn" onClick={()=>addExperience()}>
          저장
        </button>

</div>
    </>
  );
}
export default Languages;