import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import axios from "axios";
import AutoComplete from "../../components/AutoCompleteName";


//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 



function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {

    const {manufacturer, product_name, standard, quantity, destination}= rowsData;
    return (
      <tr key={index}>
        <td>{index+1}</td>
       
        <td>
          <input
            type="text"
            value={manufacturer}
            onChange={(event) => onValUpdate(index, event)}
            name="manufacturer"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={product_name}
            onChange={(event) => onValUpdate(index, event)}
            name="product_name"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={standard}
            onChange={(event) => onValUpdate(index, event)}
            name="standard"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={quantity}
            onChange={(event) => onValUpdate(index, event)}
            name="quantity"
            className="form-control"
          />
        </td>

        <td>
          <input
            type="text"
            value={destination}
            onChange={(event) => onValUpdate(index, event)}
            name="destination"
            className="form-control"
          />
        </td>
       
       
        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

const initialValues = {
  register_no:"",
  order_date:"",
  tel:"",
  fax:"",
  company:"",
  pic_tel:"",
  pic_user_no:"",
  pic_name:"",
  place:"",
  note:" "
}
const Order = ({doc_no, onChange, line}) => {  
  


  const [values, setValues] = useState(initialValues);



  useEffect(() => {
    console.log(doc_no);
    if (doc_no!==undefined){
    axios.get('http://gw.thegmmedical.com:5003/api/get_forms_data/endoscope/'+doc_no)
    .then(function (response){
      

      let order_no = response.data[0].no;
      axios.get('http://gw.thegmmedical.com:5003/api/get_forms_list/endoscope/'+order_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({manufacturer:"", product_name:"", standard:"", quantity:"", destination:""});
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      delete response.data[0].doc_no;
      delete response.data[0].no;
      onChange(response.data[0]);
      setValues(response.data[0]);
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    }
  }, []);




 

  const [cnt, setCnt] = useState(0);

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  
    values.arr = rows;

    onChange(values);


  };
 


  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let initial = [{
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    },
    {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    }];
    initRow(initial);
    
  }, []);


  const addRowTable = () => {
    const data = {
      manufacturer:"", product_name:"", standard:"", quantity:"", destination:""
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);

    values.arr = rows;
    onChange(values);


  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    
    /*if (name ==="mth_amount" || name ==="remain_amount"){
      let mth_amount = data[i]["mth_amount"];
      let remain_amount = data[i]["remain_amount"];
      if (data[i]["mth_amount"] === ""){
        mth_amount="0";
      }
      if (data[i]["remain_amount"] === ""){
        remain_amount="0";
      }
      data[i]["sum"] = parseInt(mth_amount)+parseInt(remain_amount);
      
      let result = data.filter(item=>item.sum !="").map(item => parseInt(item.sum));
      console.log("result...", result);
      var total = result.reduce(function(a, b) { return a + b; }, 0);
      console.log(total);
      setTotal(total);

    }*/



    initRow(data);
    values.arr = rows;
    onChange(values);

  };

  function getUserInfo(user){
    
    values.pic_user_no = user.user_no;
    values.pic_tel = user.tel;
    values.pic_name = user.name;
    console.log(
      "pic_tel..............", user.tel
    )
    setValues({
      ...values,
      pic_tel: user.tel,
      pic_name : user.name,
      pic_user_no : user.user_no
    });
    
  }
  /*window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });*/



  function dateFormat(value) {
    let date = new Date(value);
    let month = date.getMonth()+1;
    let day = date.getDate();
    let year = date.getFullYear();

    return year + "년 " + month + "월 " + day + "일";
  } 

  
  /*const response = document.getElementById('response');
  var link_array=[];
  var string = "<div>대용량첨부파일</div>";
  window.addEventListener('message', (event) => {
    if (event.data["selectedMembers"] !== undefined){
      
      var temp = event.data["selectedMembers"];
      var tempArray = members;
      for (let i=0;i<temp.length;i++){
        if (!tempArray.includes(temp[i])){
          
          tempArray = [...tempArray, temp[i]];
        }
      }
      const map = new Map(
        tempArray.map(obj => [obj.user_no, obj])
      );

      const deduplicatedArr = [...map.values()];
        console.log("응:<ㅡ",deduplicatedArr);
      setValues({
        ...values,
        workers: deduplicatedArr,
      })
      values.workers = deduplicatedArr;
      
      
    }
    if (event.data["selectedMember"] !== undefined){
      console.log("selectedMEmber....", event.data["selectedMember"]);
      var user_no = event.data["selectedMember"].user_no;
      var name = event.data["selectedMember"].name;
      var tel = event.data["selectedMember"].tel;
      setValues({
        ...values,
        person_in_charge: name + " ["+email+"]",
        user_no : user_no,
        PIC : user_no
      })
      values.pic_user_no = user_no;
      values.pic_name = name;
      values.pic_tel = tel;
    }
  });*/

  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper forms" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"50px auto"}}>     

    

          <div className="order_write endoscope" style={{padding:"0", margin:"20px auto"}}>
   
          <table style={{width:"100%", border:"none"}} className="headers-1">
            <tr>
              <td rowSpan={2} colSpan={8} className="title" style={{border:"none"}}>발주서</td>
            </tr>

            
          </table>

        <div style={{display:"flex", justifyContent:"space-between"}}>
          <table className="table1" style={{width:"42%"}}>
          <tbody>

          <tr>
          <td colspan="3" style={{textAlign:"right", wordSpacing:"15px"}}> 
          <input type="date" name="order_date" value={values.order_date} onChange={handleInputChange}></input>
          </td>

          </tr>

          <tr>
          <td colspan="3" style={{width:"90%"}}>
          <input type="text" name="company" value={values.company} onChange={handleInputChange}></input>

          </td><td>귀하</td>
          </tr>
          <tr>
          <td colspan="3" style={{display:"flex"}}> 
          <div style={{display:"flex", alignItems:"center"}}>
            <span>T: </span>
          <input type="text" name="tel" value={values.tel} onChange={handleInputChange}></input>
          </div>
          <div style={{display:"flex", alignItems:"center"}}>
            <span>F: </span>
          <input type="text" name="fax" value={values.fax} onChange={handleInputChange}></input>
          </div>

          </td>

          </tr>
          
          <tr>
       
          </tr>
          </tbody>
          </table>


          <table border="1" className="supply" style={{width:""}}>
          <tbody>
          <tr>
          <td rowspan="5" style={{textAlign:"center"}}>공<br></br>
          <br></br><br></br>급</td>
          <td>등록번호</td>
          <td colspan="3">108-81-84884</td>
          </tr>
          <tr>
          <td>상호</td>
          <td>주식회사 GM메디칼</td>
          <td>대표자</td>
          <td>임명한</td>
          </tr>
          <tr>
          <td>주소</td>
          <td colspan="3">서울 서초구 서초대로 46길 19-7, 201호</td>
          </tr>
          <tr>
          <td>전화</td>
          <td>02-2055-2255</td>
          <td>팩스</td>
          <td>02-2055-1177</td>
          </tr>

          <tr>
          <td>담당자</td>
          <td>
          <div style={{display:"flex", alignItems:"center"}}>



          <AutoComplete value = {values.pic_name} onChange={(value) => {getUserInfo(value); console.log("hihi", value)}}/>
           
          {/*<button onClick={() => popupwindow("/people", "결재라인", 500, 750)} alt='' style={{background:"white", border:"1px solid #666", padding:"5px 10px", display:"flex",cursor:"pointer"}}>찾기</button>
          */}
        </div>
          </td>
          <td>H.P</td>
          <td><input type="text" value={values.pic_tel} name="pic_tel" onChange={handleInputChange}>
            </input>  
          </td>
          </tr>
          </tbody>
          </table>
        </div>
               
        <div style={{marginTop:"20px"}}>
        <div style={{display:"flex", alignItems:"center", width:"100%", borderBottom:"1px solid #ddd"}}>
            <span> 납품장소: </span>
          <input type="text" name="place" value={values.place} onChange={handleInputChange}
          style={{width:"90%", border:"none"}}></input>
          </div>
          <div style={{display:"flex", alignItems:"center", width:"100%", borderBottom:"1px solid #ddd"}}>
            <span> 특이사항: </span>
          <input type="text" name="note" value={values.note} onChange={handleInputChange}
          style={{width:"90%", border:"none"}}></input>
          </div>
        </div>
        <div className="btns" style={{display:"flex", justifyContent:"flex-end", margin:"20px 0"}}>
     
        <button className="btn" onClick={addRowTable}>
          추가
        </button>
      </div>

      <p></p>
        <table className="form_list" style={{borderCollapse: 'collapse', width:"790px"}}>
                 
          <thead>
            <tr>
              <th>번호</th>
              <th>제조사</th>
              <th>품명</th>
              <th>규격</th>
              <th>수량</th>
              <th>납품 예상처</th>

              <th>삭제</th>
            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
            />
         
          </tbody>
        </table>
       
      </div>

      </div>

      </div>
      
 
  
      </div>
  
  );
};

export default Order;

