import React, { useRef, useEffect,useMemo, useState } from "react";
import Folder from "./Folder";
import FolderTree from 'react-folder-tree';
import axios from "axios";
import "./css/contacts.css";
import Employee from "../components/Employee";
import 'react-folder-tree/dist/style.css';
import user from "./img/user.png";
axios.defaults.withCredentials = true; 

const Org = () => {
    const onTreeStateChange = (state, event) => console.log(state, event);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [users, setUsers] = useState([]);
    const [ccList, setCC] = useState([]);
    const [bccList, setBCC] = useState([]);
    const [toDel, setToDel] = useState([]);
    const [ccDel, setCCDel] = useState([]);
    const [bccDel, setBCCDel] = useState([]);
    const [testData, setTestData] = useState([]);
    const [showUser, setShowuser] = useState(false);
    const [selectedMember, setSelectedMember]= useState();

  

    function lookingForNodeWithParent( nodes, parentId ) {

        var arrayToReturn = [];
      
        for( var i = 0, length = nodes.length; i < length; i++ ) {
          if( nodes[i].d_parent === parentId ) {
              var node = nodes[i];
            
            arrayToReturn.push({
              name: node.d_name,
              children: lookingForNodeWithParent( nodes, node.d_no )
            });
          }
        }
        
        return arrayToReturn;
      }
      
      
      useEffect(() => {
     
        fetch("http://gw.thegmmedical.com:5003/api/getdepartment_group", {
            method: "get", // 통신방법
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
          })
          .then((res) => res.json())
          .then((json) => {
            console.log("contactgroup", json);  
      console.log(lookingForNodeWithParent( json, null ));
            //setBoxes(json[0].boxes);
            var array = lookingForNodeWithParent( json, null );
            //let newData = array.map(el => el[0]);
                //console.log(newData);
            return setTestData(array[0]);
    
            //console.log(boxes.boxes);
            //console.log(boxes.boxes);
          })
          .catch(() => {});
      
      }, []);
    
   

      const [selectedItem, setSelectedItem] = useState(null);

   

      const onNameClick = ({ defaultOnClick, nodeData }) => {
        defaultOnClick();
      
        const {
          // internal data
          path, name, checked, isOpen, children
          // custom data
        } = nodeData;
      
        

        const item = name;
        const tempArray = [...options, item];
        setOptions(tempArray);

        axios.post('http://gw.thegmmedical.com:5003/api/getcontacts',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              folder_name: name
            },
          })
    .then(function (response){
      console.log("결과는요", response.data);
   
      setOptions(response.data);
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });

      };
      const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
        axios.post('http://gw.thegmmedical.com:5003/api/getcontactsbyinput',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              input: e.target.value
            },
          })
            .then(function (response){
            console.log(response.data);
           
            setOptions(response.data);
            })
            .catch(function (error){
            console.log(error);
            })
            .then(function (){
            //always executed
            });
        }
      }
      const searchword = (e) => {
        const value = e.target.getAttribute("value");
        console.log(value);
        axios.post('http://gw.thegmmedical.com:5003/api/getcontactsbyword',{
            data: {
              // 서버에서 req.body.{} 로 확인할 수 있다.
              input: value
            },
          })
            .then(function (response){
            console.log(response.data);
            setOptions(response.data);
            })
            .catch(function (error){
            console.log(error);
            })
            .then(function (){
            //always executed
            });
        
      }
      function getSelectValues(select) {
        var result = [];
        var options = select && select.options;
        var opt;
      
        for (var i=0, iLen=options.length; i<iLen; i++) {
          opt = options[i];
          
            var item = {name : opt.value || opt.text, id : opt.index};
          if (opt.selected) {
            console.log("selected");
            result.push(item); console.log("index..", result);
          }
        }
       
        return result;
      }
     
      
      const toggleUser = () => {
        
        if (showUser === true){
            var aTags = document.getElementsByTagName("option");
    console.log("...job", selectedMember.email);
var searchText = selectedMember.email;
var found;

for (var i = 0; i < aTags.length; i++) {
  console.log(aTags[i].className);
  if (aTags[i].className === searchText) {
    
    found = aTags[i];
    break;
  }
}
console.log(found);
found.click();
        }else{
        }
        setShowuser(!showUser); 
    };

    function add(){
      console.log(selectedMember);
      window.opener.postMessage({ selectedMember: selectedMember, users: users }, '*');
  
    
  
  window.close();
}

const onChangeHandler = (event) => {
  const selectedOptions = event.currentTarget.selectedOptions;
  console.log(event.currentTarget.selectedOptions);
  const users = [];
  for (let i = 0; i < selectedOptions.length; i++) {
    //users.push(selectedOptions[i].index);
    users.push(options[selectedOptions[i].index]);
  }

  console.log(users);
  setUsers(users);
};
    return (
        <div>

        <div style={{overflowY:"hidden", display:"flex", padding:"20px"}} className="org">
            
            <div className="directory-wrap">
            <div className="title">주소록</div>    
                <div className="directory-list">
                <FolderTree
      data={ testData }
      onChange={ onTreeStateChange }
      onNameClick={ onNameClick }
      showCheckbox={ false } 
      readOnly
      />

                </div>
                <div className="directory-select">
                    <div className="directory-search">
                    <div className="search-word">
                        <span onClick={searchword} value="가">ㄱ</span>
                        <span onClick={searchword} value="나">ㄴ</span>
                        <span onClick={searchword} value="다">ㄷ</span>
                        <span onClick={searchword} value="라">ㄹ</span>
                        <span onClick={searchword} value="마">ㅁ</span>
                        <span onClick={searchword} value="바">ㅂ</span>
                        <span onClick={searchword} value="사">ㅅ</span>
                        <span onClick={searchword} value="아">ㅇ</span>
                        <span onClick={searchword} value="자">ㅈ</span>
                        <span onClick={searchword} value="차">ㅊ</span>
                        <span onClick={searchword} value="카">ㅋ</span>
                        <span onClick={searchword} value="타">ㅌ</span>
                        <span onClick={searchword} value="파">ㅍ</span>
                        <span onClick={searchword} value="하">ㅎ</span>
                    </div>
                    <input type="text" onKeyDown={handleOnKeyPress} style={{marginLeft:"20px"}}></input>
                    </div>
                    <select
                multiple class="m_select pc">
                    { options.map((element, index) => <option className={element.email} key={index} onClick={() => {setSelectedMember(element);}}>
                      {element.name} {element.job_name} ({element.d_name})</option>) }
                </select>

                <div style={{border:"1px solid #ddd", padding:"0 1em"}}>
                  <ul>
                  { options.map((element, index) => <li className={(selectedMember !==undefined  && (selectedMember.user_no === element.user_no)) ? 'selected_member' : null} key={index} 
                  style={{cursor:"pointer"}}
                  onClick={() => {setSelectedMember(element);}}>
                      {element.name} {element.job_name} ({element.d_name})</li>) }

                  </ul>
                </div>
              {/*
              <select
                multiple multiSelect="false" class="m_select" onChange={(e)=>onChangeHandler(e)}>
                    { options.map((element, index) => <option className={element.email} key={index} onClick={() => {setSelectedMember(element);}}>
                      {element.name} {element.job_name} ({element.d_name})</option>) }
                </select> */}
                </div>
                
               
            </div>
   
   
        </div>
        <div className="button_wrap">
      <button className="submit" onClick={()=>add()}>추가</button>
      {/*</form>*/}
      </div>
    
        </div>
    )
}
export default Org;
