import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

axios.defaults.withCredentials = true; 

function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    const {name, job, tel, email, fax}= rowsData;
    return (
      <tr key={index}>
       <td>
          <input
            type="text"
            value={name}
            onChange={(event) => onValUpdate(index, event)}
            name="name"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={job}
            onChange={(event) => onValUpdate(index, event)}
            name="job"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={tel}
            onChange={(event) => onValUpdate(index, event)}
            name="tel"
            className="form-control"
          />
        </td>
     
    
        <td>
          <input
            type="text"
            value={email}
            onChange={(event) => onValUpdate(index, event)}
            name="email"
            className="form-control"
            
          />
        </td>

        <td>
          <input
            type="text"
            value={fax}
            onChange={(event) => onValUpdate(index, event)}
            name="fax"
            className="form-control"
            
          />
        </td>

        <td>
          <button
            className="btn"
            onClick={() => tableRowRemove(index)}
          >
            x
          </button>
        </td>
      
      </tr>
    );
  });
}
function PICInfo({pic, onChange }) {
  const [rows, initRow] = useState(pic);

  useEffect(() => {
   
    
    
  }, []);

  const addRowTable = () => {
    const data = {
        name:"", job:"", tel:"", email:"", fax:""
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
    onChange(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);

    onChange(data);
  };


  return (
    <>

<div className="" style={{display:"flex", justifyContent:"flex-end", marginBottom:"20px"}}>

   
<button className="btn" onClick={addRowTable} style={{border:"1px solid"}}>
  추가
</button>
</div>

      <table style={{width:"100%"}} className="info_summary">
        <thead>
          <tr>
                <th>담당자명</th>
                <th>직위</th>
                <th>전화번호</th>
                <th>이메일</th>
                <th>FAX</th>
                <th className="del">삭제</th>

          </tr>
        </thead>
        <tbody>
        <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
          />
        </tbody>
      </table>
{/*<div className="btns" style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>

<button className="btn" onClick={()=>addExperience()}>
          저장
        </button>

  </div>*/}
    </>
  );
}
export default PICInfo;