import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import DocList from "../../components/side/DocList";
import axios from "axios";
import moment from "moment";

// Toast 에디터
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import DraftsList from "../../components/DraftsList";
import BoardList from "../../components/side/BoardList";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import "./css/order.css";
//import LargeFile from "./largeFile";
axios.defaults.withCredentials = true; 
const initialValues = {
  department:"",
  create_date:"",
  name:"",
  company:"",
  charge:"",
  tel:"",
  cash:"",
  purchase_name:"",
  account:"",
  vat:"",
  card:"",
  content:"",
  total:"",
  cate1:"",
  cate2:"",
  cate3:"",
  c1:"",
  c2:"",
  c3:"",
  u1:"",
  u2:"",
  u3:"",
  p1:"",
  p2:"",
  p3:"",
  link:"",
  pay_date:"",
  bank_name:"",
  holder:"",
  account_no:"",
  comment:""
 
 };
 
 


function TableRows({ rows, tableRowRemove, onValUpdate, user_no }) {
  return rows.map((rowsData, index) => {
    const {name, product_no, unit, standard, quantity,unit_price, amount, user, note }= rowsData;
    return (
      <tr key={index}>
      <td> 
{name!==undefined && (
name.split('<br/>').map((item, idx) => {
return (
  <div key={idx}>
    {item}
    <br />
  </div>
);
})
)}      

      </td>
     
      <td>
 

{product_no!==undefined && (
product_no.split('<br/>').map((item, idx) => {
return (
  <div key={idx}>
    {item}
    <br />
  </div>
);
})
)}    
      </td>
      <td>
      



{unit!==undefined && (
unit.split('<br/>').map((item, idx) => {
return (
  <div key={idx}>
    {item}
    <br />
  </div>
);
})
)}  
      </td>
      <td>


{standard!==undefined && (
standard.split('<br/>').map((item, idx) => {
return (
  <div key={idx}>
    {item}
    <br />
  </div>
);
})
)}             
      </td>

      <td>
    


{quantity!==undefined && (
quantity.split('<br/>').map((item, idx) => {
return (
  <div key={idx}>
    {item}
    <br />
  </div>
);
})
)}    
      </td>
      <td>
   
   

{unit_price!==undefined && (
unit_price.split('<br/>').map((item, idx) => {
return (
  <div key={idx}>
    {item}
    <br />
  </div>
);
})
)}   
      </td>

      <td>

     


{amount!==undefined && (
amount.split('<br/>').map((item, idx) => {
return (
  <div key={idx}>
    {item}
    <br />
  </div>
);
})
)}   
      </td>
      <td>
 
      

{user!==undefined && (
user.split('<br/>').map((item, idx) => {
return (
  <div key={idx}>
    {item}
    <br />
  </div>
);
})
)}   
      </td>

      <td>
  


{note!==undefined && (
note.split('<br/>').map((item, idx) => {
return (
  <div key={idx}>
    {item}
    <br />
  </div>
);
})
)}  
      </td>
     
    </tr>
    );
  });
}

const PurchaseView = ({data, line}) => {  
  
  const editorRef = useRef();
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const [list, setList] = useState([]);
  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState("");
  const [links, setLinks]=useState([]);
  const [fileArray, setArray] = useState([]);
  const [select, setSelect] = useState("");
  const [boardName, setBoardName] = useState("dfdfd");
  const { board } = useParams();
  const [fileList, setFileList] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [type, setType] =useState("0");
  const [show, setShow] = useState(false);
  const [draftNo, setDraftNo] = useState();
  const [option, setOption] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  const [values, setValues] = useStateWithCallbackLazy(initialValues);

  
  
  const { state } = useLocation();



  useEffect(() => {
    
    axios.get('https://gw.thegmmedical.com:5003/api/get_forms_data/purchase/'+data)
    .then(function (response){
      console.log("order.........",response.data[0]);
      if (response.data.length>0){
        setValues(response.data[0], () => {
          function resize(ara) {
          ara.style.height = 'auto'; /* Always set to auto before resizing... */
          ara.style.height = ara.scrollHeight + 'px';
        }
        var autosize = document.getElementsByClassName('purchase_comment')[0];
        resize(autosize);

      });
       
      }
      
      let inspect_no = response.data[0].no;
      axios.get('https://gw.thegmmedical.com:5003/api/get_forms_list/purchase/'+inspect_no)
      .then(function (response){
        console.log("orders...........", response.data);
        let temp = response.data;
        if (response.data.length<10){
          let len = response.data.length;
          while (10-len>0){
          temp.push({      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
        });
          len++;
          }
        }
        initRow(temp);
      })
      .catch(function (error){
        console.log(error);
      })
      .then(function (){
        //always executed
      });
      
    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
    
  }, []);


 
  

  const [rows, initRow] = useState([]);
  
  useEffect(() => {

    let initial = [{
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    },
    {
      name:'', product_no:'', unit:'', standard:'', quantity:'',unit_price:'', amount:'', user:'', note:''
    }];
    initRow(initial);
    
    
  }, []);


 
  

  

  window.addEventListener('message', (event) => {
    console.log(event.data);
    if (event.data["selectedMember"] !== undefined){
      
      var temp = event.data["selectedMember"];
      console.log("temp = ", temp);
      if (select === "담당"){
        setFirst(temp);
      }else if (select ==="검토"){
        setSecond(temp);
      }else if (select ==="승인"){
        setThird(temp);
      }
      
     
      
      
    }
   
  });


  return (
    <div className="wrapper">
    
    
      
      <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>


      <div style={{width:"100%", margin:"0px auto"}}>     

    

            <div className="order_write purchase" style={{padding:"0", margin:"0px auto"}}>
          

      <div>
        <div style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}>
          <span style={{fontFamily: '돋움', fontSize: '9pt', lineHeight: 'normal', marginTop: '0px', marginBottom: '0px'}}> 
       

                <table className="purchase_header" style={{border: '1px solid rgb(0, 0, 0)', fontFamily: 'malgun gothic,dotum,arial,tahoma', marginTop: '0px', borderCollapse: 'collapse',
                  width:"100%"}}>{/* User */} 
                   
                      <tbody>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            부서명
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                         

{values.department.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>

                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            작성일자
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
{values.create_date.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>


                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            작성자
                          </th>
                          <td style={{border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
      
{values.name.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>
                        </tr>
                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            납품업체명 
                          </th>
                          <td style={{ border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
{values.company.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            업체담당 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
             
{values.charge.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            전화번호 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                     
{values.tel.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>
                        </tr>
                       
                        <tr>
                          <th colSpan={4} style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            다음과 같이 구입하고자 하오니 승인하여 주시기 바랍니다. 
                          </th>
                          
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            현금 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                        
{values.cash.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>
                       
                        </tr>


                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            구입명 
                          </th>
                          <td colSpan={3} style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                         
{values.purchase_name.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>
                      
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            계좌이체 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
{values.account.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>
                        </tr>


                        <tr>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            구입금액 (VAT포함)
                          </th>
                          <td colSpan={2} style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          </td>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                          {values.vat!==undefined && (Number(values.vat).toLocaleString())}
                          </td>
                          
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            법인카드 
                          </th>
                          <td style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>

{values.card.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>
                        </tr>


                        <tr height={50}>
                          <th style={{padding: '5px', border: '1px solid black', height: '18px', textAlign: 'center', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            내용 
                          </th>
                          <td colSpan={5} style={{background: 'rgb(255, 255, 255)', border: '1px solid black', textAlign: 'left', color: 'rgb(0, 0, 0)', fontSize: '12px', fontWeight: 'normal', verticalAlign: 'middle'}}>
                        
{values.content.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
                          </td>
                          
                        </tr>

                      </tbody>
                    </table>

            <table style={{marginTop: '1px', borderCollapse: 'collapse', width:"100%"}} className="forms">{/* Header */} 
          
              <tbody>
                
              

                <tr>
                
                <table className="form_list order_list" style={{borderCollapse: 'collapse', width:"100%"}}>
                 
          <thead>
            <tr>
              <th>품목</th>
              <th>(드림디포)<br></br>제품번호</th>
              <th>단위</th>
              <th>규격</th>
              <th>수량</th>
              <th>단가</th>
              <th>금액</th>
              <th>사용자</th>
              <th>비고</th>

            </tr>
          </thead>
          
          <tbody>


   
            <TableRows
            rows={rows}
           
            />
            <tr>
            <td colSpan={2}>합계</td>
            <td colSpan={5}>

{values.total.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>

            <td colSpan={3}>VAT포함 {values.vat!==undefined && (Number(values.vat).toLocaleString())}</td>
            </tr>

            <tr>
            <td rowSpan={4}>견적대비</td>
            <td>구분</td>
            <td colSpan={2}>                          

{values.cate1.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>
            <td colSpan={2}>

{values.cate2.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
    
            </td>
            <td colSpan={4}>
        
{values.cate3.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>

          </tr>


          <tr>
            <td>업체명</td>
            <td colSpan={2}>
      
            {values.c1.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}

            </td>
            <td colSpan={2}>
            {values.c2.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>
            <td colSpan={4}>

            {values.c3.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>

          </tr>

          <tr>
            <td>제품단가</td>
            <td colSpan={2}>
            {values.u1.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>
            <td colSpan={2}>
            {values.u2.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>
            <td colSpan={4}>
            {values.u3.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>

          </tr>

          <tr>
            <td>금액</td>
            <td colSpan={2}>
            {values.p1.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>
            <td colSpan={2}>
            {values.p2.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>
            <td colSpan={4}>
            {values.p3.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>

          </tr>

          <tr>
            <td>첨부서류</td>
            <td colSpan={9}>

            {values.link.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>

          </tr>

          <tr>
            <td>지급일자</td>
            <td colSpan={2}>
            {values.pay_date}

            </td>
            <td>은행명</td>
            <td colSpan={6}>
            {values.bank_name.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>
          </tr>

          <tr>
            <td>예금주</td>
            <td colSpan={2}>
            {values.holder.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>
            <td>계좌번호</td>
            <td colSpan={6}>
            
            {values.account_no.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}
            </td>
          </tr>
          </tbody>
        </table>

     
                </tr>
              </tbody>
            </table>
            <h3>의견내용</h3>
            <div style={{border:"1px solid black", padding:"10px"}}>
              <textarea className="purchase_comment" readOnly value={values.comment}>
              
              </textarea>
            {/*{values.comment.split('<br/>').map((item, idx) => {
  return (
    <div key={idx}>
      {item}
      <br />
    </div>
  );
})}*/}


            </div>
          </span></div>
        
        
      </div>

      </div>


      
     <div id="response">{links}</div>
 
      <div className="button_wrap">
      {/*</form>*/}
      </div>
      </div>
    </div>
    </div>
  );
};

export default PurchaseView;

