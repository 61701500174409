import { NavLink, Link,useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import MailNav from "../components/side/MailNav";
import './css/view.css';
import { IoIosArrowDown } from "react-icons/io";
import ScheduleList from "../components/side/ScheduleList";
import AutoComplete from "../components/AutoComplete";
import { Tooltip } from "react-tooltip";
import { GiHamburgerMenu } from "react-icons/gi";

axios.defaults.withCredentials = true; 
const initialValues = {
  calendar:"monthly",
  startDay:"sunday",
  weekNumber:false
 
};


const CalendarAdd = () => {
  const [values, setValues] = useState(initialValues);
  const [CheckList, setCheckList]=useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [weekNumber, setWeekNumber] = useState("0");
  const [showDepartments, SetShowDepartments] = useState(false);
  const [calendars, setCalendars] = useState([]);
    const [teamCalendars, setTeamCalendars] = useState([]);
    const [userCalendars, setUserCalendars] = useState([]);
    const [groupCalendars, setGroupCalendars] = useState([]);

  //const [shData, setShData]= useState();
  const navigate = useNavigate();


  useEffect(()=>{
    axios.get('https://gw.thegmmedical.com:5003/api/get_calendar_setting')
    .then(function (response){
      console.log(response.data);
      const vals = {
        calendar:response.data[0].calendar_type,
        startDay:response.data[0].start_day,
       
      };
      if (response.data[0].show_weeknum===1){
        setWeekNumber(true);
      }else{
        setWeekNumber(false);
      }
      setValues(vals);
      
      setCheckList(JSON.parse(response.data[0].selections));
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });


    axios.get('https://gw.thegmmedical.com:5003/api/getcalendar',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      setCalendars(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
        

    axios.get('https://gw.thegmmedical.com:5003/api/get_teamcalendar',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
      setTeamCalendars(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    var user_calendar=[];
    axios.get('https://gw.thegmmedical.com:5003/api/get_user_calendar',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    setUserCalendars(response.data);


    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
      
    //always executed
    });

    axios.get('https://gw.thegmmedical.com:5003/api/get_invited_calendar',{})
    .then(function (response){
      
      setGroupCalendars(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    
}, []);

  const handleRegisterButton = () => {
    console.log(values);
    if (values.name === "") {
      alert("내용을 입력해주세요.");
      return false;
    } 
   
    const formData = new FormData();
   /*const data = {
      subject : values.subject,
      form_name : values.form_name,
      form_group : values.form_group,
      in_use : values.in_use,
      cc : ccs,
      recipient : recs,
      expire : values.expire,
      security : values.security,
      form_data : test

    }*/
   


   const data = {
    calendar:values.calendar,
  startDay:values.startDay,
  weekNumber:weekNumber,
  selections: JSON.stringify(CheckList)
   }
   
    console.log(data);
    axios.post('https://gw.thegmmedical.com:5003/api/calendar_setting', data)
    .then(function (response){
      console.log(response);
      window.location.reload(true);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
   



  };

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (name==="group" && value==="department"){
      SetShowDepartments(true);
    }else if (name==="group" && value!=="department"){
      SetShowDepartments(false);
    }
  };

  

  

  const onChangeEach = (e, id) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
       setCheckList([...CheckList, id]);
       console.log("checkList...", [...CheckList, id]);
    // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
       setCheckList(CheckList.filter((checkedId) => checkedId !== id));
    }
    
 }
 

  return (
    <div className="wrapper">

<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <ScheduleList/>
        </div>
      )}
<div className="content-wrap">
    
      
    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>
      <div className="side-menu">
        <ScheduleList/>
      </div>
    

    <div className="main_content calendar_setting">
  
    

    <div className="btns" style={{display:"flex", alignItems:"start", 
    flexDirection:"column", width:"100%", margin:"0 auto"}}>
            
      <h4 style={{padding:"0"}}>환경설정 - 일정관리 </h4>
      
      </div>
          <div style={{background:"#fff"}}>
        
          <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
          

         
          <table className="form_write" style={{margin:"10px 0", width:"100%"}}>

      {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
      
      




<tr>
<td class="table_title2" nowrap="">
  기본화면
</td>
  <td class="table_list2_left">
  <div className="input-group radio" style={{display:"flex"}}>
      <label>
        <input type="radio" value="monthly" name="calendar" checked={values.calendar === "monthly"} onChange={handleInputChange}/><span>
          월별 보기
        </span>
      </label>
      <label>
        <input type="radio" value="weekly" name="calendar" checked={values.calendar === "weekly"} onChange={handleInputChange}/><span>
        주별 보기
        </span>
      </label>
      <label>
        <input type="radio" value="daily" name="calendar" checked={values.calendar === "daily"} onChange={handleInputChange}/><span>
        일별 보기
        </span>
      </label>
      <label>
        <input type="radio" value="list" name="calendar" checked={values.calendar === "list"} onChange={handleInputChange}/><span>
          목록 보기
        </span>
      </label>
  </div>
  </td>


</tr>

<tr>
<td class="table_title2" nowrap="">
  한주의 시작
</td>
  <td class="table_list2_left">
  <div className="input-group radio" style={{display:"flex"}}>
      <label>
        <input type="radio" value="sunday" name="startDay" checked={values.startDay === "sunday"} onChange={handleInputChange}/><span>
          일요일
        </span>
      </label>
      <label>
        <input type="radio" value="monday" name="startDay" checked={values.startDay === "monday"} onChange={handleInputChange}/><span>
        월요일
        </span>
      </label>
    
  </div>
  </td>


</tr>


<tr>
<td class="table_title2" nowrap="">
  몇 번째 주인지 표시
</td>
  <td class="table_list2_left">
  <div className="input-group radio" style={{display:"flex"}}>
      <label>
        <input type="checkbox" value="1" name="weekN" checked={weekNumber === true} onChange={()=>{console.log(weekNumber);setWeekNumber(!weekNumber)}}/><span>
          표시
        </span>
      </label>
  
    
  </div>
  </td>


</tr>





<tr>
<td class="table_title2" nowrap="">
  활성 캘린더
</td>
  <td class="table_list2_left">
  <div className="input-group radio" style={{display:"flex"}}>
    {calendars.map((ele,idx)=>
    <label>
      <input type="checkbox" style={{accentColor:`${ele.color}`}}
        value={ele.no}
        onChange={(e) => onChangeEach(e, ele.no)} checked={CheckList.includes(ele.no)}
        ></input>
        <span>
          {ele.name}
        </span>
      </label>
    )}
      {teamCalendars.map((ele,idx)=>
    <label>
      <input type="checkbox" style={{accentColor:`${ele.color}`}}
        value={ele.no}
        onChange={(e) => onChangeEach(e, ele.no)} checked={CheckList.includes(ele.no)}
        ></input>
        
      <span>
          {ele.name}
        </span>
      </label>
    )}
  {userCalendars.map((ele,idx)=>
    <label>
      <input type="checkbox" style={{accentColor:`${ele.color}`}}
        value={ele.no}
        onChange={(e) => onChangeEach(e, ele.no)} checked={CheckList.includes(ele.no)}
        ></input>
      
      <span>
          {ele.name}
        </span>
      </label>
    )}
    
  </div>
  </td>


</tr>







    </table>


    </div>

   

    </div>


    

    <div className="button_wrap">
    <button onClick={handleRegisterButton}>저장</button>
    
    {/*</form>*/}
    </div>
  </div>
  </div></div>

</div>
  );
};

export default CalendarAdd;
