

import { NavLink, Link,useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useRef, useCallback } from "react";
import MailNav from "../components/side/MailNav";
import './css/view.css';
import ReactCrop from 'react-image-crop';
import AddressList from "../components/side/AddressList";
import user from "./img/user.png";

import {GiHamburgerMenu} from "react-icons/gi";
axios.defaults.withCredentials = true; 
const initialValues = {
  name:"",
  phone:"",
  email:"",
  address:"",
  company:"",
  job_name:"",
  d_name:"",
  fax:"",
  note:"",
  group_no:"",
  piclink:""
 
};
function setCanvasImage(image, canvas, crop) {
  if (!crop || !canvas || !image) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');
  // refer https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );
}


const AddressUpdate = () => {
  const [values, setValues] = useState(initialValues);

  const [addrs, setAddrs] = useState([]);
  const [department, setDepartment] = useState([]);
  const [myGroups, setMyGroups] = useState([]);

  const [filename, setFileName] = useState("");
  const [sidebar, setSidebar] = useState(false);
  const [users, setUsers]=useState([]);


  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [profile, setProfile] = useState();

  //const [shData, setShData]= useState();
  const navigate = useNavigate();
  const imageRef = useRef();

  const [upImg, setUpImg] = useState();

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [crop, setCrop] = useState({ unit: 'px', width: 30, aspect: 0 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [cropped, setCropped] = useState(false);

  const location  = useLocation();

  console.log(crop);

  // on selecting file we set load the image on to cropper
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      //setUpImg(e.target.files[0]);
      reader.readAsDataURL(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setCropped(false);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  function generateDownload(canvas, crop, filename) {
    if (!crop || !canvas) {
      return;
    }
  
    canvas.toBlob(
      (blob) => {
        const previewUrl = window.URL.createObjectURL(blob);
  
        /*const anchor = document.createElement('a');
        anchor.download = 'cropPreview.png';
        anchor.href = URL.createObjectURL(blob);
        anchor.click();
  
        window.URL.revokeObjectURL(previewUrl);*/
  
        var file = new File([blob], filename);
        console.log("filename", filename, file);
        setCropped(true);
        values.file = file;
      },
      'image/png',
      1
    );
  }
  
  useEffect(() => {
    setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
  }, [completedCrop]);



  useEffect(()=>{
  
    axios.get('http://gw.thegmmedical.com:5003/api/check_login')
    .then(function (response){
    //console.log("hey result is!!!!!!!!!!!!", response.data);
    setUsers(response.data[0]);
    
  
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('http://gw.thegmmedical.com:5003/api/view_addr/'+location.state.no)
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log(response.data[0])
    setValues(response.data[0]);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('http://gw.thegmmedical.com:5003/api/getaddrs',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    setAddrs(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

   
    axios.get('http://gw.thegmmedical.com:5003/api/get_teamaddr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setDepartment(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });

    axios.get('http://gw.thegmmedical.com:5003/api/get_user_addr',{})
    .then(function (response){
    //setBoxes(json[0].boxes);
    console.log("department", response.data);
    setMyGroups(response.data);
    })
    .catch(function (error){
    console.log(error);
    })
    .then(function (){
    //always executed
    });
    
}, []);

  const handleRegisterButton = () => {
    console.log(values);
    if (values.group_no === "") {
      alert("주소록을 선택해주세요.");
      return false;
    } 
    if (values.name === "") {
      alert("이름을 입력해주세요.");
      return false;
    } 
    if (values.phone === "") {
      alert("휴대폰 번호를 입력해주세요.");
      return false;
    } 
    const formData = new FormData();
   /*const data = {
      subject : values.subject,
      form_name : values.form_name,
      form_group : values.form_group,
      in_use : values.in_use,
      cc : ccs,
      recipient : recs,
      expire : values.expire,
      security : values.security,
      form_data : test

    }*/
   

    
   
    axios.post('http://gw.thegmmedical.com:5003/api/addr_update', values)
    .then(function (response){
      console.log(response);
      navigate('/addr_list/'+values.group_no);

    })
    .catch(function (error){
      console.log(error);
    })
    .then(function (){
      //always executed
    });
   



  };

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

   
  };

  



 

  return (
    <div className="wrapper">
<div className="sidebar-icon" style={{margin:"0 auto"}} onClick={()=>{if (sidebar === false){document.getElementsByTagName("main")[0].style.overflow="hidden"}else{document.getElementsByTagName("main")[0].style.overflow="auto"} setSidebar(!sidebar);}}>
        <GiHamburgerMenu/>
      </div>
      {sidebar && (
        <div className="side-mobile-menu">
        <AddressList/>
        </div>
      )}
   
<div className="content-wrap">
    
      
    <div className="outer_wrapper" style={{justifyContent:"flex-start", width:"100%"}}>

    <div className="side-menu">
       <AddressList/>
    </div>

    <div className="main_content">
  
    

    <div className="btns" style={{display:"flex", alignItems:"start", 
    flexDirection:"column", width:"100%", margin:"0 auto"}}>
            
      <h4>새 연락처 추가 </h4>
      <div style={{background:"#fff", width:"100%", maxWidth:"1000px"}}>
       
          <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
          

         
          <table className="form_write" style={{margin:"10px 0", width:"100%"}}>

      {/*<button onClick={()=>downloadEmployeeData()}>test...</button>*/}
      
      
      <tr>
<td class="table_title2" nowrap="">
  주소록 그룹<span style={{color:"red", marginLeft:"3px"}}>*</span>
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <select name="group_no" className="formInput" value={values.group_no ||''}
                                  onChange={handleInputChange}>    
      <option value="no">선택 안함</option>
    {addrs.map((element,index)=>(
      <option value={element.no}>{element.g_name}</option>
    ))}
{department.map((element,index)=>(
      <option value={element.no}>{element.g_name}</option>
    ))}
    {myGroups.map((element,index)=>(
      <option value={element.no}>{element.g_name}</option>
    ))}
  </select>
  </div>
  </td>


</tr>

{/*
<tr>
<td class="table_title2" nowrap="">
  명함<span style={{color:"red", marginLeft:"3px"}}>*</span>
</td>
  <td class="table_list2_left">
  <div className="input-group">

{values.filename !=="" ? 
<div className="img-wrap img-upload"><img for="photo-upload" src={"https://gw.thegmmedical.com/uploads/addr/"+values.no+"/"+values.filename} alt="profile_pic" ref={imageRef}/>
</div>
: <img for="photo-upload" src={user} alt="profile_pic"/>
}

  <div>
      <div>
        <input type='file' accept='image/*' onChange={onSelectFile} style={{margin:"0"}}/>
      </div>

      {cropped === false && (
        <div>
         <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />
        <button
        type='button'
        disabled={!completedCrop?.width || !completedCrop?.height}
        onClick={() =>
          generateDownload(previewCanvasRef.current, completedCrop, filename)
        }
      >
        자르기
      </button>
      </div>
      )}
      <div>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        /> 
        
       
      </div>
      
    </div>




  </div>
  </td>


</tr>*/}


<tr>
<td class="table_title2" nowrap="">
  이름<span style={{color:"red", marginLeft:"3px"}}>*</span>
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <input type="text" name="name" className="formInput" placeholder="이름을 입력하세요." value={values.name}
          onChange={handleInputChange}></input>
  </div>
  </td>


</tr>




<tr>
<td class="table_title2" nowrap="">
  휴대폰<span style={{color:"red", marginLeft:"3px"}}>*</span>
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <input type="text" name="phone" className="formInput" placeholder="휴대폰 번호를 입력하세요." value={values.phone}
          onChange={handleInputChange}></input>
  </div>
  </td>

 

</tr>

<tr>
<td class="table_title2" nowrap="">
  회사
</td>
  <td class="table_list2_left">
  <div className="input-group">
  <input type="text" name="company" className="formInput" placeholder="회사 이름을 입력하세요." value={values.company}
          onChange={handleInputChange}></input>
  </div>
  </td>


</tr>


<tr>
<td class="table_title2" nowrap="">
  직위
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>

  <input type="text" name="job_name" className="formInput" placeholder="직위를 입력하세요." value={values.job_name}
          onChange={handleInputChange}></input>

  </div>
  </td>


 
  <td class="table_title2" nowrap="">
    부서
  </td>
  <td class="table_list2_left">
  <div className="input-group">
  <input type="text" name="d_name" className="formInput" placeholder="부서를 입력하세요." value={values.d_name}
          onChange={handleInputChange}></input>
  
  </div>
  </td>

</tr>



<tr>

<td class="table_title2" nowrap="">
  이메일
</td>
<td class="table_list2_left" >
<div className="input-group">
<input type="text" name="email" className="formInput" placeholder="이메일 주소를 입력하세요." value={values.email}
          onChange={handleInputChange}></input>   
</div>
</td>
</tr>
<tr>

<td class="table_title2" nowrap="">
  fax
</td>
<td class="table_list2_left">
<div className="input-group">
<input type="text" name="fax" className="formInput" placeholder="팩스 번호를 입력하세요." value={values.fax}
          onChange={handleInputChange}></input>     
</div>
</td>
</tr>

<tr>

<td class="table_title2" nowrap="">
  비고
</td>
  <td class="table_list2_left">
  <div className="input-group" style={{display:"flex", flexShrink:"0"}}>
  <input type="text" name="note" className="formInput" placeholder="비고" value={values.note}
          onChange={handleInputChange}></input>   
  </div>
  </td>
 

</tr>







    </table>


    </div>

   

    </div>


    

    <div className="button_wrap">
    <button onClick={handleRegisterButton}>등록</button>
    
    {/*</form>*/}
    </div>
    </div>
  </div>
  </div></div>

</div>
  );
};

export default AddressUpdate;

