import { Outlet, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import { useLayoutEffect } from 'react';

const Layout = () => {

  const location = useLocation();
  useLayoutEffect(() => {
    console.log("location",location);
    if (document.getElementsByTagName("main")[0] !==undefined){
      document.getElementsByTagName("main")[0].style.overflow="unset";
    }
   }, [location])
  return (
    <div style={{height:"100%"}}>
      <Navbar/>
      <main style={{height:"100%", overflow:"auto"}}>
        <Outlet/>
      </main>
    </div>
  );
};

export default Layout;